import './searchResults.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import OfferCard from '../offerCard/offerCard';
import PBottom from '../../assets/photo_bottom.png';
import logo from '../../assets/whiteLogoFH.png';
import Accordion from 'react-bootstrap/Accordion';
import SearchForm from '../searchForm/searchForm';
import Spinner from 'react-bootstrap/Spinner';
import DynamicToast from '../dynamicToast/dynamicToast';
import Button from 'react-bootstrap/Button';
import up from '../../assets/totop.png';
import filter from '../../assets/filter.png';
import defaultPhoto from '../../assets/defaultphoto.png';
import guidaIcon from '../../assets/guide.png';
import guidewhite from '../../assets/guidewhite.png';
import Sheet from 'react-modal-sheet';
import Guide from '../guide/guide';
import closeImg from '../../assets/close.png';
import dragbottom from '../../assets/dragbottom.png';
import Col from 'react-bootstrap/Col';
import newwindows from '../../assets/newwindows.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import bookingLogo from '../../assets/booking.png';
import shareImg from '../../assets/white_share.png';
import Share from '../share/share';

function SearchResults () {

    const navigate = useNavigate();
    const offersObj = useLocation();

    const [offers, setOffers] = useState([]);
    const [fullOffers, setFullOffers] = useState([]);
    const [offersType, setOffersType] = useState();
    const [photo, setPhoto] = useState();
    const [cityTo, setCityTo] = useState({});
    const [searchType, setSearchType] = useState();
    const [guida, setGuida] = useState();
    const [guidaSheet, setGuidaSheet] = useState(false);
    const [voliDiretti, setVoliDiretti] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState();

    const [bookingId, setBookingId] = useState(undefined);
    const [bookingUrl, setBookingUrl] = useState();

    const [openShare, setOpenShare] = useState(false);

    const [loader, setLoader] = useState(true);
    const [showYearMess, setShowYearMess] = useState(false);
    const [toastText, setToastText] = useState('');

    const [imageError, setImageError] = useState(false);

    useEffect(()=>{
        if (offersObj.state) {
            setOffers(offersObj.state.offers.offers);
            setFullOffers(offersObj.state.offers.offers);
            setOffersType(offersObj.state.offers.offersType);
            setPhoto(offersObj.state.offers.img.img);
            setCityTo(offersObj.state.offers.cityTo);
            setSearchType(offersObj.state.offers.searchType);
            setGuida(offersObj.state.offers.cityTo.guide);
            setVoliDiretti(offersObj.state.offers.voliDiretti);
            setBookingId(offersObj.state.offers.bookingId);
            setSelectedMonth(offersObj.state.offers.selectedMonth)
        } else {
            navigate('/');
        }
    }, []);

    useEffect(()=>{
        if (bookingId) {
            setBookingUrl(`https://www.booking.com/searchresults.it.html?city=${bookingId}&aid=7924785&no_rooms=1&group_adults=2&room1=A%2CA&label=FlightsHunter`)
          }
    }, [bookingId])

    const isLoading = (e)=> {
        setLoader(e)
    }

    const yearMessage = ()=> {
        setToastText('In modalità ricerca annuale le date non sono modificabili');
        setShowYearMess(true);
    }

    const noFlights = ()=> {
        setToastText('Non abbiamo trovato offerte con i criteri di ricerca inseriti, prova a cambiare qualcosa!');
        setShowYearMess(true);
    }

    const closeDynamicToast = ()=> {
        setToastText('');
        setShowYearMess(false);
    }

    const scrollToTop = ()=> {
        window.scrollTo(0, 0);
    }

    const closeGuideSheet = ()=> {
        setGuidaSheet(false)
    }

    const filterOffers = (voliDiretti)=> {
        setVoliDiretti(voliDiretti)
        if (voliDiretti) {
            let filteredOffers = [];
            offers.forEach(off => {
                if(off.route) {
                    if (off.route.length == 2) filteredOffers.push(off);
                } else if (off.segment){
                    if(off.segment.length == 2)filteredOffers.push(off);
                }
            });
            if (filteredOffers.length) {
                setOffers(filteredOffers);
            } else {
                setShowYearMess(true);
                setToastText('Non risltano voli diretti per questa ricerca');
            }
        }else {
            setOffers(fullOffers);
        }
    }

    const openBooking = ()=> {
        window.open(bookingUrl, '_blank');
    }

    const openBookingLandingPage = ()=> {
        window.open('https://www.booking.com/index.html?aid=7924785', '_blank');
    }

    const closeShare = (e) => {
        setOpenShare(e);
    }

    const [shareDate, setShareDate] = useState({});
    const createAndOpenShare = ()=> {
        if (searchType === 'month') {
            let today = new Date();
            let firstDay = new Date(selectedMonth.fromDate+today.getFullYear().toString());
            let year = today.getFullYear();
            if (today.getMonth() >= firstDay.getMonth()) {
                year+=1;
            }
            setShareDate({
                dep: selectedMonth.fromDate.substring(3,5) + '-' + selectedMonth.fromDate.substring(0,2) + '-' + year.toString(),
                ret: selectedMonth.toDate.substring(3,5) + '-' + selectedMonth.toDate.substring(0,2) + '-' + year.toString()
            })
        } else {
            setOpenShare(true);
        }
    }

    useEffect(()=>{
        if(shareDate.dep || shareDate.ret) setOpenShare(true);
    }, [shareDate])

    return (
        <>
            {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            <div className='mb-5 relative'>
                <div className='iconContainer'>
                    <img className='imgw18' style={{width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                    <img className='imgw18' style={{width: '30px'}} src={shareImg} alt="share" onClick={()=>createAndOpenShare()}/>
                </div>
                {
                    imageError ? 
                    <img src={defaultPhoto} alt="city to photo" style={{width: '100vw', top: '0'}} onLoad={()=>setLoader(false)}/>
                    :
                    <img src={photo} alt="city to photo" style={{width: '100vw', top: '0'}} onLoad={()=>setLoader(false)} onError={()=>{setImageError(true)}}/>
                }
                <span className='center_f'>
                    <img src={PBottom} alt="city to photo" style={{width: '100vw', position: 'absolute', left: '0', top: '80%'}}/>
                    <span className='toCityTxt'>{cityTo.name}</span>
                    {
                        guida ? 
                        <img className='guideImg' style={{width: '34px'}} src={guidewhite} alt="Guida" onClick={()=>{setGuidaSheet(true)}}/>
                        :
                        <div></div>
                    } 
                </span>
            </div>
            <Accordion defaultActiveKey="0" className='acc' onClick={scrollToTop}>
              <Accordion.Item eventKey="1" className='acc_bck'>
                <Accordion.Header>
                    <div className='offerTxt att_title'>
                        Modifica ricerca
                    </div>
                </Accordion.Header>
                <Accordion.Body className='acc_body'>
                  <SearchForm isLoading={isLoading} openToast={yearMessage} noFlights={noFlights} inResult={true}/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>           
            <div className='icon_menu'>
                <Col style={{'textAlign': 'center'}}>
                    <div className='relative center'>
                        <Button className='offerBtn icon_btn absolute' style={{'left': '0'}}><img className='imgw18' src={filter} alt="settings" /></Button>
                        <Dropdown className='absolute' autoClose={'outside'} style={{'left': '0'}}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            i
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                                Voli diretti
                                <Form.Check 
                                  type="switch"
                                  id="custom-switch"
                                  checked={voliDiretti}
                                  onChange={()=>{filterOffers(!voliDiretti)}}
                                />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <Col style={{'textAlign': 'center'}}>
                    <div>
                        {
                            bookingId ? 
                            <div onClick={openBooking} className='booking-btn'>
                                <div style={{'whiteSpace': 'nowrap', 'color': 'darkslategrey', 'fontSize': '12px'}}>
                                    Cerca Hotel su <img src={bookingLogo} alt="open" style={{'width': '90px'}}/> <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                                </div>
                            </div>
                            : 
                            <div onClick={openBookingLandingPage} className='booking-btn'>
                                <div style={{'whiteSpace': 'nowrap', 'color': 'darkslategrey', 'fontSize': '12px'}}>
                                    Cerca Hotel su <img src={bookingLogo} alt="open" style={{'width': '90px'}}/> <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
                <Col style={{'textAlign': 'center'}}>
                    <div style={{'display': 'flex', 'justifyContent': 'end'}}>
                        <Button className='offerBtn icon_btn' onClick={scrollToTop}><img src={up} alt="su" className="imgw18" /></Button>
                    </div>
                </Col>
                
            </div>
            <div>
                {
                    offers.map((offer, i)=>
                        <div key={i}>
                            <OfferCard offer={offer} offersType={offersType} offerId={undefined} searchType={searchType} bookingId={bookingId}/>
                        </div>
                    )
                }
            </div>
            {
                offers.length > 2 ?
            <Share
                open={openShare}
                closeShare={closeShare}
                daNome={offers[1].cityFrom}
                aNome={offers[1].cityTo}
                da={offers[1].flyFrom}
                a={cityTo.code}
                price={offers[1].price}
                date={searchType == 'month' ? shareDate.dep + '-' + shareDate.ret : offers[0].date.departure.from.split('/').join('-') + '-' + offers[0].date.return.from.split('/').join('-')}
                isForFullOffer={false}
                isSearch={searchType !== 'month' ? true : false}
                isResult={searchType == 'month' ? true : false}
            />
            : 
            <div></div>
            }
            

            <DynamicToast open={showYearMess} text={toastText} close={closeDynamicToast}/>
            <Sheet
              isOpen={guidaSheet} onClose={closeGuideSheet}
              snapPoints={[700]}
              initialSnap={0}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={closeGuideSheet}/>
                    <img src={closeImg} onClick={closeGuideSheet} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                    <Guide id={guida} fromRes={true}/>
                    <div className='footerContainer'>
                      <Col style={{'textAlign': 'center'}}>
                      </Col>
                      <Col style={{'textAlign': 'center'}}>
                        <div style={{'whiteSpace': 'nowrap'}} onClick={()=>{window.open(`https://www.flightshunter.it/guide/${guida}`)}}>
                            Apri in una nuova scheda <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                        </div>
                      </Col>
                      <Col style={{'textAlign': 'right'}}>
                      </Col>
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
        </>
    )
}

export default SearchResults;