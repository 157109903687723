import { useEffect } from 'react';
import AreaOffersSection from '../areaOffersSection/areaOffersSection';

function AreaOffers({offer, offerId}) {

    useEffect(()=>{
        let t = Object.keys(offer);
        for(let i = 0; i < t.length; i++) {
            if(!offer[t[i]].flights.length) {
                delete offer[t[i]];
            }
        };
    }, [offer])

    return (
        <>
            {
                Object.keys(offer)
                .sort((a, b) => offer[a].flights[0]?.price < offer[b].flights[0]?.price ? -1 : 1)
                .map((of, i)=>
                <div key={i} className="center">
                    {
                        offer[of].flights.length ? 
                        <AreaOffersSection name={offer[of].name} code={offer[of].code} flights={offer[of].flights} offerId={offerId}/>                        
                        :
                        <></>
                    }
                </div>
                )
            }
        </>
    )
}

export default AreaOffers;