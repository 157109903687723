const guide = [
    {
        name: 'barcellona',
        guide: '2',
        views: '549',
        img: 'https://www.grimaldi-lines.com/wp-content/uploads/2022/07/sagrada-familia-1030x687.jpg',
        desc: "Barcellona, capitale della Catalogna, è celebre soprattutto per l'arte, l'architettura e le sue spiagge. La basilica della Sagrada Família è il simbolo della città",
        attractions:[
            {
               "description":"Dichiarata nel 2015 Patrimonio Unesco, è il capolavoro dell'architetto Antonio Gaudì e al suo interno ospita la cripta dove si trova proprio la sua tomba. La Sagrada Familia era un progetto molto ambizioso, tutt'oggi ancora \"incompleto\".",
               "id":0,
               "img":"https://cdn-imgix.headout.com/mircobrands-content/image/faaf565e4c27db2407c2be79d566e8ed-Finish%20Date.jpg",
               "metro":"metro linea L2",
               "title":"Sagrada Familia",
               "link":"https://www.getyourguide.it/sagrada-familia-l2699/sagrada-familia-skip-the-line-ticket-t50027/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
            },
            {
               "img":"https://travelourplanet.com/wp-content/uploads/2020/10/Visitare-Casa-Batllo-e-La-Pedrera-a-Barcellona.jpg",
               "id":1,
               "description":"Casa Batlló è uno degli edifici più stravaganti e originali d'Europa, inserita nel Patrimonio Unesco. Dal 2007 fa anche parte delle sette meraviglie di Barcellona",
               "metro":"metro linea L2",
               "link":"",
               "title":"Casa Batlló"
            },
            {
               "link":"https://www.getyourguide.it/casa-mila-l3038/skip-the-line-casa-mila-ticket-with-audio-guide-t49864/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
               "id":2,
               "img":"https://runnerbeantours.com/wp-content/uploads/2018/08/casa-batllo-balconies-barcelona.jpg",
               "title":"Casa Milà",
               "metro":"metro linea L2",
               "description":"Conosciuta anche come Casa Milà, La Pedrera fu costruita all'inizio del '900 e ha uno stile modernista, molto diverso rispetto alla vicina Casa Battlò. La casa si sviluppa su ben 9 piani, un vero e proprio spettacolo d'architettura. Per visitarla con calma dovete preventivare minimo un'ora."
            },
            {
               "metro":"",
               "img":"https://s3.eu-west-1.amazonaws.com/pro.static.portalstdo.tmb.cat/styles/galeria_slider/s3/s3fs-public/2017-11/REF_CATEDRAL_CT6A5463.jpg",
               "description":"Immergetevi nel Barrio Gotico per trovare la splendida Catedral de Barcelona, imponente cattedrale in stile gotico che dalle sue terrazze regala anche una splendida vista su tutta la città e il centro",
               "id":3,
               "link":"https://www.getyourguide.it/barcelona-l45/barcelona-gothic-cathedral-walking-tour-entry-ticket-t428189/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
               "title":"Catedral de Barcelona"
            },
            {
               "description":"Il mercato più famoso di Barcellona. Da Casa Batlló si raggiunge facilmente a piedi con una passeggiata (15 min) lungo La Rambla. Non è solo il mercato più famoso della città, ma si tratta anche del più grande mercato di tutta la Spagna!",
               "img":"https://www.hcchotels.com/files/boqueria_frutas-1024x766.jpg",
               "id":4,
               "link":"",
               "title":"Mercat de la Boqueria",
               "metro":""
            },
            {
               "img":"https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/03/e3/7d/a-stroll-down-la-rambla.jpg?w=700&h=-1&s=1",
               "title":"La Rambla",
               "metro":"metro linea L3",
               "id":5,
               "link":"",
               "description":"Prendetevi un po' di tempo per passeggiare lungo La Rambla, la via più famosa di Barcellona. Tutta la via è animata da locali e artisti di strada"
            },
            {
               "link":"",
               "img":"https://thumbs.dreamstime.com/b/barcellona-spagna-agosto-vista-aerea-di-placa-catalunya-il-agosto-barcellona-spagna-questo-quadrato-%C3%A8-considerato-come-il-30305517.jpg",
               "title":"Plaça de Catalunya",
               "description":"La meravigliosa Plaça de Catalunya, il cuore del centro storico. Non è solo una piazza, ma un vero e proprio punto di incontro e centro culturale. Circondata da meravigliosi edifici, immersa nel verde e ricca di locali, qui potrete trovare anche l'Hard Rock Cafe Barcelona.",
               "id":6,
               "metro":"metro linea L1, L3, L6 e L7"
            },
            {
               "id":"7",
               "title":"Platja de la Barceloneta",
               "description":"Barcellona non è solo cultura e visite, ma anche spiaggia e mare. Platja de la Barceloneta è la spiaggia più famosa di Barcellona, in estate presa d'assalto da locals e turisti. Qui potrete rilassarvi sotto i raggi del sole, fare il bagno e giocare a beach volley.",
               "link":"",
               "img":"https://a.travel-assets.com/findyours-php/viewfinder/images/res70/501000/501690-barceloneta-beach.jpg"
            },
            {
               "metro":"metro linea L3",
               "id":8,
               "description":"Il Parc Güell è un grande giardino cittadino realizzato dall'architetto Gaudì. Il parco è oggi il luogo ideale per evadere dallo stress della quotidianità e rifugiarsi in un mondo da favola tra meravigliose sculture e inconfondibili opere architettoniche. L'area verde è accessibile gratuitamente, tuttavia le principali attrazioni sono concentrate nella zona monumentale, per la quale bisogna pagare un biglietto.",
               "img":"https://images.musement.com/cover/0002/15/park-guell_header-114423.jpeg",
               "title":"Parc Güell",
               "link":"https://www.getyourguide.it/barcelona-l45/skip-the-line-park-guell-entrance-ticket-t53791/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
            }
        ]
    }, {
        name: 'madrid',
        guide: '1',
        views: '486',
        img: 'https://www.travellens.co/content/images/2019/07/Gran-via-1.jpg',
        desc: "Capitale al centro della Spagna, è una città caratterizzata da eleganti viali e parchi ampi e curati. A Madrid sono custodite moltissime opere d'arte europee, come i dipinti di Goya, Velázquez e altri maestri spagnoli",
        attractions:[
           {
              "img":"https://a.cdn-hotels.com/gdcs/production192/d1353/9aa7c8ec-ea63-46a1-9457-ed98c029263e.jpg",
              "id":0,
              "description":"Una delle piazze principali di Madrid. È qui che si svolge il capodanno madrileno ed è qui che, presso la Real Casa de Correo si trova la sede della presidenza della comunità di Madrid. Famosa per le 'campanadas', ovvero i 12 rintocchi che segnalano gli ultimi secondi dell'anno",
              "link":"https://www.getyourguide.it/madrid-l46/madrid-1-or-2-day-hop-on-hop-off-sightseeing-bus-tour-t1251/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "metro":"metro linea 1, 2, 3 e 5",
              "title":"Puerta del Sol"
           },
           {
              "id":1,
              "metro":"metro linea 2",
              "img":"https://www.travel365.it/f/madrid-city-hall-building-plaza-de-cibeles-spain-selective-focus.jpg.webp",
              "title":"Plaza de Cibeles",
              "description":"Plaza de Cibeles è uno dei simboli di Madrid e incanta i turisti con la sua bellezza! E' caratterizzata da splendidi ed imponenti edifici come la sede centrale della Banca di Spagna, i cui interni sono arricchiti dai dipinti di Goya, Mengs, Maella e Vicente López. Ma il fulcro di tutta la piazza è sicuramente la splendida Fontana che raffigura la dea Cibeles su un carro trainato da leoni.",
              "link":""
           },
           {
              "id":2,
              "img":"https://www.progettosigillo.it/wp-content/uploads/2021/06/museo-del-prado-madrid.jpg",
              "description":"Si tratta di uno dei musei più importanti e visitati al mondo. Esposte opere di Caravaggio, Goya, Raffaello e Velasquez.",
              "link":"https://www.getyourguide.it/madrid-l46/entrance-ticket-national-prado-museum-t32264/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "metro":"metro linea 2",
              "title":"Museo del Prado"
           },
           {
              "id":3,
              "img":"https://offloadmedia.feverup.com/madridsecreto.co/wp-content/uploads/2018/02/08114643/Jard%C3%ADn_Bot%C3%A1nico_Buenos_Aires_Invernadero-1024x597.jpg",
              "metro":"metro linea 1 e 2",
              "description":"Il Giardino Botanico Reale di Madrid venne fondato insieme al Manzanares nel 1755 per ordine di re Fernando VI; all'inizio raccoglieva solamente duemila specie vegetali, oggi invece ci sono un totale di 30,000 piante e fiori.",
              "title":"Real Jardín Botánico",
              "link":""
           },
           {
              "description":"Chiamato semplicemente El Retiro, il Parco del Retiro di Madrid è una tappa consigliata soprattutto alle famiglie con bambini. Si tratta di un'area verde di 118 ettari dove è possibile darsi a diverse attività. Lungo la via delle Statue, in spagnolo Paseo de la Argentina, sono presenti sculture che ritraggono i diversi sovrani di Spagna e una fontana dedicata al Diavolo.",
              "id":4,
              "link":"https://www.getyourguide.it/madrid-l46/madrid-el-retiro-park-self-guided-audio-tour-t407729/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "metro":"metro linea 2",
              "title":"Parco del Retiro",
              "img":"https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0c/53/53/2c/img-20160729-205021-largejpg.jpg?w=1200&h=-1&s=1"
           },
           {
              "link":"",
              "title":"Plaza Mayor",
              "id":5,
              "metro":"metro linea 2 e 5",
              "description":"Non si direbbe mai che questa piazza, fino al 1580, è stata sede del mercato cittadino ma non solo. Qui si svolgevano anche esecuzioni capitali, feste popolari e la più celebre delle manifestazioni spagnole, la Corrida.",
              "img":"https://images.placesonline.com/photos/plaza-mayor-madrid_574067893_200676_1646035161.jpg?quality=80&w=550"
           },
           {
              "id":6,
              "metro":"metro linea 2 e 5",
              "title":"Mercato de San Miguel",
              "description":"Il Mercato de San Miguel è uno dei principali mercati gastronomici al mondo, e la sua importanza è data dal fatto che permette al turista di fare un vero e proprio tour dei sapori e odori tipici della Spagna!",
              "img":"https://www.acquabuona.it/wp-content/uploads/2009/08/img_4087.jpg",
              "link":""
           },
           {
              "description":"La sua storia ha inizio nel 1624, quando il re Filippo IV e sua moglie Isabella di Borbone decidono di erigere la cattedrale cittadina proprio dove sorgeva la Chiesa di Santa Maria dell'Almudena. Dal progetto all'effettiva costruzione della struttura trascorsero però oltre 200 anni.",
              "link":"",
              "metro":"metro linea 2 e 5",
              "title":"Cattedrale dell'Almudena",
              "id":7,
              "img":"https://images.mnstatic.com/5f/21/5f210f3acf17afb0e469394312f957a5.jpg"
           },
           {
              "description":"L'edificio attuale è il risultato della ricostruzione, avvenuta nel 1764, delle rovine di quella che fu la residenza della famiglia reale spagnola. Il Palazzo Reale è ricco di storia e di meravigliose aree, imperdibili ad esempio la Sala del Trono, l'Armeria Reale e gli appartamenti.",
              "metro":"metro linea 2 e 5",
              "id":8,
              "link":"https://www.getyourguide.it/madrid-l46/madrid-royal-palace-skip-the-line-guided-tour-t231452/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "title":"Palazzo Reale",
              "img":"https://sviaggiare.it/wp-content/uploads/2019/09/palazzo-reale-madrid.jpg"
           },
           {
              "img":"https://images.mnstatic.com/47/26/4726005420ea7b3b1031f6a8d891b93b.jpg",
              "link":"",
              "metro":"metro linea 3 e 10",
              "description":"Nel quartiere di Argüelles, praticamente a nord-ovest del centro cittadino, si erge il Templo de Debod, sicuramente uno dei più noti ma soprattutto insoliti monumenti della città madrilena. Questo splendido tempio egizio è sito su una collinetta a poca distanza da Plaza de España e risale probabilmente al 200 a.C.",
              "id":9,
              "title":"Tempio di Debod"
           }
        ]
    },
    {
        name: 'berlino',
        guide: '3',
        views: '385',
        img: 'https://www.labussola.it/wp-content/uploads/2022/03/Berlino-labussola.jpeg',
        desc: "Capitale della Germania, fu fondata nel XIII secolo. Il Memoriale dell'Olocausto e le parti restanti del muro di Berlino, ricoperte di graffiti, testimoniano la difficile storia della città nel corso del XX secolo. Durante la guerra fredda, la città fu divisa in due parti, e la Porta di Brandeburgo, porta di passaggio tra le due Germanie oggi è diventata il simbolo della sua riunificazione.",
        attractions:[
           {
              "title":"Porta di Brandeburgo",
              "id":0,
              "metro":"Metro linea U5",
              "link":"https://www.getyourguide.it/berlino-l17/berlino-bus-hop-onhop-off-e-ingressi-alle-attrazioni-t16079/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Progettata nel 1791 è un vero e proprio simbolo della città. Emblema della divisione delle due Germanie all'epoca della Guerra Fredda, una volta caduto il Muro è diventata invece l'immagine di un Paese di nuovo unito. Formata da dodici colonne sormontate dalla celebre Quadriga di Johann Gottfried Schadow.",
              "img":"https://cdn.getyourguide.com/img/tour/591ad6e28be866ac.jpeg/146.jpg"
           },
           {
              "link":"https://www.getyourguide.it/berlino-l17/berlino-tour-del-reichstag-e-del-distretto-governativo-t49448/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Sede del parlamento tedesco. Oggi una parte del parlamento è aperta al pubblico: è infatti possibile visitare la sua grande cupola di vetro. Da una delle finestre di questo edificio, nel 1918, fu proclamata la nascita della Repubblica Tedesca. Il palazzo venne poi distrutto quasi completamente da un incendio nel 1933 e, durante la guerra, venne utilizzato anche come clinica per le nuove nascite. Infine, nel 1990, qui venne celebrata al suo interno la cerimonia ufficiale della Germania riunificata.",
              "title":"Reichstag",
              "metro":"",
              "img":"https://www.visitberlin.de/system/files/styles/visitberlin_gallery_item_visitberlin_mobile_1x/private/image/regierungsviertel44_DL_PPT_0.jpg?itok=QPrT_ymk",
              "id":1
           },
           {
              "title":"L'Isola dei Musei",
              "metro":"Metro linea U5",
              "img":"https://www.viaggiberlino.com/images/isola-dei-musei-berlino.jpg",
              "description":"L'Isola dei Musei è il quartiere museale di Berlino, dichiarato Patrimonio dell'UNESCO: qui si trovano numerosi musei, ma tra tutti il più famoso è sicuramente il Pergamonmuseum, che conserva al suo interno testimonianze di diverse epoche storiche, dall'Impero Romano all'Antica Grecia; ma il punto forte di tutta la collezione è sicuramente la grande Porta di Babilonia. Vi consigliamo di acquistare il Museuminsel Pass che consente ingressi a tutti i musei presenti, risparmiando notevolmente.",
              "id":2,
              "link":"https://www.getyourguide.it/berlino-l17/berlino-welcomecard-e-tessera-dell-isola-dei-musei-t20348/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
           },
           {
              "metro":"Metro linea U9",
              "title":"Tiergarten",
              "img":"https://upload.wikimedia.org/wikipedia/commons/3/32/Siegess%C3%A4ule-Berlin-Tiergarten.jpg",
              "link":"",
              "id":3,
              "description":"Il Tiergarten è il più grande e antico parco di Berlino. Al centro si trova invece l'imponente Siegessäule, ovvero la Colonna della Vittoria, uno dei monumenti più famosi della capitale. Il Tiergarten è facilmente raggiungibile a piedi dalla Porta di Brandeburgo."
           },
           {
              "description":"L'edificio che possiamo ammirare oggi risale al 1904, risultato di una ristrutturazione voluta dall'imperatore Guglielmo II per rispecchiare il prestigio della dinastia reale. La visita è scontata con la Berlino WelcomeCard.",
              "link":"https://www.getyourguide.it/berlino-l17/berlino-welcomecard-e-tessera-dell-isola-dei-musei-t20348/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "title":"Duomo di Berlino",
              "metro":"",
              "id":4,
              "img":"https://www.10cose.it/wp-content/uploads/2016/01/duomo-berlino.jpg"
           },
           {
              "description":"Una delle piazze più famose di Berlino. Riconoscerla è davvero semplice, in quanto qui si trova l'iconica Berliner Fernsehturm, ovvero la Torre della Televisione, che con i suoi 365 m d'altezza è visibile da ogni punto della città! E' ormai diventata uno dei simboli di Berlino e vi permetterà di ammirare tutta la capitale tedesca dall'alto. ingresso è incluso nella Berlino WelcomeCard",
              "img":"https://berlintouristinformation.com/en/wp-content/uploads/2020/06/Alexanderplatz-in-Berlin-in-Deutschland-1.jpg",
              "metro":"",
              "title":"Alexanderplatz",
              "link":"https://www.getyourguide.it/berlino-l17/ingresso-prioritario-torre-della-televisione-a-berlino-t141648/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "id":5
           },
           {
              "title":"Muro di Berlino",
              "metro":"Metro linea U1 e U3",
              "id":6,
              "link":"",
              "description":"Con la fine della Seconda Guerra Mondiale e la sconfitta della Germania, la capitale tedesca venne divisa in due parti dal famoso Muro di Berlino\" che si estendeva per oltre 170 km ed era alto circa 10 m. Nel 1989 il muro venne abbattuto ed oggi ne rimangono solo brevi tratti, decorati a decine di artisti con murales ed opere d'arte divenute celebri in tutto il mondo.",
              "img":"https://www.traveldreams.it/wp-content/uploads/2012/08/Berlino-east-side-gallery.jpg"
           },
           {
              "title":"Memoriale dell'Olocausto",
              "metro":"",
              "id":7,
              "link":"",
              "description":"L'area è ricoperta da 2711 blocchi di cemento di diverse altezze, da 20 cm fino a quasi 5 m. Il risultato è una struttura labirintica, stretta e quasi irritante per chi ci cammina attraverso, dando una sensazione di smarrimento, solitudine e straniamento.",
              "img":"https://www.viveberlintours.com/wp-content/uploads/2019/04/Memoriale_ebrei_assassinati_Europa_olocausto_Berlino.jpg"
           }
        ]
    },
    {
        name: 'londra',
        guide: '4',
        views: '524',
        img: 'https://londra.io/wp-content/uploads/2021/10/Londra.jpg',
        desc: "Londra, capitale dell'Inghilterra e del Regno Unito, è una città estremamente contemporanea con una storia che risale all'antica Roma. Nel suo centro sorgono l'imponente Palazzo del Parlamento, l'iconica torre dell'orologio nota come Big Ben e l'abbazia di Westminster, dove hanno luogo le incoronazioni dei monarchi britannici.",
        attractions:[
           {
              "img":"https://images.musement.com/cover/0001/75/thumb_74608_cover_header.jpeg",
              "metro":"Metro fermate Tower Hill, linea Circle e District Line",
              "id":0,
              "link":"https://www.getyourguide.it/londra-l57/biglietto-d-ingresso-al-tower-bridge-t439425/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Il ponte levatoio più famoso al mondo e collega la Torre di Londra alla zona di Southwark. Offre uno degli scorci più apprezzati e fotografati della città. I visitatori possono percorrere la passerella in vetro posta a 42 metri sopra il Tamigi che collega le due torri, in stile neogotico. Quando fu costruito doveva permettere il passaggio sul fiume anche di navi molto grandi.",
              "title":"Tower Bridge"
           },
           {
              "id":"1",
              "metro":"Metro fermate Westminster, linea Circle, District Line e Jubilee",
              "link":"https://www.getyourguide.it/londra-l57/abbazia-e-palazzo-di-westminster-tour-di-4-ore-t116803/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "img":"https://siviaggia.it/wp-content/uploads/sites/2/2023/01/Big-Ben.jpg",
              "title":"Big Ben e Parlamento",
              "description":"Il Palazzo di Westminster è l'edificio che ospita il Parlamento inglese, si tratta di un vero e proprio spettacolo di architettura gotica e, al suo interno, si compone di ben 1.200 stanze. Proprio accanto al Palazzo si trova quello che è uno dei simboli di Londra: il famosissimo Big Ben, ovvero la Clock Tower, che ospita quello che è considerato l'orologio più preciso al mondo. Dietro al Palazzo del Parlamento potrete trovare la famosa Abbazia di Westminster, o Westminster Abbey, una delle più belle di Londra. Questo è il luogo in cui si tengono le più importanti celebrazioni, come l'incoronazione di re e regine."
           },
           {
              "link":"https://www.getyourguide.it/london-eye-l2711/coca-cola-london-eye-t170451/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "title":"London Eye",
              "description":"Una delle ruote panoramiche più grandi al mondo. Le grandi cabine arrivano fino a 135 m d'altezza, regalando una vista a 360° su Londra, avrete davvero la città e il Tamigi sotto i vostri piedi. Il giro completo dura circa 30 min.",
              "id":2,
              "img":"https://www.tourlondra.com/images/biglietti-london-eye/biglietti-london-eye.jpg",
              "metro":"Metro fermate Waterloo Station, linea Bakerloo, Jubilee, Northern"
           },
           {
              "img":"https://www.lecosmopolite.it/wp-content/uploads/2022/12/visitare-buckingham-palace-a-londra.jpg",
              "id":3,
              "metro":"Metro fermate St. James's Park, linea Circle e District Line",
              "link":"https://www.getyourguide.it/londra-l57/biglietto-appartamenti-di-stato-di-buckingham-palace-t53844/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Si tratta della residenza della monarchia inglese e si trova alla fine del The Mall, la famosa via che collega il palazzo a Trafalgar Square. Alle 10:45 fate in modo di trovarvi presso Buckingham Palace, potrete assistere al cambio della Guardia di Sua Maestà, un rito che avviene tutti i giorni fin dal 1660.",
              "title":"Buckingham Palace"
           },
           {
              "metro":"",
              "link":"",
              "id":"4",
              "title":"Piccadilly Circus",
              "description":"L'incrocio più famoso della capitale inglese. Si trova nel cuore della città ed è anche facilmente raggiungibile grazie alla metro, che ferma proprio alla Piccadilly Circus Station. Da qui potrete inoltre facilmente raggiungere Soho, uno dei quartieri più famosi di Londra. Si tratta del fulcro della vita notturna della città, vivace sia di giorno che di notte.",
              "img":"https://res.cloudinary.com/thrillophilia/image/upload/c_fill,f_auto,fl_progressive.strip_profile,g_auto,h_600,q_auto,w_auto/v1/filestore/z4py112seg62ikjvsozdvtr8wpcm_1580213381_shutterstock_1186647259.jpg"
           },
           {
              "title":"Natural History Museum",
              "metro":"Metro fermate South Kensington, linea Circle e District Line",
              "img":"https://www.atelier-brueckner.com/sites/default/files/styles/width-1440/public/gallery/oslo_natural_history_museum_norwegen_rex_dinosauries_steine_schichten_erdreich_naturkund_museum_fuer_experten_und_familien_atelier-brueckner_marcus-sies_300dpi-8.jpg?itok=_3E9INAB",
              "id":5,
              "description":"Uno dei musei più belli e famosi di Londra: il Natural History Museum, ovvero il museo di storia naturale della città. Costruito tra il 1873 e il 1880, inizialmente il Natural History Museum doveva solamente accogliere una collezione di scheletri, fossili e piante del British Museum. Ma, con il passare degli anni, il museo si ampliò, assorbendo anche il Museo Geologico che si trovava nelle vicinanze, diventando poi così una delle attrazioni più visitate della capitale inglese. La magia inizia già varcando la porta d'ingresso: la hall ospita gli scheletri di alcuni animali di grande dimensioni, come il dinosauro diplodocus e il mastodonte. Da qui inizia poi una meravigliosa collezione dedicata alla vita sulla terra attraverso i millenni.",
              "link":"https://www.getyourguide.it/londra-l57/londra-natural-history-museum-con-accesso-prioritario-t135956/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
           },
           {
              "description":"Camden Town è il quartiere hipster e alternativo di Londra. Preparatevi ad un'atmosfera davvero unica: qui i locali sono ospitati all'interno di vecchi container recuperati, le pareti degli edifici presentano numerosi murales e sculture particolari e le vie sono ricche di negozietti e boteghe unici, che vendono davvero di tutto, da oggetti vintage a prodotti di design.  Godetevi qualche ora in questo quartiere, dedicatevi ad un po' di shopping alla ricerca del souvenir perfetto e non perdetevi il Camden Market, il meraviglioso mercato del quartiere, attivo ogni giorno con tantissime bancarelle.",
              "title":"Camden Town",
              "metro":"Metro fermata Camden Town, linea Northern",
              "id":6,
              "img":"https://images.squarespace-cdn.com/content/v1/57d58992f7e0ab316f4c6515/1475933649061-7KZ0X0XFE2SD3RKCD60O/image-asset.jpeg",
              "link":""
           },
           {
              "img":"https://images.unsplash.com/photo-1630208208637-d553a59d1632?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHlkZSUyMHBhcmt8ZW58MHx8MHx8&w=1000&q=80",
              "title":"Hyde Park",
              "metro":"Metro fermata  Hyde Park Corner, linea Piccadilly",
              "link":"",
              "id":7,
              "description":"Si tratta del luogo ideale dove prendersi una pausa tra una visita culturale e l'altra, soprattutto nelle belle giornate soleggiate. Hyde Park è uno degli otto parchi reali di Londra e si estende per ben 124 ettari. E' molto amato dai londinesi, che vengono qui per rilassarsi lontano dal traffico e dalla vita frenetica della città, trascorrendo qualche ora in famiglia o per fare sport."
           },
           {
              "link":"https://www.getyourguide.it/londra-l57/biglietti-per-la-visita-di-kensington-palace-t21251/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Il Kensington Palace è una delle più importanti residenze reali inglesi di Londra. Oggi parte del palazzo è aperto al pubblico, un'occasione davvero unica. Pensate che questo luogo è ricco di storia: qui nacque la Regina Vittoria nel 1819, venne battezzata nella Stanza della Cupola e rimase nel palazzo fino al trasferimento a Buckingham Palace.",
              "title":"Kensington Palace",
              "metro":"Metro fermata High Street Kensington, linea Circle e District Line",
              "id":8,
              "img":"https://www.baglionihotels.com/wp-content/uploads/2019/03/Cupola_Room_Kensington_Palace_800x500.jpg"
           },
           {
              "img":"https://mywowo.net/media/images/cache/londra_madame_tussaud_01_introduzione_jpg_1200_630_cover_85.jpg",
              "title":"Madame Tussauds",
              "metro":"Metro fermata Baker Street Station, linee Bakerloo, Circle",
              "description":"Il Madame Tussauds è il più famoso museo delle cere del mondo. Nnacque nel lontano 1835, quando la scultrice Marie Tussaud espose le prime statue di cera. Fu un successo e, dal 1884, le statue vennero trasferite nel museo in cui si trovano ancora oggi. Qui le statue hanno una qualità altissima. Tantissime le sezioni, ma sicuramente imperdibile la sala della Famiglia Reale, dove potrete scattarvi una foto con la statua della Regina Elisabetta",
              "id":9,
              "link":"https://www.getyourguide.it/londra-l57/madame-tussauds-di-londra-t174429/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
           }
        ],
    },
    {
        name: 'parigi',
        guide: '5',
        views: '601',
        img: 'https://media.cntraveler.com/photos/5a93281d8087c02669a7dc07/16:9/w_2560,c_limit/Arc%20de%20Triomphe_GettyImages-615063063.jpg',
        desc: "Parigi, capitale della Francia, è una delle città più importanti d'Europa, centro mondiale di arte, moda, gastronomia e cultura. L'architettura urbana risalente al XIX secolo è caratterizzata dagli ampi boulevard e dalla Senna. Oltre a monumenti come la Torre Eiffel e la cattedrale gotica di Notre-Dame, del XII secolo, la città è rinomata per i tradizionali caffè e per i negozi di alta moda.",
        attractions:[
           {
              "img":"https://www.vivaparigi.com/wp-content/uploads/2017/11/torre-eiffel.jpg",
              "link":"https://www.getyourguide.it/parigi-l16/torre-eiffel-accesso-prioritario-al-2deg-piano-o-vertice-t403969/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "title":"Tour Effiel",
              "description":"Il simbolo di Parigi, realizzata con 18.038 pezzi di metallo e terminata il 31 marzo 1889. Destinata a dover essere distrutta dopo soli due anni, ha resistito e rimane una delle attrazioni più visitate della città. La torre Eiffel dispone al suo interno anche di ristoranti e bar, per rendere ancora più gradevole la vostra visita.",
              "id":0,
              "metro":"Metro Linea C, fermata Champs de Mars"
           },
           {
              "link":"https://www.getyourguide.it/parigi-l16/parigi-biglietti-per-il-tetto-dell-arco-di-trionfo-t66157/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "id":1,
              "metro":"Metro linee della metro 1, 2 e 6, fermata Charles de Gaulle",
              "img":"https://media.cntraveler.com/photos/5a93281d8087c02669a7dc07/16:9/w_2560,c_limit/Arc%20de%20Triomphe_GettyImages-615063063.jpg",
              "description":"monumento voluto da Napoleone Bonaparte per celebrare le proprie gesta militari, in particolare la vittoria nella battaglia di Austerlitz. Presso l'Arco c'è un piccolo museo che custodisce alcuni oggetti risalenti al periodo della prima guerra mondiale e altri appartenuti a Napoleone. Dalla cima dell'arco si può godere di un bellissimo panorama sulla città. Inoltre, è un ottimo punto di partenza per una passeggiata romantica sugli Champs Elysées.",
              "title":"Arc de Triomphe"
           },
           {
              "link":"https://www.getyourguide.it/parigi-l16/parigi-ingresso-prioritario-al-museo-del-louvre-t145779/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "id":2,
              "img":"https://api-www.louvre.fr/sites/default/files/2021-01/cour-napoleon-et-pyramide_1.jpg",
              "description":"Celebre per ospitare La Gioconda, il Louvre ospita di tutto e servirebbe più di una giornata per goderselo, quindi è consigliato scegliere con anticipo a quali settori dedicarsi. Il Palazzo del Re che ospita il museo è di per sé un'opera architettonica di immenso valore; la piramide di vetro che segna l'accesso alle biglietterie si sposa perfettamente nell'ambientazione storica che la circonda.  Il Louvre racchiude opere pittoriche e scultoree di immenso valore.",
              "title":"Louvre",
              "metro":"Metro linea 1, fermata Palais-Royal/musée du Louvre"
           },
           {
              "link":"",
              "title":"Notre-Dame",
              "description":"Per costruire questa cattedrale sono stati cercati e reperiti soldi in tutta Parigi! Custodisce importanti reliquie per il credo cristiano, tra cui un presunto chiodo appartenuto alla croce dove morì Gesù e la sua corona di spine.",
              "id":3,
              "img":"https://www.focusjunior.it/content/uploads/2019/04/notredampixaba4.jpg",
              "metro":"Metro linea 1, 4, 7, 10 e 11"
           },
           {
              "link":"",
              "metro":"Metro linee 1,8,9 e 12",
              "img":"https://www.ecologica.online/wp-content/uploads/2021/01/view-on-avenue-TJ447M3-scaled.jpg",
              "title":"Champs Elysées",
              "id":4,
              "description":"La passeggiata più famosa d'Europa; si tratta di un lungo viale costeggiato da giardini, ristoranti e negozi di lusso. Questo viale resta il simbolo del bel mondo parigino e dell'eleganza della città, soprattutto grazie alla scelta stilistica delle costruzioni che vi si affacciano."
           },
           {
              "description":"La Reggia di Versailles è uno dei palazzi più belli e più grandi al mondo. Voluta da Re Sole Luigi XIV per potersi rifugiare dalle presunte congiure che i nobili parigini avrebbero organizzato alle sue spalle.",
              "metro":"Metro linea C5, fermata Versailles Rive Gauche",
              "id":5,
              "title":"Versailles",
              "link":"https://www.getyourguide.it/parigi-l16/pass-da-1-o-2-giorni-per-la-reggia-di-versailles-t59565/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "img":"https://assets.gocity.com/files/paris_pass/files/styles/crop_freeform/public/blog/new2mp.jpg"
           },
           {
              "description":"L'edificio ha una perfetta architettura ecclesiastica ed era dedicato a Santa Genoveffa, patrona di Parigi. Venne laicizzato dopo la Rivoluzione diventando un edificio patriottico che accoglie il riposo di Voltaire, Rousseau, Zola, Pierre e Marie Curie e altri personaggi di spicco. Dalla cupola si gode di un notevole panorama a 360° sulla città.",
              "metro":"",
              "img":"https://ohlalafrenchfanfan.com/wp-content/uploads/2022/11/Ohlala-by-french-fanfan-facade-cololles-pantheon-de-paris-1024x988.jpg",
              "id":6,
              "title":"Pantheon",
              "link":""
           },
           {
              "id":7,
              "img":"https://www.vivaparigi.com/wp-content/uploads/2018/04/quartiere-latino-parigi.jpg",
              "link":"",
              "description":"La zona è tipicamente francese e molto attiva e vivace. Si sprecano i locali e le caffetterie aperti 24 ore su 24, tutto l'anno, frequentati da studenti e insegnanti della vicina università Sorbone. Anche se molte zone del quartiere sono tipicamente turistiche, qui potrete visitare anche il Pantheon.",
              "title":"Quartiere Latino",
              "metro":"Metro linee 4 e 10"
           },
           {
              "img":"https://img.itinari.com/pages/images/original/4db93621-1ae1-449d-bf13-788f6e72334a-istock-514769980_super.jpg?ch=DPR&dpr=2.625&w=1600&s=b49b012a96326f9ff8c609886789d2ed",
              "description":"Un rigoglioso giardino pubblico nel cuore della città. Oltre a essere una vera e propria esplosione di vegetazione all'interno di Parigi, il Giardino di Lussemburgo presenta monumenti, come la celebre fontana dei Medici, la statua della Libertà, il carosello progettato da Garnier e il busto di Baudelaire. Passeggiare per il giardino più grande della città è un modo piacevole per rigenerarsi dalla frenesia parigina in qualsiasi stagione.",
              "id":8,
              "link":"",
              "title":"Jardin du Luxembourg",
              "metro":""
           }
        ]
    },
    {
        name: 'lisbona',
        guide: '6',
        views: '387',
        img: 'http://www.turismoitalianews.it/images/stories/portogallo/Lisbona_Spiagge_Sintra_Azenhas-do-Mar01.jpg',
        desc: "La capitale del Portogallo, Lisbona, sorge sulla costa ed è caratterizzata da un territorio collinare. Dall'imponente castello di São Jorge, il panorama si allarga sugli edifici in tinte pastello della città vecchia. Appena fuori Lisbona si trova la striscia delle spiagge atlantiche, che va da Cascais a Estoril.",
        attractions:[
           {
              "link":"https://www.getyourguide.it/lisbona-l42/lisbona-card-valida-24-48-e-72-ore-t225711/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Fuori dal centro, sul fiume Tago si erge questa tozza torre, uno dei monumenti simbolo di Lisbona: è da qui che partivano le spedizioni dei grandi navigatori verso l'ignoto. La Torre di Belém venne utilizzata come faro e fortezza, oggi è Patrimonio dell'Unesco.  La torre è visitabile all'interno seppur non si presenta particolarmente decorata. Per entrare si attraversa il suggestivo ponte levatoio. All'interno si compone di 3 piani con diverse stanze, oltre ad una cappella e una terrazza panoramica.",
              "metro":"Metro linea de Cascais, fermata Belem",
              "img":"https://www.ilviaggiochetimanca.com/media/cache/photo/uploads/photos/torre-di-belem-lisbona-portogallo.jpeg",
              "title":"Torre di Belem",
              "id":0
           },
           {
              "id":1,
              "metro":"Tram 28 e scendere alla fermata Limoeiro",
              "link":"",
              "img":"https://www.portugal-vakantie.info/images/lissabon/tram-28/tram-28-alfama-kathedraal.jpg",
              "title":"La Sè - Cattedrale",
              "description":"La cattedrale di Lisbona, meglio nota come La Sè, ha una struttura imponente, quasi una fortezza ed è stata costruita dove una volta c'era una vecchia moschea. All'interno si possono vedere dei resti romani, arabi e medievali, rinvenuti negli ultimi anni; nella parte superiore della Cattedrale è invece custodito il Tesoro: vesti, gioielli e reliquie di differenti epoche."
           },
           {
              "link":"https://www.getyourguide.it/lisbona-l42/accesso-prioritario-al-monastero-dos-jeronimos-t131169/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top",
              "description":"Il più importante monumento di Lisbona, fu eretto nel 1505 in occasione delle celebrazioni delle imprese dell'esploratore portoghese Vasco de Gama, di ritorno dalle sue navigazioni grazie alle quali scoprì la rotta per l'India. Patrimonio dell'UNESCO. ",
              "title":"Mosteiro dos Jerònimos",
              "img":"https://www.viaggiandoconluca.it/wp-content/uploads/2017/02/Cosa-vedere-a-Lisbona-il-Monastero-dos-Jeronimos-5.jpg",
              "metro":"Tram 15",
              "id":2
           },
           {
              "description":"L'aspetto è di un imponente ed antico castello fortificato, il Castello Sao Jorge si trova infatti in cima ad una collina e sovrasta la città da circa 2000 anni. Inizialmente l'area del castello era tutta quanta circondata da un muro, che delimitava tutta la cittadella, il Castello al suo interno era quindi l'ultimo baluardo difensivo in caso di irruzione nella cittadella. Dalle sue torri e dal belvedere che si può godere del vero spettacolo, una incantevole vista su tutta la città, migliore rispetto a tutti i mirador di Lisbona. Molto belli anche i giardini del castello, un'oasi di verde nella città piena di pavoni e specie native del Portogallo. Date un'occhiata alla parete nord delle mura: qui si trova la Porta del Tradimento! si tratta di un accesso usato da messaggeri che portavano informazioni segrete.",
              "id":3,
              "title":"Castello Sao Jorge",
              "metro":"Tram 12 e 28, fermata Miradouro Sta",
              "img":"https://www.latitudeslife.com/wp-content/uploads/lisbona-castello-sao-jorge-shutterstock_664127884.jpg",
              "link":"https://www.getyourguide.it/lisbona-l42/lisbona-ingresso-prioritario-al-castello-di-san-giorgio-t164151/?partner_id=7Y849ZC&utm_medium=travel_agent&placement=content-top"
           },
           {
              "description":"Tributo ai protagonisti delle esplorazioni marinare è una gigantesca scultura che rappresenta una caravella in pietra bianca. Scopo è commemorare l'epoca d'oro della storia nazionale.",
              "img":"https://www.vivilisbona.it/images/vivilisbona/gallery_monumento_scoperte/Monumento-alle-Scoperte-e-Astrolabio.jpg",
              "metro":"Accanto alla Torre di Belem.",
              "link":"",
              "id":"4",
              "title":"Monumento alle Scoperte"
           },
           {
              "id":5,
              "metro":"Metro fermate Baixa-Chiado o Rossio",
              "description":"Un'attrazione davvero affascinante, il complesso del Convento do Carmo comprende un monastero romanico e l'omonima chiesa; entrambi risalgono al 1398 e furono profondamente danneggiati dal terremoto del 1755. La Chiesa è stata volutamente non ristrutturata, infatti il tetto crollato nel 1755 non è mai stato ricostruito. ",
              "link":"",
              "title":"Convento do Carmo",
              "img":"https://travel.thewom.it/content/uploads/sites/4/2022/09/convento-do-carmo_1.jpeg"
           },
           {
              "id":6,
              "description":"Una delle piazze più grandi e più suggestive d'Europa, Risultato di eleganti simmetrie e virtuosismi neoclassici, nonostante il nome è un'ampia zona pedonale che rappresenta il simbolo della ristrutturazione della Baixa, uno dei quartieri storici di Lisbona.  Di forma quadrata, misura 170 m x 170 m, tre lati sono fiancheggiati da palazzi signorili, mentre uno è aperto verso il fiume Tago; vicinissima al porto principale, la piazza è stata per secoli la principale porta d'accesso alla città.",
              "metro":"",
              "link":"",
              "title":"Praça do Comércio",
              "img":"https://media.cntraveler.com/photos/5bce1d5f06957f77f66121c7/16:9/w_2560,c_limit/Prac%CC%A7a%20do%20Come%CC%81rcio_GettyImages-655529048.jpg"
           },
           {
              "img":"https://images.squarespace-cdn.com/content/v1/587860113e00be246e9fa173/1564654719210-9CUN51Z5NE0UI6L5M6Y5/sunrise-miradouro-santa-luzia-portugal-lisbon-lisboa-view-bougainvillea-sea-view-ocean-coast.jpg",
              "title":"Miradouro de Santa Luzia",
              "metro":"Tram 12 e 28, fermata Miradouro Sta. Luzia",
              "id":7,
              "description":"Lisbona è famosa anche per i suoi Miradoures, magnifiche terrazze dislocate in vari punti della città da cui ammirare la splendida vista sul mare e sui tetti rossi della parte bassa della città: il più famoso, il più ricercato e quindi più affollato dai turisti che vi si riversano per una foto ricordo è il Miradouor de Sant Luzia, una vera e propria terrazza panoramica sulla città che si trova appena sotto al Castello de Sao Jorge.",
              "link":""
           },
           {
              "title":"Chiesa di Santa Engrácia",
              "id":8,
              "img":"https://img.freepik.com/premium-photo/national-pantheon-church-santa-engracia-is-17th-century-monument-lisbon-portugal_78361-5576.jpg",
              "metro":"",
              "description":"Questo monumento barocco imponente conserva le spoglie dei più illustri cittadini portoghesi. Costruito nel secolo XVI, inizialmente come Chiesa di Santa Engrácia, dall'infanta Dona Maria, figlia del re Dom Manuel I, la chiesa non fu mai dedicata al culto. I lavori durarono 350 anni e vennero conclusi soltanto a metà del secolo XX. Oggi l'edificio, aperto nella parte superiore, è adibito a Pantheon nazionale.",
              "link":""
           },
           {
              "title":"Tram",
              "description":"A Lisbona anche i mezzi pubblici sono un'attrazione: il tram, mezzo più comodo per girare in città, è leggendario, per la sua capacità di affrontare la pendenza di vicoli; esiste una apposita linea turistica, il Tram 28, che permette di vedere tutto il caratteristico quartiere dell'Alfama. Il costo dipende dalle tratte, parte da 3,50€.",
              "metro":"",
              "id":9,
              "link":"",
              "img":"https://a.travel-assets.com/findyours-php/viewfinder/images/res40/36000/36266.jpg"
           },
           {
              "metro":"Treno da Cais do Sodré per Cascais",
              "title":"Spiagge",
              "link":"",
              "id":10,
              "description":"Con chilometri di bellissime spiagge affacciate sull'oceano, Lisbona è una delle mete irrinunciabili per andare al mare e godersi il sole. Le spiagge più famose sono Praia de Carcavelos, Praia da Rainha e Praia do Tamariz; queste distano dalla città circa 20 minuti di treno.",
              "img":"https://blog.zingarate.com/viverealisbona/wp-content/uploads/2017/04/dove-andare-al-mare-a-lisbona-5.jpg"
           }
        ]
    }
];

export default guide;