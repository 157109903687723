import {actions} from './actions';

const reducer = (state={}, data ) => {
    
    switch (data.type) {
        case actions.LAST_OFFERS_DAY: 
            return {...state, lastOffersDate: data.day};

        case actions.LAST_OFFERS:
            return {...state, lastOffers: data.offers};
        
        default:
            return state;
    }
}

export default reducer;