import './guideCards.css';
import {useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import views from '../../assets/views.png';

function GuideCards({guideDetail}) {

    const navigate = useNavigate();

    const openLink = ()=> {
        if (guideDetail.guide) {
            navigate(`/guide/${guideDetail.guide}`);
        }
    }

    return(
        <Card className='mb-3 shadow' style={{'borderRadius': '10px'}}>
          <Card.Img variant="top" src={guideDetail.img} style={{'borderTopRightRadius': '10px', 'borderTopLeftRadius': '10px', 'height': '90px', 'objectFit': 'cover'}}/>
            <Card.Body>
              <Card.Title>
                <div className='cardGuideTitle'>
                    {guideDetail.name}
                </div>
              </Card.Title>
              <Card.Text className='guideDesc'>
                {guideDetail.desc}
              </Card.Text>
              <div className='w-100 f_sb'>
                <div style={{'display': 'flex', 'alignItems': 'center'}}>
                  <img className='imgw16' src={views} alt="views" style={{'opacity': '80%'}}/>
                  <div className='viewsTxt'>{guideDetail.views}</div>
                </div>
                <Button className='offerBtn' size='sm' onClick={openLink}>Sfoglia</Button>
              </div>
            </Card.Body>
        </Card>
    )
}

export default GuideCards;