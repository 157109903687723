import './guideSection.css';
import Accordion from 'react-bootstrap/Accordion';
import link from '../../assets/link.png';
import {useState} from 'react';
import bus from '../../assets/bus.png';

function GuideSection({att, openSection, changeSection}) {

    const [thereIsImg, setThereIsImg] = useState(true);

    const goToLink = (link)=> {
        window.open(link, '_blank');
    }

    return (
        <div className='w85 mt-3'>
            <Accordion activeKey={openSection} defaultActiveKey={openSection} onClick={()=>{changeSection(att.id)}}>
              <Accordion.Item eventKey ={att.id}>
                <Accordion.Header>
                    <div className='flx-sb w-100'>
                        <div>
                            <span className='offerTxt att_title'>{att.title}</span>
                        </div>
                        <div className='me-2'>
                            {
                                att.link ? 
                                <span className='smallLabel' onClick={()=>{goToLink(att.link)}}>
                                    <img className='linkimg' src={link} alt="link" />
                                </span>
                                :
                                <div></div>
                            }
                            
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className='a_body'>
                  {
                    thereIsImg ? 
                    <img src={att.img} alt="img" className='attimg' onError={()=>{setThereIsImg(false)}}/>
                    :
                    <div></div>
                  }
                  
                  <div className='att_description mt-2'>{att.description}</div>
                  {
                    att.metro ? 
                    <div className='att_description mt-2 w-100'><img src={bus} alt="bus_icon" className='linkimg'/> {att.metro}</div>
                    :
                    <div></div>
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default GuideSection;