import './notfound.css';
import NoResult from '../../assets/noResult.png';
import LandScape from '../../assets/landscapeLogo.png';
import logo from '../../assets/whiteLogoFH.png';
import { useNavigate } from "react-router-dom";
import ShareSocialPage from '../shareSocialPage/shareSocialPage';

function NotFound () {

    const navigate = useNavigate();

    return(
        <>
        {
            window.innerWidth > 900 ?
            <div></div>
            :
            <div className='relative'>
                <img className='imgw18' style={{position: 'absolute', left: '5%', top: '5%', width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                <img src={LandScape} alt="Logo" className='landw'/>
            </div>
        }  
            <div className="container">
                <div className='messageGYG mt2'>
                    Ci dispiace Hunter
                    <br />
                    l'offerta che cercavi non è più disponibile!
                </div>
                <img src={NoResult} alt="noresult" className='nrw mt2'/>
                <div className='messageGYG fs16 mt2'>
                    Seguici sui nostri canali social per non perderne più una!
                    <div className='mt2'>
                        <ShareSocialPage />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;