import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {useEffect} from 'react';

function DynamicToast({open, text, close}) {

  useEffect(()=> {
    if(open) {
      window.scrollTo(0, 0);
    }
  }, [open])

    return (
        <ToastContainer className="p-3" position={'bottom-center'}>
          <Toast show={open} onClose={() => close(false)} delay={3500} autohide>
            <Toast.Header closeButton={false}>
                {text}
            </Toast.Header>
          </Toast>
        </ToastContainer>
    )
}

export default DynamicToast;