import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Offers from './components/offers/offers';
import CatchOffer from './components/catchOffer/catchOffer';
import NotFound from './components/notfound/notfound';
import Home from './components/home/home';
import Widget from './components/widget/widget';
import SearchResults from './components/searchResults/searchResults';
import Guide from './components/guide/guide';
import CatchGuide from './components/catchGuide/catchGuide';
import Recommended from './components/recommended/recommended';
import SearchGuide from './components/searchGuide/searchGuide';
import Links from './components/links/links';
import SearchLink from './components/searchLink/searchLink';
import PlayAndWin from './components/playAndWin/plaAndWin';
import { Provider } from 'react-redux';
import store from './stateManager/store';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  const helmetContext = {};

  /*TODO DA METTERE SOLO IN PROD*/
  /*INIZIO DISABILITAZIONE ISPEZIONA*/
  // const handelRightClick = (event) => {
  //   event.preventDefault();
  // }

  // document.addEventListener('contextmenu', handelRightClick);

  // document.onkeydown = function (event) {
  //   if (event.keyCode == 123) { // Prevent F12
  //     return false;
  //   }
  //   else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
  //     return false;
  //   }
  // };
  /*FINE DISABILITAZIONE ISPEZIONA*/

  return (
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <div className="App">
          <header className="App-header">
            <Router>
              <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={"/offers/:id"} element={<CatchOffer/>} />
                <Route path={"/offers"} element={<Offers/>}/>
                <Route path={"/searchResults"} element={<SearchResults/>}/>
                <Route path={"/guide/:id"} element={<CatchGuide/>}/>
                <Route path={"/guide"} element={<Guide/>}/>
                <Route path={"/recommended/:id"} element={<Recommended/>}/>
                <Route path={"/searchGuide"} element={<SearchGuide/>}/>
                <Route path={"/search/:data"} element={<SearchLink/>}/>
                <Route path={"/links"} element={<Links/>}/>
                <Route path={"/play"} element={<PlayAndWin/>}/>
                <Route path={"/notfound"} element={<NotFound/>}/>
                {/* <Route path={"/widget"} element={<Widget/>}/> */}
                <Route path={"*"} element={<NotFound/>}/>
              </Routes>
            </Router> 
          </header>
        </div>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
