import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDLr_Hx_OEYuatYDi6memP87ksYF4ZeX7w",
  authDomain: "fh-offers.firebaseapp.com",
  projectId: "fh-offers",
  storageBucket: "fh-offers.appspot.com",
  messagingSenderId: "97694013276",
  appId: "1:97694013276:web:503d06f603655bbc259399",
  measurementId: "G-33GF1FC3HL"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = ()=> {
  signInWithPopup(auth, googleProvider)
  .then((res)=>{
    console.log(res)
  })
  .catch((err)=>{
    console.log(err)
  })
}

export {db}