import Sheet from 'react-modal-sheet';
import {useState, useEffect} from 'react';
import directFlight from '../../assets/directFlight.png';
import noDirectFlight from '../../assets/noDirectFlight.png';
import './details.css';
import date from '../../assets/date.png';
import seat from '../../assets/seat.png';
import info from '../../assets/info.png';
import correct from '../../assets/true.png';
import notCorrect from '../../assets/false.png';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import shareImg from '../../assets/share.png';
import Share from '../share/share';
import closeImg from '../../assets/close.png';
import dragbottom from '../../assets/dragbottom.png';
import dragtop from '../../assets/dragtop.png';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import newwindows from '../../assets/newwindows.png';
import bookingLogo from '../../assets/booking.png';

function Details({open, detail, offerId, closeSheetCard, bookingId}) {

    const [openDetail, setOpenDetail] = useState(false);
    const [durataAndata, setDurataAndata] = useState();
    const [durataRitorno, setDurataRitorno] = useState();
    const [openShare, setOpenShare] = useState(false);
    const [snap, setSnap] = useState(0);
    const [loader, setLoader] = useState(false);
    const [bookingUrl, setBookingUrl] = useState();

    useEffect(()=>{
      if (bookingId) {
        setBookingUrl(`https://www.booking.com/searchresults.it.html?city=${bookingId}&aid=7924785&checkin_monthday=${detail.date.departure.from.slice(0,2)}&checkin_month=${detail.date.departure.from.slice(3,5)}&checkin_year=${detail.date.departure.from.slice(6,10)}&checkout_monthday=${detail.date.return.from.slice(0,2)}&checkout_month=${detail.date.return.from.slice(3,5)}&checkout_year=${detail.date.return.from.slice(6,10)}&no_rooms=1&group_adults=2&room1=A%2CA&label=FlightsHunter`)
      }
    },[])

    useEffect(()=>{
        setOpenDetail(open);
    }, [open])

    useEffect(()=>{
      setDurataVoli();
  }, [detail])

  const setDurataVoli = ()=> {
    if (detail.outbound) {
      setDurataAndata(convertMsToHM(new Date(detail.outbound.arrivalDate) - new Date(detail.outbound.departureDate)));
    } else {
      setDurataAndata(convertMsToHM(new Date(detail.route[0].local_arrival) - new Date(detail.route[0].local_departure)));
    }

    if (detail.inbound) {
      setDurataRitorno(convertMsToHM(new Date(detail.inbound.arrivalDate) - new Date(detail.inbound.departureDate)));
    } else {
      setDurataRitorno(convertMsToHM(new Date(detail.route[1].local_arrival) - new Date(detail.route[1].local_departure)));
    }

    
  }

    const openLink = ()=>{
      if (!detail.isAviaSales) {
        if (detail.deep_link.substring(0, 5) === 'https') {
          window.open(detail.deep_link, "_blank");
        } else {
          window.open('https://'+detail.deep_link, "_blank");
        }
      } else if (detail.isAviaSales) {
        createASLink(detail);
      }
    }

    const createASLink = (detail) => {
      setLoader(true);
      axios.post('https://thriving-panda-73b004.netlify.app/.netlify/functions/api/asl',
        {
          searchId: detail.searchId,
          termsUrl: detail.termsUrl
        },
        {headers: {
            "content-type": "application/json",
          }}
        )
        .then((response) => {
          window.open(response.data.url, "_blank");
          setLoader(false);
        })

      // axios.get('https://api.travelpayouts.com/v1/flight_searches/' + detail.searchId + '/clicks/' + detail.termsUrl + '.json',
      // {headers: { Authorization: 'Basic'}})
      //   .then((response) => {
      //     window.open(response.data.url, "_blank");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })
    }

    const closeSheet = ()=> {
      setOpenDetail(false);
      closeSheetCard(false);
      setSnap(0)
    }

    const padTo2Digits = (num, isMinutes) => {
      if (isMinutes) {
        return num.toString().padStart(2, '0');
      } else {
        return num.toString();
      }
    }

  const convertMsToHM = (millisecond)=> {
    let seconds = Math.floor(millisecond / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;

    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    hours = hours % 24;

    return `${padTo2Digits(hours, false)} h ${padTo2Digits(minutes, true)} m`;
  }

  const closeShare = (e) => {
    setBookingUrl('');
    setOpenShare(e);
  }

  const openBooking = () => {
    window.open(bookingUrl, '_blank');
  }


    return(
        <>
          {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            <Sheet
              isOpen={openDetail} onClose={closeSheet}
              snapPoints={[600, 100]}
              initialSnap={snap}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                  {
                    snap == 0 ? 
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={()=>setSnap(1)}/>
                    :
                    <img src={dragtop} alt="drag top" style={{'width': '18px'}} onClick={()=>setSnap(0)}/>
                  }
                  <img src={closeImg} onClick={closeSheet} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                    <div className='headerContainer'>
                      <Col>                        
                        <div className='headerTxt'>{detail.cityFrom}</div>
                      </Col>
                      <Col>
                      {
                        !detail.isAviaSales && detail.route.length > 2 ?
                        <img src={noDirectFlight} alt="directFlight" className='imgsize'/>
                        :
                        detail.isAviaSales && detail.segment.length > 2 ?
                        <img src={noDirectFlight} alt="directFlight" className='imgsize'/>
                        :
                        <img src={directFlight} alt="directFlight" className='imgsize'/>
                      }
                      </Col>
                      <Col>
                        <div className='headerTxt'>{detail.cityTo}</div>
                      </Col>
                    </div>
                    <div className='scalo'>
                      {
                        !detail.isAviaSales && detail.route.length == 4 ?
                        <div>1 scalo per tratta</div>
                        :
                        !detail.isAviaSales && (detail.route.length > 2 && detail.route.length !== 4) ?
                        <div>voli con scali</div>
                        :
                        detail.isAviaSales && detail.segment.length == 4 ?
                        <div>1 scalo per tratta</div>
                        :  
                        detail.isAviaSales && (detail.segment.length > 2 && detail.segment.length !== 4) ?                               
                        <div>voli con scali</div>
                        :
                        <div></div>
                      }
                      </div>
                    <div>
                        <div className='flx'>
                            <img src={date} alt="dateAndHour" className='iconw'/>
                            <div className='divider'></div>
                        </div>
                        <div>
                            <Col className='mb-3'>
                              <div className='smallLabel'>dal:</div>
                              <div className='flx-b'>
                                <span>{detail.date.departure.from}</span>
                                <div>
                                  <span>{detail.date.depH.from} - {detail.date.depH.to}</span>
                                </div>
                              </div>
                            </Col>
                            <Col className='mb-3'>
                              <div className='smallLabel'>al:</div>
                              <div className='flx-b'>
                                <span>{detail.date.return.from}</span>
                                <div>
                                  <span>{detail.date.retH.from} - {detail.date.retH.to}</span>
                                </div>
                              </div>
                            </Col>
                        </div>
                    </div>
                    <div>
                        <div className='flx'>
                            <img src={seat} alt="dateAndHour" className='iconw'/>
                            <div className='divider'></div>
                        </div>
                        <div>
                          <Col className='mb-3'>
                            <div className='smallLabel'>Compagnia andata:</div>
                            <div>
                              {
                                detail.route[0].airline ?
                                  <div className='flx-b'>
                                    <span>{detail.route[0].airline.detail.name}</span>
                                    <span>N: {detail.route[0].airline.detail.iata}{detail.route[0].flight_no}</span>
                                  </div>
                                  :
                                  <div className='flx-b'>
                                    <span>Ryanair</span>
                                    <span>N: {detail.outbound.flightNumber}</span>
                                  </div>
                              } 
                            </div>
                          </Col>
                          <Col className='mb-3'>
                            <div className='smallLabel'>Compagnia ritorno:</div>
                            <div>
                              {
                                detail.route[1].airline ?
                                <div className='flx-b'>
                                  <span>{detail.route[1].airline.detail.name}</span>
                                  <span>N: {detail.route[1].airline.detail.iata}{detail.route[1].flight_no}</span>
                                </div>
                                :
                                <div className='flx-b'>
                                  <span>Ryanair</span>
                                  <span>N: {detail.outbound.flightNumber}</span>
                                </div>
                              }
                              
                            </div>
                          </Col>
                        </div>
                    </div>

                    <div>
                        <div className='flx'>
                            <img src={info} alt="dateAndHour" className='iconw'/>
                            <div className='divider'></div>
                        </div>
                        <div>
                          <Col>
                            <div className='smallLabel'>Informazioni:</div>
                          </Col>
                          <Col className='mb-2'>
                            <div className='flx-b'>
                              <span>Posti disp.</span>
                              {
                                detail.availability ? 
                                  <span>{detail.availability.seats}</span>
                                  :
                                  <>-</>
                              }
                            </div>
                          </Col>
                          <Col className='mb-2'>
                            <div className='flx-b'>
                              <span>Voli diretti</span>
                              {
                                !detail.isAviaSales && detail.route.length > 2 ?
                                <img src={notCorrect} alt="no"className='iconCorrect'/>
                                :
                                detail.isAviaSales && detail.segment.length > 2 ?
                                <img src={notCorrect} alt="no"className='iconCorrect'/>
                                :                                 
                                <img src={correct} alt="yes"className='iconCorrect'/>
                              }
                            </div>
                          </Col>
                          <Col className='mb-2'>
                            <div className='flx-b'>
                                <span>Durata viaggio</span>
                                <span>
                                  {
                                    detail.nightsInDest?
                                    <span>{detail.nightsInDest} notti</span>
                                    :
                                    <span>{detail.summary.tripDurationDays} giorni</span>
                                  }
                                </span>
                            </div>
                          </Col>
                          {
                            !detail.isAviaSales ?
                            <div>
                              <Col className='mb-2'>
                                <div className='flx-b'>
                                  <span>Durata volo andata</span>
                                  <span>
                                    {durataAndata}
                                    </span>
                                </div>
                              </Col>
                              <Col className='mb-2'>
                                <div className='flx-b'>
                                  <span>Durata volo ritorno</span>
                                  <span>
                                    {durataRitorno}
                                  </span>
                                </div>
                              </Col>
                              <Col className='mb-2'>
                                <div className='smallLabel12'>
                                  <span>*la durata dei voli potrebbe tenere conto del differente fuso orario tra città di partenza e di arrivo</span>
                                </div>
                              </Col>
                            </div>
                            :
                            <div></div>
                            }
                        </div>
                    </div>
                    
                    <div className='f-container'>
                      <div className='footerContainer'>
                        <Col style={{'textAlign': 'center'}}>
                          <img className='imgw18' src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/>
                        </Col>
                        <Col style={{'textAlign': 'center'}}>
                          <div className='priceTxt'>{detail.price} €</div>
                        </Col>
                        <Col style={{'textAlign': 'right'}}>
                          <Button className='offerBtn' onClick={openLink}>Vai al sito</Button>
                        </Col>
                      </div>
                      {
                        bookingId ? 
                        <div onClick={openBooking}>
                          <Col style={{'textAlign': 'center'}}>
                            <div style={{'whiteSpace': 'nowrap', 'color': 'darkslategrey', 'fontSize': '12px'}}>
                                Cerca Hotel su <img src={bookingLogo} alt="open" style={{'width': '90px'}}/> <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                            </div>
                          </Col>
                        </div>
                        : 
                        <div></div>
                      }
                      
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
            <Share open={openShare} closeShare={closeShare} daNome={detail.cityFrom} aNome={detail.cityTo} da={detail.flyFrom} a={detail.flyTo} price={detail.price} id={offerId} date={detail.date.departure.from.split('/').join('-') + '-' + detail.date.return.from.split('/').join('-')} isForFullOffer={false} isSearch={offerId ? false : true}/>
        </>
    )
}

export default Details;