import './links.css';
import logo from '../../assets/whiteLogoFH.png';
import bck from '../../assets/landscapecolor.png';
import {useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import webSiteIcon from '../../assets/website.png';
import facebook from '../../assets/facebook-icon.png';
import instagram from '../../assets/instagram-icon.png';
import telegram from '../../assets/telegram-icon.png';
import { useSelector } from "react-redux";
import { setLastOffersDay, saveLastOffers } from '../../stateManager/actions';
import {useEffect, useState} from 'react';
import {db} from '../../firebase-config';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import store from '../../stateManager/store';

function Links() {

    const navigate = useNavigate();
    const lastOffersDate = useSelector(state => state.lastOffersDate);
    const lastOffersStateManager = useSelector(state => state.lastOffers);

    const [lastOffers, setLastOffers] = useState([]);

    const links = [
        {name: 'Sito web', icon: webSiteIcon, url: 'https://www.flightshunter.it'},
        {name: 'Instagram', icon: instagram, url: 'https://www.instagram.com/flights.hunter/'},
        {name: 'Telegram', icon: telegram, url: 'https://t.me/FlightsHunterIt'},
        {name: 'Facebook', icon: facebook, url: 'https://www.facebook.com/profile.php?id=100066867267065'},
    ];

    useEffect(()=>{loadOffers();}, []);

    const loadOffers = async ()=>{
        if (enterLastOffers()) {
            let today = new Date();
            today.setHours(0,0,0,0);
            store.dispatch(setLastOffersDay(today));
            const docRef = doc(db, "lastOffers", 'offers');
            const docSnap = await getDoc(docRef);
            if (docSnap.data()) {
                setLastOffers(docSnap.data().last);
                store.dispatch(saveLastOffers(docSnap.data().last));
            }
        } else {
            setLastOffers(lastOffersStateManager);
        } 
    };

    const enterLastOffers = ()=> {
        let today = new Date();
        today.setHours(0,0,0,0);
        if (lastOffersDate && lastOffersStateManager) {
            lastOffersDate.setHours(0,0,0,0);
            if (today > lastOffersDate) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    return(
        <>
            <div className='mb-3 relative'>
                <div className='iconContainer'>
                    <img className='imgw18' style={{width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                    {/* <img className='imgw18' style={{width: '30px'}} src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/> */}
                </div>
                <img src={bck} alt="bck" className='bck_img'/>
                <div className='linksTitleContainer mt-5'>
                    <div className='fh'>Flightshunter</div>
                    {/* <div className='subtitle'>ultime offerte e link</div> */}
                </div>
            </div>
            {/* <div className='linkMessage w-85'>
                Segui i nostri social e non perdere più nessuna offerta!
            </div>
            <div className='linksContainer'>
                {
                    links.map((link, i)=>
                        <div className='cardContainer' key={i}>
                            <Card className='singleC'>
                              <Card.Body className='bodyC'>
                                <Col className='cnt' style={{'justifyContent' : 'flex-start'}}>
                                  <Card.Title className='mb-0 linkTitle'>{link.name}</Card.Title>
                                </Col>
                                <Col className='cnt' style={{'justifyContent' : 'center'}}>
                                    <img className='imgw18' style={{width: '36px'}} src={link.icon} alt={link.name}/>
                                </Col>
                                <Col className='cnt' style={{'justifyContent' : 'flex-end'}}>
                                  <Button className='offerBtn' onClick={()=>window.open(link.url, '_blank')}>Apri</Button>
                                </Col>
                              </Card.Body>
                            </Card>
                        </div> 
                    )
                } 
            </div>
            <div className='divider mt-1 mb-2'></div> */}
            
            <div>
                <div className='linkMessage w-85 container lastO_c'>
                    <span></span>
                    <span>Ultime offerte</span>
                    <span style={{'transform': 'rotate(90deg)'}}>{'>'}</span>
                </div>
                {
                    lastOffers
                    .map((offer, i)=>
                    <div className='cardContainer' key={i}>
                        <Card className='singleC'>
                            <Card.Img variant="top" src={offer.img} style={{'borderTopRightRadius': '10px', 'borderTopLeftRadius': '10px', 'height': '90px', 'objectFit': 'cover'}}/>
                          <Card.Body className='bodyC'>
                            <Col className='cnt' style={{'justifyContent' : 'flex-start'}}>
                              <Card.Title className='mb-0'>{offer.cityTo.name}</Card.Title>
                            </Col>
                            <Col className='cnt' style={{'justifyContent' : 'center'}}>
                              <span>da {offer.price}€</span>
                            </Col>
                            <Col className='cnt' style={{'justifyContent' : 'flex-end'}}>
                              <Button className='offerBtn' onClick={()=>navigate(`/offers/${offer.id}`)}>Vedi</Button>
                            </Col>
                          </Card.Body>
                        </Card>
                    </div>
                    )
                }
            </div>
            <div className='linkMenu'>
                {
                    links.map((link, i)=>
                        <div className='' key={i}>
                            <img className='imgw18' style={{width: '36px'}} src={link.icon} alt={link.name} onClick={()=>window.open(link.url, '_blank')}/>
                        </div> 
                    )
                }
            </div>
        </>
    )
}

export default Links;