import { useEffect } from 'react';

function Widget () {


    useEffect(()=> {
        window.open('https://fh-it-widget.netlify.app', '_self');
    }, [])

}

export default Widget;