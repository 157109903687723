import './share.css';
import Sheet from 'react-modal-sheet';
import facebook from '../../assets/facebook-icon.png';
import whatsapp from '../../assets/whatsapp-icon.png';
import telegram from '../../assets/telegram-icon.png';
import twitter from '../../assets/twitter.png';


function Share ({open, closeShare, isForFullOffer, daNome, aNome, da, a, price, id, isGuide, isSearch, date, isResult, searchType}) {

    const whatsappLink = isForFullOffer?
         `https://api.whatsapp.com/send?text=Guarda queste offerte per ${a} +%20https://www.flightshunter.it/offers/${id}`
        :
         `https://api.whatsapp.com/send?text=Guarda questa offerta da ${daNome} a ${aNome} a soli ${price}€ +%20https://www.flightshunter.it/offers/${id}`
    const telegramLink = `https://telegram.me/share/url?url=https://www.flightshunter.it/offers/${id}`;
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=https://www.flightshunter.it/offers/${id}`;
    const twitterLink = isForFullOffer?
         `http://twitter.com/share?text=Guarda queste offerte per ${a} &url=https://www.flightshunter.it/offers/${id}`
        :
         `http://twitter.com/share?text=Guarda questa offerta da ${daNome} a ${aNome} a soli ${price}€&url=https://www.flightshunter.it/offers/${id}`; // http://twitter.com/share?text=Iscriviti%20a%20FlightsHunter%20e%20rimani%20aggiornato%20sulle%20migliori%20offerte%20di%20voli%20lowcost&url=https://t.me/FlightsHunterIt

    const whatsappGenericLink = `https://api.whatsapp.com/send?text=Guarda quante offerte su +%20https://www.flightshunter.it`;
    const telegramGenericLink = `https://telegram.me/share/url?url=https://www.flightshunter.it`;
    const facebookGenericLink = `https://www.facebook.com/sharer/sharer.php?u=https://www.flightshunter.it`;
    const twitterGenericLink = `http://twitter.com/share?text=Guarda quante offerte su &url=https://www.flightshunter.it`;

    const whatsappGuideLink = `https://api.whatsapp.com/send?text=Guarda questa guida per ${a} +%20https://www.flightshunter.it/guide/${id}`;
    const telegramGuideLink = `https://telegram.me/share/url?url=https://www.flightshunter.it/guide/${id}`;
    const facebookGuideLink = `https://www.facebook.com/sharer/sharer.php?u=https://www.flightshunter.it/guide/${id}`;
    const twitterGuideLink = `http://twitter.com/share?text=Guarda questa guida per ${a} +%20https://www.flightshunter.it/guide/${id}`;

    const whatsappSearchLink = `https://api.whatsapp.com/send?text=Guarda questa offerta da ${daNome} per ${aNome} a ${price}€ +%20https://www.flightshunter.it/search/${da}-${a}-${date}-0-${price}`;
    const telegramSearchLink = `https://telegram.me/share/url?url=https://www.flightshunter.it/search/${da}-${a}-${date}-0-${price}`;
    const facebookSearchLink = `https://www.facebook.com/sharer/sharer.php?u=https://www.flightshunter.it/search/${da}-${a}-${date}-0-${price}`;
    const twitterSearchLink =  `http://twitter.com/share?text=Guarda questa offerta da ${daNome} per ${aNome} a ${price}€ +%20https://www.flightshunter.it/search/${da}-${a}-${date}-0-${price}`;

    const whatsappMonthLink = `https://api.whatsapp.com/send?text=Guarda questa offerta da ${daNome} per ${aNome} a ${price}€ +%20https://www.flightshunter.it/search/${da}-${a}-${date}-1-${price}`;
    const telegramMonthLink = `https://telegram.me/share/url?url=https://www.flightshunter.it/search/${da}-${a}-${date}-1-${price}`;
    const facebookMonthLink = `https://www.facebook.com/sharer/sharer.php?u=https://www.flightshunter.it/search/${da}-${a}-${date}-1-${price}`;
    const twitterMonthLink =  `http://twitter.com/share?text=Guarda questa offerta da ${daNome} per ${aNome} a ${price}€ +%20https://www.flightshunter.it/search/${da}-${a}-${date}-1-${price}`;

    const close = ()=> {
        closeShare(false);
    }

    const share = (social, socialName) => {
        window.open(social, '_blank');
    }

    return (
        <>
        <Sheet
              isOpen={open} onClose={close}
              snapPoints={[150]}
              initialSnap={0}>
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                    <div className='shareContainer'>
                        <img className='socialIcon' src={whatsapp} alt="whatsapp" onClick={()=>share(id && !isGuide ? whatsappLink : isGuide ? whatsappGuideLink : isSearch ? whatsappSearchLink : isResult ? whatsappMonthLink : whatsappGenericLink, 'whatsapp')}/>
                        <img className='socialIcon' src={telegram} alt="telegram" onClick={()=>share(id && !isGuide ? telegramLink : isGuide ? telegramGuideLink : isSearch ? telegramSearchLink : isResult ? telegramMonthLink : telegramGenericLink, 'telegram')}/>
                        <img className='socialIcon' src={facebook} alt="facebook" onClick={()=>share(id && !isGuide ? facebookLink : isGuide ? facebookGuideLink : isSearch ? facebookSearchLink : isResult ? facebookMonthLink : facebookGenericLink, 'facebook')}/>
                        <img className='socialIcon' src={twitter} alt="twitter" onClick={()=>share(id && !isGuide ? twitterLink : isGuide ? twitterGuideLink : isSearch ? twitterSearchLink : isResult ? twitterMonthLink : twitterGenericLink, 'twitter')}/>
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
        </>
    )
}

export default Share;