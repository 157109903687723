import './home.css';
import homePhoto from '../../assets/homebck.png';
import desktophome from '../../assets/desktophome.png';
import {useEffect, useState} from 'react';
import {db} from '../../firebase-config';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import LastOfferCard from '../lastOffersCard/lastOffersCard';
import ShareSocialPage from '../shareSocialPage/shareSocialPage';
import SearchForm from '../searchForm/searchForm';
import Spinner from 'react-bootstrap/Spinner';
import DynamicToast from '../dynamicToast/dynamicToast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { browserName, deviceDetect } from 'react-device-detect';
import shareiphone from '../../assets/shareiphone.png';
import addiphone from '../../assets/addiphone.png';
import store from '../../stateManager/store';
import { setLastOffersDay, saveLastOffers } from '../../stateManager/actions';
import { useSelector } from "react-redux";

function Home () {

    const navigate = useNavigate();
    const lastOffersDate = useSelector(state => state.lastOffersDate);
    const lastOffersStateManager = useSelector(state => state.lastOffers);

    const [lastOffers, setLastOffers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showYearMess, setShowYearMess] = useState(false);
    const [toastText, setToastText] = useState('');
    const [showModalPWA, setShowModalPWA] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState();
    const [viewMessagePwa, setViewMessagePwa] = useState(false);
    const [device, setDevice] = useState('');

    useEffect(()=>{
        if (browserName === 'Mobile Safari' && (deviceDetect(window.navigator.userAgent).vendor === 'Apple' || deviceDetect(window.navigator.userAgent).model === 'iPhone')) {
            setDevice('Apple');
            let viewPwa = localStorage.getItem('FH-nSM');
            if (!JSON.parse(viewPwa)) setShowModalPWA(true);
        } else {
            setDevice('Android');
        }
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                setDeferredPrompt(e);
            });
        }
        loadOffers();
    }, [])

    useEffect(()=>{
        let viewPwa = localStorage.getItem('FH-nSM');
        if (deferredPrompt && !JSON.parse(viewPwa)) setShowModalPWA(true);
    }, [deferredPrompt])

    const loadOffers = async ()=>{
        if (enterLastOffers()) {
            let today = new Date();
            today.setHours(0,0,0,0);
            store.dispatch(setLastOffersDay(today));
            const docRef = doc(db, "lastOffers", 'offers');
            const docSnap = await getDoc(docRef);
            if (docSnap.data()) {
                setLastOffers(docSnap.data().last);
                store.dispatch(saveLastOffers(docSnap.data().last));
            }
        } else {
            setLastOffers(lastOffersStateManager);
        } 
    }

    const enterLastOffers = ()=> {
        let today = new Date();
        today.setHours(0,0,0,0);
        if (lastOffersDate && lastOffersStateManager) {
            lastOffersDate.setHours(0,0,0,0);
            if (today > lastOffersDate) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const isLoading = (e)=> {
        setLoader(e)
    }

    const yearMessage = ()=> {
        setToastText('In modalità ricerca annuale le date non sono modificabili');
        setShowYearMess(true);
    }

    const noFlights = ()=> {
        setToastText('Non abbiamo trovato offerte con i criteri di ricerca inseriti, prova a cambiare qualcosa!');
        setShowYearMess(true);
    }

    const closeDynamicToast = ()=> {
        setToastText('');
        setShowYearMess(false);
    }

    const downloadPWA = async()=> {
            if (deferredPrompt) {
                deferredPrompt.prompt().then((res)=> {
                    if (res.outcome === "accepted") {
                        updateDownloaders();
                    } else {
                        dismissModalPWA();
                    }
                  })
                  .catch((err)=> {
                    console.log(err);
                    dismissModalPWA();
                  })
                // setTimeout(()=>{
                //     if(window.matchMedia('(display-mode: standalone)').matches) {
                //         updateDownloaders();
                //     }
                // },6000)
            }
        
    }

    const updateDownloaders = async() => {
        // TODO FUNZIONA DA METTERE SOLO IN PROD
        let downloaders = 0;
        let id = 'download';
        const docRef = doc(db, "download", id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data());
        if (docSnap.data()) {
            downloaders = Number(docSnap.data().download) +1;
        } else {
            downloaders = 1;
        }
        setDoc(docRef, {download: downloaders});
        localStorage.setItem('FH-nSM', true); 
        setShowModalPWA(false);
    }

    const dismissModalPWA = ()=> {
        setShowModalPWA(false);
        if (viewMessagePwa) {
            localStorage.setItem('FH-nSM', true);
        }
    }

    return (
        <div className='relative centerize'>
            {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            {
                window.innerWidth > 900 ?
                <img src={desktophome} alt="homePhoto" className='homePhoto shadowP mb-3'/>
                :
                <img src={homePhoto} alt="homePhoto" className='homePhoto mobile_bck'/>
            }
            <div>
                <div className='container mb-1 mt-1 lastO_c'>
                    <span className='offerTxt mb-3'>Ultime offerte</span>
                    <span className='offerTxt mb-3' onClick={()=> {localStorage.setItem('FH-nSM', false)}} style={{'transform': 'rotate(90deg)'}}>{'>'}</span>
                </div>
                {
                    lastOffers.map((offer, i)=>
                    <div key={i} className="center">
                        {
                            i<5 ?
                            <LastOfferCard offer={offer}/>
                            :
                            <div></div>
                        }
                    </div>
                    )
                }
                <div className='mt-2 mb-3'>
                    <span className='shareTxt'>Segui i nostri canali social per altre offerte</span>
                    <ShareSocialPage />
                </div>
            </div>
            <div className={window.innerWidth > 1084 ? 'desk_bck container_form' : 'container_form'}>
                <SearchForm isLoading={isLoading} openToast={yearMessage} noFlights={noFlights} inResult={false}/>
            </div>
            <DynamicToast open={showYearMess} text={toastText} close={closeDynamicToast}/>
            <Modal show={showModalPWA} onHide={dismissModalPWA}>
              <Modal.Header closeButton>
                <Modal.Title>📣 Novità 📣</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>📱 Woohoo, è arrivata la nostra app!</div>
                <div>🚀 Più veloce</div>
                <div>✨ Più comoda</div>
                <div>😍 Più offerte</div>
                </Modal.Body>
              <Modal.Footer className={device !== 'Apple' ? 'modal_footer' : 'modal_footer_iphone'}>
                {
                    device === 'Apple' ? 
                    <div className='iphone_footer'>
                        <div>
                            Tocca l'icona <img className='imgw18' src={shareiphone} alt="shareiphone" /> sul tuo dispositivo
                        </div>
                        <div>
                            e installa l'app con un click sul pulsante <img className='imgw18' src={addiphone} alt="addtoiphone" />
                        </div>
                    </div>
                    :
                    <div className='modal_footer'>
                        <Button className='footer_btn' variant="light" onClick={dismissModalPWA}>
                          No, grazie
                        </Button>
                        <Button className='offerBtn footer_btn' onClick={downloadPWA}>
                          Installa
                        </Button>
                    </div>
                }
                <Form.Check 
                      type={'checkbox'}
                      id={`default-checkbox`}
                      label={`non visualizzare più`}
                      className="pwa_checkbox"
                      onClick={()=>setViewMessagePwa(!viewMessagePwa)}
                />
              </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Home;