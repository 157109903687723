import {useParams, useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

function CatchOffer () {

    let {id} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if (id) {
            navigate('/offers', {state:{id:id}});
        }
    }, [id])

    return(
        <>
        </>
    )
}

export default CatchOffer;