const airports = [
    {
        "code": "BER",
        "lat": "52.5548",
        "lon": "13.289",
        "name": "Berlin",
        "city": "Berlino",
        "state": "Berlin",
        "country": "Germany",
        "woeid": "22164086",
        "tz": "EuropeBerlin",
        "phone": "0180 5000 186 (0,12 Euro",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9918",
        "elev": "121",
        "icao": "EDDT",
        "direct_flights": "101",
        "carriers": "70",
        "bookingId": "-1746443"
    },
    {
        "code": "BCN",
        "lat": "41.3006",
        "lon": "2.07976",
        "name": "Barcellona",
        "city": "Barcellona",
        "state": "Catalonia",
        "country": "Spain",
        "woeid": "22411879",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.es",
        "runway_length": "11001",
        "elev": "15",
        "icao": "LEBL",
        "direct_flights": "145",
        "carriers": "90",
        "bookingId": "-372490"
    },
    {
        "code": "LON",
        "lat": "51.8894",
        "lon": "0.26151",
        "name": "London",
        "city": "Londra",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22489156",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.stanstedairport.com",
        "runway_length": "10000",
        "elev": "347",
        "icao": "EGSS",
        "direct_flights": "168",
        "carriers": "25",
        "bookingId": "-2601889"
    },
    {
        "code": "ES",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Spain",
        "city": "Spagna",
        "state": "Spain",
        "country": "Spain",
        "woeid": "20081243",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "ROM",
        "lat": "41.8026",
        "lon": "12.2551",
        "name": "Rome",
        "city": "Roma",
        "state": "Lazio",
        "country": "Italy",
        "woeid": "22318195",
        "tz": "EuropeRome",
        "phone": "+39 06 65951",
        "type": "Airports",
        "email": "",
        "url": "http:www.adr.it",
        "runway_length": "12795",
        "elev": "14",
        "icao": "LIRF",
        "direct_flights": "181",
        "carriers": "124",
        "bookingId": "-126693"
    },
    {
        "code": "TCI",
        "lat": "28.4978",
        "lon": "-16.3419",
        "name": "Tenerife",
        "city": "Tenerife",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12517560",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "GCXO",
        "direct_flights": "22",
        "carriers": "20",
        "bookingId": "-401686"
    },
    {
        "code": "BUD",
        "lat": "47.4453",
        "lon": "19.2195",
        "name": "Budapest",
        "city": "Budapest",
        "state": "Budapest",
        "country": "Hungary",
        "woeid": "12513389",
        "tz": "EuropeBudapest",
        "phone": "(36) 1 2969696",
        "type": "Airports",
        "email": "",
        "url": "http:www.bud.hu",
        "runway_length": "9875",
        "elev": "440",
        "icao": "LHBP",
        "direct_flights": "102",
        "carriers": "64",
        "bookingId": "-850553"
    },
    {
        "code": "MIL",
        "lat": "45.6314",
        "lon": "8.72284",
        "name": "Milano",
        "city": "Milano",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "22306432",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.sea-aeroportimilano.it",
        "runway_length": "12844",
        "elev": "768",
        "icao": "LIMC",
        "direct_flights": "163",
        "carriers": "109",
        "bookingId": "-121726"
    },
    {
        "code": "AMS",
        "lat": "52.3122",
        "lon": "4.77511",
        "name": "Amsterdam Schiphol",
        "city": "Amsterdam",
        "state": "North Holland",
        "country": "Netherlands",
        "woeid": "22386730",
        "tz": "EuropeAmsterdam",
        "phone": "+31-207940800",
        "type": "Airports",
        "email": "",
        "url": "http:www.schiphol.com",
        "runway_length": "12467",
        "elev": "-11",
        "icao": "EHAM",
        "direct_flights": "284",
        "carriers": "118",
        "bookingId": "-2140479"
    },
    {
        "code": "MAD",
        "lat": "40.4684",
        "lon": "-3.56769",
        "name": "Madrid",
        "city": "Madrid",
        "state": "Madrid",
        "country": "Spain",
        "woeid": "12517539",
        "tz": "EuropeMadrid",
        "phone": "00 34 902 404 704",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13451",
        "elev": "1998",
        "icao": "LEMD",
        "direct_flights": "186",
        "carriers": "93",
        "bookingId": "-390625"
    },
    {
        "code": "IT",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Italy",
        "city": "Italia",
        "state": "Italy",
        "country": "Italy",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "FRA",
        "lat": "50.0483",
        "lon": "8.57041",
        "name": "Frankfurt",
        "city": "Francoforte",
        "state": "Hesse",
        "country": "Germany",
        "woeid": "22981759",
        "tz": "EuropeBerlin",
        "phone": "+49 69 690 0",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportcity-frankfurt.de",
        "runway_length": "13123",
        "elev": "364",
        "icao": "EDDF",
        "direct_flights": "337",
        "carriers": "131",
        "bookingId": "-1771148"
    },
    {
        "code": "CDG",
        "lat": "49.0175",
        "lon": "2.5564",
        "name": "Paris",
        "city": "Parigi",
        "state": "Ile-de-France",
        "country": "France",
        "woeid": "22140453",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11811",
        "elev": "387",
        "icao": "LFPG",
        "direct_flights": "310",
        "carriers": "131",
        "bookingId": "-1456928"
    },
    
    {
        "code": "ATL",
        "lat": "33.6558",
        "lon": "-84.4333",
        "name": "Hartsfield-Jackson Atlanta",
        "city": "Atlanta",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12522118",
        "tz": "AmericaNew_York",
        "phone": "(654) 57 8833",
        "type": "Airports",
        "email": "",
        "url": "http:www.atlanta-airport.com",
        "runway_length": "11889",
        "elev": "1026",
        "icao": "KATL",
        "direct_flights": "253",
        "carriers": "60"
    },
    {
        "code": "JFK",
        "lat": "40.6437",
        "lon": "-73.79",
        "name": "New York",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "woeid": "12520380",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "14572",
        "elev": "13",
        "icao": "KJFK",
        "direct_flights": "233",
        "carriers": "100"
    },
    {
        "code": "LGW",
        "lat": "51.1568",
        "lon": "-0.16988",
        "name": "London Gatwick",
        "city": "Londra Gatwick",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "23387567",
        "tz": "EuropeLondon",
        "phone": "0870 000 2468",
        "type": "Airports",
        "email": "",
        "url": "http:www.gatwickairport.com",
        "runway_length": "10364",
        "elev": "202",
        "icao": "",
        "direct_flights": "227",
        "carriers": "71",
        "bookingId": "-2601889"
    },
    {
        "code": "LHR",
        "lat": "51.4703",
        "lon": "-0.45342",
        "name": "London Heathrow",
        "city": "Londra Heathrow",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "23382429",
        "tz": "EuropeLondon",
        "phone": "+44 (0)8700 000698",
        "type": "Airports",
        "email": "",
        "url": "http:www.heathrowairport.com",
        "runway_length": "12802",
        "elev": "80",
        "icao": "EGLL",
        "direct_flights": "227",
        "carriers": "105",
        "bookingId": "-2601889"
    },
    {
        "code": "ORD",
        "lat": "41.9796",
        "lon": "-87.8825",
        "name": "Chicago O'Hare",
        "city": "Chicago",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12521200",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flychicago.comoharehome.asp",
        "runway_length": "13000",
        "elev": "668",
        "icao": "KORD",
        "direct_flights": "222",
        "carriers": "85"
    },
    {
        "code": "EWR",
        "lat": "40.6924",
        "lon": "-74.1843",
        "name": "Newark",
        "city": "Newark",
        "state": "New Jersey",
        "country": "United States",
        "woeid": "12521127",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "18",
        "icao": "KEWR",
        "direct_flights": "220",
        "carriers": "64"
    },
    {
        "code": "MUC",
        "lat": "48.354",
        "lon": "11.7816",
        "name": "Munich",
        "city": "Monaco",
        "state": "Bavaria",
        "country": "Germany",
        "woeid": "22923040",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.munich-airport.de",
        "runway_length": "13100",
        "elev": "1737",
        "icao": "EDDM",
        "direct_flights": "213",
        "carriers": "95",
        "bookingId": "-1829149"
    },
    // {
    //     "code": "EU",
    //     "lat": "38.9833",
    //     "lon": "-1.85",
    //     "name": "Europa",
    //     "city": "Europa",
    //     "state": "Italy",
    //     "country": "Italy",
    //     "woeid": "20081243",
    //     "tz": "Europe",
    //     "phone": "",
    //     "type": "Airports",
    //     "email": "",
    //     "url": "",
    //     "runway_length": null,
    //     "elev": null,
    //     "icao": "LEAB",
    //     "direct_flights": "3",
    //     "carriers": "2",
    //     "isCountry": true
    // },
    {
        "code": "IAH",
        "lat": "29.9784",
        "lon": "-95.3424",
        "name": "George Bush Intercontinental",
        "city": "Houston",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520245",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12001",
        "elev": "98",
        "icao": "KIAH",
        "direct_flights": "210",
        "carriers": "54"
    },
    {
        "code": "LAX",
        "lat": "33.9456",
        "lon": "-118.391",
        "name": "Los Angeles",
        "city": "Los Angeles",
        "state": "California",
        "country": "United States",
        "woeid": "12520706",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12091",
        "elev": "126",
        "icao": "KLAX",
        "direct_flights": "200",
        "carriers": "99"
    },
    {
        "code": "BRU",
        "lat": "50.899",
        "lon": "4.4859",
        "name": "Bruxelles",
        "city": "Brussels",
        "state": "Vlaams Brabant",
        "country": "Belgium",
        "woeid": "22103751",
        "tz": "EuropeBrussels",
        "phone": "+32 (0) 900 7 0000",
        "type": "Airports",
        "email": "",
        "url": "http:www.brusselsairport.be",
        "runway_length": "9790",
        "elev": "184",
        "icao": "EBBR",
        "direct_flights": "186",
        "carriers": "97"
    },
    {
        "code": "VIE",
        "lat": "48.1036",
        "lon": "16.5804",
        "name": "Vienna Schwechat",
        "city": "Vienna",
        "state": "Lower Austria",
        "country": "Austria",
        "woeid": "22022012",
        "tz": "EuropeVienna",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.viennaairport.com",
        "runway_length": "11811",
        "elev": "600",
        "icao": "LOWW",
        "direct_flights": "183",
        "carriers": "96"
    },
    {
        "code": "FCO",
        "lat": "41.8026",
        "lon": "12.2551",
        "name": "Rome Fiumicino",
        "city": "Roma Fiumicino",
        "state": "Lazio",
        "country": "Italy",
        "woeid": "22318195",
        "tz": "EuropeRome",
        "phone": "+39 06 65951",
        "type": "Airports",
        "email": "",
        "url": "http:www.adr.it",
        "runway_length": "12795",
        "elev": "14",
        "icao": "LIRF",
        "direct_flights": "181",
        "carriers": "124",
        "bookingId": "-126693"
    },
    {
        "code": "DFW",
        "lat": "32.9222",
        "lon": "-97.0409",
        "name": "Fort Worth",
        "city": "Dallas",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519786",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11388",
        "elev": "603",
        "icao": "KDFW",
        "direct_flights": "179",
        "carriers": "62"
    },
    {
        "code": "YYZ",
        "lat": "43.685",
        "lon": "-79.6142",
        "name": "Toronto Lester B Pearson",
        "city": "Mississauga",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511883",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.gtaa.com",
        "runway_length": "11120",
        "elev": "569",
        "icao": "CYYZ",
        "direct_flights": "176",
        "carriers": "66"
    },
    {
        "code": "MSP",
        "lat": "44.8793",
        "lon": "-93.1987",
        "name": "Minneapolis St Paul",
        "city": "St. Paul",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12520966",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:mspairport.com",
        "runway_length": "11006",
        "elev": "841",
        "icao": "KMSP",
        "direct_flights": "171",
        "carriers": "41"
    },
    {
        "code": "DXB",
        "lat": "25.2509",
        "lon": "55.3629",
        "name": "Dubai",
        "city": "Dubai",
        "state": "Dubai",
        "country": "United Arab Emirates",
        "woeid": "23388362",
        "tz": "AsiaDubai",
        "phone": "+971 4 216 2525",
        "type": "Airports",
        "email": "",
        "url": "http:www.dubaiairport.com",
        "runway_length": "13123",
        "elev": "34",
        "icao": "OMDB",
        "direct_flights": "170",
        "carriers": "126"
    },
    {
        "code": "STN",
        "lat": "51.8894",
        "lon": "0.26151",
        "name": "London Stansted",
        "city": "Londra Stansted",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22489156",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.stanstedairport.com",
        "runway_length": "10000",
        "elev": "347",
        "icao": "EGSS",
        "direct_flights": "168",
        "carriers": "25",
        "bookingId": "-2601889"
    },
    {
        "code": "DUB",
        "lat": "53.4272",
        "lon": "-6.24418",
        "name": "Dublin",
        "city": "Dublino",
        "state": "",
        "country": "Ireland",
        "woeid": "23388266",
        "tz": "EuropeDublin",
        "phone": "+353 1 8141111",
        "type": "Airports",
        "email": "",
        "url": "http:www.dub.aero",
        "runway_length": "7499",
        "elev": "222",
        "icao": "EIDW",
        "direct_flights": "167",
        "carriers": "54"
    },
    {
        "code": "DUS",
        "lat": "51.2794",
        "lon": "6.76481",
        "name": "Dusseldorf",
        "city": "Dusseldorf",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "23024911",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.duesseldorf-international.de",
        "runway_length": "9842",
        "elev": "147",
        "icao": "EDDL",
        "direct_flights": "167",
        "carriers": "74",
        "bookingId": "-1762397"
    },
    {
        "code": "MIA",
        "lat": "25.7953",
        "lon": "-80.2727",
        "name": "Miami",
        "city": "Miami",
        "state": "Florida",
        "country": "United States",
        "woeid": "12520923",
        "tz": "AmericaNew_York",
        "phone": "(305) 876-7000",
        "type": "Airports",
        "email": "customerservice@miami-airport.com",
        "url": "http:www.miami-airport.com",
        "runway_length": "13000",
        "elev": "11",
        "icao": "KMIA",
        "direct_flights": "167",
        "carriers": "91"
    },
    {
        "code": "DME",
        "lat": "55.4025",
        "lon": "37.9136",
        "name": "Domodedovo",
        "city": "Podol'sk",
        "state": "Moskovskaya Oblast",
        "country": "Russia",
        "woeid": "12515946",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.domodedovo.ru",
        "runway_length": null,
        "elev": "512",
        "icao": "UUDD",
        "direct_flights": "165",
        "carriers": "71"
    },
    {
        "code": "PEK",
        "lat": "40.0724",
        "lon": "116.583",
        "name": "Beijing Capital",
        "city": "Shunyi",
        "state": "Beijing",
        "country": "China",
        "woeid": "12511989",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.bcia.com.cn",
        "runway_length": "12467",
        "elev": "115",
        "icao": "ZBAA",
        "direct_flights": "165",
        "carriers": "84"
    },
    {
        "code": "MXP",
        "lat": "45.6314",
        "lon": "8.72284",
        "name": "Milano Malpensa",
        "city": "Milano Malpensa",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "22306432",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.sea-aeroportimilano.it",
        "runway_length": "12844",
        "elev": "768",
        "icao": "LIMC",
        "direct_flights": "163",
        "carriers": "109",
        "bookingId": "-121726"
    },
    {
        "code": "DEN",
        "lat": "39.8396",
        "lon": "-104.672",
        "name": "Denver",
        "city": "Denver",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12523052",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "5333",
        "icao": "KDEN",
        "direct_flights": "162",
        "carriers": "57"
    },
    {
        "code": "DTW",
        "lat": "42.2327",
        "lon": "-83.3412",
        "name": "Detroit Metropolitan Wayne County",
        "city": "Detroit",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519481",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.metroairport.com",
        "runway_length": "10501",
        "elev": "639",
        "icao": "KDTW",
        "direct_flights": "161",
        "carriers": "45"
    },
    {
        "code": "ZRH",
        "lat": "47.454",
        "lon": "8.56137",
        "name": "Zürich",
        "city": "Zurigo",
        "state": "Canton of Zurich",
        "country": "Switzerland",
        "woeid": "22442771",
        "tz": "EuropeZurich",
        "phone": "+41 43 816 22 11",
        "type": "Airports",
        "email": "",
        "url": "http:www.zurich-airport.com",
        "runway_length": "12139",
        "elev": "1416",
        "icao": "LSZH",
        "direct_flights": "161",
        "carriers": "79"
    },
    {
        "code": "IST",
        "lat": "40.9857",
        "lon": "28.8163",
        "name": "Istanbul",
        "city": "Istanbul",
        "state": "Istanbul",
        "country": "Turkey",
        "woeid": "23388365",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "157",
        "icao": "LTBA",
        "direct_flights": "157",
        "carriers": "79"
    },
    {
        "code": "IAD",
        "lat": "38.9556",
        "lon": "-77.4484",
        "name": "Dulles",
        "city": "Washington",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12519544",
        "tz": "AmericaNew_York",
        "phone": "703-572-6001",
        "type": "Airports",
        "email": "",
        "url": "http:www.metwashairports.comDulles",
        "runway_length": "11501",
        "elev": "313",
        "icao": "KIAD",
        "direct_flights": "150",
        "carriers": "63"
    },
    {
        "code": "SFO",
        "lat": "37.6148",
        "lon": "-122.392",
        "name": "San Francisco",
        "city": "San Francisco",
        "state": "California",
        "country": "United States",
        "woeid": "12521721",
        "tz": "AmericaLos_Angeles",
        "phone": "(650) 821-8211",
        "type": "Airports",
        "email": "",
        "url": "http:www.flysfo.com",
        "runway_length": "11870",
        "elev": "12",
        "icao": "KSFO",
        "direct_flights": "147",
        "carriers": "76"
    },
    {
        "code": "SVO",
        "lat": "55.9621",
        "lon": "37.4189",
        "name": "Moscow",
        "city": "Mosca",
        "state": "Moskovskaya Oblast",
        "country": "Russia",
        "woeid": "12516860",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.sheremetyevo-airport.ru",
        "runway_length": "12139",
        "elev": "623",
        "icao": "UUEE",
        "direct_flights": "140",
        "carriers": "48"
    },
    {
        "code": "ICN",
        "lat": "37.4534",
        "lon": "126.657",
        "name": "New Incheon",
        "city": "Incheon",
        "state": "Incheon",
        "country": "South Korea",
        "woeid": "24554859",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.or.krengairport",
        "runway_length": "12300",
        "elev": "6",
        "icao": "RKSI",
        "direct_flights": "139",
        "carriers": "76"
    },
    {
        "code": "ORY",
        "lat": "48.7261",
        "lon": "2.36411",
        "name": "Paris Orly",
        "city": "Parigi Orly",
        "state": "Ile-de-France",
        "country": "France",
        "woeid": "22144181",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.adp.fr",
        "runway_length": "11975",
        "elev": "292",
        "icao": "LFPO",
        "direct_flights": "138",
        "carriers": "52",
        "bookingId": "-1456928"
    },
    {
        "code": "CPH",
        "lat": "55.6205",
        "lon": "12.6495",
        "name": "Copenhagen",
        "city": "Copenhagen",
        "state": "Hovedstaden",
        "country": "Denmark",
        "woeid": "22851127",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cph.dk",
        "runway_length": "11811",
        "elev": "17",
        "icao": "EKCH",
        "direct_flights": "136",
        "carriers": "76"
    },
    {
        "code": "CLT",
        "lat": "35.2226",
        "lon": "-80.946",
        "name": "Douglas",
        "city": "Charlotte",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12519519",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.charmeck.orgDepartmentsAirportHome.htm",
        "runway_length": "10000",
        "elev": "749",
        "icao": "KCLT",
        "direct_flights": "133",
        "carriers": "39"
    },
    {
        "code": "PHL",
        "lat": "39.8768",
        "lon": "-75.2419",
        "name": "Philadelphia",
        "city": "Philadelphia",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12521360",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.phl.org",
        "runway_length": "10500",
        "elev": "21",
        "icao": "KPHL",
        "direct_flights": "133",
        "carriers": "51"
    },
    {
        "code": "BKK",
        "lat": "13.9144",
        "lon": "100.608",
        "name": "Bangkok",
        "city": "Lak Si",
        "state": "Bangkok",
        "country": "Thailand",
        "woeid": "12517747",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportthai.co.thairportnewbiahtml",
        "runway_length": "12139",
        "elev": "12",
        "icao": "VTBS",
        "direct_flights": "132",
        "carriers": "113"
    },
    {
        "code": "PRG",
        "lat": "50.1079",
        "lon": "14.2675",
        "name": "Prague",
        "city": "Praga",
        "state": "Hlavni mesto Praha",
        "country": "Czech Republic",
        "woeid": "12512558",
        "tz": "EuropePrague",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.prg.aero",
        "runway_length": "10663",
        "elev": "1247",
        "icao": "KPRG",
        "direct_flights": "131",
        "carriers": "66"
    },
    {
        "code": "HKG",
        "lat": "22.3124",
        "lon": "113.929",
        "name": "Hong Kong",
        "city": "Hong Kong",
        "state": "Hong Kong Island",
        "country": "Hong Kong",
        "woeid": "24875607",
        "tz": "AsiaHong_Kong",
        "phone": "+852 2188 7111",
        "type": "Airports",
        "email": "",
        "url": "http:www.hongkongairport.com",
        "runway_length": "12467",
        "elev": "19",
        "icao": "VHHH",
        "direct_flights": "131",
        "carriers": "87"
    },
    {
        "code": "PVG",
        "lat": "31.1156",
        "lon": "121.803",
        "name": "Pudong",
        "city": "Huinan",
        "state": "Shanghai",
        "country": "China",
        "woeid": "23428057",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KPVG",
        "direct_flights": "131",
        "carriers": "76"
    },
    {
        "code": "LAS",
        "lat": "36.0806",
        "lon": "-115.143",
        "name": "Mccarran",
        "city": "Las Vegas",
        "state": "Nevada",
        "country": "United States",
        "woeid": "12520849",
        "tz": "AmericaLos_Angeles",
        "phone": "(702) 261-5211",
        "type": "Airports",
        "email": "",
        "url": "http:www.mccarran.com",
        "runway_length": "12636",
        "elev": "2174",
        "icao": "KLAS",
        "direct_flights": "130",
        "carriers": "64"
    },
    {
        "code": "CGN",
        "lat": "50.8784",
        "lon": "7.12293",
        "name": "Cologne Bonn",
        "city": "Colonia",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "23030981",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.koeln-bonn-airport.deindex.php",
        "runway_length": "12467",
        "elev": "300",
        "icao": "EDDK",
        "direct_flights": "129",
        "carriers": "45",
        "bookingId": "-1810561"
    },
    {
        "code": "MAN",
        "lat": "53.365",
        "lon": "-2.27089",
        "name": "Manchester",
        "city": "Manchester",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22478032",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 161 489 3000",
        "type": "Airports",
        "email": "",
        "url": "http:www.manchesterairport.co.uk",
        "runway_length": "10000",
        "elev": "256",
        "icao": "EGCC",
        "direct_flights": "129",
        "carriers": "79",
        "bookingId": "-2602512"
    },
    {
        "code": "ARN",
        "lat": "59.6521",
        "lon": "17.9317",
        "name": "Arlanda",
        "city": "Stockholm",
        "state": "Stoccolma",
        "country": "Sweden",
        "woeid": "12517610",
        "tz": "EuropeStockholm",
        "phone": "+46 8 797 6000",
        "type": "Airports",
        "email": "",
        "url": "http:www.arlanda.se",
        "runway_length": "10827",
        "elev": "123",
        "icao": "ESSA",
        "direct_flights": "126",
        "carriers": "76"
    },
    {
        "code": "GVA",
        "lat": "46.2329",
        "lon": "6.10682",
        "name": "Geneva",
        "city": "Ginevra",
        "state": "Canton of Geneva",
        "country": "Switzerland",
        "woeid": "23365569",
        "tz": "EuropeZurich",
        "phone": "+41 22 717 71 11",
        "type": "Airports",
        "email": "",
        "url": "http:www.gva.ch",
        "runway_length": "12796",
        "elev": "1411",
        "icao": "LSGG",
        "direct_flights": "122",
        "carriers": "71"
    },
    {
        "code": "LED",
        "lat": "59.9667",
        "lon": "30.3",
        "name": "St. Petersburg",
        "city": "San Pietroburgo",
        "state": "St. Peterburg",
        "country": "Russia",
        "woeid": "12523047",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11155",
        "elev": "59",
        "icao": "ULLI",
        "direct_flights": "121",
        "carriers": "58"
    },
    {
        "code": "LUX",
        "lat": "49.6269",
        "lon": "6.20685",
        "name": "Luxembourg",
        "city": "Lussenburgo",
        "state": "Luxemburg",
        "country": "Luxembourg",
        "woeid": "12514635",
        "tz": "EuropeLuxembourg",
        "phone": "+352 2464 1",
        "type": "Airports",
        "email": "",
        "url": "http:www.luxair.lu",
        "runway_length": "13123",
        "elev": "1234",
        "icao": "ELLX",
        "direct_flights": "119",
        "carriers": "29"
    },
    {
        "code": "ATH",
        "lat": "37.8937",
        "lon": "23.7235",
        "name": "Athens",
        "city": "Atene",
        "state": "Attiki",
        "country": "Greece",
        "woeid": "12513286",
        "tz": "EuropeAthens",
        "phone": "0030 210 353 0001",
        "type": "Airports",
        "email": "",
        "url": "http:www.aia.gr",
        "runway_length": "13123",
        "elev": "308",
        "icao": "LGAV",
        "direct_flights": "117",
        "carriers": "81"
    },
    {
        "code": "OSL",
        "lat": "60.1947",
        "lon": "11.1005",
        "name": "Oslo",
        "city": "Gardermoen",
        "state": "Akershus Fylke",
        "country": "Norway",
        "woeid": "12515119",
        "tz": "EuropeOslo",
        "phone": "+47 64812000",
        "type": "Airports",
        "email": "",
        "url": "http:www.osl.no",
        "runway_length": "11811",
        "elev": "681",
        "icao": "ENGM",
        "direct_flights": "116",
        "carriers": "59"
    },
    {
        "code": "STR",
        "lat": "48.6895",
        "lon": "9.19298",
        "name": "Stuttgart",
        "city": "Stuttgart",
        "state": "Baden-Wurttemberg",
        "country": "Germany",
        "woeid": "22236827",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "1300",
        "icao": "EDDS",
        "direct_flights": "116",
        "carriers": "51",
        "bookingId": "-1871728"
    },
    {
        "code": "CVG",
        "lat": "39.0571",
        "lon": "-84.6625",
        "name": "Greater Cincinnati",
        "city": "Hebron",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12519978",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9501",
        "elev": "891",
        "icao": "KCVG",
        "direct_flights": "115",
        "carriers": "39"
    },
    {
        "code": "BOS",
        "lat": "42.3717",
        "lon": "-71.0281",
        "name": "Gen E L Logan",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12519874",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10081",
        "elev": "20",
        "icao": "KBOS",
        "direct_flights": "113",
        "carriers": "68"
    },
    {
        "code": "HAM",
        "lat": "53.6321",
        "lon": "10.0042",
        "name": "Hamburg",
        "city": "Amburgo",
        "state": "Hamburg",
        "country": "Germany",
        "woeid": "22211603",
        "tz": "EuropeBerlin",
        "phone": "+49 (0)405075-0",
        "type": "Airports",
        "email": "",
        "url": "http:www.hamburg-airport.de",
        "runway_length": "12024",
        "elev": "53",
        "icao": "EDDH",
        "direct_flights": "112",
        "carriers": "75",
        "bookingId": "-1785434"
    },
    {
        "code": "CAN",
        "lat": "23.3925",
        "lon": "113.299",
        "name": "Baiyun",
        "city": "Guangzhou",
        "state": "Guangdong",
        "country": "China",
        "woeid": "12511984",
        "tz": "AsiaChongqing",
        "phone": "0086-020-86120000",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "45",
        "icao": "ZGGG",
        "direct_flights": "111",
        "carriers": "49"
    },
    {
        "code": "PMI",
        "lat": "39.5495",
        "lon": "2.73188",
        "name": "Palma de Mallorca",
        "city": "Palma",
        "state": "Balearic Islands",
        "country": "Spain",
        "woeid": "23281165",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10728",
        "elev": "15",
        "icao": "LEPA",
        "direct_flights": "109",
        "carriers": "57",
        "bookingId": "-395224"
    },
    {
        "code": "SEA",
        "lat": "47.4405",
        "lon": "-122.296",
        "name": "Tacoma",
        "city": "Seattle",
        "state": "Washington",
        "country": "United States",
        "woeid": "12522066",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.portseattle.orgseatac",
        "runway_length": "11900",
        "elev": "429",
        "icao": "KSEA",
        "direct_flights": "109",
        "carriers": "59"
    },
    {
        "code": "FLL",
        "lat": "26.0722",
        "lon": "-80.1354",
        "name": "Fort Lauderdale Hollywood",
        "city": "Dania Beach",
        "state": "Florida",
        "country": "United States",
        "woeid": "12519836",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9001",
        "elev": "11",
        "icao": "KFLL",
        "direct_flights": "108",
        "carriers": "46"
    },
    {
        "code": "PHX",
        "lat": "33.4376",
        "lon": "-112.03",
        "name": "Sky Harbor",
        "city": "Phoenix",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12521872",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:phoenix.govAhttp:phoenix.govaviation",
        "runway_length": "11001",
        "elev": "1132",
        "icao": "KPHX",
        "direct_flights": "108",
        "carriers": "51"
    },
    {
        "code": "SIN",
        "lat": "1.3578",
        "lon": "103.991",
        "name": "Singapore Changi",
        "city": "Singapore",
        "state": "South East",
        "country": "Singapore",
        "woeid": "12517525",
        "tz": "AsiaSingapore",
        "phone": "(65) 6542 1122",
        "type": "Airports",
        "email": "",
        "url": "http:www.changiairport.com.sg",
        "runway_length": "13200",
        "elev": "65",
        "icao": "WSSS",
        "direct_flights": "108",
        "carriers": "80"
    },
    {
        "code": "AGP",
        "lat": "36.6749",
        "lon": "-4.49298",
        "name": "Málaga",
        "city": "Malaga",
        "state": "Andalucia",
        "country": "Spain",
        "woeid": "23232917",
        "tz": "EuropeMadrid",
        "phone": "+34 952 048 838",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.escseeSatellite?cid=1048858947193&pagename=Est",
        "runway_length": "10500",
        "elev": "52",
        "icao": "LEMG",
        "direct_flights": "106",
        "carriers": "59",
        "bookingId": "-390787"
    },
    {
        "code": "JED",
        "lat": "21.706",
        "lon": "39.1386",
        "name": "King Abdul Aziz",
        "city": "Jeddah",
        "state": "Makka",
        "country": "Saudi Arabia",
        "woeid": "23388353",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12467",
        "elev": "48",
        "icao": "OEJN",
        "direct_flights": "106",
        "carriers": "54"
    },
    {
        "code": "MCO",
        "lat": "28.4418",
        "lon": "-81.3115",
        "name": "Orlando",
        "city": "Orlando",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521243",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12004",
        "elev": "96",
        "icao": "KMCO",
        "direct_flights": "106",
        "carriers": "63"
    },
    {
        "code": "HEL",
        "lat": "60.3243",
        "lon": "24.9688",
        "name": "Helsinki Vantaa",
        "city": "Helsinki",
        "state": "Southern Finland",
        "country": "Finland",
        "woeid": "12512766",
        "tz": "EuropeHelsinki",
        "phone": "+358 200 14636",
        "type": "Airports",
        "email": "",
        "url": "http:www.helsinki-vantaa.fi",
        "runway_length": "11286",
        "elev": "179",
        "icao": "EFHK",
        "direct_flights": "104",
        "carriers": "53"
    },
    {
        "code": "SLC",
        "lat": "40.7862",
        "lon": "-111.982",
        "name": "Salt Lake City",
        "city": "Salt Lake City",
        "state": "Utah",
        "country": "United States",
        "woeid": "12521709",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12003",
        "elev": "4226",
        "icao": "KSLC",
        "direct_flights": "104",
        "carriers": "38"
    },
    {
        "code": "YUL",
        "lat": "45.4562",
        "lon": "-73.7473",
        "name": "Aéroport International Pierre-Elliott-Trudeau d",
        "city": "Dorval",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511638",
        "tz": "AmericaToronto",
        "phone": "514 394 7377",
        "type": "Airports",
        "email": "",
        "url": "http:www.admtl.com",
        "runway_length": "11000",
        "elev": "117",
        "icao": "CYUL",
        "direct_flights": "104",
        "carriers": "57"
    },
    {
        "code": "BHX",
        "lat": "52.4531",
        "lon": "-1.73847",
        "name": "Birmingham",
        "city": "Birmingham",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22454274",
        "tz": "EuropeLondon",
        "phone": "+44 (0)8707 335511",
        "type": "Airports",
        "email": "",
        "url": "http:www.bhx.co.uk",
        "runway_length": "7398",
        "elev": "325",
        "icao": "EGBB",
        "direct_flights": "103",
        "carriers": "45",
        "bookingId": "-2589989"
    },
    {
        "code": "CLE",
        "lat": "41.4115",
        "lon": "-81.8339",
        "name": "Hopkins",
        "city": "Cleveland",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12520226",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8998",
        "elev": "792",
        "icao": "KCLE",
        "direct_flights": "102",
        "carriers": "44"
    },
    {
        "code": "WAW",
        "lat": "52.17",
        "lon": "20.9725",
        "name": "Warsaw",
        "city": "Varsavia",
        "state": "Mazowieckie",
        "country": "Poland",
        "woeid": "12515363",
        "tz": "EuropeWarsaw",
        "phone": "+48 (22) 650 42 20",
        "type": "Airports",
        "email": "",
        "url": "http:www.lotnisko-chopina.pl",
        "runway_length": "9852",
        "elev": "354",
        "icao": "EPWA",
        "direct_flights": "102",
        "carriers": "60"
    },
    {
        "code": "WMI",
        "lat": "52.17",
        "lon": "20.9725",
        "name": "Warsaw",
        "city": "Varsavia",
        "state": "Mazowieckie",
        "country": "Poland",
        "woeid": "12515363",
        "tz": "EuropeWarsaw",
        "phone": "+48 (22) 650 42 20",
        "type": "Airports",
        "email": "",
        "url": "http:www.lotnisko-chopina.pl",
        "runway_length": "9852",
        "elev": "354",
        "icao": "EPWA",
        "direct_flights": "102",
        "carriers": "60"
    },
    {
        "code": "KUL",
        "lat": "2.77859",
        "lon": "101.689",
        "name": "Kuala Lumpur",
        "city": "Sepang",
        "state": "Putrajaya",
        "country": "Malaysia",
        "woeid": "28752278",
        "tz": "AsiaKuala_Lumpur",
        "phone": "+603 8776 4386",
        "type": "Airports",
        "email": "",
        "url": "http:www.klia.com.my",
        "runway_length": "4000",
        "elev": null,
        "icao": "WMKK",
        "direct_flights": "101",
        "carriers": "68"
    },
    {
        "code": "TXL",
        "lat": "52.5548",
        "lon": "13.289",
        "name": "Berlin Tegel",
        "city": "Berlin Tegel",
        "state": "Berlin",
        "country": "Germany",
        "woeid": "22164086",
        "tz": "EuropeBerlin",
        "phone": "0180 5000 186 (0,12 Euro",
        "type": "Airports",
        "email": "",
        "url": "http:www.berlin-airport.dePubEnglishPubTegelindex.php?",
        "runway_length": "9918",
        "elev": "121",
        "icao": "EDDT",
        "direct_flights": "101",
        "carriers": "70"
    },
    {
        "code": "LGG",
        "lat": "50.64",
        "lon": "5.44035",
        "name": "Liegi Bierset",
        "city": "Velroux",
        "state": "Liege",
        "country": "Belgium",
        "woeid": "12510845",
        "tz": "EuropeBrussels",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8839",
        "elev": "660",
        "icao": "EBLG",
        "direct_flights": "99",
        "carriers": "7"
    },
    {
        "code": "MEX",
        "lat": "19.4344",
        "lon": "-99.0742",
        "name": "Lic Benito Juarez",
        "city": "Mexico City",
        "state": "Distrito Federal",
        "country": "Mexico",
        "woeid": "12514910",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aicm.com.mx",
        "runway_length": "12619",
        "elev": "7341",
        "icao": "MMMX",
        "direct_flights": "98",
        "carriers": "42"
    },
    {
        "code": "NCE",
        "lat": "43.6638",
        "lon": "7.21286",
        "name": "Nice",
        "city": "Nizza",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "22143543",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "13",
        "icao": "LFMN",
        "direct_flights": "97",
        "carriers": "67",
        "bookingId": "-1454990"
    },
    {
        "code": "NRT",
        "lat": "35.7491",
        "lon": "140.389",
        "name": "Narita",
        "city": "Narita-shi",
        "state": "Chiba Prefecture",
        "country": "Japan",
        "woeid": "12513999",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.narita-airport.jp",
        "runway_length": "13123",
        "elev": "141",
        "icao": "RJAA",
        "direct_flights": "97",
        "carriers": "63"
    },
    {
        "code": "MEM",
        "lat": "35.047",
        "lon": "-89.9823",
        "name": "Memphis",
        "city": "Memphis",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12520895",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9319",
        "elev": "332",
        "icao": "KMEM",
        "direct_flights": "96",
        "carriers": "28"
    },
    {
        "code": "DEL",
        "lat": "28.5603",
        "lon": "77.1027",
        "name": "Indira Gandhi",
        "city": "New Delhi",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513599",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12500",
        "elev": "776",
        "icao": "VIDP",
        "direct_flights": "95",
        "carriers": "70"
    },
    {
        "code": "CAI",
        "lat": "30.1206",
        "lon": "31.4078",
        "name": "Cairo",
        "city": "Cairo",
        "state": "Al Qahirah",
        "country": "Egypt",
        "woeid": "12512678",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cairo-airport.com",
        "runway_length": "13123",
        "elev": "381",
        "icao": "HECA",
        "direct_flights": "92",
        "carriers": "52"
    },
    {
        "code": "LIS",
        "lat": "38.7701",
        "lon": "-9.13775",
        "name": "Lisbon",
        "city": "Lisbona",
        "state": "Lisbon",
        "country": "Portugal",
        "woeid": "22405520",
        "tz": "EuropeLisbon",
        "phone": "+351 218 413 500",
        "type": "Airports",
        "email": "",
        "url": "http:www.ana.pt",
        "runway_length": "12484",
        "elev": "374",
        "icao": "LPPT",
        "direct_flights": "92",
        "carriers": "57"
    },
    {
        "code": "YVR",
        "lat": "49.1931",
        "lon": "-123.172",
        "name": "Vancouver",
        "city": "Richmond",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511894",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.yvr.ca",
        "runway_length": "11500",
        "elev": "9",
        "icao": "CYVR",
        "direct_flights": "92",
        "carriers": "54"
    },
    {
        "code": "ALC",
        "lat": "38.2844",
        "lon": "-0.5576",
        "name": "Alicante",
        "city": "Alicante",
        "state": "Valencia",
        "country": "Spain",
        "woeid": "22412043",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "141",
        "icao": "LEAL",
        "direct_flights": "91",
        "carriers": "37",
        "bookingId": "-370210"
    },
    {
        "code": "JNB",
        "lat": "-26.1219",
        "lon": "28.2467",
        "name": "OR Tambo",
        "city": "Johannesburg",
        "state": "Gauteng",
        "country": "South Africa",
        "woeid": "12517424",
        "tz": "AfricaJohannesburg",
        "phone": "+27 (0)11 921 6262",
        "type": "Airports",
        "email": "",
        "url": "http:www.airports.co.zahome.asp?pid=1147",
        "runway_length": "14495",
        "elev": "5512",
        "icao": "FAJS",
        "direct_flights": "89",
        "carriers": "63"
    },
    {
        "code": "LYS",
        "lat": "45.7198",
        "lon": "5.08245",
        "name": "Lyon",
        "city": "Colombier",
        "state": "Rhone-Alpes",
        "country": "France",
        "woeid": "12512963",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lyon.aeroport.fr",
        "runway_length": "13123",
        "elev": "814",
        "icao": "LFLL",
        "direct_flights": "89",
        "carriers": "54",
        "bookingId": "-1448468"
    },
    {
        "code": "DOH",
        "lat": "25.2592",
        "lon": "51.5658",
        "name": "Doha",
        "city": "Doha",
        "state": "Doha",
        "country": "Qatar",
        "woeid": "12515487",
        "tz": "AsiaQatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "15000",
        "elev": "35",
        "icao": "OTBD",
        "direct_flights": "87",
        "carriers": "43"
    },
    {
        "code": "EDI",
        "lat": "55.9486",
        "lon": "-3.36431",
        "name": "Edinburgh",
        "city": "Edimburgo",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22463734",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 870 040 0007",
        "type": "Airports",
        "email": "",
        "url": "http:www.edinburghairport.com",
        "runway_length": "8400",
        "elev": "135",
        "icao": "EGPH",
        "direct_flights": "87",
        "carriers": "49",
        "bookingId": "-2595386"
    },
    {
        "code": "TLV",
        "lat": "32.0117",
        "lon": "34.8861",
        "name": "Ben Gurion",
        "city": "Petaẖ Tiqwa",
        "state": "HaMerkaz",
        "country": "Israel",
        "woeid": "12513775",
        "tz": "AsiaJerusalem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.iaa.gov.ilRashaten-USAirportsBenGurion",
        "runway_length": "11998",
        "elev": "135",
        "icao": "LLBG",
        "direct_flights": "87",
        "carriers": "56"
    },
    {
        "code": "BOM",
        "lat": "19.0932",
        "lon": "72.8654",
        "name": "Chhatrapati Shivaji",
        "city": "Mumbai",
        "state": "Maharashtra",
        "country": "India",
        "woeid": "12513559",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportsindia.org.inaaimumbaiindex.htm",
        "runway_length": "11446",
        "elev": "36",
        "icao": "VABB",
        "direct_flights": "86",
        "carriers": "60"
    },
    {
        "code": "HAJ",
        "lat": "52.4586",
        "lon": "9.69459",
        "name": "Hannover",
        "city": "Langenhagen",
        "state": "Lower Saxony",
        "country": "Germany",
        "woeid": "22235735",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "183",
        "icao": "EDDV",
        "direct_flights": "86",
        "carriers": "46",
        "bookingId": "-1785903"
    },
    {
        "code": "SXF",
        "lat": "52.3886",
        "lon": "13.5188",
        "name": "Berlin Schonefeld",
        "city": "Berlino Schonefeld",
        "state": "Bundesland Brandenburg",
        "country": "Germany",
        "woeid": "22276234",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "154",
        "icao": "EDDB",
        "direct_flights": "85",
        "carriers": "21",
        "bookingId": "-1746443"
    },
    {
        "code": "SYD",
        "lat": "-33.9344",
        "lon": "151.168",
        "name": "Sydney",
        "city": "Sydney",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "23388205",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13000",
        "elev": "21",
        "icao": "YSSY",
        "direct_flights": "84",
        "carriers": "55"
    },
    {
        "code": "KBP",
        "lat": "50.3405",
        "lon": "30.9025",
        "name": "Kiev Borispol",
        "city": "Kiev",
        "state": "Kyyivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518215",
        "tz": "EuropeKiev",
        "phone": "(+380 44) 490 47 77",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-borispol.kiev.ua",
        "runway_length": "11483",
        "elev": "427",
        "icao": "UKBB",
        "direct_flights": "83",
        "carriers": "64"
    },
    {
        "code": "LGA",
        "lat": "40.7731",
        "lon": "-73.8756",
        "name": "LaGuardia",
        "city": "Flushing",
        "state": "New York",
        "country": "United States",
        "woeid": "12520509",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "22",
        "icao": "KLGA",
        "direct_flights": "82",
        "carriers": "30"
    },
    {
        "code": "MRS",
        "lat": "43.4411",
        "lon": "5.22087",
        "name": "Marsiglia",
        "city": "Marsiglia",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12512931",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "69",
        "icao": "LFML",
        "direct_flights": "81",
        "carriers": "51",
        "bookingId": "-1449947"
    },
    {
        "code": "SZX",
        "lat": "22.5333",
        "lon": "113.967",
        "name": "Shenzhen",
        "city": "Shenzhen",
        "state": "Guangdong",
        "country": "China",
        "woeid": "12523239",
        "tz": "AsiaShanghai",
        "phone": "0086-755-27776047",
        "type": "Airports",
        "email": "sacabd@public.szptt.net.cn",
        "url": "http:www.szairport.comindex_e.asp",
        "runway_length": "3400",
        "elev": "3",
        "icao": "",
        "direct_flights": "81",
        "carriers": "31"
    },
    {
        "code": "BWI",
        "lat": "39.1841",
        "lon": "-76.6745",
        "name": "Baltimore-Washington International Thurgood Mars",
        "city": "Baltimore",
        "state": "Maryland",
        "country": "United States",
        "woeid": "12522341",
        "tz": "AmericaNew_York",
        "phone": "410-859-7111",
        "type": "Airports",
        "email": "",
        "url": "http:www.bwiairport.com",
        "runway_length": "9519",
        "elev": "146",
        "icao": "KBWI",
        "direct_flights": "79",
        "carriers": "39"
    },
    {
        "code": "VKO",
        "lat": "55.5978",
        "lon": "37.285",
        "name": "Ynukovo",
        "city": "Podol'sk",
        "state": "Moskovskaya Oblast",
        "country": "Russia",
        "woeid": "12517260",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "669",
        "icao": "UUWW",
        "direct_flights": "79",
        "carriers": "24"
    },
    {
        "code": "KIX",
        "lat": "34.4295",
        "lon": "135.244",
        "name": "Kansai",
        "city": "Tajiri-cho",
        "state": "Osaka Prefecture",
        "country": "Japan",
        "woeid": "12523041",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.kansai-airport.or.jp",
        "runway_length": "11400",
        "elev": null,
        "icao": "RJBB",
        "direct_flights": "78",
        "carriers": "61"
    },
    {
        "code": "LTN",
        "lat": "51.8796",
        "lon": "-0.37548",
        "name": "London Luton",
        "city": "Londra Luton",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22477104",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 1582 405 100",
        "type": "Airports",
        "email": "",
        "url": "http:www.london-luton.co.uk",
        "runway_length": "7087",
        "elev": "528",
        "icao": "EGGW",
        "direct_flights": "78",
        "carriers": "11",
        "bookingId": "-2601889"
    },
    {
        "code": "STL",
        "lat": "38.7414",
        "lon": "-90.3647",
        "name": "Lambert St Louis",
        "city": "St. Louis",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12520553",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11019",
        "elev": "605",
        "icao": "KSTL",
        "direct_flights": "78",
        "carriers": "46"
    },
    {
        "code": "DCA",
        "lat": "38.849",
        "lon": "-77.0438",
        "name": "Washington National",
        "city": "Arlington",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12522343",
        "tz": "AmericaNew_York",
        "phone": "703 417 80 00",
        "type": "Airports",
        "email": "",
        "url": "http:metwashairports.comreagan",
        "runway_length": "6869",
        "elev": "16",
        "icao": "KDCA",
        "direct_flights": "77",
        "carriers": "42"
    },
    {
        "code": "MNL",
        "lat": "14.5114",
        "lon": "121.016",
        "name": "Ninoy Aquino",
        "city": "Parañaque",
        "state": "National Capital Region",
        "country": "Philippines",
        "woeid": "12515637",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11004",
        "elev": "75",
        "icao": "RPLL",
        "direct_flights": "77",
        "carriers": "35"
    },
    {
        "code": "AUH",
        "lat": "24.4331",
        "lon": "54.6489",
        "name": "Abu Dhabi",
        "city": "Abu Dhabi",
        "state": "Abu Dhabi",
        "country": "United Arab Emirates",
        "woeid": "12517727",
        "tz": "AsiaDubai",
        "phone": "+971 2-5757500",
        "type": "Airports",
        "email": "",
        "url": "http:www.dcaauh.gov.aeenglishabudhabiintroduction.htm#",
        "runway_length": "10499",
        "elev": "15",
        "icao": "OMAA",
        "direct_flights": "76",
        "carriers": "45"
    },
    {
        "code": "RIX",
        "lat": "56.9231",
        "lon": "23.9717",
        "name": "Riga",
        "city": "Marupe",
        "state": "Rigas Rajons",
        "country": "Latvia",
        "woeid": "12514576",
        "tz": "EuropeRiga",
        "phone": "+371 720-70-09",
        "type": "Airports",
        "email": "office@riga-airport.com",
        "url": "http:www.riga-airport.com",
        "runway_length": "8366",
        "elev": "34",
        "icao": "EVRA",
        "direct_flights": "76",
        "carriers": "36"
    },
    {
        "code": "TFS",
        "lat": "28.0474",
        "lon": "-16.5705",
        "name": "Tenerife",
        "city": "Tenerife",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12517573",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.es",
        "runway_length": null,
        "elev": null,
        "icao": "GCTS",
        "direct_flights": "76",
        "carriers": "33",
        "bookingId": "-401686"
    },
    {
        "code": "VLC",
        "lat": "39.4929",
        "lon": "-0.47617",
        "name": "Valencia",
        "city": "Valencia",
        "state": "Valencia",
        "country": "Spain",
        "woeid": "12517577",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.es",
        "runway_length": "8858",
        "elev": "226",
        "icao": "LEVC",
        "direct_flights": "75",
        "carriers": "36",
        "bookingId": "-406131"
    },
    {
        "code": "CMN",
        "lat": "33.365",
        "lon": "-7.5817",
        "name": "Casablanca",
        "city": "Casablanca",
        "state": "Casablanca",
        "country": "Morocco",
        "woeid": "12514788",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12205",
        "elev": "656",
        "icao": "GMMN",
        "direct_flights": "74",
        "carriers": "31"
    },
    {
        "code": "PDX",
        "lat": "45.5867",
        "lon": "-122.587",
        "name": "Portland",
        "city": "Portland",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12521453",
        "tz": "AmericaLos_Angeles",
        "phone": "(+1) 877 739 4636",
        "type": "Airports",
        "email": "",
        "url": "http:www.flypdx.com",
        "runway_length": "11011",
        "elev": "22",
        "icao": "KPDX",
        "direct_flights": "74",
        "carriers": "43"
    },
    {
        "code": "TPA",
        "lat": "27.9744",
        "lon": "-82.5356",
        "name": "Tampa",
        "city": "Tampa",
        "state": "Florida",
        "country": "United States",
        "woeid": "12522077",
        "tz": "AmericaNew_York",
        "phone": "1(813)870-8770",
        "type": "Airports",
        "email": "",
        "url": "http:www.tampaairport.com",
        "runway_length": "11002",
        "elev": "26",
        "icao": "KTPA",
        "direct_flights": "74",
        "carriers": "48"
    },
    {
        "code": "BRS",
        "lat": "51.3848",
        "lon": "-2.70526",
        "name": "Bristol",
        "city": "Bristol",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22456683",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.bristolairport.co.uk",
        "runway_length": "6598",
        "elev": "620",
        "icao": "EGGD",
        "direct_flights": "73",
        "carriers": "22",
        "bookingId": "-2590919"
    },
    {
        "code": "ILN",
        "lat": "39.4348",
        "lon": "-83.7982",
        "name": "Airborne Airpark",
        "city": "Wilmington",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12518539",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "1071",
        "icao": "KILN",
        "direct_flights": "71",
        "carriers": "4"
    },
    {
        "code": "LPA",
        "lat": "27.9372",
        "lon": "-15.3828",
        "name": "Las Palmas",
        "city": "Telde",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12517553",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.escseeSatellite?pagename=Estandar%2FPage%2FAer",
        "runway_length": "10171",
        "elev": "76",
        "icao": "",
        "direct_flights": "71",
        "carriers": "43",
        "bookingId": "-388528"
    },
    {
        "code": "RUH",
        "lat": "24.9625",
        "lon": "46.7078",
        "name": "King Khalid",
        "city": "Riyadh",
        "state": "Ar Riyad",
        "country": "Saudi Arabia",
        "woeid": "12517349",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13287",
        "elev": "2082",
        "icao": "OERK",
        "direct_flights": "71",
        "carriers": "35"
    },
    {
        "code": "BGY",
        "lat": "45.6664",
        "lon": "9.699",
        "name": "Bergamo Orio Al Serio",
        "city": "Milano Orio Al Serio",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "22309691",
        "tz": "EuropeRome",
        "phone": "035311258",
        "type": "Airports",
        "email": "",
        "url": "http:www.sacbo.it",
        "runway_length": "9186",
        "elev": "779",
        "icao": "LIME",
        "direct_flights": "70",
        "carriers": "19",
        "bookingId": "-121726"
    },
    {
        "code": "VCE",
        "lat": "45.505",
        "lon": "12.3433",
        "name": "Venezia Marco Polo",
        "city": "Venice",
        "state": "Veneto",
        "country": "Italy",
        "woeid": "23095516",
        "tz": "EuropeRome",
        "phone": "+39 041 2606111",
        "type": "Airports",
        "email": "",
        "url": "http:www.veniceairport.it",
        "runway_length": "10827",
        "elev": "7",
        "icao": "LIPZ",
        "direct_flights": "70",
        "carriers": "51",
        "bookingId": "-132007"
    },
    {
        "code": "VCE",
        "lat": "45.505",
        "lon": "12.3433",
        "name": "Venezia Marco Polo",
        "city": "Venice",
        "state": "Veneto",
        "country": "Italy",
        "woeid": "23095516",
        "tz": "EuropeRome",
        "phone": "+39 041 2606111",
        "type": "Airports",
        "email": "",
        "url": "http:www.veniceairport.it",
        "runway_length": "10827",
        "elev": "7",
        "icao": "LIPZ",
        "direct_flights": "70",
        "carriers": "51",
        "bookingId": "-132007"
    },
    {
        "code": "NUE",
        "lat": "49.4946",
        "lon": "11.0785",
        "name": "Nurnberg",
        "city": "Norimberga",
        "state": "Bavaria",
        "country": "Germany",
        "woeid": "22257687",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "450",
        "icao": "EDDN",
        "direct_flights": "69",
        "carriers": "34",
        "bookingId": "-1835238"
    },
    {
        "code": "CUN",
        "lat": "21.0406",
        "lon": "-86.8744",
        "name": "Cancun",
        "city": "Cancun",
        "state": "Quintana Roo",
        "country": "Mexico",
        "woeid": "23388337",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "16",
        "icao": "MMUN",
        "direct_flights": "68",
        "carriers": "47"
    },
    {
        "code": "BSL",
        "lat": "47.5998",
        "lon": "7.532",
        "name": "Basel-Mulhouse-Freiburg",
        "city": "Basilea-Mulhouse-Friburgo",
        "state": "Alsace",
        "country": "France",
        "woeid": "12523049",
        "tz": "EuropeZurich",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12795",
        "elev": "883",
        "icao": "LFSB",
        "direct_flights": "67",
        "carriers": "32"
    },
    {
        "code": "EMA",
        "lat": "52.8258",
        "lon": "-1.33094",
        "name": "Derby",
        "city": "Derby",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22462097",
        "tz": "EuropeLondon",
        "phone": "0871 919 9000",
        "type": "Airports",
        "email": "",
        "url": "http:www.nottinghamema.com",
        "runway_length": "9400",
        "elev": "310",
        "icao": "EGNX",
        "direct_flights": "67",
        "carriers": "16",
        "bookingId": "-2594262"
    },
    {
        "code": "GLA",
        "lat": "55.8646",
        "lon": "-4.43234",
        "name": "Glasgow",
        "city": "Glasgow",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22482705",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.glasgowairport.com",
        "runway_length": "7625",
        "elev": "26",
        "icao": "EGPF",
        "direct_flights": "67",
        "carriers": "44",
        "bookingId": "-2597039"
    },
    {
        "code": "KWI",
        "lat": "29.2442",
        "lon": "47.9748",
        "name": "Kuwait",
        "city": "Kuwait City",
        "state": "Al Farwaniyah",
        "country": "Kuwait",
        "woeid": "23388328",
        "tz": "AsiaKuwait",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.kuwait-airport.com.kw",
        "runway_length": "11152",
        "elev": "189",
        "icao": "OKBK",
        "direct_flights": "67",
        "carriers": "52"
    },
    {
        "code": "SHJ",
        "lat": "25.3297",
        "lon": "55.5178",
        "name": "Sharjah",
        "city": "Ajman",
        "state": "Ajman",
        "country": "United Arab Emirates",
        "woeid": "12517739",
        "tz": "AsiaDubai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12336",
        "elev": "111",
        "icao": "",
        "direct_flights": "66",
        "carriers": "25"
    },
    {
        "code": "BOG",
        "lat": "4.69895",
        "lon": "-74.1435",
        "name": "Eldorado",
        "city": "Fontibón",
        "state": "Distrito Especial",
        "country": "Colombia",
        "woeid": "12512375",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aerocivil.gov.coplan%20maestroindex.htm",
        "runway_length": "12467",
        "elev": "8356",
        "icao": "SKBO",
        "direct_flights": "64",
        "carriers": "30"
    },
    {
        "code": "MCI",
        "lat": "39.2992",
        "lon": "-94.7171",
        "name": "Kansas City",
        "city": "Kansas City",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12520420",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flykci.com",
        "runway_length": "10801",
        "elev": "1025",
        "icao": "KMCI",
        "direct_flights": "64",
        "carriers": "41"
    },
    {
        "code": "YYC",
        "lat": "51.1343",
        "lon": "-114.007",
        "name": "Calgary",
        "city": "Calgary",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511594",
        "tz": "AmericaEdmonton",
        "phone": "403-735-1200",
        "type": "Airports",
        "email": "",
        "url": "http:www.yyc.com",
        "runway_length": "12675",
        "elev": "3557",
        "icao": "CYYC",
        "direct_flights": "64",
        "carriers": "33"
    },
    {
        "code": "GRO",
        "lat": "41.8992",
        "lon": "2.7646",
        "name": "Gerona",
        "city": "Girona",
        "state": "Catalonia",
        "country": "Spain",
        "woeid": "23268768",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "469",
        "icao": "LEGE",
        "direct_flights": "63",
        "carriers": "6",
        "bookingId": "-383956"
    },
    {
        "code": "GRU",
        "lat": "-23.435",
        "lon": "-46.4728",
        "name": "Guarulhos",
        "city": "Guarulhos",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511170",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12140",
        "elev": "2459",
        "icao": "SBGR",
        "direct_flights": "63",
        "carriers": "35"
    },
    {
        "code": "HHN",
        "lat": "49.9454",
        "lon": "7.26851",
        "name": "Frankfurt-Hahn",
        "city": "Francoforte",
        "state": "Rheinland-Pfalz",
        "country": "Germany",
        "woeid": "22991139",
        "tz": "EuropeBerlin",
        "phone": "+49 6543 5090",
        "type": "Airports",
        "email": "",
        "url": "http:www.hahn-airport.de",
        "runway_length": "3300",
        "elev": "1649",
        "icao": "EDFH",
        "direct_flights": "63",
        "carriers": "11",
        "bookingId": "-1771148"
    },
    {
        "code": "ALG",
        "lat": "36.6993",
        "lon": "3.21935",
        "name": "Houari Boumediene",
        "city": "Algiers",
        "state": "Alger",
        "country": "Algeria",
        "woeid": "12510331",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.egsa.dz",
        "runway_length": "11483",
        "elev": "82",
        "icao": "DAAG",
        "direct_flights": "62",
        "carriers": "20"
    },
    {
        "code": "AMM",
        "lat": "31.7231",
        "lon": "35.9936",
        "name": "Queen Alia",
        "city": "Amman",
        "state": "'Amman",
        "country": "Jordan",
        "woeid": "12514059",
        "tz": "AsiaAmman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12008",
        "elev": "2395",
        "icao": "OJAI",
        "direct_flights": "62",
        "carriers": "41"
    },
    {
        "code": "ANC",
        "lat": "61.1767",
        "lon": "-149.961",
        "name": "Anchorage",
        "city": "Anchorage",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518613",
        "tz": "AmericaAnchorage",
        "phone": "(907) 266-2526",
        "type": "Airports",
        "email": "",
        "url": "http:www.dot.state.ak.usancindex.shtml",
        "runway_length": "10897",
        "elev": "144",
        "icao": "PANC",
        "direct_flights": "62",
        "carriers": "47"
    },
    {
        "code": "BNE",
        "lat": "-27.3589",
        "lon": "153.122",
        "name": "Brisbane",
        "city": "Brisbane",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "23388207",
        "tz": "AustraliaBrisbane",
        "phone": "+61 7 3406 3000",
        "type": "Airports",
        "email": "",
        "url": "http:www.bne.com.au",
        "runway_length": "11483",
        "elev": "13",
        "icao": "YBBN",
        "direct_flights": "62",
        "carriers": "43"
    },
    {
        "code": "CTU",
        "lat": "30.5775",
        "lon": "103.941",
        "name": "Chengdushuang Liu",
        "city": "Chengdu",
        "state": "Sichuan",
        "country": "China",
        "woeid": "12512010",
        "tz": "AsiaShanghai",
        "phone": "0086-28-85702649  0086-",
        "type": "Airports",
        "email": "chengduair@cdairport.com",
        "url": "http:www.cdairport.comcdairporten_frontindex.jsp",
        "runway_length": null,
        "elev": null,
        "icao": "ZUUU",
        "direct_flights": "62",
        "carriers": "30"
    },
    {
        "code": "KMG",
        "lat": "24.966",
        "lon": "102.733",
        "name": "Wuchia Pa",
        "city": "Kunming",
        "state": "Yunnan",
        "country": "China",
        "woeid": "12512231",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "6235",
        "icao": "ZPPP",
        "direct_flights": "62",
        "carriers": "27"
    },
    {
        "code": "TUN",
        "lat": "36.8435",
        "lon": "10.2348",
        "name": "Aeroport Tunis",
        "city": "Tunis",
        "state": "Tunis",
        "country": "Tunisia",
        "woeid": "23388364",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "DTTA",
        "direct_flights": "62",
        "carriers": "26"
    },
    {
        "code": "LBA",
        "lat": "53.8685",
        "lon": "-1.66123",
        "name": "Leeds",
        "city": "Leeds",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22471856",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 113 250 9696",
        "type": "Airports",
        "email": "",
        "url": "http:www.lbia.co.uk",
        "runway_length": "7381",
        "elev": "682",
        "icao": "EGNM",
        "direct_flights": "61",
        "carriers": "34",
        "bookingId": "-2600941"
    },
    {
        "code": "LPL",
        "lat": "53.3371",
        "lon": "-2.85746",
        "name": "Liverpool",
        "city": "Liverpool",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22473264",
        "tz": "EuropeLondon",
        "phone": "+44 (0)870 129 8484",
        "type": "Airports",
        "email": "",
        "url": "http:www.liverpoolairport.com",
        "runway_length": "7500",
        "elev": "82",
        "icao": "EGGP",
        "direct_flights": "61",
        "carriers": "8",
        "bookingId": "-2601422"
    },
    {
        "code": "TAS",
        "lat": "41.2552",
        "lon": "69.2846",
        "name": "Tashkent South",
        "city": "Tashkent",
        "state": "Toshkent",
        "country": "Uzbekistan",
        "woeid": "12522725",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13123",
        "elev": "1417",
        "icao": "UTTT",
        "direct_flights": "61",
        "carriers": "25"
    },
    {
        "code": "SHA",
        "lat": "31.1865",
        "lon": "121.34",
        "name": "Hongqiao",
        "city": "Shanghai",
        "state": "Shanghai",
        "country": "China",
        "woeid": "23388248",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "15",
        "icao": "ZSSS",
        "direct_flights": "60",
        "carriers": "21"
    },
    {
        "code": "SJU",
        "lat": "18.4367",
        "lon": "-66.0095",
        "name": "Luis Munoz Marin",
        "city": "Carolina",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "23388351",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10002",
        "elev": "10",
        "icao": "TJSJ",
        "direct_flights": "60",
        "carriers": "37"
    },
    {
        "code": "CCS",
        "lat": "10.6053",
        "lon": "-66.9881",
        "name": "Simon Bolivar",
        "city": "Catia la Mar",
        "state": "Vargas",
        "country": "Venezuela",
        "woeid": "12522849",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "235",
        "icao": "SVMI",
        "direct_flights": "59",
        "carriers": "35"
    },
    {
        "code": "MKE",
        "lat": "42.9471",
        "lon": "-87.9051",
        "name": "General Mitchell",
        "city": "Milwaukee",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519880",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9690",
        "elev": "723",
        "icao": "KMKE",
        "direct_flights": "59",
        "carriers": "30"
    },
    {
        "code": "FAO",
        "lat": "37.0154",
        "lon": "-7.97197",
        "name": "Faro",
        "city": "Faro",
        "state": "Faro",
        "country": "Portugal",
        "woeid": "12515442",
        "tz": "EuropeLisbon",
        "phone": "289 800 800",
        "type": "Airports",
        "email": "",
        "url": "http:www.ana.pt",
        "runway_length": "8169",
        "elev": "24",
        "icao": "LPFR",
        "direct_flights": "58",
        "carriers": "33"
    },
    {
        "code": "GYD",
        "lat": "40.3781",
        "lon": "49.8028",
        "name": "Azerbaijan",
        "city": "Baku",
        "state": "Baki",
        "country": "Azerbaijan",
        "woeid": "23424741",
        "tz": "AsiaBaku",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UBBB",
        "direct_flights": "58",
        "carriers": "35"
    },
    {
        "code": "PSA",
        "lat": "43.6959",
        "lon": "10.3976",
        "name": "Pisa",
        "city": "Pisa",
        "state": "Tuscany",
        "country": "Italy",
        "woeid": "12513850",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.pisa-airport.com",
        "runway_length": "9800",
        "elev": "9",
        "icao": "LIRP",
        "direct_flights": "57",
        "carriers": "26",
        "bookingId": "-124918"
    },
    {
        "code": "IBZ",
        "lat": "38.8755",
        "lon": "1.36851",
        "name": "Ibiza",
        "city": "Ibiza",
        "state": "Balearic Islands",
        "country": "Spain",
        "woeid": "12517548",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "23",
        "icao": "LEIB",
        "direct_flights": "57",
        "carriers": "30",
        "bookingId": "-385722"
    },
    {
        "code": "SKG",
        "lat": "40.5208",
        "lon": "22.9722",
        "name": "Thessaloniki",
        "city": "Thessaloniki",
        "state": "Kentriki Makedonia",
        "country": "Greece",
        "woeid": "12513329",
        "tz": "EuropeAthens",
        "phone": "+30 2310 473700  2310 4",
        "type": "Airports",
        "email": "",
        "url": "http:www.hcaa-eleng.grthesdat.htm",
        "runway_length": "10",
        "elev": "22",
        "icao": "LGTS",
        "direct_flights": "57",
        "carriers": "34"
    },
    {
        "code": "NAP",
        "lat": "40.8837",
        "lon": "14.2815",
        "name": "Naples",
        "city": "Napoli",
        "state": "Campania",
        "country": "Italy",
        "woeid": "12513810",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7201",
        "elev": "289",
        "icao": "LIRN",
        "direct_flights": "56",
        "carriers": "48",
        "bookingId": "-122902"
    },
    {
        "code": "BAH",
        "lat": "26.2736",
        "lon": "50.6234",
        "name": "Bahrain",
        "city": "Al Muharraq",
        "state": "Al Manamah",
        "country": "Bahrain",
        "woeid": "23388208",
        "tz": "AsiaBahrain",
        "phone": "+973 1732 1997",
        "type": "Airports",
        "email": "",
        "url": "http:www.bahrainairport.combiaindex_bia.htm",
        "runway_length": "13002",
        "elev": "6",
        "icao": "OBBI",
        "direct_flights": "56",
        "carriers": "49"
    },
    {
        "code": "LCA",
        "lat": "34.8789",
        "lon": "33.6303",
        "name": "Cyprus",
        "city": "Cipro",
        "state": "Rep. Cyprus",
        "country": "Cyprus",
        "woeid": "12512501",
        "tz": "AsiaNicosia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "8",
        "icao": "LCLK",
        "direct_flights": "56",
        "carriers": "48"
    },
    {
        "code": "MLA",
        "lat": "35.8586",
        "lon": "14.4781",
        "name": "Malta",
        "city": "Malta",
        "state": "South Eastern",
        "country": "Malta",
        "woeid": "12514808",
        "tz": "EuropeMalta",
        "phone": "00356 2124 9600",
        "type": "Airports",
        "email": "",
        "url": "http:www.maltairport.com",
        "runway_length": "11627",
        "elev": "300",
        "icao": "LMML",
        "direct_flights": "56",
        "carriers": "35"
    },
    {
        "code": "NCL",
        "lat": "55.0374",
        "lon": "-1.70962",
        "name": "Newcastle",
        "city": "Newcastle",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22480195",
        "tz": "EuropeLondon",
        "phone": "0870 122 1488",
        "type": "Airports",
        "email": "",
        "url": "http:www.newcastleairport.com",
        "runway_length": "7651",
        "elev": "266",
        "icao": "EGNT",
        "direct_flights": "56",
        "carriers": "33",
        "bookingId": "-2603966"
    },
    {
        "code": "TOL",
        "lat": "41.5923",
        "lon": "-83.8072",
        "name": "Toledo Express",
        "city": "Swanton",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12522152",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTOL",
        "direct_flights": "56",
        "carriers": "17"
    },
    {
        "code": "HND",
        "lat": "35.5533",
        "lon": "139.771",
        "name": "Tokyo",
        "city": "Tokyo",
        "state": "Tokyo Prefecture",
        "country": "Japan",
        "woeid": "23388311",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.tokyo-airport-bldg.co.jp",
        "runway_length": null,
        "elev": null,
        "icao": "KHND",
        "direct_flights": "55",
        "carriers": "19"
    },
    {
        "code": "MDW",
        "lat": "41.7875",
        "lon": "-87.7416",
        "name": "Chicago Midway",
        "city": "Chicago",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12519178",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6519",
        "elev": "619",
        "icao": "KMDW",
        "direct_flights": "55",
        "carriers": "8"
    },
    {
        "code": "NBO",
        "lat": "-1.31697",
        "lon": "36.9222",
        "name": "Jomo Kenyatta",
        "city": "Nairobi",
        "state": "Nairobi Area",
        "country": "Kenya",
        "woeid": "12514067",
        "tz": "AfricaNairobi",
        "phone": "00254(0)20825400",
        "type": "Airports",
        "email": "",
        "url": "http:www.kenyaairport.co.ke",
        "runway_length": "13507",
        "elev": "5327",
        "icao": "HKJK",
        "direct_flights": "55",
        "carriers": "42"
    },
    {
        "code": "NGO",
        "lat": "34.8624",
        "lon": "136.811",
        "name": "Chubu",
        "city": "Tokoname-shi",
        "state": "Aichi Prefecture",
        "country": "Japan",
        "woeid": "28298951",
        "tz": "AsiaTokyo",
        "phone": "+81-569-38-1195",
        "type": "Airports",
        "email": "",
        "url": "http:www.centrair.jpenindex.html",
        "runway_length": "11513",
        "elev": "12",
        "icao": "RJGG",
        "direct_flights": "55",
        "carriers": "37"
    },
    {
        "code": "THR",
        "lat": "35.6908",
        "lon": "51.3144",
        "name": "Mehrabad",
        "city": "Tehran",
        "state": "Tehran",
        "country": "Iran",
        "woeid": "12513746",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OIII",
        "direct_flights": "55",
        "carriers": "11"
    },
    {
        "code": "XMN",
        "lat": "24.5333",
        "lon": "118.117",
        "name": "Xiamen",
        "city": "Xiamen",
        "state": "Fujian",
        "country": "China",
        "woeid": "12523266",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "59",
        "icao": "ZSAM",
        "direct_flights": "55",
        "carriers": "33"
    },
    {
        "code": "BNA",
        "lat": "36.1342",
        "lon": "-86.6682",
        "name": "Nashville",
        "city": "Nashville",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12521083",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "599",
        "icao": "KBNA",
        "direct_flights": "53",
        "carriers": "38"
    },
    {
        "code": "MEL",
        "lat": "-37.6759",
        "lon": "144.844",
        "name": "Melbourne",
        "city": "Melbourne",
        "state": "Victoria",
        "country": "Australia",
        "woeid": "23388202",
        "tz": "AustraliaMelbourne",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "434",
        "icao": "YMML",
        "direct_flights": "53",
        "carriers": "44"
    },
    {
        "code": "PTY",
        "lat": "9.07",
        "lon": "-79.3836",
        "name": "Tocumen",
        "city": "Tocumen",
        "state": "Panama",
        "country": "Panama",
        "woeid": "12515432",
        "tz": "AmericaPanama",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.tocumenpanama.aero",
        "runway_length": "10006",
        "elev": "135",
        "icao": "MPTO",
        "direct_flights": "53",
        "carriers": "23"
    },
    {
        "code": "TIP",
        "lat": "31",
        "lon": "15",
        "name": "International",
        "city": "Tripoli",
        "state": "Sawfajjin",
        "country": "Libyan Arab Jamahiriya",
        "woeid": "1352663",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "HLLT",
        "direct_flights": "53",
        "carriers": "27"
    },
    {
        "code": "BLQ",
        "lat": "44.5345",
        "lon": "11.2903",
        "name": "Bologna",
        "city": "Bologna",
        "state": "Emilia Romagna",
        "country": "Italy",
        "woeid": "12513806",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8435",
        "elev": "125",
        "icao": "LIPE",
        "direct_flights": "52",
        "carriers": "39",
        "bookingId": "-111742"
    },
    {
        "code": "AKL",
        "lat": "-37.0085",
        "lon": "174.782",
        "name": "Auckland",
        "city": "Manukau City",
        "state": "Auckland",
        "country": "New Zealand",
        "woeid": "12515151",
        "tz": "PacificAuckland",
        "phone": "+64 9 256 8899",
        "type": "Airports",
        "email": "",
        "url": "http:www.auckland-airport.co.nz",
        "runway_length": "10800",
        "elev": "23",
        "icao": "NZAA",
        "direct_flights": "52",
        "carriers": "35"
    },
    {
        "code": "DAM",
        "lat": "33.4139",
        "lon": "36.5178",
        "name": "Damascus",
        "city": "Damascus",
        "state": "Rif Dimashq",
        "country": "Syria",
        "woeid": "12517698",
        "tz": "AsiaDamascus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11811",
        "elev": "2020",
        "icao": "OSDI",
        "direct_flights": "52",
        "carriers": "39"
    },
    {
        "code": "OTP",
        "lat": "44.5656",
        "lon": "26.1029",
        "name": "Bucharest Otopeni",
        "city": "Bucarest",
        "state": "Ilfov",
        "country": "Romania",
        "woeid": "12515558",
        "tz": "EuropeBucharest",
        "phone": "+40 21 204 1200",
        "type": "Airports",
        "email": "",
        "url": "http:www.otp-airport.ro",
        "runway_length": "11483",
        "elev": "312",
        "icao": "LROP",
        "direct_flights": "52",
        "carriers": "42"
    },
    {
        "code": "TPE",
        "lat": "25.081",
        "lon": "121.237",
        "name": "Taiwan Taoyuan",
        "city": "Taoyuan City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "2306254",
        "tz": "AsiaTaipei",
        "phone": "+886 (03) 398-2036",
        "type": "Airports",
        "email": "",
        "url": "http:www.cksairport.gov.tw",
        "runway_length": "3660",
        "elev": "33",
        "icao": "RCTP",
        "direct_flights": "52",
        "carriers": "43"
    },
    {
        "code": "AYT",
        "lat": "36.9022",
        "lon": "30.7917",
        "name": "Antalya",
        "city": "Antalya",
        "state": "Antalya",
        "country": "Turkey",
        "woeid": "12517869",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11243",
        "elev": "167",
        "icao": "LTAI",
        "direct_flights": "51",
        "carriers": "24"
    },
    {
        "code": "BEY",
        "lat": "33.825",
        "lon": "35.4925",
        "name": "Beirut",
        "city": "Beirut",
        "state": "Jabal Lubnan",
        "country": "Lebanon",
        "woeid": "23388329",
        "tz": "AsiaBeirut",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.beirutairport.gov.lbindexflash.html",
        "runway_length": "10663",
        "elev": "87",
        "icao": "OLBA",
        "direct_flights": "51",
        "carriers": "47"
    },
    {
        "code": "CGK",
        "lat": "-6.11964",
        "lon": "106.656",
        "name": "Jakarta",
        "city": "Tangerang",
        "state": "Banten",
        "country": "Indonesia",
        "woeid": "23388291",
        "tz": "AsiaJakarta",
        "phone": "+62 (0)21 550 5179",
        "type": "Airports",
        "email": "",
        "url": "http:www.angkasapura2.co.id",
        "runway_length": "12008",
        "elev": "34",
        "icao": "WIII",
        "direct_flights": "51",
        "carriers": "45"
    },
    {
        "code": "NKG",
        "lat": "32.0833",
        "lon": "118.8",
        "name": "Nanjing Lukou",
        "city": "Nanjing",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "12523190",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11800",
        "elev": null,
        "icao": "ZSNG",
        "direct_flights": "51",
        "carriers": "25"
    },
    {
        "code": "OVB",
        "lat": "55.0078",
        "lon": "82.6508",
        "name": "Tolmachevo",
        "city": "Novosibirsk",
        "state": "Novosibirskaya Oblast",
        "country": "Russia",
        "woeid": "12517016",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11808",
        "elev": "364",
        "icao": "UNNT",
        "direct_flights": "51",
        "carriers": "24"
    },
    {
        "code": "SAN",
        "lat": "32.7299",
        "lon": "-117.195",
        "name": "San Diego",
        "city": "San Diego",
        "state": "California",
        "country": "United States",
        "woeid": "12520660",
        "tz": "AmericaLos_Angeles",
        "phone": "(619) 231 2100",
        "type": "Airports",
        "email": "",
        "url": "http:www.san.org",
        "runway_length": "9400",
        "elev": "15",
        "icao": "KSAN",
        "direct_flights": "51",
        "carriers": "57"
    },
    {
        "code": "AUS",
        "lat": "30.2027",
        "lon": "-97.6653",
        "name": "Austin-Bergstrom",
        "city": "Austin",
        "state": "Texas",
        "country": "United States",
        "woeid": "23418445",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.abia.org",
        "runway_length": "12248",
        "elev": "542",
        "icao": "KAUS",
        "direct_flights": "50",
        "carriers": "37"
    },
    {
        "code": "ESB",
        "lat": "40.1289",
        "lon": "32.9961",
        "name": "Esenboga",
        "city": "Çubuk",
        "state": "Ankara",
        "country": "Turkey",
        "woeid": "12517889",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12310",
        "elev": "3125",
        "icao": "LTAC",
        "direct_flights": "50",
        "carriers": "15"
    },
    {
        "code": "HER",
        "lat": "35.3397",
        "lon": "25.1836",
        "name": "Crete",
        "city": "Creta",
        "state": "Kriti",
        "country": "Greece",
        "woeid": "12513291",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8793",
        "elev": "115",
        "icao": "LGIR",
        "direct_flights": "50",
        "carriers": "28"
    },
    {
        "code": "OLB",
        "lat": "40.8994",
        "lon": "9.51616",
        "name": "Olbia",
        "city": "Terranova",
        "state": "Sardinia",
        "country": "Italy",
        "woeid": "12513840",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8022",
        "elev": "37",
        "icao": "",
        "direct_flights": "49",
        "carriers": "24",
        "bookingId": "-123255"
    },
    {
        "code": "ACE",
        "lat": "28.9521",
        "lon": "-13.6085",
        "name": "Lanzarote",
        "city": "Lanzarote",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12523048",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "46",
        "icao": "GCRR",
        "direct_flights": "49",
        "carriers": "28",
        "bookingId": "-371574"
    },
    {
        "code": "BFS",
        "lat": "54.6542",
        "lon": "-6.225",
        "name": "Aldergrove",
        "city": "Crumlin",
        "state": "Northern Ireland",
        "country": "United Kingdom",
        "woeid": "12518031",
        "tz": "EuropeLondon",
        "phone": "00 44 (0)28 9448 4848",
        "type": "Airports",
        "email": "",
        "url": "http:www.belfastairport.com",
        "runway_length": "2780",
        "elev": "267",
        "icao": "EGAA",
        "direct_flights": "49",
        "carriers": "14"
    },
    {
        "code": "DLC",
        "lat": "38.9617",
        "lon": "121.543",
        "name": "Chou Shui Tzu",
        "city": "Dalian",
        "state": "Liaoning",
        "country": "China",
        "woeid": "23388254",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.dlairport.comEnglishhome-v.htm",
        "runway_length": null,
        "elev": null,
        "icao": "KDLC",
        "direct_flights": "49",
        "carriers": "31"
    },
    {
        "code": "NAS",
        "lat": "25.0406",
        "lon": "-77.4719",
        "name": "Nassau",
        "city": "Nassau",
        "state": "New Providence",
        "country": "Bahamas",
        "woeid": "12510874",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "10",
        "icao": "MYNN",
        "direct_flights": "49",
        "carriers": "19"
    },
    {
        "code": "SOF",
        "lat": "42.6895",
        "lon": "23.4024",
        "name": "Sofia",
        "city": "Sofia",
        "state": "Sofiya-Grad",
        "country": "Bulgaria",
        "woeid": "12511552",
        "tz": "EuropeSofia",
        "phone": "+35 (92) 937-2211",
        "type": "Airports",
        "email": "",
        "url": "http:www.sofia-airport.bg",
        "runway_length": "9186",
        "elev": "1742",
        "icao": "LBSF",
        "direct_flights": "49",
        "carriers": "39"
    },
    {
        "code": "SVX",
        "lat": "56.7435",
        "lon": "60.7911",
        "name": "Koltsovo",
        "city": "Yekaterinburg",
        "state": "Sverdlovskaya Oblast",
        "country": "Russia",
        "woeid": "12516207",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "USSS",
        "direct_flights": "49",
        "carriers": "30"
    },
    {
        "code": "CKG",
        "lat": "29.5833",
        "lon": "106.5",
        "name": "Chongqing Jiangbei International",
        "city": "Chongqing",
        "state": "Chongqing",
        "country": "China",
        "woeid": "12523085",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "48",
        "carriers": "25"
    },
    {
        "code": "OPO",
        "lat": "41.2372",
        "lon": "-8.67341",
        "name": "Porto",
        "city": "Porto",
        "state": "Porto",
        "country": "Portugal",
        "woeid": "12515455",
        "tz": "EuropeLisbon",
        "phone": "229 432 400",
        "type": "Airports",
        "email": "",
        "url": "http:www.ana-aeroportos.pt",
        "runway_length": "11417",
        "elev": "228",
        "icao": "LPPR",
        "direct_flights": "48",
        "carriers": "26"
    },
    {
        "code": "RHO",
        "lat": "36.4044",
        "lon": "28.0867",
        "name": "Rhodes",
        "city": "Rodi",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513316",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10696",
        "elev": "14",
        "icao": "LGRP",
        "direct_flights": "48",
        "carriers": "21"
    },
    {
        "code": "RDU",
        "lat": "35.8729",
        "lon": "-78.7923",
        "name": "Durham",
        "city": "RaleighDurham",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12519551",
        "tz": "AmericaNew_York",
        "phone": "919 840 2123",
        "type": "Airports",
        "email": "",
        "url": "http:rdu.com",
        "runway_length": "10000",
        "elev": "437",
        "icao": "KRDU",
        "direct_flights": "47",
        "carriers": "44"
    },
    {
        "code": "TLS",
        "lat": "43.6294",
        "lon": "1.3747",
        "name": "Toulouse",
        "city": "Tolosa",
        "state": "Midi-Pyrenees",
        "country": "France",
        "woeid": "12512856",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LFBO",
        "direct_flights": "47",
        "carriers": "43",
        "bookingId": "-1473166"
    },
    {
        "code": "URC",
        "lat": "43.9333",
        "lon": "87.4667",
        "name": "Diwopu",
        "city": "Urumqi",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "12523254",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10496",
        "elev": "2129",
        "icao": "ZWWW",
        "direct_flights": "47",
        "carriers": "18"
    },
    {
        "code": "GOT",
        "lat": "57.6693",
        "lon": "12.2957",
        "name": "Gothenburg",
        "city": "Härryda",
        "state": "Vastra Gotaland",
        "country": "Sweden",
        "woeid": "12517657",
        "tz": "EuropeStockholm",
        "phone": "+4631941000",
        "type": "Airports",
        "email": "",
        "url": "http:www.landvetter.lfv.se",
        "runway_length": "10827",
        "elev": "506",
        "icao": "ESGG",
        "direct_flights": "46",
        "carriers": "43"
    },
    {
        "code": "HGH",
        "lat": "30.3332",
        "lon": "120.234",
        "name": "Jianoiao",
        "city": "Hangzhou",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "12512090",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZSHC",
        "direct_flights": "46",
        "carriers": "28"
    },
    {
        "code": "IND",
        "lat": "39.7322",
        "lon": "-86.2707",
        "name": "Indianapolis",
        "city": "Indianapolis",
        "state": "Indiana",
        "country": "United States",
        "woeid": "12520294",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10005",
        "elev": "797",
        "icao": "KIND",
        "direct_flights": "46",
        "carriers": "39"
    },
    {
        "code": "LEJ",
        "lat": "51.4197",
        "lon": "12.2201",
        "name": "Leipzig",
        "city": "Lipsia",
        "state": "Saxony",
        "country": "Germany",
        "woeid": "22275261",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "466",
        "icao": "EDDP",
        "direct_flights": "46",
        "carriers": "31",
        "bookingId": "-1817680"
    },
    {
        "code": "PER",
        "lat": "-31.9336",
        "lon": "115.961",
        "name": "Perth",
        "city": "Perth",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510761",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.perthairport.com",
        "runway_length": "11300",
        "elev": "67",
        "icao": "YPPH",
        "direct_flights": "46",
        "carriers": "36"
    },
    {
        "code": "CTA",
        "lat": "37.4696",
        "lon": "15.0672",
        "name": "Catania",
        "city": "Catania",
        "state": "Sicily",
        "country": "Italy",
        "woeid": "22307044",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroporto.catania.it",
        "runway_length": "8727",
        "elev": "43",
        "icao": "LICC",
        "direct_flights": "45",
        "carriers": "40",
        "bookingId": "-114787"
    },
    {
        "code": "AER",
        "lat": "43.447",
        "lon": "39.9552",
        "name": "Adler",
        "city": "Sochi",
        "state": "Krasnodarskiy Kray",
        "country": "Russia",
        "woeid": "12515677",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "URSS",
        "direct_flights": "45",
        "carriers": "31"
    },
    {
        "code": "CSX",
        "lat": "28.0711",
        "lon": "112.958",
        "name": "Huanghua",
        "city": "Changsha",
        "state": "Hunan",
        "country": "China",
        "woeid": "12523089",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZGHA",
        "direct_flights": "45",
        "carriers": "20"
    },
    {
        "code": "DMM",
        "lat": "26.4722",
        "lon": "49.8058",
        "name": "King Fahd",
        "city": "Khuwaylidiyah",
        "state": "Ash Sharqiyah",
        "country": "Saudi Arabia",
        "woeid": "12517346",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "72",
        "icao": "OEDF",
        "direct_flights": "45",
        "carriers": "29"
    },
    {
        "code": "FUE",
        "lat": "28.3997",
        "lon": "-13.9998",
        "name": "Fuerteventura",
        "city": "Fuerteventura",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12517563",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "75",
        "icao": "GCFV",
        "direct_flights": "45",
        "carriers": "27",
        "bookingId": "-397863"
    },
    {
        "code": "KJA",
        "lat": "56.1688",
        "lon": "92.4987",
        "name": "Yelovaya",
        "city": "Kansk",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "12517249",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UNKL",
        "direct_flights": "45",
        "carriers": "12"
    },
    {
        "code": "KRK",
        "lat": "50.0741",
        "lon": "19.8011",
        "name": "Kraków",
        "city": "Cracovia",
        "state": "Małopolskie",
        "country": "Poland",
        "woeid": "12515280",
        "tz": "EuropeWarsaw",
        "phone": "+48 12 2855120",
        "type": "Airports",
        "email": "",
        "url": "http:www.lotnisko-balice.pl",
        "runway_length": "7874",
        "elev": "790",
        "icao": "EPKK",
        "direct_flights": "45",
        "carriers": "29"
    },
    {
        "code": "PIT",
        "lat": "40.4914",
        "lon": "-80.2328",
        "name": "Pittsburgh",
        "city": "Coraopolis",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12519983",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1204",
        "icao": "KPIT",
        "direct_flights": "45",
        "carriers": "50"
    },
    {
        "code": "SGN",
        "lat": "10.8191",
        "lon": "106.658",
        "name": "Tan Son Nhut",
        "city": "Ho Chi Minh City",
        "state": "Ho Chi Minh",
        "country": "Vietnam",
        "woeid": "12522956",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.saigonairport.com",
        "runway_length": "3320",
        "elev": "9",
        "icao": "VVTS",
        "direct_flights": "45",
        "carriers": "48"
    },
    {
        "code": "ALA",
        "lat": "43.3608",
        "lon": "77.0319",
        "name": "Alma Ata",
        "city": "Almaty",
        "state": "Almaty",
        "country": "Kazakhstan",
        "woeid": "12514259",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "14427",
        "elev": "2234",
        "icao": "UAAA",
        "direct_flights": "44",
        "carriers": "36"
    },
    {
        "code": "BOD",
        "lat": "44.8291",
        "lon": "-0.70278",
        "name": "Bordeaux",
        "city": "Merignac",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512934",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10170",
        "elev": "161",
        "icao": "LFBD",
        "direct_flights": "44",
        "carriers": "33",
        "bookingId": "-1413751"
    },
    {
        "code": "EZE",
        "lat": "-34.82",
        "lon": "-58.5333",
        "name": "Ministro Pistarini",
        "city": "Ezeiza",
        "state": "Buenos Aires",
        "country": "Argentina",
        "woeid": "12510496",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "66",
        "icao": "SAEZ",
        "direct_flights": "44",
        "carriers": "36"
    },
    {
        "code": "KHI",
        "lat": "24.8984",
        "lon": "67.1518",
        "name": "Karachi Civil",
        "city": "Karachi",
        "state": "Sindh",
        "country": "Pakistan",
        "woeid": "12515241",
        "tz": "AsiaKarachi",
        "phone": "9248792",
        "type": "Airports",
        "email": "",
        "url": "http:www.karachiairport.com",
        "runway_length": "10500",
        "elev": "100",
        "icao": "OPKC",
        "direct_flights": "44",
        "carriers": "29"
    },
    {
        "code": "MAA",
        "lat": "12.9849",
        "lon": "80.1634",
        "name": "Chennai",
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "country": "India",
        "woeid": "12513629",
        "tz": "AsiaKolkata",
        "phone": "044-2340551",
        "type": "Airports",
        "email": "",
        "url": "http:chennaiairport.com",
        "runway_length": "10050",
        "elev": "50",
        "icao": "VOMM",
        "direct_flights": "44",
        "carriers": "42"
    },
    {
        "code": "SAT",
        "lat": "29.5252",
        "lon": "-98.4729",
        "name": "San Antonio",
        "city": "San Antonio",
        "state": "Texas",
        "country": "United States",
        "woeid": "12521716",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8502",
        "elev": "809",
        "icao": "KSAT",
        "direct_flights": "44",
        "carriers": "34"
    },
    {
        "code": "SHE",
        "lat": "41.7857",
        "lon": "123.529",
        "name": "Dongta",
        "city": "Shenyang",
        "state": "Liaoning",
        "country": "China",
        "woeid": "12512041",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6626",
        "elev": "157",
        "icao": "ZYTX",
        "direct_flights": "44",
        "carriers": "29"
    },
    {
        "code": "SOU",
        "lat": "50.9512",
        "lon": "-1.36139",
        "name": "Southampton",
        "city": "Southampton",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22488616",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5653",
        "elev": "44",
        "icao": "EGHI",
        "direct_flights": "44",
        "carriers": "9",
        "bookingId": "-2608164"
    },
    {
        "code": "XIY",
        "lat": "34.3667",
        "lon": "108.7",
        "name": "Hsien Yang",
        "city": "Xianyang",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "12512075",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZLXY",
        "direct_flights": "44",
        "carriers": "23"
    },
    {
        "code": "YEG",
        "lat": "53.3078",
        "lon": "-113.584",
        "name": "Edmonton",
        "city": "Leduc",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511646",
        "tz": "AmericaEdmonton",
        "phone": "(780) 890-8900",
        "type": "Airports",
        "email": "",
        "url": "http:www.edmontonairports.com",
        "runway_length": "11000",
        "elev": "2373",
        "icao": "CYEG",
        "direct_flights": "44",
        "carriers": "27"
    },
    {
        "code": "ADD",
        "lat": "8.9783",
        "lon": "38.8011",
        "name": "Bole",
        "city": "Addis Ababa",
        "state": "Debub Shewa",
        "country": "Ethiopia",
        "woeid": "12512758",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12139",
        "elev": "7625",
        "icao": "HAAB",
        "direct_flights": "43",
        "carriers": "25"
    },
    {
        "code": "EVN",
        "lat": "40.1573",
        "lon": "44.4065",
        "name": "Yerevan-Parakar",
        "city": "Yerevan",
        "state": "Armavir",
        "country": "Armenia",
        "woeid": "12510427",
        "tz": "AsiaYerevan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.zvartnots.am",
        "runway_length": null,
        "elev": "2976",
        "icao": "UDYZ",
        "direct_flights": "43",
        "carriers": "29"
    },
    {
        "code": "GDL",
        "lat": "20.5347",
        "lon": "-103.322",
        "name": "Don Miguel Hidalgo",
        "city": "Tlajomulco de Zúñiga",
        "state": "Jalisco",
        "country": "Mexico",
        "woeid": "12514863",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13120",
        "elev": "5012",
        "icao": "MMGL",
        "direct_flights": "43",
        "carriers": "29"
    },
    {
        "code": "HNL",
        "lat": "21.3358",
        "lon": "-157.919",
        "name": "Honolulu",
        "city": "Honolulu",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520216",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12360",
        "elev": "13",
        "icao": "PHNL",
        "direct_flights": "43",
        "carriers": "36"
    },
    {
        "code": "SCL",
        "lat": "-33.39",
        "lon": "-70.785",
        "name": "Arturo Merino Benitez",
        "city": "Lo Amor",
        "state": "Santiago",
        "country": "Chile",
        "woeid": "12512304",
        "tz": "AmericaSantiago",
        "phone": "(56 2) 690 17 52",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeropuertosantiago.clinglesindex.php",
        "runway_length": "10499",
        "elev": "1554",
        "icao": "SCEL",
        "direct_flights": "43",
        "carriers": "36"
    },
    {
        "code": "YOW",
        "lat": "45.3248",
        "lon": "-75.6666",
        "name": "Ottawa",
        "city": "Ottawa",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511779",
        "tz": "AmericaToronto",
        "phone": "(613) 248-2000",
        "type": "Airports",
        "email": "",
        "url": "http:www.ottawa-airport.ca",
        "runway_length": "10000",
        "elev": "374",
        "icao": "CYOW",
        "direct_flights": "43",
        "carriers": "32"
    },
    {
        "code": "CCU",
        "lat": "22.6572",
        "lon": "88.4506",
        "name": "Netaji Subhash Chandra Bose International Airpor",
        "city": "Kolkata",
        "state": "West Bengal",
        "country": "India",
        "woeid": "12513561",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11900",
        "elev": "19",
        "icao": "VECC",
        "direct_flights": "42",
        "carriers": "24"
    },
    {
        "code": "LOS",
        "lat": "6.575",
        "lon": "3.3222",
        "name": "Lagos",
        "city": "Lagos",
        "state": "Lagos",
        "country": "Nigeria",
        "woeid": "12515073",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12795",
        "elev": "135",
        "icao": "DNMM",
        "direct_flights": "42",
        "carriers": "37"
    },
    {
        "code": "SJO",
        "lat": "9.9919",
        "lon": "-84.2114",
        "name": "Juan Santamaria",
        "city": "Heredia",
        "state": "Alajuela",
        "country": "Costa Rica",
        "woeid": "12512434",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9882",
        "elev": "3046",
        "icao": "MROC",
        "direct_flights": "42",
        "carriers": "28"
    },
    {
        "code": "SSH",
        "lat": "27.975",
        "lon": "34.3897",
        "name": "Ras Nasrani",
        "city": "Al Arish",
        "state": "Janub Sina'",
        "country": "Egypt",
        "woeid": "12512706",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9596",
        "elev": "150",
        "icao": "HESH",
        "direct_flights": "42",
        "carriers": "23"
    },
    {
        "code": "WUH",
        "lat": "30.5069",
        "lon": "114.31",
        "name": "Wuchang Nanhu",
        "city": "Wuhan",
        "state": "Hubei",
        "country": "China",
        "woeid": "12512230",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZHHH",
        "direct_flights": "42",
        "carriers": "19"
    },
    {
        "code": "ABQ",
        "lat": "35.0494",
        "lon": "-106.625",
        "name": "Albuquerque",
        "city": "Albuquerque",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12518564",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13375",
        "elev": "5352",
        "icao": "KABQ",
        "direct_flights": "41",
        "carriers": "25"
    },
    {
        "code": "BDL",
        "lat": "41.9271",
        "lon": "-72.6816",
        "name": "Bradley",
        "city": "Windsor Locks",
        "state": "Connecticut",
        "country": "United States",
        "woeid": "12518915",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9502",
        "elev": "174",
        "icao": "KBDL",
        "direct_flights": "41",
        "carriers": "31"
    },
    {
        "code": "BEG",
        "lat": "44.8192",
        "lon": "20.3122",
        "name": "Surcin",
        "city": "Surčin",
        "state": "Beograd",
        "country": "Serbia",
        "woeid": "12517583",
        "tz": "EuropeBelgrade",
        "phone": "+381 11 209 4000",
        "type": "Airports",
        "email": "",
        "url": "http:www.beg.aero",
        "runway_length": "11155",
        "elev": "335",
        "icao": "LYBE",
        "direct_flights": "41",
        "carriers": "22"
    },
    {
        "code": "CMH",
        "lat": "39.9974",
        "lon": "-82.8877",
        "name": "Port Columbus",
        "city": "Columbus",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12521441",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10701",
        "elev": "816",
        "icao": "KCMH",
        "direct_flights": "41",
        "carriers": "35"
    },
    {
        "code": "DTM",
        "lat": "51.5123",
        "lon": "7.60451",
        "name": "Dortmund",
        "city": "Dortmund",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "23046924",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.dortmund-airport.com",
        "runway_length": "3455",
        "elev": "456",
        "icao": "EDLW",
        "direct_flights": "41",
        "carriers": "11",
        "bookingId": "-1761123"
    },
    {
        "code": "MSY",
        "lat": "29.983",
        "lon": "-90.2569",
        "name": "New Orleans",
        "city": "Kenner",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12521116",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flymsy.com",
        "runway_length": null,
        "elev": "4",
        "icao": "KMSY",
        "direct_flights": "41",
        "carriers": "44"
    },
    {
        "code": "SNN",
        "lat": "52.6931",
        "lon": "-8.92047",
        "name": "Shannon",
        "city": "Shannon",
        "state": "",
        "country": "Ireland",
        "woeid": "12512726",
        "tz": "EuropeDublin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.shannonairport.com",
        "runway_length": "10499",
        "elev": "47",
        "icao": "EINN",
        "direct_flights": "41",
        "carriers": "15"
    },
    {
        "code": "YHZ",
        "lat": "44.8868",
        "lon": "-63.5154",
        "name": "Halifax",
        "city": "Fall River",
        "state": "Nova Scotia",
        "country": "Canada",
        "woeid": "12511686",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.hiaa.ca",
        "runway_length": "8800",
        "elev": "477",
        "icao": "CYHZ",
        "direct_flights": "41",
        "carriers": "23"
    },
    {
        "code": "HAV",
        "lat": "22.9894",
        "lon": "-82.4075",
        "name": "Jose Marti",
        "city": "Wajay",
        "state": "Ciudad de la Habana",
        "country": "Cuba",
        "woeid": "12512466",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13123",
        "elev": "210",
        "icao": "MUHA",
        "direct_flights": "40",
        "carriers": "26"
    },
    {
        "code": "LJU",
        "lat": "46.2247",
        "lon": "14.4608",
        "name": "Ljubljana",
        "city": "Ljubljana",
        "state": "Kranj",
        "country": "Slovenia",
        "woeid": "12517513",
        "tz": "EuropeBelgrade",
        "phone": "+386 4 20 61 000",
        "type": "Airports",
        "email": "info@lju-airport.si",
        "url": "http:www.lju-airport.si",
        "runway_length": "10827",
        "elev": "1273",
        "icao": "LJLJ",
        "direct_flights": "40",
        "carriers": "28"
    },
    {
        "code": "PIK",
        "lat": "55.5091",
        "lon": "-4.6109",
        "name": "Prestwick",
        "city": "Prestwick",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22484413",
        "tz": "EuropeLondon",
        "phone": "0871 223 0700",
        "type": "Airports",
        "email": "",
        "url": "http:www.glasgowprestwick.com",
        "runway_length": "9800",
        "elev": "66",
        "icao": "EGPK",
        "direct_flights": "40",
        "carriers": "9"
    },
    {
        "code": "PUJ",
        "lat": "18.5675",
        "lon": "-68.3469",
        "name": "Punta Cana",
        "city": "Salvaleón de Higüey",
        "state": "La Altagracia",
        "country": "Dominican Republic",
        "woeid": "12512620",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5043",
        "elev": "30",
        "icao": "MDPC",
        "direct_flights": "40",
        "carriers": "27"
    },
    {
        "code": "SVQ",
        "lat": "37.4224",
        "lon": "-5.89796",
        "name": "Sevilla",
        "city": "Siviglia",
        "state": "Andalucia",
        "country": "Spain",
        "woeid": "12517571",
        "tz": "EuropeMadrid",
        "phone": "+34 954 44 90 00",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11024",
        "elev": "112",
        "icao": "LEZL",
        "direct_flights": "40",
        "carriers": "20",
        "bookingId": "-402849"
    },
    {
        "code": "IKA",
        "lat": "35.5683",
        "lon": "51.4436",
        "name": "Imam Khomeini",
        "city": "Tehran",
        "state": "Tehran",
        "country": "Iran",
        "woeid": "2245727",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OIIE",
        "direct_flights": "39",
        "carriers": "27"
    },
    {
        "code": "LIM",
        "lat": "-12.0228",
        "lon": "-77.1081",
        "name": "Jorge Chavez",
        "city": "Ventanilla",
        "state": "Provincia Constitucional del Cal",
        "country": "Peru",
        "woeid": "23388342",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11506",
        "elev": "113",
        "icao": "SPIM",
        "direct_flights": "39",
        "carriers": "32"
    },
    {
        "code": "MBJ",
        "lat": "18.5042",
        "lon": "-77.9125",
        "name": "Sangster",
        "city": "Montego Bay",
        "state": "Saint James",
        "country": "Jamaica",
        "woeid": "12514046",
        "tz": "AmericaJamaica",
        "phone": "(876) 952-3124",
        "type": "Airports",
        "email": "",
        "url": "http:www.mbjairport.com",
        "runway_length": "8700",
        "elev": "4",
        "icao": "MKJS",
        "direct_flights": "39",
        "carriers": "24"
    },
    {
        "code": "RSW",
        "lat": "26.5228",
        "lon": "-81.7531",
        "name": "Southwest Florida",
        "city": "Fort Myers",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521921",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8400",
        "elev": "31",
        "icao": "KRSW",
        "direct_flights": "39",
        "carriers": "25"
    },
    {
        "code": "SZG",
        "lat": "47.7937",
        "lon": "13.0043",
        "name": "Salzburg",
        "city": "Salisburgo",
        "state": "Salzburg",
        "country": "Austria",
        "woeid": "12510826",
        "tz": "EuropeVienna",
        "phone": "+43(0)66285800",
        "type": "Airports",
        "email": "",
        "url": "http:www.salzburg-airport.com",
        "runway_length": "8366",
        "elev": "1411",
        "icao": "",
        "direct_flights": "39",
        "carriers": "26"
    },
    {
        "code": "TSN",
        "lat": "39.1333",
        "lon": "117.35",
        "name": "Zhangguizhuang",
        "city": "Tanggu",
        "state": "Tianjin",
        "country": "China",
        "woeid": "12523246",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "39",
        "carriers": "26"
    },
    {
        "code": "VNO",
        "lat": "54.64",
        "lon": "25.2803",
        "name": "Vilnius",
        "city": "Vilnius",
        "state": "Vilniaus apskritis",
        "country": "Lithuania",
        "woeid": "12523042",
        "tz": "EuropeVilnius",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.vilnius-airport.lt",
        "runway_length": "8202",
        "elev": "623",
        "icao": "EYVI",
        "direct_flights": "39",
        "carriers": "27"
    },
    {
        "code": "PMO",
        "lat": "38.1822",
        "lon": "13.1031",
        "name": "Palermo",
        "city": "Palermo",
        "state": "Sicily",
        "country": "Italy",
        "woeid": "12513842",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.gesap.it",
        "runway_length": "9842",
        "elev": "65",
        "icao": "LICJ",
        "direct_flights": "38",
        "carriers": "36",
        "bookingId": "-123798"
    },
    {
        "code": "BLL",
        "lat": "55.742",
        "lon": "9.15354",
        "name": "Billund",
        "city": "Billund",
        "state": "Syddanmark",
        "country": "Denmark",
        "woeid": "12512589",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.billund-airport.com",
        "runway_length": "10171",
        "elev": "247",
        "icao": "EKBI",
        "direct_flights": "38",
        "carriers": "23"
    },
    {
        "code": "BRE",
        "lat": "53.0523",
        "lon": "8.78533",
        "name": "Bremen",
        "city": "Bremen",
        "state": "Bremen",
        "country": "Germany",
        "woeid": "22177393",
        "tz": "EuropeBerlin",
        "phone": "+49 421 5595-0",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-bremen.de",
        "runway_length": "6673",
        "elev": "13",
        "icao": "EDDW",
        "direct_flights": "38",
        "carriers": "27",
        "bookingId": "-1752234"
    },
    {
        "code": "CGO",
        "lat": "34.75",
        "lon": "113.65",
        "name": "Zhengzhou",
        "city": "Zhengzhou",
        "state": "Henan",
        "country": "China",
        "woeid": "12523109",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZHCC",
        "direct_flights": "38",
        "carriers": "14"
    },
    {
        "code": "DKR",
        "lat": "14.7417",
        "lon": "-17.4931",
        "name": "Dakar Yoff",
        "city": "Ngor",
        "state": "Dakar",
        "country": "Senegal",
        "woeid": "12517505",
        "tz": "AfricaDakar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11450",
        "elev": "89",
        "icao": "GOOY",
        "direct_flights": "38",
        "carriers": "38"
    },
    {
        "code": "NTE",
        "lat": "47.1573",
        "lon": "-1.60205",
        "name": "Chateau Bougon",
        "city": "Bouguenais",
        "state": "Pays de la Loire",
        "country": "France",
        "woeid": "12512881",
        "tz": "EuropeParis",
        "phone": "+33 (0)2 40 84 81 52",
        "type": "Airports",
        "email": "",
        "url": "http:www.nantes.aeroport.fr",
        "runway_length": "9514",
        "elev": "89",
        "icao": "LFRS",
        "direct_flights": "38",
        "carriers": "23"
    },
    {
        "code": "ORK",
        "lat": "51.8463",
        "lon": "-8.48897",
        "name": "Cork",
        "city": "Fivemilebridge",
        "state": "",
        "country": "Ireland",
        "woeid": "12512720",
        "tz": "EuropeDublin",
        "phone": "+353 (0)21 4313131",
        "type": "Airports",
        "email": "",
        "url": "http:www.corkairport.com",
        "runway_length": null,
        "elev": "502",
        "icao": "EICK",
        "direct_flights": "38",
        "carriers": "13"
    },
    {
        "code": "PPT",
        "lat": "-17.5578",
        "lon": "-149.611",
        "name": "Tahiti Faaa",
        "city": "Papeete",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "12512832",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11204",
        "elev": "7",
        "icao": "NTAA",
        "direct_flights": "38",
        "carriers": "12"
    },
    {
        "code": "TAO",
        "lat": "36.2311",
        "lon": "120.389",
        "name": "Liuting",
        "city": "Wanggezhuang",
        "state": "Shandong",
        "country": "China",
        "woeid": "12512130",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZSQD",
        "direct_flights": "38",
        "carriers": "23"
    },
    {
        "code": "VRN",
        "lat": "45.4033",
        "lon": "10.9053",
        "name": "Verona",
        "city": "Verona",
        "state": "Veneto",
        "country": "Italy",
        "woeid": "22592277",
        "tz": "EuropeRome",
        "phone": "+39 045 8095666",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportoverona.it",
        "runway_length": "9794",
        "elev": "238",
        "icao": "",
        "direct_flights": "38",
        "carriers": "28",
        "bookingId": "-132092"
    },
    {
        "code": "YWG",
        "lat": "49.9045",
        "lon": "-97.2253",
        "name": "Winnipeg",
        "city": "Winnipeg",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511918",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "783",
        "icao": "CYWG",
        "direct_flights": "38",
        "carriers": "17"
    },
    {
        "code": "LIN",
        "lat": "45.4558",
        "lon": "9.27269",
        "name": "Milano Linate",
        "city": "Milano Linate",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "22314960",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.sea-aeroportimilano.it",
        "runway_length": "8005",
        "elev": "353",
        "icao": "LIML",
        "direct_flights": "37",
        "carriers": "28",
        "bookingId": "-121726"
    },
    {
        "code": "TRN",
        "lat": "45.1975",
        "lon": "7.64937",
        "name": "Turin",
        "city": "Torino",
        "state": "Piedmont",
        "country": "Italy",
        "woeid": "12513862",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportoditorino.it",
        "runway_length": null,
        "elev": null,
        "icao": "LIMF",
        "direct_flights": "37",
        "carriers": "42",
        "bookingId": "-130938"
    },
    {
        "code": "ABZ",
        "lat": "57.2004",
        "lon": "-2.20373",
        "name": "Aberdeen Dyce",
        "city": "Aberdeen",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22450858",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aberdeenairport.com",
        "runway_length": "6002",
        "elev": "215",
        "icao": "EGPD",
        "direct_flights": "37",
        "carriers": "35",
        "bookingId": "-2587757"
    },
    {
        "code": "ADB",
        "lat": "38.32",
        "lon": "27.1603",
        "name": "Gaziemir",
        "city": "Azmir",
        "state": "İzmir",
        "country": "Turkey",
        "woeid": "12517893",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7700",
        "elev": "412",
        "icao": "LTBJ",
        "direct_flights": "37",
        "carriers": "10"
    },
    {
        "code": "BGO",
        "lat": "60.2907",
        "lon": "5.22065",
        "name": "Bergen Flesland",
        "city": "Blomsterdalen",
        "state": "Hordaland Fylke",
        "country": "Norway",
        "woeid": "12515104",
        "tz": "EuropeOslo",
        "phone": "+47 55 99 80 00",
        "type": "Airports",
        "email": "",
        "url": "http:www.avinor.noEnglishAirportsBergen_Airport,_Flesland",
        "runway_length": "8038",
        "elev": "165",
        "icao": "ENBR",
        "direct_flights": "37",
        "carriers": "21"
    },
    {
        "code": "BIO",
        "lat": "43.3025",
        "lon": "-2.91112",
        "name": "Bilbao",
        "city": "Loiu",
        "state": "Basque Country",
        "country": "Spain",
        "woeid": "12517541",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "136",
        "icao": "LEBB",
        "direct_flights": "37",
        "carriers": "35",
        "bookingId": "-373608"
    },
    {
        "code": "BLR",
        "lat": "12.9526",
        "lon": "77.6656",
        "name": "HAL Bangalore",
        "city": "Bangalore",
        "state": "Karnataka",
        "country": "India",
        "woeid": "12513543",
        "tz": "AsiaKolkata",
        "phone": "+91 (0)80 25228460",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10850",
        "elev": "2910",
        "icao": "VOBG",
        "direct_flights": "37",
        "carriers": "33"
    },
    {
        "code": "CMB",
        "lat": "7.1806",
        "lon": "79.8856",
        "name": "Katunayake",
        "city": "Gampaha",
        "state": "Western",
        "country": "Sri Lanka",
        "woeid": "12511941",
        "tz": "AsiaColombo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10900",
        "elev": null,
        "icao": "VCBI",
        "direct_flights": "37",
        "carriers": "26"
    },
    {
        "code": "SMF",
        "lat": "38.683",
        "lon": "-121.591",
        "name": "Sacramento",
        "city": "Sacramento",
        "state": "California",
        "country": "United States",
        "woeid": "12521693",
        "tz": "AmericaLos_Angeles",
        "phone": "916 929 5151",
        "type": "Airports",
        "email": "",
        "url": "http:www.sacairports.org",
        "runway_length": "8600",
        "elev": "23",
        "icao": "KSMF",
        "direct_flights": "37",
        "carriers": "29"
    },
    {
        "code": "SPU",
        "lat": "43.5406",
        "lon": "16.3",
        "name": "Spalato",
        "city": "Spalato",
        "state": "Splitsko-Dalmatinska",
        "country": "Croatia",
        "woeid": "12513372",
        "tz": "EuropeBelgrade",
        "phone": "+385 21 203506",
        "type": "Airports",
        "email": "",
        "url": "http:www.split-airport.hrhttp:www.split-airport.hr",
        "runway_length": "8366",
        "elev": "79",
        "icao": "LDSP",
        "direct_flights": "37",
        "carriers": "20"
    },
    {
        "code": "ZAG",
        "lat": "45.7402",
        "lon": "16.0704",
        "name": "Zagreb",
        "city": "Nagygoricza",
        "state": "Zagrebačka",
        "country": "Croatia",
        "woeid": "12513376",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.zagreb-airport.hr",
        "runway_length": "10663",
        "elev": "351",
        "icao": "LDZA",
        "direct_flights": "37",
        "carriers": "25"
    },
    {
        "code": "FUK",
        "lat": "33.5971",
        "lon": "130.443",
        "name": "Fukuoka",
        "city": "Fukuoka-shi",
        "state": "Fukuoka Prefecture",
        "country": "Japan",
        "woeid": "23388316",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9250",
        "elev": "30",
        "icao": "RJFF",
        "direct_flights": "36",
        "carriers": "31"
    },
    {
        "code": "KWL",
        "lat": "25.1921",
        "lon": "110.305",
        "name": "Li Chia Tsun",
        "city": "Guilin",
        "state": "Guangxi",
        "country": "China",
        "woeid": "12512117",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "36",
        "carriers": "23"
    },
    {
        "code": "MCT",
        "lat": "23.5917",
        "lon": "58.2792",
        "name": "Seeb",
        "city": "Muscat",
        "state": "Masqat",
        "country": "Oman",
        "woeid": "12514813",
        "tz": "AsiaMuscat",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11762",
        "elev": "48",
        "icao": "OOMS",
        "direct_flights": "36",
        "carriers": "33"
    },
    {
        "code": "NYO",
        "lat": "58.7833",
        "lon": "16.9224",
        "name": "Skavsta",
        "city": "Nykoping",
        "state": "Sodermanland",
        "country": "Sweden",
        "woeid": "12517663",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.skavsta-air.se",
        "runway_length": "8202",
        "elev": "140",
        "icao": "ESKN",
        "direct_flights": "36",
        "carriers": "5"
    },
    {
        "code": "ROV",
        "lat": "47.2556",
        "lon": "39.8169",
        "name": "Rostov East",
        "city": "Taganrog",
        "state": "Rostovskaya Oblast",
        "country": "Russia",
        "woeid": "12516787",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "253",
        "icao": "",
        "direct_flights": "36",
        "carriers": "21"
    },
    {
        "code": "BTS",
        "lat": "48.2063",
        "lon": "17.2055",
        "name": "Bratislava",
        "city": "Bratislava",
        "state": "Bratislavsky",
        "country": "Slovakia",
        "woeid": "12514608",
        "tz": "EuropeBratislava",
        "phone": "+42148571111",
        "type": "Airports",
        "email": "information@airportbratislava.sk",
        "url": "http:www.airportbratislava.sk",
        "runway_length": "9514",
        "elev": "433",
        "icao": "LZIB",
        "direct_flights": "35",
        "carriers": "16"
    },
    {
        "code": "DBV",
        "lat": "42.5631",
        "lon": "18.2692",
        "name": "Dubrovnik",
        "city": "Dubrovnik",
        "state": "Dubrovačko-neretvanska",
        "country": "Croatia",
        "woeid": "12513367",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-dubrovnik.hrmindex.php",
        "runway_length": "10824",
        "elev": "528",
        "icao": "LDDU",
        "direct_flights": "35",
        "carriers": "21"
    },
    {
        "code": "HAN",
        "lat": "21.2263",
        "lon": "105.815",
        "name": "Noi Bai",
        "city": "Hanoi",
        "state": "Ha Noi",
        "country": "Vietnam",
        "woeid": "12522936",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "26",
        "icao": "VVNB",
        "direct_flights": "35",
        "carriers": "30"
    },
    {
        "code": "LHE",
        "lat": "31.521",
        "lon": "74.3933",
        "name": "Lahore",
        "city": "Lahore",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "12515245",
        "tz": "AsiaKarachi",
        "phone": "9248792",
        "type": "Airports",
        "email": "",
        "url": "http:www.lahore-airport.com",
        "runway_length": "11024",
        "elev": "700",
        "icao": "OPLA",
        "direct_flights": "35",
        "carriers": "19"
    },
    {
        "code": "OAK",
        "lat": "37.7158",
        "lon": "-122.209",
        "name": "Oakland",
        "city": "Oakland",
        "state": "California",
        "country": "United States",
        "woeid": "12520917",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "6",
        "icao": "KOAK",
        "direct_flights": "35",
        "carriers": "19"
    },
    {
        "code": "CIA",
        "lat": "41.8004",
        "lon": "12.5908",
        "name": "Rome Ciampino",
        "city": "Roma Ciampino",
        "state": "Lazio",
        "country": "Italy",
        "woeid": "22307480",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.adr.it",
        "runway_length": "7218",
        "elev": "426",
        "icao": "LIRA",
        "direct_flights": "34",
        "carriers": "4",
        "bookingId": "-126693"
    },
    {
        "code": "FLR",
        "lat": "43.8097",
        "lon": "11.2003",
        "name": "Firenze Amerigo Vespucci",
        "city": "Florence",
        "state": "Tuscany",
        "country": "Italy",
        "woeid": "12513846",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": null,
        "icao": "LIRQ",
        "direct_flights": "34",
        "carriers": "23",
        "bookingId": "-117543"
    },
    {
        "code": "BHM",
        "lat": "33.5604",
        "lon": "-86.7492",
        "name": "Birmingham",
        "city": "Birmingham",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12518836",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "644",
        "icao": "KBHM",
        "direct_flights": "34",
        "carriers": "24"
    },
    {
        "code": "FNC",
        "lat": "32.6919",
        "lon": "-16.7768",
        "name": "Funchal",
        "city": "Madeira",
        "state": "Madeira",
        "country": "Portugal",
        "woeid": "12523046",
        "tz": "EuropeLisbon",
        "phone": "+351 291 520 700",
        "type": "Airports",
        "email": "",
        "url": "http:www.anam.pt",
        "runway_length": "5905",
        "elev": "190",
        "icao": "LPMA",
        "direct_flights": "34",
        "carriers": "23"
    },
    {
        "code": "HYD",
        "lat": "17.4522",
        "lon": "78.4629",
        "name": "Begumpet",
        "city": "Hyderabad",
        "state": "Andhra Pradesh",
        "country": "India",
        "woeid": "12513597",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9082",
        "elev": "1741",
        "icao": "VOHY",
        "direct_flights": "34",
        "carriers": "28"
    },
    {
        "code": "KGD",
        "lat": "54.8056",
        "lon": "21.3394",
        "name": "Kaliningradskaya Oblast",
        "city": "Kaliningrad",
        "state": "Kaliningradskaya Oblast",
        "country": "Russia",
        "woeid": "2346938",
        "tz": "EuropeKaliningrad",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "34",
        "carriers": "9"
    },
    {
        "code": "KTW",
        "lat": "50.4819",
        "lon": "19.0772",
        "name": "Zendek",
        "city": "Katowice",
        "state": "Sląskie",
        "country": "Poland",
        "woeid": "12515408",
        "tz": "EuropeWarsaw",
        "phone": "0048 32 39 27 200",
        "type": "Airports",
        "email": "",
        "url": "http:www.gtl.com.pl",
        "runway_length": "9183",
        "elev": "995",
        "icao": "EPKT",
        "direct_flights": "34",
        "carriers": "9"
    },
    {
        "code": "KUF",
        "lat": "53.5067",
        "lon": "50.166",
        "name": "Kurumoch",
        "city": "Syzran'",
        "state": "Samarskaya Oblast",
        "country": "Russia",
        "woeid": "12516311",
        "tz": "EuropeSamara",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9846",
        "elev": "479",
        "icao": "",
        "direct_flights": "34",
        "carriers": "22"
    },
    {
        "code": "SAW",
        "lat": "40.9928",
        "lon": "29.034",
        "name": "Istanbul Sabiha Gokcen",
        "city": "Istanbul",
        "state": "Istanbul",
        "country": "Turkey",
        "woeid": "23416958",
        "tz": "EuropeIstanbul",
        "phone": "+90 216 585 50 00",
        "type": "Airports",
        "email": "",
        "url": "http:www.sgairport.com",
        "runway_length": "9843",
        "elev": "90",
        "icao": "LTFJ",
        "direct_flights": "34",
        "carriers": "18"
    },
    {
        "code": "SJC",
        "lat": "37.3679",
        "lon": "-121.926",
        "name": "Norman Y Mineta San Jose",
        "city": "San Jose",
        "state": "California",
        "country": "United States",
        "woeid": "12521722",
        "tz": "AmericaLos_Angeles",
        "phone": "(408) 501-7600",
        "type": "Airports",
        "email": "",
        "url": "http:www.sjc.org",
        "runway_length": "11000",
        "elev": "56",
        "icao": "KSJC",
        "direct_flights": "34",
        "carriers": "33"
    },
    {
        "code": "TBS",
        "lat": "41.6716",
        "lon": "44.9556",
        "name": "Tbilisi-Noyo Alekseyevka",
        "city": "Tbilisi",
        "state": "Gardabanis Raioni",
        "country": "Georgia",
        "woeid": "12513015",
        "tz": "AsiaTbilisi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UGTB",
        "direct_flights": "34",
        "carriers": "25"
    },
    {
        "code": "CFU",
        "lat": "39.6013",
        "lon": "19.9136",
        "name": "Corfù",
        "city": "Corfu",
        "state": "Nisia Ionioy",
        "country": "Greece",
        "woeid": "12513298",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.hcaa.grcontentindex.asp",
        "runway_length": "7792",
        "elev": "13",
        "icao": "LGKR",
        "direct_flights": "33",
        "carriers": "14"
    },
    {
        "code": "HOU",
        "lat": "29.6572",
        "lon": "-95.2795",
        "name": "William P Hobby",
        "city": "Houston",
        "state": "Texas",
        "country": "United States",
        "woeid": "12522451",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7602",
        "elev": "47",
        "icao": "KHOU",
        "direct_flights": "33",
        "carriers": "12"
    },
    {
        "code": "HRG",
        "lat": "27.1858",
        "lon": "33.7981",
        "name": "Hurghada",
        "city": "Bor Safajah",
        "state": "Al Bahr al Ahmar",
        "country": "Egypt",
        "woeid": "12512692",
        "tz": "AfricaCairo",
        "phone": "+20 (0)65 442831",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "52",
        "icao": "HEGN",
        "direct_flights": "33",
        "carriers": "15"
    },
    {
        "code": "POM",
        "lat": "-9.4425",
        "lon": "147.219",
        "name": "Port Moresby",
        "city": "Port Moresby",
        "state": "National Capital",
        "country": "Papua New Guinea",
        "woeid": "12515477",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "148",
        "icao": "AYPY",
        "direct_flights": "33",
        "carriers": "3"
    },
    {
        "code": "TIA",
        "lat": "41.4247",
        "lon": "19.7147",
        "name": "Tirane Rinas",
        "city": "Tirana",
        "state": "Durrës",
        "country": "Albania",
        "woeid": "12510418",
        "tz": "EuropeTirane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "www.tirana-airport.com",
        "runway_length": null,
        "elev": null,
        "icao": "LATI",
        "direct_flights": "33",
        "carriers": "17"
    },
    {
        "code": "BBU",
        "lat": "44.4966",
        "lon": "26.087",
        "name": "Bucharest Aurel Vlaicu",
        "city": "Bucarest",
        "state": "Bucuresti",
        "country": "Romania",
        "woeid": "23388350",
        "tz": "EuropeBucharest",
        "phone": "+40 21 232 0020",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "295",
        "icao": "LRBS",
        "direct_flights": "32",
        "carriers": "5"
    },
    {
        "code": "BSB",
        "lat": "-15.8622",
        "lon": "-47.9122",
        "name": "Juscelino Kubitschek",
        "city": "Lago Sul",
        "state": "Distrito Federal",
        "country": "Brazil",
        "woeid": "12511058",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.infraero.gov.br",
        "runway_length": "10499",
        "elev": "3473",
        "icao": "SBBR",
        "direct_flights": "32",
        "carriers": "10"
    },
    {
        "code": "CRL",
        "lat": "50.4601",
        "lon": "4.45878",
        "name": "Bruxelles",
        "city": "Brusselles Charleroi",
        "state": "Hainault",
        "country": "Belgium",
        "woeid": "22048658",
        "tz": "EuropeBrussels",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "614",
        "icao": "EBCI",
        "direct_flights": "32",
        "carriers": "3"
    },
    {
        "code": "DAC",
        "lat": "23.8379",
        "lon": "90.3948",
        "name": "Zia Dhaka",
        "city": "Dhaka",
        "state": "Dhaka",
        "country": "Bangladesh",
        "woeid": "12510895",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "26",
        "icao": "VGZR",
        "direct_flights": "32",
        "carriers": "31"
    },
    {
        "code": "FAI",
        "lat": "64.8183",
        "lon": "-147.866",
        "name": "Fairbanks",
        "city": "Fairbanks",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519682",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10300",
        "elev": "434",
        "icao": "PAFA",
        "direct_flights": "32",
        "carriers": "18"
    },
    {
        "code": "GIG",
        "lat": "-22.8094",
        "lon": "-43.25",
        "name": "Rio de Janeiro-Antonio Carlos Jobim Internationa",
        "city": "Rio de Janeiro",
        "state": "Rio de Janeiro",
        "country": "Brazil",
        "woeid": "12511292",
        "tz": "AmericaSao_Paulo",
        "phone": "+55(21)3398-5050",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13123",
        "elev": "30",
        "icao": "SBGL",
        "direct_flights": "32",
        "carriers": "25"
    },
    {
        "code": "HRB",
        "lat": "45.6281",
        "lon": "126.252",
        "name": "Harbin Yangjiagang",
        "city": "Harbin",
        "state": "Heilongjiang",
        "country": "China",
        "woeid": "12512069",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZYHB",
        "direct_flights": "32",
        "carriers": "24"
    },
    {
        "code": "IFP",
        "lat": "35.1657",
        "lon": "-114.557",
        "name": "Laughlin-Bullhead",
        "city": "Bullhead City",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12518972",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KIFP",
        "direct_flights": "32",
        "carriers": "1"
    },
    {
        "code": "JAX",
        "lat": "30.4914",
        "lon": "-81.676",
        "name": "Jacksonville",
        "city": "Jacksonville",
        "state": "Florida",
        "country": "United States",
        "woeid": "12520339",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "30",
        "icao": "KJAX",
        "direct_flights": "32",
        "carriers": "31"
    },
    {
        "code": "KTM",
        "lat": "27.6969",
        "lon": "85.3594",
        "name": "Tribhuvan",
        "city": "Kathmandu",
        "state": "Central",
        "country": "Nepal",
        "woeid": "12515135",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10121",
        "elev": "4420",
        "icao": "",
        "direct_flights": "32",
        "carriers": "26"
    },
    {
        "code": "SDF",
        "lat": "38.1865",
        "lon": "-85.7462",
        "name": "Louisville",
        "city": "Louisville",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "12521983",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "496",
        "icao": "KSDF",
        "direct_flights": "32",
        "carriers": "26"
    },
    {
        "code": "SXM",
        "lat": "18.0421",
        "lon": "-63.1107",
        "name": "Prinses Juliana",
        "city": "",
        "state": "St Maarten",
        "country": "Netherlands Antilles",
        "woeid": "12515140",
        "tz": "AmericaCuracao",
        "phone": "+599 545 2060",
        "type": "Airports",
        "email": "",
        "url": "http:www.pjiae.com",
        "runway_length": "7054",
        "elev": "13",
        "icao": "TNCM",
        "direct_flights": "32",
        "carriers": "23"
    },
    {
        "code": "TNA",
        "lat": "36.3306",
        "lon": "118.761",
        "name": "Shandong",
        "city": "Jinan",
        "state": "Shandong",
        "country": "China",
        "woeid": "12578014",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "32",
        "carriers": "14"
    },
    {
        "code": "BRI",
        "lat": "41.1376",
        "lon": "16.7674",
        "name": "Bari",
        "city": "Bari",
        "state": "Puglia",
        "country": "Italy",
        "woeid": "12513843",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.seap-puglia.it",
        "runway_length": "7283",
        "elev": "167",
        "icao": "LIBD",
        "direct_flights": "31",
        "carriers": "28",
        "bookingId": "-111255"
    },
    {
        "code": "AEP",
        "lat": "-34.5617",
        "lon": "-58.4113",
        "name": "Aeroparque Jorge Newbery",
        "city": "Buenos Aires",
        "state": "Ciudad de Buenos Aires",
        "country": "Argentina",
        "woeid": "23388199",
        "tz": "AmericaBuenos_Aires",
        "phone": "(54 11) 5480 6111",
        "type": "Airports",
        "email": "",
        "url": "http:www.aa2000.com.ar",
        "runway_length": "6890",
        "elev": "20",
        "icao": "SABE",
        "direct_flights": "31",
        "carriers": "4"
    },
    {
        "code": "HAK",
        "lat": "20.0204",
        "lon": "110.348",
        "name": "Haikou",
        "city": "Haikou",
        "state": "Hainan",
        "country": "China",
        "woeid": "12512065",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "31",
        "carriers": "17"
    },
    {
        "code": "ISB",
        "lat": "33.6147",
        "lon": "73.0967",
        "name": "Islamabad",
        "city": "Råwalpindi",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "12515238",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.islamabadairport.com.pk",
        "runway_length": "9000",
        "elev": "1665",
        "icao": "KISB",
        "direct_flights": "31",
        "carriers": "18"
    },
    {
        "code": "KHV",
        "lat": "48.5241",
        "lon": "135.171",
        "name": "Khabarovsk Northeast",
        "city": "Khabarovsk",
        "state": "Khabarovskiy Kray",
        "country": "Russia",
        "woeid": "12516130",
        "tz": "AsiaSakhalin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13123",
        "elev": "240",
        "icao": "",
        "direct_flights": "31",
        "carriers": "16"
    },
    {
        "code": "KRT",
        "lat": "15.5917",
        "lon": "32.5533",
        "name": "Khartoum",
        "city": "Khartoum",
        "state": "Al Khartum",
        "country": "Sudan",
        "woeid": "12517601",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1261",
        "icao": "",
        "direct_flights": "31",
        "carriers": "31"
    },
    {
        "code": "LCY",
        "lat": "51.5039",
        "lon": "0.04981",
        "name": "London City",
        "city": "Londra City",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22475376",
        "tz": "EuropeLondon",
        "phone": "02076460000",
        "type": "Airports",
        "email": "",
        "url": "http:www.londoncityairport.com",
        "runway_length": "4327",
        "elev": "6",
        "icao": "EGLC",
        "direct_flights": "31",
        "carriers": "13",
        "bookingId": "-2601889"
    },
    {
        "code": "MFM",
        "lat": "22.1845",
        "lon": "113.532",
        "name": "Macau",
        "city": "Macau",
        "state": "Macau",
        "country": "Macau",
        "woeid": "12523336",
        "tz": "AsiaMacau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3214",
        "elev": "1",
        "icao": "VMMC",
        "direct_flights": "31",
        "carriers": "20"
    },
    {
        "code": "MIR",
        "lat": "35.7611",
        "lon": "10.7556",
        "name": "Habib Bourguiba",
        "city": "Sidi al Ghudamisi",
        "state": "Al Munastir",
        "country": "Tunisia",
        "woeid": "12517860",
        "tz": "AfricaTunis",
        "phone": "+216 (0)73 521.300",
        "type": "Airports",
        "email": "",
        "url": "http:www.oaca.nat.tn",
        "runway_length": "9678",
        "elev": "9",
        "icao": "",
        "direct_flights": "31",
        "carriers": "10"
    },
    {
        "code": "MTY",
        "lat": "25.7783",
        "lon": "-100.107",
        "name": "Gen Mariano Escobedo",
        "city": "Pesquería",
        "state": "Nuevo Leon",
        "country": "Mexico",
        "woeid": "12514877",
        "tz": "AmericaMonterrey",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1269",
        "icao": "MMMY",
        "direct_flights": "31",
        "carriers": "24"
    },
    {
        "code": "NRN",
        "lat": "51.6031",
        "lon": "6.1417",
        "name": "Weeze",
        "city": "Weeze",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "12513156",
        "tz": "EuropeBerlin",
        "phone": "+49 (0) 28 37  66 61 11",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-weeze.de",
        "runway_length": "2440",
        "elev": "32",
        "icao": "KNRN",
        "direct_flights": "31",
        "carriers": "1"
    },
    {
        "code": "PBI",
        "lat": "26.6909",
        "lon": "-80.0889",
        "name": "Palm Beach",
        "city": "West Palm Beach",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521288",
        "tz": "AmericaNew_York",
        "phone": "(561) 471-7420",
        "type": "Airports",
        "email": "",
        "url": "http:www.pbia.org",
        "runway_length": "7989",
        "elev": "19",
        "icao": "KPBI",
        "direct_flights": "31",
        "carriers": "24"
    },
    {
        "code": "PUS",
        "lat": "35.1777",
        "lon": "128.937",
        "name": "Kimhae",
        "city": "Busan",
        "state": "Busan",
        "country": "South Korea",
        "woeid": "23388327",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "13",
        "icao": "RKPP",
        "direct_flights": "31",
        "carriers": "26"
    },
    {
        "code": "TLL",
        "lat": "59.4121",
        "lon": "24.8297",
        "name": "Ulemiste",
        "city": "Tallinn",
        "state": "Harjumaa",
        "country": "Estonia",
        "woeid": "12512744",
        "tz": "EuropeTallinn",
        "phone": "+372 605 8701",
        "type": "Airports",
        "email": "",
        "url": "http:www.tallinn-airport.ee",
        "runway_length": "3070",
        "elev": "131",
        "icao": "EETN",
        "direct_flights": "31",
        "carriers": "25"
    },
    {
        "code": "TYN",
        "lat": "37.774",
        "lon": "112.614",
        "name": "Taiyuan Wusu",
        "city": "Taiyuan",
        "state": "Shanxi",
        "country": "China",
        "woeid": "12512207",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "31",
        "carriers": "14"
    },
    {
        "code": "CAG",
        "lat": "39.2527",
        "lon": "9.05813",
        "name": "Cagliari",
        "city": "Cagliari",
        "state": "Sardinia",
        "country": "Italy",
        "woeid": "12513818",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "13",
        "icao": "KCAG",
        "direct_flights": "30",
        "carriers": "23",
        "bookingId": "-112463"
    },
    {
        "code": "CTS",
        "lat": "42.7757",
        "lon": "141.691",
        "name": "New Chitose",
        "city": "Chitose-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "12523091",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "87",
        "icao": "RJCC",
        "direct_flights": "30",
        "carriers": "28"
    },
    {
        "code": "DPS",
        "lat": "-8.7486",
        "lon": "115.165",
        "name": "Bali",
        "city": "Denpasar",
        "state": "Bali",
        "country": "Indonesia",
        "woeid": "12513453",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "14",
        "icao": "WADD",
        "direct_flights": "30",
        "carriers": "31"
    },
    {
        "code": "JER",
        "lat": "49.205",
        "lon": "-2.19729",
        "name": "Jersey",
        "city": "St. Peter",
        "state": "Channel Islands",
        "country": "United Kingdom",
        "woeid": "12514041",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.jerseyairport.com",
        "runway_length": "5597",
        "elev": "276",
        "icao": "KJER",
        "direct_flights": "30",
        "carriers": "16"
    },
    {
        "code": "KEF",
        "lat": "63.9853",
        "lon": "-22.6042",
        "name": "Reykjavik",
        "city": "Reykjavik",
        "state": "Keflavik",
        "country": "Iceland",
        "woeid": "12513445",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10058",
        "elev": "169",
        "icao": "BIKF",
        "direct_flights": "30",
        "carriers": "9"
    },
    {
        "code": "LNZ",
        "lat": "48.2338",
        "lon": "14.1905",
        "name": "Linz",
        "city": "Hoersching",
        "state": "Upper Austria",
        "country": "Austria",
        "woeid": "22016347",
        "tz": "EuropeVienna",
        "phone": "+43(0)72216000",
        "type": "Airports",
        "email": "",
        "url": "http:www.linz-airport.at",
        "runway_length": "9219",
        "elev": "978",
        "icao": "",
        "direct_flights": "30",
        "carriers": "17"
    },
    {
        "code": "MRU",
        "lat": "-20.4272",
        "lon": "57.6767",
        "name": "Plaisance",
        "city": "Mahebourg",
        "state": "Grand Port",
        "country": "Mauritius",
        "woeid": "12514796",
        "tz": "IndianMauritius",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8498",
        "elev": "186",
        "icao": "FIMP",
        "direct_flights": "30",
        "carriers": "18"
    },
    {
        "code": "RAK",
        "lat": "31.6167",
        "lon": "-8.05",
        "name": "Marrakesh",
        "city": "Marrakesh",
        "state": "Marrakech",
        "country": "Morocco",
        "woeid": "12523044",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "1535",
        "icao": "GMMX",
        "direct_flights": "30",
        "carriers": "20"
    },
    {
        "code": "SXB",
        "lat": "48.5427",
        "lon": "7.63466",
        "name": "Entzheim",
        "city": "Entzheim",
        "state": "Alsace",
        "country": "France",
        "woeid": "12512892",
        "tz": "EuropeParis",
        "phone": "+ 33 3 88 64 67 67",
        "type": "Airports",
        "email": "",
        "url": "http:www.strasbourg.aeroport.fr",
        "runway_length": "7874",
        "elev": "502",
        "icao": "LFST",
        "direct_flights": "30",
        "carriers": "20"
    },
    {
        "code": "TSE",
        "lat": "51.0266",
        "lon": "71.4782",
        "name": "Tselinograd South",
        "city": "Aqmola",
        "state": "Aqmola",
        "country": "Kazakhstan",
        "woeid": "12514494",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "30",
        "carriers": "18"
    },
    {
        "code": "TSR",
        "lat": "45.8047",
        "lon": "21.3377",
        "name": "Timisoara Northeast",
        "city": "",
        "state": "Timis",
        "country": "Romania",
        "woeid": "12515586",
        "tz": "EuropeBucharest",
        "phone": "+40 256 493 123",
        "type": "Airports",
        "email": "",
        "url": "http:www.aerotim.ro",
        "runway_length": "3500",
        "elev": "106",
        "icao": "LRTR",
        "direct_flights": "30",
        "carriers": "9"
    },
    {
        "code": "BET",
        "lat": "60.7788",
        "lon": "-161.847",
        "name": "Bethel",
        "city": "Bethel",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518815",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6399",
        "elev": "131",
        "icao": "PABE",
        "direct_flights": "29",
        "carriers": "11"
    },
    {
        "code": "BUF",
        "lat": "42.9309",
        "lon": "-78.738",
        "name": "Greater Buffalo",
        "city": "Buffalo",
        "state": "New York",
        "country": "United States",
        "woeid": "12519977",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.nfta.comairport",
        "runway_length": "15",
        "elev": "724",
        "icao": "KBUF",
        "direct_flights": "29",
        "carriers": "30"
    },
    {
        "code": "CNS",
        "lat": "-16.8831",
        "lon": "145.753",
        "name": "Cairns",
        "city": "Cairns",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510623",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10489",
        "elev": "10",
        "icao": "YBCS",
        "direct_flights": "29",
        "carriers": "20"
    },
    {
        "code": "GDN",
        "lat": "54.3821",
        "lon": "18.454",
        "name": "Rebiechowo",
        "city": "Gdansk",
        "state": "Pomorskie",
        "country": "Poland",
        "woeid": "12515381",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.gdansk.pl",
        "runway_length": "9186",
        "elev": "489",
        "icao": "EPGD",
        "direct_flights": "29",
        "carriers": "12"
    },
    {
        "code": "GRZ",
        "lat": "46.9902",
        "lon": "15.4389",
        "name": "Graz",
        "city": "Feldkirchen",
        "state": "Styria",
        "country": "Austria",
        "woeid": "22014356",
        "tz": "EuropeVienna",
        "phone": "+43(0)31629020",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafen-graz.at",
        "runway_length": "9055",
        "elev": "1115",
        "icao": "LOWG",
        "direct_flights": "29",
        "carriers": "18"
    },
    {
        "code": "HET",
        "lat": "40.8543",
        "lon": "111.821",
        "name": "Huhehaote",
        "city": "Hohhot",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "12512080",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZBHH",
        "direct_flights": "29",
        "carriers": "14"
    },
    {
        "code": "KGS",
        "lat": "36.7956",
        "lon": "27.0917",
        "name": "Kos",
        "city": "Kos",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513301",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "410",
        "icao": "LGKO",
        "direct_flights": "29",
        "carriers": "10"
    },
    {
        "code": "KWE",
        "lat": "26.9015",
        "lon": "106.585",
        "name": "Guizhou",
        "city": "Guiyang",
        "state": "Guizhou",
        "country": "China",
        "woeid": "12578007",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "29",
        "carriers": "14"
    },
    {
        "code": "LAD",
        "lat": "-8.8547",
        "lon": "13.2342",
        "name": "Luanda 4 de Fevereiro",
        "city": "Luanda",
        "state": "Luanda",
        "country": "Angola",
        "woeid": "12510439",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12139",
        "elev": "243",
        "icao": "",
        "direct_flights": "29",
        "carriers": "13"
    },
    {
        "code": "MJV",
        "lat": "37.7697",
        "lon": "-0.82098",
        "name": "Murcia San Javier",
        "city": "San Javier",
        "state": "Murcia",
        "country": "Spain",
        "woeid": "12517559",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "11",
        "icao": "LELC",
        "direct_flights": "29",
        "carriers": "9"
    },
    {
        "code": "OKC",
        "lat": "35.3981",
        "lon": "-97.5974",
        "name": "Will Rogers World",
        "city": "Oklahoma City",
        "state": "Oklahoma",
        "country": "United States",
        "woeid": "12522444",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9802",
        "elev": "1295",
        "icao": "KOKC",
        "direct_flights": "29",
        "carriers": "28"
    },
    {
        "code": "ONT",
        "lat": "34.062",
        "lon": "-117.594",
        "name": "Ontario",
        "city": "Ontario",
        "state": "California",
        "country": "United States",
        "woeid": "12521228",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12198",
        "elev": "944",
        "icao": "KONT",
        "direct_flights": "29",
        "carriers": "17"
    },
    {
        "code": "SAH",
        "lat": "15.48",
        "lon": "44.2208",
        "name": "Sanaa",
        "city": "Ar Raudha",
        "state": "San`a´",
        "country": "Yemen",
        "woeid": "12523005",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "7216",
        "icao": "OYSN",
        "direct_flights": "29",
        "carriers": "18"
    },
    {
        "code": "SGC",
        "lat": "61.3468",
        "lon": "73.4182",
        "name": "Surgut North",
        "city": "Khanty-Mansiysk",
        "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
        "country": "Russia",
        "woeid": "12516957",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "29",
        "carriers": "8"
    },
    {
        "code": "SIP",
        "lat": "45.0153",
        "lon": "33.9941",
        "name": "Simferopol North",
        "city": "Simferopol'",
        "state": "Krym, Avtonomna Respublika",
        "country": "Ukraine",
        "woeid": "12518424",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12158",
        "elev": "637",
        "icao": "",
        "direct_flights": "29",
        "carriers": "26"
    },
    {
        "code": "TJM",
        "lat": "57.1704",
        "lon": "65.3561",
        "name": "Tyumen Northwest",
        "city": "Tyumen'",
        "state": "Tyumenskaya Oblast",
        "country": "Russia",
        "woeid": "12517062",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "29",
        "carriers": "10"
    },
    {
        "code": "VVO",
        "lat": "43.3972",
        "lon": "132.156",
        "name": "Artem North",
        "city": "Nakhodka",
        "state": "Primorskiy Kray",
        "country": "Russia",
        "woeid": "12515734",
        "tz": "AsiaVladivostok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.vladavia.ruavia_engcompany_airport.php3",
        "runway_length": null,
        "elev": null,
        "icao": "UHWW",
        "direct_flights": "29",
        "carriers": "14"
    },
    {
        "code": "SUF",
        "lat": "38.9087",
        "lon": "16.2443",
        "name": "Lamezia Terme",
        "city": "Lamezia Terme",
        "state": "Calabria",
        "country": "Italy",
        "woeid": "12513830",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lameziatermeairport.it",
        "runway_length": "7874",
        "elev": "41",
        "icao": "LICA",
        "direct_flights": "28",
        "carriers": "28",
        "bookingId": "-123036"
    },
    {
        "code": "ANU",
        "lat": "17.1375",
        "lon": "-61.79",
        "name": "V C Bird",
        "city": "Carlisle",
        "state": "Saint George",
        "country": "Antigua and Barbuda",
        "woeid": "12510300",
        "tz": "AmericaAntigua",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "62",
        "icao": "TAPA",
        "direct_flights": "28",
        "carriers": "14"
    },
    {
        "code": "EXT",
        "lat": "50.7303",
        "lon": "-3.4166",
        "name": "Exeter",
        "city": "Exeter",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22464406",
        "tz": "EuropeLondon",
        "phone": "+44 (0)1392 367433",
        "type": "Airports",
        "email": "",
        "url": "http:www.exeter-airport.co.uk",
        "runway_length": "6834",
        "elev": "102",
        "icao": "EGTE",
        "direct_flights": "28",
        "carriers": "3",
        "bookingId": "-2595805"
    },
    {
        "code": "FMO",
        "lat": "52.1312",
        "lon": "7.69163",
        "name": "Munster-Osnabruck",
        "city": "Greven",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "22206577",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafen-fmo.de",
        "runway_length": "7119",
        "elev": "158",
        "icao": "EDDG",
        "direct_flights": "28",
        "carriers": "20"
    },
    {
        "code": "KRR",
        "lat": "45.0441",
        "lon": "39.1442",
        "name": "Krasnodar-Pashovskiy",
        "city": "Krasnodar",
        "state": "Krasnodarskiy Kray",
        "country": "Russia",
        "woeid": "12516256",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "28",
        "carriers": "20"
    },
    {
        "code": "MAH",
        "lat": "39.8652",
        "lon": "4.22383",
        "name": "Menorca",
        "city": "Minorca",
        "state": "Balearic Islands",
        "country": "Spain",
        "woeid": "12517557",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7710",
        "elev": "287",
        "icao": "",
        "direct_flights": "28",
        "carriers": "18",
        "bookingId": "-390720"
    },
    {
        "code": "MHD",
        "lat": "36.2358",
        "lon": "59.6392",
        "name": "Mashhad",
        "city": "Mashhad",
        "state": "Khorasan",
        "country": "Iran",
        "woeid": "12513744",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12497",
        "elev": "3263",
        "icao": "",
        "direct_flights": "28",
        "carriers": "11"
    },
    {
        "code": "MSQ",
        "lat": "53.8873",
        "lon": "28.0344",
        "name": "Minsk",
        "city": "Minsk",
        "state": "Minskaya Voblasts'",
        "country": "Belarus",
        "woeid": "12510995",
        "tz": "EuropeMinsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "742",
        "icao": "UMMS",
        "direct_flights": "28",
        "carriers": "17"
    },
    {
        "code": "OKA",
        "lat": "24.8289",
        "lon": "125.146",
        "name": "Shimojishima",
        "city": "Naha-shi",
        "state": "Okinawa Prefecture",
        "country": "Japan",
        "woeid": "12514018",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "15",
        "icao": "ROAH",
        "direct_flights": "28",
        "carriers": "13"
    },
    {
        "code": "ORF",
        "lat": "36.8977",
        "lon": "-76.2154",
        "name": "Norfolk",
        "city": "Norfolk",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12521146",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "27",
        "icao": "KORF",
        "direct_flights": "28",
        "carriers": "27"
    },
    {
        "code": "PFO",
        "lat": "34.7194",
        "lon": "32.4847",
        "name": "Paphos",
        "city": "Paphos",
        "state": "Rep. Cyprus",
        "country": "Cyprus",
        "woeid": "12512502",
        "tz": "AsiaNicosia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "41",
        "icao": "LCPH",
        "direct_flights": "28",
        "carriers": "15"
    },
    {
        "code": "SVG",
        "lat": "58.8806",
        "lon": "5.63019",
        "name": "Stavanger Sola",
        "city": "Rage",
        "state": "Rogaland Fylke",
        "country": "Norway",
        "woeid": "12515124",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "29",
        "icao": "ENZV",
        "direct_flights": "28",
        "carriers": "26"
    },
    {
        "code": "ALB",
        "lat": "42.7426",
        "lon": "-73.809",
        "name": "Albany",
        "city": "Latham",
        "state": "New York",
        "country": "United States",
        "woeid": "12518554",
        "tz": "AmericaNew_York",
        "phone": "518-242-2222",
        "type": "Airports",
        "email": "",
        "url": "http:www.albanyairport.com",
        "runway_length": "7200",
        "elev": "285",
        "icao": "KALB",
        "direct_flights": "27",
        "carriers": "27"
    },
    {
        "code": "BKI",
        "lat": "5.9386",
        "lon": "116.049",
        "name": "Kota Kinabalu",
        "city": "Kota Kinabalu",
        "state": "Sabah",
        "country": "Malaysia",
        "woeid": "12514995",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9800",
        "elev": "10",
        "icao": "WBKK",
        "direct_flights": "27",
        "carriers": "16"
    },
    {
        "code": "CEB",
        "lat": "10.3596",
        "lon": "123.837",
        "name": "Lahug",
        "city": "Cebu",
        "state": "Central Visayas",
        "country": "Philippines",
        "woeid": "12515625",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "36",
        "icao": "RPVM",
        "direct_flights": "27",
        "carriers": "13"
    },
    {
        "code": "DAY",
        "lat": "39.8968",
        "lon": "-84.2207",
        "name": "James M Cox Dayton",
        "city": "Dayton",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12520347",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9500",
        "elev": "1009",
        "icao": "KDAY",
        "direct_flights": "27",
        "carriers": "29"
    },
    {
        "code": "FDH",
        "lat": "47.6747",
        "lon": "9.52088",
        "name": "Friedrichshafen",
        "city": "Meckenbeuren",
        "state": "Baden-Wurttemberg",
        "country": "Germany",
        "woeid": "22244457",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7726",
        "elev": "1365",
        "icao": "EDNY",
        "direct_flights": "27",
        "carriers": "8"
    },
    {
        "code": "FWA",
        "lat": "40.9883",
        "lon": "-85.1899",
        "name": "Fort Wayne Municipal-Baer Field",
        "city": "Fort Wayne",
        "state": "Indiana",
        "country": "United States",
        "woeid": "12519784",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "802",
        "icao": "KFWA",
        "direct_flights": "27",
        "carriers": "16"
    },
    {
        "code": "MLE",
        "lat": "4.167",
        "lon": "73.4974",
        "name": "Male",
        "city": "Male",
        "state": "Maale",
        "country": "Maldives",
        "woeid": "12514816",
        "tz": "IndianMaldives",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9318",
        "elev": "4",
        "icao": "KMLE",
        "direct_flights": "27",
        "carriers": "22"
    },
    {
        "code": "NGB",
        "lat": "29.8333",
        "lon": "121.533",
        "name": "Ningbo",
        "city": "Jiangshan",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "12523321",
        "tz": "AsiaShanghai",
        "phone": "+86-0574-8742-7888",
        "type": "Airports",
        "email": "",
        "url": "http:ningboguide.comairport.html",
        "runway_length": "8200",
        "elev": null,
        "icao": "",
        "direct_flights": "27",
        "carriers": "11"
    },
    {
        "code": "SDQ",
        "lat": "18.4292",
        "lon": "-69.6692",
        "name": "De Las Americas",
        "city": "Santo Domingo",
        "state": "Santo Domingo",
        "country": "Dominican Republic",
        "woeid": "12512616",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11002",
        "elev": "58",
        "icao": "MDSD",
        "direct_flights": "27",
        "carriers": "26"
    },
    {
        "code": "WNZ",
        "lat": "28.0025",
        "lon": "120.648",
        "name": "Wenzhou",
        "city": "Wenzhou",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "12523455",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "27",
        "carriers": "14"
    },
    {
        "code": "CGH",
        "lat": "-23.6285",
        "lon": "-46.6589",
        "name": "Congonhas",
        "city": "Sao Paulo",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "23388212",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6362",
        "elev": "2631",
        "icao": "SBSP",
        "direct_flights": "26",
        "carriers": "6"
    },
    {
        "code": "DRS",
        "lat": "51.1688",
        "lon": "13.7899",
        "name": "Ottendorf Okrilla Highway Strip",
        "city": "Ottendorf-Okrilla",
        "state": "Saxony",
        "country": "Germany",
        "woeid": "12513205",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.dresden-airport.de",
        "runway_length": "8229",
        "elev": "755",
        "icao": "EDDC",
        "direct_flights": "26",
        "carriers": "22"
    },
    {
        "code": "FOC",
        "lat": "26.0833",
        "lon": "119.283",
        "name": "Fuzhou",
        "city": "Fuzhou",
        "state": "Fujian",
        "country": "China",
        "woeid": "12523107",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "26",
        "carriers": "14"
    },
    {
        "code": "HFE",
        "lat": "31.7712",
        "lon": "117.301",
        "name": "Hefei-Luogang",
        "city": "Hefei",
        "state": "Anhui",
        "country": "China",
        "woeid": "12512070",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "26",
        "carriers": "10"
    },
    {
        "code": "MED",
        "lat": "24.5517",
        "lon": "39.7025",
        "name": "Madinah",
        "city": "Al Madinah",
        "state": "Al Madinah",
        "country": "Saudi Arabia",
        "woeid": "12517353",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "2144",
        "icao": "",
        "direct_flights": "26",
        "carriers": "8"
    },
    {
        "code": "NNG",
        "lat": "22.6183",
        "lon": "108.179",
        "name": "Nanning-Wuyu",
        "city": "Wuxu",
        "state": "Guangxi",
        "country": "China",
        "woeid": "12512151",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "246",
        "icao": "",
        "direct_flights": "26",
        "carriers": "13"
    },
    {
        "code": "POZ",
        "lat": "52.4284",
        "lon": "16.8208",
        "name": "Lawica",
        "city": "Poznan",
        "state": "Wielkopolskie",
        "country": "Poland",
        "woeid": "12515333",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "308",
        "icao": "EPPO",
        "direct_flights": "26",
        "carriers": "10"
    },
    {
        "code": "PVR",
        "lat": "20.6819",
        "lon": "-105.248",
        "name": "Lic Gustavo Diaz Ordaz",
        "city": "Puerto Vallarta",
        "state": "Jalisco",
        "country": "Mexico",
        "woeid": "12514911",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "19",
        "icao": "MMPR",
        "direct_flights": "26",
        "carriers": "22"
    },
    {
        "code": "RIC",
        "lat": "37.5108",
        "lon": "-77.3332",
        "name": "Richmond",
        "city": "Richmond",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12518996",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8999",
        "elev": "168",
        "icao": "KRIC",
        "direct_flights": "26",
        "carriers": "34"
    },
    {
        "code": "SJD",
        "lat": "23.1564",
        "lon": "-109.723",
        "name": "Los Cabos",
        "city": "S. Jose del Cabo",
        "state": "Baja California Sur",
        "country": "Mexico",
        "woeid": "12514915",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "358",
        "icao": "MMSD",
        "direct_flights": "26",
        "carriers": "21"
    },
    {
        "code": "TLC",
        "lat": "19.3356",
        "lon": "-99.565",
        "name": "Jose Maria Morelos Y Pavon",
        "city": "Toluca de Lerdo",
        "state": "Mexico",
        "country": "Mexico",
        "woeid": "12514900",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13780",
        "elev": "8448",
        "icao": "MMTO",
        "direct_flights": "26",
        "carriers": "6"
    },
    {
        "code": "TNR",
        "lat": "-18.7953",
        "lon": "47.4861",
        "name": "Antananarivo Ivato",
        "city": "Ambohidratrimo",
        "state": "Antananarivo",
        "country": "Madagascar",
        "woeid": "12514691",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "4196",
        "icao": "FMMI",
        "direct_flights": "26",
        "carriers": "11"
    },
    {
        "code": "TRD",
        "lat": "63.4578",
        "lon": "10.9229",
        "name": "Trondheim Vaernes",
        "city": "Stjordal",
        "state": "Nord-Trondelag",
        "country": "Norway",
        "woeid": "12515128",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.avinor.noNorskLufthavnerTrondheim_lufthavn,_Varnes",
        "runway_length": "2900",
        "elev": null,
        "icao": "ENVA",
        "direct_flights": "26",
        "carriers": "11"
    },
    {
        "code": "TUL",
        "lat": "36.1877",
        "lon": "-95.8892",
        "name": "Tulsa",
        "city": "Tulsa",
        "state": "Oklahoma",
        "country": "United States",
        "woeid": "12522219",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTUL",
        "direct_flights": "26",
        "carriers": "21"
    },
    {
        "code": "WLG",
        "lat": "-41.3295",
        "lon": "174.806",
        "name": "Wellington",
        "city": "Wellington",
        "state": "Wellington",
        "country": "New Zealand",
        "woeid": "12515175",
        "tz": "PacificAuckland",
        "phone": "+64 4 385 5100",
        "type": "Airports",
        "email": "",
        "url": "http:www.wellington-airport.co.nz",
        "runway_length": "6350",
        "elev": "40",
        "icao": "NZWN",
        "direct_flights": "26",
        "carriers": "14"
    },
    {
        "code": "WRO",
        "lat": "51.1039",
        "lon": "16.8985",
        "name": "Wroclaw",
        "city": "Wroclaw",
        "state": "Dolnosląskie",
        "country": "Poland",
        "woeid": "12515390",
        "tz": "EuropeWarsaw",
        "phone": "+48 713581100",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.wroclaw.pl",
        "runway_length": null,
        "elev": null,
        "icao": "EPWR",
        "direct_flights": "26",
        "carriers": "12"
    },
    {
        "code": "ACC",
        "lat": "5.5964",
        "lon": "-0.17",
        "name": "Kotoka",
        "city": "Accra",
        "state": "Greater Accra",
        "country": "Ghana",
        "woeid": "12513022",
        "tz": "AfricaAccra",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.gcaa.com.ghindex.html",
        "runway_length": "9800",
        "elev": "205",
        "icao": "DGAA",
        "direct_flights": "25",
        "carriers": "30"
    },
    {
        "code": "AUA",
        "lat": "12.5022",
        "lon": "-70.0139",
        "name": "Reina Beatrix",
        "city": "Aruba",
        "state": "",
        "country": "Aruba",
        "woeid": "12510298",
        "tz": "AmericaAruba",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportaruba.com",
        "runway_length": "9000",
        "elev": "59",
        "icao": "TNCA",
        "direct_flights": "25",
        "carriers": "24"
    },
    {
        "code": "DLA",
        "lat": "4.0133",
        "lon": "9.7097",
        "name": "Douala",
        "city": "Douala",
        "state": "Littoral",
        "country": "Cameroon",
        "woeid": "12512348",
        "tz": "AfricaDouala",
        "phone": "+237 42 35 26",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9350",
        "elev": "33",
        "icao": "FKKD",
        "direct_flights": "25",
        "carriers": "28"
    },
    {
        "code": "GSP",
        "lat": "34.8907",
        "lon": "-82.2167",
        "name": "Greenville Spartanburg",
        "city": "Greer",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12520010",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7600",
        "elev": "972",
        "icao": "KGSP",
        "direct_flights": "25",
        "carriers": "25"
    },
    {
        "code": "ITM",
        "lat": "34.7857",
        "lon": "135.439",
        "name": "Osaka",
        "city": "Itami-shi",
        "state": "Hyogo Prefecture",
        "country": "Japan",
        "woeid": "12514009",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.osaka-airport.co.jp",
        "runway_length": "9840",
        "elev": "40",
        "icao": "RJOO",
        "direct_flights": "25",
        "carriers": "10"
    },
    {
        "code": "LHW",
        "lat": "36.0167",
        "lon": "103.75",
        "name": "Lanzhou",
        "city": "Lanzhou",
        "state": "甘肃省",
        "country": "China",
        "woeid": "12714026",
        "tz": "AsiaShanghai",
        "phone": "+86-931-8968160",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "1947",
        "icao": "KLHW",
        "direct_flights": "25",
        "carriers": "9"
    },
    {
        "code": "NAN",
        "lat": "-17.7558",
        "lon": "177.446",
        "name": "Nadi",
        "city": "Nadi",
        "state": "Western",
        "country": "Fiji",
        "woeid": "12523183",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.afl.com.fj",
        "runway_length": "10500",
        "elev": "63",
        "icao": "NFFN",
        "direct_flights": "25",
        "carriers": "14"
    },
    {
        "code": "PVD",
        "lat": "41.723",
        "lon": "-71.4399",
        "name": "Theodore Francis Green State",
        "city": "Warwick",
        "state": "Rhode Island",
        "country": "United States",
        "woeid": "12522119",
        "tz": "AmericaNew_York",
        "phone": "(401) 737-8222",
        "type": "Airports",
        "email": "",
        "url": "http:www.pvdairport.com",
        "runway_length": "7166",
        "elev": "56",
        "icao": "KPVD",
        "direct_flights": "25",
        "carriers": "24"
    },
    {
        "code": "TIJ",
        "lat": "32.5411",
        "lon": "-116.972",
        "name": "General Abelardo L Rodriguez International Airpo",
        "city": "Tijuana",
        "state": "Baja California",
        "country": "Mexico",
        "woeid": "12514874",
        "tz": "AmericaTijuana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9711",
        "elev": "489",
        "icao": "MMTJ",
        "direct_flights": "25",
        "carriers": "12"
    },
    {
        "code": "TUS",
        "lat": "32.1204",
        "lon": "-110.936",
        "name": "Tucson",
        "city": "Tucson",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12522214",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2643",
        "icao": "KTUS",
        "direct_flights": "25",
        "carriers": "26"
    },
    {
        "code": "TYS",
        "lat": "35.8064",
        "lon": "-83.9889",
        "name": "Mcghee Tyson",
        "city": "Knoxville",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12520857",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9008",
        "elev": "981",
        "icao": "KTYS",
        "direct_flights": "25",
        "carriers": "25"
    },
    {
        "code": "AAQ",
        "lat": "44.9",
        "lon": "37.3167",
        "name": "Olkhovka",
        "city": "Novorossiysk",
        "state": "Krasnodarskiy Kray",
        "country": "Russia",
        "woeid": "12516605",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "URKA",
        "direct_flights": "24",
        "carriers": "15"
    },
    {
        "code": "ADL",
        "lat": "-34.9464",
        "lon": "138.529",
        "name": "Adelaide",
        "city": "Adelaide",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "1107231",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aal.com.au",
        "runway_length": "10171",
        "elev": "20",
        "icao": "YPAD",
        "direct_flights": "24",
        "carriers": "20"
    },
    {
        "code": "BGI",
        "lat": "13.0719",
        "lon": "-59.4939",
        "name": "Grantley Adams",
        "city": "Bridgetown",
        "state": "Christ Church",
        "country": "Barbados",
        "woeid": "12510833",
        "tz": "AmericaBarbados",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "18000",
        "elev": "169",
        "icao": "TBPB",
        "direct_flights": "24",
        "carriers": "16"
    },
    {
        "code": "BHD",
        "lat": "54.6151",
        "lon": "-5.87096",
        "name": "Belfast",
        "city": "Belfast",
        "state": "Northern Ireland",
        "country": "United Kingdom",
        "woeid": "23387943",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "15",
        "icao": "EGAC",
        "direct_flights": "24",
        "carriers": "24",
        "bookingId": "-2589607"
    },
    {
        "code": "CHC",
        "lat": "-43.4859",
        "lon": "172.534",
        "name": "Christchurch",
        "city": "Christchurch",
        "state": "Canterbury",
        "country": "New Zealand",
        "woeid": "12515152",
        "tz": "PacificAuckland",
        "phone": "+64 3 358 5029",
        "type": "Airports",
        "email": "",
        "url": "http:www.christchurch-airport.co.nz",
        "runway_length": "10785",
        "elev": "123",
        "icao": "NZCH",
        "direct_flights": "24",
        "carriers": "19"
    },
    {
        "code": "CHQ",
        "lat": "35.5319",
        "lon": "24.1489",
        "name": "Creta Chania",
        "city": "Creta Chania",
        "state": "Kriti",
        "country": "Greece",
        "woeid": "12513324",
        "tz": "EuropeAthens",
        "phone": "+30 28 21083800",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11811",
        "elev": "492",
        "icao": "LGSA",
        "direct_flights": "24",
        "carriers": "12"
    },
    {
        "code": "DJE",
        "lat": "33.8764",
        "lon": "10.7792",
        "name": "Zarzis",
        "city": "Hawmat as Suq",
        "state": "Madanin",
        "country": "Tunisia",
        "woeid": "12517864",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "16",
        "icao": "DTTJ",
        "direct_flights": "24",
        "carriers": "10"
    },
    {
        "code": "FKB",
        "lat": "48.781",
        "lon": "8.08678",
        "name": "Baden-Airpark",
        "city": "Rheinmünster",
        "state": "Baden-Wurttemberg",
        "country": "Germany",
        "woeid": "22269415",
        "tz": "EuropeBerlin",
        "phone": "+49 (7229)  66-2309",
        "type": "Airports",
        "email": "",
        "url": "http:www.badenairpark.deindex.html",
        "runway_length": null,
        "elev": null,
        "icao": "EDSB",
        "direct_flights": "24",
        "carriers": "5"
    },
    {
        "code": "GSO",
        "lat": "36.1038",
        "lon": "-79.9427",
        "name": "Triad",
        "city": "Greensboro",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12522200",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "926",
        "icao": "KGSO",
        "direct_flights": "24",
        "carriers": "26"
    },
    {
        "code": "OMA",
        "lat": "41.3",
        "lon": "-95.8998",
        "name": "Eppley Airfield",
        "city": "Omaha",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12519653",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "983",
        "icao": "KOMA",
        "direct_flights": "24",
        "carriers": "32"
    },
    {
        "code": "SSA",
        "lat": "-12.9081",
        "lon": "-38.3222",
        "name": "Deputado Luis Eduardo Magalhaes International Ai",
        "city": "Salvador",
        "state": "Nordeste",
        "country": "Brazil",
        "woeid": "12511112",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9865",
        "elev": "60",
        "icao": "SBSV",
        "direct_flights": "24",
        "carriers": "14"
    },
    {
        "code": "AHO",
        "lat": "40.6319",
        "lon": "8.29279",
        "name": "Alghero",
        "city": "Alghero",
        "state": "Sardinia",
        "country": "Italy",
        "woeid": "12513798",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "88",
        "icao": "LIEA",
        "direct_flights": "23",
        "carriers": "11",
        "bookingId": "-110164"
    },
    {
        "code": "CPT",
        "lat": "-33.9647",
        "lon": "18.6022",
        "name": "D F Malan",
        "city": "Cape Town",
        "state": "Western Cape",
        "country": "South Africa",
        "woeid": "12517402",
        "tz": "AfricaJohannesburg",
        "phone": "+ 27 21 937 1275",
        "type": "Airports",
        "email": "",
        "url": "http:www.acsa.co.za",
        "runway_length": "10512",
        "elev": "151",
        "icao": "FACT",
        "direct_flights": "23",
        "carriers": "27"
    },
    {
        "code": "CWL",
        "lat": "51.3998",
        "lon": "-3.34475",
        "name": "Cardiff",
        "city": "Cardiff",
        "state": "Wales",
        "country": "United Kingdom",
        "woeid": "22452963",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cwlfly.com",
        "runway_length": "7723",
        "elev": "220",
        "icao": "EGFF",
        "direct_flights": "23",
        "carriers": "16",
        "bookingId": "-2591777"
    },
    {
        "code": "HIR",
        "lat": "-9.4211",
        "lon": "160.053",
        "name": "Henderson",
        "city": "Honiara",
        "state": "Guadalcanal",
        "country": "Solomon Islands",
        "woeid": "12511006",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6702",
        "elev": "32",
        "icao": "",
        "direct_flights": "23",
        "carriers": "7"
    },
    {
        "code": "INN",
        "lat": "47.2591",
        "lon": "11.3566",
        "name": "Innsbruck",
        "city": "Innsbruck",
        "state": "Tyrol",
        "country": "Austria",
        "woeid": "12510823",
        "tz": "EuropeVienna",
        "phone": "+43(0)512225250",
        "type": "Airports",
        "email": "",
        "url": "http:www.innsbruck-airport.com",
        "runway_length": "6560",
        "elev": "1906",
        "icao": "LOWI",
        "direct_flights": "23",
        "carriers": "19"
    },
    {
        "code": "KHN",
        "lat": "28.6316",
        "lon": "115.931",
        "name": "Nanchang New",
        "city": "Nanchang",
        "state": "Jiangxi",
        "country": "China",
        "woeid": "12512149",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "23",
        "carriers": "10"
    },
    {
        "code": "LBV",
        "lat": "0.4592",
        "lon": "9.4153",
        "name": "Libreville Leon M Ba",
        "city": "Libreville",
        "state": "Estuaire",
        "country": "Gabon",
        "woeid": "12512994",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "39",
        "icao": "FOOL",
        "direct_flights": "23",
        "carriers": "22"
    },
    {
        "code": "ORN",
        "lat": "35.6244",
        "lon": "-0.6186",
        "name": "Es Senia",
        "city": "Oran Rp",
        "state": "Oran",
        "country": "Algeria",
        "woeid": "12510326",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "295",
        "icao": "",
        "direct_flights": "23",
        "carriers": "3"
    },
    {
        "code": "PAD",
        "lat": "51.6126",
        "lon": "8.61871",
        "name": "Paderborn-Lippstadt",
        "city": "Büren",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "22179500",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "712",
        "icao": "EDLP",
        "direct_flights": "23",
        "carriers": "6"
    },
    {
        "code": "PEN",
        "lat": "5.2975",
        "lon": "100.278",
        "name": "Penang",
        "city": "Batu Maung",
        "state": "Pulau Pinang",
        "country": "Malaysia",
        "woeid": "12515004",
        "tz": "AsiaKuala_Lumpur",
        "phone": "604-643 4411",
        "type": "Airports",
        "email": "",
        "url": "http:www.malaysiaairports.com.my",
        "runway_length": "11000",
        "elev": "11",
        "icao": "WMKP",
        "direct_flights": "23",
        "carriers": "24"
    },
    {
        "code": "SCQ",
        "lat": "42.8991",
        "lon": "-8.41646",
        "name": "Santiago",
        "city": "Santiago",
        "state": "Galicia",
        "country": "Spain",
        "woeid": "12517569",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "1221",
        "icao": "LEST",
        "direct_flights": "23",
        "carriers": "17"
    },
    {
        "code": "SYX",
        "lat": "18.281",
        "lon": "109.498",
        "name": "Sanya",
        "city": "Sanya",
        "state": "Hainan",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "23",
        "carriers": "21"
    },
    {
        "code": "TRF",
        "lat": "59.182",
        "lon": "10.2509",
        "name": "Torp",
        "city": "Sandefjord",
        "state": "Vestfold Fylke",
        "country": "Norway",
        "woeid": "12515126",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9809",
        "elev": "285",
        "icao": "ENTO",
        "direct_flights": "23",
        "carriers": "11"
    },
    {
        "code": "ABJ",
        "lat": "5.2556",
        "lon": "-3.9292",
        "name": "Abidjan Port Bouet",
        "city": "Abidjan",
        "state": "Abidjan",
        "country": "Ivory Coast",
        "woeid": "12513870",
        "tz": "AfricaAbidjan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "20",
        "icao": "DIAP",
        "direct_flights": "22",
        "carriers": "29"
    },
    {
        "code": "BOI",
        "lat": "43.5709",
        "lon": "-116.222",
        "name": "Boise Air Terminal",
        "city": "Boise",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12518880",
        "tz": "AmericaBoise",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9763",
        "elev": "2858",
        "icao": "KBOI",
        "direct_flights": "22",
        "carriers": "21"
    },
    {
        "code": "BOJ",
        "lat": "42.5667",
        "lon": "27.5",
        "name": "Bourgas",
        "city": "Burgas",
        "state": "Burgas",
        "country": "Bulgaria",
        "woeid": "12523782",
        "tz": "EuropeSofia",
        "phone": "+359 56 870248",
        "type": "Airports",
        "email": "",
        "url": "http:www.bourgas-airport.com",
        "runway_length": "8530",
        "elev": "121",
        "icao": "LBBG",
        "direct_flights": "22",
        "carriers": "14"
    },
    {
        "code": "CGQ",
        "lat": "43.9069",
        "lon": "125.207",
        "name": "Dafang Shen",
        "city": "Changchun",
        "state": "Jilin",
        "country": "China",
        "woeid": "12512022",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZYCC",
        "direct_flights": "22",
        "carriers": "12"
    },
    {
        "code": "CHS",
        "lat": "32.8838",
        "lon": "-80.0356",
        "name": "Charleston",
        "city": "North Charleston",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12519142",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "9001",
        "elev": "46",
        "icao": "KCHS",
        "direct_flights": "22",
        "carriers": "25"
    },
    {
        "code": "COK",
        "lat": "9.93997",
        "lon": "76.2748",
        "name": "Kochi",
        "city": "Kochi",
        "state": "Kerala",
        "country": "India",
        "woeid": "12513568",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cochin-airport.com",
        "runway_length": "6380",
        "elev": "8",
        "icao": "VOCC",
        "direct_flights": "22",
        "carriers": "21"
    },
    {
        "code": "DAR",
        "lat": "-6.8753",
        "lon": "39.2019",
        "name": "Dar Es Salaam",
        "city": "Dar es Salaam",
        "state": "Dar es Salaam",
        "country": "Tanzania",
        "woeid": "12518013",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "182",
        "icao": "HTDA",
        "direct_flights": "22",
        "carriers": "24"
    },
    {
        "code": "GCM",
        "lat": "19.2925",
        "lon": "-81.3589",
        "name": "Owen Roberts",
        "city": "Georgetown",
        "state": "Grand Cayman",
        "country": "Cayman Islands",
        "woeid": "12512345",
        "tz": "AmericaCayman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "8",
        "icao": "MWCR",
        "direct_flights": "22",
        "carriers": "13"
    },
    {
        "code": "GUM",
        "lat": "13.4833",
        "lon": "144.796",
        "name": "Antonio B Won Pat",
        "city": "Hagåtña",
        "state": "Guam",
        "country": "United States",
        "woeid": "12523115",
        "tz": "PacificGuam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PGUM",
        "direct_flights": "22",
        "carriers": "10"
    },
    {
        "code": "IKT",
        "lat": "52.2604",
        "lon": "104.404",
        "name": "Irkutsk Southeast",
        "city": "Irkutsk",
        "state": "Irkutskaya Oblast",
        "country": "Russia",
        "woeid": "12516044",
        "tz": "AsiaIrkutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "1641",
        "icao": "UUII",
        "direct_flights": "22",
        "carriers": "15"
    },
    {
        "code": "KIV",
        "lat": "46.9296",
        "lon": "28.9389",
        "name": "Kishinev Southeast",
        "city": "Chisinau",
        "state": "Chisinau",
        "country": "Moldova",
        "woeid": "12514720",
        "tz": "EuropeChisinau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.md",
        "runway_length": null,
        "elev": null,
        "icao": "LUKK",
        "direct_flights": "22",
        "carriers": "16"
    },
    {
        "code": "LIT",
        "lat": "34.7278",
        "lon": "-92.219",
        "name": "Adams Field",
        "city": "Little Rock",
        "state": "Arkansas",
        "country": "United States",
        "woeid": "12518526",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7173",
        "elev": "258",
        "icao": "KLIT",
        "direct_flights": "22",
        "carriers": "20"
    },
    {
        "code": "MMX",
        "lat": "55.5406",
        "lon": "13.3666",
        "name": "Sturup",
        "city": "Svedala",
        "state": "Skane",
        "country": "Sweden",
        "woeid": "23318094",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.setemplatesLFV_AirportStartPage____4125.aspx",
        "runway_length": "9186",
        "elev": "236",
        "icao": "ESMS",
        "direct_flights": "22",
        "carriers": "12"
    },
    {
        "code": "ROC",
        "lat": "43.1309",
        "lon": "-77.6697",
        "name": "Greater Rochester",
        "city": "Rochester",
        "state": "New York",
        "country": "United States",
        "woeid": "12519985",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8001",
        "elev": "559",
        "icao": "KROC",
        "direct_flights": "22",
        "carriers": "31"
    },
    {
        "code": "RTM",
        "lat": "51.9525",
        "lon": "4.4392",
        "name": "Rotterdam",
        "city": "Rotterdam",
        "state": "South Holland",
        "country": "Netherlands",
        "woeid": "23182361",
        "tz": "EuropeAmsterdam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.rotterdam-airport.nl",
        "runway_length": "7218",
        "elev": "-15",
        "icao": "EHRD",
        "direct_flights": "22",
        "carriers": "10",
        "bookingId": "-2152403"
    },
    {
        "code": "TFN",
        "lat": "28.4978",
        "lon": "-16.3419",
        "name": "Tenerife nord",
        "city": "Tenerife",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12517560",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "GCXO",
        "direct_flights": "22",
        "carriers": "20",
        "bookingId": "-401686"
    },
    {
        "code": "VLI",
        "lat": "-17.7039",
        "lon": "168.312",
        "name": "Port Vila Bauerfield",
        "city": "Port-Vila",
        "state": "Shefa",
        "country": "Vanuatu",
        "woeid": "12515052",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "66",
        "icao": "NVVV",
        "direct_flights": "22",
        "carriers": "7"
    },
    {
        "code": "YQB",
        "lat": "46.791",
        "lon": "-71.3837",
        "name": "Quebec",
        "city": "Ste-Foy",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511805",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "243",
        "icao": "CYQB",
        "direct_flights": "22",
        "carriers": "16"
    },
    {
        "code": "YXL",
        "lat": "50.1153",
        "lon": "-91.9056",
        "name": "Sioux Lookout",
        "city": "Sioux Lookout",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511831",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4400",
        "elev": "1280",
        "icao": "CYXL",
        "direct_flights": "22",
        "carriers": "3"
    },
    {
        "code": "DYU",
        "lat": "38.5785",
        "lon": "68.7773",
        "name": "Tajikistan",
        "city": "Dushanbe",
        "state": "Karategin",
        "country": "Tajikistan",
        "woeid": "23424961",
        "tz": "AsiaDushanbe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10170",
        "elev": "2569",
        "icao": "UTDD",
        "direct_flights": "21",
        "carriers": "16"
    },
    {
        "code": "EIN",
        "lat": "51.4463",
        "lon": "5.39212",
        "name": "Eindhoven",
        "city": "Eindhoven",
        "state": "North Brabant",
        "country": "Netherlands",
        "woeid": "12515087",
        "tz": "EuropeAmsterdam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.eindhovenairport.com",
        "runway_length": "9846",
        "elev": "74",
        "icao": "EHEH",
        "direct_flights": "21",
        "carriers": "9",
        "bookingId": "-2144027"
    },
    {
        "code": "FIH",
        "lat": "-4.3847",
        "lon": "15.445",
        "name": "Kinshasa N Djili",
        "city": "Kinshasa",
        "state": "Kinshasa",
        "country": "Democratic Republic of Congo",
        "woeid": "12511963",
        "tz": "AfricaKinshasa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1027",
        "icao": "FZAA",
        "direct_flights": "21",
        "carriers": "15"
    },
    {
        "code": "GRR",
        "lat": "42.8841",
        "lon": "-85.5307",
        "name": "Gerald R. Ford",
        "city": "Grand Rapids",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520445",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "794",
        "icao": "KGRR",
        "direct_flights": "21",
        "carriers": "26"
    },
    {
        "code": "GUA",
        "lat": "14.5819",
        "lon": "-90.5275",
        "name": "La Aurora",
        "city": "Guatemala City",
        "state": "Guatemala",
        "country": "Guatemala",
        "woeid": "12513336",
        "tz": "AmericaGuatemala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9793",
        "elev": "4952",
        "icao": "MGGT",
        "direct_flights": "21",
        "carriers": "25"
    },
    {
        "code": "ODS",
        "lat": "46.4402",
        "lon": "30.6764",
        "name": "Odessa",
        "city": "Odesa",
        "state": "Odes´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518374",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "164",
        "icao": "UKOO",
        "direct_flights": "21",
        "carriers": "23"
    },
    {
        "code": "PDL",
        "lat": "37.7375",
        "lon": "-25.6983",
        "name": "Ponta Delgada",
        "city": "Ponta Delgada",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515454",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "259",
        "icao": "LPPD",
        "direct_flights": "21",
        "carriers": "5"
    },
    {
        "code": "POS",
        "lat": "10.5936",
        "lon": "-61.3386",
        "name": "Piarco",
        "city": "Trinidad",
        "state": "Port of Spain",
        "country": "Trinidad and Tobago",
        "woeid": "12517744",
        "tz": "AmericaPort_of_Spain",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.TrinidadAirport.com",
        "runway_length": "10508",
        "elev": "57",
        "icao": "TTPP",
        "direct_flights": "21",
        "carriers": "16"
    },
    {
        "code": "RGN",
        "lat": "16.9064",
        "lon": "96.1389",
        "name": "Mingaladon",
        "city": "Insein",
        "state": "Yangon",
        "country": "Myanmar",
        "woeid": "12510924",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8100",
        "elev": "109",
        "icao": "VYYY",
        "direct_flights": "21",
        "carriers": "17"
    },
    {
        "code": "RNO",
        "lat": "39.5064",
        "lon": "-119.776",
        "name": "Reno-Tahoe",
        "city": "Reno",
        "state": "Nevada",
        "country": "United States",
        "woeid": "12519047",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.renoairport.com",
        "runway_length": null,
        "elev": null,
        "icao": "KRNO",
        "direct_flights": "21",
        "carriers": "22"
    },
    {
        "code": "SYR",
        "lat": "43.1141",
        "lon": "-76.1121",
        "name": "Hancock",
        "city": "Syracuse",
        "state": "New York",
        "country": "United States",
        "woeid": "12520075",
        "tz": "AmericaNew_York",
        "phone": "(315) 454-4330",
        "type": "Airports",
        "email": "",
        "url": "http:www.syrairport.org",
        "runway_length": "9003",
        "elev": "421",
        "icao": "KSYR",
        "direct_flights": "21",
        "carriers": "26"
    },
    {
        "code": "UFA",
        "lat": "54.5569",
        "lon": "55.8893",
        "name": "Ufa South",
        "city": "Oufa",
        "state": "Bashkortostan",
        "country": "Russia",
        "woeid": "12517068",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UWUU",
        "direct_flights": "21",
        "carriers": "15"
    },
    {
        "code": "YKS",
        "lat": "62.0953",
        "lon": "129.767",
        "name": "Yakutsk",
        "city": "Yakutsk",
        "state": "Sakha",
        "country": "Russia",
        "woeid": "12517227",
        "tz": "AsiaYakutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1155",
        "elev": "322",
        "icao": "",
        "direct_flights": "21",
        "carriers": "7"
    },
    {
        "code": "TSF",
        "lat": "45.6513",
        "lon": "12.2022",
        "name": "Treviso",
        "city": "Treviso",
        "state": "Veneto",
        "country": "Italy",
        "woeid": "22321438",
        "tz": "EuropeRome",
        "phone": "(0039)0422315111",
        "type": "Airports",
        "email": "",
        "url": "http:www.trevisoairport.it",
        "runway_length": "7940",
        "elev": "59",
        "icao": "LIPH",
        "direct_flights": "20",
        "carriers": "6",
        "bookingId": "-131327"
    },
    {
        "code": "BIA",
        "lat": "42.5497",
        "lon": "9.48341",
        "name": "Bastia Poretta",
        "city": "Borgo",
        "state": "Corsica",
        "country": "France",
        "woeid": "12512955",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8235",
        "elev": "26",
        "icao": "LFKB",
        "direct_flights": "20",
        "carriers": "8"
    },
    {
        "code": "CAE",
        "lat": "33.9468",
        "lon": "-81.1241",
        "name": "Columbia Metropolitan",
        "city": "West Columbia",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12519284",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8602",
        "elev": "236",
        "icao": "KCAE",
        "direct_flights": "20",
        "carriers": "26"
    },
    {
        "code": "CUR",
        "lat": "12.188",
        "lon": "-68.9634",
        "name": "Hato",
        "city": "Curacao",
        "state": "Curacao",
        "country": "Netherlands Antilles",
        "woeid": "12515138",
        "tz": "AmericaCuracao",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11188",
        "elev": "29",
        "icao": "TNCC",
        "direct_flights": "20",
        "carriers": "21"
    },
    {
        "code": "DLM",
        "lat": "36.7125",
        "lon": "28.7922",
        "name": "Dalaman",
        "city": "Ortaca",
        "state": "Muğla",
        "country": "Turkey",
        "woeid": "12517880",
        "tz": "EuropeIstanbul",
        "phone": "+90 252 792 55",
        "type": "Airports",
        "email": "",
        "url": "http:www.atmairport.aero",
        "runway_length": "9842",
        "elev": "20",
        "icao": "LTBS",
        "direct_flights": "20",
        "carriers": "11"
    },
    {
        "code": "DSA",
        "lat": "53.3954",
        "lon": "-1.38243",
        "name": "Sheffield",
        "city": "Doncaster Sheffield",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22656550",
        "tz": "EuropeLondon",
        "phone": "+44 8708 33 22 10",
        "type": "Airports",
        "email": "",
        "url": "http:www.robinhoodairport.com",
        "runway_length": "9485",
        "elev": null,
        "icao": "EGCN",
        "direct_flights": "20",
        "carriers": "4",
        "bookingId": "-2607573"
    },
    {
        "code": "DSM",
        "lat": "41.5328",
        "lon": "-93.6481",
        "name": "Des Moines",
        "city": "Des Moines",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12519473",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.dsmairport.com",
        "runway_length": "9001",
        "elev": "957",
        "icao": "KDSM",
        "direct_flights": "20",
        "carriers": "24"
    },
    {
        "code": "ELP",
        "lat": "31.7982",
        "lon": "-106.393",
        "name": "El Paso",
        "city": "El Paso",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519608",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11012",
        "elev": "3956",
        "icao": "KELP",
        "direct_flights": "20",
        "carriers": "23"
    },
    {
        "code": "KZN",
        "lat": "55.6014",
        "lon": "49.2751",
        "name": "Kirbi",
        "city": "Zelenodol'sk",
        "state": "Tatarstan",
        "country": "Russia",
        "woeid": "12516165",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "210",
        "icao": "",
        "direct_flights": "20",
        "carriers": "12"
    },
    {
        "code": "LIL",
        "lat": "50.5713",
        "lon": "3.1072",
        "name": "Lesquin",
        "city": "Fretin",
        "state": "Nord-Pas-de-Calais",
        "country": "France",
        "woeid": "12523162",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9268",
        "elev": "157",
        "icao": "",
        "direct_flights": "20",
        "carriers": "15"
    },
    {
        "code": "MHT",
        "lat": "42.9293",
        "lon": "-71.4386",
        "name": "Manchester-Boston",
        "city": "Manchester",
        "state": "New Hampshire",
        "country": "United States",
        "woeid": "12520772",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7001",
        "elev": "234",
        "icao": "KMHT",
        "direct_flights": "20",
        "carriers": "16"
    },
    {
        "code": "OGG",
        "lat": "20.8926",
        "lon": "-156.441",
        "name": "Kahului",
        "city": "Kahului",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520412",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "53",
        "icao": "PHOG",
        "direct_flights": "20",
        "carriers": "19"
    },
    {
        "code": "PLS",
        "lat": "21.7728",
        "lon": "-72.2683",
        "name": "Providenciales",
        "city": "The Bight Settlements",
        "state": "Providencales and West Caicos",
        "country": "Turks And Caicos Islands",
        "woeid": "12517843",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7600",
        "elev": "25",
        "icao": "MBPV",
        "direct_flights": "20",
        "carriers": "11"
    },
    {
        "code": "PTP",
        "lat": "16.2675",
        "lon": "-61.5281",
        "name": "Le Raizet",
        "city": "Les Abymes",
        "state": "Pointe-À-Pitre",
        "country": "Guadeloupe",
        "woeid": "12513277",
        "tz": "AmericaGuadeloupe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11499",
        "elev": "36",
        "icao": "TFFR",
        "direct_flights": "20",
        "carriers": "9"
    },
    {
        "code": "SAL",
        "lat": "13.44",
        "lon": "-89.0558",
        "name": "El Salvador",
        "city": "San Luis",
        "state": "La Paz",
        "country": "El Salvador",
        "woeid": "12512751",
        "tz": "AmericaEl_Salvador",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeropuertoelsalvador.gob.sv",
        "runway_length": "10500",
        "elev": "101",
        "icao": "MSLP",
        "direct_flights": "20",
        "carriers": "16"
    },
    {
        "code": "TOS",
        "lat": "69.6787",
        "lon": "18.9096",
        "name": "Tromso Langnes",
        "city": "Tromso",
        "state": "Troms Fylke",
        "country": "Norway",
        "woeid": "12515127",
        "tz": "EuropeOslo",
        "phone": "+ 47 77 64 84 00",
        "type": "Airports",
        "email": "",
        "url": "http:www.avinor.noEnglishAirportsTromso_Airport",
        "runway_length": "2392",
        "elev": "31",
        "icao": "ENTC",
        "direct_flights": "20",
        "carriers": "4"
    },
    {
        "code": "UIO",
        "lat": "-0.1381",
        "lon": "-78.4833",
        "name": "Mariscal Sucre",
        "city": "Quito",
        "state": "Pichincha",
        "country": "Ecuador",
        "woeid": "12512643",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.quitoairport.com",
        "runway_length": "10240",
        "elev": "9223",
        "icao": "SEQU",
        "direct_flights": "20",
        "carriers": "22"
    },
    {
        "code": "VAR",
        "lat": "43.2416",
        "lon": "27.8137",
        "name": "Varna",
        "city": "Varna",
        "state": "Varna",
        "country": "Bulgaria",
        "woeid": "12511529",
        "tz": "EuropeSofia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.varna-airport.bg",
        "runway_length": "8202",
        "elev": "230",
        "icao": "LBWN",
        "direct_flights": "20",
        "carriers": "19"
    },
    {
        "code": "YNT",
        "lat": "37.55",
        "lon": "121.333",
        "name": "Yantai",
        "city": "Yantai",
        "state": "Shandong",
        "country": "China",
        "woeid": "12523272",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "20",
        "carriers": "9"
    },
    {
        "code": "ZAZ",
        "lat": "41.6607",
        "lon": "-1.0078",
        "name": "Zaragoza Air Base",
        "city": "Zaragoza",
        "state": "Aragon",
        "country": "Spain",
        "woeid": "12517581",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "12197",
        "elev": "863",
        "icao": "LEZG",
        "direct_flights": "20",
        "carriers": "6",
        "bookingId": "-409149"
    },
    {
        "code": "ALP",
        "lat": "36.1808",
        "lon": "37.2267",
        "name": "Aleppo",
        "city": "Djibrine",
        "state": "H'alab",
        "country": "Syria",
        "woeid": "12517695",
        "tz": "AsiaDamascus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9908",
        "elev": "1276",
        "icao": "OSAP",
        "direct_flights": "19",
        "carriers": "13"
    },
    {
        "code": "ALY",
        "lat": "31.1845",
        "lon": "29.9491",
        "name": "An-Nuzhah",
        "city": "Alexandria",
        "state": "Al Iskandariyah",
        "country": "Egypt",
        "woeid": "23388264",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7021",
        "elev": null,
        "icao": "HEAX",
        "direct_flights": "19",
        "carriers": "15"
    },
    {
        "code": "BVA",
        "lat": "49.4565",
        "lon": "2.11552",
        "name": "Beauvais-Tille",
        "city": "Beauvais",
        "state": "Picardie",
        "country": "France",
        "woeid": "22904235",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "http:www.aeroportbeauvais.com",
        "runway_length": "7972",
        "elev": "358",
        "icao": "LFOB",
        "direct_flights": "19",
        "carriers": "3"
    },
    {
        "code": "CFE",
        "lat": "45.7896",
        "lon": "3.16013",
        "name": "Aulnat",
        "city": "Aulnat",
        "state": "Auvergne",
        "country": "France",
        "woeid": "12512847",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9892",
        "elev": "1089",
        "icao": "LFLC",
        "direct_flights": "19",
        "carriers": "7"
    },
    {
        "code": "CJU",
        "lat": "33.5056",
        "lon": "126.495",
        "name": "Cheju",
        "city": "Jeju-Si",
        "state": "Jaeju-Do",
        "country": "South Korea",
        "woeid": "12514202",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9800",
        "elev": "126",
        "icao": "RKPC",
        "direct_flights": "19",
        "carriers": "8"
    },
    {
        "code": "CLO",
        "lat": "3.5461",
        "lon": "-76.385",
        "name": "Alfonso Bonilla Aragon",
        "city": "Obando",
        "state": "Valle del Cauca",
        "country": "Colombia",
        "woeid": "12512360",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "3162",
        "icao": "SKCL",
        "direct_flights": "19",
        "carriers": "15"
    },
    {
        "code": "CWB",
        "lat": "-25.5275",
        "lon": "-49.1731",
        "name": "Afonso Pena",
        "city": "Sao Jose dos Pinhais",
        "state": "Parana",
        "country": "Brazil",
        "woeid": "12511012",
        "tz": "AmericaSao_Paulo",
        "phone": "+55(41)3381-1515",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7267",
        "elev": "2989",
        "icao": "SBCT",
        "direct_flights": "19",
        "carriers": "16"
    },
    {
        "code": "DAL",
        "lat": "32.8439",
        "lon": "-96.8496",
        "name": "Dallas Love Field",
        "city": "Dallas",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519401",
        "tz": "AmericaChicago",
        "phone": "1-214-670-6073",
        "type": "Airports",
        "email": "",
        "url": "http:www.dallas-lovefield.com",
        "runway_length": "8800",
        "elev": "487",
        "icao": "KDAL",
        "direct_flights": "19",
        "carriers": "8"
    },
    {
        "code": "DRW",
        "lat": "-12.4159",
        "lon": "130.874",
        "name": "Darwin",
        "city": "Darwin",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510651",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10906",
        "elev": "102",
        "icao": "YPDN",
        "direct_flights": "19",
        "carriers": "12"
    },
    {
        "code": "HKT",
        "lat": "8.1106",
        "lon": "98.3125",
        "name": "Phuket",
        "city": "Thalang",
        "state": "Phuket",
        "country": "Thailand",
        "woeid": "12517777",
        "tz": "AsiaBangkok",
        "phone": "076 327230-7",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportthai.co.thairportnewphukethtmlindex.html",
        "runway_length": "8200",
        "elev": "69",
        "icao": "VTSP",
        "direct_flights": "19",
        "carriers": "38"
    },
    {
        "code": "KBL",
        "lat": "34.5642",
        "lon": "69.2094",
        "name": "Kabul",
        "city": "Kabul",
        "state": "Kabul",
        "country": "Afghanistan",
        "woeid": "12510306",
        "tz": "AsiaKabul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "5871",
        "icao": "OAKB",
        "direct_flights": "19",
        "carriers": "9"
    },
    {
        "code": "LGB",
        "lat": "33.8186",
        "lon": "-118.144",
        "name": "Long Beach Daugherty Field",
        "city": "Long Beach",
        "state": "California",
        "country": "United States",
        "woeid": "12520696",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "57",
        "icao": "",
        "direct_flights": "19",
        "carriers": "8"
    },
    {
        "code": "MRV",
        "lat": "44.2026",
        "lon": "43.1352",
        "name": "Mineral'nyye Vody",
        "city": "Mineralnye Vody",
        "state": "Stavropolrskiy Kray",
        "country": "Russia",
        "woeid": "2046335",
        "tz": "EuropeVolgograd",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "URMM",
        "direct_flights": "19",
        "carriers": "14"
    },
    {
        "code": "NWI",
        "lat": "52.6704",
        "lon": "1.27648",
        "name": "Norwich",
        "city": "Norwich",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22481371",
        "tz": "EuropeLondon",
        "phone": "+44 (0)1603 411923",
        "type": "Airports",
        "email": "",
        "url": "http:www.norwichairport.co.uk",
        "runway_length": "6043",
        "elev": "117",
        "icao": "EGSH",
        "direct_flights": "19",
        "carriers": "14",
        "bookingId": "-2604458"
    },
    {
        "code": "OMS",
        "lat": "54.9634",
        "lon": "73.3068",
        "name": "Omsk Southwest",
        "city": "Omsk",
        "state": "Omskaya Oblast",
        "country": "Russia",
        "woeid": "12516609",
        "tz": "AsiaOmsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "308",
        "icao": "",
        "direct_flights": "19",
        "carriers": "14"
    },
    {
        "code": "POA",
        "lat": "-29.9933",
        "lon": "-51.1708",
        "name": "Salgado Filho",
        "city": "Porto Alegre",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511298",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7480",
        "elev": "10",
        "icao": "SBPA",
        "direct_flights": "19",
        "carriers": "15"
    },
    {
        "code": "SJW",
        "lat": "38.0492",
        "lon": "114.489",
        "name": "Shijiazhuang",
        "city": "Shijiazhuang",
        "state": "Hebei",
        "country": "China",
        "woeid": "2171287",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "19",
        "carriers": "8"
    },
    {
        "code": "SMI",
        "lat": "37.6903",
        "lon": "26.9128",
        "name": "Samos",
        "city": "Pithagorion",
        "state": "Voreio Aigaio",
        "country": "Greece",
        "woeid": "12513320",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6660",
        "elev": "20",
        "icao": "",
        "direct_flights": "19",
        "carriers": "7"
    },
    {
        "code": "SNA",
        "lat": "33.6813",
        "lon": "-117.859",
        "name": "John Wayne",
        "city": "Santa Ana",
        "state": "California",
        "country": "United States",
        "woeid": "12520383",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5700",
        "elev": "54",
        "icao": "KSNA",
        "direct_flights": "19",
        "carriers": "21"
    },
    {
        "code": "AJA",
        "lat": "41.9204",
        "lon": "8.79778",
        "name": "Ajaccio",
        "city": "Ajaccio",
        "state": "Corsica",
        "country": "France",
        "woeid": "12512869",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6972",
        "elev": "16",
        "icao": "LFKJ",
        "direct_flights": "18",
        "carriers": "6",
        "bookingId": "-1406959"
    },
    {
        "code": "CCJ",
        "lat": "11.1329",
        "lon": "75.9536",
        "name": "Kozhikode",
        "city": "Pallikkal",
        "state": "Kerala",
        "country": "India",
        "woeid": "12513562",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.calicutairport.com",
        "runway_length": "9383",
        "elev": "328",
        "icao": "VOCL",
        "direct_flights": "18",
        "carriers": "13"
    },
    {
        "code": "FOR",
        "lat": "-3.7758",
        "lon": "-38.5322",
        "name": "Pinto Martins",
        "city": "Fortaleza",
        "state": "Ceara",
        "country": "Brazil",
        "woeid": "12511272",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8350",
        "elev": "82",
        "icao": "SBFZ",
        "direct_flights": "18",
        "carriers": "13"
    },
    {
        "code": "GYE",
        "lat": "-2.1533",
        "lon": "-79.8833",
        "name": "Simon Bolivar",
        "city": "Guayaquil",
        "state": "Guayas",
        "country": "Ecuador",
        "woeid": "12512652",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8005",
        "elev": "15",
        "icao": "SEGU",
        "direct_flights": "18",
        "carriers": "21"
    },
    {
        "code": "HMO",
        "lat": "29.0967",
        "lon": "-111.047",
        "name": "Gen Ignacio P Garcia",
        "city": "Hermosillo",
        "state": "Sonora",
        "country": "Mexico",
        "woeid": "12514876",
        "tz": "AmericaHermosillo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "630",
        "icao": "MMHO",
        "direct_flights": "18",
        "carriers": "12"
    },
    {
        "code": "IOM",
        "lat": "54.0819",
        "lon": "-4.6269",
        "name": "Ronaldsway",
        "city": "Castletown",
        "state": "Isle of Man",
        "country": "United Kingdom",
        "woeid": "12513525",
        "tz": "EuropeIsle_of_Man",
        "phone": "+44 1624 821600",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5751",
        "elev": "55",
        "icao": "EGNS",
        "direct_flights": "18",
        "carriers": "6"
    },
    {
        "code": "LFW",
        "lat": "6.1625",
        "lon": "1.255",
        "name": "Lome Tokoin",
        "city": "Lome",
        "state": "Lome",
        "country": "Togo",
        "woeid": "12517850",
        "tz": "AfricaLome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "72",
        "icao": "DXXX",
        "direct_flights": "18",
        "carriers": "15"
    },
    {
        "code": "MDT",
        "lat": "40.1962",
        "lon": "-76.7564",
        "name": "Harrisburg",
        "city": "Middletown",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12520098",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9501",
        "elev": "310",
        "icao": "KMDT",
        "direct_flights": "18",
        "carriers": "22"
    },
    {
        "code": "MSN",
        "lat": "43.1351",
        "lon": "-89.3465",
        "name": "Dane County-Truax Field",
        "city": "Madison",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519407",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9005",
        "elev": "862",
        "icao": "KMSN",
        "direct_flights": "18",
        "carriers": "20"
    },
    {
        "code": "MYY",
        "lat": "4.3256",
        "lon": "113.984",
        "name": "Miri",
        "city": "Miri",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12515003",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "55",
        "icao": "",
        "direct_flights": "18",
        "carriers": "3"
    },
    {
        "code": "MZT",
        "lat": "23.1625",
        "lon": "-106.274",
        "name": "General Rafael Buelna",
        "city": "Mazatlán",
        "state": "Sinaloa",
        "country": "Mexico",
        "woeid": "12514885",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8859",
        "elev": "33",
        "icao": "MMMZ",
        "direct_flights": "18",
        "carriers": "15"
    },
    {
        "code": "OVD",
        "lat": "43.5596",
        "lon": "-6.03102",
        "name": "Asturias",
        "city": "Castrillón",
        "state": "Asturias",
        "country": "Spain",
        "woeid": "12517538",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "415",
        "icao": "LEAS",
        "direct_flights": "18",
        "carriers": "17"
    },
    {
        "code": "REC",
        "lat": "-8.1261",
        "lon": "-34.9225",
        "name": "Gilberto Freyre",
        "city": "Recife",
        "state": "Pernambuco",
        "country": "Brazil",
        "woeid": "12511167",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9875",
        "elev": "36",
        "icao": "SBRF",
        "direct_flights": "18",
        "carriers": "13"
    },
    {
        "code": "RNS",
        "lat": "48.0686",
        "lon": "-1.72536",
        "name": "St Jacques",
        "city": "St-Jacques",
        "state": "Brittany",
        "country": "France",
        "woeid": "12512973",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "121",
        "icao": "LFRN",
        "direct_flights": "18",
        "carriers": "11"
    },
    {
        "code": "RUN",
        "lat": "-20.8772",
        "lon": "55.5214",
        "name": "Saint Denis Gillot",
        "city": "Sainte-Marie",
        "state": "St-Denis",
        "country": "Reunion",
        "woeid": "12515488",
        "tz": "IndianReunion",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8760",
        "elev": "66",
        "icao": "FMEE",
        "direct_flights": "18",
        "carriers": "7"
    },
    {
        "code": "SAV",
        "lat": "32.1338",
        "lon": "-81.2133",
        "name": "Savannah",
        "city": "Savannah",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12521756",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9003",
        "elev": "51",
        "icao": "KSAV",
        "direct_flights": "18",
        "carriers": "21"
    },
    {
        "code": "SFB",
        "lat": "28.773",
        "lon": "-81.2403",
        "name": "Orlando Sanford",
        "city": "Sanford",
        "state": "Florida",
        "country": "United States",
        "woeid": "23418431",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.orlandosanfordairport.com",
        "runway_length": "9600",
        "elev": "55",
        "icao": "KSFB",
        "direct_flights": "18",
        "carriers": "3"
    },
    {
        "code": "SJJ",
        "lat": "43.8247",
        "lon": "18.3361",
        "name": "Sarajevo",
        "city": "Ilidža",
        "state": "Federacija Bosne I Hercegovine",
        "country": "Bosnia and Herzegovina",
        "woeid": "12510901",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "1708",
        "icao": "LQSA",
        "direct_flights": "18",
        "carriers": "13"
    },
    {
        "code": "YZF",
        "lat": "62.4699",
        "lon": "-114.433",
        "name": "Yellowknife",
        "city": "Yellowknife",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511922",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "674",
        "icao": "CYZF",
        "direct_flights": "18",
        "carriers": "6"
    },
    {
        "code": "BDS",
        "lat": "40.6626",
        "lon": "17.9426",
        "name": "Brindisi",
        "city": "Brindisi",
        "state": "Puglia",
        "country": "Italy",
        "woeid": "12513811",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8622",
        "elev": "48",
        "icao": "LIBR",
        "direct_flights": "17",
        "carriers": "19",
        "bookingId": "-112183"
    },
    {
        "code": "TRS",
        "lat": "45.8234",
        "lon": "13.4841",
        "name": "Ronchi Dei Legionari",
        "city": "Ronchi",
        "state": "Friuli-Venezia Giulia",
        "country": "Italy",
        "woeid": "12513859",
        "tz": "EuropeRome",
        "phone": "0039 0481 773224",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroporto.fvg.it",
        "runway_length": "3",
        "elev": null,
        "icao": "LIPQ",
        "direct_flights": "17",
        "carriers": "23"
    },
    {
        "code": "BKO",
        "lat": "12.5361",
        "lon": "-7.9486",
        "name": "Bamako Senou",
        "city": "Kalaban",
        "state": "Bamako",
        "country": "Mali",
        "woeid": "12514773",
        "tz": "AfricaBamako",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "1247",
        "icao": "GABS",
        "direct_flights": "17",
        "carriers": "19"
    },
    {
        "code": "CLJ",
        "lat": "46.7902",
        "lon": "23.6984",
        "name": "Cluj",
        "city": "Cluj",
        "state": "Cluj",
        "country": "Romania",
        "woeid": "12515577",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "1033",
        "icao": "LRCL",
        "direct_flights": "17",
        "carriers": "9"
    },
    {
        "code": "DOK",
        "lat": "48.072",
        "lon": "37.7426",
        "name": "Donetsk",
        "city": "Donets'k",
        "state": "Donets´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12523100",
        "tz": "EuropeZaporozhye",
        "phone": "(+380 622) 515322",
        "type": "Airports",
        "email": "",
        "url": "http:kbp.kiev.uaairportssearchDONETSK.html",
        "runway_length": null,
        "elev": "234",
        "icao": "UKCC",
        "direct_flights": "17",
        "carriers": "12"
    },
    {
        "code": "EBB",
        "lat": "0.0417",
        "lon": "32.4381",
        "name": "Entebbe",
        "city": "Entebbe",
        "state": "Wakiso",
        "country": "Uganda",
        "woeid": "12518024",
        "tz": "AfricaKampala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.caa.co.ugentebbe.php",
        "runway_length": "12000",
        "elev": "3782",
        "icao": "HUEN",
        "direct_flights": "17",
        "carriers": "20"
    },
    {
        "code": "HMA",
        "lat": "61.0286",
        "lon": "69.0919",
        "name": "Khanty-Nansiysk",
        "city": "Khanty-Mansiysk",
        "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
        "country": "Russian Federation",
        "woeid": "2121314",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "17",
        "carriers": "3"
    },
    {
        "code": "HSV",
        "lat": "34.6492",
        "lon": "-86.7767",
        "name": "Huntsville",
        "city": "Huntsville",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12519069",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "363",
        "icao": "KHSV",
        "direct_flights": "17",
        "carriers": "24"
    },
    {
        "code": "MBA",
        "lat": "-4.0314",
        "lon": "39.5936",
        "name": "Moi",
        "city": "Mombasa",
        "state": "Coast",
        "country": "Kenya",
        "woeid": "12514073",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.kenyaairports.co.kemombasaindexMoi.htm",
        "runway_length": "10991",
        "elev": "196",
        "icao": "HKMO",
        "direct_flights": "17",
        "carriers": "14"
    },
    {
        "code": "NJC",
        "lat": "60.9518",
        "lon": "76.4972",
        "name": "Nizhnevartovsk Northwest",
        "city": "Nizhnevartovsk",
        "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
        "country": "Russia",
        "woeid": "12516511",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "17",
        "carriers": "13"
    },
    {
        "code": "PSP",
        "lat": "33.8227",
        "lon": "-116.509",
        "name": "Palm Springs",
        "city": "Palm Springs",
        "state": "California",
        "country": "United States",
        "woeid": "12521289",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "462",
        "icao": "KPSP",
        "direct_flights": "17",
        "carriers": "16"
    },
    {
        "code": "PWM",
        "lat": "43.6475",
        "lon": "-70.3103",
        "name": "Jetport",
        "city": "Portland",
        "state": "Maine",
        "country": "United States",
        "woeid": "12520368",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "74",
        "icao": "KPWM",
        "direct_flights": "17",
        "carriers": "18"
    },
    {
        "code": "SPC",
        "lat": "28.6676",
        "lon": "-17.7708",
        "name": "Santa Cruz de la Palma",
        "city": "Breña Alta",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12523225",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "95",
        "icao": "GCLA",
        "direct_flights": "17",
        "carriers": "10"
    },
    {
        "code": "STT",
        "lat": "18.3333",
        "lon": "-64.9667",
        "name": "Cyril E King",
        "city": "Charlotte Amalie",
        "state": "US Virgin Islands",
        "country": "United States",
        "woeid": "12522972",
        "tz": "AmericaSt_Thomas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5348",
        "elev": "15",
        "icao": "TIST",
        "direct_flights": "17",
        "carriers": "12"
    },
    {
        "code": "SUB",
        "lat": "-7.38",
        "lon": "112.785",
        "name": "Juanda",
        "city": "Sidoarjo",
        "state": "Jawa Timur",
        "country": "Indonesia",
        "woeid": "12513477",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "9",
        "icao": "WARR",
        "direct_flights": "17",
        "carriers": "18"
    },
    {
        "code": "SWA",
        "lat": "23.425",
        "lon": "116.759",
        "name": "Shantou Northeast",
        "city": "Chenghai",
        "state": "Guangdong",
        "country": "China",
        "woeid": "12512184",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "17",
        "carriers": "6"
    },
    {
        "code": "SYZ",
        "lat": "29.54",
        "lon": "52.5897",
        "name": "Shiraz",
        "city": "Shiraz",
        "state": "Fars",
        "country": "Iran",
        "woeid": "12513764",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "14009",
        "elev": "4912",
        "icao": "OISS",
        "direct_flights": "17",
        "carriers": "6"
    },
    {
        "code": "TRV",
        "lat": "8.47824",
        "lon": "76.9215",
        "name": "Thiruvananthapuram",
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "country": "India",
        "woeid": "12513686",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "VOTV",
        "direct_flights": "17",
        "carriers": "18"
    },
    {
        "code": "UPG",
        "lat": "-5.0694",
        "lon": "119.55",
        "name": "Hasanuddin",
        "city": "Maros",
        "state": "Sulawesi Selatan",
        "country": "Indonesia",
        "woeid": "12513470",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "47",
        "icao": "",
        "direct_flights": "17",
        "carriers": "8"
    },
    {
        "code": "VRA",
        "lat": "23.0306",
        "lon": "-81.4367",
        "name": "Varadero",
        "city": "Varadero",
        "state": "Matanzas",
        "country": "Cuba",
        "woeid": "12512489",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7382",
        "elev": "39",
        "icao": "MUVR",
        "direct_flights": "17",
        "carriers": "10"
    },
    {
        "code": "GOA",
        "lat": "44.4119",
        "lon": "8.84633",
        "name": "Genova Cristoforo Colombo",
        "city": "Genova",
        "state": "Liguria",
        "country": "Italy",
        "woeid": "12513821",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportodigenova.comhomeindex.php",
        "runway_length": "9925",
        "elev": "10",
        "icao": "LIMJ",
        "direct_flights": "16",
        "carriers": "22",
        "bookingId": "-118400"
    },
    {
        "code": "AMD",
        "lat": "23.0728",
        "lon": "72.6328",
        "name": "Sardar Vallabhbhai Patel",
        "city": "Ahmedabad",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513529",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "184",
        "icao": "VAAH",
        "direct_flights": "16",
        "carriers": "15"
    },
    {
        "code": "BWN",
        "lat": "4.9458",
        "lon": "114.924",
        "name": "Brunei",
        "city": "Bandar Seri Begawan",
        "state": "Brunei and Muara",
        "country": "Brunei",
        "woeid": "12511563",
        "tz": "AsiaBrunei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "73",
        "icao": "WBSB",
        "direct_flights": "16",
        "carriers": "8"
    },
    {
        "code": "CNX",
        "lat": "18.7714",
        "lon": "98.9628",
        "name": "Chiang Mai",
        "city": "Chiang Mai",
        "state": "Roi Et",
        "country": "Thailand",
        "woeid": "12517750",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportthai.co.thairportnewchmaihtmlgen_info1.htm",
        "runway_length": "10330",
        "elev": "1035",
        "icao": "VTCC",
        "direct_flights": "16",
        "carriers": "21"
    },
    {
        "code": "COO",
        "lat": "6.3542",
        "lon": "2.3856",
        "name": "Cotonou Cadjehon",
        "city": "Cotonou",
        "state": "Atlantique",
        "country": "Benin",
        "woeid": "12510939",
        "tz": "AfricaPorto-Novo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "16",
        "icao": "DBBB",
        "direct_flights": "16",
        "carriers": "21"
    },
    {
        "code": "COS",
        "lat": "38.7829",
        "lon": "-104.697",
        "name": "City of Colorado Springs Municipal",
        "city": "Colorado Springs",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519208",
        "tz": "AmericaDenver",
        "phone": "719-550-1972",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11021",
        "elev": "6172",
        "icao": "KCOS",
        "direct_flights": "16",
        "carriers": "19"
    },
    {
        "code": "GRX",
        "lat": "37.1876",
        "lon": "-3.77498",
        "name": "Granada",
        "city": "Chauchina",
        "state": "Andalucia",
        "country": "Spain",
        "woeid": "12517547",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9514",
        "elev": "1860",
        "icao": "",
        "direct_flights": "16",
        "carriers": "12"
    },
    {
        "code": "HPN",
        "lat": "41.0693",
        "lon": "-73.7042",
        "name": "Westchester County",
        "city": "Purchase",
        "state": "New York",
        "country": "United States",
        "woeid": "12522396",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6548",
        "elev": "439",
        "icao": "KHPN",
        "direct_flights": "16",
        "carriers": "13"
    },
    {
        "code": "HRE",
        "lat": "-17.9228",
        "lon": "31.1014",
        "name": "Harare",
        "city": "Harare",
        "state": "Harare",
        "country": "Zimbabwe",
        "woeid": "12523026",
        "tz": "AfricaHarare",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "15500",
        "elev": "4901",
        "icao": "FVHA",
        "direct_flights": "16",
        "carriers": "13"
    },
    {
        "code": "JAN",
        "lat": "32.3089",
        "lon": "-90.0733",
        "name": "Jackson",
        "city": "Pearl",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12520336",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "346",
        "icao": "KJAN",
        "direct_flights": "16",
        "carriers": "15"
    },
    {
        "code": "JIB",
        "lat": "11.5467",
        "lon": "43.1592",
        "name": "Djibouti Ambouli",
        "city": "Djibouti City",
        "state": "Djibouti",
        "country": "Djibouti",
        "woeid": "12512608",
        "tz": "AfricaDjibouti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10302",
        "elev": "49",
        "icao": "HDAM",
        "direct_flights": "16",
        "carriers": "10"
    },
    {
        "code": "KIN",
        "lat": "17.9305",
        "lon": "-76.7898",
        "name": "Norman Manley",
        "city": "Kingston",
        "state": "Kingston St John",
        "country": "Jamaica",
        "woeid": "12514045",
        "tz": "AmericaJamaica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8786",
        "elev": "10",
        "icao": "MKJP",
        "direct_flights": "16",
        "carriers": "15"
    },
    {
        "code": "LXR",
        "lat": "25.675",
        "lon": "32.7075",
        "name": "Luxor",
        "city": "Luxor",
        "state": "Qina",
        "country": "Egypt",
        "woeid": "12512697",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "288",
        "icao": "",
        "direct_flights": "16",
        "carriers": "13"
    },
    {
        "code": "MPL",
        "lat": "43.5807",
        "lon": "3.96039",
        "name": "Frejorgues",
        "city": "Mauguio",
        "state": "Languedoc-Roussillon",
        "country": "France",
        "woeid": "12512897",
        "tz": "EuropeParis",
        "phone": "+33 (0) 4 67 20 85",
        "type": "Airports",
        "email": "",
        "url": "http:www.montpellier.aeroport.fr",
        "runway_length": "8530",
        "elev": "16",
        "icao": "LFMT",
        "direct_flights": "16",
        "carriers": "15"
    },
    {
        "code": "OME",
        "lat": "64.5125",
        "lon": "-165.445",
        "name": "Nome",
        "city": "Nome",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521145",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6001",
        "elev": "36",
        "icao": "PAOM",
        "direct_flights": "16",
        "carriers": "8"
    },
    {
        "code": "POP",
        "lat": "19.7575",
        "lon": "-70.5692",
        "name": "Puerto Plata",
        "city": "San Felipe de Puerto Plata",
        "state": "Puerto Plata",
        "country": "Dominican Republic",
        "woeid": "12512619",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10105",
        "elev": "16",
        "icao": "MDPP",
        "direct_flights": "16",
        "carriers": "17"
    },
    {
        "code": "PRN",
        "lat": "42.5753",
        "lon": "21.0364",
        "name": "Pristina",
        "city": "Prishtina",
        "state": "Kosovo",
        "country": "Serbia",
        "woeid": "12517589",
        "tz": "EuropeBelgrade",
        "phone": "+381 0 38 5958159",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportpristina.com",
        "runway_length": "8202",
        "elev": "1788",
        "icao": "KPRN",
        "direct_flights": "16",
        "carriers": "14"
    },
    {
        "code": "SBN",
        "lat": "41.7006",
        "lon": "-86.311",
        "name": "South Bend",
        "city": "South Bend",
        "state": "Indiana",
        "country": "United States",
        "woeid": "12520929",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7099",
        "elev": "790",
        "icao": "KSBN",
        "direct_flights": "16",
        "carriers": "14"
    },
    {
        "code": "SDJ",
        "lat": "38.1401",
        "lon": "140.918",
        "name": "Sendai",
        "city": "Natori-shi",
        "state": "Miyagi Prefecture",
        "country": "Japan",
        "woeid": "12514015",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.pref.miyagi.jpKUTAIENGLISHtop-english.htm",
        "runway_length": "9342",
        "elev": "6",
        "icao": "RJSS",
        "direct_flights": "16",
        "carriers": "14"
    },
    {
        "code": "SKP",
        "lat": "41.9606",
        "lon": "21.6217",
        "name": "Petrovec",
        "city": "Skopje",
        "state": "Ilinden",
        "country": "Macedonia",
        "woeid": "12514772",
        "tz": "EuropeBelgrade",
        "phone": "++ (389 2) 3148 300",
        "type": "Airports",
        "email": "skpap@airports.com.mk",
        "url": "http:www.airports.com.mk",
        "runway_length": "8038",
        "elev": "781",
        "icao": "LWSK",
        "direct_flights": "16",
        "carriers": "17"
    },
    {
        "code": "ULN",
        "lat": "47.8458",
        "lon": "106.759",
        "name": "Ulaanbaatar Southwest",
        "city": "Ulan Bator",
        "state": "Tov",
        "country": "Mongolia",
        "woeid": "12514761",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZMUB",
        "direct_flights": "16",
        "carriers": "7"
    },
    {
        "code": "XRY",
        "lat": "36.7497",
        "lon": "-6.06054",
        "name": "Jerez",
        "city": "Jerez",
        "state": "Andalucia",
        "country": "Spain",
        "woeid": "12517549",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "85",
        "icao": "LEJR",
        "direct_flights": "16",
        "carriers": "14"
    },
    {
        "code": "YTH",
        "lat": "55.7999",
        "lon": "-97.8598",
        "name": "Thompson",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511873",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYTH",
        "direct_flights": "16",
        "carriers": "4"
    },
    {
        "code": "FRL",
        "lat": "44.1953",
        "lon": "12.0686",
        "name": "Forli",
        "city": "Forli",
        "state": "Emilia Romagna",
        "country": "Italy",
        "woeid": "12513820",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7907",
        "elev": "98",
        "icao": "LIPK",
        "direct_flights": "15",
        "carriers": "3"
    },
    {
        "code": "RMI",
        "lat": "44.0225",
        "lon": "12.618",
        "name": "Rimini",
        "city": "Rimini",
        "state": "Emilia Romagna",
        "country": "Italy",
        "woeid": "12513855",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9810",
        "elev": "39",
        "icao": "LIPR",
        "direct_flights": "15",
        "carriers": "11",
        "bookingId": "-126373"
    },
    {
        "code": "ADE",
        "lat": "12.8278",
        "lon": "45.0306",
        "name": "Aden",
        "city": "Ash Shaykh Uthman",
        "state": "`Adan",
        "country": "Yemen",
        "woeid": "12522998",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10270",
        "elev": "12",
        "icao": "OYAA",
        "direct_flights": "15",
        "carriers": "8"
    },
    {
        "code": "AGA",
        "lat": "30.3833",
        "lon": "-9.55",
        "name": "Almassira",
        "city": "Agadir",
        "state": "Agadir",
        "country": "Morocco",
        "woeid": "12523043",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9547",
        "elev": "88",
        "icao": "GMAD",
        "direct_flights": "15",
        "carriers": "16"
    },
    {
        "code": "ASB",
        "lat": "37.9842",
        "lon": "58.3294",
        "name": "Ashkhabad Northwest",
        "city": "Ashgabat",
        "state": "Ashkhabad",
        "country": "Turkmenistan",
        "woeid": "12517960",
        "tz": "AsiaAshgabat",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "689",
        "icao": "UTAA",
        "direct_flights": "15",
        "carriers": "8"
    },
    {
        "code": "BAQ",
        "lat": "10.8961",
        "lon": "-74.7789",
        "name": "Ernesto Cortissoz",
        "city": "Soledad",
        "state": "Atlantico",
        "country": "Colombia",
        "woeid": "12512376",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "94",
        "icao": "SKBQ",
        "direct_flights": "15",
        "carriers": "9"
    },
    {
        "code": "BEN",
        "lat": "35.6425",
        "lon": "-87.1192",
        "name": "Benina Intl",
        "city": "Benghazi",
        "state": "Banghazi",
        "country": "Libya",
        "woeid": "1352831",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11800",
        "elev": "433",
        "icao": "HLLB",
        "direct_flights": "15",
        "carriers": "6"
    },
    {
        "code": "BOH",
        "lat": "50.7797",
        "lon": "-1.83424",
        "name": "Bournemouth",
        "city": "Bournemouth",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22460043",
        "tz": "EuropeLondon",
        "phone": "+44 1202 364 000",
        "type": "Airports",
        "email": "",
        "url": "http:www.flybournemouth.com",
        "runway_length": "7450",
        "elev": "36",
        "icao": "EGHH",
        "direct_flights": "15",
        "carriers": "5",
        "bookingId": "-2590498"
    },
    {
        "code": "BTV",
        "lat": "44.4688",
        "lon": "-73.1552",
        "name": "Burlington",
        "city": "South Burlington",
        "state": "Vermont",
        "country": "United States",
        "woeid": "12518979",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7807",
        "elev": "334",
        "icao": "KBTV",
        "direct_flights": "15",
        "carriers": "16"
    },
    {
        "code": "BZE",
        "lat": "17.5386",
        "lon": "-88.3042",
        "name": "Philip S W Goldson",
        "city": "Hattieville",
        "state": "Belize",
        "country": "Belize",
        "woeid": "12510896",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6300",
        "elev": "15",
        "icao": "MZBZ",
        "direct_flights": "15",
        "carriers": "10"
    },
    {
        "code": "CID",
        "lat": "41.8893",
        "lon": "-91.7008",
        "name": "Cedar Rapids Municipal",
        "city": "Cedar Rapids",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12519110",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "864",
        "icao": "KCID",
        "direct_flights": "15",
        "carriers": "20"
    },
    {
        "code": "CNF",
        "lat": "-19.6242",
        "lon": "-43.9717",
        "name": "Tancredo Neves",
        "city": "Confins",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511339",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "SBCF",
        "direct_flights": "15",
        "carriers": "13"
    },
    {
        "code": "ERF",
        "lat": "50.9754",
        "lon": "10.9626",
        "name": "Erfurt",
        "city": "Erfurt",
        "state": "Thuringia",
        "country": "Germany",
        "woeid": "22193194",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1033",
        "icao": "EDDE",
        "direct_flights": "15",
        "carriers": "5",
        "bookingId": "-1766996"
    },
    {
        "code": "FNT",
        "lat": "42.9756",
        "lon": "-83.7411",
        "name": "Bishop",
        "city": "Flint",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12518841",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7848",
        "elev": "782",
        "icao": "KFNT",
        "direct_flights": "15",
        "carriers": "10"
    },
    {
        "code": "GEG",
        "lat": "47.625",
        "lon": "-117.538",
        "name": "Spokane",
        "city": "Spokane",
        "state": "Washington",
        "country": "United States",
        "woeid": "12521938",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "2372",
        "icao": "KGEG",
        "direct_flights": "15",
        "carriers": "20"
    },
    {
        "code": "JNU",
        "lat": "58.3598",
        "lon": "-134.583",
        "name": "Juneau",
        "city": "Juneau",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520409",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8456",
        "elev": "18",
        "icao": "PAJN",
        "direct_flights": "15",
        "carriers": "11"
    },
    {
        "code": "KCH",
        "lat": "1.485",
        "lon": "110.342",
        "name": "Kuching",
        "city": "Kuching",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12514997",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8250",
        "elev": "88",
        "icao": "",
        "direct_flights": "15",
        "carriers": "8"
    },
    {
        "code": "LUN",
        "lat": "-15.3292",
        "lon": "28.4528",
        "name": "Lusaka",
        "city": "Lusaka",
        "state": "Lusaka",
        "country": "Zambia",
        "woeid": "12523011",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13000",
        "elev": "3779",
        "icao": "FLLS",
        "direct_flights": "15",
        "carriers": "14"
    },
    {
        "code": "MAO",
        "lat": "-3.0392",
        "lon": "-60.0469",
        "name": "Eduardo Gomes",
        "city": "Manaus",
        "state": "Amazonas",
        "country": "Brazil",
        "woeid": "12511115",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "279",
        "icao": "KMAO",
        "direct_flights": "15",
        "carriers": "11"
    },
    {
        "code": "RYG",
        "lat": "59.3789",
        "lon": "10.7856",
        "name": "Moss",
        "city": "Rygge",
        "state": "",
        "country": "Norway",
        "woeid": "863279",
        "tz": "EuropeOslo",
        "phone": "(+47) 69 23 58 40",
        "type": "Airports",
        "email": "post@ryg.no",
        "url": "http:www.ryg.no",
        "runway_length": "8",
        "elev": "174",
        "icao": "ENRY",
        "direct_flights": "15",
        "carriers": "1"
    },
    {
        "code": "SDR",
        "lat": "43.4232",
        "lon": "-3.82375",
        "name": "Santander",
        "city": "Santander",
        "state": "Cantabria",
        "country": "Spain",
        "woeid": "12517568",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "17",
        "icao": "",
        "direct_flights": "15",
        "carriers": "2"
    },
    {
        "code": "SEZ",
        "lat": "-4.6751",
        "lon": "55.5177",
        "name": "Seychelles",
        "city": "Victoria",
        "state": "English River",
        "country": "Seychelles",
        "woeid": "12517389",
        "tz": "IndianMahe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9800",
        "elev": "10",
        "icao": "FSIA",
        "direct_flights": "15",
        "carriers": "8"
    },
    {
        "code": "SGF",
        "lat": "37.243",
        "lon": "-93.3817",
        "name": "Springfield",
        "city": "Springfield",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12521949",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7003",
        "elev": "1267",
        "icao": "KSGF",
        "direct_flights": "15",
        "carriers": "16"
    },
    {
        "code": "SIA",
        "lat": "34.3667",
        "lon": "108.7",
        "name": "Xiguan",
        "city": "Xi'an",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "12512238",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "1572",
        "icao": "",
        "direct_flights": "15",
        "carriers": "1"
    },
    {
        "code": "SID",
        "lat": "16.745",
        "lon": "-22.9517",
        "name": "Amilcar Cabral",
        "city": "Santa Maria",
        "state": "Sal",
        "country": "Cape Verde",
        "woeid": "12512490",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10728",
        "elev": "177",
        "icao": "GVAC",
        "direct_flights": "15",
        "carriers": "5"
    },
    {
        "code": "SUV",
        "lat": "-18.05",
        "lon": "178.567",
        "name": "Nausori",
        "city": "Nausori",
        "state": "Central",
        "country": "Fiji",
        "woeid": "12523233",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6053",
        "elev": "16",
        "icao": "",
        "direct_flights": "15",
        "carriers": "2"
    },
    {
        "code": "SZB",
        "lat": "3.1303",
        "lon": "101.551",
        "name": "Sultan Abdul Aziz Shah",
        "city": "Kampong Baru Subang",
        "state": "Selangor",
        "country": "Malaysia",
        "woeid": "12515008",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12402",
        "elev": "89",
        "icao": "WMSA",
        "direct_flights": "15",
        "carriers": "3"
    },
    {
        "code": "UVF",
        "lat": "13.7336",
        "lon": "-60.9542",
        "name": "Hewanorra",
        "city": "Vieux Fort",
        "state": "Vieux Fort",
        "country": "St. Lucia",
        "woeid": "12517595",
        "tz": "AmericaSt_Lucia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "11",
        "icao": "TLPL",
        "direct_flights": "15",
        "carriers": "11"
    },
    {
        "code": "XNA",
        "lat": "36.2756",
        "lon": "-94.2955",
        "name": "Northwest Arkansas",
        "city": "Bentonville",
        "state": "Arkansas",
        "country": "United States",
        "woeid": "29388192",
        "tz": "AmericaChicago",
        "phone": "479-205-1000",
        "type": "Airports",
        "email": "",
        "url": "http:www.nwara.com",
        "runway_length": "8800",
        "elev": "1286",
        "icao": "KXNA",
        "direct_flights": "15",
        "carriers": "13"
    },
    {
        "code": "YHM",
        "lat": "43.1632",
        "lon": "-79.9266",
        "name": "Hamilton",
        "city": "Beamsville",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511688",
        "tz": "AmericaToronto",
        "phone": "(905) 679-1999",
        "type": "Airports",
        "email": "",
        "url": "http:www.flyhi.ca",
        "runway_length": "8000",
        "elev": "780",
        "icao": "CYHM",
        "direct_flights": "15",
        "carriers": "4"
    },
    {
        "code": "ZTH",
        "lat": "37.7522",
        "lon": "20.8847",
        "name": "Zakinthos",
        "city": "Zante",
        "state": "Nisia Ionioy",
        "country": "Greece",
        "woeid": "12513332",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5971",
        "elev": "10",
        "icao": "LGZA",
        "direct_flights": "15",
        "carriers": "7"
    },
    {
        "code": "ABE",
        "lat": "40.6514",
        "lon": "-75.4342",
        "name": "Lehigh Valley",
        "city": "Allentown",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12518581",
        "tz": "AmericaNew_York",
        "phone": "610-266-6000",
        "type": "Airports",
        "email": "",
        "url": "http:www.lvia.org",
        "runway_length": null,
        "elev": null,
        "icao": "KABE",
        "direct_flights": "14",
        "carriers": "11"
    },
    {
        "code": "ADA",
        "lat": "36.9811",
        "lon": "35.2803",
        "name": "Sakirpasa",
        "city": "Adana",
        "state": "Adana",
        "country": "Turkey",
        "woeid": "12517911",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "64",
        "icao": "LTAF",
        "direct_flights": "14",
        "carriers": "4"
    },
    {
        "code": "AKN",
        "lat": "58.6775",
        "lon": "-156.655",
        "name": "King Salmon",
        "city": "King Salmon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520466",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8500",
        "elev": "57",
        "icao": "PAKN",
        "direct_flights": "14",
        "carriers": "7"
    },
    {
        "code": "BES",
        "lat": "48.4458",
        "lon": "-4.41511",
        "name": "Guipavas",
        "city": "Guipavas",
        "state": "Brittany",
        "country": "France",
        "woeid": "12523072",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "325",
        "icao": "LFRB",
        "direct_flights": "14",
        "carriers": "9"
    },
    {
        "code": "BIQ",
        "lat": "43.472",
        "lon": "-1.53137",
        "name": "Anglet",
        "city": "Anglet",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512842",
        "tz": "EuropeParis",
        "phone": "+33 5 59 43 83 83",
        "type": "Airports",
        "email": "",
        "url": "http:www.biarritz.aeroport.frindexen.html",
        "runway_length": "7382",
        "elev": "243",
        "icao": "LFBZ",
        "direct_flights": "14",
        "carriers": "8"
    },
    {
        "code": "BOO",
        "lat": "67.2709",
        "lon": "14.3653",
        "name": "Bodo",
        "city": "Bodo",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12515105",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9163",
        "elev": "42",
        "icao": "ENBO",
        "direct_flights": "14",
        "carriers": "4"
    },
    {
        "code": "BUR",
        "lat": "34.1963",
        "lon": "-118.352",
        "name": "Burbank Glendale Pasadena",
        "city": "Burbank",
        "state": "California",
        "country": "United States",
        "woeid": "12518974",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.bobhopeairport.com",
        "runway_length": "6902",
        "elev": "775",
        "icao": "KBUR",
        "direct_flights": "14",
        "carriers": "12"
    },
    {
        "code": "CEK",
        "lat": "55.3104",
        "lon": "61.4913",
        "name": "Chelyabinsk Balandino",
        "city": "Zlatoust",
        "state": "Chelyabinskaya Oblast",
        "country": "Russia",
        "woeid": "12515894",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCEK",
        "direct_flights": "14",
        "carriers": "11"
    },
    {
        "code": "CZM",
        "lat": "20.5242",
        "lon": "-86.9264",
        "name": "Cozumel",
        "city": "San Miguel de Cozumel",
        "state": "Quintana Roo",
        "country": "Mexico",
        "woeid": "12514855",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8859",
        "elev": "16",
        "icao": "MMCZ",
        "direct_flights": "14",
        "carriers": "12"
    },
    {
        "code": "DYG",
        "lat": "29.1333",
        "lon": "110.483",
        "name": "Dayong",
        "city": "Dayong",
        "state": "Hunan",
        "country": "China",
        "woeid": "2142725",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "14",
        "carriers": "8"
    },
    {
        "code": "FAT",
        "lat": "36.7675",
        "lon": "-119.717",
        "name": "Fresno Yosemite",
        "city": "Fresno",
        "state": "California",
        "country": "United States",
        "woeid": "12519826",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9222",
        "elev": "332",
        "icao": "KFAT",
        "direct_flights": "14",
        "carriers": "19"
    },
    {
        "code": "FRU",
        "lat": "43.0603",
        "lon": "74.4866",
        "name": "Vasilyevka",
        "city": "Bishkek",
        "state": "Chuy",
        "country": "Kyrgyzstan",
        "woeid": "12514125",
        "tz": "AsiaBishkek",
        "phone": "+996 312 693109",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.kg",
        "runway_length": null,
        "elev": null,
        "icao": "UAFM",
        "direct_flights": "14",
        "carriers": "17"
    },
    {
        "code": "GSE",
        "lat": "57.7774",
        "lon": "11.8648",
        "name": "Save",
        "city": "Kyrkobyn",
        "state": "Vastra Gotaland",
        "country": "Sweden",
        "woeid": "12517675",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.goteborgcityairport.se",
        "runway_length": "6316",
        "elev": "66",
        "icao": "ESGP",
        "direct_flights": "14",
        "carriers": "3"
    },
    {
        "code": "ICT",
        "lat": "37.6531",
        "lon": "-97.43",
        "name": "Wichita Mid-Continent",
        "city": "Wichita",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12522432",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7302",
        "elev": "1332",
        "icao": "KICT",
        "direct_flights": "14",
        "carriers": "22"
    },
    {
        "code": "INC",
        "lat": "38.4999",
        "lon": "106.146",
        "name": "Yinchuan",
        "city": "Yinchuan",
        "state": "Ningxia",
        "country": "China",
        "woeid": "12512275",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "14",
        "carriers": "11"
    },
    {
        "code": "INV",
        "lat": "57.5399",
        "lon": "-4.06352",
        "name": "Inverness",
        "city": "Inverness",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22469954",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.hial.co.ukinverness-airport.html",
        "runway_length": "6190",
        "elev": "31",
        "icao": "",
        "direct_flights": "14",
        "carriers": "6",
        "bookingId": "-2599537"
    },
    {
        "code": "JAI",
        "lat": "26.8165",
        "lon": "75.8128",
        "name": "Sanganer",
        "city": "Jaipur",
        "state": "Rajasthan",
        "country": "India",
        "woeid": "12513603",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5955",
        "elev": "1278",
        "icao": "VIJP",
        "direct_flights": "14",
        "carriers": "12"
    },
    {
        "code": "KHH",
        "lat": "22.5698",
        "lon": "120.345",
        "name": "Kaohsiung",
        "city": "Kaohsiung City",
        "state": "Kaohsiung City",
        "country": "Taiwan",
        "woeid": "2306255",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10006",
        "elev": "30",
        "icao": "RCKH",
        "direct_flights": "14",
        "carriers": "15"
    },
    {
        "code": "LEX",
        "lat": "38.0384",
        "lon": "-84.5989",
        "name": "Blue Grass Field",
        "city": "Lexington",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "24554866",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7003",
        "elev": "980",
        "icao": "KLEX",
        "direct_flights": "14",
        "carriers": "17"
    },
    {
        "code": "MME",
        "lat": "54.5116",
        "lon": "-1.43475",
        "name": "Durham Tees Valley",
        "city": "Darlington",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22461793",
        "tz": "EuropeLondon",
        "phone": "+44 1325 332811",
        "type": "Airports",
        "email": "",
        "url": "http:www.durhamteesvalleyairport.com",
        "runway_length": null,
        "elev": null,
        "icao": "EGNV",
        "direct_flights": "14",
        "carriers": "26"
    },
    {
        "code": "MPM",
        "lat": "-25.9211",
        "lon": "32.5694",
        "name": "Maputo",
        "city": "Maputo",
        "state": "Maputo",
        "country": "Mozambique",
        "woeid": "12515021",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11910",
        "elev": "131",
        "icao": "",
        "direct_flights": "14",
        "carriers": "7"
    },
    {
        "code": "NSK",
        "lat": "69.3089",
        "lon": "87.3351",
        "name": "Norilsk Alykel",
        "city": "Kansk",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "12516523",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "14",
        "carriers": "9"
    },
    {
        "code": "REP",
        "lat": "13.4092",
        "lon": "103.816",
        "name": "Siem Reap",
        "city": "Siemrap",
        "state": "Siem Reab",
        "country": "Cambodia",
        "woeid": "12511929",
        "tz": "AsiaPhnom_Penh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cambodia-airports.com",
        "runway_length": "8366",
        "elev": "75",
        "icao": "VDSR",
        "direct_flights": "14",
        "carriers": "16"
    },
    {
        "code": "SRQ",
        "lat": "27.3868",
        "lon": "-82.5516",
        "name": "Sarasota Bradenton",
        "city": "Sarasota",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521750",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7003",
        "elev": "28",
        "icao": "KSRQ",
        "direct_flights": "14",
        "carriers": "14"
    },
    {
        "code": "UUS",
        "lat": "46.8885",
        "lon": "142.724",
        "name": "Yuzhno Sakhalinsk South",
        "city": "Kholmsk",
        "state": "Sakhalinskaya Oblast",
        "country": "Russia",
        "woeid": "12517272",
        "tz": "AsiaVladivostok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "56",
        "icao": "UHSS",
        "direct_flights": "14",
        "carriers": "9"
    },
    {
        "code": "VCP",
        "lat": "-23.0067",
        "lon": "-47.1344",
        "name": "Viracopos",
        "city": "Campinas",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511374",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10630",
        "elev": "2169",
        "icao": "SBKP",
        "direct_flights": "14",
        "carriers": "11"
    },
    {
        "code": "VVI",
        "lat": "-17.6411",
        "lon": "-63.1339",
        "name": "Viru Viru",
        "city": "Santa Cruz",
        "state": "Santa Cruz",
        "country": "Bolivia",
        "woeid": "12510911",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "1224",
        "icao": "SLVR",
        "direct_flights": "14",
        "carriers": "9"
    },
    {
        "code": "VBS",
        "lat": "45.4343",
        "lon": "10.3214",
        "name": "Brescia Montichiari",
        "city": "Brescia",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "711410",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LIPO",
        "direct_flights": "13",
        "carriers": "7",
        "bookingId": "-112155"
    },
    {
        "code": "ARH",
        "lat": "64.4465",
        "lon": "40.4242",
        "name": "Arkhangelsk",
        "city": "Talagi",
        "state": "Arkhangelrskaya Oblast",
        "country": "Russia",
        "woeid": "12515724",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ULAA",
        "direct_flights": "13",
        "carriers": "5"
    },
    {
        "code": "BDA",
        "lat": "32.3675",
        "lon": "-64.6903",
        "name": "Bermuda",
        "city": "St. George",
        "state": "Pembroke",
        "country": "Bermuda",
        "woeid": "12523071",
        "tz": "AtlanticBermuda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9713",
        "elev": "12",
        "icao": "TXKF",
        "direct_flights": "13",
        "carriers": "12"
    },
    {
        "code": "BIL",
        "lat": "45.8034",
        "lon": "-108.537",
        "name": "Logan",
        "city": "Billings",
        "state": "Montana",
        "country": "United States",
        "woeid": "12520688",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10528",
        "elev": "3649",
        "icao": "KBIL",
        "direct_flights": "13",
        "carriers": "14"
    },
    {
        "code": "BLK",
        "lat": "53.7756",
        "lon": "-3.04118",
        "name": "Blackpool",
        "city": "Blackpool",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22454971",
        "tz": "EuropeLondon",
        "phone": "08700 273 777",
        "type": "Airports",
        "email": "",
        "url": "http:www.blackpoolinternational.com",
        "runway_length": "6001",
        "elev": "34",
        "icao": "EGNH",
        "direct_flights": "13",
        "carriers": "4",
        "bookingId": "-2590134"
    },
    {
        "code": "CAK",
        "lat": "40.9149",
        "lon": "-81.4309",
        "name": "Akron Canton",
        "city": "Canton",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12518548",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6397",
        "elev": "1228",
        "icao": "KCAK",
        "direct_flights": "13",
        "carriers": "13"
    },
    {
        "code": "CGB",
        "lat": "-15.6497",
        "lon": "-56.1169",
        "name": "Marechal Rondon",
        "city": "Cuiaba",
        "state": "Centro-Oeste",
        "country": "Brazil",
        "woeid": "12511228",
        "tz": "AmericaCuiaba",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "613",
        "icao": "SBCY",
        "direct_flights": "13",
        "carriers": "5"
    },
    {
        "code": "CHA",
        "lat": "35.0373",
        "lon": "-85.1966",
        "name": "Chattanooga Metropolitan",
        "city": "Chattanooga",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12520716",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7401",
        "elev": "682",
        "icao": "KCHA",
        "direct_flights": "13",
        "carriers": "15"
    },
    {
        "code": "CZL",
        "lat": "36.2778",
        "lon": "6.6247",
        "name": "Ain El Bey",
        "city": "Constantine",
        "state": "Constantine",
        "country": "Algeria",
        "woeid": "12510312",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "2303",
        "icao": "KCZL",
        "direct_flights": "13",
        "carriers": "2"
    },
    {
        "code": "EOH",
        "lat": "6.2231",
        "lon": "-75.5886",
        "name": "Olaya Herrera",
        "city": "Medellin",
        "state": "Antioquia",
        "country": "Colombia",
        "woeid": "12512399",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeropuertoolayaherrera.gov.co",
        "runway_length": "5900",
        "elev": "4940",
        "icao": "SKMD",
        "direct_flights": "13",
        "carriers": "3"
    },
    {
        "code": "FMM",
        "lat": "20.499",
        "lon": "103.75",
        "name": "Memmingen",
        "city": "Memmingen",
        "state": "Bavaria",
        "country": "Germany",
        "woeid": "20153097",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "1",
        "bookingId": "-1824854"
    },
    {
        "code": "GCI",
        "lat": "49.4341",
        "lon": "-2.60003",
        "name": "Guernsey",
        "city": "St. Peter Port",
        "state": "Channel Islands",
        "country": "United Kingdom",
        "woeid": "12513029",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "336",
        "icao": "EGJB",
        "direct_flights": "13",
        "carriers": "2"
    },
    {
        "code": "GND",
        "lat": "12.0056",
        "lon": "-61.7867",
        "name": "Point Salines",
        "city": "Grenada",
        "state": "Saint George",
        "country": "Grenada",
        "woeid": "12513028",
        "tz": "AmericaGrenada",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "30",
        "icao": "TGPY",
        "direct_flights": "13",
        "carriers": "9"
    },
    {
        "code": "GOI",
        "lat": "15.3818",
        "lon": "73.8265",
        "name": "Dabolim",
        "city": "Vasco Da Gama",
        "state": "Goa",
        "country": "India",
        "woeid": "12513587",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7850",
        "elev": "157",
        "icao": "",
        "direct_flights": "13",
        "carriers": "10"
    },
    {
        "code": "JHB",
        "lat": "1.6389",
        "lon": "103.671",
        "name": "Sultan Ismail",
        "city": "Senai",
        "state": "Johor",
        "country": "Malaysia",
        "woeid": "12515010",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11004",
        "elev": "135",
        "icao": "",
        "direct_flights": "13",
        "carriers": "6"
    },
    {
        "code": "JMK",
        "lat": "37.4383",
        "lon": "25.3472",
        "name": "Mykonos",
        "city": "Mikonos",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513311",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "405",
        "icao": "",
        "direct_flights": "13",
        "carriers": "10"
    },
    {
        "code": "JTR",
        "lat": "36.4033",
        "lon": "25.4806",
        "name": "Santorini",
        "city": "Santorini",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513321",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "130",
        "icao": "",
        "direct_flights": "13",
        "carriers": "9"
    },
    {
        "code": "KIJ",
        "lat": "37.9553",
        "lon": "139.113",
        "name": "Niigata",
        "city": "Niigata-shi",
        "state": "Niigata Prefecture",
        "country": "Japan",
        "woeid": "12514001",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "23",
        "icao": "RJSN",
        "direct_flights": "13",
        "carriers": "12"
    },
    {
        "code": "KTN",
        "lat": "55.354",
        "lon": "-131.706",
        "name": "Ketchikan",
        "city": "Ketchikan",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520453",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7497",
        "elev": "88",
        "icao": "PAKT",
        "direct_flights": "13",
        "carriers": "11"
    },
    {
        "code": "LEI",
        "lat": "36.8459",
        "lon": "-2.37221",
        "name": "Almeria",
        "city": "Almeria",
        "state": "Andalucia",
        "country": "Spain",
        "woeid": "12517536",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "50",
        "icao": "LEAM",
        "direct_flights": "13",
        "carriers": "10"
    },
    {
        "code": "LIR",
        "lat": "10.5922",
        "lon": "-85.5439",
        "name": "Tomas Guardia",
        "city": "Liberia",
        "state": "Guanacaste",
        "country": "Costa Rica",
        "woeid": "12512444",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7465",
        "elev": "230",
        "icao": "MRLB",
        "direct_flights": "13",
        "carriers": "9"
    },
    {
        "code": "LRD",
        "lat": "27.5431",
        "lon": "-99.4555",
        "name": "Laredo",
        "city": "Laredo",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520571",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8201",
        "elev": "508",
        "icao": "KLRD",
        "direct_flights": "13",
        "carriers": "15"
    },
    {
        "code": "LRH",
        "lat": "46.1766",
        "lon": "-1.19372",
        "name": "Laleu",
        "city": "La Rochelle",
        "state": "Pays de la Loire",
        "country": "France",
        "woeid": "12512909",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7021",
        "elev": "72",
        "icao": "",
        "direct_flights": "13",
        "carriers": "6"
    },
    {
        "code": "LWO",
        "lat": "49.7977",
        "lon": "23.9695",
        "name": "Sknilov",
        "city": "L'viv",
        "state": "L´vivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518429",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8235",
        "elev": "1063",
        "icao": "UKLL",
        "direct_flights": "13",
        "carriers": "13"
    },
    {
        "code": "MVD",
        "lat": "-34.8291",
        "lon": "-56.0323",
        "name": "Carrasco",
        "city": "Montevideo",
        "state": "Montevideo",
        "country": "Uruguay",
        "woeid": "12522559",
        "tz": "AmericaMontevideo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "105",
        "icao": "SUMU",
        "direct_flights": "13",
        "carriers": "14"
    },
    {
        "code": "MYR",
        "lat": "33.6821",
        "lon": "-78.9228",
        "name": "Myrtle Beach",
        "city": "Myrtle Beach",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12521074",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "9502",
        "elev": "26",
        "icao": "KMYR",
        "direct_flights": "13",
        "carriers": "12"
    },
    {
        "code": "NDJ",
        "lat": "12.1347",
        "lon": "15.035",
        "name": "Ndjamena",
        "city": "N'Djamena",
        "state": "Chari-Baguirmi",
        "country": "Chad",
        "woeid": "12511933",
        "tz": "AfricaNdjamena",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "968",
        "icao": "FTTJ",
        "direct_flights": "13",
        "carriers": "15"
    },
    {
        "code": "OTZ",
        "lat": "66.8893",
        "lon": "-162.61",
        "name": "Ralph Wien Memorial",
        "city": "Kotzebue",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521522",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5900",
        "elev": "11",
        "icao": "PAOT",
        "direct_flights": "13",
        "carriers": "8"
    },
    {
        "code": "OUA",
        "lat": "12.3522",
        "lon": "-1.5133",
        "name": "Ouagadougou",
        "city": "Ouagadougou",
        "state": "Kadiogo",
        "country": "Burkina Faso",
        "woeid": "12522555",
        "tz": "AfricaOuagadougou",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "1037",
        "icao": "DFFD",
        "direct_flights": "13",
        "carriers": "15"
    },
    {
        "code": "PEW",
        "lat": "33.9933",
        "lon": "71.515",
        "name": "Peshawar",
        "city": "Peshawar",
        "state": "Federally Administered Tribal Ar",
        "country": "Pakistan",
        "woeid": "12515258",
        "tz": "AsiaKarachi",
        "phone": "0092 303 6909744",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "1180",
        "icao": "OPPS",
        "direct_flights": "13",
        "carriers": "10"
    },
    {
        "code": "PUY",
        "lat": "44.8939",
        "lon": "13.9272",
        "name": "Pula",
        "city": "Pluj",
        "state": "Istarska",
        "country": "Croatia",
        "woeid": "12513369",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9678",
        "elev": "276",
        "icao": "LDPL",
        "direct_flights": "13",
        "carriers": "8"
    },
    {
        "code": "RAI",
        "lat": "14.9253",
        "lon": "-23.5031",
        "name": "Francisco Mendes",
        "city": "Praia",
        "state": "Praia",
        "country": "Cape Verde",
        "woeid": "12512491",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5828",
        "elev": "229",
        "icao": "",
        "direct_flights": "13",
        "carriers": "4"
    },
    {
        "code": "REU",
        "lat": "41.1444",
        "lon": "1.15631",
        "name": "tarragona",
        "city": "tarragona",
        "state": "Catalonia",
        "country": "Spain",
        "woeid": "12517564",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9513",
        "elev": "233",
        "icao": "LERS",
        "direct_flights": "13",
        "carriers": "2"
    },
    {
        "code": "ROA",
        "lat": "37.3203",
        "lon": "-79.9688",
        "name": "Roanoke-Woodrum Field",
        "city": "Roanoke",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12521606",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "1175",
        "icao": "KROA",
        "direct_flights": "13",
        "carriers": "18"
    },
    {
        "code": "SAP",
        "lat": "15.4514",
        "lon": "-87.9242",
        "name": "La Mesa",
        "city": "San Pedro Sula",
        "state": "Cortés",
        "country": "Honduras",
        "woeid": "12513361",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9613",
        "elev": "90",
        "icao": "MHLM",
        "direct_flights": "13",
        "carriers": "18"
    },
    {
        "code": "SCN",
        "lat": "49.2188",
        "lon": "7.11263",
        "name": "Saarbrucken",
        "city": "Sarrebruck",
        "state": "Saar",
        "country": "Germany",
        "woeid": "22273157",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1057",
        "icao": "EDDR",
        "direct_flights": "13",
        "carriers": "6",
        "bookingId": "-1854320"
    },
    {
        "code": "SCO",
        "lat": "43.8667",
        "lon": "51.1",
        "name": "Aktau",
        "city": "Aktau",
        "state": "Mangghystau",
        "country": "Kazakhstan",
        "woeid": "2261655",
        "tz": "AsiaAqtau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "6"
    },
    {
        "code": "SCW",
        "lat": "61.6726",
        "lon": "50.822",
        "name": "Syktyvkar",
        "city": "Syktyvkar",
        "state": "Komi",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "5"
    },
    {
        "code": "TGD",
        "lat": "42.3675",
        "lon": "19.275",
        "name": "Titograd",
        "city": "Podgorica",
        "state": "Montenegro",
        "country": "Montenegro",
        "woeid": "12517592",
        "tz": "EuropePodgorica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "121",
        "icao": "LYPG",
        "direct_flights": "13",
        "carriers": "8"
    },
    {
        "code": "TNG",
        "lat": "35.7283",
        "lon": "-5.9175",
        "name": "Boukhalf",
        "city": "Tangiers",
        "state": "Tanger",
        "country": "Morocco",
        "woeid": "12514783",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "GMTT",
        "direct_flights": "13",
        "carriers": "8"
    },
    {
        "code": "TSA",
        "lat": "25.0637",
        "lon": "121.552",
        "name": "Taipei Songshan",
        "city": "Taipei City",
        "state": "Taipei City",
        "country": "Taiwan",
        "woeid": "12517954",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "RCSS",
        "direct_flights": "13",
        "carriers": "9"
    },
    {
        "code": "VLL",
        "lat": "41.7077",
        "lon": "-4.84504",
        "name": "Valladolid",
        "city": "Valladolid",
        "state": "Castille and Leon",
        "country": "Spain",
        "woeid": "12517578",
        "tz": "EuropeMadrid",
        "phone": "00 34 983 415 500",
        "type": "Airports",
        "email": "",
        "url": "http:www.aena.es",
        "runway_length": "9843",
        "elev": "2785",
        "icao": "KVLL",
        "direct_flights": "13",
        "carriers": "5"
    },
    {
        "code": "WUX",
        "lat": "31.574",
        "lon": "120.288",
        "name": "Wuxi",
        "city": "Wuxi",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "2137083",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "5"
    },
    {
        "code": "XUZ",
        "lat": "32.9444",
        "lon": "119.119",
        "name": "Jiangsu",
        "city": "Xuzhou",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "12577994",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "7"
    },
    {
        "code": "ZQW",
        "lat": "49.9545",
        "lon": "7.31024",
        "name": "Rheinland-Pfalz",
        "city": "Zweibrucken",
        "state": "Rheinland-Pfalz",
        "country": "Germany",
        "woeid": "2345488",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "13",
        "carriers": "2"
    },
    {
        "code": "AOI",
        "lat": "43.6158",
        "lon": "13.3634",
        "name": "Falconara",
        "city": "Falconara Marittima",
        "state": "Marche",
        "country": "Italy",
        "woeid": "12513819",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.ancona-airport.com",
        "runway_length": "9810",
        "elev": "50",
        "icao": "LIPY",
        "direct_flights": "12",
        "carriers": "16"
    },
    {
        "code": "AAN",
        "lat": "24.25",
        "lon": "55.75",
        "name": "Al Ain",
        "city": "Ayn al Faydah",
        "state": "Abu Dhabi",
        "country": "United Arab Emirates",
        "woeid": "12523371",
        "tz": "AsiaDubai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OMAL",
        "direct_flights": "12",
        "carriers": "9"
    },
    {
        "code": "ACA",
        "lat": "16.7561",
        "lon": "-99.7536",
        "name": "General Juan N Alvarez",
        "city": "Acapulco",
        "state": "Guerrero",
        "country": "Mexico",
        "woeid": "12514882",
        "tz": "AmericaMexico_City",
        "phone": "52-748-66-93-23",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMAA",
        "direct_flights": "12",
        "carriers": "17"
    },
    {
        "code": "ADQ",
        "lat": "57.7545",
        "lon": "-152.512",
        "name": "Kodiak",
        "city": "Kodiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520492",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7548",
        "elev": "73",
        "icao": "PADQ",
        "direct_flights": "12",
        "carriers": "7"
    },
    {
        "code": "BEL",
        "lat": "-1.3844",
        "lon": "-48.4783",
        "name": "Val de Caes",
        "city": "Belem",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511369",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "52",
        "icao": "SBBE",
        "direct_flights": "12",
        "carriers": "7"
    },
    {
        "code": "BJX",
        "lat": "20.9903",
        "lon": "-101.478",
        "name": "Silao",
        "city": "Silao",
        "state": "Guanajuato",
        "country": "Mexico",
        "woeid": "12514965",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMLO",
        "direct_flights": "12",
        "carriers": "13"
    },
    {
        "code": "BZV",
        "lat": "-4.2558",
        "lon": "15.2497",
        "name": "Brazzaville Maya Maya",
        "city": "Brazzaville",
        "state": "Brazzaville",
        "country": "Congo",
        "woeid": "12511945",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10826",
        "elev": "1047",
        "icao": "FCBB",
        "direct_flights": "12",
        "carriers": "20"
    },
    {
        "code": "DMK",
        "lat": "13.9181",
        "lon": "100.59",
        "name": "Don Mueang",
        "city": "Don Muang",
        "state": "Bangkok",
        "country": "Thailand",
        "woeid": "28341403",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "VTBD",
        "direct_flights": "12",
        "carriers": "4"
    },
    {
        "code": "EGC",
        "lat": "44.8227",
        "lon": "0.51524",
        "name": "Bergerac-Roumaniere",
        "city": "Bergerac",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512960",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "6742",
        "elev": "167",
        "icao": "LFBE",
        "direct_flights": "12",
        "carriers": "3"
    },
    {
        "code": "EGE",
        "lat": "39.6395",
        "lon": "-106.912",
        "name": "Eagle County",
        "city": "Gypsum",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519558",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "6538",
        "icao": "KEGE",
        "direct_flights": "12",
        "carriers": "9"
    },
    {
        "code": "EUG",
        "lat": "44.1184",
        "lon": "-123.213",
        "name": "Mahlon Sweet Field",
        "city": "Eugene",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12520758",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6202",
        "elev": "365",
        "icao": "KEUG",
        "direct_flights": "12",
        "carriers": "15"
    },
    {
        "code": "FLN",
        "lat": "-27.6697",
        "lon": "-48.5517",
        "name": "Hercilio Luz",
        "city": "Florianopolis",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511173",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7942",
        "elev": "20",
        "icao": "",
        "direct_flights": "12",
        "carriers": "10"
    },
    {
        "code": "GAU",
        "lat": "26.0857",
        "lon": "91.5669",
        "name": "Borjhar",
        "city": "Gawahati",
        "state": "Assam",
        "country": "India",
        "woeid": "12513585",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "161",
        "icao": "VEGT",
        "direct_flights": "12",
        "carriers": "7"
    },
    {
        "code": "GRB",
        "lat": "44.4923",
        "lon": "-88.1274",
        "name": "Austin Straubel",
        "city": "Green Bay",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12518709",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.co.brown.wi.usairport",
        "runway_length": "7699",
        "elev": "695",
        "icao": "KGRB",
        "direct_flights": "12",
        "carriers": "16"
    },
    {
        "code": "HIJ",
        "lat": "34.4368",
        "lon": "132.922",
        "name": "Hiroshima",
        "city": "Mihara-shi",
        "state": "Hiroshima Prefecture",
        "country": "Japan",
        "woeid": "28360532",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "15",
        "icao": "",
        "direct_flights": "12",
        "carriers": "12"
    },
    {
        "code": "LBD",
        "lat": "40.2833",
        "lon": "69.6167",
        "name": "Khujand",
        "city": "Khudzhand",
        "state": "Leninobod",
        "country": "Tajikistan",
        "woeid": "2212369",
        "tz": "AsiaDushanbe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "12",
        "carriers": "7"
    },
    {
        "code": "MDE",
        "lat": "6.1675",
        "lon": "-75.4267",
        "name": "Jose Maria Cordova",
        "city": "Ríonegro",
        "state": "Antioquia",
        "country": "Colombia",
        "woeid": "12512386",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "7028",
        "icao": "SKRG",
        "direct_flights": "12",
        "carriers": "11"
    },
    {
        "code": "MLH",
        "lat": "47.5998",
        "lon": "7.53134",
        "name": "Bale Mulhouse",
        "city": "Basel Mulhouse Freiburg",
        "state": "Alsace",
        "country": "France",
        "woeid": "12512851",
        "tz": "EuropeZurich",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.euroairport.com",
        "runway_length": "12795",
        "elev": "883",
        "icao": "LFSB",
        "direct_flights": "12",
        "carriers": "13"
    },
    {
        "code": "OOL",
        "lat": "51.188",
        "lon": "5.94811",
        "name": "Gold Coast (Coolangatta)",
        "city": "Gold Coast",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "7226269",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6700",
        "elev": "21",
        "icao": "YBCG",
        "direct_flights": "12",
        "carriers": "9"
    },
    {
        "code": "PAP",
        "lat": "36.4215",
        "lon": "-83.3607",
        "name": "Port Au Prince",
        "city": "Port Au Prince",
        "state": "Ouest",
        "country": "Haiti",
        "woeid": "96110",
        "tz": "AmericaPort-au-Prince",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9974",
        "elev": "109",
        "icao": "MTPP",
        "direct_flights": "12",
        "carriers": "11"
    },
    {
        "code": "PEE",
        "lat": "57.9114",
        "lon": "56.0174",
        "name": "Bolshesavino",
        "city": "Perm'",
        "state": "Permskiy Kray",
        "country": "Russia",
        "woeid": "12515842",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "12",
        "carriers": "8"
    },
    {
        "code": "PNH",
        "lat": "11.5458",
        "lon": "104.849",
        "name": "Pochentong",
        "city": "Phnom Penh",
        "state": "Phnum Penh",
        "country": "Cambodia",
        "woeid": "12511928",
        "tz": "AsiaPhnom_Penh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cambodia-airports.com",
        "runway_length": "9842",
        "elev": "39",
        "icao": "VDPP",
        "direct_flights": "12",
        "carriers": "22"
    },
    {
        "code": "REN",
        "lat": "51.7953",
        "lon": "55.4498",
        "name": "Orenburg East",
        "city": "Orenburg",
        "state": "Orenburgskaya Oblast",
        "country": "Russia",
        "woeid": "12516620",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "12",
        "carriers": "1"
    },
    {
        "code": "SBA",
        "lat": "34.4255",
        "lon": "-119.835",
        "name": "Santa Barbara Municipal",
        "city": "Goleta",
        "state": "California",
        "country": "United States",
        "woeid": "12521741",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6049",
        "elev": "10",
        "icao": "KSBA",
        "direct_flights": "12",
        "carriers": "17"
    },
    {
        "code": "TZX",
        "lat": "41",
        "lon": "39.7167",
        "name": "Trabzon Air Base",
        "city": "Trabzon",
        "state": "Trabzon",
        "country": "Turkey",
        "woeid": "12523250",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2640",
        "elev": "104",
        "icao": "LTCD",
        "direct_flights": "12",
        "carriers": "4"
    },
    {
        "code": "VTE",
        "lat": "17.9833",
        "lon": "102.565",
        "name": "Vientiane",
        "city": "Vientiane",
        "state": "Viangchan",
        "country": "Laos",
        "woeid": "12514560",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "559",
        "icao": "VLVT",
        "direct_flights": "12",
        "carriers": "6"
    },
    {
        "code": "YFB",
        "lat": "63.7514",
        "lon": "-68.5353",
        "name": "Iqaluit",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12511707",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "110",
        "icao": "CYFB",
        "direct_flights": "12",
        "carriers": "3"
    },
    {
        "code": "YXE",
        "lat": "52.1703",
        "lon": "-106.689",
        "name": "John G Diefenbaker",
        "city": "Saskatoon",
        "state": "Saskatchewan",
        "country": "Canada",
        "woeid": "12511826",
        "tz": "AmericaRegina",
        "phone": "+1 306 9758900",
        "type": "Airports",
        "email": "comments@saskatoonairport.ca",
        "url": "http:www.yxe.ca",
        "runway_length": "8300",
        "elev": "1650",
        "icao": "CYXE",
        "direct_flights": "12",
        "carriers": "8"
    },
    {
        "code": "ZIH",
        "lat": "17.6025",
        "lon": "-101.458",
        "name": "Ixtapa Zihuatanejo",
        "city": "Petatlán",
        "state": "Guerrero",
        "country": "Mexico",
        "woeid": "12514897",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "10",
        "icao": "MMZH",
        "direct_flights": "12",
        "carriers": "15"
    },
    {
        "code": "TPS",
        "lat": "37.9021",
        "lon": "12.4994",
        "name": "Trapani",
        "city": "Trapani",
        "state": "Sicily",
        "country": "Italy",
        "woeid": "12513863",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LICT",
        "direct_flights": "11",
        "carriers": "2",
        "bookingId": "-131189"
    },
    {
        "code": "BAX",
        "lat": "53.3667",
        "lon": "83.75",
        "name": "Barnaui West",
        "city": "Barnaul",
        "state": "Altayskiy Kray",
        "country": "Russia",
        "woeid": "12515778",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KBAX",
        "direct_flights": "11",
        "carriers": "7"
    },
    {
        "code": "BMA",
        "lat": "59.3562",
        "lon": "17.9378",
        "name": "Bromma",
        "city": "Bromma",
        "state": "Stockholm",
        "country": "Sweden",
        "woeid": "12517616",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.bromma.lfv.se",
        "runway_length": "5863",
        "elev": "46",
        "icao": "ESSB",
        "direct_flights": "11",
        "carriers": "8"
    },
    {
        "code": "CTG",
        "lat": "10.4481",
        "lon": "-75.5106",
        "name": "Rafael Nunez",
        "city": "La Boquilla",
        "state": "Bolivar",
        "country": "Colombia",
        "woeid": "12512408",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "5",
        "icao": "SKCG",
        "direct_flights": "11",
        "carriers": "8"
    },
    {
        "code": "IFN",
        "lat": "32.7514",
        "lon": "51.8639",
        "name": "Esfahan",
        "city": "Esfahan",
        "state": "Esfahan",
        "country": "Iran",
        "woeid": "12513720",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "5242",
        "icao": "",
        "direct_flights": "11",
        "carriers": "4"
    },
    {
        "code": "JJN",
        "lat": "24.9",
        "lon": "118.583",
        "name": "Jinjiang",
        "city": "Jinjiang",
        "state": "Fujian",
        "country": "China",
        "woeid": "2139965",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZBDX",
        "direct_flights": "11",
        "carriers": "5"
    },
    {
        "code": "KOA",
        "lat": "19.7334",
        "lon": "-156.039",
        "name": "Kailua-Kona",
        "city": "Kailua Kona",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520428",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "43",
        "icao": "PHKO",
        "direct_flights": "11",
        "carriers": "17"
    },
    {
        "code": "KOE",
        "lat": "-10.1739",
        "lon": "123.658",
        "name": "El Tari",
        "city": "Kupang",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12513464",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "335",
        "icao": "",
        "direct_flights": "11",
        "carriers": "4"
    },
    {
        "code": "KOJ",
        "lat": "31.8",
        "lon": "130.718",
        "name": "Kagoshima",
        "city": "Kirishima-shi",
        "state": "Kagoshima Prefecture",
        "country": "Japan",
        "woeid": "12513971",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "905",
        "icao": "RJFK",
        "direct_flights": "11",
        "carriers": "9"
    },
    {
        "code": "KSC",
        "lat": "48.6548",
        "lon": "21.2487",
        "name": "Kosice",
        "city": "Kosice",
        "state": "Kosicky",
        "country": "Slovakia",
        "woeid": "12514604",
        "tz": "EuropePrague",
        "phone": "+421 (0) 55 6832-123",
        "type": "Airports",
        "email": "sekretariat@airportkosice.sk",
        "url": "http:www.airportkosice.sk",
        "runway_length": "3100",
        "elev": "771",
        "icao": "LZKZ",
        "direct_flights": "11",
        "carriers": "10"
    },
    {
        "code": "MDL",
        "lat": "21.9392",
        "lon": "96.0914",
        "name": "Mandalay",
        "city": "Mandalay",
        "state": "Mandalay",
        "country": "Myanmar",
        "woeid": "12510922",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "14000",
        "elev": "300",
        "icao": "",
        "direct_flights": "11",
        "carriers": "4"
    },
    {
        "code": "MLI",
        "lat": "41.4539",
        "lon": "-90.5058",
        "name": "Quad City",
        "city": "Coal Valley",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12521505",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6507",
        "elev": "589",
        "icao": "KMLI",
        "direct_flights": "11",
        "carriers": "14"
    },
    {
        "code": "MOB",
        "lat": "30.6775",
        "lon": "-88.243",
        "name": "Mobile",
        "city": "Mobile",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12518752",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8527",
        "elev": "218",
        "icao": "KMOB",
        "direct_flights": "11",
        "carriers": "15"
    },
    {
        "code": "NAT",
        "lat": "-5.84694",
        "lon": "-35.2145",
        "name": "Augusto Severo",
        "city": "Natal",
        "state": "Rio Grande do Norte",
        "country": "Brazil",
        "woeid": "12511038",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7441",
        "elev": "171",
        "icao": "SBNT",
        "direct_flights": "11",
        "carriers": "10"
    },
    {
        "code": "NUX",
        "lat": "65.9549",
        "lon": "78.3955",
        "name": "Urengoy",
        "city": "Novy Urengoy",
        "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
        "country": "Russia",
        "woeid": "12517089",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "11",
        "carriers": "9"
    },
    {
        "code": "PNQ",
        "lat": "18.5817",
        "lon": "73.9206",
        "name": "Pune",
        "city": "Pune",
        "state": "Maharashtra",
        "country": "India",
        "woeid": "12513655",
        "tz": "AsiaKolkata",
        "phone": "91-020- 26691495",
        "type": "Airports",
        "email": "",
        "url": "http:www.aai.aeroaaimain.htm",
        "runway_length": "8700",
        "elev": "1934",
        "icao": "",
        "direct_flights": "11",
        "carriers": "8"
    },
    {
        "code": "PNS",
        "lat": "30.4761",
        "lon": "-87.1941",
        "name": "Pensacola",
        "city": "Pensacola",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521335",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7002",
        "elev": "121",
        "icao": "KPNS",
        "direct_flights": "11",
        "carriers": "15"
    },
    {
        "code": "SLY",
        "lat": "59.4615",
        "lon": "108.832",
        "name": "Russia",
        "city": "Salekhard",
        "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "11",
        "carriers": "2"
    },
    {
        "code": "SSG",
        "lat": "3.7472",
        "lon": "8.7083",
        "name": "Malabo",
        "city": "Malabo",
        "state": "Bioko Norte",
        "country": "Equatorial Guinea",
        "woeid": "12512730",
        "tz": "AfricaMalabo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9646",
        "elev": "79",
        "icao": "FGSL",
        "direct_flights": "11",
        "carriers": "11"
    },
    {
        "code": "TER",
        "lat": "38.765",
        "lon": "-27.0958",
        "name": "Lajes",
        "city": "Praia da Vitória",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515446",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LPLA",
        "direct_flights": "11",
        "carriers": "3"
    },
    {
        "code": "TRC",
        "lat": "25.5683",
        "lon": "-103.411",
        "name": "Torreon",
        "city": "Torreón",
        "state": "Coahuila de Zaragoza",
        "country": "Mexico",
        "woeid": "12514978",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "3688",
        "icao": "MMTC",
        "direct_flights": "11",
        "carriers": "10"
    },
    {
        "code": "VOZ",
        "lat": "51.8089",
        "lon": "39.2242",
        "name": "Voronezh-Chertovitskoye",
        "city": "Semiluki",
        "state": "Voronezhskaya Oblast",
        "country": "Russia",
        "woeid": "12517197",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "11",
        "carriers": "3"
    },
    {
        "code": "WUS",
        "lat": "34.668",
        "lon": "104.166",
        "name": "Wuyishan",
        "city": "Wuyishan",
        "state": "",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "11",
        "carriers": "5"
    },
    {
        "code": "XNN",
        "lat": "36.5678",
        "lon": "101.862",
        "name": "Xining",
        "city": "Xining",
        "state": "Qinghai",
        "country": "China",
        "woeid": "12512251",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "11",
        "carriers": "8"
    },
    {
        "code": "YEV",
        "lat": "68.3063",
        "lon": "-133.497",
        "name": "Inuvik",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511705",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "224",
        "icao": "CYEV",
        "direct_flights": "11",
        "carriers": "5"
    },
    {
        "code": "YQT",
        "lat": "48.3718",
        "lon": "-89.3118",
        "name": "Thunder Bay",
        "city": "Thunder Bay",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511875",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYQT",
        "direct_flights": "11",
        "carriers": "8"
    },
    {
        "code": "YRT",
        "lat": "62.8042",
        "lon": "-92.1026",
        "name": "Rankin Inlet",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524215",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "106",
        "icao": "CYRT",
        "direct_flights": "11",
        "carriers": "5"
    },
    {
        "code": "YYR",
        "lat": "53.3263",
        "lon": "-60.4106",
        "name": "Goose Bay",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511679",
        "tz": "AmericaGoose_Bay",
        "phone": "709-896-5445",
        "type": "Airports",
        "email": "",
        "url": "http:www.goosebayairport.comindex1.htm",
        "runway_length": "11050",
        "elev": "160",
        "icao": "CYYR",
        "direct_flights": "11",
        "carriers": "3"
    },
    {
        "code": "YYT",
        "lat": "47.6124",
        "lon": "-52.7425",
        "name": "St John's",
        "city": "St. John's",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511848",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.stjohnsairport.com",
        "runway_length": null,
        "elev": "462",
        "icao": "CYYT",
        "direct_flights": "11",
        "carriers": "9"
    },
    {
        "code": "ZNZ",
        "lat": "-6.2203",
        "lon": "39.2228",
        "name": "Zanzibar",
        "city": "Stone Town",
        "state": "Zanzibar UrbanWest",
        "country": "Tanzania",
        "woeid": "12518023",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "54",
        "icao": "HTZA",
        "direct_flights": "11",
        "carriers": "13"
    },
    {
        "code": "AQJ",
        "lat": "29.6119",
        "lon": "35.0178",
        "name": "Aqaba",
        "city": "Aqaba",
        "state": "Al `Aqabah",
        "country": "Jordan",
        "woeid": "12514051",
        "tz": "AsiaAmman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "174",
        "icao": "OJAQ",
        "direct_flights": "10",
        "carriers": "5"
    },
    {
        "code": "ATQ",
        "lat": "31.7131",
        "lon": "74.7955",
        "name": "Raja Sansi",
        "city": "Raja Sansi",
        "state": "Punjab",
        "country": "India",
        "woeid": "12513536",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9250",
        "elev": "754",
        "icao": "VIAR",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "BGR",
        "lat": "44.8086",
        "lon": "-68.8167",
        "name": "Bangor",
        "city": "Bangor",
        "state": "Maine",
        "country": "United States",
        "woeid": "12518734",
        "tz": "AmericaNew_York",
        "phone": "207-992-4600",
        "type": "Airports",
        "email": "",
        "url": "http:www.flybangor.com",
        "runway_length": "11439",
        "elev": "192",
        "icao": "KBGR",
        "direct_flights": "10",
        "carriers": "7"
    },
    {
        "code": "BND",
        "lat": "27.225",
        "lon": "56.3783",
        "name": "Bandar Abbass",
        "city": "Bandar Abbas",
        "state": "Hormozgan",
        "country": "Iran",
        "woeid": "12513703",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12020",
        "elev": "23",
        "icao": "",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "BUS",
        "lat": "50.0662",
        "lon": "2.96519",
        "name": "Batumi",
        "city": "Batumi",
        "state": "Ajaria",
        "country": "Georgia",
        "woeid": "1962543",
        "tz": "AsiaTbilisi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UGSB",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "BZN",
        "lat": "45.7722",
        "lon": "-111.157",
        "name": "Gallatin Field",
        "city": "Bozeman",
        "state": "Montana",
        "country": "United States",
        "woeid": "12519854",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9002",
        "elev": "4458",
        "icao": "KBZN",
        "direct_flights": "10",
        "carriers": "8"
    },
    {
        "code": "CGP",
        "lat": "22.2517",
        "lon": "91.815",
        "name": "Chittagong",
        "city": "Chittagong",
        "state": "Chittagong",
        "country": "Bangladesh",
        "woeid": "12510886",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "12",
        "icao": "VGEG",
        "direct_flights": "10",
        "carriers": "14"
    },
    {
        "code": "CJS",
        "lat": "31.6361",
        "lon": "-106.428",
        "name": "Ciudad Juarez",
        "city": "Ciudad Juarez",
        "state": "Chihuahua",
        "country": "Mexico",
        "woeid": "12514848",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8859",
        "elev": "3842",
        "icao": "MMCS",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "CRW",
        "lat": "38.3697",
        "lon": "-81.5951",
        "name": "Yeager",
        "city": "Charleston",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12522530",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6302",
        "elev": "982",
        "icao": "KCRW",
        "direct_flights": "10",
        "carriers": "9"
    },
    {
        "code": "CUU",
        "lat": "28.7028",
        "lon": "-105.964",
        "name": "General R F Villalobos",
        "city": "Chihuahua",
        "state": "Chihuahua",
        "country": "Mexico",
        "woeid": "12514884",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8531",
        "elev": "4462",
        "icao": "MMCU",
        "direct_flights": "10",
        "carriers": "12"
    },
    {
        "code": "DLG",
        "lat": "59.0445",
        "lon": "-158.513",
        "name": "Dillingham Municipal",
        "city": "Dillingham",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519494",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6404",
        "elev": "85",
        "icao": "PADL",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "DNK",
        "lat": "48.368",
        "lon": "35.0942",
        "name": "Voloskoye",
        "city": "Dnipropetrovs'k",
        "state": "Dnipropetrovs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518475",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UKDD",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "DUR",
        "lat": "-29.9736",
        "lon": "30.9472",
        "name": "Louis Botha",
        "city": "Durban",
        "state": "Kwazulu Natal",
        "country": "South Africa",
        "woeid": "12517440",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8015",
        "elev": "25",
        "icao": "FADN",
        "direct_flights": "10",
        "carriers": "16"
    },
    {
        "code": "ECN",
        "lat": "35.1597",
        "lon": "33.4897",
        "name": "Ercan",
        "city": "Nicosia",
        "state": "Cyprus, TRNC",
        "country": "Cyprus",
        "woeid": "12512497",
        "tz": "AsiaNicosia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9050",
        "elev": "402",
        "icao": "LCEN",
        "direct_flights": "10",
        "carriers": "3"
    },
    {
        "code": "FDF",
        "lat": "14.5897",
        "lon": "-61.0092",
        "name": "Le Lamentin",
        "city": "Ducos",
        "state": "Fort-de-France",
        "country": "Martinique",
        "woeid": "12514712",
        "tz": "AmericaMartinique",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "16",
        "icao": "TFFF",
        "direct_flights": "10",
        "carriers": "9"
    },
    {
        "code": "GMP",
        "lat": "37.5563",
        "lon": "126.793",
        "name": "Gimpo",
        "city": "Seoul",
        "state": "Seoul",
        "country": "South Korea",
        "woeid": "23388324",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "RKSS",
        "direct_flights": "10",
        "carriers": "9"
    },
    {
        "code": "GOJ",
        "lat": "56.2153",
        "lon": "43.783",
        "name": "Strigino",
        "city": "Dzerzinsk",
        "state": "Nizhegorodskaya Oblast",
        "country": "Russia",
        "woeid": "12516946",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "GTF",
        "lat": "47.482",
        "lon": "-111.356",
        "name": "Great Falls",
        "city": "Great Falls",
        "state": "Montana",
        "country": "United States",
        "woeid": "12519974",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10502",
        "elev": "3674",
        "icao": "KGTF",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "ISP",
        "lat": "40.7891",
        "lon": "-73.0984",
        "name": "Long Island Macarthur",
        "city": "Ronkonkoma",
        "state": "New York",
        "country": "United States",
        "woeid": "12520697",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5999",
        "elev": "99",
        "icao": "KISP",
        "direct_flights": "10",
        "carriers": "3"
    },
    {
        "code": "KLU",
        "lat": "46.6428",
        "lon": "14.3429",
        "name": "Klagenfurt",
        "city": "Celovec",
        "state": "Carinthia",
        "country": "Austria",
        "woeid": "12510824",
        "tz": "EuropeVienna",
        "phone": "+43(0)463415000",
        "type": "Airports",
        "email": "",
        "url": "http:www.klagenfurt-airport.com",
        "runway_length": "8924",
        "elev": "1470",
        "icao": "LOWK",
        "direct_flights": "10",
        "carriers": "11"
    },
    {
        "code": "KMQ",
        "lat": "36.394",
        "lon": "136.408",
        "name": "Komatsu",
        "city": "Komatsu-shi",
        "state": "Ishikawa Prefecture",
        "country": "Japan",
        "woeid": "12513980",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8860",
        "elev": "18",
        "icao": "",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "LBB",
        "lat": "33.6566",
        "lon": "-101.821",
        "name": "Lubbock",
        "city": "Lubbock",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520718",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flylia.com",
        "runway_length": "11500",
        "elev": "3281",
        "icao": "KLBB",
        "direct_flights": "10",
        "carriers": "11"
    },
    {
        "code": "LIG",
        "lat": "45.8615",
        "lon": "1.1779",
        "name": "Bellegarde",
        "city": "Limoges",
        "state": "Limousin",
        "country": "France",
        "woeid": "12512853",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "1299",
        "icao": "LFBL",
        "direct_flights": "10",
        "carriers": "5"
    },
    {
        "code": "LYI",
        "lat": "35.0631",
        "lon": "118.343",
        "name": "Linyi",
        "city": "Linyi",
        "state": "Shandong",
        "country": "China",
        "woeid": "2168342",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "MES",
        "lat": "3.5606",
        "lon": "98.6708",
        "name": "Polonia",
        "city": "Medan",
        "state": "Sumatera Utara",
        "country": "Indonesia",
        "woeid": "12513497",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9715",
        "elev": "89",
        "icao": "WIMM",
        "direct_flights": "10",
        "carriers": "14"
    },
    {
        "code": "MFR",
        "lat": "42.3691",
        "lon": "-122.874",
        "name": "Rogue Valley International-Medford",
        "city": "Central Point",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12520884",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8800",
        "elev": "1331",
        "icao": "KMFR",
        "direct_flights": "10",
        "carriers": "13"
    },
    {
        "code": "MGA",
        "lat": "12.1411",
        "lon": "-86.1686",
        "name": "Augusto Cesar Sandino",
        "city": "Tipitapa",
        "state": "Managua",
        "country": "Nicaragua",
        "woeid": "12515143",
        "tz": "AmericaManagua",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.eaai.com.nienglishindex.shtml",
        "runway_length": "7999",
        "elev": "194",
        "icao": "MNMG",
        "direct_flights": "10",
        "carriers": "15"
    },
    {
        "code": "MJN",
        "lat": "-15.6658",
        "lon": "46.3531",
        "name": "Mahajanga Amborovy",
        "city": "Mahajanga",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "12514698",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "85",
        "icao": "",
        "direct_flights": "10",
        "carriers": "3"
    },
    {
        "code": "MJT",
        "lat": "39.0606",
        "lon": "26.5981",
        "name": "Mitilini",
        "city": "Mitilini",
        "state": "Voreio Aigaio",
        "country": "Greece",
        "woeid": "12513313",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6476",
        "elev": "57",
        "icao": "",
        "direct_flights": "10",
        "carriers": "4"
    },
    {
        "code": "MYJ",
        "lat": "33.8276",
        "lon": "132.7",
        "name": "Matsuyama",
        "city": "Matsuyama-shi",
        "state": "Ehime Prefecture",
        "country": "Japan",
        "woeid": "12513985",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "21",
        "icao": "KMYJ",
        "direct_flights": "10",
        "carriers": "8"
    },
    {
        "code": "NAG",
        "lat": "21.0917",
        "lon": "79.0486",
        "name": "Sonegaon",
        "city": "Nagpur",
        "state": "Maharashtra",
        "country": "India",
        "woeid": "12513637",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "1033",
        "icao": "",
        "direct_flights": "10",
        "carriers": "9"
    },
    {
        "code": "NKC",
        "lat": "18.0975",
        "lon": "-15.9478",
        "name": "Nouakchott",
        "city": "Nouakchott",
        "state": "Nouakchott",
        "country": "Mauritania",
        "woeid": "12514806",
        "tz": "AfricaNouakchott",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "7",
        "icao": "GQNN",
        "direct_flights": "10",
        "carriers": "8"
    },
    {
        "code": "NOU",
        "lat": "-22.0258",
        "lon": "166.21",
        "name": "La Tontouta",
        "city": "",
        "state": "Sud",
        "country": "New Caledonia",
        "woeid": "12515035",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10663",
        "elev": "52",
        "icao": "NWWW",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "NQY",
        "lat": "50.4364",
        "lon": "-5.01373",
        "name": "St Mawgan",
        "city": "Newquay",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518154",
        "tz": "EuropeLondon",
        "phone": "01637 861053",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8984",
        "elev": "390",
        "icao": "EGDG",
        "direct_flights": "10",
        "carriers": "7"
    },
    {
        "code": "PVK",
        "lat": "38.9261",
        "lon": "20.7667",
        "name": "Preveza",
        "city": "Paliambela",
        "state": "Dytiki Ellada",
        "country": "Greece",
        "woeid": "12513319",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9810",
        "elev": "13",
        "icao": "LGPZ",
        "direct_flights": "10",
        "carriers": "4"
    },
    {
        "code": "SHV",
        "lat": "32.4549",
        "lon": "-93.8285",
        "name": "Shreveport",
        "city": "Shreveport",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12521845",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8351",
        "elev": "258",
        "icao": "KSHV",
        "direct_flights": "10",
        "carriers": "13"
    },
    {
        "code": "SKB",
        "lat": "17.3128",
        "lon": "-62.7178",
        "name": "Golden Rock",
        "city": "Basseterre",
        "state": "Saint George Basseterre",
        "country": "Saint Kitts and Nevis",
        "woeid": "12517381",
        "tz": "AmericaSt_Kitts",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8002",
        "elev": "170",
        "icao": "TKPK",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "SLP",
        "lat": "22.2539",
        "lon": "-100.927",
        "name": "San Luis Potosi",
        "city": "S. Luis Potosi",
        "state": "San Luis Potosi",
        "country": "Mexico",
        "woeid": "12514959",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "6070",
        "icao": "",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "SPN",
        "lat": "15.1333",
        "lon": "145.717",
        "name": "Saipan",
        "city": "Saipan",
        "state": "Northern Mariana Islands",
        "country": "United States",
        "woeid": "12523226",
        "tz": "PacificSaipan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8700",
        "elev": "215",
        "icao": "PGSN",
        "direct_flights": "10",
        "carriers": "6"
    },
    {
        "code": "TDD",
        "lat": "-14.8156",
        "lon": "-64.9161",
        "name": "Jorge Henrich Arauz",
        "city": "Trinidad",
        "state": "El Beni",
        "country": "Bolivia",
        "woeid": "12510907",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "10",
        "carriers": "1"
    },
    {
        "code": "TLH",
        "lat": "30.3964",
        "lon": "-84.3503",
        "name": "Tallahassee",
        "city": "Tallahassee",
        "state": "Florida",
        "country": "United States",
        "woeid": "12522075",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.talgov.comcitytlhaviation",
        "runway_length": "8000",
        "elev": "81",
        "icao": "KTLH",
        "direct_flights": "10",
        "carriers": "10"
    },
    {
        "code": "TSV",
        "lat": "-19.2542",
        "lon": "146.764",
        "name": "Townsville",
        "city": "Townsville",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510800",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.townsvilleairport.com.au",
        "runway_length": "2436",
        "elev": "18",
        "icao": "YBTL",
        "direct_flights": "10",
        "carriers": "5"
    },
    {
        "code": "TUU",
        "lat": "28.3722",
        "lon": "36.6253",
        "name": "Tabuk",
        "city": "Tabuk",
        "state": "Tabuk",
        "country": "Saudi Arabia",
        "woeid": "12517371",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTUU",
        "direct_flights": "10",
        "carriers": "1"
    },
    {
        "code": "VGO",
        "lat": "42.2258",
        "lon": "-8.63269",
        "name": "Vigo",
        "city": "Vigo",
        "state": "Galicia",
        "country": "Spain",
        "woeid": "12517579",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "866",
        "icao": "LEVX",
        "direct_flights": "10",
        "carriers": "17"
    },
    {
        "code": "YIH",
        "lat": "30.9007",
        "lon": "111.06",
        "name": "China",
        "city": "Yichang",
        "state": "Hubei",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "10",
        "carriers": "8"
    },
    {
        "code": "ZAD",
        "lat": "44.0978",
        "lon": "15.3567",
        "name": "Zara",
        "city": "Zara",
        "state": "Zadarsko-Kninska",
        "country": "Croatia",
        "woeid": "12513375",
        "tz": "EuropeZagreb",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.zadar-airport.hreng",
        "runway_length": "8202",
        "elev": "269",
        "icao": "LDZD",
        "direct_flights": "10",
        "carriers": "5"
    },
    {
        "code": "ZUH",
        "lat": "22.277",
        "lon": "113.557",
        "name": "Zhuhai",
        "city": "Zhuhai",
        "state": "Guangdong",
        "country": "China",
        "woeid": "2161856",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "10",
        "carriers": "7"
    },
    {
        "code": "AAR",
        "lat": "56.3088",
        "lon": "10.6154",
        "name": "Tirstrup",
        "city": "Kolind",
        "state": "Midtjylland",
        "country": "Denmark",
        "woeid": "12512604",
        "tz": "EuropeCopenhagen",
        "phone": "+45 8775 7000",
        "type": "Airports",
        "email": "",
        "url": "http:www.aar.dk",
        "runway_length": "9127",
        "elev": "81",
        "icao": "EKAH",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "ABV",
        "lat": "9.0056",
        "lon": "7.2661",
        "name": "Abuja",
        "city": "Gwagwa",
        "state": "Abuja Capital Territory",
        "country": "Nigeria",
        "woeid": "12515056",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11808",
        "elev": "1122",
        "icao": "DNAA",
        "direct_flights": "9",
        "carriers": "12"
    },
    {
        "code": "ACK",
        "lat": "41.2568",
        "lon": "-70.0649",
        "name": "Nantucket Memorial",
        "city": "Nantucket",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12521076",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6303",
        "elev": "48",
        "icao": "KACK",
        "direct_flights": "9",
        "carriers": "6"
    },
    {
        "code": "ASM",
        "lat": "15.2906",
        "lon": "38.9103",
        "name": "Yohannes Iv",
        "city": "Asmara",
        "state": "Maekel",
        "country": "Eritrea",
        "woeid": "12512750",
        "tz": "AfricaAsmara",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "7661",
        "icao": "HHAS",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "ASU",
        "lat": "-25.2397",
        "lon": "-57.5189",
        "name": "Silvio Pettirossi",
        "city": "Colonia Mariano Roque Alonso",
        "state": "Central",
        "country": "Paraguay",
        "woeid": "12515187",
        "tz": "AmericaAsuncion",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "292",
        "icao": "SGAS",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "BJV",
        "lat": "37.0413",
        "lon": "27.4362",
        "name": "Milas",
        "city": "Bodrum",
        "state": "Muğla",
        "country": "Turkey",
        "woeid": "12523053",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "BON",
        "lat": "12.1392",
        "lon": "-68.2697",
        "name": "Flamingo",
        "city": "Kralendijk",
        "state": "Bonaire",
        "country": "Netherlands Antilles",
        "woeid": "12515137",
        "tz": "AmericaCuracao",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9449",
        "elev": "20",
        "icao": "TNCB",
        "direct_flights": "9",
        "carriers": "11"
    },
    {
        "code": "BZG",
        "lat": "53.0918",
        "lon": "17.9821",
        "name": "Szwederowo",
        "city": "Białe Błota",
        "state": "Kujawsko-Pomorskie",
        "country": "Poland",
        "woeid": "12523956",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.plb.pl",
        "runway_length": null,
        "elev": null,
        "icao": "EPBD",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "CGR",
        "lat": "-20.475",
        "lon": "-54.6736",
        "name": "Campo Grande",
        "city": "Campo Grande",
        "state": "Mato Grosso do Sul",
        "country": "Brazil",
        "woeid": "12511071",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "1833",
        "icao": "SBCG",
        "direct_flights": "9",
        "carriers": "4"
    },
    {
        "code": "CMF",
        "lat": "45.6375",
        "lon": "5.88291",
        "name": "Aix les Bains",
        "city": "La Motte",
        "state": "Rhone-Alpes",
        "country": "France",
        "woeid": "12512839",
        "tz": "EuropeParis",
        "phone": "+33 4 79 54 43 54",
        "type": "Airports",
        "email": "",
        "url": "http:www.chambery.aeroport.frrubrique.php3?id_rubrique=55",
        "runway_length": "5807",
        "elev": "778",
        "icao": "LFLB",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "CRK",
        "lat": "15.1806",
        "lon": "120.557",
        "name": "Clark Field",
        "city": "Mabalacat",
        "state": "Central Luzon",
        "country": "Philippines",
        "woeid": "12515607",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "478",
        "icao": "RPLC",
        "direct_flights": "9",
        "carriers": "9"
    },
    {
        "code": "CVT",
        "lat": "52.367",
        "lon": "-1.4895",
        "name": "Coventry",
        "city": "Coventry",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518067",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 24 76 308 600",
        "type": "Airports",
        "email": "",
        "url": "http:www.coventryairport.co.uk",
        "runway_length": "6588",
        "elev": "269",
        "icao": "EGBE",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "DAD",
        "lat": "16.05",
        "lon": "108.2",
        "name": "Da Nang",
        "city": "Tourane",
        "state": "Da Nang",
        "country": "Vietnam",
        "woeid": "12523093",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "30",
        "icao": "VVDN",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "EBL",
        "lat": "36.1797",
        "lon": "44.016",
        "name": "Erbil",
        "city": "Erbil",
        "state": "Arbil",
        "country": "Iraq",
        "woeid": "1979432",
        "tz": "AsiaBaghdad",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ORER",
        "direct_flights": "9",
        "carriers": "6"
    },
    {
        "code": "FPO",
        "lat": "26.5561",
        "lon": "-78.6986",
        "name": "Freeport",
        "city": "Freeport City",
        "state": "City of Freeport",
        "country": "Bahamas",
        "woeid": "12510867",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MYGF",
        "direct_flights": "9",
        "carriers": "9"
    },
    {
        "code": "FSC",
        "lat": "41.5022",
        "lon": "9.0968",
        "name": "Figari",
        "city": "Figari",
        "state": "Corsica",
        "country": "France",
        "woeid": "12512979",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8136",
        "elev": "85",
        "icao": "LFKF",
        "direct_flights": "9",
        "carriers": "3"
    },
    {
        "code": "FSD",
        "lat": "43.5813",
        "lon": "-96.7317",
        "name": "Sioux Falls",
        "city": "Sioux Falls",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12520371",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8999",
        "elev": "1429",
        "icao": "KFSD",
        "direct_flights": "9",
        "carriers": "11"
    },
    {
        "code": "GAL",
        "lat": "64.7404",
        "lon": "-156.931",
        "name": "Galena",
        "city": "Galena",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519851",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7254",
        "elev": "152",
        "icao": "PAGA",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "GPA",
        "lat": "38.1519",
        "lon": "21.4272",
        "name": "Araxos",
        "city": "Lakkopetra",
        "state": "Dytiki Ellada",
        "country": "Greece",
        "woeid": "12513285",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LGRX",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "GPT",
        "lat": "30.4133",
        "lon": "-89.072",
        "name": "Gulfport Biloxi",
        "city": "Gulfport",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12520037",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9002",
        "elev": "28",
        "icao": "KGPT",
        "direct_flights": "9",
        "carriers": "11"
    },
    {
        "code": "HAH",
        "lat": "-11.5319",
        "lon": "43.2611",
        "name": "Moroni Hahaia",
        "city": "Hahaia",
        "state": "Grande Comore",
        "country": "Comoros",
        "woeid": "12512357",
        "tz": "IndianComoro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9514",
        "elev": "89",
        "icao": "FMCH",
        "direct_flights": "9",
        "carriers": "6"
    },
    {
        "code": "HDN",
        "lat": "40.485",
        "lon": "-107.219",
        "name": "Yampa Valley",
        "city": "Hayden",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12522528",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6999",
        "elev": "6597",
        "icao": "KHDN",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "HLZ",
        "lat": "-37.8632",
        "lon": "175.328",
        "name": "Hamilton",
        "city": "Hamilton",
        "state": "Waikato",
        "country": "New Zealand",
        "woeid": "12515155",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "172",
        "icao": "NZHN",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "HRL",
        "lat": "26.2216",
        "lon": "-97.663",
        "name": "Grande Valley",
        "city": "Harlingen",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519957",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8300",
        "elev": "36",
        "icao": "KHRL",
        "direct_flights": "9",
        "carriers": "10"
    },
    {
        "code": "IDR",
        "lat": "22.7197",
        "lon": "75.807",
        "name": "Devi Ahilyabai Holkar",
        "city": "Indore",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513600",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7480",
        "elev": "1850",
        "icao": "VAID",
        "direct_flights": "9",
        "carriers": "4"
    },
    {
        "code": "IXE",
        "lat": "12.9812",
        "lon": "74.8992",
        "name": "Mangalore",
        "city": "Mulur",
        "state": "Karnataka",
        "country": "India",
        "woeid": "12513631",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:mangaloreairport.com",
        "runway_length": "5800",
        "elev": "336",
        "icao": "VOML",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "JRO",
        "lat": "-3.4278",
        "lon": "37.0714",
        "name": "Kilimanjaro",
        "city": "Sanya",
        "state": "Kilimanjaro",
        "country": "Tanzania",
        "woeid": "12518016",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11814",
        "elev": "2932",
        "icao": "HTKJ",
        "direct_flights": "9",
        "carriers": "13"
    },
    {
        "code": "LAP",
        "lat": "24.0722",
        "lon": "-110.362",
        "name": "General Manuel Marquez de Leon International Air",
        "city": "La Paz",
        "state": "Baja California Sur",
        "country": "Mexico",
        "woeid": "12514883",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "69",
        "icao": "MMLP",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "LCJ",
        "lat": "51.7217",
        "lon": "19.3989",
        "name": "Lodz Lublinek",
        "city": "Lodz",
        "state": "Woj. Lodzkie",
        "country": "Poland",
        "woeid": "505120",
        "tz": "EuropeWarsaw",
        "phone": "+48 42 683 52 00",
        "type": "Airports",
        "email": "handling@airport.lodz.pl",
        "url": "http:www.airport.lodz.pl",
        "runway_length": "4701",
        "elev": "606",
        "icao": "EPLL",
        "direct_flights": "9",
        "carriers": "3"
    },
    {
        "code": "LPB",
        "lat": "-16.5094",
        "lon": "-68.1906",
        "name": "El Alto",
        "city": "La Paz",
        "state": "La Paz",
        "country": "Bolivia",
        "woeid": "12510906",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13123",
        "elev": "13313",
        "icao": "SLLP",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "MAG",
        "lat": "-5.21",
        "lon": "145.787",
        "name": "Madang",
        "city": "Madang",
        "state": "Madang",
        "country": "Papua New Guinea",
        "woeid": "12515472",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5150",
        "elev": "20",
        "icao": "",
        "direct_flights": "9",
        "carriers": "1"
    },
    {
        "code": "NIM",
        "lat": "13.4817",
        "lon": "2.1703",
        "name": "Niamey",
        "city": "Niamey",
        "state": "Niamey",
        "country": "Niger",
        "woeid": "12515047",
        "tz": "AfricaNiamey",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "728",
        "icao": "DRRN",
        "direct_flights": "9",
        "carriers": "13"
    },
    {
        "code": "NKM",
        "lat": "35.2527",
        "lon": "136.924",
        "name": "Nagoya",
        "city": "Toyoyama-cho",
        "state": "Aichi Prefecture",
        "country": "Japan",
        "woeid": "23388304",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "http:www.nagoya-airport-bldg.co.jp",
        "runway_length": "8990",
        "elev": "46",
        "icao": "RJNA",
        "direct_flights": "9",
        "carriers": "1"
    },
    {
        "code": "NTL",
        "lat": "-32.7985",
        "lon": "151.837",
        "name": "Williamtown",
        "city": "Ferodale",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510813",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "31",
        "icao": "YWLM",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "NYM",
        "lat": "65.6179",
        "lon": "72.6856",
        "name": "Nadym",
        "city": "Nadym",
        "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
        "country": "Russia",
        "woeid": "12516467",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "PIA",
        "lat": "40.6663",
        "lon": "-89.6909",
        "name": "Greater Peoria",
        "city": "Peoria",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12519982",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "660",
        "icao": "KPIA",
        "direct_flights": "9",
        "carriers": "14"
    },
    {
        "code": "PKC",
        "lat": "53.1663",
        "lon": "158.447",
        "name": "Petropavlovsk Yelizovo",
        "city": "Elizovo",
        "state": "Kamchatskaya Oblast",
        "country": "Russia",
        "woeid": "12516681",
        "tz": "AsiaKamchatka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11155",
        "elev": "131",
        "icao": "UHPP",
        "direct_flights": "9",
        "carriers": "9"
    },
    {
        "code": "PNR",
        "lat": "-4.8128",
        "lon": "11.8856",
        "name": "Pointe Noire",
        "city": "Pointe-Noire",
        "state": "Kouilou",
        "country": "Congo",
        "woeid": "12511949",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "56",
        "icao": "FCPP",
        "direct_flights": "9",
        "carriers": "12"
    },
    {
        "code": "RAP",
        "lat": "44.037",
        "lon": "-103.06",
        "name": "Rapid City",
        "city": "Rapid City",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12521530",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8701",
        "elev": "3202",
        "icao": "KRAP",
        "direct_flights": "9",
        "carriers": "11"
    },
    {
        "code": "RAR",
        "lat": "-21.2",
        "lon": "-159.8",
        "name": "Rarotonga",
        "city": "Avarua",
        "state": "Rarotonga",
        "country": "Cook Islands",
        "woeid": "12523208",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7638",
        "elev": "22",
        "icao": "NCRG",
        "direct_flights": "9",
        "carriers": "6"
    },
    {
        "code": "RKV",
        "lat": "64.1311",
        "lon": "-21.9389",
        "name": "Reykjavik",
        "city": "Reykjavik",
        "state": "Reykjavik",
        "country": "Iceland",
        "woeid": "12513446",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "BIRK",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "RMF",
        "lat": "25.07",
        "lon": "34.9",
        "name": "Marsa Alam International",
        "city": "Marsa Alam",
        "state": "Al Bahr al Ahmar",
        "country": "Egypt",
        "woeid": "1525119",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:marsa-alam-airport.com",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "SBZ",
        "lat": "45.7882",
        "lon": "24.0943",
        "name": "Sibiu",
        "city": "Sibiu",
        "state": "Sibiu",
        "country": "Romania",
        "woeid": "12515589",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1474",
        "icao": "LRSB",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "SLU",
        "lat": "14.0192",
        "lon": "-60.9928",
        "name": "Vigie",
        "city": "St Lucia",
        "state": "Castries",
        "country": "St. Lucia",
        "woeid": "12517596",
        "tz": "AmericaSt_Lucia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5700",
        "elev": "8",
        "icao": "TLPC",
        "direct_flights": "9",
        "carriers": "3"
    },
    {
        "code": "SON",
        "lat": "-15.5094",
        "lon": "167.214",
        "name": "Santo Pekoa",
        "city": "Luganville",
        "state": "Sanma",
        "country": "Vanuatu",
        "woeid": "12515053",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "184",
        "icao": "",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "STX",
        "lat": "17.6988",
        "lon": "-64.7977",
        "name": "Henry E Rohlson",
        "city": "Frederiksted",
        "state": "US Virgin Islands",
        "country": "United States",
        "woeid": "12522971",
        "tz": "AmericaSt_Thomas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7612",
        "elev": "61",
        "icao": "TISX",
        "direct_flights": "9",
        "carriers": "10"
    },
    {
        "code": "SVD",
        "lat": "13.1422",
        "lon": "-61.2111",
        "name": "E T Joshua",
        "city": "Kingstown",
        "state": "Saint George",
        "country": "Saint Vincent and the Grenadines",
        "woeid": "12522749",
        "tz": "AmericaSt_Vincent",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "66",
        "icao": "TVSV",
        "direct_flights": "9",
        "carriers": "3"
    },
    {
        "code": "SWF",
        "lat": "41.4869",
        "lon": "-74.0974",
        "name": "Stewart",
        "city": "New Windsor",
        "state": "New York",
        "country": "United States",
        "woeid": "12522012",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11818",
        "elev": "491",
        "icao": "KSWF",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "TAE",
        "lat": "35.9007",
        "lon": "128.641",
        "name": "Daegu",
        "city": "Daegu",
        "state": "Daegu",
        "country": "South Korea",
        "woeid": "12514232",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "TMP",
        "lat": "61.4166",
        "lon": "23.615",
        "name": "Tampere Pirkkala",
        "city": "Pitkäniemi",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12512800",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2700",
        "elev": "120",
        "icao": "EFTP",
        "direct_flights": "9",
        "carriers": "8"
    },
    {
        "code": "TRI",
        "lat": "36.4805",
        "lon": "-82.4087",
        "name": "Tri-Cities",
        "city": "Blountville",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12522192",
        "tz": "AmericaNew_York",
        "phone": "423-325-6000",
        "type": "Airports",
        "email": "",
        "url": "http:www.triflight.com",
        "runway_length": null,
        "elev": null,
        "icao": "KTRI",
        "direct_flights": "9",
        "carriers": "12"
    },
    {
        "code": "USM",
        "lat": "9.55",
        "lon": "100.067",
        "name": "Koh Samui",
        "city": "Ko Samui",
        "state": "Surat Thani",
        "country": "Thailand",
        "woeid": "12517782",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4724",
        "elev": "44",
        "icao": "",
        "direct_flights": "9",
        "carriers": "4"
    },
    {
        "code": "VXO",
        "lat": "56.9242",
        "lon": "14.7318",
        "name": "Kronoberg",
        "city": "Vaxjo",
        "state": "Kronoberg",
        "country": "Sweden",
        "woeid": "12517654",
        "tz": "EuropeStockholm",
        "phone": "+46(0)470758500",
        "type": "Airports",
        "email": "",
        "url": "http:www.smalandairport.se",
        "runway_length": "7546",
        "elev": "610",
        "icao": "ESMX",
        "direct_flights": "9",
        "carriers": "5"
    },
    {
        "code": "WDH",
        "lat": "-22.4792",
        "lon": "17.4722",
        "name": "Windhoek",
        "city": "",
        "state": "Khomas",
        "country": "Namibia",
        "woeid": "12522994",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "15010",
        "elev": "5640",
        "icao": "FYWH",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "WIL",
        "lat": "-1.31888",
        "lon": "36.8157",
        "name": "Nairobi Wilson",
        "city": "Nairobi",
        "state": "Nairobi Area",
        "country": "Kenya",
        "woeid": "12514078",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "5536",
        "icao": "HKNW",
        "direct_flights": "9",
        "carriers": "2"
    },
    {
        "code": "YLW",
        "lat": "49.9509",
        "lon": "-119.382",
        "name": "Kelowna",
        "city": "Kelowna",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511712",
        "tz": "AmericaVancouver",
        "phone": "(250) 765-5125",
        "type": "Airports",
        "email": "",
        "url": "www.ylw.aero",
        "runway_length": "5350",
        "elev": "1128",
        "icao": "CYLW",
        "direct_flights": "9",
        "carriers": "7"
    },
    {
        "code": "YYJ",
        "lat": "48.6402",
        "lon": "-123.43",
        "name": "Victoria",
        "city": "Sidney",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511899",
        "tz": "AmericaVancouver",
        "phone": "(250) 953-7500",
        "type": "Airports",
        "email": "",
        "url": "http:www.victoriaairport.com",
        "runway_length": "7000",
        "elev": "63",
        "icao": "CYYJ",
        "direct_flights": "9",
        "carriers": "11"
    },
    {
        "code": "PSR",
        "lat": "42.4337",
        "lon": "14.1846",
        "name": "Pescara",
        "city": "Pescara",
        "state": "Abruzzi",
        "country": "Italy",
        "woeid": "12513848",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7808",
        "elev": "48",
        "icao": "LIBP",
        "direct_flights": "8",
        "carriers": "8",
        "bookingId": "-124336"
    },
    {
        "code": "REG",
        "lat": "38.0731",
        "lon": "15.6498",
        "name": "Reggio Calabria",
        "city": "Reggio Calabria",
        "state": "Calabria",
        "country": "Italy",
        "woeid": "22315780",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.sogas.it",
        "runway_length": "6050",
        "elev": "85",
        "icao": "",
        "direct_flights": "8",
        "carriers": "8",
        "bookingId": "-126191"
    },
    {
        "code": "AGU",
        "lat": "21.7047",
        "lon": "-102.317",
        "name": "Aguascalientes",
        "city": "Aguascalientes",
        "state": "Aguascalientes",
        "country": "Mexico",
        "woeid": "12514821",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "6112",
        "icao": "MMAS",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "ASP",
        "lat": "-23.802",
        "lon": "133.892",
        "name": "Alice Springs",
        "city": "Alice Springs",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510584",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "1789",
        "icao": "YBAS",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "ATW",
        "lat": "44.2603",
        "lon": "-88.5111",
        "name": "Outagamie County",
        "city": "Appleton",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12521262",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.atwairport.com",
        "runway_length": "7001",
        "elev": "918",
        "icao": "KATW",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "AVP",
        "lat": "41.3354",
        "lon": "-75.7294",
        "name": "Wilkes Barre Scranton",
        "city": "Pittston",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12522441",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7501",
        "elev": "957",
        "icao": "KAVP",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "AZA",
        "lat": "33.3078",
        "lon": "-111.656",
        "name": "Phoenix-Mesa Gateway",
        "city": "Phoenix",
        "state": "Arizona",
        "country": "United States",
        "woeid": "2449808",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flywga.org",
        "runway_length": "10",
        "elev": "1",
        "icao": "KIWA",
        "direct_flights": "8",
        "carriers": "1"
    },
    {
        "code": "AZN",
        "lat": "40.7324",
        "lon": "72.2931",
        "name": "Andizhan",
        "city": "Andijon",
        "state": "Andijon",
        "country": "Uzbekistan",
        "woeid": "12522576",
        "tz": "AsiaTashkent",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UTKA",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "BAV",
        "lat": "40.5679",
        "lon": "109.998",
        "name": "Baotou",
        "city": "Baotou",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "12511987",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "BLI",
        "lat": "48.7955",
        "lon": "-122.532",
        "name": "Bellingham",
        "city": "Bellingham",
        "state": "Washington",
        "country": "United States",
        "woeid": "12518790",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "158",
        "icao": "KBLI",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "BME",
        "lat": "-17.9464",
        "lon": "122.233",
        "name": "Broome",
        "city": "Broome",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510620",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5010",
        "elev": "56",
        "icao": "",
        "direct_flights": "8",
        "carriers": "4"
    },
    {
        "code": "BPN",
        "lat": "-1.2681",
        "lon": "116.897",
        "name": "Sepinggan",
        "city": "Balikpapan",
        "state": "Kalimantan Timur",
        "country": "Indonesia",
        "woeid": "12513506",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "10",
        "icao": "WALL",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "BQS",
        "lat": "50.4243",
        "lon": "127.409",
        "name": "Blagoveshchensk Northwest",
        "city": "Blagoveshchensk",
        "state": "Amurskaya Oblast",
        "country": "Russia",
        "woeid": "12515824",
        "tz": "AsiaYakutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "BTH",
        "lat": "1.1306",
        "lon": "104.134",
        "name": "Hang Nadim",
        "city": "Batam",
        "state": "Riau",
        "country": "Indonesia",
        "woeid": "12513469",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "68",
        "icao": "",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "CBR",
        "lat": "-35.3069",
        "lon": "149.195",
        "name": "Canberra",
        "city": "Canberra",
        "state": "Australian Capital Territory",
        "country": "Australia",
        "woeid": "12510625",
        "tz": "AustraliaSydney",
        "phone": "+61 2 6275 2222",
        "type": "Airports",
        "email": "",
        "url": "http:www.canberraairport.com.au",
        "runway_length": "8800",
        "elev": "1888",
        "icao": "YSCB",
        "direct_flights": "8",
        "carriers": "9"
    },
    {
        "code": "CJB",
        "lat": "11.0233",
        "lon": "77.0482",
        "name": "Peelamedu",
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "country": "India",
        "woeid": "12513569",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "1319",
        "icao": "",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "CUL",
        "lat": "24.7644",
        "lon": "-107.474",
        "name": "Culiacan",
        "city": "Culiacan",
        "state": "Sinaloa",
        "country": "Mexico",
        "woeid": "12514859",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "108",
        "icao": "MMCL",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "DOM",
        "lat": "15.5431",
        "lon": "-61.3092",
        "name": "Melville Hall",
        "city": "Marigot",
        "state": "Saint Andrew",
        "country": "Dominica",
        "woeid": "12512609",
        "tz": "AmericaDominica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "80",
        "icao": "TDPD",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "DZA",
        "lat": "-12.8",
        "lon": "45.2833",
        "name": "Dzaoudzi Pamanzi",
        "city": "Mamoudzou",
        "state": "Mamoudzou",
        "country": "Mayotte",
        "woeid": "12514730",
        "tz": "IndianMayotte",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4429",
        "elev": "26",
        "icao": "FMCZ",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "EYW",
        "lat": "24.5536",
        "lon": "-81.7551",
        "name": "Key West",
        "city": "Key West",
        "state": "Florida",
        "country": "United States",
        "woeid": "12520456",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "4",
        "icao": "KEYW",
        "direct_flights": "8",
        "carriers": "10"
    },
    {
        "code": "FBM",
        "lat": "-11.5908",
        "lon": "27.5292",
        "name": "Lubumbashi Luano",
        "city": "Lubumbashi",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511970",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10370",
        "elev": "4295",
        "icao": "",
        "direct_flights": "8",
        "carriers": "9"
    },
    {
        "code": "FCA",
        "lat": "48.3075",
        "lon": "-114.252",
        "name": "Glacier Park",
        "city": "Kalispell",
        "state": "Montana",
        "country": "United States",
        "woeid": "12519905",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "2972",
        "icao": "KFCA",
        "direct_flights": "8",
        "carriers": "10"
    },
    {
        "code": "FNA",
        "lat": "8.6181",
        "lon": "-13.1994",
        "name": "Freetown Lungi",
        "city": "Freetown",
        "state": "Northern",
        "country": "Sierra Leone",
        "woeid": "12517517",
        "tz": "AfricaFreetown",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "84",
        "icao": "GFLL",
        "direct_flights": "8",
        "carriers": "12"
    },
    {
        "code": "GEA",
        "lat": "-22.2667",
        "lon": "166.467",
        "name": "Magenta",
        "city": "Noumea",
        "state": "Sud",
        "country": "New Caledonia",
        "woeid": "12523111",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3609",
        "elev": "10",
        "icao": "",
        "direct_flights": "8",
        "carriers": "1"
    },
    {
        "code": "GIZ",
        "lat": "16.9069",
        "lon": "42.5819",
        "name": "Gizan",
        "city": "Jizan",
        "state": "Jizan",
        "country": "Saudi Arabia",
        "woeid": "12517335",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "20",
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "GOH",
        "lat": "64.1922",
        "lon": "-51.6825",
        "name": "Godthaab",
        "city": "Nuuk",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12513030",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3100",
        "elev": "283",
        "icao": "BGGH",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "GUW",
        "lat": "47.1133",
        "lon": "51.8457",
        "name": "Guryev",
        "city": "Atyrau",
        "state": "Atyrau",
        "country": "Kazakhstan",
        "woeid": "12514338",
        "tz": "AsiaAqtobe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "GWT",
        "lat": "54.9139",
        "lon": "8.33126",
        "name": "Westerland",
        "city": "Westerland",
        "state": "Schleswig-Holstein",
        "country": "Germany",
        "woeid": "22294483",
        "tz": "EuropeBerlin",
        "phone": "0049 4651 920612",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafen-sylt.de",
        "runway_length": "6968",
        "elev": "51",
        "icao": "",
        "direct_flights": "8",
        "carriers": "4",
        "bookingId": "-1886793"
    },
    {
        "code": "HGU",
        "lat": "-5.8272",
        "lon": "144.295",
        "name": "Mount Hagen",
        "city": "Mount Hagen",
        "state": "Western Highlands",
        "country": "Papua New Guinea",
        "woeid": "12515475",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6180",
        "elev": "5388",
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "HME",
        "lat": "31.6739",
        "lon": "6.1428",
        "name": "Oued Irara",
        "city": "Ouargla",
        "state": "Ouargla",
        "country": "Algeria",
        "woeid": "12510340",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "463",
        "icao": "",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "HOG",
        "lat": "20.7853",
        "lon": "-76.315",
        "name": "Holguin",
        "city": "San Pedro de Cacocum",
        "state": "Holguin",
        "country": "Cuba",
        "woeid": "12512464",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10414",
        "elev": "348",
        "icao": "MUHG",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "IAM",
        "lat": "28.0511",
        "lon": "9.6381",
        "name": "In Amenas",
        "city": "Illizi",
        "state": "Illizi",
        "country": "Algeria",
        "woeid": "12510332",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "aeroport-iam@egsa-alger.com",
        "url": "http:www.egsa-alger.comindex.php?id=89",
        "runway_length": "9843",
        "elev": "1847",
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "KAN",
        "lat": "12.0444",
        "lon": "8.5139",
        "name": "Kano Mallam Aminu",
        "city": "",
        "state": "Kano",
        "country": "Nigeria",
        "woeid": "12515072",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "1565",
        "icao": "",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "KEJ",
        "lat": "55.3227",
        "lon": "86.0229",
        "name": "Kemerovo",
        "city": "Kemerovo",
        "state": "Kemerovskaya Oblast",
        "country": "Russia",
        "woeid": "12516123",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "KKN",
        "lat": "69.726",
        "lon": "29.8958",
        "name": "Kirkenes Hoybuktmoen",
        "city": "Hesseng",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12515111",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "299",
        "icao": "ENKR",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "KVA",
        "lat": "40.915",
        "lon": "24.6203",
        "name": "Chrisoupolis",
        "city": "Khrysoupolis",
        "state": "Anatoliki Makedonia",
        "country": "Greece",
        "woeid": "12513288",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "18",
        "icao": "",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "KVB",
        "lat": "58.4529",
        "lon": "13.9643",
        "name": "Skovde",
        "city": "Väring",
        "state": "Vastra Gotaland",
        "country": "Sweden",
        "woeid": "22656501",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2675",
        "elev": "397",
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "LCE",
        "lat": "15.7433",
        "lon": "-86.8528",
        "name": "Goloson",
        "city": "La Ceiba",
        "state": "Atlántida",
        "country": "Honduras",
        "woeid": "12513358",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9783",
        "elev": "49",
        "icao": "",
        "direct_flights": "8",
        "carriers": "4"
    },
    {
        "code": "LCG",
        "lat": "43.3026",
        "lon": "-8.37867",
        "name": "La Coruna",
        "city": "S Esteban",
        "state": "Galicia",
        "country": "Spain",
        "woeid": "12517551",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5512",
        "elev": "318",
        "icao": "LECO",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "LPI",
        "lat": "58.4082",
        "lon": "15.6586",
        "name": "Saab",
        "city": "Linkoping",
        "state": "Ostergotland",
        "country": "Sweden",
        "woeid": "12517672",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7382",
        "elev": "306",
        "icao": "ESSL",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "LPQ",
        "lat": "19.8958",
        "lon": "102.164",
        "name": "Louangphrabang",
        "city": "Louangphrabang",
        "state": "Louangphabang",
        "country": "Laos",
        "woeid": "12514546",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "978",
        "icao": "",
        "direct_flights": "8",
        "carriers": "4"
    },
    {
        "code": "LZH",
        "lat": "24.2717",
        "lon": "109.372",
        "name": "Liuzhou",
        "city": "Linzhou",
        "state": "Guangxi",
        "country": "China",
        "woeid": "12512131",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "MAF",
        "lat": "31.9361",
        "lon": "-102.208",
        "name": "Midland",
        "city": "Midland",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520944",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9501",
        "elev": "2871",
        "icao": "KMAF",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "MAR",
        "lat": "10.5614",
        "lon": "-71.7253",
        "name": "La Chinita",
        "city": "Maracaibo",
        "state": "Zulia",
        "country": "Venezuela",
        "woeid": "12522800",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "235",
        "icao": "SVMC",
        "direct_flights": "8",
        "carriers": "9"
    },
    {
        "code": "MID",
        "lat": "20.9361",
        "lon": "-89.6575",
        "name": "Lic M Crecencio Rejon",
        "city": "Mérida",
        "state": "Yucatan",
        "country": "Mexico",
        "woeid": "12514912",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "34",
        "icao": "MMMD",
        "direct_flights": "8",
        "carriers": "21"
    },
    {
        "code": "MLM",
        "lat": "19.8464",
        "lon": "-101.029",
        "name": "General Francisco J Mujica",
        "city": "Álvaro Obregón",
        "state": "Michoacan de Ocampo",
        "country": "Mexico",
        "woeid": "12514879",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "6234",
        "icao": "MMMM",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "MLN",
        "lat": "35.2825",
        "lon": "-2.9569",
        "name": "Melilla",
        "city": "Melilla",
        "state": "Melilla Province",
        "country": "Spain",
        "woeid": "12517556",
        "tz": "AfricaCeuta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3543",
        "elev": "154",
        "icao": "",
        "direct_flights": "8",
        "carriers": "1"
    },
    {
        "code": "MMK",
        "lat": "68.7794",
        "lon": "32.7434",
        "name": "Murmashi",
        "city": "Apatity",
        "state": "Murmanskaya Oblast",
        "country": "Russia",
        "woeid": "12516460",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMMK",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "MRY",
        "lat": "36.5872",
        "lon": "-121.851",
        "name": "Monterey Peninsula",
        "city": "Monterey",
        "state": "California",
        "country": "United States",
        "woeid": "12521001",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6597",
        "elev": "244",
        "icao": "KMRY",
        "direct_flights": "8",
        "carriers": "15"
    },
    {
        "code": "MSO",
        "lat": "46.9192",
        "lon": "-114.084",
        "name": "Missoula",
        "city": "Missoula",
        "state": "Montana",
        "country": "United States",
        "woeid": "12520974",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9499",
        "elev": "3201",
        "icao": "KMSO",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "MST",
        "lat": "50.9157",
        "lon": "5.76967",
        "name": "Maastricht",
        "city": "Maastricht",
        "state": "Limburg",
        "country": "Netherlands",
        "woeid": "12515090",
        "tz": "EuropeAmsterdam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "375",
        "icao": "EHBK",
        "direct_flights": "8",
        "carriers": "10",
        "bookingId": "-2149006"
    },
    {
        "code": "MTJ",
        "lat": "38.5003",
        "lon": "-107.899",
        "name": "Montrose",
        "city": "Montrose",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12521012",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8497",
        "elev": "5759",
        "icao": "KMTJ",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "NGS",
        "lat": "32.9141",
        "lon": "129.917",
        "name": "Nagasaki",
        "city": "Omura-shi",
        "state": "Nagasaki Prefecture",
        "country": "Japan",
        "woeid": "12513993",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "18",
        "icao": "KNGS",
        "direct_flights": "8",
        "carriers": "8"
    },
    {
        "code": "NOS",
        "lat": "-13.3153",
        "lon": "48.3103",
        "name": "Nosy Be Fascene",
        "city": "Hell-Ville",
        "state": "Antsiranana",
        "country": "Madagascar",
        "woeid": "12514706",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7185",
        "elev": "33",
        "icao": "",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "OSR",
        "lat": "49.6888",
        "lon": "18.1178",
        "name": "Mosnov",
        "city": "Mošnov",
        "state": "Moravskoslezský",
        "country": "Czech Republic",
        "woeid": "12512552",
        "tz": "EuropePrague",
        "phone": "+420 59 747 1122",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-ostrava.cz",
        "runway_length": "11483",
        "elev": "844",
        "icao": "LKMT",
        "direct_flights": "8",
        "carriers": "10"
    },
    {
        "code": "PIE",
        "lat": "27.9065",
        "lon": "-82.6924",
        "name": "St. Petersburg-Clearwater",
        "city": "Clearwater",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521978",
        "tz": "AmericaNew_York",
        "phone": "+1 (1)727 535-7600",
        "type": "Airports",
        "email": "",
        "url": "http:www.stpete-clwairport.com",
        "runway_length": "8800",
        "elev": "10",
        "icao": "KPIE",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "PMV",
        "lat": "10.9167",
        "lon": "-63.98",
        "name": "Del Caribe",
        "city": "Pampatar",
        "state": "Nueva Esparta",
        "country": "Venezuela",
        "woeid": "12522772",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10433",
        "elev": "89",
        "icao": "KPMV",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "ROR",
        "lat": "7.35743",
        "lon": "134.547",
        "name": "Koror",
        "city": "Koror",
        "state": "Koror",
        "country": "Palau",
        "woeid": "12515481",
        "tz": "PacificPalau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7200",
        "elev": "176",
        "icao": "PTRO",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "RPR",
        "lat": "21.1671",
        "lon": "81.7473",
        "name": "Raipur",
        "city": "Banarsi",
        "state": "Chhattisgarh",
        "country": "India",
        "woeid": "12513658",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6107",
        "elev": "1037",
        "icao": "VARP",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "SFJ",
        "lat": "67.0169",
        "lon": "-50.6892",
        "name": "Kangerlussuaq",
        "city": "Kangerlussuaq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12513032",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9235",
        "elev": "165",
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "STI",
        "lat": "19.4692",
        "lon": "-70.7003",
        "name": "Cibao",
        "city": "La Lomota",
        "state": "Santiago",
        "country": "Dominican Republic",
        "woeid": "12512614",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "597",
        "icao": "MDST",
        "direct_flights": "8",
        "carriers": "9"
    },
    {
        "code": "TAM",
        "lat": "22.2881",
        "lon": "-97.8633",
        "name": "Gen Francisco J Mina",
        "city": "Tampico",
        "state": "Tamaulipas",
        "country": "Mexico",
        "woeid": "12514875",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMTM",
        "direct_flights": "8",
        "carriers": "12"
    },
    {
        "code": "TBU",
        "lat": "-21.2486",
        "lon": "-175.136",
        "name": "Fua'amotu",
        "city": "Nuku'alofa",
        "state": "Tongatapu",
        "country": "Tonga",
        "woeid": "12517847",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "NFTF",
        "direct_flights": "8",
        "carriers": "6"
    },
    {
        "code": "TGU",
        "lat": "14.0603",
        "lon": "-87.2172",
        "name": "Toncontin",
        "city": "Tegucigalpa",
        "state": "Francisco Morazán",
        "country": "Honduras",
        "woeid": "12513364",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6132",
        "elev": "3297",
        "icao": "MHTG",
        "direct_flights": "8",
        "carriers": "13"
    },
    {
        "code": "TIF",
        "lat": "21.4831",
        "lon": "40.5439",
        "name": "Taif",
        "city": "",
        "state": "Makka",
        "country": "Saudi Arabia",
        "woeid": "12517372",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTIF",
        "direct_flights": "8",
        "carriers": "1"
    },
    {
        "code": "TIV",
        "lat": "42.4047",
        "lon": "18.7233",
        "name": "Tivat",
        "city": "Teodo",
        "state": "Tivat",
        "country": "Montenegro",
        "woeid": "12517593",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aptivat.com",
        "runway_length": "8208",
        "elev": "20",
        "icao": "LYTV",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "TMM",
        "lat": "-18.1064",
        "lon": "49.3919",
        "name": "Toamasina",
        "city": "Toamasina",
        "state": "Toamasina",
        "country": "Madagascar",
        "woeid": "12514708",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "TOE",
        "lat": "33.9308",
        "lon": "8.1042",
        "name": "Nefta",
        "city": "Tawzar",
        "state": "Tawzar",
        "country": "Tunisia",
        "woeid": "12517861",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "4"
    },
    {
        "code": "UAK",
        "lat": "61.1611",
        "lon": "-45.4278",
        "name": "Narsarsuaq",
        "city": "Narsarssurk",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12513031",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:iserit.greennet.glbgbw",
        "runway_length": "6004",
        "elev": "112",
        "icao": "",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "VER",
        "lat": "19.145",
        "lon": "-96.1878",
        "name": "General Heriberto Jara",
        "city": "Teocelo",
        "state": "Veracruz-Llave",
        "country": "Mexico",
        "woeid": "12514880",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "95",
        "icao": "KVER",
        "direct_flights": "8",
        "carriers": "11"
    },
    {
        "code": "VLN",
        "lat": "10.1556",
        "lon": "-67.9319",
        "name": "Zim Valencia",
        "city": "Valencia",
        "state": "Carabobo",
        "country": "Venezuela",
        "woeid": "12522858",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1410",
        "icao": "",
        "direct_flights": "8",
        "carriers": "10"
    },
    {
        "code": "VPS",
        "lat": "30.496",
        "lon": "-86.55",
        "name": "Eglin Air Force Base",
        "city": "Eglin Village",
        "state": "Florida",
        "country": "United States",
        "woeid": "12519601",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "85",
        "icao": "KVPS",
        "direct_flights": "8",
        "carriers": "12"
    },
    {
        "code": "VST",
        "lat": "59.6007",
        "lon": "16.6367",
        "name": "Hasslo",
        "city": "Vasteras",
        "state": "Vastmanland",
        "country": "Sweden",
        "woeid": "12517636",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "20",
        "icao": "",
        "direct_flights": "8",
        "carriers": "3"
    },
    {
        "code": "WAT",
        "lat": "52.1972",
        "lon": "-7.0889",
        "name": "Waterford",
        "city": "Waterford",
        "state": "",
        "country": "Ireland",
        "woeid": "12512728",
        "tz": "EuropeDublin",
        "phone": "+35351875589",
        "type": "Airports",
        "email": "",
        "url": "http:www.flywaterford.com",
        "runway_length": "4701",
        "elev": "122",
        "icao": "EIWF",
        "direct_flights": "8",
        "carriers": "1"
    },
    {
        "code": "WEH",
        "lat": "36.3306",
        "lon": "118.761",
        "name": "Shandong",
        "city": "Weihai",
        "state": "Shandong",
        "country": "China",
        "woeid": "12578014",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "7"
    },
    {
        "code": "YCU",
        "lat": "61.3167",
        "lon": "-98.5",
        "name": "Cullaton Lake",
        "city": "Chesterfield Inlet",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524094",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "YDF",
        "lat": "49.2085",
        "lon": "-57.3999",
        "name": "Deer Lake",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511635",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "72",
        "icao": "CYDF",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "YQR",
        "lat": "50.4345",
        "lon": "-104.655",
        "name": "Regina",
        "city": "Regina",
        "state": "Saskatchewan",
        "country": "Canada",
        "woeid": "23388227",
        "tz": "AmericaRegina",
        "phone": "+1 306 761 7555",
        "type": "Airports",
        "email": "",
        "url": "http:www.yqr.ca",
        "runway_length": "7900",
        "elev": "1894",
        "icao": "CYQR",
        "direct_flights": "8",
        "carriers": "4"
    },
    {
        "code": "YVP",
        "lat": "58.102",
        "lon": "-68.4333",
        "name": "Kuujjuaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511719",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "122",
        "icao": "CYVP",
        "direct_flights": "8",
        "carriers": "2"
    },
    {
        "code": "YZV",
        "lat": "50.2169",
        "lon": "-66.2654",
        "name": "Sept Iles",
        "city": "Sept-Îles",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511829",
        "tz": "AmericaMontreal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6572",
        "elev": "180",
        "icao": "CYZV",
        "direct_flights": "8",
        "carriers": "5"
    },
    {
        "code": "AAL",
        "lat": "57.0952",
        "lon": "9.85606",
        "name": "Aalborg",
        "city": "Norresundby",
        "state": "Nordjylland",
        "country": "Denmark",
        "woeid": "12512587",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aal.dk",
        "runway_length": "8700",
        "elev": "10",
        "icao": "EKYT",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "ADZ",
        "lat": "12.5811",
        "lon": "-81.7092",
        "name": "Sesquicentenario",
        "city": "San Andrés",
        "state": "San Andres y Providencia",
        "country": "Colombia",
        "woeid": "12512417",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7808",
        "elev": "19",
        "icao": "SKSP",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "ALF",
        "lat": "69.9793",
        "lon": "23.3571",
        "name": "Alta",
        "city": "Alta",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12515099",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "9",
        "icao": "ENAT",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "AMA",
        "lat": "35.2177",
        "lon": "-101.706",
        "name": "Amarillo",
        "city": "Amarillo",
        "state": "Texas",
        "country": "United States",
        "woeid": "12518598",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13502",
        "elev": "3606",
        "icao": "KAMA",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "ANF",
        "lat": "-23.44",
        "lon": "-70.4419",
        "name": "Cerro Moreno",
        "city": "Antofagasta",
        "state": "Antofagasta",
        "country": "Chile",
        "woeid": "12512312",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8527",
        "elev": "460",
        "icao": "SCFA",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "APW",
        "lat": "-13.8255",
        "lon": "-171.993",
        "name": "Faleolo",
        "city": "Apia",
        "state": "A`ana",
        "country": "Samoa",
        "woeid": "12523066",
        "tz": "PacificApia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "58",
        "icao": "NSFA",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "ASF",
        "lat": "46.2971",
        "lon": "48.0515",
        "name": "Astrakhan Southeast",
        "city": "Astrakhan'",
        "state": "Astrakhanskaya Oblast",
        "country": "Russia",
        "woeid": "12515745",
        "tz": "EuropeSamara",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "URWA",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "ASR",
        "lat": "38.7708",
        "lon": "35.4939",
        "name": "Erkilet",
        "city": "Kayseri",
        "state": "Kayseri",
        "country": "Turkey",
        "woeid": "12517886",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "3571",
        "icao": "LTAU",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "AVL",
        "lat": "35.4349",
        "lon": "-82.5373",
        "name": "Asheville",
        "city": "Fletcher",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12518672",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flyavl.com",
        "runway_length": "8001",
        "elev": "2165",
        "icao": "KAVL",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "AWZ",
        "lat": "31.3378",
        "lon": "48.7597",
        "name": "Ahvaz",
        "city": "Ahvaz",
        "state": "Khuzestan",
        "country": "Iran",
        "woeid": "12513699",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11100",
        "elev": "60",
        "icao": "OIAW",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "AXT",
        "lat": "39.6153",
        "lon": "140.219",
        "name": "Akita",
        "city": "Akita-shi",
        "state": "Akita Prefecture",
        "country": "Japan",
        "woeid": "12513940",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "313",
        "icao": "RJSK",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "BEW",
        "lat": "-19.7958",
        "lon": "34.9089",
        "name": "Beira",
        "city": "Beira",
        "state": "Sofala",
        "country": "Mozambique",
        "woeid": "12515014",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "33",
        "icao": "FQBR",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "BGA",
        "lat": "7.1217",
        "lon": "-73.1883",
        "name": "Palonegro",
        "city": "Bucaramanga",
        "state": "Santander",
        "country": "Colombia",
        "woeid": "12512401",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7415",
        "elev": "3896",
        "icao": "SKBG",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "BMI",
        "lat": "40.4841",
        "lon": "-88.9134",
        "name": "Bloomington Normal",
        "city": "Bloomington",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12518861",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "875",
        "icao": "KBMI",
        "direct_flights": "7",
        "carriers": "12"
    },
    {
        "code": "BNN",
        "lat": "65.462",
        "lon": "12.2161",
        "name": "Bronnoy",
        "city": "Bronnoysund",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523928",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2624",
        "elev": "28",
        "icao": "ENBN",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "BOB",
        "lat": "-16.45",
        "lon": "-151.767",
        "name": "Motu-Mute",
        "city": "Papeete",
        "state": "Leeward Islands",
        "country": "French Polynesia",
        "woeid": "12523077",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "10",
        "icao": "NTTB",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "BRN",
        "lat": "46.9124",
        "lon": "7.49929",
        "name": "Bern Belp",
        "city": "Belp",
        "state": "Canton of Berne",
        "country": "Switzerland",
        "woeid": "23332448",
        "tz": "EuropeZurich",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4298",
        "elev": "1673",
        "icao": "LSZB",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "BRQ",
        "lat": "49.1535",
        "lon": "16.6908",
        "name": "Turany",
        "city": "Brno",
        "state": "Jihomoravský",
        "country": "Czech Republic",
        "woeid": "12512574",
        "tz": "EuropePrague",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-brno.cz",
        "runway_length": "8694",
        "elev": "778",
        "icao": "LKTB",
        "direct_flights": "7",
        "carriers": "8"
    },
    {
        "code": "BRW",
        "lat": "71.2892",
        "lon": "-156.772",
        "name": "Wiley Post Will Rogers Memorial",
        "city": "Barrow",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522440",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "44",
        "icao": "PABR",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "CCF",
        "lat": "43.2153",
        "lon": "2.30989",
        "name": "Salvaza",
        "city": "Carcassonne",
        "state": "Languedoc-Roussillon",
        "country": "France",
        "woeid": "12512962",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "6397",
        "elev": "433",
        "icao": "LFMK",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "CDB",
        "lat": "55.2005",
        "lon": "-162.705",
        "name": "Cold Bay",
        "city": "Cold Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519269",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10415",
        "elev": "98",
        "icao": "PACD",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "CEG",
        "lat": "53.1707",
        "lon": "-2.98142",
        "name": "Hawarden",
        "city": "Chester",
        "state": "Wales",
        "country": "United Kingdom",
        "woeid": "12518100",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4714",
        "elev": "35",
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "CHO",
        "lat": "38.14",
        "lon": "-78.4487",
        "name": "Charlottesville Albemarle",
        "city": "Earlysville",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12519149",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6001",
        "elev": "640",
        "icao": "KCHO",
        "direct_flights": "7",
        "carriers": "9"
    },
    {
        "code": "CKY",
        "lat": "9.5764",
        "lon": "-13.6108",
        "name": "Conakry",
        "city": "Conakry",
        "state": "Conakry",
        "country": "Guinea",
        "woeid": "12513342",
        "tz": "AfricaConakry",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "72",
        "icao": "GUCY",
        "direct_flights": "7",
        "carriers": "14"
    },
    {
        "code": "CLY",
        "lat": "42.5295",
        "lon": "8.79677",
        "name": "Ste Catherine",
        "city": "Calenzana",
        "state": "Corsica",
        "country": "France",
        "woeid": "12512978",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7579",
        "elev": "210",
        "icao": "LFKC",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "CZX",
        "lat": "31.7833",
        "lon": "119.95",
        "name": "Changzhou",
        "city": "Changzhou",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "12523092",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "DGO",
        "lat": "24.1283",
        "lon": "-104.527",
        "name": "Durango",
        "city": "Durango",
        "state": "",
        "country": "Mexico",
        "woeid": "12514864",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8859",
        "elev": "6093",
        "icao": "MMDO",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "DIE",
        "lat": "-12.3461",
        "lon": "49.2925",
        "name": "Antsiranana Arrachart",
        "city": "Antseranana",
        "state": "Antsiranana",
        "country": "Madagascar",
        "woeid": "12514693",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "374",
        "icao": "FMNA",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "DVO",
        "lat": "7.1292",
        "lon": "125.647",
        "name": "Francisco Bangoy",
        "city": "Davao City",
        "state": "Davao Region",
        "country": "Philippines",
        "woeid": "12515616",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "88",
        "icao": "KDVO",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "ERZ",
        "lat": "39.9567",
        "lon": "41.1697",
        "name": "Erzurum",
        "city": "Erzurum",
        "state": "Erzurum",
        "country": "Turkey",
        "woeid": "12517888",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12500",
        "elev": "5761",
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "EVV",
        "lat": "38.0464",
        "lon": "-87.5308",
        "name": "Evansville",
        "city": "Evansville",
        "state": "Indiana",
        "country": "United States",
        "woeid": "12519675",
        "tz": "AmericaChicago",
        "phone": "(812) 421-4401",
        "type": "Airports",
        "email": "comments@evvairport.com",
        "url": "http:www.evvairport.com",
        "runway_length": "8021",
        "elev": "418",
        "icao": "KEVV",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "FAR",
        "lat": "46.9195",
        "lon": "-96.8243",
        "name": "Hector",
        "city": "Fargo",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12520153",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9546",
        "elev": "900",
        "icao": "KFAR",
        "direct_flights": "7",
        "carriers": "12"
    },
    {
        "code": "FEZ",
        "lat": "33.9286",
        "lon": "-4.9767",
        "name": "Fez",
        "city": "Fez",
        "state": "Fes",
        "country": "Morocco",
        "woeid": "12514790",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "1900",
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "GAY",
        "lat": "24.7443",
        "lon": "84.9493",
        "name": "Gaya",
        "city": "Gaya",
        "state": "Bihar",
        "country": "India",
        "woeid": "12513586",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "377",
        "icao": "VEGY",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "GDX",
        "lat": "59.8984",
        "lon": "150.71",
        "name": "Magadan Northwest",
        "city": "Magadan",
        "state": "Magadanskaya Oblast",
        "country": "Russia",
        "woeid": "12516379",
        "tz": "AsiaMagadan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11325",
        "elev": "574",
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "GJT",
        "lat": "39.1217",
        "lon": "-108.529",
        "name": "Walker Field",
        "city": "Grand Junction",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12522315",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10501",
        "elev": "4858",
        "icao": "KGJT",
        "direct_flights": "7",
        "carriers": "11"
    },
    {
        "code": "GNB",
        "lat": "45.3614",
        "lon": "5.33214",
        "name": "St Geoirs",
        "city": "Sillans",
        "state": "Rhone-Alpes",
        "country": "France",
        "woeid": "12512972",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "1302",
        "icao": "KGNB",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "GYN",
        "lat": "-16.6294",
        "lon": "-49.2261",
        "name": "Santa Genoveva",
        "city": "Goiania",
        "state": "Goias",
        "country": "Brazil",
        "woeid": "12511301",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7221",
        "elev": "2448",
        "icao": "",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "GZT",
        "lat": "36.9481",
        "lon": "37.4783",
        "name": "Gaziantep",
        "city": "Oğuzeli",
        "state": "Gaziantep",
        "country": "Turkey",
        "woeid": "12517892",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "2313",
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "HAU",
        "lat": "59.3436",
        "lon": "5.21246",
        "name": "Haugesund Karmoy",
        "city": "Avaldsnes",
        "state": "Rogaland Fylke",
        "country": "Norway",
        "woeid": "12515110",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6957",
        "elev": "77",
        "icao": "ENHD",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "HEH",
        "lat": "20.7439",
        "lon": "96.7944",
        "name": "Heho",
        "city": "Heho",
        "state": "Shan State",
        "country": "Myanmar",
        "woeid": "12510914",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5700",
        "elev": "3858",
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "HFT",
        "lat": "70.6808",
        "lon": "23.676",
        "name": "Hammerfest",
        "city": "Hammerfest",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523934",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "266",
        "icao": "ENHF",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "HID",
        "lat": "-10.5858",
        "lon": "142.293",
        "name": "Horn Island",
        "city": "Horn Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708481",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4550",
        "elev": "44",
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "HRK",
        "lat": "49.9245",
        "lon": "36.2946",
        "name": "Kharkov",
        "city": "Kharkiv",
        "state": "Kharkivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518282",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "499",
        "icao": "UKHH",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "IQQ",
        "lat": "-20.5356",
        "lon": "-70.1842",
        "name": "Diego Aracena",
        "city": "Alto Hospicio",
        "state": "Tarapaca",
        "country": "Chile",
        "woeid": "12512317",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8136",
        "elev": "157",
        "icao": "SCDA",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "ISA",
        "lat": "-20.6696",
        "lon": "139.488",
        "name": "Mount Isa",
        "city": "Mount Isa",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510745",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8400",
        "elev": "1121",
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "JAC",
        "lat": "43.6034",
        "lon": "-110.736",
        "name": "Jackson Hole",
        "city": "Jackson",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12520335",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6299",
        "elev": "6444",
        "icao": "KJAC",
        "direct_flights": "7",
        "carriers": "9"
    },
    {
        "code": "JAV",
        "lat": "69.25",
        "lon": "-51",
        "name": "Ilulissat",
        "city": "Ilulissat",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523844",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "JEG",
        "lat": "68.7075",
        "lon": "-52.8069",
        "name": "Auisiait",
        "city": "Aasiaat",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523840",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "JOG",
        "lat": "-7.7889",
        "lon": "110.43",
        "name": "Adisucipto",
        "city": "Yogyakarta",
        "state": "Yogyakarta",
        "country": "Indonesia",
        "woeid": "12513451",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "350",
        "icao": "",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "KGF",
        "lat": "49.6671",
        "lon": "73.3303",
        "name": "Karaganda",
        "city": "Qaraghandy",
        "state": "Qaraghandy",
        "country": "Kazakhstan",
        "woeid": "12514358",
        "tz": "AsiaAlmaty",
        "phone": "+7 (3212) 49-62-87",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.karaganda.kz",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "KGL",
        "lat": "-1.9678",
        "lon": "30.14",
        "name": "Kigali",
        "city": "Kigali",
        "state": "Kigali Province",
        "country": "Rwanda",
        "woeid": "12517316",
        "tz": "AfricaKigali",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "4892",
        "icao": "HRYR",
        "direct_flights": "7",
        "carriers": "11"
    },
    {
        "code": "KIH",
        "lat": "26.5267",
        "lon": "53.9817",
        "name": "Kish Island",
        "city": "Bandar Abbas",
        "state": "Hormozgan",
        "country": "Iran",
        "woeid": "12513738",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12008",
        "elev": "96",
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "KMI",
        "lat": "31.8771",
        "lon": "131.449",
        "name": "Miyazaki",
        "city": "Miyazaki-shi",
        "state": "Miyazaki Prefecture",
        "country": "Japan",
        "woeid": "12513991",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6230",
        "elev": "20",
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "KMJ",
        "lat": "32.8346",
        "lon": "130.849",
        "name": "Kumamoto",
        "city": "Kikuyo-machi",
        "state": "Kumamoto Prefecture",
        "country": "Japan",
        "woeid": "12513981",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "642",
        "icao": "RJFT",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "KRS",
        "lat": "58.1988",
        "lon": "8.07792",
        "name": "Kristiansand",
        "city": "Kjevic",
        "state": "Vest-Agder Fylke",
        "country": "Norway",
        "woeid": "12515113",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6135",
        "elev": "57",
        "icao": "ENCN",
        "direct_flights": "7",
        "carriers": "10"
    },
    {
        "code": "KSD",
        "lat": "59.444",
        "lon": "13.3435",
        "name": "Karlstad",
        "city": "Karlstad",
        "state": "Varmland",
        "country": "Sweden",
        "woeid": "22656500",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.setemplatesLFV_AirportStartPage____4490.aspx",
        "runway_length": "5184",
        "elev": "152",
        "icao": "",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "KUT",
        "lat": "32.507",
        "lon": "45.8217",
        "name": "Kopitnari",
        "city": "Kutaisi",
        "state": "K´ut´aisi",
        "country": "Georgia",
        "woeid": "1963698",
        "tz": "AsiaTbilisi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "LBC",
        "lat": "53.8061",
        "lon": "10.7032",
        "name": "Lubeck",
        "city": "Lubeque",
        "state": "Schleswig-Holstein",
        "country": "Germany",
        "woeid": "22239875",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafen-luebeck.de1024englishindex.htm",
        "runway_length": "5912",
        "elev": "53",
        "icao": "",
        "direct_flights": "7",
        "carriers": "2",
        "bookingId": "-1821233"
    },
    {
        "code": "LDY",
        "lat": "55.0402",
        "lon": "-7.156",
        "name": "City of Derry",
        "city": "Londonderry",
        "state": "Northern Ireland",
        "country": "United Kingdom",
        "woeid": "23387862",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4695",
        "elev": "18",
        "icao": "EGAE",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "LEN",
        "lat": "34.1697",
        "lon": "77.53",
        "name": "Leon",
        "city": "Leon",
        "state": "Castille and Leon",
        "country": "Spain",
        "woeid": "765099",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "LIH",
        "lat": "21.9782",
        "lon": "-159.351",
        "name": "Lihue",
        "city": "Lihue",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520648",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "149",
        "icao": "PHLI",
        "direct_flights": "7",
        "carriers": "13"
    },
    {
        "code": "LKO",
        "lat": "26.7667",
        "lon": "80.8833",
        "name": "Amausi",
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "country": "India",
        "woeid": "12513627",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7835",
        "elev": "400",
        "icao": "",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "LLA",
        "lat": "65.549",
        "lon": "22.1233",
        "name": "Kallax",
        "city": "Lulea",
        "state": "Norrbotten",
        "country": "Sweden",
        "woeid": "12517645",
        "tz": "EuropeStockholm",
        "phone": "+46-920-244900",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.setemplatesLFV_AirportStartPage____3595.aspx",
        "runway_length": "7218",
        "elev": "55",
        "icao": "ESPA",
        "direct_flights": "7",
        "carriers": "9"
    },
    {
        "code": "LLW",
        "lat": "-13.7917",
        "lon": "33.7792",
        "name": "Kamuzu",
        "city": "Lumbadzi",
        "state": "Dowa",
        "country": "Malawi",
        "woeid": "12514766",
        "tz": "AfricaBlantyre",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11614",
        "elev": "4035",
        "icao": "",
        "direct_flights": "7",
        "carriers": "8"
    },
    {
        "code": "LMM",
        "lat": "25.6856",
        "lon": "-109.082",
        "name": "Los Mochis",
        "city": "Ahome",
        "state": "Sinaloa",
        "country": "Mexico",
        "woeid": "12514917",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "10",
        "icao": "MMLM",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "LRM",
        "lat": "18.4103",
        "lon": "-68.9417",
        "name": "La Romana",
        "city": "La Romana",
        "state": "Romana",
        "country": "Dominican Republic",
        "woeid": "12512618",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6578",
        "elev": "27",
        "icao": "MDLR",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "LYG",
        "lat": "34.5995",
        "lon": "119.141",
        "name": "Lianyungang",
        "city": "Lianyungang",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "2137087",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "MCX",
        "lat": "42.8209",
        "lon": "47.6288",
        "name": "Makhachkala-Uytash",
        "city": "Khasavyurt",
        "state": "Dagestan",
        "country": "Russia",
        "woeid": "12516386",
        "tz": "EuropeVolgograd",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMCX",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "MDC",
        "lat": "1.5486",
        "lon": "124.926",
        "name": "Sam Ratulangi",
        "city": "Manado",
        "state": "North Sulawesi",
        "country": "Indonesia",
        "woeid": "12513501",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "262",
        "icao": "",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "MGQ",
        "lat": "2.0136",
        "lon": "45.3047",
        "name": "Mogadishu",
        "city": "Mogadishu",
        "state": "Banaadir",
        "country": "Somalia",
        "woeid": "12517532",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10335",
        "elev": "27",
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "MHG",
        "lat": "49.4762",
        "lon": "8.52105",
        "name": "Mannheim City",
        "city": "Mannheim",
        "state": "Baden-Wurttemberg",
        "country": "Germany",
        "woeid": "22243020",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "309",
        "icao": "",
        "direct_flights": "7",
        "carriers": "3",
        "bookingId": "-1822951"
    },
    {
        "code": "MHH",
        "lat": "26.5111",
        "lon": "-77.0847",
        "name": "Marsh Harbour",
        "city": "Marsh Harbour",
        "state": "Central Abaco",
        "country": "Bahamas",
        "woeid": "12510872",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "10",
        "icao": "",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "MVY",
        "lat": "41.3895",
        "lon": "-70.6112",
        "name": "Marthas Vineyard",
        "city": "Vineyard Haven",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12520822",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMVY",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "NOC",
        "lat": "53.9133",
        "lon": "-8.81129",
        "name": "Connaught",
        "city": "Knock",
        "state": "",
        "country": "Ireland",
        "woeid": "12512719",
        "tz": "EuropeDublin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "665",
        "icao": "EIKN",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "OGX",
        "lat": "35.8764",
        "lon": "7.25695",
        "name": "Ain Beida",
        "city": "Ouargla",
        "state": "Oum el Bouaghi",
        "country": "Algeria",
        "woeid": "12805369",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "492",
        "icao": "",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "OKJ",
        "lat": "34.7579",
        "lon": "133.855",
        "name": "Okayama",
        "city": "Okayama-shi",
        "state": "Okayama Prefecture",
        "country": "Japan",
        "woeid": "12514005",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "3",
        "icao": "RJOB",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "OUD",
        "lat": "34.7903",
        "lon": "-1.925",
        "name": "Angads",
        "city": "Oujda",
        "state": "Oujda",
        "country": "Morocco",
        "woeid": "12514781",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1535",
        "icao": "GMFO",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "OVS",
        "lat": "43.1537",
        "lon": "-90.6764",
        "name": "Boscobel",
        "city": "Boscobel",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12518898",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KOVS",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "OXB",
        "lat": "11.8886",
        "lon": "-15.6575",
        "name": "Bissau Oswaldo Vieira",
        "city": "Bissau",
        "state": "Biombo",
        "country": "Guinea-Bissau",
        "woeid": "12515483",
        "tz": "AfricaBissau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KOXB",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "PEI",
        "lat": "4.8147",
        "lon": "-75.745",
        "name": "Matecana",
        "city": "Pereira",
        "state": "Risaralda",
        "country": "Colombia",
        "woeid": "12512397",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6626",
        "elev": "4416",
        "icao": "SKPE",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "PHF",
        "lat": "37.1306",
        "lon": "-76.5026",
        "name": "Newport News-Williamsburg",
        "city": "Newport News",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12521311",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8003",
        "elev": "43",
        "icao": "KPHF",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "PIR",
        "lat": "44.3801",
        "lon": "-100.293",
        "name": "Pierre Municipal",
        "city": "Pierre",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12521375",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6891",
        "elev": "1742",
        "icao": "KPIR",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "PLU",
        "lat": "-19.8517",
        "lon": "-43.9508",
        "name": "Pampulha",
        "city": "Belo Horizonte",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511257",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8327",
        "elev": "2587",
        "icao": "SBBH",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "PSC",
        "lat": "46.2592",
        "lon": "-119.117",
        "name": "Tri Cities",
        "city": "Pasco",
        "state": "Washington",
        "country": "United States",
        "woeid": "12522188",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7700",
        "elev": "407",
        "icao": "KPSC",
        "direct_flights": "7",
        "carriers": "12"
    },
    {
        "code": "PUF",
        "lat": "43.3823",
        "lon": "-0.4166",
        "name": "Pont Long Uzein",
        "city": "Lescar",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512953",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "617",
        "icao": "LFBP",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "RAO",
        "lat": "-21.1322",
        "lon": "-47.7744",
        "name": "Leite Lopes",
        "city": "Ribeirão Prêto",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511210",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1801",
        "icao": "",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "RFD",
        "lat": "42.2035",
        "lon": "-89.0955",
        "name": "Greater Rockford",
        "city": "Rockford",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12519986",
        "tz": "AmericaChicago",
        "phone": "815-969-4000",
        "type": "Airports",
        "email": "",
        "url": "http:www.flyrfd.com",
        "runway_length": "10",
        "elev": "736",
        "icao": "KRFD",
        "direct_flights": "7",
        "carriers": "4"
    },
    {
        "code": "RGI",
        "lat": "-14.9516",
        "lon": "-147.649",
        "name": "Rangiroa",
        "city": "Papeete",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "12512828",
        "tz": "PacificGambier",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "10",
        "icao": "",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "RIV",
        "lat": "33.8806",
        "lon": "-117.259",
        "name": "March Air Force Base",
        "city": "Alessandro",
        "state": "California",
        "country": "United States",
        "woeid": "12520791",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KRIV",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "RIY",
        "lat": "14.6611",
        "lon": "49.3761",
        "name": "Riyan",
        "city": "Shuhayr",
        "state": "Hadramawt",
        "country": "Yemen",
        "woeid": "12523004",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "54",
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "RTB",
        "lat": "16.3242",
        "lon": "-86.5323",
        "name": "Roatan Island",
        "city": "Roatán",
        "state": "Islas de la Bahía",
        "country": "Honduras",
        "woeid": "12513362",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "7",
        "icao": "MHRO",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "RZE",
        "lat": "50.1062",
        "lon": "22.0243",
        "name": "Jasionka",
        "city": "Trzebownisko",
        "state": "Subcarpathia",
        "country": "Poland",
        "woeid": "12515314",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8209",
        "elev": "692",
        "icao": "EPRZ",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "SZD",
        "lat": "53.3957",
        "lon": "-1.38232",
        "name": "Sheffield City",
        "city": "Sheffield",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22656550",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EGSY",
        "direct_flights": "7",
        "carriers": "1",
        "bookingId": "-2607573"
    },
    {
        "code": "TAB",
        "lat": "11.25",
        "lon": "-60.6667",
        "name": "Crown Point",
        "city": "Plymouth",
        "state": "Tobago",
        "country": "Trinidad and Tobago",
        "woeid": "12517743",
        "tz": "AmericaPort_of_Spain",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.TobagoAirport.com",
        "runway_length": "9002",
        "elev": "38",
        "icao": "TTCP",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "TBZ",
        "lat": "38.1322",
        "lon": "46.2431",
        "name": "Tabriz",
        "city": "Tabriz",
        "state": "Azarbayjan-e Sharqi",
        "country": "Iran",
        "woeid": "12513767",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OITT",
        "direct_flights": "7",
        "carriers": "7"
    },
    {
        "code": "URA",
        "lat": "51.1526",
        "lon": "51.5336",
        "name": "Podstepnyy",
        "city": "Oral",
        "state": "Batys Qazaqstan",
        "country": "Kazakhstan",
        "woeid": "12514438",
        "tz": "AsiaAqtobe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "VDS",
        "lat": "70.0653",
        "lon": "29.8416",
        "name": "Vadso",
        "city": "Vadso",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523954",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "127",
        "icao": "ENVD",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "VSA",
        "lat": "17.9958",
        "lon": "-92.8189",
        "name": "Villahermosa",
        "city": "Villahermosa",
        "state": "Tabasco",
        "country": "Mexico",
        "woeid": "12514984",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "43",
        "icao": "MMVA",
        "direct_flights": "7",
        "carriers": "15"
    },
    {
        "code": "XKS",
        "lat": "53.513",
        "lon": "-88.6475",
        "name": "Kasabonika",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524061",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "630",
        "icao": "CYAQ",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "XQP",
        "lat": "9.4442",
        "lon": "-84.1311",
        "name": "Quepos Managua",
        "city": "Quepos",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12512440",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "105",
        "icao": "MRQP",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "YGW",
        "lat": "55.2825",
        "lon": "-77.7583",
        "name": "Kuujjuarapik",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524130",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "60",
        "icao": "CYGW",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "YIW",
        "lat": "0.688365",
        "lon": "129.154",
        "name": "Yiwu",
        "city": "Yiwu",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "2132647",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "YQM",
        "lat": "46.1166",
        "lon": "-64.6883",
        "name": "Greater Moncton",
        "city": "Moncton",
        "state": "New Brunswick",
        "country": "Canada",
        "woeid": "12511754",
        "tz": "AmericaHalifax",
        "phone": "(506) 856-5455",
        "type": "Airports",
        "email": "",
        "url": "http:www.gmia.ca",
        "runway_length": "8000",
        "elev": "232",
        "icao": "CYQM",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "YRL",
        "lat": "51.0683",
        "lon": "-93.8015",
        "name": "Red Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511811",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "1257",
        "icao": "CYRL",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "YTL",
        "lat": "53.7767",
        "lon": "-89.9697",
        "name": "Big Trout Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524229",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3300",
        "elev": "740",
        "icao": "CYTL",
        "direct_flights": "7",
        "carriers": "1"
    },
    {
        "code": "YWP",
        "lat": "52.978",
        "lon": "-87.3578",
        "name": "Webequie",
        "city": "Marathon",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524263",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "650",
        "icao": "CYWP",
        "direct_flights": "7",
        "carriers": "2"
    },
    {
        "code": "YXS",
        "lat": "53.8805",
        "lon": "-122.676",
        "name": "Prince George",
        "city": "Prince George",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511801",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7400",
        "elev": "2268",
        "icao": "CYXS",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "YYG",
        "lat": "46.2833",
        "lon": "-63.1333",
        "name": "Charlottetown",
        "city": "Charlottetown",
        "state": "Prince Edward Island",
        "country": "Canada",
        "woeid": "12511608",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flypei.com",
        "runway_length": "7000",
        "elev": "178",
        "icao": "CYYG",
        "direct_flights": "7",
        "carriers": "6"
    },
    {
        "code": "ZAM",
        "lat": "6.9244",
        "lon": "122.061",
        "name": "Zamboanga",
        "city": "Zamboanga",
        "state": "Zamboanga Peninsula",
        "country": "Philippines",
        "woeid": "12515655",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8560",
        "elev": "30",
        "icao": "",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "ZCL",
        "lat": "22.8972",
        "lon": "-102.685",
        "name": "Zacatecas",
        "city": "Pánuco",
        "state": "Zacatecas",
        "country": "Mexico",
        "woeid": "12514987",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9640",
        "elev": "7021",
        "icao": "MMZC",
        "direct_flights": "7",
        "carriers": "3"
    },
    {
        "code": "ZHA",
        "lat": "21.2151",
        "lon": "110.362",
        "name": "Zhanjiang",
        "city": "Zhanjiang",
        "state": "Guangdong",
        "country": "China",
        "woeid": "12512295",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "7",
        "carriers": "5"
    },
    {
        "code": "CUF",
        "lat": "44.5466",
        "lon": "7.61998",
        "name": "Cuneo",
        "city": "Cuneo",
        "state": "Piedmont",
        "country": "Italy",
        "woeid": "12513834",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "1242",
        "icao": "",
        "direct_flights": "6",
        "carriers": "4",
        "bookingId": "-116669"
    },
    {
        "code": "PMF",
        "lat": "44.8232",
        "lon": "10.2949",
        "name": "Parma",
        "city": "Parma",
        "state": "Emilia Romagna",
        "country": "Italy",
        "woeid": "12513845",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportoparma.it",
        "runway_length": "7546",
        "elev": "164",
        "icao": "LIMP",
        "direct_flights": "6",
        "carriers": "4",
        "bookingId": "-123999"
    },
    {
        "code": "AAE",
        "lat": "36.8236",
        "lon": "7.8103",
        "name": "El Mellah",
        "city": "El Tarf",
        "state": "Annaba",
        "country": "Algeria",
        "woeid": "12510325",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "16",
        "icao": "DABB",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "ACV",
        "lat": "40.9698",
        "lon": "-124.108",
        "name": "Arcata",
        "city": "Mckinleyville",
        "state": "California",
        "country": "United States",
        "woeid": "12518649",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5998",
        "elev": "218",
        "icao": "KACV",
        "direct_flights": "6",
        "carriers": "7"
    },
    {
        "code": "ACY",
        "lat": "39.4512",
        "lon": "-74.5716",
        "name": "Atlantic City",
        "city": "Egg Harbor City",
        "state": "New Jersey",
        "country": "United States",
        "woeid": "12518689",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "76",
        "icao": "KACY",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "AES",
        "lat": "62.5607",
        "lon": "6.11129",
        "name": "Vigra",
        "city": "Vigra",
        "state": "More og Romsdal",
        "country": "Norway",
        "woeid": "12515129",
        "tz": "EuropeOslo",
        "phone": "+47 70 11 48 00",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "71",
        "icao": "ENAL",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "AHB",
        "lat": "18.24",
        "lon": "42.6556",
        "name": "Abha",
        "city": "Hajlah",
        "state": "Asir",
        "country": "Saudi Arabia",
        "woeid": "12517317",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10991",
        "elev": "6857",
        "icao": "OEAB",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "AJF",
        "lat": "29.7897",
        "lon": "40.1039",
        "name": "Al Jouf",
        "city": "Sakakah",
        "state": "Al Jawf",
        "country": "Saudi Arabia",
        "woeid": "12517324",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12008",
        "elev": "2261",
        "icao": "OESK",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "AMI",
        "lat": "-8.562",
        "lon": "116.098",
        "name": "Selaparang",
        "city": "Mataram",
        "state": "Nusa Tenggara Barat",
        "country": "Indonesia",
        "woeid": "12513504",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "48",
        "icao": "WRRA",
        "direct_flights": "6",
        "carriers": "7"
    },
    {
        "code": "ANI",
        "lat": "61.5742",
        "lon": "-159.535",
        "name": "Aniak",
        "city": "Bethel",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524558",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "88",
        "icao": "PANI",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "AOJ",
        "lat": "40.7357",
        "lon": "140.69",
        "name": "Aomori",
        "city": "Aomori-shi",
        "state": "Aomori Prefecture",
        "country": "Japan",
        "woeid": "12523065",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4590",
        "elev": "662",
        "icao": "RJSA",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "AOK",
        "lat": "35.4239",
        "lon": "27.1472",
        "name": "Karpathos",
        "city": "Karpathos",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513293",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "23",
        "icao": "LGKP",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "AZS",
        "lat": "19.2099",
        "lon": "-69.3279",
        "name": "Samana El Catey",
        "city": "Samana",
        "state": "Samana",
        "country": "Dominican Republic",
        "woeid": "76414",
        "tz": "AmericaSanto_Domingo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MDEP",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "BDO",
        "lat": "-6.9017",
        "lon": "107.576",
        "name": "Husein Sastranegara",
        "city": "Bandung",
        "state": "Jawa Barat",
        "country": "Indonesia",
        "woeid": "12513471",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6519",
        "elev": "2430",
        "icao": "WICC",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "BFI",
        "lat": "47.5369",
        "lon": "-122.304",
        "name": "King County-Boeing Field",
        "city": "Seattle",
        "state": "Washington",
        "country": "United States",
        "woeid": "12518877",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10001",
        "elev": "17",
        "icao": "KBFI",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "BGF",
        "lat": "4.3961",
        "lon": "18.5203",
        "name": "Bangui M Poko",
        "city": "Bangui",
        "state": "Bangui",
        "country": "Central African Republic",
        "woeid": "12512446",
        "tz": "AfricaBangui",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "1204",
        "icao": "FEFF",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "BJA",
        "lat": "36.7133",
        "lon": "5.0697",
        "name": "Soummam",
        "city": "Bejaia",
        "state": "Bejaia",
        "country": "Algeria",
        "woeid": "12510347",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "20",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "BJF",
        "lat": "70.6031",
        "lon": "29.6948",
        "name": "Batsfjord",
        "city": "Baasfjord",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523932",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2493",
        "elev": "133",
        "icao": "ENBS",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "BJL",
        "lat": "13.3372",
        "lon": "-16.6544",
        "name": "Yundum",
        "city": "Yundum",
        "state": "Western",
        "country": "Gambia",
        "woeid": "12512990",
        "tz": "AfricaBanjul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11810",
        "elev": "95",
        "icao": "GBYD",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "BJM",
        "lat": "-3.3317",
        "lon": "29.3208",
        "name": "Bujumbura",
        "city": "Bujumbura",
        "state": "Bujumbura",
        "country": "Burundi",
        "woeid": "12511564",
        "tz": "AfricaBujumbura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11810",
        "elev": "2566",
        "icao": "HBBA",
        "direct_flights": "6",
        "carriers": "10"
    },
    {
        "code": "BLA",
        "lat": "10.1103",
        "lon": "-64.6872",
        "name": "Jose Antonio Anzoategui",
        "city": "Pariaguán",
        "state": "Anzoategui",
        "country": "Venezuela",
        "woeid": "12522795",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "26",
        "icao": "SVBC",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "BLZ",
        "lat": "-15.6764",
        "lon": "34.9714",
        "name": "Chileka",
        "city": "Lunzu",
        "state": "Blantyre",
        "country": "Malawi",
        "woeid": "12514765",
        "tz": "AfricaBlantyre",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7710",
        "elev": "2555",
        "icao": "FWCL",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "BTR",
        "lat": "30.533",
        "lon": "-91.1567",
        "name": "Baton Rouge Metropolitan",
        "city": "Baton Rouge",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12518754",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6900",
        "elev": "70",
        "icao": "KBTR",
        "direct_flights": "6",
        "carriers": "11"
    },
    {
        "code": "CAX",
        "lat": "54.9375",
        "lon": "-2.8106",
        "name": "Carlisle",
        "city": "Carlisle",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518055",
        "tz": "EuropeLondon",
        "phone": "+44 (0)1228 573641",
        "type": "Airports",
        "email": "",
        "url": "http:www.carlisleairport.co.uk",
        "runway_length": "6026",
        "elev": "190",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "CBB",
        "lat": "-17.4161",
        "lon": "-66.1744",
        "name": "Jorge Wilsterman",
        "city": "Cochabamba",
        "state": "Cochabamba",
        "country": "Bolivia",
        "woeid": "12510908",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8649",
        "elev": "8360",
        "icao": "SLCB",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "COR",
        "lat": "-31.3131",
        "lon": "-64.1994",
        "name": "Ingeniero Ambrosio L.V. Taravella International ",
        "city": "Cordoba",
        "state": "Cordoba",
        "country": "Argentina",
        "woeid": "12510482",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "1604",
        "icao": "SACO",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "CPQ",
        "lat": "-23",
        "lon": "-47.1333",
        "name": "Campinas",
        "city": "Campinas",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12523513",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3936",
        "elev": "2051",
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "CYO",
        "lat": "21.6169",
        "lon": "-81.5422",
        "name": "Cayo Largo del sur",
        "city": "Cayo Largo Del Sur",
        "state": "Isla de la Juventud",
        "country": "Cuba",
        "woeid": "12512455",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10493",
        "elev": "16",
        "icao": "KCYO",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "DIY",
        "lat": "37.8947",
        "lon": "40.2019",
        "name": "Diyarbakir",
        "city": "Diyaribakir",
        "state": "Diyarbakır",
        "country": "Turkey",
        "woeid": "12517881",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11600",
        "elev": "2251",
        "icao": "LTCC",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "DJJ",
        "lat": "-2.575",
        "lon": "140.514",
        "name": "Sentani",
        "city": "Jayapura",
        "state": "Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513505",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5741",
        "elev": "292",
        "icao": "WAJJ",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "DUD",
        "lat": "-45.9287",
        "lon": "170.199",
        "name": "Dunedin",
        "city": "Outram",
        "state": "Otago",
        "country": "New Zealand",
        "woeid": "12515153",
        "tz": "PacificAuckland",
        "phone": "+64 3 486 2879",
        "type": "Airports",
        "email": "",
        "url": "http:www.dnairport.co.nz",
        "runway_length": "6266",
        "elev": "4",
        "icao": "NZDN",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "EAM",
        "lat": "17.6117",
        "lon": "44.4297",
        "name": "Nejran",
        "city": "Najran",
        "state": "Najran",
        "country": "Saudi Arabia",
        "woeid": "12517355",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "3982",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "ESS",
        "lat": "51.4078",
        "lon": "6.93968",
        "name": "Essen-Mulheim",
        "city": "Mulheim-on-Ruhr",
        "state": "North Rhine-Westphalia",
        "country": "Germany",
        "woeid": "22247850",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5095",
        "elev": "407",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "ETZ",
        "lat": "48.9788",
        "lon": "6.24722",
        "name": "Lorraine",
        "city": "Verny",
        "state": "Lorraine",
        "country": "France",
        "woeid": "12523821",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.metz-nancy-lorraine.aeroport.fr",
        "runway_length": "8200",
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "FEG",
        "lat": "40.3833",
        "lon": "71.7667",
        "name": "Fergana",
        "city": "Fergana",
        "state": "Farghona",
        "country": "Uzbekistan",
        "woeid": "2270088",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "FKS",
        "lat": "37.2314",
        "lon": "140.429",
        "name": "Fukushima",
        "city": "Sukagawa-shi",
        "state": "Fukushima Prefecture",
        "country": "Japan",
        "woeid": "28360523",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KFKS",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "GGT",
        "lat": "23.5611",
        "lon": "-75.8744",
        "name": "Exuma",
        "city": "George Town",
        "state": "Exuma",
        "country": "Bahamas",
        "woeid": "12510866",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "5",
        "icao": "",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "GHA",
        "lat": "32.3828",
        "lon": "3.7964",
        "name": "Noumerate",
        "city": "Ghardaia",
        "state": "Ghardaia",
        "country": "Algeria",
        "woeid": "12510338",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "1512",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "GWY",
        "lat": "53.3016",
        "lon": "-8.94049",
        "name": "Carnmore",
        "city": "Carnmore",
        "state": "",
        "country": "Ireland",
        "woeid": "12512716",
        "tz": "EuropeDublin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1870",
        "elev": "90",
        "icao": "EICM",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "GZO",
        "lat": "-8.0749",
        "lon": "156.832",
        "name": "Nusatupe",
        "city": "Gizo",
        "state": "Western",
        "country": "Solomon Islands",
        "woeid": "12511008",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3202",
        "elev": "13",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "HDF",
        "lat": "53.8788",
        "lon": "14.1383",
        "name": "Heringsdorf",
        "city": "Heringsdorf",
        "state": "Mecklenburg-Vorpommern",
        "country": "Germany",
        "woeid": "12832291",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2",
        "bookingId": "-1863011"
    },
    {
        "code": "HKD",
        "lat": "41.7706",
        "lon": "140.824",
        "name": "Hakodate",
        "city": "Hakodate-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "12523120",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "138",
        "icao": "RJCH",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "HLN",
        "lat": "46.6104",
        "lon": "-111.99",
        "name": "Helena",
        "city": "Helena",
        "state": "Montana",
        "country": "United States",
        "woeid": "12520154",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "3873",
        "icao": "KHLN",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "HTA",
        "lat": "52.1138",
        "lon": "113.498",
        "name": "Chita",
        "city": "Chita",
        "state": "Chitinskaya Oblast",
        "country": "Russia",
        "woeid": "12515913",
        "tz": "AsiaYakutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "2274",
        "icao": "",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "IDA",
        "lat": "43.5147",
        "lon": "-112.068",
        "name": "Fanning Field",
        "city": "Idaho Falls",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12519708",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9001",
        "elev": "4741",
        "icao": "KIDA",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "ILM",
        "lat": "34.2668",
        "lon": "-77.912",
        "name": "Wilmington",
        "city": "Wilmington",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12521109",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flyilm.com",
        "runway_length": "7999",
        "elev": "32",
        "icao": "KILM",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "IMF",
        "lat": "24.7611",
        "lon": "93.8972",
        "name": "Imphal",
        "city": "Lilong (Imphal West)",
        "state": "Manipur",
        "country": "India",
        "woeid": "12513598",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "2542",
        "icao": "",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "JDZ",
        "lat": "29.293",
        "lon": "117.203",
        "name": "Jingde Town",
        "city": "Jingdezhen",
        "state": "Jiangxi",
        "country": "China",
        "woeid": "2133709",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "JKH",
        "lat": "38.345",
        "lon": "26.1417",
        "name": "Chios",
        "city": "Khios",
        "state": "Voreio Aigaio",
        "country": "Greece",
        "woeid": "12513287",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "16",
        "icao": "LGHI",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "JUB",
        "lat": "4.8722",
        "lon": "31.5931",
        "name": "Juba",
        "city": "Juba",
        "state": "Bahr al Jabal",
        "country": "Sudan",
        "woeid": "12517599",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "1509",
        "icao": "",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "KCZ",
        "lat": "33.5468",
        "lon": "133.672",
        "name": "Kochi",
        "city": "Nankoku-shi",
        "state": "Kochi Prefecture",
        "country": "Japan",
        "woeid": "12513978",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6658",
        "elev": "31",
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "KSN",
        "lat": "53.2297",
        "lon": "63.6038",
        "name": "Kustanay",
        "city": "Qostanay",
        "state": "Qostanay",
        "country": "Kazakhstan",
        "woeid": "12514397",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EKSN",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "KSY",
        "lat": "40.5828",
        "lon": "43.0675",
        "name": "Kars (abandoned)",
        "city": "Kars",
        "state": "Kars",
        "country": "Turkey",
        "woeid": "12517901",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "LAE",
        "lat": "-6.5672",
        "lon": "146.725",
        "name": "Nadzab",
        "city": "Lae",
        "state": "Morobe",
        "country": "Papua New Guinea",
        "woeid": "12515476",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5823",
        "elev": "46",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "LDB",
        "lat": "-23.3297",
        "lon": "-51.1361",
        "name": "Londrina",
        "city": "Londrina",
        "state": "Parana",
        "country": "Brazil",
        "woeid": "12511215",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6896",
        "elev": "1867",
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "LEA",
        "lat": "-22.233",
        "lon": "114.092",
        "name": "Learmonth",
        "city": "Learmonth",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510715",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10100",
        "elev": "19",
        "icao": "YPLM",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "LFM",
        "lat": "27.3739",
        "lon": "53.1911",
        "name": "Lamerd",
        "city": "Lamerd",
        "state": "Fars",
        "country": "Iran",
        "woeid": "12513739",
        "tz": "AsiaTehran",
        "phone": "+987825222595",
        "type": "Airports",
        "email": "sohrab_azizy@YAHOO.COM",
        "url": "",
        "runway_length": "3054",
        "elev": "1345",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "LOD",
        "lat": "31.9587",
        "lon": "34.8864",
        "name": "Longana",
        "city": "Longana",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2329",
        "elev": "167",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "LXA",
        "lat": "29.6355",
        "lon": "91.1646",
        "name": "Lhasa",
        "city": "Lhasa",
        "state": "西藏自治区",
        "country": "China",
        "woeid": "12714232",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZULS",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "LYA",
        "lat": "34.6833",
        "lon": "112.467",
        "name": "Luoyang",
        "city": "Luoyang",
        "state": "Henan",
        "country": "China",
        "woeid": "12523313",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "LYP",
        "lat": "31.3692",
        "lon": "72.9981",
        "name": "Faisalabad",
        "city": "Shah Faisalabad",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "12515233",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9300",
        "elev": "607",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "MJF",
        "lat": "65.7833",
        "lon": "13.2167",
        "name": "Kjaerstad",
        "city": "Mosjoen",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523941",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "MQN",
        "lat": "66.3643",
        "lon": "14.3035",
        "name": "Rossvoll",
        "city": "Skonseng",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523942",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2624",
        "elev": "228",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "MUR",
        "lat": "4.175",
        "lon": "114.33",
        "name": "Marudi",
        "city": "Miri",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12515002",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "103",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "MXT",
        "lat": "-27.2167",
        "lon": "-65.1333",
        "name": "Maintirano",
        "city": "Maintirano",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "1362166",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "95",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "NDR",
        "lat": "35.1678",
        "lon": "-2.94411",
        "name": "Nador",
        "city": "Nador",
        "state": "Nador",
        "country": "Morocco",
        "woeid": "24554857",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "20",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "NNY",
        "lat": "32.9959",
        "lon": "112.527",
        "name": "Nanyang",
        "city": "Nanyang",
        "state": "Henan",
        "country": "China",
        "woeid": "2172743",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "NSI",
        "lat": "3.83168",
        "lon": "11.5233",
        "name": "Nsimalen",
        "city": "Yaounde",
        "state": "Centre",
        "country": "Cameroon",
        "woeid": "12523057",
        "tz": "AfricaDouala",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KNSI",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "NVA",
        "lat": "2.93333",
        "lon": "-75.2833",
        "name": "Neiva Lamarguita",
        "city": "Neiva",
        "state": "Huila",
        "country": "Colombia",
        "woeid": "12524502",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "1440",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "OXF",
        "lat": "51.831",
        "lon": "-1.31029",
        "name": "Oxford",
        "city": "Kidlington",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518107",
        "tz": "EuropeLondon",
        "phone": "0044 1865 844 260",
        "type": "Airports",
        "email": "",
        "url": "http:www.oxfordairport.co.uk",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "1",
        "bookingId": "-2604911"
    },
    {
        "code": "PBM",
        "lat": "5.45",
        "lon": "-55.1867",
        "name": "Zandery",
        "city": "Sabakoe",
        "state": "Para",
        "country": "Suriname",
        "woeid": "12515136",
        "tz": "AmericaParamaribo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11417",
        "elev": "54",
        "icao": "SMJP",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "PGF",
        "lat": "42.74",
        "lon": "2.8692",
        "name": "Rivesaltes",
        "city": "Perpignan",
        "state": "Languedoc-Roussillon",
        "country": "France",
        "woeid": "12512957",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "144",
        "icao": "LFMP",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "PKU",
        "lat": "0.4642",
        "lon": "101.439",
        "name": "Simpang Tiga",
        "city": "Pekanbaru",
        "state": "Riau",
        "country": "Indonesia",
        "woeid": "12513507",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "102",
        "icao": "WIBB",
        "direct_flights": "6",
        "carriers": "8"
    },
    {
        "code": "PLH",
        "lat": "50.423",
        "lon": "-4.11154",
        "name": "Roborough",
        "city": "Plymouth",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22483569",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2490",
        "elev": "488",
        "icao": "EGHD",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "PLQ",
        "lat": "55.9661",
        "lon": "21.0825",
        "name": "Palanga International",
        "city": "KlaipedaPalanga",
        "state": "Klaipedos Apskritis",
        "country": "Lithuania",
        "woeid": "477139",
        "tz": "EuropeVilnius",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EYPA",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "PLZ",
        "lat": "-33.9861",
        "lon": "25.6139",
        "name": "H F Verwoerd",
        "city": "Port Elizabeth",
        "state": "Eastern Cape",
        "country": "South Africa",
        "woeid": "12517413",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6529",
        "elev": "225",
        "icao": "FAPE",
        "direct_flights": "6",
        "carriers": "12"
    },
    {
        "code": "PNA",
        "lat": "42.7672",
        "lon": "-1.64017",
        "name": "Pamplona",
        "city": "Noáin",
        "state": "Navarre",
        "country": "Spain",
        "woeid": "12517562",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7350",
        "elev": "1506",
        "icao": "KPNA",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "PUQ",
        "lat": "-53.0033",
        "lon": "-70.8558",
        "name": "Carlos Ibanez de Campo",
        "city": "Punta Arenas",
        "state": "Magallanes y Antartica Chilena",
        "country": "Chile",
        "woeid": "12512310",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9153",
        "elev": "144",
        "icao": "SCCI",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "QDH",
        "lat": "51.08",
        "lon": "0.53",
        "name": "Ashford",
        "city": "Ashford",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "26352342",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "QSF",
        "lat": "36.1914",
        "lon": "5.40944",
        "name": "Setif",
        "city": "Setif",
        "state": "Setif",
        "country": "Algeria",
        "woeid": "1257253",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "RDM",
        "lat": "44.2533",
        "lon": "-121.162",
        "name": "Roberts Field",
        "city": "Redmond",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12521617",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7040",
        "elev": "3077",
        "icao": "KRDM",
        "direct_flights": "6",
        "carriers": "11"
    },
    {
        "code": "RJK",
        "lat": "45.2167",
        "lon": "14.5683",
        "name": "Rijeka",
        "city": "Rijeka",
        "state": "Primorsko-Goranska",
        "country": "Croatia",
        "woeid": "12513371",
        "tz": "EuropeZagreb",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.rijeka-airport.hr",
        "runway_length": "8202",
        "elev": "246",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "RST",
        "lat": "43.9101",
        "lon": "-92.4884",
        "name": "Rochester",
        "city": "Rochester",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12521625",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7533",
        "elev": "1317",
        "icao": "KRST",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "RTW",
        "lat": "51.5617",
        "lon": "46.0419",
        "name": "Saratov",
        "city": "Saratov",
        "state": "Saratovskaya Oblast",
        "country": "Russia",
        "woeid": "12516819",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "SBH",
        "lat": "17.8667",
        "lon": "-62.8333",
        "name": "Gustavia",
        "city": "Gustavia",
        "state": "Saint-Martin et Saint-Barthélé",
        "country": "Guadeloupe",
        "woeid": "12523214",
        "tz": "AmericaGuadeloupe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "49",
        "icao": "",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "SBW",
        "lat": "2.3417",
        "lon": "111.838",
        "name": "Sibu",
        "city": "Sibu",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12515006",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "28",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "SKD",
        "lat": "39.6667",
        "lon": "66.9667",
        "name": "Samarqand",
        "city": "Samarkand",
        "state": "Samarqand",
        "country": "Uzbekistan",
        "woeid": "2272628",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "2201",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "SZZ",
        "lat": "53.5686",
        "lon": "14.8676",
        "name": "Golenow",
        "city": "Goleniów",
        "state": "Zachodniopomorskie",
        "country": "Poland",
        "woeid": "12515303",
        "tz": "EuropeWarsaw",
        "phone": "+48 91 4817400",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport.szczecin.pl",
        "runway_length": "8202",
        "elev": "154",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "TAH",
        "lat": "-19.4867",
        "lon": "169.362",
        "name": "Tanna",
        "city": "Isangel",
        "state": "Tafea",
        "country": "Vanuatu",
        "woeid": "12515054",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "19",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "TMR",
        "lat": "22.8147",
        "lon": "5.4592",
        "name": "Tamanrasset",
        "city": "Tamanrasset",
        "state": "Tamanghasset",
        "country": "Algeria",
        "woeid": "12510350",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "TNO",
        "lat": "10.9333",
        "lon": "-85.4667",
        "name": "Tamarindo",
        "city": "Liberia",
        "state": "Guanacaste",
        "country": "Costa Rica",
        "woeid": "12523332",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "TOY",
        "lat": "36.6495",
        "lon": "137.187",
        "name": "Toyama",
        "city": "Toyama-shi",
        "state": "Toyama Prefecture",
        "country": "Japan",
        "woeid": "2345891",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "TVC",
        "lat": "44.7365",
        "lon": "-85.5796",
        "name": "Cherry Capital",
        "city": "Traverse City",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519167",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.tvcairport.com",
        "runway_length": "6501",
        "elev": "624",
        "icao": "KTVC",
        "direct_flights": "6",
        "carriers": "9"
    },
    {
        "code": "UDI",
        "lat": "-18.8833",
        "lon": "-48.2247",
        "name": "Coronel Aviador Cesar Bombonato",
        "city": "Uberlandia",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511358",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6365",
        "elev": "3094",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "UET",
        "lat": "30.2536",
        "lon": "66.9389",
        "name": "Quetta",
        "city": "",
        "state": "Balochistan",
        "country": "Pakistan",
        "woeid": "12515260",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "5250",
        "icao": "",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "UIB",
        "lat": "5.6903",
        "lon": "-76.6464",
        "name": "El Carano",
        "city": "Quibdó",
        "state": "Choco",
        "country": "Colombia",
        "woeid": "12512373",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3870",
        "elev": "220",
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "UKB",
        "lat": "34.6356",
        "lon": "135.22",
        "name": "Kobe",
        "city": "Kobe-shi",
        "state": "Hyogo Prefecture",
        "country": "Japan",
        "woeid": "12523307",
        "tz": "AsiaTokyo",
        "phone": "078-306-4195",
        "type": "Airports",
        "email": "",
        "url": "http:www.city.kobe.jp",
        "runway_length": "8202",
        "elev": "22",
        "icao": "",
        "direct_flights": "6",
        "carriers": "4"
    },
    {
        "code": "UME",
        "lat": "63.7932",
        "lon": "20.2848",
        "name": "Umea",
        "city": "Umea",
        "state": "Vasterbotten",
        "country": "Sweden",
        "woeid": "12517687",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "22",
        "icao": "ESNU",
        "direct_flights": "6",
        "carriers": "7"
    },
    {
        "code": "USH",
        "lat": "-54.8176",
        "lon": "-68.326",
        "name": "Ushuaia",
        "city": "Ushuaia",
        "state": "Tierra del Fuego",
        "country": "Argentina",
        "woeid": "12510571",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4763",
        "elev": "33",
        "icao": "SAWH",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "USK",
        "lat": "48.3136",
        "lon": "-117.281",
        "name": "Usinsk",
        "city": "Usinsk",
        "state": "Komi",
        "country": "Russia",
        "woeid": "2101023",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "VIX",
        "lat": "-20.2578",
        "lon": "-40.2864",
        "name": "Goiabeiras",
        "city": "Vitoria",
        "state": "Espirito Santo",
        "country": "Brazil",
        "woeid": "12511159",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6138",
        "elev": "11",
        "icao": "SBVT",
        "direct_flights": "6",
        "carriers": "7"
    },
    {
        "code": "VPE",
        "lat": "-17.0704",
        "lon": "15.7258",
        "name": "Ondjiva",
        "city": "Ongiva",
        "state": "Cunene",
        "country": "Angola",
        "woeid": "1263141",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8120",
        "elev": "3641",
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "XQE",
        "lat": "54.3139",
        "lon": "-2.23218",
        "name": "London Ebbsfleet",
        "city": "Londra Ebbsfleet",
        "state": null,
        "country": "United Kingdom",
        "woeid": "23424975",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "1",
        "bookingId": "-2601889"
    },
    {
        "code": "XXK",
        "lat": "59.0167",
        "lon": "16.2167",
        "name": "Katrineholm",
        "city": "Katrineholm",
        "state": "",
        "country": "Sweden",
        "woeid": "895148",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "YCB",
        "lat": "69.0981",
        "lon": "-105.134",
        "name": "Cambridge Bay",
        "city": "Cambridge Bay",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12523271",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "90",
        "icao": "CYCB",
        "direct_flights": "6",
        "carriers": "3"
    },
    {
        "code": "YKA",
        "lat": "50.7054",
        "lon": "-120.442",
        "name": "Kamloops",
        "city": "Kamloops",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511710",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1134",
        "icao": "CYKA",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "YNJ",
        "lat": "42.65",
        "lon": "129.5",
        "name": "Yanji",
        "city": "Yanji",
        "state": "吉林省",
        "country": "China",
        "woeid": "12712349",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "6",
        "carriers": "7"
    },
    {
        "code": "YPL",
        "lat": "51.4503",
        "lon": "-90.2155",
        "name": "Pickle Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511790",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "1267",
        "icao": "CYPL",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "YPX",
        "lat": "59.8141",
        "lon": "-77.2512",
        "name": "Povungnituk",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524203",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2800",
        "elev": "48",
        "icao": "CYPX",
        "direct_flights": "6",
        "carriers": "1"
    },
    {
        "code": "YSB",
        "lat": "46.6229",
        "lon": "-80.7953",
        "name": "Sudbury",
        "city": "Garson",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511859",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6600",
        "elev": "1140",
        "icao": "CYSB",
        "direct_flights": "6",
        "carriers": "2"
    },
    {
        "code": "YXU",
        "lat": "43.0285",
        "lon": "-81.1497",
        "name": "London",
        "city": "London",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511735",
        "tz": "AmericaToronto",
        "phone": "(519) 452-4015",
        "type": "Airports",
        "email": "",
        "url": "http:www.londonairport.on.ca",
        "runway_length": "8800",
        "elev": "912",
        "icao": "CYXU",
        "direct_flights": "6",
        "carriers": "4",
        "bookingId": "-2601889"
    },
    {
        "code": "YXY",
        "lat": "60.7141",
        "lon": "-135.076",
        "name": "Whitehorse",
        "city": "Whitehorse",
        "state": "Yukon Territory",
        "country": "Canada",
        "woeid": "12511913",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9497",
        "elev": "2317",
        "icao": "CYXY",
        "direct_flights": "6",
        "carriers": "5"
    },
    {
        "code": "ZQN",
        "lat": "-45.0217",
        "lon": "168.737",
        "name": "Frankton",
        "city": "Queenstown",
        "state": "Otago",
        "country": "New Zealand",
        "woeid": "12515166",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4400",
        "elev": "1173",
        "icao": "NZQN",
        "direct_flights": "6",
        "carriers": "6"
    },
    {
        "code": "FOG",
        "lat": "41.4341",
        "lon": "15.5438",
        "name": "Gino Lisa",
        "city": "Foggia",
        "state": "Puglia",
        "country": "Italy",
        "woeid": "12513823",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "308",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2",
        "bookingId": "-117638"
    },
    {
        "code": "PEG",
        "lat": "43.1021",
        "lon": "12.5075",
        "name": "Perugia",
        "city": "Perugia",
        "state": "Umbria",
        "country": "Italy",
        "woeid": "12513847",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5020",
        "elev": "686",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3",
        "bookingId": "-124324"
    },
    {
        "code": "ABD",
        "lat": "30.3374",
        "lon": "48.3032",
        "name": "Abadan",
        "city": "Abadan",
        "state": "Khuzestan",
        "country": "Iran",
        "woeid": "2254271",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10170",
        "elev": "10",
        "icao": "OIAA",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "AEX",
        "lat": "31.323",
        "lon": "-92.5417",
        "name": "Alexandria",
        "city": "Alexandria",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12519648",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KAEX",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "AJN",
        "lat": "-12.2234",
        "lon": "44.3709",
        "name": "Comoros",
        "city": "Anjouan",
        "state": "Anjouan",
        "country": "Comoros",
        "woeid": "23424786",
        "tz": "IndianComoro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4429",
        "elev": "62",
        "icao": "FMCV",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "AJU",
        "lat": "-10.985",
        "lon": "-37.0733",
        "name": "Santa Maria",
        "city": "Aracaju",
        "state": "Sergipe",
        "country": "Brazil",
        "woeid": "12511305",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5372",
        "elev": "26",
        "icao": "SBAR",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "AMQ",
        "lat": "-3.7067",
        "lon": "128.078",
        "name": "Pattimura",
        "city": "Ambon",
        "state": "Maluku",
        "country": "Indonesia",
        "woeid": "12513492",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "33",
        "icao": "WAPP",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "APL",
        "lat": "-15.1",
        "lon": "39.2814",
        "name": "Nampula",
        "city": "Nampula",
        "state": "Nampula",
        "country": "Mozambique",
        "woeid": "12515027",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1457",
        "icao": "FQNP",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "ARW",
        "lat": "46.1805",
        "lon": "21.2609",
        "name": "Ceala",
        "city": "Arad",
        "state": "Arad",
        "country": "Romania",
        "woeid": "12515518",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportularad.roindex_en.html",
        "runway_length": "6562",
        "elev": "351",
        "icao": "LRAR",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "ASE",
        "lat": "39.2197",
        "lon": "-106.864",
        "name": "Aspen Pitkin County-Sardy Field",
        "city": "Aspen",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12518679",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7003",
        "elev": "7815",
        "icao": "KASE",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "ASW",
        "lat": "23.965",
        "lon": "32.8217",
        "name": "Aswan",
        "city": "Aswan",
        "state": "Aswan",
        "country": "Egypt",
        "woeid": "12512666",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11155",
        "elev": "656",
        "icao": "HESN",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "ATZ",
        "lat": "27.0411",
        "lon": "31.0114",
        "name": "Asyut",
        "city": "Asyut",
        "state": "Asyut",
        "country": "Egypt",
        "woeid": "12512667",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "774",
        "icao": "HEAT",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "AXA",
        "lat": "18.2103",
        "lon": "-63.0575",
        "name": "Wallblake",
        "city": "Anguilla",
        "state": "The Valley",
        "country": "Anguilla",
        "woeid": "12510830",
        "tz": "AmericaAnguilla",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "108",
        "icao": "TQPF",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "AYQ",
        "lat": "-25.1872",
        "lon": "130.977",
        "name": "Ayers Rock",
        "city": "Ayers Rock",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510821",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "1626",
        "icao": "YAYE",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "AZO",
        "lat": "42.2398",
        "lon": "-85.5563",
        "name": "Kalamazoo-Battle Creek",
        "city": "Kalamazoo",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520414",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6499",
        "elev": "874",
        "icao": "KAZO",
        "direct_flights": "5",
        "carriers": "12"
    },
    {
        "code": "BBI",
        "lat": "20.2466",
        "lon": "85.8152",
        "name": "Bhubaneswar",
        "city": "Bhubaneswar",
        "state": "Orissa",
        "country": "India",
        "woeid": "12513553",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5850",
        "elev": "146",
        "icao": "VEBS",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "BFN",
        "lat": "-29.0942",
        "lon": "26.3027",
        "name": "J B M Hertzog",
        "city": "Bloemfontein",
        "state": "Free State",
        "country": "South Africa",
        "woeid": "12517423",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "FABL",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "BHY",
        "lat": "21.4833",
        "lon": "109.083",
        "name": "Beihai",
        "city": "Beihai",
        "state": "Guangxi",
        "country": "China",
        "woeid": "2166477",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "BIS",
        "lat": "46.7745",
        "lon": "-100.757",
        "name": "Bismarck Municipal",
        "city": "Bismarck",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12518843",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8788",
        "elev": "1677",
        "icao": "KBIS",
        "direct_flights": "5",
        "carriers": "8"
    },
    {
        "code": "BRO",
        "lat": "25.9064",
        "lon": "-97.4321",
        "name": "Brownsville-South Padre Island International Air",
        "city": "Brownsville",
        "state": "Texas",
        "country": "United States",
        "woeid": "12518951",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7400",
        "elev": "22",
        "icao": "KBRO",
        "direct_flights": "5",
        "carriers": "9"
    },
    {
        "code": "BTU",
        "lat": "3.1728",
        "lon": "113.043",
        "name": "Bintulu",
        "city": "Bintulu",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12514990",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "6",
        "icao": "WBGB",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "BVG",
        "lat": "70.8702",
        "lon": "29.0294",
        "name": "Berlevag",
        "city": "Berlevaag",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523927",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "43",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "CBG",
        "lat": "52.2069",
        "lon": "0.17355",
        "name": "Cambridge",
        "city": "Cambridge",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22458071",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6446",
        "elev": "50",
        "icao": "EGSC",
        "direct_flights": "5",
        "carriers": "1",
        "bookingId": "-2591658"
    },
    {
        "code": "CEN",
        "lat": "27.3925",
        "lon": "-109.832",
        "name": "Ciudad Obregon",
        "city": "Ciudad Obregón",
        "state": "Sonora",
        "country": "Mexico",
        "woeid": "12514849",
        "tz": "AmericaHermosillo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "243",
        "icao": "MMCN",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "CGD",
        "lat": "28.9196",
        "lon": "111.63",
        "name": "Changde",
        "city": "Changde",
        "state": "Hunan",
        "country": "China",
        "woeid": "12511995",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "CIH",
        "lat": "36.1833",
        "lon": "113.133",
        "name": "Changzhi",
        "city": "Changzhi",
        "state": "Shanxi",
        "country": "China",
        "woeid": "2154549",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "CIT",
        "lat": "39.89",
        "lon": "-78.0166",
        "name": "Shimkent",
        "city": "Shimkent",
        "state": "",
        "country": "Kazakhstan",
        "woeid": "23424871",
        "tz": "AsiaQyzylorda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "CJC",
        "lat": "-22.4944",
        "lon": "-68.9017",
        "name": "El Loa",
        "city": "Calama",
        "state": "Antofagasta",
        "country": "Chile",
        "woeid": "12512320",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9468",
        "elev": "7606",
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "CJJ",
        "lat": "36.7131",
        "lon": "127.509",
        "name": "Cheongju",
        "city": "Naesu-Eup",
        "state": "Chungcheongbuk-Do",
        "country": "South Korea",
        "woeid": "12514203",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCJJ",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "CPO",
        "lat": "-27.2933",
        "lon": "-70.4122",
        "name": "Chamonate",
        "city": "Copiapó",
        "state": "Atacama",
        "country": "Chile",
        "woeid": "12512314",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "954",
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "CRP",
        "lat": "27.7747",
        "lon": "-97.5019",
        "name": "Corpus Christi",
        "city": "Corpus Christi",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519324",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7508",
        "elev": "44",
        "icao": "KCRP",
        "direct_flights": "5",
        "carriers": "12"
    },
    {
        "code": "CUZ",
        "lat": "-13.5308",
        "lon": "-71.9472",
        "name": "Velazco Astete",
        "city": "San Sebastián",
        "state": "Cusco",
        "country": "Peru",
        "woeid": "12515222",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11316",
        "elev": "10656",
        "icao": "SPZO",
        "direct_flights": "5",
        "carriers": "10"
    },
    {
        "code": "DAU",
        "lat": "-9.0878",
        "lon": "143.195",
        "name": "Daru",
        "city": "Daru",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "12515464",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "20",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "DBO",
        "lat": "-32.2183",
        "lon": "148.574",
        "name": "Dubbo",
        "city": "Dubbo",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510657",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5604",
        "elev": "935",
        "icao": "YSDU",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "DIR",
        "lat": "9.6244",
        "lon": "41.8536",
        "name": "Aba Tenna Dejazmatch Yilma",
        "city": "Dire Dawa",
        "state": "Ogaden",
        "country": "Ethiopia",
        "woeid": "12512756",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "3812",
        "icao": "HADR",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "DJG",
        "lat": "24.2625",
        "lon": "9.4428",
        "name": "Tiska",
        "city": "Illizi",
        "state": "Illizi",
        "country": "Algeria",
        "woeid": "12510354",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "3291",
        "icao": "DAAJ",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "DNH",
        "lat": "40.2",
        "lon": "94.6833",
        "name": "Dunhuang",
        "city": "Dunhuang",
        "state": "Gansu",
        "country": "China",
        "woeid": "2145159",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "DNR",
        "lat": "48.589",
        "lon": "-2.07757",
        "name": "Pleurtuit",
        "city": "Pleurtuit",
        "state": "Brittany",
        "country": "France",
        "woeid": "12523809",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "217",
        "icao": "LFRD",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "EIS",
        "lat": "18.4439",
        "lon": "-64.5428",
        "name": "Beef Island-Roadtown",
        "city": "Beef Island",
        "state": "",
        "country": "British Virgin Islands",
        "woeid": "12522860",
        "tz": "AmericaTortola",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "TUPJ",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "ELH",
        "lat": "25.4747",
        "lon": "-76.685",
        "name": "North Eleuthera",
        "city": "The Bluff",
        "state": "North Eleuthera",
        "country": "Bahamas",
        "woeid": "12510876",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "5",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "EMK",
        "lat": "62.785",
        "lon": "-164.491",
        "name": "Emmonak",
        "city": "Alakanuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519644",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "10",
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "FRD",
        "lat": "48.5252",
        "lon": "-123.027",
        "name": "Friday Harbor",
        "city": "Friday Harbor",
        "state": "Washington",
        "country": "United States",
        "woeid": "12519828",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "120",
        "icao": "KFHR",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "FTE",
        "lat": "-50.3333",
        "lon": "-72.25",
        "name": "El Calafate",
        "city": "El Calafate",
        "state": "Santa Cruz",
        "country": "Argentina",
        "woeid": "24554862",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "FYU",
        "lat": "66.5714",
        "lon": "-145.25",
        "name": "Fort Yukon",
        "city": "Fort Yukon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519788",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5809",
        "elev": "433",
        "icao": "PFYU",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "GBD",
        "lat": "38.352",
        "lon": "-98.853",
        "name": "Great Bend Municipal",
        "city": "Great Bend",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12519973",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7999",
        "elev": "1891",
        "icao": "KGBD",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "GBE",
        "lat": "-24.5558",
        "lon": "25.9189",
        "name": "Sir Seretse Khama",
        "city": "Tlokweng",
        "state": "South-East",
        "country": "Botswana",
        "woeid": "12510841",
        "tz": "AfricaGaborone",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9850",
        "elev": "3299",
        "icao": "FBSK",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "GUC",
        "lat": "38.5356",
        "lon": "-106.941",
        "name": "Gunnison County",
        "city": "Gunnison",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12520039",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7198",
        "elev": "7668",
        "icao": "KGUC",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "HAD",
        "lat": "56.6819",
        "lon": "12.8164",
        "name": "Halmstad",
        "city": "Hamstad",
        "state": "Halland",
        "country": "Sweden",
        "woeid": "12517635",
        "tz": "EuropeStockholm",
        "phone": "+46 (0) 035-18 26 00",
        "type": "Airports",
        "email": "",
        "url": "http:www.halmstadsflygplats.se",
        "runway_length": "7546",
        "elev": "101",
        "icao": "KHAD",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "HAS",
        "lat": "27.4433",
        "lon": "41.6872",
        "name": "Hail",
        "city": "Ha'il",
        "state": "Hail",
        "country": "Saudi Arabia",
        "woeid": "12517339",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "3331",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "HBE",
        "lat": "30.9183",
        "lon": "29.6914",
        "name": "Borg El Arab",
        "city": "Alexandria",
        "state": "Al Iskandariyah",
        "country": "Egypt",
        "woeid": "12512663",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "HOI",
        "lat": "-18.0753",
        "lon": "-140.946",
        "name": "Hao",
        "city": "Papeete",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "12512822",
        "tz": "PacificGambier",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11089",
        "elev": "10",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "HOR",
        "lat": "38.52",
        "lon": "-28.7172",
        "name": "Horta",
        "city": "Horta",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515445",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "115",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "HSL",
        "lat": "65.7024",
        "lon": "-156.387",
        "name": "Alaska",
        "city": "Huslia",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "180",
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "HSN",
        "lat": "29.935",
        "lon": "122.359",
        "name": "Zhoushan",
        "city": "Shenjiamen Town",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "2130999",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "HTI",
        "lat": "-20.3563",
        "lon": "148.95",
        "name": "Hamilton Island",
        "city": "Hamilton Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510686",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5984",
        "elev": "15",
        "icao": "YBHM",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "HTS",
        "lat": "38.37",
        "lon": "-82.5542",
        "name": "Tri State Walker Long Field",
        "city": "Huntington",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12522198",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6509",
        "elev": "828",
        "icao": "KHTS",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "ILI",
        "lat": "59.7555",
        "lon": "-154.918",
        "name": "Iliamna",
        "city": "Iliamna",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29387741",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "207",
        "icao": "PAIL",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "ITH",
        "lat": "42.4898",
        "lon": "-76.4627",
        "name": "Tompkins County",
        "city": "Ithaca",
        "state": "New York",
        "country": "United States",
        "woeid": "12522159",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5801",
        "elev": "1099",
        "icao": "KITH",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "IXR",
        "lat": "23.3066",
        "lon": "85.3353",
        "name": "Birsa Munda",
        "city": "Ranchi",
        "state": "Jharkhand",
        "country": "India",
        "woeid": "12513662",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8900",
        "elev": "2147",
        "icao": "VERC",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "IXS",
        "lat": "24.9667",
        "lon": "92.5167",
        "name": "Kumbhirgram",
        "city": "Tarapur",
        "state": "Assam",
        "country": "India",
        "woeid": "12513625",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5856",
        "elev": "353",
        "icao": "VEKU",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "JDH",
        "lat": "26.2564",
        "lon": "73.0546",
        "name": "Jodhpur",
        "city": "Jodhpur",
        "state": "Rajasthan",
        "country": "India",
        "woeid": "12513611",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "717",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "JHS",
        "lat": "66.9273",
        "lon": "-53.6754",
        "name": "Holsteinsborg",
        "city": "Sisimiut",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523843",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "KER",
        "lat": "30.2633",
        "lon": "56.9583",
        "name": "Kerman",
        "city": "Kerman",
        "state": "Kerman",
        "country": "Iran",
        "woeid": "12513735",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12614",
        "elev": "5739",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "KIR",
        "lat": "52.1767",
        "lon": "-9.5333",
        "name": "Kerry County",
        "city": "Farranfore",
        "state": "",
        "country": "Ireland",
        "woeid": "12512722",
        "tz": "EuropeDublin",
        "phone": "++353 (0) 66 9764644",
        "type": "Airports",
        "email": "",
        "url": "http:www.kerryairport.ie",
        "runway_length": "2100",
        "elev": "77",
        "icao": "EIKY",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "KNH",
        "lat": "24.4583",
        "lon": "118.376",
        "name": "Kinmen County",
        "city": "Kinmen",
        "state": "Fujian",
        "country": "Taiwan",
        "woeid": "28760735",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9845",
        "elev": "30",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "KOI",
        "lat": "58.9549",
        "lon": "-2.9026",
        "name": "Kirkwall",
        "city": "Kirkwall",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22471196",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4698",
        "elev": "69",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "KOT",
        "lat": "63.0328",
        "lon": "-163.554",
        "name": "Kotlik",
        "city": "Kotlik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2433856",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2236",
        "elev": "5",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "KOW",
        "lat": "25.8224",
        "lon": "114.907",
        "name": "Ganzhou",
        "city": "Ganzhou",
        "state": "Jiangxi",
        "country": "China",
        "woeid": "12512054",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "KRN",
        "lat": "67.8239",
        "lon": "20.3389",
        "name": "Kiruna",
        "city": "Kiruna",
        "state": "Norrbotten",
        "country": "Sweden",
        "woeid": "12517650",
        "tz": "EuropeStockholm",
        "phone": "+46 (0)980 121 90",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.setemplatesLFV_AirportStartPage____2187.aspx",
        "runway_length": "6562",
        "elev": "1507",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "KSM",
        "lat": "62.0503",
        "lon": "-163.179",
        "name": "Alaska",
        "city": "Saint Marys",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "311",
        "icao": "PASM",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "KUN",
        "lat": "54.9863",
        "lon": "24.0512",
        "name": "Karmilava",
        "city": "Kovno",
        "state": "Kauno apskritis",
        "country": "Lithuania",
        "woeid": "12514587",
        "tz": "EuropeVilnius",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "KUS",
        "lat": "65.5667",
        "lon": "-37.1167",
        "name": "Kulusuk",
        "city": "Kulusuk",
        "state": "Ostgronland",
        "country": "Greenland",
        "woeid": "12523846",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3904",
        "elev": "112",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "KXK",
        "lat": "50.4029",
        "lon": "136.946",
        "name": "Komsomolsk South",
        "city": "Komsomol'sk-na-Amure",
        "state": "Khabarovskiy Kray",
        "country": "Russia",
        "woeid": "12516213",
        "tz": "AsiaSakhalin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "LAN",
        "lat": "42.7728",
        "lon": "-84.5888",
        "name": "Lansing Capital City",
        "city": "Lansing",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519060",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7251",
        "elev": "860",
        "icao": "KLAN",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "LFT",
        "lat": "30.2084",
        "lon": "-91.9935",
        "name": "Lafayette",
        "city": "Lafayette",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12520518",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7651",
        "elev": "42",
        "icao": "KLFT",
        "direct_flights": "5",
        "carriers": "9"
    },
    {
        "code": "LJG",
        "lat": "37.8167",
        "lon": "118.017",
        "name": "Lijiang",
        "city": "Lijiang City",
        "state": "山东省",
        "country": "China",
        "woeid": "12712671",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2066",
        "elev": "0",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "LNK",
        "lat": "40.8461",
        "lon": "-96.7543",
        "name": "Lincoln Municipal",
        "city": "Lincoln",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12520655",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12901",
        "elev": "1214",
        "icao": "KLNK",
        "direct_flights": "5",
        "carriers": "9"
    },
    {
        "code": "LSI",
        "lat": "59.877",
        "lon": "-1.29733",
        "name": "Sumburgh",
        "city": "Shetland",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22472492",
        "tz": "EuropeLondon",
        "phone": "+44(0) 1950 461000",
        "type": "Airports",
        "email": "",
        "url": "http:www.hial.co.uksumburgh-airport.html",
        "runway_length": "4678",
        "elev": "18",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "LTK",
        "lat": "35.4028",
        "lon": "35.95",
        "name": "Latakia",
        "city": "Djeble",
        "state": "Al Ladhiqiyah",
        "country": "Syria",
        "woeid": "12517705",
        "tz": "AsiaDamascus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "157",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "LUG",
        "lat": "46.0013",
        "lon": "8.90909",
        "name": "Lugano",
        "city": "Agno",
        "state": "Canton of Ticino",
        "country": "Switzerland",
        "woeid": "23354864",
        "tz": "EuropeZurich",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "915",
        "icao": "KLUG",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "MCZ",
        "lat": "-9.5117",
        "lon": "-35.8",
        "name": "Zumbi dos Palmares",
        "city": "Maceio",
        "state": "Alagoas",
        "country": "Brazil",
        "woeid": "12511069",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "377",
        "icao": "KMCZ",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "MEH",
        "lat": "71.0333",
        "lon": "27.8333",
        "name": "Mehamn",
        "city": "Mehavn",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523940",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "41",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "MHK",
        "lat": "39.1368",
        "lon": "-96.6699",
        "name": "Manhattan Municipal",
        "city": "Manhattan",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12520775",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "1056",
        "icao": "KMHK",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "MHR",
        "lat": "38.5646",
        "lon": "-121.297",
        "name": "Mather",
        "city": "Mather",
        "state": "California",
        "country": "United States",
        "woeid": "12521692",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMHR",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "MJK",
        "lat": "-25.8881",
        "lon": "113.578",
        "name": "Shark Bay",
        "city": "Monkey Mia",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12523287",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "MKP",
        "lat": "-16.5978",
        "lon": "-143.662",
        "name": "Makemo",
        "city": "Makemo",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "12512824",
        "tz": "PacificGambier",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "3",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "MKY",
        "lat": "-21.1703",
        "lon": "149.183",
        "name": "Mackay",
        "city": "Mackay",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510724",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "19",
        "icao": "YBMK",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "MMB",
        "lat": "43.8814",
        "lon": "144.163",
        "name": "Memanbetsu",
        "city": "Ozora-cho",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "12523175",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "122",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "MOQ",
        "lat": "-20.2836",
        "lon": "44.3183",
        "name": "Morondava",
        "city": "Morondava",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "12514705",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "23",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "MPH",
        "lat": "11.9",
        "lon": "121.917",
        "name": "Malay",
        "city": "Caticlan",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "23424934",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "7",
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "MQT",
        "lat": "46.3501",
        "lon": "-87.3871",
        "name": "Sawyer",
        "city": "Marquette",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520411",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "1419",
        "icao": "KSAW",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "MUX",
        "lat": "30.2028",
        "lon": "71.4181",
        "name": "Multan",
        "city": "Multan",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "12515251",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "400",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "MXL",
        "lat": "32.6308",
        "lon": "-115.242",
        "name": "Gen Rodolfo Sanchez T",
        "city": "Mexicali",
        "state": "Baja California",
        "country": "Mexico",
        "woeid": "12514878",
        "tz": "AmericaTijuana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "72",
        "icao": "MMML",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "MYD",
        "lat": "-3.2261",
        "lon": "40.1006",
        "name": "Malindi",
        "city": "Mombasa",
        "state": "Coast",
        "country": "Kenya",
        "woeid": "12514070",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "80",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "MZG",
        "lat": "23.5631",
        "lon": "119.634",
        "name": "Magong",
        "city": "Makung City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517945",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "69",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "NCU",
        "lat": "42.4833",
        "lon": "59.6333",
        "name": "Nukus",
        "city": "Nukus",
        "state": "Qoraqalpoghiston",
        "country": "Uzbekistan",
        "woeid": "2272619",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "NEV",
        "lat": "17.2",
        "lon": "-62.6",
        "name": "Newcastle",
        "city": "Nevis",
        "state": "Saint James Windward",
        "country": "Saint Kitts and Nevis",
        "woeid": "12523186",
        "tz": "AmericaSt_Kitts",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "20",
        "icao": "TKPN",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "NLA",
        "lat": "-12.9958",
        "lon": "28.6631",
        "name": "Ndola",
        "city": "Ndola",
        "state": "Copperbelt",
        "country": "Zambia",
        "woeid": "12523017",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8250",
        "elev": "4167",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "NLK",
        "lat": "-29.0363",
        "lon": "168.032",
        "name": "Norfolk Island",
        "city": "Kingston",
        "state": "",
        "country": "Norfolk Island",
        "woeid": "12515042",
        "tz": "PacificNorfolk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6400",
        "elev": "371",
        "icao": "YNSF",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "NNM",
        "lat": "67.6167",
        "lon": "53.15",
        "name": "Naryan-Mar",
        "city": "Naryan-Mar",
        "state": "Nenetskiy Avtonomnyy Okrug",
        "country": "Russia",
        "woeid": "12598021",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "NOJ",
        "lat": "63.5",
        "lon": "75",
        "name": "Nojabrxsk",
        "city": "Nojabrxsk",
        "state": "",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "NOV",
        "lat": "-12.8064",
        "lon": "15.7572",
        "name": "Huambo",
        "city": "Huambo",
        "state": "Huambo",
        "country": "Angola",
        "woeid": "12510435",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8727",
        "elev": "5584",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "NSN",
        "lat": "-41.2961",
        "lon": "173.22",
        "name": "Nelson",
        "city": "Nelson",
        "state": "Nelson",
        "country": "New Zealand",
        "woeid": "12515160",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "17",
        "icao": "NZNS",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "NUS",
        "lat": "45.7396",
        "lon": "7.46666",
        "name": "Norsup",
        "city": "Norsup",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3116",
        "elev": "23",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "NVI",
        "lat": "40.1155",
        "lon": "65.19",
        "name": "Navoi",
        "city": "Navoi",
        "state": "Nawoiy",
        "country": "Uzbekistan",
        "woeid": "12522688",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "OHD",
        "lat": "41.1806",
        "lon": "20.7431",
        "name": "Ohrid",
        "city": "Ohrid",
        "state": "Debarca",
        "country": "Macedonia",
        "woeid": "12514771",
        "tz": "EuropeSkopje",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "2313",
        "icao": "LWOH",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "OKD",
        "lat": "43.1162",
        "lon": "141.382",
        "name": "Okadama",
        "city": "Sapporo-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "23388309",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "26",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "OSD",
        "lat": "63.1972",
        "lon": "14.4933",
        "name": "Ostersunds",
        "city": "Frösö",
        "state": "Jamtland",
        "country": "Sweden",
        "woeid": "12517626",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "1233",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "OSS",
        "lat": "40.6155",
        "lon": "72.7853",
        "name": "Osh",
        "city": "Osh",
        "state": "Osh",
        "country": "Kyrgyzstan",
        "woeid": "12514111",
        "tz": "AsiaBishkek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "PBC",
        "lat": "19.1433",
        "lon": "-98.3714",
        "name": "Puebla",
        "city": "Tlaltenango",
        "state": "Puebla",
        "country": "Mexico",
        "woeid": "12514937",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "7118",
        "icao": "MMPB",
        "direct_flights": "5",
        "carriers": "8"
    },
    {
        "code": "PDG",
        "lat": "-0.8794",
        "lon": "100.352",
        "name": "Tabing",
        "city": "Padang",
        "state": "Sumatera Barat",
        "country": "Indonesia",
        "woeid": "12513515",
        "tz": "AsiaJakarta",
        "phone": "+62 (0)751 819123",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7152",
        "elev": "16",
        "icao": "WIPT",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "PLM",
        "lat": "-2.9003",
        "lon": "104.698",
        "name": "Sultan Mahmud Badaruddin Ii",
        "city": "Palembang",
        "state": "Sumatera Selatan",
        "country": "Indonesia",
        "woeid": "12513509",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7216",
        "elev": "37",
        "icao": "WIPP",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "PMC",
        "lat": "-41.4319",
        "lon": "-73.0928",
        "name": "El Tepual",
        "city": "Los Quemas",
        "state": "Los Lagos",
        "country": "Chile",
        "woeid": "12512322",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8694",
        "elev": "296",
        "icao": "SCTE",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "PMR",
        "lat": "-40.3227",
        "lon": "175.611",
        "name": "Palmerston North",
        "city": "Palmerston North",
        "state": "Manawatu Wanganui",
        "country": "New Zealand",
        "woeid": "12515164",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.pnairport.co.nz",
        "runway_length": "1",
        "elev": "148",
        "icao": "NZPM",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "POL",
        "lat": "-12.9883",
        "lon": "40.5225",
        "name": "Pemba",
        "city": "Pemba",
        "state": "Cabo Delgado",
        "country": "Mozambique",
        "woeid": "12515028",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "338",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "PUU",
        "lat": "0.5061",
        "lon": "-76.5022",
        "name": "Puerto Asis",
        "city": "Puerto Asís",
        "state": "Putumayo",
        "country": "Colombia",
        "woeid": "12512405",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5248",
        "elev": "834",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "RAB",
        "lat": "-4.2272",
        "lon": "152.184",
        "name": "Rabaul",
        "city": "Rabaul",
        "state": "East New Britain",
        "country": "Papua New Guinea",
        "woeid": "12515478",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5210",
        "elev": "29",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "RBA",
        "lat": "34.0475",
        "lon": "-6.7578",
        "name": "Sale",
        "city": "Rabat",
        "state": "Rabat-Sale",
        "country": "Morocco",
        "woeid": "12514791",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "276",
        "icao": "GMME",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "RDZ",
        "lat": "44.41",
        "lon": "2.48254",
        "name": "Marcillac",
        "city": "Marcillac",
        "state": "Midi-Pyrenees",
        "country": "France",
        "woeid": "12512930",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "1906",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "RGL",
        "lat": "-51.6092",
        "lon": "-69.3131",
        "name": "Rio Gallegos",
        "city": "Río Gallegos",
        "state": "Santa Cruz",
        "country": "Argentina",
        "woeid": "12510544",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11647",
        "elev": "59",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "RJL",
        "lat": "42.4563",
        "lon": "-2.31476",
        "name": "Agoncillo",
        "city": "Logrono",
        "state": "La Rioja",
        "country": "Spain",
        "woeid": "12578023",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "RMQ",
        "lat": "23.5998",
        "lon": "121.024",
        "name": "Taiwan",
        "city": "Taichung",
        "state": null,
        "country": "Taiwan",
        "woeid": "23424971",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "ROK",
        "lat": "-23.3768",
        "lon": "150.474",
        "name": "Rockhampton",
        "city": "Rockhampton",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510773",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "34",
        "icao": "KBRK",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "SDD",
        "lat": "-14.9244",
        "lon": "13.5767",
        "name": "Lubango",
        "city": "Lubango",
        "state": "Huila",
        "country": "Angola",
        "woeid": "12510440",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9200",
        "elev": "5740",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "SDK",
        "lat": "5.9017",
        "lon": "118.053",
        "name": "Sandakan",
        "city": "Sandakan",
        "state": "Sabah",
        "country": "Malaysia",
        "woeid": "12515005",
        "tz": "AsiaKuala_Lumpur",
        "phone": "+60 (0)89 660405",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "46",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "SFA",
        "lat": "34.7181",
        "lon": "10.6917",
        "name": "El Maou",
        "city": "Safaqis",
        "state": "Safaqis",
        "country": "Tunisia",
        "woeid": "12517857",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "82",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "SHW",
        "lat": "17.4686",
        "lon": "47.1242",
        "name": "Sharurah",
        "city": "As-Saraura",
        "state": "Najran",
        "country": "Saudi Arabia",
        "woeid": "12517369",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11975",
        "elev": "2363",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "SLM",
        "lat": "40.9465",
        "lon": "-5.50744",
        "name": "Salamanca",
        "city": "Villagonzalo de Tormes",
        "state": "Castille and Leon",
        "country": "Spain",
        "woeid": "12517566",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "2602",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "SLN",
        "lat": "38.7786",
        "lon": "-97.6397",
        "name": "Salina Municipal",
        "city": "Salina",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12521703",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13332",
        "elev": "1272",
        "icao": "KSLN",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "SLZ",
        "lat": "-2.5867",
        "lon": "-44.2358",
        "name": "Marechal Cunha Machado",
        "city": "Salvador",
        "state": "Nordeste",
        "country": "Brazil",
        "woeid": "12511227",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7710",
        "elev": "177",
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "SOB",
        "lat": "46.6998",
        "lon": "17.1494",
        "name": "Sarmellek",
        "city": "Zalavár",
        "state": "Zala",
        "country": "Hungary",
        "woeid": "12513427",
        "tz": "EuropeBudapest",
        "phone": "+36 83 355 500",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "407",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "SOG",
        "lat": "61.1566",
        "lon": "7.13623",
        "name": "Haukasen",
        "city": "Kaupanger",
        "state": "Sogn og Fjordane Fylke",
        "country": "Norway",
        "woeid": "12523948",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "1632",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "SOQ",
        "lat": "-0.9326",
        "lon": "131.12",
        "name": "Jefman",
        "city": "Sorong",
        "state": "West Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513476",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "10",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "SSJ",
        "lat": "65.9612",
        "lon": "12.474",
        "name": "Stokka",
        "city": "Sandnessjoen",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523951",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2516",
        "elev": "54",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "SYY",
        "lat": "58.2119",
        "lon": "-6.32319",
        "name": "Stornoway",
        "city": "Isle of lewis",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22472653",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.hial.co.ukstornoway-airport.html",
        "runway_length": "7214",
        "elev": "30",
        "icao": "EGPO",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "SZF",
        "lat": "41.1959",
        "lon": "36.7248",
        "name": "Çarşamba",
        "city": "Samsun",
        "state": "Samsun",
        "country": "Turkey",
        "woeid": "2343864",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "TAL",
        "lat": "47.7184",
        "lon": "-115.431",
        "name": "Ralph Calhoun",
        "city": "Tanana",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2503916",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATA",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "THF",
        "lat": "52.4825",
        "lon": "13.3892",
        "name": "Berlin Tempelhof Central",
        "city": "Berlin Tempelhof Central",
        "state": "Berlin",
        "country": "Germany",
        "woeid": "22164089",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6942",
        "elev": "164",
        "icao": "EDDI",
        "direct_flights": "5",
        "carriers": "7"
    },
    {
        "code": "TLE",
        "lat": "-23.3864",
        "lon": "43.7264",
        "name": "Toliara",
        "city": "Toliara",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "12514710",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "TLM",
        "lat": "35.0139",
        "lon": "-1.4572",
        "name": "Zenata",
        "city": "Tlemcen",
        "state": "Tlemcen",
        "country": "Algeria",
        "woeid": "12510357",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "TMS",
        "lat": "0.3783",
        "lon": "6.725",
        "name": "Sao Tome Salazar",
        "city": "Sao Tome",
        "state": "Sao Tome",
        "country": "Sao Tome and Principe",
        "woeid": "12517853",
        "tz": "AfricaSao_Tome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7284",
        "elev": "33",
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "TMX",
        "lat": "27.8676",
        "lon": "-0.28984",
        "name": "Adrar",
        "city": "Timimoun",
        "state": "Adrar",
        "country": "Algeria",
        "woeid": "1252933",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "TOF",
        "lat": "56.3833",
        "lon": "85.2",
        "name": "Tomsk",
        "city": "Tomsk",
        "state": "Tomskaya Oblast",
        "country": "Russia",
        "woeid": "2123927",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "182",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "TRZ",
        "lat": "10.7645",
        "lon": "78.7396",
        "name": "Tiruchirapally",
        "city": "Tiruchirapally",
        "state": "",
        "country": "India",
        "woeid": "12513684",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "TUK",
        "lat": "25.9833",
        "lon": "63.0319",
        "name": "Turbat",
        "city": "Turbat",
        "state": "Balochistan",
        "country": "Pakistan",
        "woeid": "12515273",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "TXN",
        "lat": "29.7354",
        "lon": "118.262",
        "name": "Tunxi",
        "city": "Haiyang",
        "state": "Anhui",
        "country": "China",
        "woeid": "12512221",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "UBA",
        "lat": "-19.75",
        "lon": "-47.95",
        "name": "Uberaba",
        "city": "Uberaba",
        "state": "Sudeste",
        "country": "Brazil",
        "woeid": "455916",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "2648",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "UCT",
        "lat": "63.5577",
        "lon": "53.7894",
        "name": "Ust Ukhta",
        "city": "Ukhta",
        "state": "Komi",
        "country": "Russia",
        "woeid": "12517120",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "UDR",
        "lat": "24.6041",
        "lon": "73.7726",
        "name": "Udaipur",
        "city": "Debari",
        "state": "Rajasthan",
        "country": "India",
        "woeid": "12513688",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1670",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "UKK",
        "lat": "47.0436",
        "lon": "17.2142",
        "name": "Ust-kamenogorsk",
        "city": "Ust-kamenogorsk",
        "state": "",
        "country": "Kazakhstan",
        "woeid": "23424871",
        "tz": "AsiaQyzylorda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "UNG",
        "lat": "-23.4941",
        "lon": "-46.5488",
        "name": "Kiunga",
        "city": "Kiunga",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "1061752",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3690",
        "elev": "102",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "VAN",
        "lat": "38.4681",
        "lon": "43.3308",
        "name": "Van",
        "city": "Van",
        "state": "Van",
        "country": "Turkey",
        "woeid": "12517920",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8990",
        "elev": "5474",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "VFA",
        "lat": "-18.0931",
        "lon": "25.8403",
        "name": "Victoria Falls",
        "city": "",
        "state": "Matabeleland North",
        "country": "Zimbabwe",
        "woeid": "12523039",
        "tz": "AfricaHarare",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "3490",
        "icao": "FVFA",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "VNS",
        "lat": "25.4541",
        "lon": "82.8642",
        "name": "Varanasi",
        "city": "Baragaon",
        "state": "Uttar Pradesh",
        "country": "India",
        "woeid": "12513693",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5880",
        "elev": "262",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "VOG",
        "lat": "48.7788",
        "lon": "44.3382",
        "name": "Gumrak",
        "city": "Kamyshin",
        "state": "Volgogradskaya Oblast",
        "country": "Russia",
        "woeid": "12516023",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "476",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "VTZ",
        "lat": "17.7214",
        "lon": "83.2201",
        "name": "Vishakapatnam",
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "country": "India",
        "woeid": "12513695",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "14",
        "icao": "",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "VVC",
        "lat": "4.1708",
        "lon": "-73.6117",
        "name": "Vanguardia",
        "city": "Villavicencio",
        "state": "Meta",
        "country": "Colombia",
        "woeid": "12512423",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5576",
        "elev": "1388",
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "WKJ",
        "lat": "45.4041",
        "lon": "141.801",
        "name": "Wakkanai",
        "city": "Wakkanai-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "28360512",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "24",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "WLH",
        "lat": "-15.1468",
        "lon": "167.038",
        "name": "Vanuatu",
        "city": "Walaha",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2165",
        "elev": "151",
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "WNN",
        "lat": "52.9228",
        "lon": "-89.1934",
        "name": "Wunnummin Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524051",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "WTS",
        "lat": "-18.9076",
        "lon": "47.5277",
        "name": "Antananarivo",
        "city": "Tsiroanomandidy",
        "state": "Antananarivo",
        "country": "Madagascar",
        "woeid": "1358594",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "WVB",
        "lat": "22.9667",
        "lon": "14.4833",
        "name": "Rooikop",
        "city": "Walvis Bay",
        "state": "Murzuq",
        "country": "South Africa",
        "woeid": "1353302",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "290",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "XQU",
        "lat": "49.3384",
        "lon": "-124.392",
        "name": "Qualicum Beach",
        "city": "Qualicum Beach",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524067",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "200",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "XXA",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Sweden",
        "city": "Alvesta",
        "state": "",
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YBC",
        "lat": "49.1368",
        "lon": "-68.1995",
        "name": "Baie Comeau",
        "city": "Betsiamites",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511572",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "71",
        "icao": "CYBC",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "YBP",
        "lat": "28.766",
        "lon": "104.623",
        "name": "Yibin",
        "city": "Yibin",
        "state": "Sichuan",
        "country": "China",
        "woeid": "2158444",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "YEI",
        "lat": "40.2546",
        "lon": "29.5629",
        "name": "Bursa",
        "city": "Bursa",
        "state": "Bursa",
        "country": "Turkey",
        "woeid": "2347273",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "9818",
        "elev": "764",
        "icao": "",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "YGR",
        "lat": "47.42",
        "lon": "-61.7743",
        "name": "Iles de la Madeleine",
        "city": "Fatima",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511701",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3725",
        "elev": "34",
        "icao": "CYGR",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "YHR",
        "lat": "50.5013",
        "lon": "-59.4799",
        "name": "Harrington Harbour",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524141",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "30",
        "icao": "CYHR",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YKF",
        "lat": "43.45",
        "lon": "-80.4833",
        "name": "Kitchener",
        "city": "Kitchener",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524154",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.waterlooairport.ca",
        "runway_length": "5200",
        "elev": "1040",
        "icao": "CYKF",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "YKQ",
        "lat": "51.485",
        "lon": "-78.7483",
        "name": "Waskaganish",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524158",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": null,
        "icao": "CYKQ",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YOO",
        "lat": "43.9263",
        "lon": "-78.8959",
        "name": "Oshawa",
        "city": "Oshawa",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511777",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3476",
        "elev": "458",
        "icao": "CYOO",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YPM",
        "lat": "51.8117",
        "lon": "-93.9886",
        "name": "Pikangikum",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524199",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "1114",
        "icao": "CYPM",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YQQ",
        "lat": "49.7083",
        "lon": "-124.897",
        "name": "Comox",
        "city": "Comox",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511624",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "80",
        "icao": "CYQQ",
        "direct_flights": "5",
        "carriers": "5"
    },
    {
        "code": "YTZ",
        "lat": "43.6321",
        "lon": "-79.3962",
        "name": "Toronto City Centre",
        "city": "Toronto",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511882",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYTZ",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "YVQ",
        "lat": "65.2825",
        "lon": "-126.798",
        "name": "Norman Wells",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511772",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "241",
        "icao": "CYVQ",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "YWK",
        "lat": "52.9261",
        "lon": "-66.873",
        "name": "Wabush",
        "city": "Wabush",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511902",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1806",
        "icao": "CYWK",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "YXJ",
        "lat": "56.2464",
        "lon": "-120.737",
        "name": "North Peace",
        "city": "Fort St. John",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511669",
        "tz": "AmericaDawson_Creek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6900",
        "elev": "2280",
        "icao": "CYXJ",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "YXT",
        "lat": "54.4727",
        "lon": "-128.577",
        "name": "Terrace",
        "city": "Terrace",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511868",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYXT",
        "direct_flights": "5",
        "carriers": "4"
    },
    {
        "code": "YYQ",
        "lat": "58.7557",
        "lon": "-94.0678",
        "name": "Churchill",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511615",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYYQ",
        "direct_flights": "5",
        "carriers": "2"
    },
    {
        "code": "ZLO",
        "lat": "19.1478",
        "lon": "-104.56",
        "name": "Playa de Oro",
        "city": "Manzanillo",
        "state": "Jalisco",
        "country": "Mexico",
        "woeid": "12514936",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "26",
        "icao": "MMZO",
        "direct_flights": "5",
        "carriers": "6"
    },
    {
        "code": "ZRJ",
        "lat": "52.95",
        "lon": "-91.3167",
        "name": "Round Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524304",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "974",
        "icao": "CZRJ",
        "direct_flights": "5",
        "carriers": "1"
    },
    {
        "code": "ZSE",
        "lat": "-20.8833",
        "lon": "55.5333",
        "name": "Réunion",
        "city": "St Pierre dela Reunion",
        "state": "St-Denis",
        "country": "Reunion",
        "woeid": "1511237",
        "tz": "IndianReunion",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "52",
        "icao": "FMEP",
        "direct_flights": "5",
        "carriers": "3"
    },
    {
        "code": "LMP",
        "lat": "35.4995",
        "lon": "12.6165",
        "name": "Lampedusa",
        "city": "Lampedusa",
        "state": "Sicily",
        "country": "Italy",
        "woeid": "12513831",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "72",
        "icao": "LICD",
        "direct_flights": "4",
        "carriers": "3",
        "bookingId": "-119645"
    },
    {
        "code": "PNL",
        "lat": "36.8185",
        "lon": "11.963",
        "name": "Pantelleria",
        "city": "Pantelleria",
        "state": "Sicily",
        "country": "Italy",
        "woeid": "12513844",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.pantelleriairport.it",
        "runway_length": "4045",
        "elev": "632",
        "icao": "",
        "direct_flights": "4",
        "carriers": "6",
        "bookingId": "-123934"
    },
    {
        "code": "ABA",
        "lat": "53.7167",
        "lon": "91.4333",
        "name": "Abakan",
        "city": "Abakan",
        "state": "Khakasiya",
        "country": "Russian Federation",
        "woeid": "2119917",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UNAA",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "AET",
        "lat": "66.55",
        "lon": "-152.65",
        "name": "Allakaket",
        "city": "Allakaket",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524551",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "350",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "AEY",
        "lat": "65.6561",
        "lon": "-18.0923",
        "name": "Akureyri",
        "city": "Akureyri",
        "state": "Akureyri",
        "country": "Iceland",
        "woeid": "12513442",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6365",
        "elev": "6",
        "icao": "BIAR",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "AGH",
        "lat": "56.2873",
        "lon": "12.8675",
        "name": "Angelholm",
        "city": "Angelholm",
        "state": "Skane",
        "country": "Sweden",
        "woeid": "23319131",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.setemplatesLFV_AirportStartPage____4777.aspx",
        "runway_length": "6562",
        "elev": "65",
        "icao": "ESTA",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "AKJ",
        "lat": "43.665",
        "lon": "142.453",
        "name": "Asahikawa",
        "city": "Asahikawa-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "28360516",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "709",
        "icao": "RJEC",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "AKX",
        "lat": "50.2362",
        "lon": "57.2115",
        "name": "Aktyubinsk",
        "city": "Aqtobe",
        "state": "Aqtobe",
        "country": "Kazakhstan",
        "woeid": "12514256",
        "tz": "AsiaAqtobe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10170",
        "elev": "722",
        "icao": "UATT",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ANX",
        "lat": "69.3088",
        "lon": "16.1236",
        "name": "Andoya",
        "city": "Andenes",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12515100",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8005",
        "elev": "46",
        "icao": "ENAN",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "AUQ",
        "lat": "-9.7719",
        "lon": "-139.015",
        "name": "Atuona",
        "city": "Atuona",
        "state": "Marquesas Islands",
        "country": "French Polynesia",
        "woeid": "12523068",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3691",
        "elev": "1473",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "AVN",
        "lat": "43.9056",
        "lon": "4.89951",
        "name": "Avignon-Caumont",
        "city": "Avignon",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12523803",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "118",
        "icao": "LFMV",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "AVV",
        "lat": "-38.0408",
        "lon": "144.468",
        "name": "Avalon",
        "city": "Lara",
        "state": "Victoria",
        "country": "Australia",
        "woeid": "12510591",
        "tz": "AustraliaMelbourne",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "34",
        "icao": "YMAV",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "AXR",
        "lat": "-15.5098",
        "lon": "-146.538",
        "name": "French Polynesia",
        "city": "Arutua",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2230",
        "elev": "7",
        "icao": "NTGU",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "BBA",
        "lat": "-45.9167",
        "lon": "-71.6869",
        "name": "Balmaceda",
        "city": "Balmaceda",
        "state": "Aisen del General Carlos Ibanez ",
        "country": "Chile",
        "woeid": "12512305",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6883",
        "elev": "1713",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "BCM",
        "lat": "46.5283",
        "lon": "26.8997",
        "name": "Luizi Calugara",
        "city": "Bacæu",
        "state": "Bacau",
        "country": "Romania",
        "woeid": "12515549",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LRBC",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "BFE",
        "lat": "52",
        "lon": "8.5",
        "name": "Bielefeld",
        "city": "Bielefeld",
        "state": "North-Rhine-Westphalia",
        "country": "Germany",
        "woeid": "20066058",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KBFE",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "BFL",
        "lat": "35.4288",
        "lon": "-119.044",
        "name": "Kern County-Meadows Field",
        "city": "Bakersfield",
        "state": "California",
        "country": "United States",
        "woeid": "12520881",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9100",
        "elev": "490",
        "icao": "KBFL",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "BHK",
        "lat": "39.7948",
        "lon": "64.4895",
        "name": "Bukhara",
        "city": "Bukhara",
        "state": "Bukhoro",
        "country": "Uzbekistan",
        "woeid": "12522588",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UTSB",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "BHO",
        "lat": "23.2844",
        "lon": "77.3383",
        "name": "Bairagarh",
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513540",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6017",
        "elev": "1716",
        "icao": "VABP",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "BJZ",
        "lat": "38.8939",
        "lon": "-6.81921",
        "name": "Talavera la Real",
        "city": "Badajoz",
        "state": "Extremadura",
        "country": "Spain",
        "woeid": "12517575",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9350",
        "elev": "607",
        "icao": "LEBZ",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "BLE",
        "lat": "60.4297",
        "lon": "15.5081",
        "name": "Dala",
        "city": "Borlange",
        "state": "Dalarna",
        "country": "Sweden",
        "woeid": "12517614",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.dalaflyget.se",
        "runway_length": "7585",
        "elev": "503",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "BLJ",
        "lat": "35.5572",
        "lon": "6.17229",
        "name": "Algeria",
        "city": "Batna",
        "state": "Batna",
        "country": "Algeria",
        "woeid": "23424740",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "3445",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "BQN",
        "lat": "18.496",
        "lon": "-67.1357",
        "name": "Rafael Hernandez",
        "city": "Aguadilla",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12515664",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11700",
        "elev": "237",
        "icao": "TJBQ",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "BRC",
        "lat": "-41.1511",
        "lon": "-71.1581",
        "name": "San Carlos de Bariloche",
        "city": "San Carlos DeBariloche",
        "state": "Santa Fe",
        "country": "Argentina",
        "woeid": "12510550",
        "tz": "AmericaArgentinaCordoba",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7900",
        "elev": "2772",
        "icao": "SAZS",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "BTJ",
        "lat": "5.5197",
        "lon": "95.4181",
        "name": "Blangbintang",
        "city": "Banda Aceh",
        "state": "Aceh",
        "country": "Indonesia",
        "woeid": "12513456",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "62",
        "icao": "",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "BTK",
        "lat": "56.1667",
        "lon": "102.067",
        "name": "Bratsk",
        "city": "Bratsk",
        "state": "Irkutskaya Oblast",
        "country": "Russia",
        "woeid": "2120401",
        "tz": "AsiaIrkutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "BUZ",
        "lat": "28.9494",
        "lon": "50.8311",
        "name": "Bushehr",
        "city": "Bandar-e Bushehr",
        "state": "Bushehr",
        "country": "Iran",
        "woeid": "12513710",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10860",
        "elev": "57",
        "icao": "OIBB",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "CAY",
        "lat": "4.93333",
        "lon": "-52.3333",
        "name": "Rochambeau",
        "city": "Cayenne",
        "state": "Cayenne",
        "country": "French Guiana",
        "woeid": "379817",
        "tz": "AmericaCayenne",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "26",
        "icao": "SOCA",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "CBH",
        "lat": "31.6583",
        "lon": "-2.2614",
        "name": "Bechar",
        "city": "Bechar",
        "state": "Bechar",
        "country": "Algeria",
        "woeid": "12510314",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10064",
        "elev": "2661",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "CBT",
        "lat": "-12.4827",
        "lon": "13.4904",
        "name": "Angola",
        "city": "Catumbela",
        "state": null,
        "country": "Angola",
        "woeid": "23424745",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12135",
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "CCC",
        "lat": "22.4817",
        "lon": "-78.4813",
        "name": "Cuba",
        "city": "Cayo Coco",
        "state": "Ciego de Avila",
        "country": "Cuba",
        "woeid": "23424793",
        "tz": "AmericaHavana",
        "phone": "0049 172 6221025",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "CCP",
        "lat": "-36.7736",
        "lon": "-73.06",
        "name": "Carriel Sur",
        "city": "Hualpencillo",
        "state": "Bio-Bio",
        "country": "Chile",
        "woeid": "12512311",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeropuertocarrielsur.co.cl",
        "runway_length": "7546",
        "elev": "41",
        "icao": "SCIE",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "CCV",
        "lat": "-16.6619",
        "lon": "168.38",
        "name": "Vanuatu",
        "city": "Craig Cove",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2952",
        "elev": "69",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "CDW",
        "lat": "40.8759",
        "lon": "-74.2775",
        "name": "Essex County",
        "city": "Fairfield",
        "state": "New Jersey",
        "country": "United States",
        "woeid": "12519660",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4553",
        "elev": "173",
        "icao": "KCDW",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "CEE",
        "lat": "59.083",
        "lon": "37.938",
        "name": "Cherepovets",
        "city": "Cherepovets",
        "state": "Vologodskaya Oblast",
        "country": "Russia",
        "woeid": "12515900",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "CFS",
        "lat": "-30.3171",
        "lon": "153.119",
        "name": "Coffs Harbour",
        "city": "Coffs Harbour",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510636",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6824",
        "elev": "18",
        "icao": "KCFS",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "CKB",
        "lat": "39.2967",
        "lon": "-80.2314",
        "name": "Benedum",
        "city": "Clarksburg",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12518797",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5198",
        "elev": "1203",
        "icao": "KCKB",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "CME",
        "lat": "18.6522",
        "lon": "-91.8011",
        "name": "Ciudad del Carmen",
        "city": "Carmen Olorón",
        "state": "Campeche",
        "country": "Mexico",
        "woeid": "12514846",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "7",
        "icao": "MMCE",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "CND",
        "lat": "44.3581",
        "lon": "28.4896",
        "name": "Constanta Mihail Kogalniceanu",
        "city": "Kustenje",
        "state": "Constanta",
        "country": "Romania",
        "woeid": "12515525",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "354",
        "icao": "LRCK",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "CNJ",
        "lat": "-20.6657",
        "lon": "140.508",
        "name": "Cloncurry Aerodrome",
        "city": "Cloncurry",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510634",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "622",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "CPR",
        "lat": "42.8966",
        "lon": "-106.465",
        "name": "Natrona County",
        "city": "Casper",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12521085",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10600",
        "elev": "5348",
        "icao": "KCPR",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "CVQ",
        "lat": "-24.8801",
        "lon": "113.669",
        "name": "Carnarvon",
        "city": "Carnarvon",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510626",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5508",
        "elev": "12",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "CWA",
        "lat": "44.7846",
        "lon": "-89.6704",
        "name": "Central Wisconsin",
        "city": "Mosinee",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519120",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7645",
        "elev": "1277",
        "icao": "KCWA",
        "direct_flights": "4",
        "carriers": "9"
    },
    {
        "code": "CXH",
        "lat": "49.5912",
        "lon": "-125.848",
        "name": "Coal Harbour",
        "city": "Vancouver",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511619",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYHC",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "DDC",
        "lat": "37.7573",
        "lon": "-99.9697",
        "name": "Dodge City",
        "city": "Dodge City",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12519504",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6899",
        "elev": "2594",
        "icao": "KDDC",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "DLH",
        "lat": "46.838",
        "lon": "-92.1781",
        "name": "Duluth",
        "city": "Duluth",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12519545",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10152",
        "elev": "1430",
        "icao": "KDLH",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "DUT",
        "lat": "53.896",
        "lon": "-166.535",
        "name": "Unalaska",
        "city": "Unalaska",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522244",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "12",
        "icao": "PADU",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ELI",
        "lat": "64.6167",
        "lon": "-162.267",
        "name": "Elim",
        "city": "Elim",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524667",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "200",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ELQ",
        "lat": "26.3025",
        "lon": "43.7703",
        "name": "Gassim",
        "city": "Al Qara'",
        "state": "Al Qasim",
        "country": "Saudi Arabia",
        "woeid": "12517334",
        "tz": "AsiaRiyadh",
        "phone": "+966(6)3800001",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "2126",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "ELS",
        "lat": "-33.0361",
        "lon": "27.8239",
        "name": "Ben Schoeman",
        "city": "East London",
        "state": "Eastern Cape",
        "country": "South Africa",
        "woeid": "12517396",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6350",
        "elev": "431",
        "icao": "FAEL",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "ESU",
        "lat": "31.5147",
        "lon": "-9.76532",
        "name": "Morocco",
        "city": "Essaouira",
        "state": "Essaouira",
        "country": "Morocco",
        "woeid": "23424893",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "EVE",
        "lat": "68.489",
        "lon": "16.6806",
        "name": "Evenes",
        "city": "Tarnstad",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12515107",
        "tz": "EuropeOslo",
        "phone": "(+47) 76 98 10 25",
        "type": "Airports",
        "email": "",
        "url": "http:www.avinor.noNorskLufthavnerHarstad+Narvik_lufthavn,_E",
        "runway_length": "8720",
        "elev": "85",
        "icao": "ENEV",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "FAE",
        "lat": "62.0625",
        "lon": "-7.2708",
        "name": "Vagar",
        "city": "Faroe Islands",
        "state": "Vága",
        "country": "Faroe Islands",
        "woeid": "12512818",
        "tz": "AtlanticFaroe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.floghavn.fo",
        "runway_length": "4104",
        "elev": "280",
        "icao": "EKVG",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "FLW",
        "lat": "39.4569",
        "lon": "-31.1272",
        "name": "Flores",
        "city": "Flores Island",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515443",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3707",
        "elev": "112",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "FNI",
        "lat": "43.7618",
        "lon": "4.42092",
        "name": "Garons",
        "city": "St-Gilles-du-Gard",
        "state": "Languedoc-Roussillon",
        "country": "France",
        "woeid": "12512899",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8005",
        "elev": "308",
        "icao": "LFTW",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "FNJ",
        "lat": "39.1437",
        "lon": "125.695",
        "name": "Sunan",
        "city": "Sunan",
        "state": "P'yongan-namdo",
        "country": "North Korea",
        "woeid": "12514175",
        "tz": "AsiaPyongyang",
        "phone": "+850-2-837-917",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "94",
        "icao": "ZKPY",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "FRS",
        "lat": "16.9139",
        "lon": "-89.8664",
        "name": "Santa Elena",
        "city": "Flores",
        "state": "Peten",
        "country": "Guatemala",
        "woeid": "12513341",
        "tz": "AmericaGuatemala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "420",
        "icao": "MGTK",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "FSP",
        "lat": "46.7708",
        "lon": "-56.1667",
        "name": "St Pierre",
        "city": "St Pierre",
        "state": "Miquelon-Langlade",
        "country": "Saint Pierre and Miquelon",
        "woeid": "12517380",
        "tz": "AmericaMiquelon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4100",
        "elev": "23",
        "icao": "LFVP",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "FTU",
        "lat": "-25.0381",
        "lon": "46.9556",
        "name": "Tolagnaro",
        "city": "Faradofay",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "12514709",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "26",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "GAJ",
        "lat": "38.4109",
        "lon": "140.37",
        "name": "Yamagata",
        "city": "Higashine-shi",
        "state": "Yamagata Prefecture",
        "country": "Japan",
        "woeid": "12514035",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "353",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "GCC",
        "lat": "44.3465",
        "lon": "-105.533",
        "name": "Gillette Campbell County",
        "city": "Gillette",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12519902",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "4363",
        "icao": "KGCC",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "GCK",
        "lat": "37.9267",
        "lon": "-100.719",
        "name": "Garden City",
        "city": "Pierceville",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12519861",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5999",
        "elev": "2888",
        "icao": "KGCK",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "GDT",
        "lat": "21.4439",
        "lon": "-71.1425",
        "name": "Grand Turk",
        "city": "Grand Turk",
        "state": "Gand Turk",
        "country": "Turks And Caicos Islands",
        "woeid": "12517840",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6335",
        "elev": "13",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "GEO",
        "lat": "6.5003",
        "lon": "-58.2542",
        "name": "Timehri",
        "city": "Hyde Park",
        "state": "Demerara-Mahaica",
        "country": "Guyana",
        "woeid": "12513350",
        "tz": "AmericaGuyana",
        "phone": "(920) 498-4800",
        "type": "Airports",
        "email": "",
        "url": "http:www.cjairport-gy.com",
        "runway_length": "7",
        "elev": "95",
        "icao": "SYCJ",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "GET",
        "lat": "-28.7975",
        "lon": "114.706",
        "name": "Geraldton",
        "city": "Geraldton",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510673",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "120",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "GKA",
        "lat": "-6.0828",
        "lon": "145.396",
        "name": "Goroka",
        "city": "Goroka",
        "state": "Eastern Highlands",
        "country": "Papua New Guinea",
        "woeid": "12515466",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "5208",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "GOU",
        "lat": "9.3361",
        "lon": "13.3756",
        "name": "Garoua",
        "city": "Garoua",
        "state": "Nord",
        "country": "Cameroon",
        "woeid": "12512350",
        "tz": "AfricaDouala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11155",
        "elev": "794",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "GRJ",
        "lat": "-34.005",
        "lon": "22.3792",
        "name": "P W Botha",
        "city": "George",
        "state": "Western Cape",
        "country": "South Africa",
        "woeid": "12517456",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "649",
        "icao": "FAGG",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "GWD",
        "lat": "25.2306",
        "lon": "62.3389",
        "name": "Gwadar",
        "city": "Gawadar",
        "state": "Balochistan",
        "country": "Pakistan",
        "woeid": "12515236",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "96",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "GXF",
        "lat": "15.9625",
        "lon": "48.7877",
        "name": "Sayun",
        "city": "Seiyun",
        "state": "Hadramawt",
        "country": "Yemen",
        "woeid": "24554865",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3850",
        "elev": "2100",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "HBA",
        "lat": "-42.8375",
        "lon": "147.506",
        "name": "Hobart",
        "city": "Hobart",
        "state": "Tasmania",
        "country": "Australia",
        "woeid": "12510690",
        "tz": "AustraliaHobart",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7386",
        "elev": "13",
        "icao": "YMHB",
        "direct_flights": "4",
        "carriers": "8"
    },
    {
        "code": "HGA",
        "lat": "9.5186",
        "lon": "44.0903",
        "name": "Hargeisa",
        "city": "Hargeysa",
        "state": "Woqooyi Galbeed",
        "country": "Somalia",
        "woeid": "12517530",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7480",
        "elev": "4423",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "HLD",
        "lat": "49.2",
        "lon": "119.7",
        "name": "Hailar",
        "city": "Hailar",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "2149763",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "HNA",
        "lat": "39.4315",
        "lon": "141.135",
        "name": "Hanamaki",
        "city": "Hanamaki-shi",
        "state": "Iwate Prefecture",
        "country": "Japan",
        "woeid": "12513956",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "297",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "HOM",
        "lat": "59.6408",
        "lon": "-151.499",
        "name": "Homer",
        "city": "Homer",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520209",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7401",
        "elev": "78",
        "icao": "PAHO",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "HOV",
        "lat": "62.1804",
        "lon": "6.07624",
        "name": "Hovden",
        "city": "Hovdebygda",
        "state": "More og Romsdal",
        "country": "Norway",
        "woeid": "12523936",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "243",
        "icao": "ENOV",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "HUH",
        "lat": "-16.6867",
        "lon": "-151.019",
        "name": "Huahine",
        "city": "Papeete",
        "state": "Leeward Islands",
        "country": "French Polynesia",
        "woeid": "12512823",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "7",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "HUX",
        "lat": "15.7739",
        "lon": "-96.2625",
        "name": "Bahias de Huatulco",
        "city": "Santo Domingo de Morelos",
        "state": "Oaxaca",
        "country": "Mexico",
        "woeid": "12514830",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "HUY",
        "lat": "53.5833",
        "lon": "-0.34925",
        "name": "Humberside",
        "city": "Ulceby",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22492354",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5098",
        "elev": "102",
        "icao": "",
        "direct_flights": "4",
        "carriers": "9"
    },
    {
        "code": "HVG",
        "lat": "71.0099",
        "lon": "25.9767",
        "name": "Valan",
        "city": "Honningsvåg",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523938",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "46",
        "icao": "ENHV",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "HYA",
        "lat": "41.6667",
        "lon": "-70.2863",
        "name": "Barnstable Boardman Polando",
        "city": "Hyannis",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12518745",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5564",
        "elev": "52",
        "icao": "KHYA",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "HYN",
        "lat": "60.8929",
        "lon": "15.9744",
        "name": "Huangyan",
        "city": "Huangyan",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "2132584",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "IAG",
        "lat": "43.1",
        "lon": "-78.9423",
        "name": "Niagara Falls",
        "city": "Niagara Falls",
        "state": "New York",
        "country": "United States",
        "woeid": "12521138",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "9125",
        "elev": "590",
        "icao": "KIAG",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "IEV",
        "lat": "50.4069",
        "lon": "30.4456",
        "name": "kyiv Zhulyany",
        "city": "Kiev",
        "state": "Kyyivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518501",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "574",
        "icao": "UKKK",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "IOS",
        "lat": "-14.8142",
        "lon": "-39.0331",
        "name": "Jorge Amado",
        "city": "Ilhéus",
        "state": "Bahia",
        "country": "Brazil",
        "woeid": "12511178",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5164",
        "elev": "13",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "IXA",
        "lat": "23.89",
        "lon": "91.2422",
        "name": "Agartala",
        "city": "Gandhigram",
        "state": "Tripura",
        "country": "India",
        "woeid": "12513527",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6152",
        "elev": "55",
        "icao": "VEAT",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "IXJ",
        "lat": "32.6917",
        "lon": "74.8375",
        "name": "Jammu",
        "city": "Jammu Cantt",
        "state": "Jammu and Kashmir",
        "country": "India",
        "woeid": "12513606",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5556",
        "elev": "1029",
        "icao": "VIJU",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "JCB",
        "lat": "-27.1722",
        "lon": "-51.5511",
        "name": "Joacaba",
        "city": "Joaçaba",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511200",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4134",
        "elev": "2546",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "JDF",
        "lat": "-21.7925",
        "lon": "-43.385",
        "name": "Francisco de Assis",
        "city": "Juiz de Fora",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511151",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4275",
        "elev": "2989",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "JGS",
        "lat": "27.1093",
        "lon": "114.984",
        "name": "Ji AnJing Gang Shan",
        "city": "Ji An",
        "state": "Jiangxi",
        "country": "China",
        "woeid": "2133708",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "JIM",
        "lat": "32.3834",
        "lon": "-81.8349",
        "name": "Jimma",
        "city": "Jimma",
        "state": "Kefa",
        "country": "Ethiopia",
        "woeid": "1317611",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "5500",
        "icao": "HAJM",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "JIU",
        "lat": "45.0377",
        "lon": "23.2697",
        "name": "Jiujiang",
        "city": "Jiujiang",
        "state": "Jiangxi",
        "country": "China",
        "woeid": "26198150",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZSJJ",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "JJU",
        "lat": "60.7483",
        "lon": "-46.0317",
        "name": "Julianehab Heliport",
        "city": "Qaqortoq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523845",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "BGQE",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "JQA",
        "lat": "71.8021",
        "lon": "-42.1772",
        "name": "Greenland",
        "city": "Qaarsut",
        "state": "Arkansas",
        "country": "Greenland",
        "woeid": "23424828",
        "tz": "AmericaIndianaTell_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "JSH",
        "lat": "35.2113",
        "lon": "26.1012",
        "name": "Sitia",
        "city": "Seteia",
        "state": "Kriti",
        "country": "Greece",
        "woeid": "12523352",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2395",
        "elev": "402",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "JZH",
        "lat": "32.8369",
        "lon": "103.682",
        "name": "Jiu Zhai Huang Long",
        "city": "Song Pan",
        "state": "四川省",
        "country": "China",
        "woeid": "12713656",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "11311",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "KBV",
        "lat": "7.98237",
        "lon": "98.8406",
        "name": "Thailand",
        "city": "Krabi",
        "state": "Krabi",
        "country": "Thailand",
        "woeid": "23424960",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "8"
    },
    {
        "code": "KEP",
        "lat": "28",
        "lon": "81.6333",
        "name": "Nepalganj",
        "city": "Nepalganj",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "2269174",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3700",
        "elev": "600",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "KKA",
        "lat": "64.9311",
        "lon": "-161.16",
        "name": "Koyuk",
        "city": "Koyuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2433864",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "150",
        "icao": "PAKK",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "KKJ",
        "lat": "33.839",
        "lon": "131.033",
        "name": "New Kitakyushu",
        "city": "Kita Kyushu",
        "state": "Kagoshima Prefecture",
        "country": "Japan",
        "woeid": "23388303",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.kitakyu-air.co.jp",
        "runway_length": "8202",
        "elev": "10",
        "icao": "",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "KNX",
        "lat": "-15.7756",
        "lon": "128.715",
        "name": "Kununurra",
        "city": "Durack",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510710",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "144",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "KRI",
        "lat": "-6.65619",
        "lon": "145.859",
        "name": "Papua New Guinea",
        "city": "Kikori",
        "state": null,
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2310",
        "elev": "40",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "KTT",
        "lat": "67.695",
        "lon": "24.8508",
        "name": "Kittila",
        "city": "Kittila",
        "state": "Lapland",
        "country": "Finland",
        "woeid": "12512777",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "643",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "KUH",
        "lat": "43.043",
        "lon": "144.194",
        "name": "Kushiro",
        "city": "Kushiro",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "28360574",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "324",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "KVD",
        "lat": "40.6844",
        "lon": "46.3488",
        "name": "Elisavetpol",
        "city": "Gyandzha",
        "state": "Ganca",
        "country": "Azerbaijan",
        "woeid": "1951907",
        "tz": "AsiaBaku",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "KVG",
        "lat": "-2.5806",
        "lon": "150.807",
        "name": "Kavieng",
        "city": "Kavieng",
        "state": "New Ireland",
        "country": "Papua New Guinea",
        "woeid": "12515469",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5010",
        "elev": "12",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "KWA",
        "lat": "8.7147",
        "lon": "167.727",
        "name": "Bucholz Army Air Field",
        "city": "Kwajalein",
        "state": "Kwajalein",
        "country": "Marshall Islands",
        "woeid": "12515490",
        "tz": "PacificMajuro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6673",
        "elev": "9",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "KZO",
        "lat": "44.8172",
        "lon": "65.5435",
        "name": "Kzyl Orda",
        "city": "Kzyl-Orda",
        "state": "Qyzylorda",
        "country": "Kazakhstan",
        "woeid": "12514405",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "LGK",
        "lat": "6.3364",
        "lon": "99.7353",
        "name": "Langkawi",
        "city": "Kuah",
        "state": "Perlis",
        "country": "Malaysia",
        "woeid": "12515000",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8001",
        "elev": "29",
        "icao": "",
        "direct_flights": "4",
        "carriers": "8"
    },
    {
        "code": "LIF",
        "lat": "-20.9314",
        "lon": "167.229",
        "name": "Loyalty Islands",
        "city": "Wé",
        "state": "Loyaute",
        "country": "New Caledonia",
        "woeid": "12515037",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3608",
        "elev": "92",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "LIO",
        "lat": "9.9578",
        "lon": "-83.0291",
        "name": "Limon",
        "city": "Pto. Limon",
        "state": "Limon",
        "country": "Costa Rica",
        "woeid": "12512435",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5900",
        "elev": "7",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "LKE",
        "lat": "47.6333",
        "lon": "-122.333",
        "name": "Lake Union Seaplane Base",
        "city": "Seattle",
        "state": "Washington",
        "country": "United States",
        "woeid": "12523163",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "14",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "LNY",
        "lat": "20.7909",
        "lon": "-156.951",
        "name": "Lanai",
        "city": "Lanai City",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520557",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1308",
        "icao": "PHNY",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "LRR",
        "lat": "27.6868",
        "lon": "54.3334",
        "name": "Lar",
        "city": "Lar",
        "state": "Fars",
        "country": "Iran",
        "woeid": "2254868",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "LSP",
        "lat": "11.7794",
        "lon": "-70.1525",
        "name": "Josefa Camejo",
        "city": "Las Piedras",
        "state": "Falcon",
        "country": "Venezuela",
        "woeid": "12522797",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "75",
        "icao": "",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "LST",
        "lat": "-41.5469",
        "lon": "147.213",
        "name": "Launceston",
        "city": "Blessington",
        "state": "Tasmania",
        "country": "Australia",
        "woeid": "12510712",
        "tz": "AustraliaHobart",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "-10",
        "elev": "6500",
        "icao": "YMLT",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "LWB",
        "lat": "37.8571",
        "lon": "-80.4044",
        "name": "Greenbrier Valley",
        "city": "Lewisburg",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12519993",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7004",
        "elev": "2303",
        "icao": "KLWB",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "LWN",
        "lat": "40.7859",
        "lon": "43.8439",
        "name": "Gyumri",
        "city": "Gyumri",
        "state": "Shirak",
        "country": "Armenia",
        "woeid": "12523169",
        "tz": "AsiaYerevan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UDSG",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "LWS",
        "lat": "46.3776",
        "lon": "-117.011",
        "name": "Lewiston Nez Perce County",
        "city": "Lewiston",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12520635",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6512",
        "elev": "1438",
        "icao": "KLWS",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "LZO",
        "lat": "28.8516",
        "lon": "105.401",
        "name": "Luzhou",
        "city": "Luzhou",
        "state": "Sichuan",
        "country": "China",
        "woeid": "12512139",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MAJ",
        "lat": "7.0605",
        "lon": "171.275",
        "name": "Marshall Islands",
        "city": "Majuro",
        "state": "Majuro",
        "country": "Marshall Islands",
        "woeid": "12515494",
        "tz": "PacificKwajalein",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7900",
        "elev": "6",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MDG",
        "lat": "44.6",
        "lon": "129.5",
        "name": "Mudanjiang",
        "city": "Mudanjiang",
        "state": "黑龙江省",
        "country": "China",
        "woeid": "12712423",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "MEE",
        "lat": "-21.5021",
        "lon": "167.964",
        "name": "Loyalty Islands",
        "city": "Tadine",
        "state": "Loyaute",
        "country": "New Caledonia",
        "woeid": "12515036",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "141",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "MFE",
        "lat": "26.1813",
        "lon": "-98.2404",
        "name": "Miller",
        "city": "Mcallen",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520953",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7108",
        "elev": "107",
        "icao": "KMFE",
        "direct_flights": "4",
        "carriers": "11"
    },
    {
        "code": "MGM",
        "lat": "32.3049",
        "lon": "-86.3909",
        "name": "Montgomery",
        "city": "Montgomery",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12519409",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9001",
        "elev": "221",
        "icao": "KMGM",
        "direct_flights": "4",
        "carriers": "9"
    },
    {
        "code": "MLU",
        "lat": "32.5099",
        "lon": "-92.044",
        "name": "Monroe",
        "city": "Monroe",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12520996",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7507",
        "elev": "79",
        "icao": "KMLU",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "MLX",
        "lat": "38.435",
        "lon": "38.0911",
        "name": "Erhac",
        "city": "Arga",
        "state": "Malatya",
        "country": "Turkey",
        "woeid": "12517885",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7600",
        "elev": "3016",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MMY",
        "lat": "24.7824",
        "lon": "125.295",
        "name": "Miyako",
        "city": "Miyako Jima",
        "state": "Iwate Prefecture",
        "country": "Japan",
        "woeid": "12513990",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "149",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MOZ",
        "lat": "-17.5328",
        "lon": "-149.839",
        "name": "Society Islands",
        "city": "Papeete",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "12512831",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2887",
        "elev": "10",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "MQP",
        "lat": "-25.5",
        "lon": "30.9167",
        "name": "Nelspruit",
        "city": "Nelspruit",
        "state": "",
        "country": "South Africa",
        "woeid": "12523191",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "MRE",
        "lat": "-1.3944",
        "lon": "35.0089",
        "name": "Mara Serena",
        "city": "Mara Lodges",
        "state": "Rift Valley",
        "country": "Kenya",
        "woeid": "12514071",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2700",
        "elev": "5600",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MTR",
        "lat": "8.8306",
        "lon": "-75.8247",
        "name": "Los Garzones",
        "city": "Los Garzones",
        "state": "Cordoba",
        "country": "Colombia",
        "woeid": "12512392",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6132",
        "elev": "36",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "MUB",
        "lat": "-19.9708",
        "lon": "23.4306",
        "name": "Maun",
        "city": "Maun",
        "state": "Ngamiland",
        "country": "Botswana",
        "woeid": "12510837",
        "tz": "AfricaGaborone",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "3101",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MUE",
        "lat": "19.9983",
        "lon": "-155.675",
        "name": "Waimea Kohala",
        "city": "Kamuela",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12522305",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "2671",
        "icao": "PHMU",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "MWZ",
        "lat": "-2.4469",
        "lon": "32.9286",
        "name": "Mwanza",
        "city": "Ilemera",
        "state": "Mwanza",
        "country": "Tanzania",
        "woeid": "12518019",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10830",
        "elev": "3763",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "NBC",
        "lat": "59.4615",
        "lon": "108.832",
        "name": "Russia",
        "city": "Naberevnye Chelny",
        "state": "",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KNBC",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "NER",
        "lat": "17.611",
        "lon": "8.08095",
        "name": "Neryungri",
        "city": "Neryungri",
        "state": "Sakha",
        "country": "Russia",
        "woeid": "2050745",
        "tz": "AsiaYakutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "NHV",
        "lat": "-8.79383",
        "lon": "-140.223",
        "name": "Marquesas Islands",
        "city": "Nuku Hiva",
        "state": "Marquesas Islands",
        "country": "French Polynesia",
        "woeid": "12512826",
        "tz": "PacificMarquesas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "226",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "NMA",
        "lat": "40.9787",
        "lon": "71.5677",
        "name": "Namangan",
        "city": "Namangan",
        "state": "Namangan",
        "country": "Uzbekistan",
        "woeid": "12522684",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "NOZ",
        "lat": "54.5183",
        "lon": "86.9342",
        "name": "Kemerovskaya Oblast",
        "city": "Novokuznetsk",
        "state": "Kemerovskaya Oblast",
        "country": "Russia",
        "woeid": "2346901",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "NRK",
        "lat": "58.5841",
        "lon": "16.2338",
        "name": "Kungsangen",
        "city": "Norrkoping",
        "state": "Ostergotland",
        "country": "Sweden",
        "woeid": "12517656",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "17",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "NTG",
        "lat": "32.0155",
        "lon": "120.84",
        "name": "Nantong",
        "city": "Nantong",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "12523350",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "NYU",
        "lat": "21.1814",
        "lon": "94.9347",
        "name": "Nyaung U",
        "city": "Nyaung-u",
        "state": "Mandalay",
        "country": "Myanmar",
        "woeid": "12510930",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "300",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "OAX",
        "lat": "16.9986",
        "lon": "-96.7264",
        "name": "Xoxocotlan",
        "city": "San Bernardo Mixtepec",
        "state": "Oaxaca",
        "country": "Mexico",
        "woeid": "12514986",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8036",
        "elev": "5012",
        "icao": "MMOX",
        "direct_flights": "4",
        "carriers": "10"
    },
    {
        "code": "ODW",
        "lat": "48.2517",
        "lon": "-122.68",
        "name": "Oak Harbor Airpark",
        "city": "Oak Harbor",
        "state": "Washington",
        "country": "United States",
        "woeid": "12521178",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3267",
        "elev": "189",
        "icao": "KOKH",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "OIT",
        "lat": "33.4801",
        "lon": "131.737",
        "name": "Oita",
        "city": "Kunisaki-shi",
        "state": "Oita Prefecture",
        "country": "Japan",
        "woeid": "12514003",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "19",
        "icao": "RJFO",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "ORB",
        "lat": "59.2279",
        "lon": "15.0455",
        "name": "Orebro",
        "city": "Orebro",
        "state": "Orebro",
        "country": "Sweden",
        "woeid": "12517665",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3346",
        "elev": "102",
        "icao": "KORB",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "PBH",
        "lat": "27.4033",
        "lon": "89.4292",
        "name": "Paro",
        "city": "Paro",
        "state": "Paro",
        "country": "Bhutan",
        "woeid": "12511377",
        "tz": "AsiaThimphu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.drukair.com.bt",
        "runway_length": "4935",
        "elev": "7332",
        "icao": "KPBH",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "PFN",
        "lat": "30.2071",
        "lon": "-85.6829",
        "name": "Panama City Bay County",
        "city": "Panama City",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521294",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6314",
        "elev": "21",
        "icao": "KPFN",
        "direct_flights": "4",
        "carriers": "9"
    },
    {
        "code": "PGU",
        "lat": "36.9243",
        "lon": "-111.451",
        "name": "Page Municipal",
        "city": "Asalouyeh",
        "state": "",
        "country": "Iran",
        "woeid": "12521277",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11823",
        "elev": "15",
        "icao": "OIBI",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "PHC",
        "lat": "5.0125",
        "lon": "6.9625",
        "name": "Port Harcourt",
        "city": "Port Harcourt",
        "state": "Rivers",
        "country": "Nigeria",
        "woeid": "12515076",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7005",
        "elev": "61",
        "icao": "DNPO",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "PHO",
        "lat": "68.3494",
        "lon": "-166.797",
        "name": "Point Hope",
        "city": "Point Hope",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521425",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "14",
        "icao": "PAPO",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "PIS",
        "lat": "46.5859",
        "lon": "0.31135",
        "name": "Biard",
        "city": "Vouneuil-sous-Biard",
        "state": "Poitou-Charentes",
        "country": "France",
        "woeid": "12512855",
        "tz": "EuropeParis",
        "phone": "+33 (0)5 49 30 04 40",
        "type": "Airports",
        "email": "",
        "url": "http:www.poitiers.cci.fraeroport",
        "runway_length": "7710",
        "elev": "420",
        "icao": "LFBI",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "PKZ",
        "lat": "15.1303",
        "lon": "105.785",
        "name": "Pakse",
        "city": "Pakxe",
        "state": "Champasak",
        "country": "Laos",
        "woeid": "12514552",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5330",
        "elev": "330",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "PPS",
        "lat": "9.7433",
        "lon": "118.756",
        "name": "Puerto Princesa",
        "city": "Puerto Princesa",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12515642",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "20",
        "icao": "RPVP",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PQQ",
        "lat": "-31.4309",
        "lon": "152.866",
        "name": "Port Macquarie",
        "city": "Port Macquarie",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510766",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "12",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PRC",
        "lat": "34.6492",
        "lon": "-112.428",
        "name": "Ernest A Love Field",
        "city": "Prescott",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12519656",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7616",
        "elev": "5042",
        "icao": "KPRC",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PSE",
        "lat": "18.0116",
        "lon": "-66.5664",
        "name": "Mercedita",
        "city": "Coto Laurel",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12515663",
        "tz": "AmericaPuerto_Rico",
        "phone": "787 557 7753",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5529",
        "elev": "27",
        "icao": "TJPS",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PVH",
        "lat": "-8.7133",
        "lon": "-63.9022",
        "name": "Governador Jorge Teixeira de Oliveira Internatio",
        "city": "Pôrto Velho",
        "state": "Rondonia",
        "country": "Brazil",
        "woeid": "12511285",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "289",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PWQ",
        "lat": "52.1859",
        "lon": "77.0858",
        "name": "Pavlodar South",
        "city": "Pavlodar",
        "state": "Pavlodar",
        "country": "Kazakhstan",
        "woeid": "12514433",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "PZU",
        "lat": "19.5761",
        "lon": "37.2167",
        "name": "Port Sudan",
        "city": "Port Sudan",
        "state": "Al Bahr al Ahmar",
        "country": "Sudan",
        "woeid": "12517603",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "10",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "QRO",
        "lat": "20.6228",
        "lon": "-100.369",
        "name": "Queretaro",
        "city": "Queretaro",
        "state": "Queretaro de Arteaga",
        "country": "Mexico",
        "woeid": "12514944",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "6461",
        "icao": "",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "RAE",
        "lat": "30.9086",
        "lon": "41.1367",
        "name": "Arar",
        "city": "Arar",
        "state": "Al Hudud ash Shamaliyah",
        "country": "Saudi Arabia",
        "woeid": "12517330",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "1821",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "RDD",
        "lat": "40.5056",
        "lon": "-122.302",
        "name": "Redding Municipal",
        "city": "Redding",
        "state": "California",
        "country": "United States",
        "woeid": "12521548",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.ci.redding.ca.usadminsvairportsindex.htm",
        "runway_length": "7003",
        "elev": "502",
        "icao": "KRDD",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "REL",
        "lat": "-43.2111",
        "lon": "-65.2681",
        "name": "Trelew Almirante Zar",
        "city": "Trelew",
        "state": "Chubut",
        "country": "Argentina",
        "woeid": "12510569",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "REX",
        "lat": "26.0133",
        "lon": "-98.2319",
        "name": "Reynosa",
        "city": "Reynosa",
        "state": "Tamaulipas",
        "country": "Mexico",
        "woeid": "12514948",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "128",
        "icao": "MMRX",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "RFP",
        "lat": "-16.7167",
        "lon": "-151.467",
        "name": "Raiatea Island",
        "city": "Papeete",
        "state": "Leeward Islands",
        "country": "French Polynesia",
        "woeid": "12523210",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "3",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "RHI",
        "lat": "45.626",
        "lon": "-89.4626",
        "name": "Rhinelander-Oneida County",
        "city": "Rhinelander",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12521570",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "1624",
        "icao": "KRHI",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "RKT",
        "lat": "25.6111",
        "lon": "55.9378",
        "name": "Ras Al Khaimah",
        "city": "",
        "state": "Ras Al Khaimah",
        "country": "United Arab Emirates",
        "woeid": "12517738",
        "tz": "AsiaDubai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12336",
        "elev": "102",
        "icao": "OMRK",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "RLG",
        "lat": "46.7427",
        "lon": "-96.2383",
        "name": "Rostock-laage",
        "city": "Rostock-laage",
        "state": "Mecklenburg-Vorpommern",
        "country": "Germany",
        "woeid": "655984",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ETNL",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "RMA",
        "lat": "-26.5457",
        "lon": "148.773",
        "name": "Roma Aerodrome",
        "city": "Blythdale",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510774",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4212",
        "elev": "1032",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "ROB",
        "lat": "6.2328",
        "lon": "-10.365",
        "name": "Roberts",
        "city": "Harbelville",
        "state": "Grand Bassa",
        "country": "Liberia",
        "woeid": "12514603",
        "tz": "AfricaMonrovia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11000",
        "elev": "31",
        "icao": "",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "ROP",
        "lat": "14.1667",
        "lon": "145.25",
        "name": "Rota",
        "city": "Rota",
        "state": "Northern Mariana Islands",
        "country": "United States",
        "woeid": "12523212",
        "tz": "PacificSaipan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "606",
        "icao": "PGRO",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "ROS",
        "lat": "-32.9033",
        "lon": "-60.7856",
        "name": "Rosario",
        "city": "Rosario",
        "state": "Santa Fe",
        "country": "Argentina",
        "woeid": "12510547",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "82",
        "icao": "KROS",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "RVK",
        "lat": "64.8402",
        "lon": "11.1352",
        "name": "Ryumsjoen",
        "city": "Rorvik",
        "state": "Nord-Trondelag",
        "country": "Norway",
        "woeid": "12523946",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1969",
        "elev": "11",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "RVN",
        "lat": "66.5628",
        "lon": "25.8266",
        "name": "Rovaniemi",
        "city": "Saarenkylä",
        "state": "Lapland",
        "country": "Finland",
        "woeid": "12512798",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "645",
        "icao": "EFRO",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "SCC",
        "lat": "70.1975",
        "lon": "-148.458",
        "name": "Deadhorse",
        "city": "Prudhoe Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519437",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "45",
        "icao": "PASC",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "SCE",
        "lat": "40.8515",
        "lon": "-77.851",
        "name": "University Park",
        "city": "State College",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12522252",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4065",
        "elev": "1228",
        "icao": "KUNV",
        "direct_flights": "4",
        "carriers": "8"
    },
    {
        "code": "SDL",
        "lat": "62.5249",
        "lon": "17.4407",
        "name": "Sundsvall Harnosand",
        "city": "Bergeforsen",
        "state": "Vasternorrland",
        "country": "Sweden",
        "woeid": "23322231",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "13",
        "icao": "KSDL",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "SDN",
        "lat": "61.8296",
        "lon": "6.10628",
        "name": "Sandane",
        "city": "Sandene",
        "state": "Sogn og Fjordane Fylke",
        "country": "Norway",
        "woeid": "12523947",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "196",
        "icao": "ENSD",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SDU",
        "lat": "-22.9107",
        "lon": "-43.1707",
        "name": "Santos Dumont",
        "city": "Rio de Janeiro",
        "state": "Rio de Janeiro",
        "country": "Brazil",
        "woeid": "12511312",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4341",
        "elev": "11",
        "icao": "SBRJ",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "SEB",
        "lat": "26.9933",
        "lon": "14.4669",
        "name": "Sebha",
        "city": "Sabha",
        "state": "Sabha",
        "country": "Libya",
        "woeid": "12514670",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11877",
        "elev": "1427",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "SKN",
        "lat": "68.5802",
        "lon": "15.0325",
        "name": "Stokmarknes",
        "city": "Stokkmarknes",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12515125",
        "tz": "EuropeOslo",
        "phone": "76 16 11 75",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "11",
        "icao": "ENSK",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SLH",
        "lat": "-14.2459",
        "lon": "167.509",
        "name": "Torba",
        "city": "Sola",
        "state": "Torba",
        "country": "Vanuatu",
        "woeid": "20069883",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2296",
        "elev": "7",
        "icao": "KSLH",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SNW",
        "lat": "18.4587",
        "lon": "94.3696",
        "name": "Myanmar",
        "city": "Thandwe",
        "state": "Rakhine State",
        "country": "Myanmar",
        "woeid": "23424763",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "SPP",
        "lat": "-14.6542",
        "lon": "17.7247",
        "name": "Menongue East",
        "city": "Menongue",
        "state": "Cuando Cubango",
        "country": "Angola",
        "woeid": "12510445",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "4616",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SPR",
        "lat": "17.9167",
        "lon": "-87.9667",
        "name": "San Pedro",
        "city": "San Pedro",
        "state": "Belize",
        "country": "Belize",
        "woeid": "12524036",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2575",
        "elev": "2",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SRG",
        "lat": "-6.9758",
        "lon": "110.38",
        "name": "Achmad Yani",
        "city": "Semarang",
        "state": "Jawa Tengah",
        "country": "Indonesia",
        "woeid": "12513449",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5414",
        "elev": "10",
        "icao": "WARS",
        "direct_flights": "4",
        "carriers": "6"
    },
    {
        "code": "SSR",
        "lat": "-15.1468",
        "lon": "167.038",
        "name": "Vanuatu",
        "city": "Sara",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1575",
        "elev": "371",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "STM",
        "lat": "-2.4244",
        "lon": "-54.7853",
        "name": "Santarem",
        "city": "Santarém",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511308",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7881",
        "elev": "197",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "STS",
        "lat": "38.511",
        "lon": "-122.805",
        "name": "Sonoma County",
        "city": "Windsor",
        "state": "California",
        "country": "United States",
        "woeid": "12521904",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5115",
        "elev": "125",
        "icao": "KSTS",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "SUN",
        "lat": "43.5065",
        "lon": "-114.301",
        "name": "Friedman Memorial",
        "city": "Hailey",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12519829",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6600",
        "elev": "5313",
        "icao": "KSUN",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "SVB",
        "lat": "-14.2505",
        "lon": "50.1539",
        "name": "Sambava",
        "city": "Sambava",
        "state": "Antsiranana",
        "country": "Madagascar",
        "woeid": "1364128",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "16",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "SXP",
        "lat": "62.5333",
        "lon": "-164.833",
        "name": "Sheldon SPB",
        "city": "Sheldon Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2492171",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "80",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "TAI",
        "lat": "13.6889",
        "lon": "44.1375",
        "name": "Taiz Ganed",
        "city": "Al-Ganad",
        "state": "Ta`izz",
        "country": "Yemen",
        "woeid": "12523006",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "TBG",
        "lat": "-7.18482",
        "lon": "142.374",
        "name": "Western",
        "city": "Tabubil",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "2346586",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "1500",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "TCB",
        "lat": "26.7461",
        "lon": "-77.3931",
        "name": "Treasure Cay",
        "city": "Green Turtle Cay",
        "state": "North Abaco",
        "country": "Bahamas",
        "woeid": "12510884",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "TEN",
        "lat": "35.5089",
        "lon": "102.011",
        "name": "Tongren",
        "city": "Tongren",
        "state": "Qinghai",
        "country": "China",
        "woeid": "2138839",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "TGZ",
        "lat": "16.7683",
        "lon": "-93.3422",
        "name": "Tuxtla Gutierrez",
        "city": "San Fernando",
        "state": "Chiapas",
        "country": "Mexico",
        "woeid": "12514981",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMTG",
        "direct_flights": "4",
        "carriers": "7"
    },
    {
        "code": "THN",
        "lat": "58.3161",
        "lon": "12.3507",
        "name": "Trollhattan Vanersborg",
        "city": "Trollhatan",
        "state": "Vastra Gotaland",
        "country": "Sweden",
        "woeid": "12517685",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.fyrstadsflyget.se",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "TKU",
        "lat": "60.5138",
        "lon": "22.272",
        "name": "Turku",
        "city": "Turku",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12523801",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "161",
        "icao": "EFTU",
        "direct_flights": "4",
        "carriers": "10"
    },
    {
        "code": "TLN",
        "lat": "43.093",
        "lon": "6.15712",
        "name": "Le Palyvestre",
        "city": "Hyeres",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12512920",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6955",
        "elev": "7",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "TTQ",
        "lat": "10.5667",
        "lon": "-83.5167",
        "name": "Tortuquero",
        "city": "Colorado",
        "state": "Limon",
        "country": "Costa Rica",
        "woeid": "12524322",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "TUI",
        "lat": "31.6917",
        "lon": "38.7289",
        "name": "Turaif",
        "city": "Turayf",
        "state": "Al Hudud ash Shamaliyah",
        "country": "Saudi Arabia",
        "woeid": "12517375",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "TVU",
        "lat": "-16.8494",
        "lon": "-179.97",
        "name": "Taveuni",
        "city": "Taveuni",
        "state": "Northern",
        "country": "Fiji",
        "woeid": "12512812",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "TZA",
        "lat": "17.5347",
        "lon": "-88.3028",
        "name": "Belize City Municipal",
        "city": "Hattieville",
        "state": "Belize",
        "country": "Belize",
        "woeid": "12523291",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1740",
        "elev": "5",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "UEL",
        "lat": "-17.8569",
        "lon": "36.8556",
        "name": "Quelimane",
        "city": "Quelimane",
        "state": "Zambezia",
        "country": "Mozambique",
        "woeid": "12515029",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "36",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "UGC",
        "lat": "41.5434",
        "lon": "60.6368",
        "name": "Urganch",
        "city": "Urgench",
        "state": "Qoraqalpoghiston",
        "country": "Uzbekistan",
        "woeid": "2272233",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "UNK",
        "lat": "63.8833",
        "lon": "-160.783",
        "name": "Unalakleet",
        "city": "Unalakleet",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799735",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "21",
        "icao": "PAUN",
        "direct_flights": "4",
        "carriers": "8"
    },
    {
        "code": "UVE",
        "lat": "-20.5542",
        "lon": "166.571",
        "name": "Ouloup",
        "city": "Fayaoué",
        "state": "Loyaute",
        "country": "New Caledonia",
        "woeid": "12515040",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3608",
        "elev": "23",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "VAS",
        "lat": "39.8147",
        "lon": "36.9039",
        "name": "Sivas",
        "city": "Sivas",
        "state": "Sivas",
        "country": "Turkey",
        "woeid": "12517914",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12500",
        "elev": "5222",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "VAV",
        "lat": "-18.65",
        "lon": "-173.967",
        "name": "Lupepau'u",
        "city": "Neiafu",
        "state": "Vava'eu",
        "country": "Tonga",
        "woeid": "12523256",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "VEE",
        "lat": "67.009",
        "lon": "-146.406",
        "name": "Venetie",
        "city": "Venetie",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2511598",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4100",
        "elev": "550",
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "VIJ",
        "lat": "18.4728",
        "lon": "-64.418",
        "name": "Virgin Gorda",
        "city": "Virgin Gorda",
        "state": "",
        "country": "British Virgin Islands",
        "woeid": "12522861",
        "tz": "AmericaTortola",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3160",
        "elev": "12",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "VIL",
        "lat": "23.7333",
        "lon": "-15.9167",
        "name": "Dakhla",
        "city": "Ad Dakhla",
        "state": "Oued Ed-Dahab",
        "country": "Western Sahara",
        "woeid": "12514785",
        "tz": "AfricaEl_Aaiun",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "33",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "VIT",
        "lat": "42.8844",
        "lon": "-2.73051",
        "name": "Vitoria",
        "city": "Vitoria",
        "state": "Basque Country",
        "country": "Spain",
        "woeid": "12517580",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "1683",
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "VQS",
        "lat": "18.1355",
        "lon": "-65.487",
        "name": "Aeropuerto Antonio Rivera Rodríguez",
        "city": "Puerto Ferro",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12524420",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "46",
        "icao": "TJVQ",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "WAA",
        "lat": "65.6112",
        "lon": "-168.098",
        "name": "Wales",
        "city": "Wales",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2513417",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "25",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "WBB",
        "lat": "63.52",
        "lon": "-162.279",
        "name": "Stebbins",
        "city": "Stebbins",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2499397",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2800",
        "elev": "26",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "WEF",
        "lat": "36.3306",
        "lon": "118.761",
        "name": "Shandong",
        "city": "Weifang",
        "state": "Shandong",
        "country": "China",
        "woeid": "12578014",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "WMN",
        "lat": "-15.4367",
        "lon": "49.6883",
        "name": "Maroantsetra",
        "city": "Maroantsetra",
        "state": "Toamasina",
        "country": "Madagascar",
        "woeid": "12514702",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "13",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "WWK",
        "lat": "-3.5858",
        "lon": "143.667",
        "name": "Wewak",
        "city": "Wewak",
        "state": "East Sepik",
        "country": "Papua New Guinea",
        "woeid": "12515480",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "19",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "WXN",
        "lat": "30.811",
        "lon": "108.375",
        "name": "Wanxian",
        "city": "Wanxian",
        "state": "Chongqing",
        "country": "China",
        "woeid": "2158443",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "XBE",
        "lat": "53.9214",
        "lon": "-90.985",
        "name": "Bearskin Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524054",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "826",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "XBR",
        "lat": "44.6353",
        "lon": "-75.7502",
        "name": "Brockville",
        "city": "Brockville",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511588",
        "tz": "",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3516",
        "elev": "398",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "XFN",
        "lat": "32.05",
        "lon": "112.017",
        "name": "Xiangfan",
        "city": "Xiangfan",
        "state": "湖北省",
        "country": "China",
        "woeid": "12713146",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "XVG",
        "lat": "46.9929",
        "lon": "-94.2075",
        "name": "Longville Municipal",
        "city": "Longville",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12520699",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KXVG",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "XXT",
        "lat": "43.6167",
        "lon": "7.05",
        "name": "Sophia Antipolis Heliport",
        "city": "Valbonne",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12523829",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YAX",
        "lat": "53.2519",
        "lon": "-89.565",
        "name": "Angling Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524079",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYAX",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YAY",
        "lat": "51.3883",
        "lon": "-56.0901",
        "name": "St Anthony",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511843",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "15",
        "icao": "CYAY",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YCS",
        "lat": "63.3358",
        "lon": "-90.734",
        "name": "Chesterfield Inlet",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524093",
        "tz": "AmericaWinnipeg",
        "phone": "867-898-9940",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "35",
        "icao": "CYCS",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YFC",
        "lat": "45.873",
        "lon": "-66.5296",
        "name": "Fredericton",
        "city": "Fredericton",
        "state": "New Brunswick",
        "country": "Canada",
        "woeid": "12511671",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "67",
        "icao": "CYFC",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YFH",
        "lat": "51.5466",
        "lon": "-87.9859",
        "name": "Fort Hope",
        "city": "Marathon",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524118",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "905",
        "icao": "CYFH",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YGL",
        "lat": "53.6256",
        "lon": "-77.705",
        "name": "La Grande Riviere",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511720",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "638",
        "icao": "CYGL",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YGO",
        "lat": "54.55",
        "lon": "-94.4833",
        "name": "Gods Lake Narrows",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524127",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2280",
        "elev": "620",
        "icao": "CYGO",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YGT",
        "lat": "69.3674",
        "lon": "-81.8254",
        "name": "Igloolik",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524129",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3700",
        "elev": "174",
        "icao": "CYGT",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YHK",
        "lat": "68.6337",
        "lon": "-95.8561",
        "name": "Gjoa Haven",
        "city": "Cambridge Bay",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524138",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4400",
        "elev": "150",
        "icao": "CYHK",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YHU",
        "lat": "45.5087",
        "lon": "-73.4302",
        "name": "Montreal St Hubert",
        "city": "St-Hubert",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511759",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7840",
        "elev": "90",
        "icao": "CYHU",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YIO",
        "lat": "72.6806",
        "lon": "-77.9981",
        "name": "Pond Inlet",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524147",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "188",
        "icao": "CYIO",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YKL",
        "lat": "54.8056",
        "lon": "-66.8067",
        "name": "Schefferville",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511828",
        "tz": "AmericaIqaluit",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1712",
        "icao": "CYKL",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YKU",
        "lat": "53.8167",
        "lon": "-79",
        "name": "Chisasibi Aerodrome",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524160",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "22",
        "icao": "",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YMM",
        "lat": "56.6512",
        "lon": "-111.246",
        "name": "Fort Mcmurray",
        "city": "Fort Chipewyan",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511665",
        "tz": "AmericaEdmonton",
        "phone": "780 791-2448",
        "type": "Airports",
        "email": "",
        "url": "http:www.fortmcmurrayairport.com",
        "runway_length": "6000",
        "elev": "1211",
        "icao": "CYMM",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YNA",
        "lat": "50.1903",
        "lon": "-61.79",
        "name": "Natashquan",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511769",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "35",
        "icao": "CYNA",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YNZ",
        "lat": "33.3856",
        "lon": "120.125",
        "name": "Yancheng",
        "city": "Yancheng",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "2137091",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YQK",
        "lat": "49.7893",
        "lon": "-94.3676",
        "name": "Kenora",
        "city": "Kenora",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511713",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1342",
        "icao": "CYQK",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "YQY",
        "lat": "46.1675",
        "lon": "-60.0473",
        "name": "Sydney",
        "city": "Sydney",
        "state": "Nova Scotia",
        "country": "Canada",
        "woeid": "12511865",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7070",
        "elev": "203",
        "icao": "CYQY",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YRB",
        "lat": "74.7167",
        "lon": "-94.9667",
        "name": "Resolute",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12523273",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "221",
        "icao": "CYRB",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YSJ",
        "lat": "45.3294",
        "lon": "-65.8903",
        "name": "Saint John",
        "city": "St. John",
        "state": "New Brunswick",
        "country": "Canada",
        "woeid": "12511822",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "356",
        "icao": "CYSJ",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YTS",
        "lat": "48.5655",
        "lon": "-81.3718",
        "name": "Timmins",
        "city": "South Porcupine",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511877",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYTS",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YVB",
        "lat": "48.0717",
        "lon": "-65.4614",
        "name": "Bonaventure",
        "city": "Percé",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511580",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "120",
        "icao": "CYVB",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YVO",
        "lat": "48.0623",
        "lon": "-77.7896",
        "name": "Val d'or",
        "city": "Val-d'or",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511891",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYVO",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YWH",
        "lat": "70.9166",
        "lon": "-109.985",
        "name": "Inner Harbour",
        "city": "Hay River",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12511702",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYWH",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "YYD",
        "lat": "54.8247",
        "lon": "-127.181",
        "name": "Smithers",
        "city": "Smithers",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511833",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1717",
        "icao": "CYYD",
        "direct_flights": "4",
        "carriers": "4"
    },
    {
        "code": "YYY",
        "lat": "48.6069",
        "lon": "-68.2111",
        "name": "Mont Joli",
        "city": "Mont-Joli",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511755",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "172",
        "icao": "CYYY",
        "direct_flights": "4",
        "carriers": "2"
    },
    {
        "code": "YZG",
        "lat": "62.1853",
        "lon": "-75.6685",
        "name": "Salluit",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524276",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYZG",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "ZAH",
        "lat": "29.4764",
        "lon": "60.9044",
        "name": "Zahedan",
        "city": "Dowzdab",
        "state": "Sistan va Baluchestan",
        "country": "Iran",
        "woeid": "12513773",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.Iranairports.com",
        "runway_length": "14000",
        "elev": "4517",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ZBR",
        "lat": "25.445",
        "lon": "60.3819",
        "name": "Chah Bahar",
        "city": "Chabahar",
        "state": "Sistan va Baluchestan",
        "country": "Iran",
        "woeid": "12513711",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12630",
        "elev": "23",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ZCO",
        "lat": "-38.7639",
        "lon": "-72.6378",
        "name": "Maquehue",
        "city": "Padre Las Casas",
        "state": "Araucania",
        "country": "Chile",
        "woeid": "12512332",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "SCTC",
        "direct_flights": "4",
        "carriers": "5"
    },
    {
        "code": "ZGI",
        "lat": "54.6768",
        "lon": "-94.1459",
        "name": "Gods River",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524291",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "600",
        "icao": "CZGI",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "ZSA",
        "lat": "24.06",
        "lon": "-74.5331",
        "name": "San Salvador",
        "city": "Cockburn Town",
        "state": "San Salvador",
        "country": "Bahamas",
        "woeid": "12510879",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "10",
        "icao": "",
        "direct_flights": "4",
        "carriers": "3"
    },
    {
        "code": "ZSJ",
        "lat": "53.0513",
        "lon": "-93.2491",
        "name": "Sandy Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524306",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "937",
        "icao": "CZSJ",
        "direct_flights": "4",
        "carriers": "1"
    },
    {
        "code": "GB",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "England",
        "city": "Inghilterra",
        "state": "England",
        "country": "England",
        "woeid": "20081243",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "FR",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "France",
        "city": "Francia",
        "state": "France",
        "country": "France",
        "woeid": "20081243",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "PT",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Portugal",
        "city": "Portogallo",
        "state": "Portugal",
        "country": "Portugal",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "DE",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Germany",
        "city": "Germania",
        "state": "Germany",
        "country": "Germany",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "IE",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Ireland",
        "city": "Irlanda",
        "state": "Ireland",
        "country": "Ireland",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "BE",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Belgium",
        "city": "Belgio",
        "state": "Belgium",
        "country": "Belgium",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "NL",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Netherlands",
        "city": "Olanda",
        "state": "Netherlands",
        "country": "Netherlands",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "GR",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Greece",
        "city": "Grecia",
        "state": "Greece",
        "country": "Greece",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "CH",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Switzerland",
        "city": "Svizzera",
        "state": "Switzerland",
        "country": "Switzerland",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "PL",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Poland",
        "city": "Polonia",
        "state": "Poland",
        "country": "Poland",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "UA",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Ukraine",
        "city": "Ucraina",
        "state": "Ukraine",
        "country": "Ukraine",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "SE",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Sweden",
        "city": "Svezia",
        "state": "Sweden",
        "country": "Sweden",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "DK",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Denmark",
        "city": "Danimarca",
        "state": "Denmark",
        "country": "Denmark",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "AT",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Austria",
        "city": "Austria",
        "state": "Austria",
        "country": "Austria",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "HR",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Croatia",
        "city": "Croazia",
        "state": "Croatia",
        "country": "Croatia",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "HU",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Hungary",
        "city": "Ungheria",
        "state": "Hungary",
        "country": "Hungary",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "NO",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Norway",
        "city": "Norvegia",
        "state": "Norway",
        "country": "Norway",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "CZ",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Czech Republic",
        "city": "Repubblica Ceca",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "MT",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Malta",
        "city": "Malta",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "RO",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Romania",
        "city": "Romania",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "FI",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Finland",
        "city": "Finlandia",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "IS",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Iceland",
        "city": "Islanda",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "RS",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Serbia",
        "city": "Serbia",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "BG",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Bulgaria",
        "city": "Bulgaria",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Europe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "MA",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Marocco",
        "city": "Marocco",
        "state": "",
        "country": "",
        "woeid": "20081243",
        "tz": "Africa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2",
        "isCountry": true
    },
    {
        "code": "EBA",
        "lat": "42.7629",
        "lon": "10.2383",
        "name": "Marina de Campo",
        "city": "Pianosa",
        "state": "Tuscany",
        "country": "Italy",
        "woeid": "12523910",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3855",
        "elev": "26",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SWK",
        "lat": "45.4738",
        "lon": "9.2986",
        "name": "Segrate",
        "city": "Trezzano sul Naviglio",
        "state": "Lombardy",
        "country": "Italy",
        "woeid": "12523358",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "AAY",
        "lat": "16.1947",
        "lon": "52.1694",
        "name": "Al Gaidah ",
        "city": "Al Ghaydah",
        "state": "Hadramawt",
        "country": "Yemen",
        "woeid": "12523000",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "98",
        "icao": "OYGD",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ABC",
        "lat": "38.9833",
        "lon": "-1.85",
        "name": "Los Llanos",
        "city": "Albacete",
        "state": "Castilla-la Mancha",
        "country": "Spain",
        "woeid": "20081243",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LEAB",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ABR",
        "lat": "45.4536",
        "lon": "-98.4189",
        "name": "Aberdeen",
        "city": "Aberdeen",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12518514",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6904",
        "elev": "1301",
        "icao": "KABR",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "ABT",
        "lat": "20.2961",
        "lon": "41.6342",
        "name": "Al Baha",
        "city": "Al Aqiq",
        "state": "Al Baha",
        "country": "Saudi Arabia",
        "woeid": "12517321",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10991",
        "elev": "5486",
        "icao": "OEBA",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ABX",
        "lat": "-36.0678",
        "lon": "146.956",
        "name": "Albury",
        "city": "Albury",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510583",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "539",
        "icao": "YMAY",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "AGT",
        "lat": "-25.5266",
        "lon": "-54.6226",
        "name": "Alejo Garcia",
        "city": "Presidente Franco",
        "state": "Alto Paraná",
        "country": "Paraguay",
        "woeid": "12523540",
        "tz": "AmericaAsuncion",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "SGES",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "AHE",
        "lat": "-14.5",
        "lon": "-1.3",
        "name": "Ahe",
        "city": "Ahe",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "24549702",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "AIA",
        "lat": "42.0622",
        "lon": "-102.81",
        "name": "Alliance Municipal",
        "city": "Alliance",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12518583",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9202",
        "elev": "3929",
        "icao": "KAIA",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "AJL",
        "lat": "23.7361",
        "lon": "92.8083",
        "name": "Aizwal",
        "city": "Aizawl",
        "state": "Mizoram",
        "country": "India",
        "woeid": "12513530",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3131",
        "elev": "1000",
        "icao": "VEAZ",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "AKP",
        "lat": "68.1916",
        "lon": "-151.79",
        "name": "Anaktuvuk Pass",
        "city": "Anaktuvuk Pass",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524556",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "2100",
        "icao": "PAKP",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "AKY",
        "lat": "20.1303",
        "lon": "92.8803",
        "name": "Sittwe",
        "city": "Sittwe",
        "state": "Rakhine State",
        "country": "Myanmar",
        "woeid": "12510936",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "28",
        "icao": "VYSW",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "ANM",
        "lat": "-18.7772",
        "lon": "46.8543",
        "name": "Madagascar",
        "city": "Antalaha",
        "state": null,
        "country": "Madagascar",
        "woeid": "23424883",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3914",
        "elev": "20",
        "icao": "FMNH",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ANR",
        "lat": "51.1896",
        "lon": "4.45236",
        "name": "Deurne",
        "city": "Antwerp",
        "state": "Antwerp",
        "country": "Belgium",
        "woeid": "22028955",
        "tz": "EuropeBrussels",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4839",
        "elev": "39",
        "icao": "EBAW",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "AQP",
        "lat": "-16.3372",
        "lon": "-71.5692",
        "name": "Rodriguez Ballon",
        "city": "Arequipa",
        "state": "Arequipa",
        "country": "Peru",
        "woeid": "12515218",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9777",
        "elev": "8365",
        "icao": "SPQU",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "ARC",
        "lat": "68.1167",
        "lon": "-145.583",
        "name": "Arctic Village",
        "city": "Arctic Village",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524562",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4450",
        "elev": "2086",
        "icao": "PARC",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "ARI",
        "lat": "-18.3458",
        "lon": "-70.3358",
        "name": "Chacalluta",
        "city": "Arica",
        "state": "Tarapaca",
        "country": "Chile",
        "woeid": "12512313",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7119",
        "elev": "180",
        "icao": "SCAR",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "ASJ",
        "lat": "28.431",
        "lon": "129.712",
        "name": "Amami",
        "city": "Amami O Shima",
        "state": "Mie Prefecture",
        "country": "Japan",
        "woeid": "12513942",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4070",
        "elev": "59",
        "icao": "RJKA",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ATT",
        "lat": "60.865",
        "lon": "-162.276",
        "name": "Atmautluak",
        "city": "Atmautluak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524566",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2250",
        "elev": "17",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "ATY",
        "lat": "44.9216",
        "lon": "-97.1609",
        "name": "Watertown Municipal",
        "city": "Watertown",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12522349",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6895",
        "elev": "1748",
        "icao": "KATY",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "AUG",
        "lat": "44.3181",
        "lon": "-69.7933",
        "name": "Augusta State",
        "city": "Augusta",
        "state": "Maine",
        "country": "United States",
        "woeid": "12518700",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5140",
        "elev": "353",
        "icao": "KAUG",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "AUK",
        "lat": "62.6833",
        "lon": "-164.633",
        "name": "Alakanuk",
        "city": "Alakanuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524567",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "10",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "AUX",
        "lat": "-7.2281",
        "lon": "-48.2403",
        "name": "Araguaina",
        "city": "Araguaina",
        "state": "Norte",
        "country": "Brazil",
        "woeid": "12511029",
        "tz": "AmericaAraguaina",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "771",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "AXU",
        "lat": "14.147",
        "lon": "38.7726",
        "name": "Axum",
        "city": "Axum",
        "state": "Tigray",
        "country": "Ethiopia",
        "woeid": "1313280",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5347",
        "elev": "7000",
        "icao": "HAAX",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BAU",
        "lat": "-22.345",
        "lon": "-49.0531",
        "name": "Bauru",
        "city": "Bauru",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511049",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "2018",
        "icao": "SBBU",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BBO",
        "lat": "10.3917",
        "lon": "44.95",
        "name": "Berbera",
        "city": "Berbera",
        "state": "Woqooyi Galbeed",
        "country": "Somalia",
        "woeid": "12517527",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13615",
        "elev": "30",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BDJ",
        "lat": "-3.4425",
        "lon": "114.757",
        "name": "Syamsuddin Noor",
        "city": "Banjarmasin",
        "state": "Kalimantan Selatan",
        "country": "Indonesia",
        "woeid": "12513514",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6135",
        "elev": "69",
        "icao": "WRBB",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "BDQ",
        "lat": "22.3367",
        "lon": "73.2264",
        "name": "Vadodara",
        "city": "Vadodara",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513692",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8100",
        "elev": "123",
        "icao": "VABO",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "BEB",
        "lat": "57.4756",
        "lon": "-7.37041",
        "name": "Benbecula",
        "city": "Balivanich",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22453706",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6017",
        "elev": "19",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "BFD",
        "lat": "41.7964",
        "lon": "-78.6407",
        "name": "Bradford",
        "city": "Lewis Run",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12518914",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6499",
        "elev": "2143",
        "icao": "KBFD",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BGM",
        "lat": "42.2082",
        "lon": "-75.9825",
        "name": "Greater Binghamton Edwin A Link Field",
        "city": "Johnson City",
        "state": "New York",
        "country": "United States",
        "woeid": "12519598",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6298",
        "elev": "1630",
        "icao": "KBGM",
        "direct_flights": "3",
        "carriers": "8"
    },
    {
        "code": "BGW",
        "lat": "33.3328",
        "lon": "44.3639",
        "name": "Al Muthana",
        "city": "Baghdad",
        "state": "Baghdad",
        "country": "Iraq",
        "woeid": "12513890",
        "tz": "AsiaBaghdad",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "112",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "BHE",
        "lat": "-41.52",
        "lon": "173.87",
        "name": "Woodbourne",
        "city": "Blenheim",
        "state": "Marlborough",
        "country": "New Zealand",
        "woeid": "12515181",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4675",
        "elev": "118",
        "icao": "NZWB",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BHQ",
        "lat": "-32.0031",
        "lon": "141.466",
        "name": "Broken Hill",
        "city": "Broken Hill",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510619",
        "tz": "AustraliaMelbourne",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8251",
        "elev": "946",
        "icao": "YBHI",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BHV",
        "lat": "29.3444",
        "lon": "71.7125",
        "name": "Bahawalpur",
        "city": "Bahawalpur",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "12515225",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "100",
        "icao": "OPBW",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BJR",
        "lat": "11.5564",
        "lon": "37.3687",
        "name": "Ethiopia",
        "city": "Bahar Dar",
        "state": "Misrak Gojam",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5217",
        "elev": "6020",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BMV",
        "lat": "12.6667",
        "lon": "108.05",
        "name": "Ban Me Thaut",
        "city": "Banmethuot",
        "state": "Dak Lak",
        "country": "Vietnam",
        "woeid": "1233132",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1758",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BNJ",
        "lat": "50.7667",
        "lon": "7.16449",
        "name": "Hangelar",
        "city": "Bonn",
        "state": "North-Rhine-Westphalia",
        "country": "Germany",
        "woeid": "695997",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2",
        "bookingId": "-1750167"
    },
    {
        "code": "BNK",
        "lat": "-28.8342",
        "lon": "153.555",
        "name": "Ballina",
        "city": "Ballina",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510596",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "YBNA",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "BNS",
        "lat": "8.6211",
        "lon": "-70.2181",
        "name": "Barinas",
        "city": "Barinas",
        "state": "Barinas",
        "country": "Venezuela",
        "woeid": "12522757",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "666",
        "icao": "SVBI",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BNX",
        "lat": "44.9403",
        "lon": "17.2992",
        "name": "Banja Luka",
        "city": "Banja Luka",
        "state": "Republika Srpska",
        "country": "Bosnia and Herzegovina",
        "woeid": "12510897",
        "tz": "EuropeSarajevo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.banjaluka-airport.com",
        "runway_length": "8215",
        "elev": "390",
        "icao": "LQBK",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BPS",
        "lat": "-16.4406",
        "lon": "-39.0833",
        "name": "Porto Seguro",
        "city": "Porto Seguro",
        "state": "Bahia",
        "country": "Brazil",
        "woeid": "12511284",
        "tz": "AmericaBahia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5351",
        "elev": "161",
        "icao": "SBPS",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "BSA",
        "lat": "11.2775",
        "lon": "49.1814",
        "name": "Somalia",
        "city": "Bossaso",
        "state": "Bari",
        "country": "Somalia",
        "woeid": "23424949",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3477",
        "elev": "6",
        "icao": "HCMF",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BSG",
        "lat": "1.9046",
        "lon": "9.8032",
        "name": "Bata",
        "city": "Bata",
        "state": "Litoral",
        "country": "Equatorial Guinea",
        "woeid": "12512729",
        "tz": "AfricaMalabo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BSK",
        "lat": "34.7933",
        "lon": "5.74",
        "name": "Biskra",
        "city": "Biskra",
        "state": "Biskra",
        "country": "Algeria",
        "woeid": "12510315",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9514",
        "elev": "285",
        "icao": "DAUB",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BTM",
        "lat": "45.9544",
        "lon": "-112.503",
        "name": "Bert Mooney",
        "city": "Butte",
        "state": "Montana",
        "country": "United States",
        "woeid": "12518811",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "5553",
        "icao": "KBTM",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "BTT",
        "lat": "66.9134",
        "lon": "-151.551",
        "name": "Bettles",
        "city": "Bettles",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524591",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5199",
        "elev": "643",
        "icao": "PABT",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "BUO",
        "lat": "65.8514",
        "lon": "12.344",
        "name": "Burao",
        "city": "Burao",
        "state": "Togdheer",
        "country": "Somalia",
        "woeid": "1425676",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7875",
        "elev": "3400",
        "icao": "HCMV",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "BUQ",
        "lat": "-20.0169",
        "lon": "28.6181",
        "name": "Bulawayo",
        "city": "Bulawayo",
        "state": "Matabeleland North",
        "country": "Zimbabwe",
        "woeid": "12523021",
        "tz": "AfricaHarare",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8471",
        "elev": "4366",
        "icao": "FVBU",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BWE",
        "lat": "52.3165",
        "lon": "10.5595",
        "name": "Braunschweig",
        "city": "Braunschweig",
        "state": "Lower Saxony",
        "country": "Germany",
        "woeid": "22176819",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5315",
        "elev": "291",
        "icao": "EDVE",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BZR",
        "lat": "43.3229",
        "lon": "3.35466",
        "name": "Vias",
        "city": "Villeneuve",
        "state": "Languedoc-Roussillon",
        "country": "France",
        "woeid": "12512985",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5971",
        "elev": "56",
        "icao": "LFMU",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "CAZ",
        "lat": "-31.5334",
        "lon": "145.797",
        "name": "Cobar",
        "city": "Canbelego",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510635",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "722",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "CBO",
        "lat": "7.165",
        "lon": "124.208",
        "name": "Cotabato",
        "city": "Sultan Kudarat",
        "state": "Autonomous Region in Muslim Mind",
        "country": "Philippines",
        "woeid": "12515608",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6233",
        "elev": "187",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "CDR",
        "lat": "42.8284",
        "lon": "-103.094",
        "name": "Chadron Municipal",
        "city": "Chadron",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12519125",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6002",
        "elev": "3296",
        "icao": "KCDR",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "CDV",
        "lat": "60.4924",
        "lon": "-145.475",
        "name": "Merle K Mudhole Smith",
        "city": "Cordova",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520907",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7499",
        "elev": "42",
        "icao": "PACV",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "CEI",
        "lat": "19.8864",
        "lon": "99.8306",
        "name": "Chiang Rai",
        "city": "Chiang Rai",
        "state": "Chiang Rai",
        "country": "Thailand",
        "woeid": "12517751",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4890",
        "elev": "1365",
        "icao": "VTCT",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "CHT",
        "lat": "-37.9723",
        "lon": "174.566",
        "name": "Karewa",
        "city": "Chatham Island",
        "state": "Canterbury",
        "country": "New Zealand",
        "woeid": "28645780",
        "tz": "PacificChatham",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4462",
        "elev": "43",
        "icao": "KCHT",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "CIX",
        "lat": "-6.7897",
        "lon": "-79.8322",
        "name": "Cap J A Quinones Gonzales",
        "city": "Chiclayo",
        "state": "Lambayeque",
        "country": "Peru",
        "woeid": "12515192",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8267",
        "elev": "95",
        "icao": "SPHI",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "CMI",
        "lat": "40.0367",
        "lon": "-88.2638",
        "name": "University of Illinois-Willard",
        "city": "Savoy",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12522247",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flycmi.com",
        "runway_length": "8100",
        "elev": "754",
        "icao": "KCMI",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "CNQ",
        "lat": "-27.4497",
        "lon": "-58.7622",
        "name": "Corrientes",
        "city": "Corrientes",
        "state": "Corrientes",
        "country": "Argentina",
        "woeid": "12510484",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "203",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "COU",
        "lat": "38.8174",
        "lon": "-92.2218",
        "name": "Columbia",
        "city": "Columbia",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12519286",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "889",
        "icao": "KCOU",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "CPE",
        "lat": "19.8158",
        "lon": "-90.5008",
        "name": "Ignacio Alberto Acuna Ongay",
        "city": "Campeche",
        "state": "Campeche",
        "country": "Mexico",
        "woeid": "12514891",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "31",
        "icao": "MMCP",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "CSY",
        "lat": "56.1311",
        "lon": "47.2882",
        "name": "Cheboksary",
        "city": "Cheboksary",
        "state": "Chuvashiya",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UWKS",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "CTL",
        "lat": "-26.4135",
        "lon": "146.263",
        "name": "Charleville Aerodrome",
        "city": "Charleville",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510629",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1001",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "CYF",
        "lat": "60.1558",
        "lon": "-164.273",
        "name": "Chefornak",
        "city": "Chefornak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524634",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "1850",
        "elev": "40",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "DAB",
        "lat": "29.1874",
        "lon": "-81.0615",
        "name": "Daytona Beach",
        "city": "Daytona Beach",
        "state": "Florida",
        "country": "United States",
        "woeid": "12519432",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "35",
        "icao": "KDAB",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "DAT",
        "lat": "45.3666",
        "lon": "-102.682",
        "name": "Datong",
        "city": "Datong",
        "state": "Shanxi",
        "country": "China",
        "woeid": "26198304",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "DAX",
        "lat": "31.1336",
        "lon": "107.419",
        "name": "Daxian",
        "city": "Daxian",
        "state": "Sichuan",
        "country": "China",
        "woeid": "12512033",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "DEA",
        "lat": "40.2483",
        "lon": "-74.0007",
        "name": "Dera Ghazi Khan",
        "city": "Dera Ghazi Khan",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "2210903",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "DGA",
        "lat": "16.9757",
        "lon": "-88.235",
        "name": "Dangriga",
        "city": "Dangriga",
        "state": "Stann Creek",
        "country": "Belize",
        "woeid": "12524030",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "DIB",
        "lat": "27.4839",
        "lon": "95.0208",
        "name": "Mohanbari",
        "city": "Dikhari",
        "state": "Assam",
        "country": "India",
        "woeid": "12513633",
        "tz": "AsiaKolkata",
        "phone": "+91 (0) 373 2382523",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "361",
        "icao": "VEMN",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "DIL",
        "lat": "-8.5533",
        "lon": "125.526",
        "name": "Comoro",
        "city": "Dili",
        "state": "Dili",
        "country": "Indonesia",
        "woeid": "12513462",
        "tz": "AsiaDili",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "7",
        "icao": "WPDL",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "DLY",
        "lat": "-16.6619",
        "lon": "168.38",
        "name": "Vanuatu",
        "city": "Dillons Bay",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2165",
        "elev": "538",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "DMD",
        "lat": "-18",
        "lon": "138.833",
        "name": "Doomadgee",
        "city": "Doomadgee",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708461",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5630",
        "elev": "153",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "DMU",
        "lat": "25.8839",
        "lon": "93.7736",
        "name": "Dimapur",
        "city": "Dimapur",
        "state": "Nagaland",
        "country": "India",
        "woeid": "12513581",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "485",
        "icao": "VEMR",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "DND",
        "lat": "56.4538",
        "lon": "-3.01347",
        "name": "Dundee",
        "city": "Dundee",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22463072",
        "tz": "EuropeLondon",
        "phone": "01382 662200",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3608",
        "elev": "13",
        "icao": "EGPN",
        "direct_flights": "3",
        "carriers": "2",
        "bookingId": "-2594859"
    },
    {
        "code": "DRO",
        "lat": "37.1595",
        "lon": "-107.751",
        "name": "Durango la Plata County",
        "city": "Durango",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519550",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9200",
        "elev": "6685",
        "icao": "KDRO",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "DSN",
        "lat": "39.8295",
        "lon": "109.976",
        "name": "Dongsheng",
        "city": "Dongsheng",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "12512040",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "EAS",
        "lat": "43.3557",
        "lon": "-1.79208",
        "name": "San Sebastian",
        "city": "Hondarribia",
        "state": "Basque Country",
        "country": "Spain",
        "woeid": "12517567",
        "tz": "EuropeMadrid",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5755",
        "elev": "16",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "EAU",
        "lat": "44.8625",
        "lon": "-91.4826",
        "name": "Eau Claire County",
        "city": "Eau Claire",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519585",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7300",
        "elev": "907",
        "icao": "KEAU",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "EFL",
        "lat": "38.1181",
        "lon": "20.5003",
        "name": "Cefalonia",
        "city": "Cefalonia",
        "state": "Nisia Ionioy",
        "country": "Greece",
        "woeid": "12513297",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7973",
        "elev": "60",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "EGO",
        "lat": "50.6406",
        "lon": "36.5853",
        "name": "Belgorod North",
        "city": "Belgorod",
        "state": "Belgorodskaya Oblast",
        "country": "Russia",
        "woeid": "12515790",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "EIB",
        "lat": "50.9853",
        "lon": "10.485",
        "name": "Haina",
        "city": "Eisenach",
        "state": "Rhineland-Palatinate",
        "country": "Germany",
        "woeid": "12513118",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1",
        "bookingId": "-1765079"
    },
    {
        "code": "EJH",
        "lat": "26.2003",
        "lon": "36.4758",
        "name": "Wejh",
        "city": "Wedjh",
        "state": "Tabuk",
        "country": "Saudi Arabia",
        "woeid": "12517378",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "66",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ELM",
        "lat": "42.1633",
        "lon": "-76.8973",
        "name": "Elmira Corning",
        "city": "Horseheads",
        "state": "New York",
        "country": "United States",
        "woeid": "12519635",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6999",
        "elev": "955",
        "icao": "KELM",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "ELU",
        "lat": "33.5122",
        "lon": "6.7842",
        "name": "Guemar",
        "city": "El Oued",
        "state": "El Oued",
        "country": "Algeria",
        "woeid": "12510328",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "208",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ENE",
        "lat": "-8.8",
        "lon": "121.6",
        "name": "Ende",
        "city": "Ende",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12523103",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2460",
        "elev": "49",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ENY",
        "lat": "36.5965",
        "lon": "109.475",
        "name": "Yan'an",
        "city": "Yan'an",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "2156695",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ERI",
        "lat": "42.0829",
        "lon": "-80.1824",
        "name": "Erie",
        "city": "Erie",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12519655",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "732",
        "icao": "KERI",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "ERS",
        "lat": "-22.6125",
        "lon": "17.0819",
        "name": "Eros",
        "city": "Windhoek",
        "state": "Khomas",
        "country": "Namibia",
        "woeid": "12522975",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "5584",
        "icao": "FYWE",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ESD",
        "lat": "48.7073",
        "lon": "-122.909",
        "name": "Orcas Island",
        "city": "Eastsound",
        "state": "Washington",
        "country": "United States",
        "woeid": "12523452",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "25",
        "icao": "KORS",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ESR",
        "lat": "-26.3083",
        "lon": "-69.7517",
        "name": "El Salvador Bajo",
        "city": "Diego de Almagro",
        "state": "Atacama",
        "country": "Chile",
        "woeid": "12512321",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "5240",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ETH",
        "lat": "29.5541",
        "lon": "34.9554",
        "name": "J Hozman",
        "city": "Elat",
        "state": "HaDarom",
        "country": "Israel",
        "woeid": "12513782",
        "tz": "AsiaJerusalem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "43",
        "icao": "LLET",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "EUN",
        "lat": "27.15",
        "lon": "-13.2",
        "name": "Hassan I",
        "city": "Laayoune",
        "state": "Sakia El Hamra",
        "country": "Morocco",
        "woeid": "1466769",
        "tz": "AfricaEl_Aaiun",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "207",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "EWN",
        "lat": "35.0786",
        "lon": "-77.0343",
        "name": "Craven County",
        "city": "New Bern",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12519354",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4804",
        "elev": "19",
        "icao": "KEWN",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "FAV",
        "lat": "-15.9969",
        "lon": "-145.509",
        "name": "French Polynesia",
        "city": "Fakarava",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "FDE",
        "lat": "61.392",
        "lon": "5.7615",
        "name": "Bringeland",
        "city": "Bygstad",
        "state": "Sogn og Fjordane Fylke",
        "country": "Norway",
        "woeid": "12523930",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2215",
        "elev": "7",
        "icao": "ENBL",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "FKI",
        "lat": "0.4808",
        "lon": "25.3331",
        "name": "Kisangani Bangoka",
        "city": "Kisangani",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511965",
        "tz": "AfricaLubumbashi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "1289",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "FLG",
        "lat": "35.139",
        "lon": "-111.675",
        "name": "Flagstaff Pulliam",
        "city": "Flagstaff",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12521495",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6999",
        "elev": "7011",
        "icao": "KFLG",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "FMN",
        "lat": "36.7387",
        "lon": "-108.227",
        "name": "Four Corners",
        "city": "Farmington",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12519792",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6702",
        "elev": "5503",
        "icao": "KFMN",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "FSM",
        "lat": "36.1881",
        "lon": "-94.4944",
        "name": "Smith Field",
        "city": "Fort Smith",
        "state": "Arkansas",
        "country": "United States",
        "woeid": "12521888",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "469",
        "icao": "KFSM",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "GAF",
        "lat": "34.4222",
        "lon": "8.81944",
        "name": "Gafsa",
        "city": "Gafsa",
        "state": "Qafash",
        "country": "Tunisia",
        "woeid": "12517859",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "1033",
        "icao": "KGAF",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "GDQ",
        "lat": "12.5167",
        "lon": "37.45",
        "name": "Gondar",
        "city": "Azezo",
        "state": "Debub Gonder",
        "country": "Ethiopia",
        "woeid": "12523110",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4626",
        "elev": "6453",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "GEL",
        "lat": "-28.2808",
        "lon": "-54.1683",
        "name": "Santo Angelo",
        "city": "Santo Angelo",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511309",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "1063",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "GEV",
        "lat": "67.1345",
        "lon": "20.8126",
        "name": "Lappland",
        "city": "Koskullskulle",
        "state": "Norrbotten",
        "country": "Sweden",
        "woeid": "12517628",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5610",
        "elev": "979",
        "icao": "KGEV",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "GFK",
        "lat": "47.9494",
        "lon": "-97.1762",
        "name": "Grand Forks Mark Andrews",
        "city": "Grand Forks",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12519951",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7349",
        "elev": "844",
        "icao": "KGFK",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "GIB",
        "lat": "36.1522",
        "lon": "-5.3472",
        "name": "Gibraltar",
        "city": "Gibilterra",
        "state": "",
        "country": "Gibraltar",
        "woeid": "12513027",
        "tz": "EuropeGibraltar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "15",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "GLV",
        "lat": "64.5433",
        "lon": "-163.033",
        "name": "Golovin",
        "city": "Golovin",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2411932",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "25",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "GNV",
        "lat": "29.6861",
        "lon": "-82.277",
        "name": "Gainesville",
        "city": "Gainesville",
        "state": "Florida",
        "country": "United States",
        "woeid": "12519850",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7503",
        "elev": "152",
        "icao": "KGNV",
        "direct_flights": "3",
        "carriers": "8"
    },
    {
        "code": "GOV",
        "lat": "-12.2747",
        "lon": "136.826",
        "name": "Gove Aerodrome",
        "city": "Gove",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510679",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6750",
        "elev": "172",
        "icao": "KGOV",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "GRK",
        "lat": "31.0615",
        "lon": "-97.8206",
        "name": "Killeen-Fort Hood",
        "city": "Killeen",
        "state": "Texas",
        "country": "United States",
        "woeid": "12521608",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KGRK",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "GSM",
        "lat": "36.0347",
        "lon": "14.3106",
        "name": "Gheshm",
        "city": "Gheshm",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "GTE",
        "lat": "-13.9743",
        "lon": "136.462",
        "name": "Groote Eylandt",
        "city": "Darwin",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510682",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6240",
        "elev": "52",
        "icao": "KGTE",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "GUR",
        "lat": "-10.3108",
        "lon": "150.339",
        "name": "Gurney",
        "city": "Alotau",
        "state": "Milne Bay",
        "country": "Papua New Guinea",
        "woeid": "12515467",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "65",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "GWL",
        "lat": "26.2843",
        "lon": "78.2288",
        "name": "Gwalior",
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513589",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "617",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "GYL",
        "lat": "-16.6364",
        "lon": "128.449",
        "name": "Argyle",
        "city": "Argyle",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510589",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7540",
        "elev": "522",
        "icao": "KGYL",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "HGL",
        "lat": "51.0908",
        "lon": "10.4542",
        "name": "Helgoland",
        "city": "Helgoland",
        "state": null,
        "country": "Germany",
        "woeid": "23424829",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1312",
        "elev": "8",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "HKN",
        "lat": "-5.4628",
        "lon": "150.404",
        "name": "Hoskins",
        "city": "Kimbe",
        "state": "West New Britain",
        "country": "Papua New Guinea",
        "woeid": "12515468",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "66",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "HLA",
        "lat": "-25.9353",
        "lon": "27.925",
        "name": "Lanseria",
        "city": "Johannesburg",
        "state": "Gauteng",
        "country": "South Africa",
        "woeid": "12517436",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "http:www.lanseria.co.za",
        "runway_length": "10000",
        "elev": "4517",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "HMV",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Hemavan",
        "city": "Hemavan",
        "state": null,
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "HOD",
        "lat": "14.755",
        "lon": "42.9858",
        "name": "Hodeidah",
        "city": "Al Hudaydah",
        "state": "Al Hudaydah",
        "country": "Yemen",
        "woeid": "12523003",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "49",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "HOH",
        "lat": "47.3833",
        "lon": "9.7",
        "name": "Hohenems",
        "city": "Hohenems",
        "state": "Vorarlberg",
        "country": "Austria",
        "woeid": "12523771",
        "tz": "EuropeVienna",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2066",
        "elev": "1352",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "HPB",
        "lat": "61.5262",
        "lon": "-166.142",
        "name": "Hooper Bay",
        "city": "Hooper Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520222",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3616",
        "elev": "18",
        "icao": "PAHP",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "HSG",
        "lat": "33.1508",
        "lon": "130.302",
        "name": "Saga",
        "city": "Saga",
        "state": "Tokyo Prefecture",
        "country": "Japan",
        "woeid": "28360533",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6561",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "HUS",
        "lat": "66.0443",
        "lon": "-154.258",
        "name": "Hughes",
        "city": "Hughes",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2425379",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "289",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "HUV",
        "lat": "61.7683",
        "lon": "17.0855",
        "name": "Hudiksvall",
        "city": "Hudiksvall",
        "state": "Gavleborg",
        "country": "Sweden",
        "woeid": "23322175",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3445",
        "elev": "91",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "IAS",
        "lat": "47.1814",
        "lon": "27.617",
        "name": "Iasi North",
        "city": "Ilasi",
        "state": "Iasi",
        "country": "Romania",
        "woeid": "12515544",
        "tz": "EuropeBucharest",
        "phone": "0232 278 510",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "394",
        "icao": "LRIA",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "IFO",
        "lat": "48.8833",
        "lon": "24.6833",
        "name": "Ivano-Frankovsk",
        "city": "Ivano-Frankivs'k",
        "state": "Ivano-Frankivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12524009",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "IGU",
        "lat": "-25.5958",
        "lon": "-54.4864",
        "name": "Cataratas",
        "city": "Foz do Iguacu",
        "state": "Parana",
        "country": "Brazil",
        "woeid": "12511087",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "787",
        "icao": "SBFI",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "ILO",
        "lat": "10.7144",
        "lon": "122.544",
        "name": "Iloilo",
        "city": "Iloilo",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12515621",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "24",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "INH",
        "lat": "-23.8731",
        "lon": "35.4058",
        "name": "Inhambane",
        "city": "Inhambane",
        "state": "Inhambane",
        "country": "Mozambique",
        "woeid": "12515018",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "30",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "INI",
        "lat": "43.3381",
        "lon": "21.8547",
        "name": "Nis",
        "city": "Nis",
        "state": "Nishava",
        "country": "Serbia",
        "woeid": "12517587",
        "tz": "EuropeBelgrade",
        "phone": "381 18 585858",
        "type": "Other",
        "email": "",
        "url": "http:www.airportnis.co.yu",
        "runway_length": "7225",
        "elev": "648",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "INZ",
        "lat": "27.2453",
        "lon": "2.5164",
        "name": "In Salah",
        "city": "In Salah",
        "state": "Tamanghasset",
        "country": "Algeria",
        "woeid": "12510334",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "883",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "IRM",
        "lat": "45.3517",
        "lon": "-89.6665",
        "name": "Irma",
        "city": "Irma",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "2427520",
        "tz": "AmericaMenominee",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ISG",
        "lat": "24.3456",
        "lon": "124.186",
        "name": "Ishigaki",
        "city": "Ishigaki-shi",
        "state": "Okinawa Prefecture",
        "country": "Japan",
        "woeid": "12513966",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "86",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ITO",
        "lat": "19.7126",
        "lon": "-155.042",
        "name": "Hilo",
        "city": "Hilo",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520190",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9803",
        "elev": "38",
        "icao": "PHTO",
        "direct_flights": "3",
        "carriers": "9"
    },
    {
        "code": "IXB",
        "lat": "26.7333",
        "lon": "88.3333",
        "name": "Bagdogra",
        "city": "Bagdogra",
        "state": "West Bengal",
        "country": "India",
        "woeid": "12513539",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9270",
        "elev": "412",
        "icao": "VEBD",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "IXL",
        "lat": "34.1667",
        "lon": "77.5833",
        "name": "Leh",
        "city": "Leh",
        "state": "Jammu and Kashmir",
        "country": "India",
        "woeid": "12513626",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10100",
        "elev": "10682",
        "icao": "VILH",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "IXU",
        "lat": "19.8698",
        "lon": "75.386",
        "name": "Aurangabad",
        "city": "Aurangabad",
        "state": "Maharashtra",
        "country": "India",
        "woeid": "12513537",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1906",
        "icao": "VAAU",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "JCH",
        "lat": "68.8333",
        "lon": "-51",
        "name": "Christianshab",
        "city": "Qasigiannguit",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523838",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "JGO",
        "lat": "69.25",
        "lon": "-53.55",
        "name": "Godhavn",
        "city": "Qeqertarsuaq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523841",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "JHG",
        "lat": "22.0167",
        "lon": "100.8",
        "name": "Gasa",
        "city": "Jinghong",
        "state": "Yunnan",
        "country": "China",
        "woeid": "2160652",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "JHW",
        "lat": "42.1528",
        "lon": "-79.2666",
        "name": "Chautauqua County-Jamestown",
        "city": "Jamestown",
        "state": "New York",
        "country": "United States",
        "woeid": "12519155",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5299",
        "elev": "1724",
        "icao": "KJHW",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "JKG",
        "lat": "57.7522",
        "lon": "14.0692",
        "name": "Jonkoping",
        "city": "Jonkoping",
        "state": "Jonkoping",
        "country": "Sweden",
        "woeid": "12517643",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "741",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "JLR",
        "lat": "23.1778",
        "lon": "80.0556",
        "name": "Jabalpur",
        "city": "Bilpura",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513601",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "1621",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "JMS",
        "lat": "46.9243",
        "lon": "-98.6788",
        "name": "Jamestown Municipal",
        "city": "Jamestown",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12520348",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "1498",
        "icao": "KJMS",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "JNN",
        "lat": "60.153",
        "lon": "-45.2532",
        "name": "Nanortalik",
        "city": "Nanortalik",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523847",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "JPA",
        "lat": "-7.1481",
        "lon": "-34.9506",
        "name": "Presidente Castro Pinto",
        "city": "Santa Rita",
        "state": "Paraiba",
        "country": "Brazil",
        "woeid": "12511287",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8251",
        "elev": "213",
        "icao": "SBJP",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "JPR",
        "lat": "-10.8703",
        "lon": "-61.8461",
        "name": "Ji Parana",
        "city": "Ji-Paraná",
        "state": "Rondonia",
        "country": "Brazil",
        "woeid": "12511198",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "594",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "JSI",
        "lat": "39.1806",
        "lon": "23.5056",
        "name": "Skiathos",
        "city": "Skiathos",
        "state": "Thessalia",
        "country": "Greece",
        "woeid": "12513322",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5151",
        "elev": "53",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "JSU",
        "lat": "65.4177",
        "lon": "-52.9269",
        "name": "Maniitsoq Heliport",
        "city": "Maitsoq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523850",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "JUL",
        "lat": "-15.4642",
        "lon": "-70.1544",
        "name": "Juliaca",
        "city": "Juliaca",
        "state": "Puno",
        "country": "Peru",
        "woeid": "12515207",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13780",
        "elev": "12546",
        "icao": "SPJL",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "JUV",
        "lat": "72.8",
        "lon": "-56.15",
        "name": "Upernavik Heliport",
        "city": "Upernavik",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523851",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "BGUK",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KAJ",
        "lat": "64.2845",
        "lon": "27.6802",
        "name": "Kajaani",
        "city": "Paltaniemi",
        "state": "Oulu Province",
        "country": "Finland",
        "woeid": "12512774",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "468",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KAO",
        "lat": "65.9941",
        "lon": "29.2214",
        "name": "Kuusamo",
        "city": "Kuusamo",
        "state": "Oulu Province",
        "country": "Finland",
        "woeid": "12512780",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "866",
        "icao": "",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "KAX",
        "lat": "-27.8667",
        "lon": "114.133",
        "name": "Kalbarri",
        "city": "Kalbarri",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12709172",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KBR",
        "lat": "6.1683",
        "lon": "102.293",
        "name": "Sultan Ismail Petra",
        "city": "Kota Baharu",
        "state": "Kelantan",
        "country": "Malaysia",
        "woeid": "12515011",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "16",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KDM",
        "lat": "6.56889",
        "lon": "-7.71056",
        "name": "Kaadedhdhoo",
        "city": "Kaadedhdhoo",
        "state": "Guiglo",
        "country": "Maldives",
        "woeid": "1345139",
        "tz": "IndianMaldives",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KEW",
        "lat": "62.3587",
        "lon": "-96.5821",
        "name": "Keewaywin",
        "city": "Keewaywin",
        "state": "",
        "country": "Canada",
        "woeid": "23424775",
        "tz": "AmericaResolute",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KGP",
        "lat": "62.1906",
        "lon": "74.5339",
        "name": "Kogalym International",
        "city": "Kogalym",
        "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
        "country": "Russian Federation",
        "woeid": "12516798",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8225",
        "elev": "220",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "KIF",
        "lat": "53.0308",
        "lon": "-89.8335",
        "name": "Kingfisher Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524045",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KKH",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Kongiganak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "25",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "KLG",
        "lat": "61.5382",
        "lon": "-160.314",
        "name": "Kalskag",
        "city": "Kalskag",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2430533",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "49",
        "icao": "PALG",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KLL",
        "lat": "59.1126",
        "lon": "-156.856",
        "name": "Levelock",
        "city": "Levelock",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2438614",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1915",
        "elev": "60",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KLN",
        "lat": "57.5383",
        "lon": "-153.98",
        "name": "Larsen Bay",
        "city": "Larsen Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "23510510",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "2377",
        "elev": "77",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KLO",
        "lat": "11.6828",
        "lon": "122.377",
        "name": "Kalib0",
        "city": "Kalibo",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12515624",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "23",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "KLR",
        "lat": "56.678",
        "lon": "16.2856",
        "name": "Kalmar",
        "city": "Kalmar",
        "state": "Kalmar",
        "country": "Sweden",
        "woeid": "12517646",
        "tz": "EuropeStockholm",
        "phone": "+46 (0)480 587 00",
        "type": "Airports",
        "email": "",
        "url": "http:www.kalmarairport.com",
        "runway_length": "6726",
        "elev": "21",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KLV",
        "lat": "50.2009",
        "lon": "12.9149",
        "name": "Karlovy Vary",
        "city": "Carlsbad",
        "state": "Karlovarský",
        "country": "Czech Republic",
        "woeid": "12512532",
        "tz": "EuropePrague",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1552",
        "icao": "LKKV",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KSU",
        "lat": "63.1137",
        "lon": "7.82585",
        "name": "Kristiansund Kvernberget",
        "city": "Kristiansund Nord",
        "state": "More og Romsdal",
        "country": "Norway",
        "woeid": "12515114",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.avinor.nonorsklufthavnerkristiansund_lufthavn,_kve",
        "runway_length": "5774",
        "elev": "204",
        "icao": "ENKB",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KTB",
        "lat": "55.6833",
        "lon": "-132.529",
        "name": "Alaska",
        "city": "Thorne Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KTS",
        "lat": "65.3333",
        "lon": "-166.483",
        "name": "Teller Mission",
        "city": "Teller Mission",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1920",
        "elev": "25",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KUA",
        "lat": "3.7747",
        "lon": "103.21",
        "name": "Kuantan",
        "city": "Gambang",
        "state": "Pahang",
        "country": "Malaysia",
        "woeid": "12514996",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9200",
        "elev": "58",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KVK",
        "lat": "67.5757",
        "lon": "33.5691",
        "name": "Kirovsk",
        "city": "Kirovsk",
        "state": "Murmanskaya Oblast",
        "country": "Russia",
        "woeid": "12516168",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KVL",
        "lat": "67.7333",
        "lon": "-164.667",
        "name": "Kivalina",
        "city": "Kivalina",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799789",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "10",
        "icao": "PAVL",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "KWK",
        "lat": "59.9077",
        "lon": "-163.026",
        "name": "Kwigillingok",
        "city": "Kwigillingok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2434060",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2300",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "KYP",
        "lat": "19.4267",
        "lon": "93.5347",
        "name": "Kyaukpyu",
        "city": "Kyaukpyu",
        "state": "Rakhine State",
        "country": "Myanmar",
        "woeid": "12510918",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "KYU",
        "lat": "64.9",
        "lon": "-157.7",
        "name": "Koyukuk",
        "city": "Koyukuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799793",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2980",
        "elev": "115",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "LBS",
        "lat": "-16.5729",
        "lon": "179.265",
        "name": "Northern",
        "city": "Labasa",
        "state": "Northern",
        "country": "Fiji",
        "woeid": "2345337",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3356",
        "elev": "30",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "LBU",
        "lat": "5.3",
        "lon": "115.249",
        "name": "Labuan",
        "city": "Victoria",
        "state": "Labuan",
        "country": "Malaysia",
        "woeid": "12514998",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6380",
        "elev": "99",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "LDH",
        "lat": "-31.5392",
        "lon": "159.08",
        "name": "Lord Howe Island",
        "city": "Lord Howe Island",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510722",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3298",
        "elev": "13",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LEH",
        "lat": "40.3897",
        "lon": "-111.847",
        "name": "Le Havre",
        "city": "Le Havre",
        "state": "Haute-Normandie",
        "country": "France",
        "woeid": "603259",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "312",
        "icao": "LFOH",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "LET",
        "lat": "-4.19332",
        "lon": "-69.9402",
        "name": "Gen. A.V. Cobo",
        "city": "Leticia",
        "state": "Amazonas",
        "country": "Colombia",
        "woeid": "12511015",
        "tz": "AmericaRio_Branco",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6167",
        "elev": "275",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "LLI",
        "lat": "12.009",
        "lon": "38.91",
        "name": "Ethiopia",
        "city": "Lalibela",
        "state": "Semen Welo",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "6500",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LLU",
        "lat": "60.5",
        "lon": "-45.5833",
        "name": "Alluitsup Paa",
        "city": "Alluitsup Paa",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "472937",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KLLU",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LNE",
        "lat": "-15.8667",
        "lon": "168.177",
        "name": "Lonorore",
        "city": "Panngi",
        "state": "Penama",
        "country": "Vanuatu",
        "woeid": "12523164",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2953",
        "elev": "43",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LPX",
        "lat": "56.501",
        "lon": "21.0897",
        "name": "Liepaja East",
        "city": "Liepaya",
        "state": "Liepajas Rajons",
        "country": "Latvia",
        "woeid": "12514573",
        "tz": "EuropeRiga",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "http:www.liepaja-airport.lv",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LRS",
        "lat": "37.1847",
        "lon": "26.8017",
        "name": "Leros",
        "city": "Lero",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513306",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2198",
        "elev": "36",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "LSC",
        "lat": "-29.9111",
        "lon": "-71.1978",
        "name": "La Florida",
        "city": "Compañía Alta",
        "state": "Coquimbo",
        "country": "Chile",
        "woeid": "12512327",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6358",
        "elev": "479",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "LSE",
        "lat": "43.8751",
        "lon": "-91.2638",
        "name": "La Crosse Municipal",
        "city": "La Crosse",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12520505",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8536",
        "elev": "654",
        "icao": "KLSE",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "LTO",
        "lat": "25.9858",
        "lon": "-111.353",
        "name": "Loreto",
        "city": "Comondú",
        "state": "Baja California Sur",
        "country": "Mexico",
        "woeid": "12514914",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "10",
        "icao": "MMLT",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "LWT",
        "lat": "47.0552",
        "lon": "-109.46",
        "name": "Lewistown Municipal",
        "city": "Lewistown",
        "state": "Montana",
        "country": "United States",
        "woeid": "12520636",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "4167",
        "icao": "KLWT",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "LXS",
        "lat": "39.9214",
        "lon": "25.2417",
        "name": "Limnos",
        "city": "Moudhros",
        "state": "Voreio Aigaio",
        "country": "Greece",
        "woeid": "12513307",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "15",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "MAS",
        "lat": "-2.1052",
        "lon": "146.926",
        "name": "Manus Island",
        "city": "Lorengau",
        "state": "Manus",
        "country": "Papua New Guinea",
        "woeid": "12515473",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7020",
        "elev": "12",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MAU",
        "lat": "-16.4311",
        "lon": "-152.28",
        "name": "Society Islands",
        "city": "Papeete",
        "state": "Leeward Islands",
        "country": "French Polynesia",
        "woeid": "12512830",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2673",
        "elev": "13",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MBS",
        "lat": "43.5311",
        "lon": "-84.0933",
        "name": "MBS",
        "city": "Freeland",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12522191",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "668",
        "icao": "KMBS",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "MCG",
        "lat": "62.9536",
        "lon": "-155.603",
        "name": "Mcgrath",
        "city": "Mcgrath",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520858",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5481",
        "elev": "337",
        "icao": "PAMC",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "MCK",
        "lat": "40.2071",
        "lon": "-100.599",
        "name": "Mccook Municipal",
        "city": "Mccook",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12520873",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5998",
        "elev": "2579",
        "icao": "KMCK",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "MCY",
        "lat": "-26.6035",
        "lon": "153.091",
        "name": "Maroochydore Aerodrome",
        "city": "Mudjimba",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510729",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "15",
        "icao": "YBMC",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "MGZ",
        "lat": "12.4458",
        "lon": "98.625",
        "name": "Mergui",
        "city": "Mergui",
        "state": "Tenasserim",
        "country": "Myanmar",
        "woeid": "12510923",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "73",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MIG",
        "lat": "31.4833",
        "lon": "104.733",
        "name": "Mian Yang",
        "city": "Mian Yang",
        "state": "Sichuan",
        "country": "China",
        "woeid": "2158439",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "MII",
        "lat": "-22.1953",
        "lon": "-49.9261",
        "name": "Dr Gastao Vidigal",
        "city": "Marilia",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511229",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "2093",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MIM",
        "lat": "-36.9081",
        "lon": "149.903",
        "name": "Merimbula Aerodrome",
        "city": "Merimbula",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12523173",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5255",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MIS",
        "lat": "46.2127",
        "lon": "11.9079",
        "name": "Saint Aignan Island",
        "city": "Misima Island",
        "state": "Milne Bay",
        "country": "Papua New Guinea",
        "woeid": "12498877",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3020",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MJM",
        "lat": "-6.1214",
        "lon": "23.5697",
        "name": "Mbuji Mayi",
        "city": "Mbuji Mayi",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511972",
        "tz": "AfricaLubumbashi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "2221",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "MJZ",
        "lat": "62.5168",
        "lon": "113.973",
        "name": "Mirnyy",
        "city": "Mirnyj",
        "state": "Sakha",
        "country": "Russia",
        "woeid": "2046493",
        "tz": "AsiaYakutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "MKG",
        "lat": "43.1646",
        "lon": "-86.2367",
        "name": "Muskegon County",
        "city": "Muskegon",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12521071",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "628",
        "icao": "KMKG",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "MKK",
        "lat": "21.1557",
        "lon": "-157.094",
        "name": "Molokai",
        "city": "Hoolehua",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520984",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4494",
        "elev": "454",
        "icao": "PHMK",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "MKR",
        "lat": "-26.6091",
        "lon": "118.551",
        "name": "Meekatharra",
        "city": "Kumarina",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510731",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7150",
        "elev": "1712",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MLL",
        "lat": "61.8833",
        "lon": "-162.067",
        "name": "Marshall",
        "city": "Marshall",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799653",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1762",
        "elev": "90",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "MNG",
        "lat": "-12.06",
        "lon": "134.233",
        "name": "Maningrida",
        "city": "Maningrida",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510727",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "112",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MNU",
        "lat": "16.4417",
        "lon": "97.6694",
        "name": "Moulmein",
        "city": "Moulmein",
        "state": "Mon State",
        "country": "Myanmar",
        "woeid": "12510926",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5300",
        "elev": "149",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MOL",
        "lat": "62.7466",
        "lon": "7.26397",
        "name": "Aro",
        "city": "Bolsøya",
        "state": "More og Romsdal",
        "country": "Norway",
        "woeid": "12515101",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "10",
        "icao": "ENML",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MPN",
        "lat": "-51.8222",
        "lon": "-58.4417",
        "name": "Mount Pleasant",
        "city": "Mount Pleasant",
        "state": "East Falkland",
        "country": "Falkland Islands",
        "woeid": "12512813",
        "tz": "AtlanticStanley",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8497",
        "elev": "244",
        "icao": "EGYP",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "MQF",
        "lat": "53.45",
        "lon": "59.0667",
        "name": "Magnitogorsk",
        "city": "Chelyabinsk",
        "state": "Chelyabinskaya Oblast",
        "country": "Russian Federation",
        "woeid": "24553463",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "MSA",
        "lat": "53.8333",
        "lon": "-92",
        "name": "Muskrat Dam",
        "city": "Muskrat Dam",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "2646",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MSZ",
        "lat": "-15.1972",
        "lon": "12.1551",
        "name": "Namibe",
        "city": "Namibe",
        "state": "Namibe",
        "country": "Angola",
        "woeid": "1262954",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8284",
        "elev": "210",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MUA",
        "lat": "-8.3272",
        "lon": "157.261",
        "name": "Munda",
        "city": "Munda",
        "state": "Western",
        "country": "Solomon Islands",
        "woeid": "12511007",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "10",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MUH",
        "lat": "31.3253",
        "lon": "27.2208",
        "name": "Mersa Matruh",
        "city": "Marsa Matruh",
        "state": "Matruh",
        "country": "Egypt",
        "woeid": "12512698",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "94",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "MXH",
        "lat": "-6.65619",
        "lon": "145.859",
        "name": "Papua New Guinea",
        "city": "Moro",
        "state": "Southern Highlands",
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "2750",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "MXX",
        "lat": "60.9586",
        "lon": "14.5049",
        "name": "Siljan",
        "city": "Mora",
        "state": "Dalarna",
        "country": "Sweden",
        "woeid": "12517676",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5938",
        "elev": "633",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "MYG",
        "lat": "22.3706",
        "lon": "-72.9481",
        "name": "Miltary & Civil",
        "city": "Abraham Bay",
        "state": "Mayaguana",
        "country": "Bahamas",
        "woeid": "12510873",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7297",
        "elev": "11",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NHA",
        "lat": "12.2333",
        "lon": "109.2",
        "name": "Nha-Trang",
        "city": "Nha Trang",
        "state": "Khanh Hoa",
        "country": "Vietnam",
        "woeid": "12523189",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6166",
        "elev": "16",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NME",
        "lat": "60.4723",
        "lon": "-164.699",
        "name": "Nightmute",
        "city": "Nightmute",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2459959",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "6",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "NPE",
        "lat": "-39.4689",
        "lon": "176.867",
        "name": "Hawkes Bay",
        "city": "Napier",
        "state": "Hawke's Bay",
        "country": "New Zealand",
        "woeid": "12515159",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "6",
        "icao": "NZNR",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NPL",
        "lat": "-39.0067",
        "lon": "174.171",
        "name": "New Plymouth",
        "city": "New Plymouth",
        "state": "Taranaki",
        "country": "New Zealand",
        "woeid": "12515161",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "97",
        "icao": "NZNP",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "NRA",
        "lat": "-34.6964",
        "lon": "146.512",
        "name": "Narrandera Leeton Aerodrome",
        "city": "Narrandera",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510749",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "5282",
        "elev": "471",
        "icao": "KNRA",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NTN",
        "lat": "-17.7",
        "lon": "141.067",
        "name": "Normanton",
        "city": "Normanton",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708490",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "72",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NVK",
        "lat": "68.4431",
        "lon": "17.3941",
        "name": "Framnes",
        "city": "Narvik",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523943",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "97",
        "icao": "ENNK",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "NWA",
        "lat": "6.47748",
        "lon": "11.0726",
        "name": "Moheli",
        "city": "Moheli",
        "state": "Moheli",
        "country": "Comoros",
        "woeid": "2345045",
        "tz": "IndianComoro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "46",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "NYA",
        "lat": "44.7718",
        "lon": "-93.9263",
        "name": "Norwood Young America",
        "city": "Norwood Young America",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "23417628",
        "tz": "AmericaMenominee",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "OBN",
        "lat": "56.4638",
        "lon": "-5.3973",
        "name": "Oban Connel",
        "city": "Oban",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "12518137",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.argyll-bute.gov.uknewsroomfeaturesobanairport?a=3",
        "runway_length": "4152",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "OBO",
        "lat": "42.7343",
        "lon": "143.216",
        "name": "Obihiro",
        "city": "Obihiro-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "12523194",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "275",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "OKY",
        "lat": "-27.4086",
        "lon": "151.738",
        "name": "Oakey Aerodrome",
        "city": "Oakey",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510754",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "1334",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "OMO",
        "lat": "43.2836",
        "lon": "17.8467",
        "name": "Mostar",
        "city": "Mostar",
        "state": "Federacija Bosne I Hercegovine",
        "country": "Bosnia and Herzegovina",
        "woeid": "12510900",
        "tz": "EuropeBelgrade",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "157",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ONG",
        "lat": "-16.565",
        "lon": "139.434",
        "name": "Mornington Island",
        "city": "Mornington",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510742",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "15",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "OOK",
        "lat": "60.5334",
        "lon": "-165.114",
        "name": "Toksook Bay",
        "city": "Toksook Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2506880",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PAOO",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "OPU",
        "lat": "-8",
        "lon": "142.906",
        "name": "Balimo",
        "city": "Balimo",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "1061889",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4270",
        "elev": "100",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ORV",
        "lat": "66.8333",
        "lon": "-161.033",
        "name": "Curtis Memorial",
        "city": "Noorvik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799802",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3200",
        "elev": "63",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "OST",
        "lat": "51.2012",
        "lon": "2.8716",
        "name": "Oostende",
        "city": "Oostende",
        "state": "West-Vlaanderen",
        "country": "Belgium",
        "woeid": "12510854",
        "tz": "EuropeBrussels",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10499",
        "elev": "13",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "OSY",
        "lat": "64.4723",
        "lon": "11.5721",
        "name": "Namsos",
        "city": "Namsos",
        "state": "Nord-Trondelag",
        "country": "Norway",
        "woeid": "12523944",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "OUL",
        "lat": "64.9294",
        "lon": "25.3574",
        "name": "Oulu",
        "city": "Oulunsalo",
        "state": "Oulu Province",
        "country": "Finland",
        "woeid": "12512792",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "47",
        "icao": "EFOU",
        "direct_flights": "3",
        "carriers": "8"
    },
    {
        "code": "OZH",
        "lat": "47.86",
        "lon": "35.3212",
        "name": "Zaporozhye East",
        "city": "Zaporizhzhya",
        "state": "Zaporiz´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518494",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "282",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "PAT",
        "lat": "25.5914",
        "lon": "85.0866",
        "name": "Lok Nayak Jaiprakash",
        "city": "Patna",
        "state": "Bihar",
        "country": "India",
        "woeid": "12513651",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6545",
        "elev": "171",
        "icao": "VEPT",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "PAZ",
        "lat": "20.6086",
        "lon": "-97.4717",
        "name": "Tajin",
        "city": "Castillo de Teayo",
        "state": "Veracruz-Llave",
        "country": "Mexico",
        "woeid": "12514968",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "453",
        "icao": "MMPA",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "PBG",
        "lat": "44.6508",
        "lon": "-73.4683",
        "name": "Plattsburgh Air Force Base",
        "city": "Plattsburgh",
        "state": "New York",
        "country": "United States",
        "woeid": "12521414",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KPBG",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PEZ",
        "lat": "53.2167",
        "lon": "45",
        "name": "Penza",
        "city": "Penza",
        "state": "Privolzhskiy",
        "country": "Russian Federation",
        "woeid": "2122796",
        "tz": "EuropeVolgograd",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "1200",
        "elev": "170",
        "icao": "KPEZ",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "PFB",
        "lat": "-28.2447",
        "lon": "-52.3317",
        "name": "Lauro Kurtz",
        "city": "Passo Fundo",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511209",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4281",
        "elev": "2369",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PGM",
        "lat": "59.35",
        "lon": "-151.833",
        "name": "Port Graham",
        "city": "Port Graham",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799662",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2245",
        "elev": "93",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PJM",
        "lat": "8.53994",
        "lon": "-83.3227",
        "name": "Puerto Jimenez",
        "city": "Puerto Jiménez",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12524317",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "7",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PLJ",
        "lat": "17.1929",
        "lon": "-88.6528",
        "name": "Belize",
        "city": "Placencia",
        "state": "",
        "country": "Belize",
        "woeid": "23424760",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "PLW",
        "lat": "-0.917694",
        "lon": "119.909",
        "name": "Mutiara",
        "city": "Palu",
        "state": "Sulawesi Tengah",
        "country": "Indonesia",
        "woeid": "12513487",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5331",
        "elev": "282",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "POG",
        "lat": "-0.7197",
        "lon": "8.7514",
        "name": "Port Gentil",
        "city": "Port-Gentil",
        "state": "Ogooue-Martime",
        "country": "Gabon",
        "woeid": "12513000",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "13",
        "icao": "FOOG",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "POR",
        "lat": "61.462",
        "lon": "21.7988",
        "name": "Pori",
        "city": "Pori",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12523800",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "44",
        "icao": "EFPO",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PPB",
        "lat": "-22.1778",
        "lon": "-51.4208",
        "name": "Presidente Prudente",
        "city": "Presidente Prudente",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511289",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6900",
        "elev": "1477",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PQS",
        "lat": "61.9362",
        "lon": "-162.882",
        "name": "Pilot Station",
        "city": "Pilot Station",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2471844",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2982",
        "elev": "275",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PSS",
        "lat": "-27.3839",
        "lon": "-55.9675",
        "name": "Posadas",
        "city": "Posadas",
        "state": "Misiones",
        "country": "Argentina",
        "woeid": "12510533",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7394",
        "elev": "430",
        "icao": "SARP",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "PUW",
        "lat": "46.743",
        "lon": "-117.119",
        "name": "Pullman-Moscow",
        "city": "Pullman",
        "state": "Washington",
        "country": "United States",
        "woeid": "12521496",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6731",
        "elev": "2551",
        "icao": "KPUW",
        "direct_flights": "3",
        "carriers": "5"
    },
    {
        "code": "PXO",
        "lat": "33.0667",
        "lon": "-16.35",
        "name": "Porto Santo",
        "city": "Porto Santo",
        "state": "Madeira",
        "country": "Portugal",
        "woeid": "12523962",
        "tz": "EuropeLisbon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8005",
        "elev": "318",
        "icao": "LPPS",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "PZE",
        "lat": "50.1282",
        "lon": "-5.51945",
        "name": "Penzance",
        "city": "Penzance",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22482999",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "PZO",
        "lat": "8.3",
        "lon": "-62.7333",
        "name": "Puerto Ordaz",
        "city": "Ciudad Guayana",
        "state": "Bolivar",
        "country": "Venezuela",
        "woeid": "12523683",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "472",
        "icao": "SVPR",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "QXB",
        "lat": "43.5048",
        "lon": "5.36794",
        "name": "Aix Les Milles",
        "city": "Aix-les-milles",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12512840",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LFMA",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "RAS",
        "lat": "37.3256",
        "lon": "49.6092",
        "name": "Rasht",
        "city": "Rasht",
        "state": "Gilan",
        "country": "Iran",
        "woeid": "12513752",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7545",
        "elev": null,
        "icao": "KRAS",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "RBR",
        "lat": "-9.9981",
        "lon": "-67.7997",
        "name": "Presidente Medici",
        "city": "Rio Branco",
        "state": "Norte",
        "country": "Brazil",
        "woeid": "12511288",
        "tz": "AmericaCuiaba",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "466",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "RBY",
        "lat": "64.7381",
        "lon": "-155.489",
        "name": "Ruby",
        "city": "Ruby",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2485593",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "635",
        "icao": "PARY",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "RIA",
        "lat": "-29.7106",
        "lon": "-53.6875",
        "name": "Santa Maria",
        "city": "Santa Maria",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511304",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7087",
        "elev": "289",
        "icao": "KRIA",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ROT",
        "lat": "-38.1047",
        "lon": "176.314",
        "name": "Rotorua",
        "city": "Rotorua",
        "state": "Bay Of Plenty",
        "country": "New Zealand",
        "woeid": "12515167",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "936",
        "icao": "NZRO",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "RSH",
        "lat": "61.7833",
        "lon": "-161.333",
        "name": "Russian",
        "city": "Russian Mission",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799709",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "70",
        "icao": "PARS",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "RUR",
        "lat": "51.0804",
        "lon": "6.14209",
        "name": "Rurutu",
        "city": "Rurutu",
        "state": "Austral Islands",
        "country": "French Polynesia",
        "woeid": "28743711",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "17",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SBP",
        "lat": "35.239",
        "lon": "-120.641",
        "name": "San Luis Obispo County",
        "city": "San Luis Obispo",
        "state": "California",
        "country": "United States",
        "woeid": "12521725",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5300",
        "elev": "212",
        "icao": "KSBP",
        "direct_flights": "3",
        "carriers": "8"
    },
    {
        "code": "SBY",
        "lat": "38.3431",
        "lon": "-75.517",
        "name": "Salisbury-Wicomico County",
        "city": "Salisbury",
        "state": "Maryland",
        "country": "United States",
        "woeid": "12521706",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "52",
        "icao": "KSBY",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SCM",
        "lat": "61.8425",
        "lon": "-165.58",
        "name": "Scammon Bay",
        "city": "Scammon Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2489516",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "2777",
        "elev": "22",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SDE",
        "lat": "-27.7675",
        "lon": "-64.3106",
        "name": "Santiago del Estero",
        "city": "Frías",
        "state": "Santiago del Estero",
        "country": "Argentina",
        "woeid": "12510562",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9430",
        "elev": "650",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SDP",
        "lat": "55.3184",
        "lon": "-160.523",
        "name": "Sand Point",
        "city": "Sand Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29387742",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3800",
        "elev": "22",
        "icao": "PASD",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SDY",
        "lat": "47.7113",
        "lon": "-104.184",
        "name": "Sidney Richland Municipal",
        "city": "Sidney",
        "state": "Montana",
        "country": "United States",
        "woeid": "12521850",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5705",
        "elev": "1984",
        "icao": "KSDY",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SFG",
        "lat": "18.0421",
        "lon": "-63.1144",
        "name": "Grand Case-Esperance",
        "city": "",
        "state": "Saint-Martin et Saint-Barthélé",
        "country": "Guadeloupe",
        "woeid": "12513276",
        "tz": "AmericaGuadeloupe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SHX",
        "lat": "62.6333",
        "lon": "-159.583",
        "name": "Shageluk",
        "city": "Shageluk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799717",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "70",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SIT",
        "lat": "57.0535",
        "lon": "-135.366",
        "name": "Sitka",
        "city": "Sitka",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521866",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "21",
        "icao": "PASI",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "SJT",
        "lat": "31.3616",
        "lon": "-100.507",
        "name": "Mathis Field",
        "city": "San Angelo",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520840",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6920",
        "elev": "1916",
        "icao": "KSJT",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "SJY",
        "lat": "62.6937",
        "lon": "22.8289",
        "name": "Ilmajoki",
        "city": "Seinajoki",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12512768",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5052",
        "elev": "302",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SJZ",
        "lat": "38.6653",
        "lon": "-28.175",
        "name": "Sao Jorge",
        "city": "Velas",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515458",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SKE",
        "lat": "59.1844",
        "lon": "9.56938",
        "name": "Geiterygen",
        "city": "Skien",
        "state": "Telemark Fylke",
        "country": "Norway",
        "woeid": "12515122",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4213",
        "elev": "463",
        "icao": "ENSN",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SKK",
        "lat": "64.3549",
        "lon": "-161.193",
        "name": "Shaktoolik",
        "city": "Shaktoolik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2491522",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "22",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "SLA",
        "lat": "-24.8589",
        "lon": "-65.4756",
        "name": "Salta",
        "city": "La Caldera",
        "state": "Salta",
        "country": "Argentina",
        "woeid": "12510549",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "4078",
        "icao": "SASA",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "SLL",
        "lat": "17.0411",
        "lon": "54.0944",
        "name": "Salalah",
        "city": "Salalah",
        "state": "Zufar",
        "country": "Oman",
        "woeid": "12514812",
        "tz": "AsiaMuscat",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "15154",
        "elev": "73",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SLW",
        "lat": "25.5467",
        "lon": "-100.929",
        "name": "Plan de Guadalupe",
        "city": "General Cepeda",
        "state": "Coahuila de Zaragoza",
        "country": "Mexico",
        "woeid": "12514935",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8251",
        "elev": "4600",
        "icao": "MMIO",
        "direct_flights": "3",
        "carriers": "6"
    },
    {
        "code": "SMK",
        "lat": "63.4735",
        "lon": "-162.052",
        "name": "Alaska",
        "city": "St Michael",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2700",
        "elev": "25",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "SML",
        "lat": "23.5833",
        "lon": "-75.2681",
        "name": "Stella Maris",
        "city": "Simms",
        "state": "Long Island",
        "country": "Bahamas",
        "woeid": "12510883",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "10",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SNE",
        "lat": "16.5869",
        "lon": "-24.2886",
        "name": "Preguica",
        "city": "Ribeira Brava",
        "state": "Sao Nicolau",
        "country": "Cape Verde",
        "woeid": "12512493",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "500",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SNU",
        "lat": "22.4922",
        "lon": "-79.9408",
        "name": "Santa Clara",
        "city": "Esperanza",
        "state": "Villa Clara",
        "country": "Cuba",
        "woeid": "12512488",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "328",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SOC",
        "lat": "-7.5181",
        "lon": "110.751",
        "name": "Adi Sumarmo Wiryokusumo",
        "city": "Surakarta",
        "state": "Jawa Tengah",
        "country": "Indonesia",
        "woeid": "12513450",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "348",
        "icao": "",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "SOJ",
        "lat": "69.7833",
        "lon": "20.9333",
        "name": "Sorkjosen",
        "city": "Sørkjosen",
        "state": "Troms Fylke",
        "country": "Norway",
        "woeid": "12523949",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "19",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SOO",
        "lat": "61.2698",
        "lon": "17.0906",
        "name": "Soderhamn",
        "city": "Soderhamn",
        "state": "Gavleborg",
        "country": "Sweden",
        "woeid": "12517680",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "85",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SOV",
        "lat": "59.4428",
        "lon": "-151.705",
        "name": "Seldovia",
        "city": "Seldovia",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2490645",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "29",
        "icao": "PASO",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SPB",
        "lat": "18.3409",
        "lon": "-64.9698",
        "name": "St Thomas Seaplane Base",
        "city": "Charlotte Amalie",
        "state": "US Virgin Islands",
        "country": "United States",
        "woeid": "12523060",
        "tz": "AmericaSt_Thomas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KSPB",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SPI",
        "lat": "39.8445",
        "lon": "-89.672",
        "name": "Capital",
        "city": "Springfield",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12519058",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7999",
        "elev": "597",
        "icao": "KSPI",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "SQO",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Sweden",
        "city": "Storuman",
        "state": null,
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SRE",
        "lat": "-19.0008",
        "lon": "-65.2878",
        "name": "Juana Azurduy de Padilla",
        "city": "Sucre",
        "state": "Chuquisaca",
        "country": "Bolivia",
        "woeid": "12510909",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9300",
        "elev": "9527",
        "icao": "KSRE",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SSY",
        "lat": "-6.2739",
        "lon": "14.2503",
        "name": "Mbanza Congo",
        "city": "M'banza Congo",
        "state": "Zaire",
        "country": "Angola",
        "woeid": "12510444",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SUR",
        "lat": "52.7086",
        "lon": "-88.5419",
        "name": "Summer Beaver",
        "city": "Summer Beaver",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "23399236",
        "tz": "AmericaNipigon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SVJ",
        "lat": "68.2441",
        "lon": "14.6669",
        "name": "Helle",
        "city": "Svolvar",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523952",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2559",
        "elev": "30",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "SVU",
        "lat": "-16.8",
        "lon": "179.35",
        "name": "Savusavu",
        "city": "Labasa",
        "state": "Northern",
        "country": "Fiji",
        "woeid": "12523234",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3300",
        "elev": "14",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "SXR",
        "lat": "33.9833",
        "lon": "74.7833",
        "name": "Srinagar",
        "city": "Srinagar",
        "state": "Jammu and Kashmir",
        "country": "India",
        "woeid": "12513675",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12040",
        "elev": "5458",
        "icao": "",
        "direct_flights": "3",
        "carriers": "7"
    },
    {
        "code": "SZA",
        "lat": "-6.1389",
        "lon": "12.3764",
        "name": "Soyo",
        "city": "Santo António do Zaire",
        "state": "Zaire",
        "country": "Angola",
        "woeid": "12510454",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6857",
        "elev": "15",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TAC",
        "lat": "11.2283",
        "lon": "125.023",
        "name": "Daniel Z Romualdez",
        "city": "Tacloban City",
        "state": "Eastern Visayas",
        "country": "Philippines",
        "woeid": "12515611",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "TAK",
        "lat": "34.2557",
        "lon": "134.046",
        "name": "Japan",
        "city": "Takamatsu",
        "state": "Kagawa Prefecture",
        "country": "Japan",
        "woeid": "23424856",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "TBI",
        "lat": "24.315",
        "lon": "-75.4536",
        "name": "The Bight",
        "city": "New Bight",
        "state": "Cat Island",
        "country": "Bahamas",
        "woeid": "12510875",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "TET",
        "lat": "-16.1033",
        "lon": "33.6389",
        "name": "Tete Chingozi",
        "city": "Tete",
        "state": "Tete",
        "country": "Mozambique",
        "woeid": "12515030",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TGG",
        "lat": "5.3811",
        "lon": "103.11",
        "name": "Sultan Mahmud",
        "city": "Kuala Terengganu",
        "state": "Terengganu",
        "country": "Malaysia",
        "woeid": "12515012",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6801",
        "elev": "21",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "TGJ",
        "lat": "-20.9314",
        "lon": "167.229",
        "name": "Loyaute",
        "city": "Tiga",
        "state": "Loyaute",
        "country": "New Caledonia",
        "woeid": "24549805",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "THL",
        "lat": "20.4842",
        "lon": "99.9322",
        "name": "Tachilek",
        "city": "Tachilek",
        "state": "Shan State",
        "country": "Myanmar",
        "woeid": "12510937",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "TIM",
        "lat": "-4.5347",
        "lon": "136.888",
        "name": "Tembagapura",
        "city": "Nabire",
        "state": "Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513519",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "TIR",
        "lat": "13.6319",
        "lon": "79.5444",
        "name": "Tirupathi",
        "city": "Renigunta",
        "state": "Andhra Pradesh",
        "country": "India",
        "woeid": "12513685",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportsindia.org",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "TMC",
        "lat": "-9.4117",
        "lon": "119.25",
        "name": "Tambolaka",
        "city": "Tambolaka",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12513516",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "TMT",
        "lat": "-1.48667",
        "lon": "-56.3961",
        "name": "Trombetas",
        "city": "Trombetas",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511352",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TNK",
        "lat": "60.5813",
        "lon": "-165.253",
        "name": "Tununak",
        "city": "Tununak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388569",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "TOG",
        "lat": "37.1198",
        "lon": "-89.8786",
        "name": "Togiak Village",
        "city": "Togiak Village",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2512308",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATG",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "TRE",
        "lat": "56.4998",
        "lon": "-6.87066",
        "name": "Tiree Aerodrome",
        "city": "Crossapol",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22486802",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "TRG",
        "lat": "-37.6738",
        "lon": "176.19",
        "name": "Tauranga",
        "city": "Tauranga",
        "state": "Bay Of Plenty",
        "country": "New Zealand",
        "woeid": "12515169",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "NZTG",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TUB",
        "lat": "31.6123",
        "lon": "-111.052",
        "name": "Tubuai",
        "city": "Tubuai",
        "state": "Austral Islands",
        "country": "French Polynesia",
        "woeid": "28743674",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TUO",
        "lat": "-38.7383",
        "lon": "176.088",
        "name": "Taupo Aerodrome",
        "city": "Taupo",
        "state": "Waikato",
        "country": "New Zealand",
        "woeid": "12523249",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "NZAP",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "TWU",
        "lat": "4.2642",
        "lon": "117.885",
        "name": "Tawau",
        "city": "Tawau",
        "state": "Sabah",
        "country": "Malaysia",
        "woeid": "12515013",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "TZN",
        "lat": "24.1543",
        "lon": "-77.5885",
        "name": "South Andros",
        "city": "Mangrove Cay",
        "state": "Mangrove Cay",
        "country": "Bahamas",
        "woeid": "12524026",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "15",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "UAP",
        "lat": "-9.41207",
        "lon": "-140.068",
        "name": "French Polynesia",
        "city": "Ua Pou",
        "state": "Marquesas Islands",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2723",
        "elev": "112",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "URO",
        "lat": "49.3827",
        "lon": "1.26106",
        "name": "Boos",
        "city": "St-Pierre",
        "state": "Haute-Normandie",
        "country": "France",
        "woeid": "12523827",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4101",
        "elev": "515",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "URS",
        "lat": "42.7756",
        "lon": "1.73213",
        "name": "Kursk",
        "city": "Kursk",
        "state": "Kurskaya Oblast",
        "country": "Russia",
        "woeid": "2121788",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "URY",
        "lat": "31.415",
        "lon": "37.2744",
        "name": "Guriat",
        "city": "Gurayat",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "12517336",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "1671",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "USU",
        "lat": "12.1",
        "lon": "120.167",
        "name": "Busuanga",
        "city": "Coron",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12523295",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "148",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "UYL",
        "lat": "10.9342",
        "lon": "25.3411",
        "name": "Janub Darfur",
        "city": "Nyala",
        "state": "Janub Darfur",
        "country": "Sudan",
        "woeid": "20069893",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5300",
        "elev": "2149",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "UYN",
        "lat": "38.2768",
        "lon": "109.769",
        "name": "Yulin",
        "city": "Yulin",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "12512286",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "VAI",
        "lat": "35.253",
        "lon": "26.2538",
        "name": "Vanimo",
        "city": "Vanimo",
        "state": "Sandaun",
        "country": "Papua New Guinea",
        "woeid": "1060345",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5250",
        "elev": "10",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "VAW",
        "lat": "70.3569",
        "lon": "31.0471",
        "name": "Vardoe",
        "city": "Vardo",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523953",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "VBY",
        "lat": "57.6603",
        "lon": "18.3397",
        "name": "Visby",
        "city": "Visby",
        "state": "Gotland",
        "country": "Sweden",
        "woeid": "12517692",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lfv.sevisby",
        "runway_length": "6562",
        "elev": "167",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "VHM",
        "lat": "64.5797",
        "lon": "16.8374",
        "name": "Vilhelmina",
        "city": "Vilhelmina",
        "state": "Vasterbotten",
        "country": "Sweden",
        "woeid": "12517691",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3330",
        "elev": "1138",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "VNX",
        "lat": "-22.0142",
        "lon": "35.3119",
        "name": "Vilanculos",
        "city": "Vilanculos",
        "state": "Inhambane",
        "country": "Mozambique",
        "woeid": "12515033",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4823",
        "elev": "46",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "VXE",
        "lat": "16.8306",
        "lon": "-25.0636",
        "name": "San Pedro",
        "city": "Mindelo",
        "state": "Sao Vicente",
        "country": "Cape Verde",
        "woeid": "12512495",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "66",
        "icao": "GVSV",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "WGE",
        "lat": "-30.0331",
        "lon": "148.118",
        "name": "Walgett",
        "city": "Walgett",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510803",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5335",
        "elev": "439",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "WLS",
        "lat": "-13.3",
        "lon": "-176.167",
        "name": "Wallis Island",
        "city": "Wallis Island",
        "state": "Hahake",
        "country": "Wallis and Futuna Islands",
        "woeid": "22503938",
        "tz": "PacificWallis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6561",
        "elev": "75",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "WRL",
        "lat": "43.9747",
        "lon": "-107.953",
        "name": "Worland Municipal",
        "city": "Worland",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12522515",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7004",
        "elev": "4245",
        "icao": "KWRL",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "WUN",
        "lat": "20.0564",
        "lon": "78.9567",
        "name": "Wiluna",
        "city": "Wiluna",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "1106702",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5308",
        "elev": "1635",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "WWT",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Newtok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2153",
        "elev": "25",
        "icao": "",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "XCM",
        "lat": "42.4",
        "lon": "-82.1833",
        "name": "Chatham",
        "city": "Chatham",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524057",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3838",
        "elev": "650",
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "XMH",
        "lat": "-14.4252",
        "lon": "-146.068",
        "name": "Manihi",
        "city": "Manihi",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "12512825",
        "tz": "PacificGambier",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "10",
        "icao": "NTGI",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "XSC",
        "lat": "21.5158",
        "lon": "-71.5297",
        "name": "South Caicos",
        "city": "Cockburn Harbour",
        "state": "South and East Caicos",
        "country": "Turks And Caicos Islands",
        "woeid": "12517846",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "5",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "XXN",
        "lat": "46.4931",
        "lon": "-90.4134",
        "name": "Riyadh Air Base",
        "city": "Riyadh",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "1939753",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OERY",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "XYI",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Sweden",
        "city": "Flen",
        "state": "",
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YAM",
        "lat": "46.4853",
        "lon": "-84.5002",
        "name": "Sault Ste Marie",
        "city": "Sault Ste. Marie",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511827",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "630",
        "icao": "CYAM",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YBB",
        "lat": "68.5333",
        "lon": "-89.8167",
        "name": "Pelly Bay Townsite",
        "city": "Cambridge Bay",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12523269",
        "tz": "AmericaResolute",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4590",
        "elev": "50",
        "icao": "KGRK",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YBL",
        "lat": "49.954",
        "lon": "-125.27",
        "name": "Campbell River",
        "city": "Gold River",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511597",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "346",
        "icao": "CYBL",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "YBX",
        "lat": "51.4436",
        "lon": "-57.1861",
        "name": "Lourdes-De-Blanc-Sablon",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511736",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "117",
        "icao": "CYBX",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YCC",
        "lat": "45.092",
        "lon": "-74.56",
        "name": "Cornwall",
        "city": "Moose Creek",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511626",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "175",
        "icao": "CYCC",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YCD",
        "lat": "49.0521",
        "lon": "-123.874",
        "name": "Nanaimo",
        "city": "Nanaimo",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511768",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "99",
        "icao": "CYCD",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YCO",
        "lat": "67.8182",
        "lon": "-115.147",
        "name": "Coppermine",
        "city": "Cambridge Bay",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524091",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "74",
        "icao": "CYCO",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YDA",
        "lat": "64.0448",
        "lon": "-139.126",
        "name": "Dawson",
        "city": "Dawson",
        "state": "Yukon Territory",
        "country": "Canada",
        "woeid": "12524097",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1211",
        "icao": "CYDA",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YDP",
        "lat": "56.5333",
        "lon": "-61.6667",
        "name": "Nain",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524102",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "500",
        "icao": "CYDP",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "YDQ",
        "lat": "55.739",
        "lon": "-120.187",
        "name": "Dawson Creek",
        "city": "Dawson Creek",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511631",
        "tz": "AmericaDawson_Creek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "2148",
        "icao": "CYDQ",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YEK",
        "lat": "61.1019",
        "lon": "-94.0622",
        "name": "Arviat",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524110",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "32",
        "icao": "CYEK",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YFO",
        "lat": "54.7483",
        "lon": "-101.83",
        "name": "Flin Flon",
        "city": "Flin Flon",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511657",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "997",
        "icao": "CYFO",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YGH",
        "lat": "66.2653",
        "lon": "-128.617",
        "name": "Fort Good Hope",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524125",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "215",
        "icao": "CYGH",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YGJ",
        "lat": "35.4943",
        "lon": "133.237",
        "name": "Yonago",
        "city": "Sakaiminato-shi",
        "state": "Tottori Prefecture",
        "country": "Japan",
        "woeid": "12513987",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "20",
        "icao": "",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "YHD",
        "lat": "49.8284",
        "lon": "-92.7465",
        "name": "Dryden",
        "city": "Dryden",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511643",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1354",
        "icao": "CYHD",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YHO",
        "lat": "55.4667",
        "lon": "-60.2167",
        "name": "Hopedale",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524139",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYHO",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YIF",
        "lat": "51.2117",
        "lon": "-58.6592",
        "name": "Pakuashipi",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511844",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3900",
        "elev": "15",
        "icao": "CYIF",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YKG",
        "lat": "60.0199",
        "lon": "-69.9964",
        "name": "Kangirsuk",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524155",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YMN",
        "lat": "55.0872",
        "lon": "-59.1714",
        "name": "Makkovik",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524175",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "234",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YMO",
        "lat": "51.2855",
        "lon": "-80.6131",
        "name": "Moosonee",
        "city": "Winisk",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511762",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "26",
        "icao": "CYMO",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YMT",
        "lat": "49.7793",
        "lon": "-74.5295",
        "name": "Aéroport de Chapais-Chibougamau",
        "city": "Métabetchouan",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511612",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3850",
        "elev": "1318",
        "icao": "CYMT",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YMX",
        "lat": "45.6667",
        "lon": "-74.03",
        "name": "Mirabel",
        "city": "Mirabel",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511753",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12000",
        "elev": "270",
        "icao": "CYMX",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YOC",
        "lat": "67.5734",
        "lon": "-139.849",
        "name": "Old Crow",
        "city": "Dawson",
        "state": "Yukon Territory",
        "country": "Canada",
        "woeid": "12524187",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "824",
        "icao": "CYOC",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YPH",
        "lat": "58.4575",
        "lon": "-78.1327",
        "name": "Inukjuak",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524196",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "9",
        "icao": "CYPH",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YPJ",
        "lat": "58.8566",
        "lon": "-69.3528",
        "name": "Aupaluk",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524198",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YQX",
        "lat": "48.9465",
        "lon": "-54.5802",
        "name": "Gander",
        "city": "Gander",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511673",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "496",
        "icao": "CYQX",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YSO",
        "lat": "54.9119",
        "lon": "-59.7708",
        "name": "Postville Aerodrome",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524221",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "193",
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YTE",
        "lat": "64.2228",
        "lon": "-76.5337",
        "name": "Cape Dorset",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524226",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "158",
        "icao": "CYTE",
        "direct_flights": "3",
        "carriers": "4"
    },
    {
        "code": "YUT",
        "lat": "66.5333",
        "lon": "-86.25",
        "name": "Repulse Bay",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524241",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "80",
        "icao": "CYUT",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YUY",
        "lat": "48.2135",
        "lon": "-78.8287",
        "name": "Rouyn Noranda",
        "city": "Évain",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511819",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "988",
        "icao": "CYUY",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "YVZ",
        "lat": "52.6528",
        "lon": "-94.1569",
        "name": "Deer Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524256",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYVZ",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YXC",
        "lat": "49.6148",
        "lon": "-115.786",
        "name": "Cranbrook",
        "city": "Cranbrook",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511628",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "3082",
        "icao": "CYXC",
        "direct_flights": "3",
        "carriers": "3"
    },
    {
        "code": "YXN",
        "lat": "62.2273",
        "lon": "-92.5948",
        "name": "Whale Cove",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524268",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3405",
        "elev": "66",
        "icao": "CYXN",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YXX",
        "lat": "49.0221",
        "lon": "-122.382",
        "name": "Abbotsford",
        "city": "Abbotsford",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511565",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "190",
        "icao": "CYXX",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "YYB",
        "lat": "46.3564",
        "lon": "-79.4285",
        "name": "North Bay",
        "city": "North Bay",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511774",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "1215",
        "icao": "CYYB",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YYH",
        "lat": "69.5466",
        "lon": "-93.5751",
        "name": "Taloyoak",
        "city": "Cambridge Bay",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524271",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYYH",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "YZS",
        "lat": "64.2",
        "lon": "-83.3667",
        "name": "Coral Harbour",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524279",
        "tz": "AmericaCoral_Harbour",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "210",
        "icao": "CYZS",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ZAL",
        "lat": "-39.6458",
        "lon": "-73.0847",
        "name": "Pichoy",
        "city": "Pelchuquin",
        "state": "Los Lagos",
        "country": "Chile",
        "woeid": "12512336",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4199",
        "elev": "13",
        "icao": "SCVL",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ZBF",
        "lat": "47.6325",
        "lon": "-65.74",
        "name": "Bathurst",
        "city": "Bathurst",
        "state": "New Brunswick",
        "country": "Canada",
        "woeid": "12511575",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "194",
        "icao": "CZBF",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ZEU",
        "lat": "2.51769",
        "lon": "30.7978",
        "name": "Zeu",
        "city": "Zeu",
        "state": "Nebbi",
        "country": "Uganda",
        "woeid": "1455541",
        "tz": "AfricaKampala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ZGU",
        "lat": "-14.25",
        "lon": "167.5",
        "name": "Gaua",
        "city": "Gaua",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ZNA",
        "lat": "49.1617",
        "lon": "-123.893",
        "name": "Nanaimo Harbour",
        "city": "Nanaimo",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12523278",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ZNB",
        "lat": "-3.08333",
        "lon": "-67.9333",
        "name": "Sinop",
        "city": "Santo Antônio do Içá",
        "state": "Amazonas",
        "country": "Brazil",
        "woeid": "12523221",
        "tz": "AmericaPorto_Velho",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ZPB",
        "lat": "53.8648",
        "lon": "-92.1915",
        "name": "Sachigo Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524301",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "885",
        "icao": "CZPB",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ZTM",
        "lat": "55.8667",
        "lon": "-92.0833",
        "name": "Shamattawa",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524310",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4170",
        "elev": "295",
        "icao": "CZTM",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "ZVK",
        "lat": "16.5536",
        "lon": "104.763",
        "name": "Savannakhet",
        "city": "Savannakhet",
        "state": "Savannahkhet",
        "country": "Laos",
        "woeid": "12514556",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5350",
        "elev": "509",
        "icao": "VLSK",
        "direct_flights": "3",
        "carriers": "1"
    },
    {
        "code": "ZWE",
        "lat": "-19.0133",
        "lon": "29.1467",
        "name": "Antwerp",
        "city": "Antwerp",
        "state": "",
        "country": "Zimbabwe",
        "woeid": "23425004",
        "tz": "AfricaHarare",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "3",
        "carriers": "2"
    },
    {
        "code": "BZO",
        "lat": "46.4621",
        "lon": "11.3282",
        "name": "Bolzano",
        "city": "Bolzano",
        "state": "Trentino-Alto Adige",
        "country": "Italy",
        "woeid": "12513807",
        "tz": "EuropeRome",
        "phone": "+39-0471-255 255",
        "type": "Airports",
        "email": "",
        "url": "http:www.abd-airport.it",
        "runway_length": "3450",
        "elev": "790",
        "icao": "LIPB",
        "direct_flights": "2",
        "carriers": "2",
        "bookingId": "-111758"
    },
    {
        "code": "AAA",
        "lat": "-17.3595",
        "lon": "-145.494",
        "name": "Anaa",
        "city": "Anaa",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "12512819",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "7",
        "icao": "NTGA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AAX",
        "lat": "-19.5603",
        "lon": "-46.9653",
        "name": "Romeu Zuma",
        "city": "Araxá",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511034",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6230",
        "elev": "3274",
        "icao": "SBAX",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ABI",
        "lat": "32.4164",
        "lon": "-99.6803",
        "name": "Abilene",
        "city": "Abilene",
        "state": "Texas",
        "country": "United States",
        "woeid": "12518518",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7199",
        "elev": "1790",
        "icao": "KABI",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "ABL",
        "lat": "67.1058",
        "lon": "-157.854",
        "name": "Ambler",
        "city": "Kiana",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518600",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "289",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "ACI",
        "lat": "49.7134",
        "lon": "-2.22089",
        "name": "The Blaye",
        "city": "St. Peter Port",
        "state": "Channel Islands",
        "country": "United Kingdom",
        "woeid": "12523974",
        "tz": "EuropeJersey",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2887",
        "elev": "291",
        "icao": "EGJA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ACT",
        "lat": "31.609",
        "lon": "-97.2234",
        "name": "Waco",
        "city": "Waco",
        "state": "Texas",
        "country": "United States",
        "woeid": "12522299",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6597",
        "elev": "516",
        "icao": "KACT",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ACX",
        "lat": "25.0887",
        "lon": "104.89",
        "name": "Xingyi",
        "city": "Xingyi",
        "state": "Guizhou",
        "country": "China",
        "woeid": "2146722",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ADF",
        "lat": "37.8099",
        "lon": "38.3357",
        "name": "Adiyaman",
        "city": "Adiyaman",
        "state": "Adıyaman",
        "country": "Turkey",
        "woeid": "2347259",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LTAG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ADJ",
        "lat": "31.973",
        "lon": "35.9822",
        "name": "Al Matar",
        "city": "Amman",
        "state": "'Amman",
        "country": "Jordan",
        "woeid": "23388321",
        "tz": "AsiaAmman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OJAM",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ADX",
        "lat": "56.377",
        "lon": "-2.86117",
        "name": "Leuchars",
        "city": "St. Andrews",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "12518118",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8707",
        "elev": "38",
        "icao": "EGQL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AFA",
        "lat": "-34.5886",
        "lon": "-68.4028",
        "name": "San Rafael",
        "city": "San Rafael",
        "state": "Mendoza",
        "country": "Argentina",
        "woeid": "12510556",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7513",
        "elev": "2444",
        "icao": "SAMR",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AFL",
        "lat": "-9.8661",
        "lon": "-56.1044",
        "name": "Alta Floresta",
        "city": "Alta Floresta",
        "state": "Mato Grosso",
        "country": "Brazil",
        "woeid": "12511017",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "288",
        "icao": "SBAT",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "AGR",
        "lat": "27.1632",
        "lon": "77.9788",
        "name": "Agra",
        "city": "Agra",
        "state": "Uttar Pradesh",
        "country": "India",
        "woeid": "12513528",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "551",
        "icao": "VIAG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AGS",
        "lat": "33.3735",
        "lon": "-81.9732",
        "name": "Bush Field",
        "city": "Augusta",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12518987",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8001",
        "elev": "145",
        "icao": "KAGS",
        "direct_flights": "2",
        "carriers": "8"
    },
    {
        "code": "AHU",
        "lat": "35.1781",
        "lon": "-3.8383",
        "name": "Cote du Rif",
        "city": "Al Hoceima",
        "state": "Al Hoceima",
        "country": "Morocco",
        "woeid": "12514784",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7087",
        "elev": "89",
        "icao": "GMTA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AIN",
        "lat": "70.6139",
        "lon": "-159.857",
        "name": "Wainwright",
        "city": "Wainwright",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522307",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "25",
        "icao": "PAWT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AJI",
        "lat": "39.7239",
        "lon": "43.0618",
        "name": "Agri",
        "city": "Agrı",
        "state": "Ağrı",
        "country": "Turkey",
        "woeid": "12523340",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LTCO",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AJR",
        "lat": "65.592",
        "lon": "19.2647",
        "name": "Arvidsjaur",
        "city": "Arvidsjaur",
        "state": "Norrbotten",
        "country": "Sweden",
        "woeid": "22656498",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ESNX",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "AKV",
        "lat": "60.8142",
        "lon": "-78.1509",
        "name": "Akulivik",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524039",
        "tz": "AmericaIqaluit",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYKO",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AMY",
        "lat": "38.4819",
        "lon": "-100.608",
        "name": "Ambatomainty",
        "city": "Ambatomainty",
        "state": "Fianarantsoa",
        "country": "Madagascar",
        "woeid": "1489538",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3010",
        "elev": "1050",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ANV",
        "lat": "62.65",
        "lon": "-160.217",
        "name": "Anvik",
        "city": "Anvik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524559",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2880",
        "elev": "325",
        "icao": "PANV",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "AOC",
        "lat": "50.9794",
        "lon": "12.5111",
        "name": "Altenburg Nobitz",
        "city": "Altenburg",
        "state": "Thuringia",
        "country": "Germany",
        "woeid": "12597007",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafen-altenburg.de",
        "runway_length": "2235",
        "elev": "641",
        "icao": "EDAC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AOE",
        "lat": "39.7905",
        "lon": "30.5172",
        "name": "Anadolu University",
        "city": "Eskişehir",
        "state": "Eskişehir",
        "country": "Turkey",
        "woeid": "12523341",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "AOO",
        "lat": "40.2952",
        "lon": "-78.324",
        "name": "Altoona-Blair County",
        "city": "Martinsburg",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12518593",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5466",
        "elev": "1504",
        "icao": "KAOO",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AOS",
        "lat": "57.4672",
        "lon": "-153.839",
        "name": "Amook Bay Seaplane Base",
        "city": "Amook",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "APF",
        "lat": "26.1483",
        "lon": "-81.7745",
        "name": "Naples Municipal",
        "city": "Naples",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521078",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "9",
        "icao": "KAPF",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "APK",
        "lat": "-8.64687",
        "lon": "-131.787",
        "name": "French Polynesia",
        "city": "Apataki",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2756",
        "elev": "3",
        "icao": "NTGD",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "APN",
        "lat": "45.07",
        "lon": "-83.5683",
        "name": "Alpena County",
        "city": "Alpena",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12518585",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9001",
        "elev": "689",
        "icao": "KAPN",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "AQG",
        "lat": "30.5167",
        "lon": "117.033",
        "name": "Anqing",
        "city": "Anqing",
        "state": "Anhui",
        "country": "China",
        "woeid": "12523067",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZSAQ",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "AQI",
        "lat": "28.3317",
        "lon": "46.13",
        "name": "Hafr Al Batin",
        "city": "Qaisumah",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "12517338",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "1175",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ARU",
        "lat": "-21.1436",
        "lon": "-50.4261",
        "name": "Aracatuba",
        "city": "Araçatuba",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511026",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6955",
        "elev": "1358",
        "icao": "SBAU",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ARV",
        "lat": "45.9296",
        "lon": "-89.7371",
        "name": "Lakelan-Noble F. Lee Memerial Field",
        "city": "Woodruff",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12521141",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1628",
        "icao": "KARV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ASI",
        "lat": "-7.93586",
        "lon": "-14.4081",
        "name": "Wideawake Fld",
        "city": "Georgetown, Ascension Island",
        "state": "Ascension",
        "country": "St. Helena",
        "woeid": "12466481",
        "tz": "AtlanticSt_Helena",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "FHAW",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ATK",
        "lat": "70.4639",
        "lon": "-157.331",
        "name": "Atqasuk",
        "city": "Atqasuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524564",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1250",
        "elev": "65",
        "icao": "PATQ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ATM",
        "lat": "-3.2506",
        "lon": "-52.2517",
        "name": "Altamira",
        "city": "Altamira",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511018",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6572",
        "elev": "367",
        "icao": "SBHT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ATR",
        "lat": "20.5067",
        "lon": "-13.0436",
        "name": "Atar",
        "city": "Atar",
        "state": "Adrar",
        "country": "Mauritania",
        "woeid": "12514800",
        "tz": "AfricaNouakchott",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "758",
        "icao": "GQPA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AUC",
        "lat": "7.0703",
        "lon": "-70.7406",
        "name": "Santiago Perez",
        "city": "Arauca",
        "state": "Caldas",
        "country": "Colombia",
        "woeid": "12512414",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6888",
        "elev": "400",
        "icao": "SKUC",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "AWB",
        "lat": "-8.01667",
        "lon": "142.75",
        "name": "Awaba",
        "city": "Awaba",
        "state": "",
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2300",
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AXD",
        "lat": "40.8567",
        "lon": "25.9567",
        "name": "Alexandroupolis",
        "city": "Alexandroupolis",
        "state": "Anatoliki Makedonia",
        "country": "Greece",
        "woeid": "12513282",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "24",
        "icao": "LGAL",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "AXM",
        "lat": "4.4564",
        "lon": "-75.7681",
        "name": "El Eden",
        "city": "La Tebaida",
        "state": "Quindio",
        "country": "Colombia",
        "woeid": "12512374",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7825",
        "elev": "3960",
        "icao": "SKAR",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "AXP",
        "lat": "22.4441",
        "lon": "-73.9775",
        "name": "Spring Point",
        "city": "Spring Point",
        "state": "Acklins",
        "country": "Bahamas",
        "woeid": "12510881",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "11",
        "icao": "MYAP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "AZD",
        "lat": "31.905",
        "lon": "54.2786",
        "name": "Yazd",
        "city": "Yezd",
        "state": "Yazd",
        "country": "Iran",
        "woeid": "12513771",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "4066",
        "icao": "OIYY",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BAL",
        "lat": "37.93",
        "lon": "41.1167",
        "name": "Batman",
        "city": "Batman",
        "state": "Batman",
        "country": "Turkey",
        "woeid": "12517873",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10005",
        "elev": "1819",
        "icao": "LTCJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BAY",
        "lat": "47.6589",
        "lon": "23.465",
        "name": "Baia Mare",
        "city": "Baia Mare",
        "state": "",
        "country": "Romania",
        "woeid": "866625",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "604",
        "icao": "LRBM",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BBN",
        "lat": "3.73333",
        "lon": "115.467",
        "name": "Bario",
        "city": "Nanga Medamit",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12523070",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "3480",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BCD",
        "lat": "10.6444",
        "lon": "122.929",
        "name": "Bacolod",
        "city": "Bacolod City",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12515595",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5932",
        "elev": "20",
        "icao": "RPVB",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BCI",
        "lat": "-23.5635",
        "lon": "145.305",
        "name": "Barcaldine Aerodrome",
        "city": "Barcaldine",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510599",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4510",
        "elev": "878",
        "icao": "YBAR",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BDD",
        "lat": "-10.1214",
        "lon": "142.142",
        "name": "Badu Island",
        "city": "Badu Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510593",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BEU",
        "lat": "-24.3544",
        "lon": "139.463",
        "name": "Bedourie Aerodrome",
        "city": "Bedourie",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510603",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3478",
        "elev": "300",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BFF",
        "lat": "41.8683",
        "lon": "-103.597",
        "name": "William B Heilig Field",
        "city": "Scottsbluff",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12522448",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8280",
        "elev": "3965",
        "icao": "KBFF",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BHB",
        "lat": "44.4461",
        "lon": "-68.3615",
        "name": "Hancock County-Bar Harbor",
        "city": "Ellsworth",
        "state": "Maine",
        "country": "United States",
        "woeid": "12520074",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5196",
        "elev": "84",
        "icao": "KBHB",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BHG",
        "lat": "15.7636",
        "lon": "-84.551",
        "name": "Brus Laguna",
        "city": "Brus Laguna",
        "state": "Gracias a Dios",
        "country": "Honduras",
        "woeid": "12524352",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BHH",
        "lat": "19.9817",
        "lon": "42.6267",
        "name": "Bisha",
        "city": "Bisha",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "12517332",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10007",
        "elev": "3829",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BIK",
        "lat": "-1",
        "lon": "136.117",
        "name": "Frans Kaisiepo",
        "city": "Biak",
        "state": "Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513465",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11715",
        "elev": "46",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BKC",
        "lat": "65.9833",
        "lon": "-161.167",
        "name": "Buckland",
        "city": "Buckland",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524578",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1870",
        "elev": "30",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BKM",
        "lat": "3.94515",
        "lon": "114.402",
        "name": "Malaysia",
        "city": "Bakalalan",
        "state": null,
        "country": "Malaysia",
        "woeid": "23424901",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "2900",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BKQ",
        "lat": "-24.4292",
        "lon": "145.429",
        "name": "Blackall Aerodrome",
        "city": "Mount Enniskillen",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510609",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5538",
        "elev": "928",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BKW",
        "lat": "37.7849",
        "lon": "-81.1174",
        "name": "Raleigh County Memorial",
        "city": "Beaver",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12521519",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6750",
        "elev": "2504",
        "icao": "KBKW",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BLT",
        "lat": "-23.6012",
        "lon": "148.805",
        "name": "Blackwater Aerodrome",
        "city": "Baralaba",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510610",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "660",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BMU",
        "lat": "-8.5444",
        "lon": "118.682",
        "name": "Mohammad Salahuddin",
        "city": "Raba",
        "state": "Nusa Tenggara Barat",
        "country": "Indonesia",
        "woeid": "12513483",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "39",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BNY",
        "lat": "-11.2961",
        "lon": "159.801",
        "name": "Bellona",
        "city": "Bellona",
        "state": "Central",
        "country": "Solomon Islands",
        "woeid": "12511005",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2181",
        "elev": "56",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BOY",
        "lat": "11.1636",
        "lon": "-4.3264",
        "name": "Bobo Dioulasso",
        "city": "Bobo-Dioulasso",
        "state": "Houet",
        "country": "Burkina Faso",
        "woeid": "12522554",
        "tz": "AfricaOuagadougou",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10826",
        "elev": "1509",
        "icao": "DFOO",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BPX",
        "lat": "31.1442",
        "lon": "97.174",
        "name": "Bangda",
        "city": "Qamdo",
        "state": "Tibet",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "18045",
        "elev": "14219",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BPY",
        "lat": "-16.7431",
        "lon": "44.4803",
        "name": "Besalampy",
        "city": "Besalampy",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "12514696",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "125",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BQL",
        "lat": "-22.9122",
        "lon": "139.897",
        "name": "Boulia Aerodrome",
        "city": "Boulia",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510613",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4180",
        "elev": "540",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BRA",
        "lat": "-12.0792",
        "lon": "-45.01",
        "name": "Barreiras",
        "city": "Barreiras",
        "state": "Bahia",
        "country": "Brazil",
        "woeid": "12511045",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "2444",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BRD",
        "lat": "46.3909",
        "lon": "-94.1413",
        "name": "Brainerd-Crow Wing County",
        "city": "Brainerd",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12518917",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "1226",
        "icao": "KBRD",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BRL",
        "lat": "40.7816",
        "lon": "-91.1197",
        "name": "Burlington Municipal",
        "city": "Burlington",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12518980",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6702",
        "elev": "698",
        "icao": "KBRL",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BRM",
        "lat": "10.0453",
        "lon": "-69.3561",
        "name": "Barquisimeto",
        "city": "Barquisimeto",
        "state": "Lara",
        "country": "Venezuela",
        "woeid": "12522758",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7808",
        "elev": "2042",
        "icao": "SVBM",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "BRR",
        "lat": "57.0251",
        "lon": "-7.44967",
        "name": "North Bay",
        "city": "Barra",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12523078",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2982",
        "elev": null,
        "icao": "EGPR",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BSC",
        "lat": "6.1786",
        "lon": "-77.3956",
        "name": "Jose Celestino Mutis",
        "city": "Bahía Solano",
        "state": "Choco",
        "country": "Colombia",
        "woeid": "12512385",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3936",
        "elev": "80",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BSX",
        "lat": "45.4264",
        "lon": "5.14384",
        "name": "Bassein",
        "city": "Bassein",
        "state": "Ayeyarwady",
        "country": "Myanmar",
        "woeid": "1017357",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4750",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BTI",
        "lat": "70.1478",
        "lon": "-143.579",
        "name": "Barter Island",
        "city": "Kaktovik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524590",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "5",
        "icao": "PABA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BTL",
        "lat": "42.3046",
        "lon": "-85.2448",
        "name": "W K Kellogg",
        "city": "Battle Creek",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12522297",
        "tz": "AmericaDetroit",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7011",
        "elev": "941",
        "icao": "KBTL",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "BUA",
        "lat": "-5.42232",
        "lon": "154.673",
        "name": "Buka",
        "city": "Buka",
        "state": "Buka Island",
        "country": "Papua New Guinea",
        "woeid": "12498830",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5100",
        "elev": "11",
        "icao": "AYBK",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BUC",
        "lat": "-17.7484",
        "lon": "139.532",
        "name": "Burketown Aerodrome",
        "city": "Burketown",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510622",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "50",
        "icao": "YBKT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BVH",
        "lat": "-12.7014",
        "lon": "-60.0931",
        "name": "Brigadeiro Camarao",
        "city": "Vilhena",
        "state": "Rondonia",
        "country": "Brazil",
        "woeid": "12511373",
        "tz": "AmericaCuiaba",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8524",
        "elev": "2008",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BVI",
        "lat": "-25.8971",
        "lon": "139.349",
        "name": "Birdsville",
        "city": "Birdsville",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510608",
        "tz": "AustraliaLindeman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4700",
        "elev": "157",
        "icao": "YBDV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "BXU",
        "lat": "8.9528",
        "lon": "125.476",
        "name": "Butuan",
        "city": "Buenavista",
        "state": "Caraga",
        "country": "Philippines",
        "woeid": "12515601",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "141",
        "icao": "RPME",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "BYN",
        "lat": "62.0526",
        "lon": "17.1936",
        "name": "Bayankhongor",
        "city": "Bayankhongor",
        "state": "Bayanhongor",
        "country": "Mongolia",
        "woeid": "2265314",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CAB",
        "lat": "-5.59",
        "lon": "12.1886",
        "name": "Cabinda",
        "city": "Cabinda",
        "state": "Cabinda",
        "country": "Angola",
        "woeid": "12510430",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "66",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CAC",
        "lat": "-25.0017",
        "lon": "-53.5014",
        "name": "Cascavel",
        "city": "Cascavel",
        "state": "Nordeste",
        "country": "Brazil",
        "woeid": "12511085",
        "tz": "AmericaFortaleza",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4633",
        "elev": "2473",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CAP",
        "lat": "19.7328",
        "lon": "-72.195",
        "name": "Cap Haitien",
        "city": "Cap-Haïtien",
        "state": "Nord",
        "country": "Haiti",
        "woeid": "12513352",
        "tz": "AmericaPort-au-Prince",
        "phone": "0115092628539",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1",
        "elev": "15",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CBQ",
        "lat": "4.9756",
        "lon": "8.3481",
        "name": "Calabar",
        "city": "Duke Town",
        "state": "Cross River",
        "country": "Nigeria",
        "woeid": "12515061",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8038",
        "elev": "210",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CCK",
        "lat": "-12.1778",
        "lon": "96.839",
        "name": "Cocos",
        "city": "West Island",
        "state": "",
        "country": "Cocos (Keeling) Islands",
        "woeid": "12523082",
        "tz": "IndianCocos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "7",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CCM",
        "lat": "-28.7239",
        "lon": "-49.4208",
        "name": "Criciuma",
        "city": "Forquilhinha",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511104",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4892",
        "elev": "93",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CCZ",
        "lat": "25.4098",
        "lon": "-77.8843",
        "name": "Chub Cay",
        "city": "Chub Cay",
        "state": "Berry Islands",
        "country": "Bahamas",
        "woeid": "12510861",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CEC",
        "lat": "41.7765",
        "lon": "-124.237",
        "name": "Jack Mcnamara Field",
        "city": "Crescent City",
        "state": "California",
        "country": "United States",
        "woeid": "12520324",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5002",
        "elev": "57",
        "icao": "KCEC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CFC",
        "lat": "-26.7775",
        "lon": "-51.0175",
        "name": "Cacador",
        "city": "Caçador",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12523508",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CFN",
        "lat": "55.0416",
        "lon": "-8.3436",
        "name": "Carrickfin",
        "city": "Mullaghduff",
        "state": "",
        "country": "Ireland",
        "woeid": "12512717",
        "tz": "EuropeDublin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2001",
        "elev": "26",
        "icao": "EIDL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CFR",
        "lat": "49.1825",
        "lon": "-0.45906",
        "name": "Carpiquet",
        "city": "Carpiquet",
        "state": "Basse-Normandie",
        "country": "France",
        "woeid": "12523083",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "256",
        "icao": "LFRK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CGA",
        "lat": "55.4592",
        "lon": "-133.106",
        "name": "Craig Seaplane Base",
        "city": "Craig",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524610",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CGY",
        "lat": "8.4139",
        "lon": "124.608",
        "name": "Cagayan de Oro",
        "city": "Cagayan de Oro",
        "state": "Northern Mindanao",
        "country": "Philippines",
        "woeid": "12515602",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6857",
        "elev": "610",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CIF",
        "lat": "42.3333",
        "lon": "119.008",
        "name": "Chifeng",
        "city": "Chifeng",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "2149762",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CIJ",
        "lat": "-11.0178",
        "lon": "-68.759",
        "name": "E. Beltram",
        "city": "Cobija",
        "state": "Pando",
        "country": "Bolivia",
        "woeid": "12523541",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3608",
        "elev": "771",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CIU",
        "lat": "46.2581",
        "lon": "-84.4739",
        "name": "Chippewa County",
        "city": "Kincheloe",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519187",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7201",
        "elev": "799",
        "icao": "KCIU",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "CIW",
        "lat": "12.7187",
        "lon": "-61.3306",
        "name": "Canouan",
        "city": "Canouan Island",
        "state": "Grenadines",
        "country": "Saint Vincent and the Grenadines",
        "woeid": "12524425",
        "tz": "AmericaSt_Vincent",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2150",
        "elev": "18",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CJL",
        "lat": "35.8861",
        "lon": "71.7944",
        "name": "Chitral",
        "city": "Chitral",
        "state": "North-West Frontier",
        "country": "Pakistan",
        "woeid": "12515230",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5800",
        "elev": "4900",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CKD",
        "lat": "61.8667",
        "lon": "-158.133",
        "name": "Crooked Creek",
        "city": "Aniak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524616",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1200",
        "elev": "2650",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "CKS",
        "lat": "-6.1175",
        "lon": "-50.0036",
        "name": "Carajas",
        "city": "Carajas",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511077",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "2028",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CLL",
        "lat": "30.5938",
        "lon": "-96.3685",
        "name": "Easterwood Field",
        "city": "College Station",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519579",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "319",
        "icao": "KCLL",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "CLQ",
        "lat": "19.2733",
        "lon": "-103.577",
        "name": "Colima",
        "city": "Cuauhtémoc",
        "state": "Colima",
        "country": "Mexico",
        "woeid": "12514853",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4746",
        "elev": "1499",
        "icao": "MMIA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CMA",
        "lat": "-28.0333",
        "lon": "145.617",
        "name": "Cunnamulla",
        "city": "Cunnamulla",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708296",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5680",
        "elev": "630",
        "icao": "YCMU",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CMW",
        "lat": "21.4186",
        "lon": "-77.8458",
        "name": "Ignacio Agramonte",
        "city": "Camagüey",
        "state": "Camaguey",
        "country": "Cuba",
        "woeid": "12512465",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "413",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CNB",
        "lat": "-30.9742",
        "lon": "148.381",
        "name": "Coonamble",
        "city": "Coonamble",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510642",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5010",
        "elev": "604",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CNM",
        "lat": "32.3458",
        "lon": "-104.251",
        "name": "Cavern City Air Terminal",
        "city": "Carlsbad",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12519107",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7854",
        "elev": "3293",
        "icao": "KCNM",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CNP",
        "lat": "70.7431",
        "lon": "-22.6583",
        "name": "Neerlerit Inaat",
        "city": "Neerlerit Inaat",
        "state": "Ostgronland",
        "country": "Greenland",
        "woeid": "10644998",
        "tz": "AmericaIndianaTell_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCNP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CNY",
        "lat": "38.7609",
        "lon": "-109.742",
        "name": "Canyonlands Field",
        "city": "Thompson",
        "state": "Utah",
        "country": "United States",
        "woeid": "12519050",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6901",
        "elev": "4575",
        "icao": "KCNY",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "COD",
        "lat": "44.5134",
        "lon": "-109.031",
        "name": "Yellowstone",
        "city": "CodyYellowstone",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12522532",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8505",
        "elev": "5089",
        "icao": "KCOD",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "COL",
        "lat": "56.6167",
        "lon": "-6.61667",
        "name": "Coll Island",
        "city": "Isle of coll",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "12523978",
        "tz": "EuropeLondon",
        "phone": "0187 93367",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1642",
        "elev": "41",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CQD",
        "lat": "32.2967",
        "lon": "50.8417",
        "name": "Shahre-Kord",
        "city": "Shahre-Kord",
        "state": "Chahar Mahall va Bakhtiari",
        "country": "Iran",
        "woeid": "12843511",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CRA",
        "lat": "44.3173",
        "lon": "23.8447",
        "name": "Craiova",
        "city": "Croiova",
        "state": "Dolj",
        "country": "Romania",
        "woeid": "12523964",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LRCV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CRD",
        "lat": "-45.7872",
        "lon": "-67.4628",
        "name": "General Enrique Mosconi",
        "city": "Comodoro Rivadavia",
        "state": "Chubut",
        "country": "Argentina",
        "woeid": "12510499",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9025",
        "elev": "190",
        "icao": "SAVC",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CRI",
        "lat": "22.7467",
        "lon": "-74.18",
        "name": "Colonel Hill",
        "city": "Colonel Hill",
        "state": "Crooked Island",
        "country": "Bahamas",
        "woeid": "12510863",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CUC",
        "lat": "7.93",
        "lon": "-72.5094",
        "name": "Camilo Daza",
        "city": "Cúcuta",
        "state": "Norte de Santander",
        "country": "Colombia",
        "woeid": "12512370",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7611",
        "elev": "1073",
        "icao": "SKCC",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "CUE",
        "lat": "-2.8867",
        "lon": "-78.9833",
        "name": "Mariscal Lamar",
        "city": "Cuenca",
        "state": "Azuay",
        "country": "Ecuador",
        "woeid": "12512642",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6230",
        "elev": "8302",
        "icao": "SECU",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CUK",
        "lat": "17.4956",
        "lon": "-88.1816",
        "name": "Belize",
        "city": "Caye Caulker",
        "state": "Belize",
        "country": "Belize",
        "woeid": "23424760",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CVM",
        "lat": "23.7139",
        "lon": "-98.9653",
        "name": "Ciudad Victoria",
        "city": "Güémez",
        "state": "Tamaulipas",
        "country": "Mexico",
        "woeid": "12514851",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7216",
        "elev": "771",
        "icao": "MMCV",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "CVU",
        "lat": "39.7",
        "lon": "-31.1",
        "name": "Corvo Island",
        "city": "Corvo",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12523960",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CXB",
        "lat": "21.45",
        "lon": "91.9681",
        "name": "Coxs Bazar",
        "city": "Cox's Bazar",
        "state": "Chittagong",
        "country": "Bangladesh",
        "woeid": "12510888",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "CXI",
        "lat": "1.9849",
        "lon": "-157.675",
        "name": "Christmas Island",
        "city": "Christmas Island",
        "state": "Florida",
        "country": "Kiribati",
        "woeid": "12514192",
        "tz": "AmericaKentuckyMonticello",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6895",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "CXR",
        "lat": "12.2333",
        "lon": "109.2",
        "name": "Nha-Trang",
        "city": "Nha Trang",
        "state": "Khanh Hoa",
        "country": "Vietnam",
        "woeid": "12523189",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CYB",
        "lat": "19.689",
        "lon": "-79.8848",
        "name": "Gerrard Smith",
        "city": "Cayman Brac Is",
        "state": "Cayman Brac",
        "country": "Cayman Islands",
        "woeid": "12512344",
        "tz": "AmericaCayman",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5800",
        "elev": "7",
        "icao": "MWCB",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CYI",
        "lat": "23.4542",
        "lon": "120.404",
        "name": "Chaiyi",
        "city": "Chiayi City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517927",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10006",
        "elev": "82",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CYU",
        "lat": "10.8762",
        "lon": "121.064",
        "name": "Cuyo",
        "city": "Cuyo",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "1199109",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "98",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "CZU",
        "lat": "9.3375",
        "lon": "-75.2828",
        "name": "Las Brujas",
        "city": "Morroa",
        "state": "Sucre",
        "country": "Colombia",
        "woeid": "12512390",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4806",
        "elev": "568",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "DBQ",
        "lat": "42.4047",
        "lon": "-90.7037",
        "name": "Dubuque",
        "city": "Dubuque",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12519540",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6498",
        "elev": "1076",
        "icao": "KDBQ",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "DCF",
        "lat": "15.5333",
        "lon": "-61.4",
        "name": "Cane Field",
        "city": "Portsmouth",
        "state": "Saint John",
        "country": "Dominica",
        "woeid": "12523094",
        "tz": "AmericaDominica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "14",
        "icao": "TDCF",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "DCM",
        "lat": "43.5538",
        "lon": "2.28923",
        "name": "Mazamet",
        "city": "Labruguière",
        "state": "Midi-Pyrenees",
        "country": "France",
        "woeid": "12523095",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "738",
        "icao": "LFCK",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "DDG",
        "lat": "40.0242",
        "lon": "124.283",
        "name": "Dandong",
        "city": "Dandong",
        "state": "Liaoning",
        "country": "China",
        "woeid": "12512026",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "DIG",
        "lat": "26.1256",
        "lon": "109.308",
        "name": "Diqing",
        "city": "Diqing",
        "state": "贵州省",
        "country": "China",
        "woeid": "12713530",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DIK",
        "lat": "46.8035",
        "lon": "-102.802",
        "name": "Dickinson Municipal",
        "city": "Dickinson",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12519490",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "2589",
        "icao": "KDIK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "DIS",
        "lat": "-4.21667",
        "lon": "12.5",
        "name": "Loubomo",
        "city": "Loubomo",
        "state": "Niari",
        "country": "Congo",
        "woeid": "1280840",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1079",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DJB",
        "lat": "-1.6369",
        "lon": "103.639",
        "name": "Sultan Taha",
        "city": "Jambi",
        "state": "Jambi",
        "country": "Indonesia",
        "woeid": "12513510",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "85",
        "icao": "WIPA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "DLI",
        "lat": "11.7501",
        "lon": "108.377",
        "name": "Lien Khuong",
        "city": "Lam Dong",
        "state": "Lam Dong",
        "country": "Vietnam",
        "woeid": "12522924",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4856",
        "elev": "3156",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DNZ",
        "lat": "37.7667",
        "lon": "29.1",
        "name": "Cardak",
        "city": "Denizli",
        "state": "Denizli",
        "country": "Turkey",
        "woeid": "12523098",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "2792",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DOG",
        "lat": "19.1833",
        "lon": "30.4833",
        "name": "Dongola",
        "city": "Dongola",
        "state": "Ash Shamaliyah",
        "country": "Sudan",
        "woeid": "12523099",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6726",
        "elev": "902",
        "icao": "HSDN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DPL",
        "lat": "8.6043",
        "lon": "123.348",
        "name": "Dipolog",
        "city": "Polanco",
        "state": "Zamboanga Peninsula",
        "country": "Philippines",
        "woeid": "12515612",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5085",
        "elev": "12",
        "icao": "RPMG",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "DRG",
        "lat": "66.0833",
        "lon": "-162.767",
        "name": "Deering",
        "city": "Deering",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524659",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "8",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "DUJ",
        "lat": "41.1833",
        "lon": "-78.8953",
        "name": "Du Bois Jefferson County",
        "city": "Reynoldsville",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12519536",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5504",
        "elev": "1817",
        "icao": "KDUJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DVL",
        "lat": "48.108",
        "lon": "-98.9037",
        "name": "Devils Lake Municipal",
        "city": "Devils Lake",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12519482",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5509",
        "elev": "1455",
        "icao": "KDVL",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "DWB",
        "lat": "-16.1",
        "lon": "45.3667",
        "name": "Soalala",
        "city": "Soalala",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "1364336",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4101",
        "elev": "141",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "DYR",
        "lat": "64.7358",
        "lon": "177.739",
        "name": "Anadyr-Ugolnyye Kopi",
        "city": "Anadyr'",
        "state": "Chukotskiy Avtonomnyy Okrug",
        "country": "Russia",
        "woeid": "12515705",
        "tz": "AsiaKamchatka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "EAE",
        "lat": "-17.0686",
        "lon": "168.381",
        "name": "Vanuatu",
        "city": "Emae",
        "state": "Shefa",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "7",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EBD",
        "lat": "13.1597",
        "lon": "30.2342",
        "name": "El Obeid",
        "city": "Al Ubayyid",
        "state": "Shamal Kurdufan",
        "country": "Sudan",
        "woeid": "12517598",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8390",
        "elev": "1883",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EBJ",
        "lat": "55.5247",
        "lon": "8.55388",
        "name": "Esbjerg",
        "city": "Esbjerg",
        "state": "Syddanmark",
        "country": "Denmark",
        "woeid": "12512590",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.esbjerg-lufthavn.dk",
        "runway_length": "8530",
        "elev": "97",
        "icao": "EKEB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "EDR",
        "lat": "-14.9",
        "lon": "141.617",
        "name": "Edward River",
        "city": "Edward River",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708477",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3820",
        "elev": "50",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EEK",
        "lat": "60.2167",
        "lon": "-162.017",
        "name": "Eek",
        "city": "Eek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524663",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1300",
        "elev": "40",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "EGN",
        "lat": "13.4833",
        "lon": "22.45",
        "name": "Geneina",
        "city": "Geneina",
        "state": "Gharb Darfur",
        "country": "Sudan",
        "woeid": "1432552",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5250",
        "elev": "2650",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EGV",
        "lat": "45.932",
        "lon": "-89.2599",
        "name": "Eagle River Union",
        "city": "Eagle River",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519564",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "1642",
        "icao": "KEGV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EGX",
        "lat": "58.2101",
        "lon": "-157.366",
        "name": "Egegik",
        "city": "Egegik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524588",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "100",
        "icao": "PAII",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ELF",
        "lat": "35.0339",
        "lon": "-83.7405",
        "name": "El Fasher",
        "city": "El Fasher",
        "state": "Shamal Darfur",
        "country": "Sudan",
        "woeid": "1430323",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6053",
        "elev": "2404",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ENA",
        "lat": "60.5651",
        "lon": "-151.244",
        "name": "Kenai Municipal",
        "city": "Kenai",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520437",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7575",
        "elev": "92",
        "icao": "PAEN",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "ENH",
        "lat": "30.2875",
        "lon": "109.472",
        "name": "Enshi",
        "city": "Enshi",
        "state": "Hubei",
        "country": "China",
        "woeid": "12512043",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "ENI",
        "lat": "34.1167",
        "lon": "-89.9395",
        "name": "El Nido",
        "city": "El Nido",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "1199158",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1640",
        "elev": "12",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ERC",
        "lat": "39.7117",
        "lon": "39.5169",
        "name": "Erzincan",
        "city": "Erzincan",
        "state": "Erzincan",
        "country": "Turkey",
        "woeid": "12517887",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5800",
        "elev": "3792",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ERM",
        "lat": "-27.6608",
        "lon": "-52.2756",
        "name": "Comandante Kraemer",
        "city": "Erechim",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511118",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4199",
        "elev": "2498",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ESC",
        "lat": "45.718",
        "lon": "-87.0942",
        "name": "Delta County",
        "city": "Escanaba",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519461",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6498",
        "elev": "609",
        "icao": "KESC",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "EUX",
        "lat": "17.5031",
        "lon": "-62.9736",
        "name": "St. Eustatius",
        "city": "Oranjestad",
        "state": "St Eustatius",
        "country": "Netherlands Antilles",
        "woeid": "12515141",
        "tz": "AmericaCuracao",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "124",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "EWB",
        "lat": "41.6735",
        "lon": "-70.9579",
        "name": "New Bedford Municipal",
        "city": "New Bedford",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12521103",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4997",
        "elev": "80",
        "icao": "KEWB",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "EYP",
        "lat": "5.35",
        "lon": "-72.3833",
        "name": "El Yopal",
        "city": "Yopal",
        "state": "Casanare",
        "country": "Colombia",
        "woeid": "12524476",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5085",
        "elev": "1100",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "EZS",
        "lat": "38.6083",
        "lon": "39.2917",
        "name": "Elazig",
        "city": "Elazığ",
        "state": "Elazığ",
        "country": "Turkey",
        "woeid": "12517883",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5610",
        "elev": "2961",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "FAY",
        "lat": "34.9914",
        "lon": "-78.8889",
        "name": "Fayetteville",
        "city": "Fayetteville",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12519718",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7204",
        "elev": "190",
        "icao": "KFAY",
        "direct_flights": "2",
        "carriers": "6"
    },
    {
        "code": "FEN",
        "lat": "-3.8519",
        "lon": "-32.4244",
        "name": "Fernando de Noronha",
        "city": "Vila dos Remédios",
        "state": "Pernambuco",
        "country": "Brazil",
        "woeid": "12511145",
        "tz": "AmericaRecife",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6050",
        "elev": "184",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "FKL",
        "lat": "41.3766",
        "lon": "-79.8573",
        "name": "Chess Lamberton",
        "city": "Franklin",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12519170",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "1540",
        "icao": "KFKL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "FKQ",
        "lat": "-2.46456",
        "lon": "117.996",
        "name": "Indonesia",
        "city": "Fak Fak",
        "state": "",
        "country": "Indonesia",
        "woeid": "23424846",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3936",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "FLA",
        "lat": "1.5847",
        "lon": "-75.5644",
        "name": "Gustavo Artunduaga Paredes",
        "city": "Florencia",
        "state": "Caqueta",
        "country": "Colombia",
        "woeid": "12512382",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4592",
        "elev": "803",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "FLO",
        "lat": "34.1936",
        "lon": "-79.7289",
        "name": "Florence",
        "city": "Florence",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12519746",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6498",
        "elev": "147",
        "icao": "KFLO",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "FRO",
        "lat": "61.5863",
        "lon": "5.02484",
        "name": "Flora",
        "city": "Floro",
        "state": "Sogn og Fjordane Fylke",
        "country": "Norway",
        "woeid": "12523929",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2690",
        "elev": "31",
        "icao": "ENFL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "FTA",
        "lat": "-19.5267",
        "lon": "170.215",
        "name": "Futuna",
        "city": "Futuna Island",
        "state": "Tafea",
        "country": "Vanuatu",
        "woeid": "12497035",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "FZO",
        "lat": "51.5194",
        "lon": "-2.5761",
        "name": "Filton",
        "city": "Bristol",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518089",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GAE",
        "lat": "33.8792",
        "lon": "10.1028",
        "name": "Gabes",
        "city": "Gabes",
        "state": "Qabis",
        "country": "Tunisia",
        "woeid": "12517858",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "26",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GAM",
        "lat": "63.7667",
        "lon": "-171.738",
        "name": "Gambell",
        "city": "Gambell",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519858",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "28",
        "icao": "PAGM",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "GAN",
        "lat": "-0.6912",
        "lon": "73.16",
        "name": "Gan Island",
        "city": "Hithadhoo",
        "state": "Seenu",
        "country": "Maldives",
        "woeid": "12514815",
        "tz": "IndianMaldives",
        "phone": "(+960) 313309",
        "type": "Airports",
        "email": "",
        "url": "http:www.airports.com.mvdomesticgan.htm",
        "runway_length": "2652",
        "elev": "1",
        "icao": "VRMG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GBT",
        "lat": "36.8281",
        "lon": "54.4392",
        "name": "Iran",
        "city": "Gorgan",
        "state": "Golestan",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "GDE",
        "lat": "5.93461",
        "lon": "43.5318",
        "name": "Ididole",
        "city": "GodeIddidole",
        "state": "Bale",
        "country": "Ethiopia",
        "woeid": "1317305",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7909",
        "elev": "968",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GES",
        "lat": "6.1094",
        "lon": "125.235",
        "name": "General Santos",
        "city": "General Santos City",
        "state": "Soccsksargen",
        "country": "Philippines",
        "woeid": "12515617",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.ato.gov.phAirportsTambler.htm",
        "runway_length": "10567",
        "elev": "505",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "GFF",
        "lat": "-34.2454",
        "lon": "146.068",
        "name": "Griffith",
        "city": "Griffith",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510681",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "446",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GHB",
        "lat": "25.2839",
        "lon": "-76.3308",
        "name": "Governors Harbour",
        "city": "Governor Harbour",
        "state": "Central Eleuthera",
        "country": "Bahamas",
        "woeid": "12510868",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7900",
        "elev": "23",
        "icao": "",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "GIS",
        "lat": "-38.66",
        "lon": "177.978",
        "name": "Gisborne",
        "city": "Gisborne",
        "state": "Gisborne",
        "country": "New Zealand",
        "woeid": "12515154",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "15",
        "icao": "NZGS",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GLK",
        "lat": "6.7786",
        "lon": "47.4528",
        "name": "Galcaio",
        "city": "Gaalkacyo",
        "state": "Mudug",
        "country": "Somalia",
        "woeid": "12517529",
        "tz": "AfricaMogadishu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9660",
        "elev": "975",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GLN",
        "lat": "28.9839",
        "lon": "-10.0603",
        "name": "Guelmim",
        "city": "Goulimime",
        "state": "Guelmim",
        "country": "Morocco",
        "woeid": "1535570",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "984",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GLO",
        "lat": "51.8925",
        "lon": "-2.1633",
        "name": "Gloucestershire",
        "city": "Cheltenham",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518156",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4052",
        "elev": "95",
        "icao": "EGBJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GLT",
        "lat": "-23.87",
        "lon": "151.226",
        "name": "Gladstone",
        "city": "Gladstone",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510675",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5360",
        "elev": "64",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GMA",
        "lat": "3.2347",
        "lon": "19.7719",
        "name": "Gemena",
        "city": "Gemena",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511955",
        "tz": "AfricaKinshasa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "1378",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "GMB",
        "lat": "13.4454",
        "lon": "-15.3114",
        "name": "Gambela",
        "city": "Gambela",
        "state": "Ilubabor",
        "country": "Ethiopia",
        "woeid": "1316101",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "1768",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GMZ",
        "lat": "28.0252",
        "lon": "-17.2148",
        "name": "La Gomera",
        "city": "Alajeró",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "24554860",
        "tz": "AtlanticCanary",
        "phone": "+34 922 87 30 00",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GOM",
        "lat": "-1.6661",
        "lon": "29.2344",
        "name": "Goma",
        "city": "Goma",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511956",
        "tz": "AfricaLubumbashi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "5089",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "GPI",
        "lat": "2.5806",
        "lon": "-77.9083",
        "name": "Guapi",
        "city": "Guapí",
        "state": "Cauca",
        "country": "Colombia",
        "woeid": "12512379",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4330",
        "elev": "164",
        "icao": "KGPI",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "GPS",
        "lat": "-0.45",
        "lon": "-90.2625",
        "name": "Seymour",
        "city": "Puerto Ayora",
        "state": "Galapagos",
        "country": "Ecuador",
        "woeid": "12512651",
        "tz": "PacificGalapagos",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "52",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "GTA",
        "lat": "-8.72906",
        "lon": "162.855",
        "name": "Solomon Islands",
        "city": "Gatokae",
        "state": "",
        "country": "Solomon Islands",
        "woeid": "23424766",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GVR",
        "lat": "-18.8958",
        "lon": "-41.9856",
        "name": "Governador Valadares",
        "city": "Governador Valadares",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511160",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "561",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "GXG",
        "lat": "-7.7547",
        "lon": "15.2919",
        "name": "Negage",
        "city": "Negage",
        "state": "Uige",
        "country": "Angola",
        "woeid": "12510448",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "4105",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HAA",
        "lat": "70.4849",
        "lon": "22.1126",
        "name": "Hasvik",
        "city": "Hasvik",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12523933",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2362",
        "elev": "25",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HAC",
        "lat": "33.1153",
        "lon": "139.784",
        "name": "Hachijojima",
        "city": "Hachijo-machi",
        "state": "Tokyo Prefecture",
        "country": "Japan",
        "woeid": "12513954",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5910",
        "elev": "303",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HBX",
        "lat": "15.364",
        "lon": "75.1131",
        "name": "India",
        "city": "Hubli",
        "state": "Karnataka",
        "country": "India",
        "woeid": "23424848",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HCR",
        "lat": "62.2016",
        "lon": "-159.77",
        "name": "Alaska",
        "city": "Holy Cross",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "85",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "HDB",
        "lat": "49.4167",
        "lon": "8.71667",
        "name": "Heidelberg",
        "city": "Heidelberg",
        "state": "Baden-Wurttemberg",
        "country": "Germany",
        "woeid": "12523860",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EDIU",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HDD",
        "lat": "25.3142",
        "lon": "68.3656",
        "name": "Hyderabad",
        "city": "Hyderabad",
        "state": "Sindh",
        "country": "Pakistan",
        "woeid": "12515237",
        "tz": "AsiaKarachi",
        "phone": "03012750377",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "130",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HDY",
        "lat": "6.9294",
        "lon": "100.399",
        "name": "Hat Yai",
        "city": "Hat Yai",
        "state": "Songkhla",
        "country": "Thailand",
        "woeid": "12517752",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10006",
        "elev": "91",
        "icao": "VTSS",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "HEA",
        "lat": "34.2069",
        "lon": "62.2267",
        "name": "Herat",
        "city": "Herat",
        "state": "Herat",
        "country": "Afghanistan",
        "woeid": "12510304",
        "tz": "AsiaKabul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8525",
        "elev": "3206",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "HFS",
        "lat": "60.026",
        "lon": "13.5817",
        "name": "Hagfors",
        "city": "Hagfors",
        "state": "",
        "country": "Sweden",
        "woeid": "12517632",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4954",
        "elev": "472",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HGD",
        "lat": "-20.8127",
        "lon": "144.225",
        "name": "Hughenden Aerodrome",
        "city": "Hughenden",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510695",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "1043",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HHH",
        "lat": "32.2267",
        "lon": "-80.6989",
        "name": "Hilton Head",
        "city": "Hilton Head Island",
        "state": "South Carolina",
        "country": "United States",
        "woeid": "12520191",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3700",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "HIB",
        "lat": "47.3934",
        "lon": "-92.8418",
        "name": "Chisholm Hibbing",
        "city": "Hibbing",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12519189",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6750",
        "elev": "1352",
        "icao": "KHIB",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "HIN",
        "lat": "35.085",
        "lon": "128.072",
        "name": "Sacheon",
        "city": "Sacheon-Si",
        "state": "Gyeongsangnam-Do",
        "country": "South Korea",
        "woeid": "12514226",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "25",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "HLH",
        "lat": "46.0935",
        "lon": "122.021",
        "name": "Ulanhot",
        "city": "Ulan Hot",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "12512222",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "HNM",
        "lat": "20.7944",
        "lon": "-156.015",
        "name": "Hana",
        "city": "Hana",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520072",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3605",
        "elev": "77",
        "icao": "PHHN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HNS",
        "lat": "59.2439",
        "lon": "-135.524",
        "name": "Haines",
        "city": "Haines",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388567",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4602",
        "elev": "16",
        "icao": "PAHN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HOE",
        "lat": "20.5",
        "lon": "103.75",
        "name": "Houeisay",
        "city": "Houeisay",
        "state": "Houaphan",
        "country": "Lao People's Democratic Republic",
        "woeid": "1140444",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KHOE",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HPA",
        "lat": "-19.8",
        "lon": "-174.35",
        "name": "Salote Pilolevu",
        "city": "Pangai",
        "state": "Ha'apai",
        "country": "Tonga",
        "woeid": "12523124",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "HUI",
        "lat": "16.3988",
        "lon": "107.7",
        "name": "Hue-Phu Bai",
        "city": "Hue",
        "state": "Thura Thien-Hue",
        "country": "Vietnam",
        "woeid": "12522918",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "49",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HUN",
        "lat": "24.0257",
        "lon": "121.613",
        "name": "Hwmlien",
        "city": "Hualien City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517939",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9098",
        "elev": "48",
        "icao": "RCYU",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "HVB",
        "lat": "-25.2839",
        "lon": "152.836",
        "name": "Hervey Bay",
        "city": "Hervey Bay",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12523127",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4855",
        "elev": "60",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "HYL",
        "lat": "55.4833",
        "lon": "-132.65",
        "name": "SPB",
        "city": "Hollis",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799854",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "HYS",
        "lat": "38.8546",
        "lon": "-99.2737",
        "name": "Hays Municipal",
        "city": "Hays",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12520139",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5700",
        "elev": "1998",
        "icao": "KHYS",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "IAN",
        "lat": "66.9699",
        "lon": "-160.429",
        "name": "Kiana",
        "city": "Kiana",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2432151",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "150",
        "icao": "PAIK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "IBE",
        "lat": "4.425",
        "lon": "-75.1394",
        "name": "Perales",
        "city": "Ibague",
        "state": "Tolima",
        "country": "Colombia",
        "woeid": "12512402",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4987",
        "elev": "3044",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "IGA",
        "lat": "20.9767",
        "lon": "-73.6656",
        "name": "Great Inagua",
        "city": "Matthew Town",
        "state": "Inagua",
        "country": "Bahamas",
        "woeid": "12510870",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "8",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IGS",
        "lat": "49.6622",
        "lon": "9.9275",
        "name": "Ingolstadt-Manching",
        "city": "Ingolstadt-Manching",
        "state": "Bavaria",
        "country": "Germany",
        "woeid": "663546",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IHR",
        "lat": "32.4207",
        "lon": "53.6824",
        "name": "Iran",
        "city": "Iran Shahr",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ILR",
        "lat": "8.4397",
        "lon": "4.4947",
        "name": "Ilorin",
        "city": "Ilorin",
        "state": "Kwara",
        "country": "Nigeria",
        "woeid": "12515066",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10171",
        "elev": "1125",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IMP",
        "lat": "-5.5303",
        "lon": "-47.4669",
        "name": "Prefeito Renato Moreira",
        "city": "Imperatriz",
        "state": "Maranhao",
        "country": "Brazil",
        "woeid": "12511179",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5918",
        "elev": "430",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "IMT",
        "lat": "45.8153",
        "lon": "-88.1185",
        "name": "Ford",
        "city": "Kingsford",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12519761",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "1182",
        "icao": "KIMT",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "INT",
        "lat": "36.1361",
        "lon": "-80.2294",
        "name": "Smith Reynolds",
        "city": "Winston-Salem",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12521891",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6655",
        "elev": "970",
        "icao": "KINT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IPC",
        "lat": "-27.1167",
        "lon": "-109.367",
        "name": "Mataveri",
        "city": "Easter Island",
        "state": "Valparaiso",
        "country": "Chile",
        "woeid": "12523131",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9524",
        "elev": "226",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "IPH",
        "lat": "4.5669",
        "lon": "101.096",
        "name": "Ipoh",
        "city": "Ipoh",
        "state": "Perak",
        "country": "Malaysia",
        "woeid": "12514993",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "128",
        "icao": "WMKI",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "IQN",
        "lat": "36.1",
        "lon": "107.783",
        "name": "Qingyang",
        "city": "Qingyang",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "2137122",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IQT",
        "lat": "-3.7856",
        "lon": "-73.3103",
        "name": "Cnl Fap Fran Seca Vignetta",
        "city": "Iquitos",
        "state": "Loreto",
        "country": "Peru",
        "woeid": "12515199",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "406",
        "icao": "SPQT",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "IRA",
        "lat": "-10.5",
        "lon": "161.833",
        "name": "Kirakira",
        "city": "Kirakira",
        "state": "Makira",
        "country": "Solomon Islands",
        "woeid": "1018638",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3969",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ISN",
        "lat": "48.1767",
        "lon": "-103.631",
        "name": "Sloulin Field",
        "city": "Williston",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12521885",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6041",
        "elev": "1957",
        "icao": "KISN",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "IVC",
        "lat": "-46.4124",
        "lon": "168.309",
        "name": "Invercargill",
        "city": "Invercargill",
        "state": "Southland",
        "country": "New Zealand",
        "woeid": "12515156",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.invercargillairport.co.nz",
        "runway_length": "7250",
        "elev": "5",
        "icao": "NZNV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IVL",
        "lat": "68.6064",
        "lon": "27.4029",
        "name": "Ivalo",
        "city": "Ivalo",
        "state": "Lapland",
        "country": "Finland",
        "woeid": "12512769",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "481",
        "icao": "EFIV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IVR",
        "lat": "-29.8892",
        "lon": "151.142",
        "name": "Inverell",
        "city": "Inverell",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12523134",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6935",
        "elev": "2667",
        "icao": "YIVL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IWJ",
        "lat": "-10.45",
        "lon": "150.333",
        "name": "Iwami",
        "city": "Iwami",
        "state": "Tottori Prefecture",
        "country": "Japan",
        "woeid": "1117345",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "IXC",
        "lat": "30.6751",
        "lon": "76.7973",
        "name": "Chandigarh",
        "city": "Bhabat",
        "state": "Chandigarh",
        "country": "India",
        "woeid": "12513566",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8950",
        "elev": "1012",
        "icao": "VICG",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "IXD",
        "lat": "25.4397",
        "lon": "81.7361",
        "name": "Bamrauli",
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "country": "India",
        "woeid": "12513532",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7600",
        "elev": "322",
        "icao": "VIAL",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "IXI",
        "lat": "21.7866",
        "lon": "82.7948",
        "name": "India",
        "city": "Lilabari",
        "state": "",
        "country": "India",
        "woeid": "23424848",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5480",
        "elev": "333",
        "icao": "VELR",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "IXM",
        "lat": "9.83543",
        "lon": "78.0964",
        "name": "Madurai",
        "city": "Harveypatti",
        "state": "Tamil Nadu",
        "country": "India",
        "woeid": "12513630",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5990",
        "elev": "459",
        "icao": "VOMD",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "IXZ",
        "lat": "11.692",
        "lon": "92.7097",
        "name": "Vir Savarkar",
        "city": "Port Blair",
        "state": "Andaman and Nicobar Islands",
        "country": "India",
        "woeid": "12513654",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "58",
        "icao": "VOPB",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "JCK",
        "lat": "-20.669",
        "lon": "141.722",
        "name": "Julia Creek Aerodrome",
        "city": "Julia Creek",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510702",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "404",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JDO",
        "lat": "-7.2181",
        "lon": "-39.2706",
        "name": "Cariri",
        "city": "Juazeiro do Norte",
        "state": "Ceara",
        "country": "Brazil",
        "woeid": "12511080",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5965",
        "elev": "1342",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JEJ",
        "lat": "7.58333",
        "lon": "168.917",
        "name": "Jeh",
        "city": "Jeh",
        "state": "Jabat",
        "country": "Marshall Islands",
        "woeid": "24549842",
        "tz": "PacificKwajalein",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JFR",
        "lat": "62",
        "lon": "-49.65",
        "name": "Paamiut",
        "city": "Paamiut",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "472981",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JGN",
        "lat": "39.8574",
        "lon": "98.3368",
        "name": "Jiayuguan",
        "city": "Jiayuguan",
        "state": "Gansu",
        "country": "China",
        "woeid": "12512095",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "JHM",
        "lat": "20.9619",
        "lon": "-156.676",
        "name": "Kapalua West Maui",
        "city": "Lahaina",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520422",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PHJH",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "JIJ",
        "lat": "9.34587",
        "lon": "42.7955",
        "name": "Jijiga",
        "city": "Jijiga",
        "state": "Ogaden",
        "country": "Ethiopia",
        "woeid": "1317599",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "HAJJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JLD",
        "lat": "55.8848",
        "lon": "12.8667",
        "name": "Landskrona Heliport",
        "city": "Landskrona",
        "state": "Skane",
        "country": "Sweden",
        "woeid": "12523971",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "180",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JMU",
        "lat": "46.8333",
        "lon": "130.35",
        "name": "Jiamusi",
        "city": "Jiamusi",
        "state": "Heilongjiang",
        "country": "China",
        "woeid": "2141175",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "JNS",
        "lat": "61.1427",
        "lon": "-45.4053",
        "name": "Narsaq Heliport",
        "city": "Narsaq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523848",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JOE",
        "lat": "62.6629",
        "lon": "29.6076",
        "name": "Joensuu",
        "city": "Ylämylly",
        "state": "Eastern Finland",
        "country": "Finland",
        "woeid": "12512772",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "391",
        "icao": "EFJO",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "JRB",
        "lat": "40.7039",
        "lon": "-74.0129",
        "name": "Downtown Manhattan Heliport",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "woeid": "12523144",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KJRB",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "JRH",
        "lat": "26.7286",
        "lon": "94.1778",
        "name": "Jorhat",
        "city": "Senchoa Gaon",
        "state": "Assam",
        "country": "India",
        "woeid": "12513612",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "284",
        "icao": "VEJT",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "JST",
        "lat": "40.3208",
        "lon": "-78.8306",
        "name": "Johnstown Cambria County",
        "city": "Johnstown",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12520393",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5486",
        "elev": "2284",
        "icao": "KJST",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JTY",
        "lat": "36.5551",
        "lon": "26.3533",
        "name": "Astypalaia Island",
        "city": "Astypalaea",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12523355",
        "tz": "EuropeAthens",
        "phone": "+30 2 436 1410",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3215",
        "elev": "154",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "JUZ",
        "lat": "36.8945",
        "lon": "104.166",
        "name": "Juzhou",
        "city": "Juzhou",
        "state": "",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZUJZ",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KAB",
        "lat": "-16.5183",
        "lon": "28.8853",
        "name": "Kariba",
        "city": "Kariba",
        "state": "Mashonaland West",
        "country": "Zimbabwe",
        "woeid": "12523030",
        "tz": "AfricaHarare",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5446",
        "elev": "1706",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KAL",
        "lat": "64.3248",
        "lon": "-158.725",
        "name": "Kaltag",
        "city": "Kaltag",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2430534",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "120",
        "icao": "PAKV",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KAW",
        "lat": "10.0472",
        "lon": "98.5408",
        "name": "Kawthaung",
        "city": "Kawthaung",
        "state": "Ranong",
        "country": "Myanmar",
        "woeid": "12510916",
        "tz": "AsiaBangkok",
        "phone": "+955951018",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "180",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KBC",
        "lat": "66.2667",
        "lon": "-145.8",
        "name": "Birch Creek",
        "city": "Birch Creek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518834",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "450",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KCC",
        "lat": "56.014",
        "lon": "-132.826",
        "name": "Alaska",
        "city": "Coffman Cove",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KCG",
        "lat": "56.3173",
        "lon": "-158.596",
        "name": "Chignik Fisheries",
        "city": "Chignik Lagoon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523152",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1280",
        "elev": "25",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KCL",
        "lat": "56.3065",
        "lon": "-158.537",
        "name": "Chignik Lagoon",
        "city": "Chignik Lagoon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523153",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1700",
        "elev": "50",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KCM",
        "lat": "37.5907",
        "lon": "36.9414",
        "name": "Kahramanmaras",
        "city": "Kahramanmaraş",
        "state": "Kahramanmaraş",
        "country": "Turkey",
        "woeid": "12523444",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KCQ",
        "lat": "56.3119",
        "lon": "-158.362",
        "name": "Chignik Lake",
        "city": "Chignik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523154",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "50",
        "icao": "PAJC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KDH",
        "lat": "31.5069",
        "lon": "65.8475",
        "name": "Kandahar",
        "city": "Alaqadari Daman",
        "state": "Kandahar",
        "country": "Afghanistan",
        "woeid": "12510307",
        "tz": "AsiaKabul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "3312",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KDO",
        "lat": "5.22745",
        "lon": "73.0989",
        "name": "Kudadu",
        "city": "Kadhdhoo",
        "state": "Baa",
        "country": "Maldives",
        "woeid": "12509007",
        "tz": "IndianMaldives",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KDV",
        "lat": "-19.05",
        "lon": "178.217",
        "name": "Kandavu",
        "city": "Kandavu",
        "state": "Eastern",
        "country": "Fiji",
        "woeid": "12523306",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2502",
        "elev": "6",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KEK",
        "lat": "59.3516",
        "lon": "-157.48",
        "name": "Ekwok",
        "city": "Ekwok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2397726",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2720",
        "elev": "130",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KET",
        "lat": "21.3014",
        "lon": "99.6378",
        "name": "Kengtung",
        "city": "Keng Tung",
        "state": "Shan State",
        "country": "Myanmar",
        "woeid": "12510917",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4720",
        "elev": "2800",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KGA",
        "lat": "-5.9",
        "lon": "22.4706",
        "name": "Kananga",
        "city": "Kananga",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511960",
        "tz": "AfricaKinshasa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "2133",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KGI",
        "lat": "-30.7896",
        "lon": "121.461",
        "name": "Kalgoorlie Bolder",
        "city": "Kalgoorlie",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510703",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5850",
        "elev": "1181",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KGK",
        "lat": "59.7266",
        "lon": "-157.26",
        "name": "Koliganek",
        "city": "New Koliganek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388571",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2140",
        "elev": "240",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KGX",
        "lat": "62.9053",
        "lon": "-160.067",
        "name": "Alaska",
        "city": "Grayling",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "99",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KHG",
        "lat": "39.5415",
        "lon": "76.0176",
        "name": "Kashi",
        "city": "Kashi",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "12512108",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KHS",
        "lat": "26.1733",
        "lon": "56.2403",
        "name": "Khasab",
        "city": "Khasab",
        "state": "Musandam",
        "country": "Oman",
        "woeid": "12514809",
        "tz": "AsiaMuscat",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "100",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KID",
        "lat": "55.9241",
        "lon": "14.0819",
        "name": "Kristianstad",
        "city": "Tollarp",
        "state": "Skane",
        "country": "Sweden",
        "woeid": "22435246",
        "tz": "EuropeStockholm",
        "phone": "+46 (0)44-238800",
        "type": "Airports",
        "email": "",
        "url": "http:www.kidairport.com",
        "runway_length": "6562",
        "elev": "76",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KIM",
        "lat": "-28.8014",
        "lon": "24.7639",
        "name": "B J Vorster",
        "city": "Kimberley",
        "state": "Northern Cape",
        "country": "South Africa",
        "woeid": "12517394",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "3949",
        "icao": "FAKM",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KIS",
        "lat": "-0.0861",
        "lon": "34.7278",
        "name": "Kisumu",
        "city": "Kisumu",
        "state": "Nyanza",
        "country": "Kenya",
        "woeid": "12514068",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6693",
        "elev": "3775",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "KLW",
        "lat": "55.5743",
        "lon": "-133.064",
        "name": "Klawock Seaplane Base",
        "city": "Klawock",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520485",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "50",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KMA",
        "lat": "-7.9636",
        "lon": "145.77",
        "name": "Kerema",
        "city": "Kerema",
        "state": "Gulf",
        "country": "Papua New Guinea",
        "woeid": "12515470",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3100",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KMO",
        "lat": "58.9817",
        "lon": "-159.058",
        "name": "Manokotak",
        "city": "Manokotak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2444918",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "107",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KNQ",
        "lat": "-21",
        "lon": "164.675",
        "name": "Kone",
        "city": "Kone",
        "state": "Nord",
        "country": "New Caledonia",
        "woeid": "1049645",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "23",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KNU",
        "lat": "26.4414",
        "lon": "80.3656",
        "name": "Kanpur",
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "country": "India",
        "woeid": "12513617",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "405",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KNW",
        "lat": "59.4513",
        "lon": "-157.317",
        "name": "New Stuyahok",
        "city": "New Stuyahok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2459005",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "75",
        "icao": "PANW",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KOC",
        "lat": "32.4981",
        "lon": "-89.8925",
        "name": "Koumac",
        "city": "Koumac",
        "state": "Nord",
        "country": "New Caledonia",
        "woeid": "24551437",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4900",
        "elev": "39",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KOK",
        "lat": "63.7211",
        "lon": "23.1438",
        "name": "Kruunupyy",
        "city": "Kruunupyy",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12512778",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.ilmailulaitos.fiairport_kruunupyy",
        "runway_length": "6890",
        "elev": "84",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KPN",
        "lat": "59.9371",
        "lon": "-164.041",
        "name": "Kipnuk",
        "city": "Kipnuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2433000",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "2140",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "KRL",
        "lat": "41.7333",
        "lon": "86.15",
        "name": "Korla",
        "city": "Korla",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "2143697",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KSA",
        "lat": "5.3183",
        "lon": "162.97",
        "name": "Kosrae Island",
        "city": "Tofol",
        "state": "Kosrae",
        "country": "Federated States of Micronesia",
        "woeid": "12512815",
        "tz": "PacificPonape",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "6",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KSF",
        "lat": "51.4066",
        "lon": "9.37722",
        "name": "Kassel Calden",
        "city": "Kalden",
        "state": "Hesse",
        "country": "Germany",
        "woeid": "22180427",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.flughafenkassel.de",
        "runway_length": "5495",
        "elev": "907",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KSH",
        "lat": "34.3489",
        "lon": "47.1572",
        "name": "Bakhtaran",
        "city": "Kermanshah",
        "state": "Kermanshah",
        "country": "Iran",
        "woeid": "12513702",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "4307",
        "icao": "OICC",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KSJ",
        "lat": "35.3903",
        "lon": "26.9371",
        "name": "Kasos",
        "city": "St. Marina",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513294",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2493",
        "elev": "33",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KSQ",
        "lat": "38.8042",
        "lon": "65.7717",
        "name": "Karshi South",
        "city": "Qarshi",
        "state": "Qashqadaryo",
        "country": "Uzbekistan",
        "woeid": "12522643",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KTA",
        "lat": "-20.7108",
        "lon": "116.776",
        "name": "Karratha",
        "city": "Karratha",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510704",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "29",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "KUD",
        "lat": "33.0758",
        "lon": "75.2973",
        "name": "Kudat",
        "city": "Kudat",
        "state": "Sabah",
        "country": "Malaysia",
        "woeid": "1154804",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2411",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KUO",
        "lat": "63.0071",
        "lon": "27.7979",
        "name": "Kuopio",
        "city": "Toivala",
        "state": "Eastern Finland",
        "country": "Finland",
        "woeid": "12512779",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "322",
        "icao": "",
        "direct_flights": "2",
        "carriers": "8"
    },
    {
        "code": "KVR",
        "lat": "49.1825",
        "lon": "-0.45906",
        "name": "Carpiquet",
        "city": "Carpiquet",
        "state": "Basse-Normandie",
        "country": "France",
        "woeid": "12523083",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KWJ",
        "lat": "35.125",
        "lon": "126.811",
        "name": "Gwangju",
        "city": "Gwangju",
        "state": "Gwangju",
        "country": "South Korea",
        "woeid": "12514210",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9300",
        "elev": "42",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "KWM",
        "lat": "-15.4878",
        "lon": "141.76",
        "name": "Kowanyama",
        "city": "Kowanyama",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708477",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KWN",
        "lat": "59.7511",
        "lon": "-161.908",
        "name": "Quinhagak",
        "city": "Quinhagak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2477888",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2700",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "KXU",
        "lat": "-16.4333",
        "lon": "-1.36667",
        "name": "Katiu",
        "city": "Katiu",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "24549702",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KYA",
        "lat": "37.9797",
        "lon": "32.5622",
        "name": "Konya",
        "city": "Konya",
        "state": "Konya",
        "country": "Turkey",
        "woeid": "12517903",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10942",
        "elev": "3390",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KZB",
        "lat": "57.55",
        "lon": "-153.75",
        "name": "Zachar Bay",
        "city": "Zachar Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799679",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "KZI",
        "lat": "40.2886",
        "lon": "21.8419",
        "name": "Kozani",
        "city": "Kozani",
        "state": "Dytiki Makedonia",
        "country": "Greece",
        "woeid": "12513302",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "2080",
        "icao": "LGKZ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LAJ",
        "lat": "-27.7831",
        "lon": "-50.2825",
        "name": "Lajes",
        "city": "Lages",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511208",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "3068",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LAO",
        "lat": "18.1797",
        "lon": "120.529",
        "name": "Laoag",
        "city": "San Nicolas",
        "state": "Ilocos Region",
        "country": "Philippines",
        "woeid": "12515626",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7940",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LAR",
        "lat": "37.8527",
        "lon": "-76.517",
        "name": "General Brees Field",
        "city": "Laramie",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12793533",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7700",
        "elev": "7278",
        "icao": "KLAR",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LAU",
        "lat": "-2.25",
        "lon": "40.8333",
        "name": "Lamu",
        "city": "Lamu",
        "state": "Coast",
        "country": "Kenya",
        "woeid": "12523159",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LAW",
        "lat": "34.573",
        "lon": "-98.4135",
        "name": "Lawton Municipal",
        "city": "Lawton",
        "state": "Oklahoma",
        "country": "United States",
        "woeid": "12520593",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8599",
        "elev": "1110",
        "icao": "KLAW",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LBL",
        "lat": "37.0446",
        "lon": "-100.952",
        "name": "Liberal Municipal",
        "city": "Liberal",
        "state": "Kansas",
        "country": "United States",
        "woeid": "12520643",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7100",
        "elev": "2887",
        "icao": "KLBL",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LBP",
        "lat": "3.18333",
        "lon": "115.45",
        "name": "Long Banga",
        "city": "Long Banga",
        "state": "",
        "country": "Malaysia",
        "woeid": "23424901",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LCK",
        "lat": "39.8176",
        "lon": "-82.936",
        "name": "Rickenbacker",
        "city": "Columbus",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12521590",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12100",
        "elev": "744",
        "icao": "KLCK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LCR",
        "lat": "-0.73333",
        "lon": "-73.0167",
        "name": "La Chorrera",
        "city": "La Chorrera",
        "state": "Amazonas",
        "country": "Colombia",
        "woeid": "12524485",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LEB",
        "lat": "43.6249",
        "lon": "-72.3087",
        "name": "Lebanon Municipal",
        "city": "West Lebanon",
        "state": "New Hampshire",
        "country": "United States",
        "woeid": "12520604",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5495",
        "elev": "589",
        "icao": "KLEB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "LER",
        "lat": "-27.873",
        "lon": "120.61",
        "name": "Leinster",
        "city": "Sir Samuel",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12523286",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5700",
        "elev": "1631",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LGL",
        "lat": "3.41667",
        "lon": "115.15",
        "name": "Long Lellang",
        "city": "Long Lellang",
        "state": "",
        "country": "Malaysia",
        "woeid": "23424901",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2559",
        "elev": "1400",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LKH",
        "lat": "3.31667",
        "lon": "114.783",
        "name": "Long Akah",
        "city": "Long Akah",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12740115",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LKL",
        "lat": "70.0661",
        "lon": "24.975",
        "name": "Banak",
        "city": "Lakeselv",
        "state": "Finnmark Fylke",
        "country": "Norway",
        "woeid": "12515102",
        "tz": "EuropeOslo",
        "phone": "47-78-46-50-01",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "25",
        "icao": "ENNA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LKN",
        "lat": "68.1564",
        "lon": "13.6121",
        "name": "Leknes",
        "city": "Leknes",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523939",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "86",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LMT",
        "lat": "42.1638",
        "lon": "-121.745",
        "name": "Klamath Falls",
        "city": "Klamath Falls",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12520483",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10301",
        "elev": "4092",
        "icao": "KLMT",
        "direct_flights": "2",
        "carriers": "6"
    },
    {
        "code": "LMY",
        "lat": "-7.18482",
        "lon": "142.374",
        "name": "Western",
        "city": "Lake Murray",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "2346586",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2495",
        "elev": "200",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LNB",
        "lat": "-16.5833",
        "lon": "168.183",
        "name": "Lamen Bay",
        "city": "Lamen Bay",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2789",
        "elev": "7",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LNO",
        "lat": "-28.8776",
        "lon": "121.315",
        "name": "Leonora Aerodrome",
        "city": "Leonora",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12523165",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4406",
        "elev": "1217",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LPM",
        "lat": "-16.4445",
        "lon": "167.819",
        "name": "Lamap",
        "city": "Lamap",
        "state": "Malampa",
        "country": "Vanuatu",
        "woeid": "12523167",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2756",
        "elev": "7",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LQM",
        "lat": "-0.12003",
        "lon": "-74.8204",
        "name": "Puerto Leguizamo",
        "city": "Puerto Leguízamo",
        "state": "Putumayo",
        "country": "Colombia",
        "woeid": "12524491",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3902",
        "elev": "765",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LRE",
        "lat": "-23.4375",
        "lon": "144.278",
        "name": "Longreach Aerodrome",
        "city": "Longreach",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510721",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "630",
        "icao": "YLRE",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "LRT",
        "lat": "47.7545",
        "lon": "-3.43763",
        "name": "Lann Bihoue",
        "city": "Ploemeur",
        "state": "Brittany",
        "country": "France",
        "woeid": "12512912",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7316",
        "elev": "161",
        "icao": "LFRH",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LSA",
        "lat": "-6.65619",
        "lon": "145.859",
        "name": "Papua New Guinea",
        "city": "Losuia",
        "state": null,
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5593",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LUD",
        "lat": "-26.6839",
        "lon": "15.245",
        "name": "Luderitz",
        "city": "Luderitz",
        "state": "Karas",
        "country": "Namibia",
        "woeid": "12522980",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "426",
        "icao": "KLUD",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LUQ",
        "lat": "-33.2744",
        "lon": "-66.3586",
        "name": "San Luis",
        "city": "Villa General Roca",
        "state": "San Luis",
        "country": "Argentina",
        "woeid": "12510554",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "2329",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LVI",
        "lat": "-17.8208",
        "lon": "25.8225",
        "name": "Livingstone",
        "city": "Livingstone",
        "state": "Southern",
        "country": "Zambia",
        "woeid": "12523009",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7520",
        "elev": "3250",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "LVO",
        "lat": "-28.6083",
        "lon": "122.417",
        "name": "Laverton Aerodrome",
        "city": "Laverton",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510713",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4606",
        "elev": "1525",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LWE",
        "lat": "0.10974",
        "lon": "113.917",
        "name": "Indonesia",
        "city": "Lewoleba",
        "state": "",
        "country": "Indonesia",
        "woeid": "23424846",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LWY",
        "lat": "4.8615",
        "lon": "115.406",
        "name": "Lawas",
        "city": "Lawas",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "1154824",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1700",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LYB",
        "lat": "19.6901",
        "lon": "-80.0385",
        "name": "Boddenfield",
        "city": "Little Cayman",
        "state": "Little Cayman",
        "country": "Cayman Islands",
        "woeid": "12512343",
        "tz": "AmericaCayman",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3311",
        "elev": "4",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "LYC",
        "lat": "64.5501",
        "lon": "18.7082",
        "name": "Lycksele",
        "city": "Lyoksele",
        "state": "Vasterbotten",
        "country": "Sweden",
        "woeid": "12517660",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3609",
        "elev": "692",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "LYH",
        "lat": "37.3309",
        "lon": "-79.1944",
        "name": "Lynchburg",
        "city": "Lynchburg",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12520730",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5799",
        "elev": "938",
        "icao": "KLYH",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "LYR",
        "lat": "78.1917",
        "lon": "15.9",
        "name": "Svalbard Longyear",
        "city": "Longyearbyen",
        "state": "Svalbard",
        "country": "Norway",
        "woeid": "12517605",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7021",
        "elev": "94",
        "icao": "ENSB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "LZN",
        "lat": "26.1497",
        "lon": "119.939",
        "name": "Nankan",
        "city": "Nangan",
        "state": "Fujian",
        "country": "Taiwan",
        "woeid": "28752831",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MAB",
        "lat": "-5.3533",
        "lon": "-49.1336",
        "name": "Maraba",
        "city": "Marabá",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511224",
        "tz": "AmericaAraguaina",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6575",
        "elev": "358",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MBH",
        "lat": "-25.515",
        "lon": "152.714",
        "name": "Maryborough",
        "city": "Maryborough",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510730",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "38",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MCP",
        "lat": "0.0525",
        "lon": "-51.0675",
        "name": "Macapa",
        "city": "Macapá",
        "state": "Amapa",
        "country": "Brazil",
        "woeid": "12511218",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6847",
        "elev": "52",
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "MCW",
        "lat": "43.153",
        "lon": "-93.3361",
        "name": "Mason City Municipal",
        "city": "Clear Lake",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12520832",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "1213",
        "icao": "KMCW",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MDK",
        "lat": "0.0233",
        "lon": "18.2922",
        "name": "Mbandaka",
        "city": "Mbandaka",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511971",
        "tz": "AfricaKinshasa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7430",
        "elev": "1040",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MDS",
        "lat": "21.7905",
        "lon": "-71.7664",
        "name": "Middle Caicos",
        "city": "Lorimers",
        "state": "Middle Caicos",
        "country": "Turks And Caicos Islands",
        "woeid": "12524422",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "10",
        "icao": "KMDS",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MDZ",
        "lat": "-32.8319",
        "lon": "-68.7847",
        "name": "El Plumerillo",
        "city": "Mendoza",
        "state": "Mendoza",
        "country": "Argentina",
        "woeid": "12510491",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9885",
        "elev": "2312",
        "icao": "KMDZ",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "MGB",
        "lat": "-37.7466",
        "lon": "140.787",
        "name": "Mount Gambier",
        "city": "Mount Gambier",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510744",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "212",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MGF",
        "lat": "-23.4394",
        "lon": "-51.9061",
        "name": "Maringa Domestic",
        "city": "Maringa",
        "state": "Parana",
        "country": "Brazil",
        "woeid": "12511231",
        "tz": "AmericaSao_Paulo",
        "phone": "55-44-3266-3838",
        "type": "Airports",
        "email": "",
        "url": "http:www.aeroportomaringa.com.br",
        "runway_length": "5250",
        "elev": "1821",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MGW",
        "lat": "39.6411",
        "lon": "-79.9241",
        "name": "Morgantown Municipal-Hart Field",
        "city": "Morgantown",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12521027",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5199",
        "elev": "1248",
        "icao": "KMGW",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MHQ",
        "lat": "60.1249",
        "lon": "19.9075",
        "name": "Mariehamn",
        "city": "Maarianhamina",
        "state": "Aland Islands",
        "country": "Finland",
        "woeid": "12512784",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "17",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MJA",
        "lat": "-23.3548",
        "lon": "43.6631",
        "name": "Toliara",
        "city": "Manja",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "1364703",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "787",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MJI",
        "lat": "26.3385",
        "lon": "17.2688",
        "name": "Libya",
        "city": "Mitiga",
        "state": "",
        "country": "Libya",
        "woeid": "23424882",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "HLLM",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MKC",
        "lat": "39.1197",
        "lon": "-94.5906",
        "name": "Kansas City Downtown",
        "city": "Kansas City",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12520419",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7001",
        "elev": "758",
        "icao": "KMKC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MKM",
        "lat": "2.91165",
        "lon": "112.609",
        "name": "Sarawak",
        "city": "Mukah",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "2346305",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2460",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MLW",
        "lat": "6.2897",
        "lon": "-10.7581",
        "name": "Monrovia Spriggs Payne",
        "city": "Monrovia",
        "state": "Montserrado",
        "country": "Liberia",
        "woeid": "12514602",
        "tz": "AfricaMonrovia",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5997",
        "elev": "25",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MMG",
        "lat": "-24.4378",
        "lon": "121.079",
        "name": "Western Australia",
        "city": "Mount Magnet",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "2344706",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5030",
        "elev": "1350",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MNJ",
        "lat": "-21.2017",
        "lon": "48.3583",
        "name": "Mananjary",
        "city": "Mananjary",
        "state": "Fianarantsoa",
        "country": "Madagascar",
        "woeid": "12514701",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MOC",
        "lat": "-16.7044",
        "lon": "-43.8203",
        "name": "Montes Claros",
        "city": "Montes Claros",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511238",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "2192",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MOF",
        "lat": "-8.6381",
        "lon": "122.24",
        "name": "Wai Oti",
        "city": "Maumere",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12513521",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4947",
        "elev": "115",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MOU",
        "lat": "62.0833",
        "lon": "-163.733",
        "name": "Mountain Village",
        "city": "Mountain Village",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799686",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2214",
        "elev": "165",
        "icao": "PAMO",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MQL",
        "lat": "-34.2308",
        "lon": "142.084",
        "name": "Mildura",
        "city": "Mildura",
        "state": "Victoria",
        "country": "Australia",
        "woeid": "12510736",
        "tz": "AustraliaMelbourne",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4852",
        "elev": "163",
        "icao": "YMIA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MQM",
        "lat": "37.2233",
        "lon": "40.6317",
        "name": "Mardin",
        "city": "Mardin",
        "state": "Mardin",
        "country": "Turkey",
        "woeid": "2329522",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MQX",
        "lat": "13.4667",
        "lon": "39.5167",
        "name": "Makale",
        "city": "Makale",
        "state": "Tigray",
        "country": "Ethiopia",
        "woeid": "1318717",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "7320",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MSJ",
        "lat": "40.7053",
        "lon": "141.361",
        "name": "Misawa",
        "city": "Misawa-shi",
        "state": "Aomori Prefecture",
        "country": "Japan",
        "woeid": "12523178",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "119",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MSL",
        "lat": "34.7439",
        "lon": "-87.6185",
        "name": "Muscle Shoals",
        "city": "Muscle Shoals",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12521070",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6693",
        "elev": "550",
        "icao": "KMSL",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MSR",
        "lat": "38.7472",
        "lon": "41.6589",
        "name": "Mus",
        "city": "Mush",
        "state": "Muş",
        "country": "Turkey",
        "woeid": "12517909",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MTV",
        "lat": "-13.8167",
        "lon": "167.7",
        "name": "Mota Lava",
        "city": "Mota Lava",
        "state": "Torba",
        "country": "Vanuatu",
        "woeid": "1050899",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2950",
        "elev": "63",
        "icao": "KMTV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MUN",
        "lat": "9.7514",
        "lon": "-63.1528",
        "name": "Maturin",
        "city": "Aguasay",
        "state": "Monagas",
        "country": "Venezuela",
        "woeid": "12522819",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "224",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MUZ",
        "lat": "-1.77227",
        "lon": "33.9811",
        "name": "Mara",
        "city": "Musoma",
        "state": "Mara",
        "country": "Tanzania",
        "woeid": "2347359",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5250",
        "elev": "3778",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MVR",
        "lat": "10.4533",
        "lon": "14.2564",
        "name": "Maroua Salak",
        "city": "Maroua",
        "state": "Extreme-Nord",
        "country": "Cameroon",
        "woeid": "12512351",
        "tz": "AfricaDouala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "1391",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "MVT",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Mataiva",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3936",
        "elev": "3",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MWF",
        "lat": "-15.1609",
        "lon": "168.145",
        "name": "Vanuatu",
        "city": "Maewo",
        "state": "Penama",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2789",
        "elev": "509",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MXM",
        "lat": "-21.7506",
        "lon": "43.3742",
        "name": "Morombe",
        "city": "Morombe",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "12514704",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "16",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MXZ",
        "lat": "24.35",
        "lon": "116.133",
        "name": "Meixian",
        "city": "Meixian",
        "state": "广东省",
        "country": "China",
        "woeid": "12713337",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MYA",
        "lat": "-35.8983",
        "lon": "150.147",
        "name": "Moruya Aerodrome",
        "city": "Bingie",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510743",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4974",
        "elev": "14",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MYI",
        "lat": "-9.91682",
        "lon": "144.051",
        "name": "Murray Island",
        "city": "Murray Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "28645248",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MYL",
        "lat": "44.8968",
        "lon": "-116.096",
        "name": "Mccall",
        "city": "Mccall",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12520848",
        "tz": "AmericaBoise",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5002",
        "elev": "5023",
        "icao": "KMYL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MYT",
        "lat": "25.3844",
        "lon": "97.3553",
        "name": "Myitkyina",
        "city": "Myitkyina",
        "state": "Kachin State",
        "country": "Myanmar",
        "woeid": "12510927",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8595",
        "elev": "470",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MYU",
        "lat": "60.3906",
        "lon": "-166.191",
        "name": "Mekoryuk",
        "city": "Mekoryuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2449074",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3271",
        "elev": "48",
        "icao": "PAMY",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MZI",
        "lat": "14.5206",
        "lon": "-4.0872",
        "name": "Mopti Barbe",
        "city": "Mopti",
        "state": "Mopti",
        "country": "Mali",
        "woeid": "12514776",
        "tz": "AfricaBamako",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "906",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "MZL",
        "lat": "5.0322",
        "lon": "-75.4708",
        "name": "La Nubia",
        "city": "Villamaría",
        "state": "Caldas",
        "country": "Colombia",
        "woeid": "12512389",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "3380",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "MZR",
        "lat": "36.7042",
        "lon": "67.2083",
        "name": "Mazar I Sharif",
        "city": "Mazar-i-Sharif",
        "state": "Balkh",
        "country": "Afghanistan",
        "woeid": "12510309",
        "tz": "AsiaKabul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8725",
        "elev": "1284",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NAJ",
        "lat": "22.8799",
        "lon": "72.5687",
        "name": "Nakhichevan",
        "city": "Nakhichevan",
        "state": "Naxcivan",
        "country": "Azerbaijan",
        "woeid": "1951979",
        "tz": "AsiaBaku",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NAO",
        "lat": "30.7556",
        "lon": "106.057",
        "name": "Nanchong",
        "city": "Nanchong",
        "state": "Sichuan",
        "country": "China",
        "woeid": "12512150",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NAQ",
        "lat": "77.5",
        "lon": "-69.1667",
        "name": "Qaanaaq",
        "city": "Qaanaaq",
        "state": "Nordgronland",
        "country": "Greenland",
        "woeid": "472989",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NAU",
        "lat": "-14.1667",
        "lon": "-141.583",
        "name": "Napuka Island",
        "city": "Napuka Island",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "22504011",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4101",
        "elev": "7",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NBW",
        "lat": "19.9093",
        "lon": "-75.2076",
        "name": "Guantanamo Bay Naval Air Station",
        "city": "Caimanera",
        "state": "Guantanamo",
        "country": "Cuba",
        "woeid": "12512463",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NCA",
        "lat": "21.9167",
        "lon": "-71.9425",
        "name": "North Caicos",
        "city": "Bottle Creek Settlements",
        "state": "North Caicos",
        "country": "Turks And Caicos Islands",
        "woeid": "12517842",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4280",
        "elev": "10",
        "icao": "KNCA",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NCY",
        "lat": "45.931",
        "lon": "6.10666",
        "name": "Meythet",
        "city": "Pringy",
        "state": "Rhone-Alpes",
        "country": "France",
        "woeid": "12512937",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4134",
        "elev": "1512",
        "icao": "LFLP",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NDB",
        "lat": "20.9342",
        "lon": "-17.0283",
        "name": "Nouadhibou",
        "city": "Port-Etienne",
        "state": "Dakhlet Nouadhibou",
        "country": "Mauritania",
        "woeid": "12514805",
        "tz": "AfricaNouakchott",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7956",
        "elev": "16",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NDG",
        "lat": "47.5167",
        "lon": "112.133",
        "name": "Qiqihar",
        "city": "Qiqihar",
        "state": "Suhbaatar",
        "country": "China",
        "woeid": "2265748",
        "tz": "AsiaHarbin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "NIP",
        "lat": "30.2342",
        "lon": "-81.6753",
        "name": "Jacksonville Naval Air Station",
        "city": "Jacksonville",
        "state": "Florida",
        "country": "United States",
        "woeid": "12520342",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KNIP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NIU",
        "lat": "21.3358",
        "lon": "-157.919",
        "name": "Honolulu",
        "city": "Honolulu",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "12520216",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NQN",
        "lat": "-38.9489",
        "lon": "-68.1569",
        "name": "Neuquen",
        "city": "Neuquen",
        "state": "Neuquen",
        "country": "Argentina",
        "woeid": "12510526",
        "tz": "AmericaArgentinaMendoza",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8434",
        "elev": "896",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NQU",
        "lat": "5.69889",
        "lon": "-77.2765",
        "name": "Nuqui",
        "city": "Nuquí",
        "state": "Choco",
        "country": "Colombia",
        "woeid": "12523644",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2427",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NSH",
        "lat": "42.7584",
        "lon": "-71.4642",
        "name": "Now Shahr",
        "city": "Now Shahr",
        "state": "Mazandaran",
        "country": "Iran",
        "woeid": "2255009",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6663",
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NUI",
        "lat": "70.2167",
        "lon": "-150.917",
        "name": "Nuiqsut",
        "city": "Nuiqsut",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799827",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "50",
        "icao": "KNUI",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "NUL",
        "lat": "64.7134",
        "lon": "-158.077",
        "name": "Nulato",
        "city": "Nulato",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388568",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2980",
        "elev": "310",
        "icao": "PANU",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "NUP",
        "lat": "60.897",
        "lon": "-162.453",
        "name": "Alaska",
        "city": "Nunapitchuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "NYK",
        "lat": "-0.0583",
        "lon": "37.0369",
        "name": "Nanyuki",
        "city": "Nyeri",
        "state": "Rift Valley",
        "country": "Kenya",
        "woeid": "12514079",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "6250",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OAJ",
        "lat": "34.8307",
        "lon": "-77.6053",
        "name": "Albert J Ellis",
        "city": "Richlands",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12518557",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7100",
        "elev": "94",
        "icao": "KOAJ",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "OBU",
        "lat": "66.9148",
        "lon": "-156.878",
        "name": "Kobuk",
        "city": "Kobuk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524644",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "145",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "OBX",
        "lat": "-7.58333",
        "lon": "141.333",
        "name": "Obo",
        "city": "Obo",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "1061857",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1600",
        "elev": "69",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ODN",
        "lat": "2.91165",
        "lon": "112.609",
        "name": "Sarawak",
        "city": "Long Seridan",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "2346305",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1500",
        "elev": "607",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OIM",
        "lat": "34.7828",
        "lon": "139.361",
        "name": "Oshima",
        "city": "Oshima-machi",
        "state": "Tokyo Prefecture",
        "country": "Japan",
        "woeid": "12514010",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "128",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OMD",
        "lat": "-28.5956",
        "lon": "16.4436",
        "name": "Oranjemund",
        "city": "Oranjemund",
        "state": "Karas",
        "country": "Namibia",
        "woeid": "12522987",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "2",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OMH",
        "lat": "37.6694",
        "lon": "45.0703",
        "name": "Uromiyeh",
        "city": "Urmieh",
        "state": "Azarbayjan-e Gharbi",
        "country": "Iran",
        "woeid": "12513769",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9514",
        "elev": "4295",
        "icao": "KOMH",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OMR",
        "lat": "47.0249",
        "lon": "21.9292",
        "name": "Oradea",
        "city": "Oradea-Mare",
        "state": "Bihor",
        "country": "Romania",
        "woeid": "12523966",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "462",
        "icao": "LROD",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ONJ",
        "lat": "40.1931",
        "lon": "140.366",
        "name": "Odate-Noshiro",
        "city": "Kitakita-shi",
        "state": "Akita Prefecture",
        "country": "Japan",
        "woeid": "28360521",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OPS",
        "lat": "-11.8733",
        "lon": "-55.5733",
        "name": "Sinop",
        "city": "Sinop",
        "state": "Mato Grosso",
        "country": "Brazil",
        "woeid": "12511328",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OSK",
        "lat": "57.3517",
        "lon": "16.4956",
        "name": "Oskarshamn",
        "city": "Fårbo",
        "state": "Kalmar",
        "country": "Sweden",
        "woeid": "12517668",
        "tz": "EuropeStockholm",
        "phone": "+46 (0)491-332 00",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2953",
        "elev": "96",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "OSZ",
        "lat": "54.25",
        "lon": "16.25",
        "name": "Koszalin",
        "city": "Sianów",
        "state": "Zachodniopomorskie",
        "country": "Poland",
        "woeid": "12523958",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "OTH",
        "lat": "43.4141",
        "lon": "-124.244",
        "name": "North Bend Municipal",
        "city": "North Bend",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12521150",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5045",
        "elev": "14",
        "icao": "KOTH",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "OZC",
        "lat": "8.1822",
        "lon": "123.841",
        "name": "Ozamis-Mindanao Island",
        "city": "Ozamis City",
        "state": "Northern Mindanao",
        "country": "Philippines",
        "woeid": "12515640",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "16",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "OZZ",
        "lat": "30.9342",
        "lon": "-6.9031",
        "name": "Ouarzazate",
        "city": "Ouarzazate",
        "state": "Ouarzazate",
        "country": "Morocco",
        "woeid": "12514789",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9842",
        "elev": "3737",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PBJ",
        "lat": "-16.2368",
        "lon": "167.492",
        "name": "Malampa",
        "city": "Paama",
        "state": "Malampa",
        "country": "Vanuatu",
        "woeid": "20069886",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PBO",
        "lat": "-23.1688",
        "lon": "117.748",
        "name": "Paraburdoo",
        "city": "Paraburdoo",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510757",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "1411",
        "icao": "YPBO",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PCR",
        "lat": "6.18333",
        "lon": "-67.6333",
        "name": "Puerto Carreno",
        "city": "Puerto Carreño",
        "state": "Vichada",
        "country": "Colombia",
        "woeid": "12523680",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5865",
        "elev": "180",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PDA",
        "lat": "3.95607",
        "lon": "-67.784",
        "name": "Puerto Inirida",
        "city": "Guaviare",
        "state": "Guainia",
        "country": "Colombia",
        "woeid": "12523681",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5576",
        "elev": "460",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PDP",
        "lat": "-34.9111",
        "lon": "-54.9197",
        "name": "Maldonado",
        "city": "Punta del Este",
        "state": "Maldonado",
        "country": "Uruguay",
        "woeid": "12522563",
        "tz": "AmericaMontevideo",
        "phone": "042 559777 - 042 559387",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2133",
        "elev": "95",
        "icao": "SULS",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PDT",
        "lat": "45.6893",
        "lon": "-118.839",
        "name": "Eastern Oregon",
        "city": "Pendleton",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12521330",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6301",
        "elev": "1494",
        "icao": "KPDT",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "PED",
        "lat": "50.0372",
        "lon": "15.7785",
        "name": "Pardubice",
        "city": "Pardubice",
        "state": "Pardubicky Kraj",
        "country": "Czech Republic",
        "woeid": "795793",
        "tz": "EuropePrague",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LKPD",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PES",
        "lat": "61.8886",
        "lon": "34.1452",
        "name": "Petrozavodsk Northwest",
        "city": "Petrozavodsk",
        "state": "Kareliya",
        "country": "Russia",
        "woeid": "12516688",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PET",
        "lat": "-31.7181",
        "lon": "-52.3244",
        "name": "Pelotas",
        "city": "Pelotas",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511267",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6503",
        "elev": "59",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PEX",
        "lat": "65.1179",
        "lon": "57.1095",
        "name": "Pechora Southwest",
        "city": "Ukhta",
        "state": "Komi",
        "country": "Russia",
        "woeid": "12516665",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KPEX",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PGA",
        "lat": "36.9243",
        "lon": "-111.451",
        "name": "Page Municipal",
        "city": "Page",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12521277",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5499",
        "elev": "4310",
        "icao": "KPGA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PGK",
        "lat": "-2.1606",
        "lon": "106.141",
        "name": "Pangkalpinang",
        "city": "Pangkalpinang",
        "state": "Bangka-Belitung",
        "country": "Indonesia",
        "woeid": "12513490",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4987",
        "elev": "109",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PHE",
        "lat": "-20.3772",
        "lon": "118.63",
        "name": "Port Hedland",
        "city": "Port Hedland",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510764",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "28",
        "icao": "YPPD",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PHG",
        "lat": "4.85",
        "lon": "7.01667",
        "name": "Port Harcourt City",
        "city": "Port Harcourt",
        "state": "Rivers",
        "country": "Nigeria",
        "woeid": "1404792",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KPHG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PIN",
        "lat": "-2.6658",
        "lon": "-56.7822",
        "name": "Parintins",
        "city": "Parintins",
        "state": "Amazonas",
        "country": "Brazil",
        "woeid": "12511260",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "75",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PIP",
        "lat": "57.5631",
        "lon": "-157.558",
        "name": "Pilot Point",
        "city": "Pilot Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523201",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3470",
        "elev": "75",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PIU",
        "lat": "-5.2022",
        "lon": "-80.6142",
        "name": "Capitan Concha",
        "city": "Piura",
        "state": "Piura",
        "country": "Peru",
        "woeid": "12515194",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6550",
        "elev": "174",
        "icao": "SPUR",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PIX",
        "lat": "38.5539",
        "lon": "-28.4442",
        "name": "Pico",
        "city": "Madalena",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515452",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "110",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PIZ",
        "lat": "69.7333",
        "lon": "-163.017",
        "name": "Dew Station",
        "city": "Point Lay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2474589",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3519",
        "elev": "20",
        "icao": "PPIZ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PJG",
        "lat": "26.9547",
        "lon": "64.1331",
        "name": "Panjgur",
        "city": "Panjgur",
        "state": "Balochistan",
        "country": "Pakistan",
        "woeid": "12515256",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "3100",
        "icao": "OPPG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PKA",
        "lat": "60.7049",
        "lon": "-161.766",
        "name": "Alaska",
        "city": "Napaskiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2411",
        "elev": "24",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PLN",
        "lat": "45.571",
        "lon": "-84.7857",
        "name": "Pellston",
        "city": "Pellston",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12521327",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6512",
        "elev": "720",
        "icao": "KPLN",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "PLX",
        "lat": "50.3517",
        "lon": "80.2336",
        "name": "Semipalatinsk",
        "city": "Semipalatinsk",
        "state": "Shyghys Qazaqstan",
        "country": "Kazakhstan",
        "woeid": "2264844",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PML",
        "lat": "55.9833",
        "lon": "-160.533",
        "name": "Port Moller",
        "city": "Port Moller",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2475435",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "27",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PMZ",
        "lat": "8.9531",
        "lon": "-83.4703",
        "name": "Palmar Sur",
        "city": "Palmar Sur",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12512438",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3183",
        "elev": "49",
        "icao": "KPMZ",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PNI",
        "lat": "6.9847",
        "lon": "158.215",
        "name": "Pohnpei",
        "city": "Palikir",
        "state": "Pohnpei",
        "country": "Federated States of Micronesia",
        "woeid": "12512816",
        "tz": "PacificPonape",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PNZ",
        "lat": "-9.3647",
        "lon": "-40.5658",
        "name": "Senador Nilo Coelho",
        "city": "Petrolina",
        "state": "Pernambuco",
        "country": "Brazil",
        "woeid": "12511269",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "1234",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PPG",
        "lat": "-14.3242",
        "lon": "-170.706",
        "name": "Pago Pago",
        "city": "Pago Pago",
        "state": "American Samoa",
        "country": "United States",
        "woeid": "12523054",
        "tz": "PacificPago_Pago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "30",
        "icao": "NSTU",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PPN",
        "lat": "2.45",
        "lon": "-76.6164",
        "name": "Guillermo Leon Valencia",
        "city": "Popayán",
        "state": "Cauca",
        "country": "Colombia",
        "woeid": "12512381",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6100",
        "elev": "5677",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PPP",
        "lat": "-20.4939",
        "lon": "148.554",
        "name": "Proserpine Aerodrome",
        "city": "Brandy Creek",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510768",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6801",
        "elev": "83",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PQC",
        "lat": "10.2233",
        "lon": "103.958",
        "name": "Duong Dong",
        "city": "Kien Giang",
        "state": "Kien Giang",
        "country": "Vietnam",
        "woeid": "12522907",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "23",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PSG",
        "lat": "56.8067",
        "lon": "-132.933",
        "name": "Petersburg James A Johnson",
        "city": "Petersburg",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521355",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6004",
        "elev": "107",
        "icao": "PAPG",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PSO",
        "lat": "1.4108",
        "lon": "-77.2922",
        "name": "Antonio Narino",
        "city": "Chachagüí",
        "state": "Narino",
        "country": "Colombia",
        "woeid": "12512363",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7132",
        "elev": "5952",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PTH",
        "lat": "56.9667",
        "lon": "-158.633",
        "name": "Port Heiden",
        "city": "Port Heiden",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2475388",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6240",
        "elev": "86",
        "icao": "PAPH",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PUB",
        "lat": "38.2836",
        "lon": "-104.496",
        "name": "Pueblo Memorial",
        "city": "Pueblo",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12521494",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10496",
        "elev": "4726",
        "icao": "KPUB",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "PWK",
        "lat": "42.1162",
        "lon": "-87.8987",
        "name": "Pal Waukee",
        "city": "Wheeling",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12521282",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "647",
        "icao": "KPWK",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PXU",
        "lat": "13.9747",
        "lon": "108.041",
        "name": "Pleiku Area",
        "city": "Gia Lai",
        "state": "Gia Lai",
        "country": "Vietnam",
        "woeid": "12522947",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "2438",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "PYJ",
        "lat": "69.1966",
        "lon": "33.4842",
        "name": "Polyarnyj",
        "city": "Polyarnyj",
        "state": "Murmanskaya Oblast",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "PZI",
        "lat": "21.652",
        "lon": "110.623",
        "name": "Pan Zhi Hua Bao AnYing",
        "city": "Pan Zhi Hua",
        "state": "Guangdong",
        "country": "China",
        "woeid": "2161880",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "QBC",
        "lat": "52.3843",
        "lon": "-126.585",
        "name": "Bella Coola",
        "city": "Masset",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511577",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "120",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "QJY",
        "lat": "33.5056",
        "lon": "126.495",
        "name": "Cheju",
        "city": "Jeju-Si",
        "state": "Jaeju-Do",
        "country": "South Korea",
        "woeid": "12514202",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "QRW",
        "lat": "5.75242",
        "lon": "5.86487",
        "name": "Delta",
        "city": "Warri",
        "state": "Delta",
        "country": "Nigeria",
        "woeid": "2346364",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "QUL",
        "lat": "36.5922",
        "lon": "-90.2465",
        "name": "Qulin",
        "city": "Qulin",
        "state": "Missouri",
        "country": "United States",
        "woeid": "2477964",
        "tz": "AmericaIndianaTell_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RAH",
        "lat": "29.6278",
        "lon": "43.4944",
        "name": "Rafha",
        "city": "Rafha",
        "state": "Al Hudud ash Shamaliyah",
        "country": "Saudi Arabia",
        "woeid": "12517363",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "1473",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RBQ",
        "lat": "-14.45",
        "lon": "-67.55",
        "name": "Rurrenabaque",
        "city": "Rurrenabaque",
        "state": "El Beni",
        "country": "Bolivia",
        "woeid": "12523701",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "899",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RBV",
        "lat": "58.05",
        "lon": "25.1",
        "name": "Ramata",
        "city": "Ramata",
        "state": "Valmieras Rajons",
        "country": "Solomon Islands",
        "woeid": "55844616",
        "tz": "EuropeRiga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RCE",
        "lat": "48.6126",
        "lon": "-123.14",
        "name": "Roche Harbor",
        "city": "Friday Harbor",
        "state": "Washington",
        "country": "United States",
        "woeid": "12521623",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "100",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RCM",
        "lat": "-20.7021",
        "lon": "143.115",
        "name": "Richmond Aerodrome",
        "city": "Bellfield",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510771",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "675",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RDN",
        "lat": "3.94515",
        "lon": "114.402",
        "name": "Malaysia",
        "city": "Redang",
        "state": "",
        "country": "Malaysia",
        "woeid": "23424901",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "940",
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RES",
        "lat": "-27.4514",
        "lon": "-59.0508",
        "name": "Resistencia",
        "city": "Makallé",
        "state": "Chaco",
        "country": "Argentina",
        "woeid": "12510540",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9084",
        "elev": "174",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RIG",
        "lat": "-32.0819",
        "lon": "-52.1664",
        "name": "Rio Grande",
        "city": "Rio Grande",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511293",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "16",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RIW",
        "lat": "43.0611",
        "lon": "-108.458",
        "name": "Riverton",
        "city": "Riverton",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12521603",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "5525",
        "icao": "KRIW",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "RJA",
        "lat": "17.1081",
        "lon": "81.82",
        "name": "Rajahmundry",
        "city": "Kapavaram",
        "state": "Andhra Pradesh",
        "country": "India",
        "woeid": "12513659",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "151",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "RKD",
        "lat": "44.0612",
        "lon": "-69.0943",
        "name": "Knox County",
        "city": "Owls Head",
        "state": "Maine",
        "country": "United States",
        "woeid": "12520489",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4502",
        "elev": "55",
        "icao": "KRKD",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "RKS",
        "lat": "41.5988",
        "lon": "-109.072",
        "name": "Rock Springs-Sweetwater County",
        "city": "Rock Springs",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12521630",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "6760",
        "icao": "KRKS",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "RMP",
        "lat": "65.5063",
        "lon": "-150.169",
        "name": "Rampart",
        "city": "Rampart",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2478448",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "275",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "RMT",
        "lat": "32.3103",
        "lon": "10.3856",
        "name": "Remada",
        "city": "Tatawin",
        "state": "Tatawin",
        "country": "Tunisia",
        "woeid": "12517862",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RNL",
        "lat": "-11.6667",
        "lon": "160.3",
        "name": "Rennell",
        "city": "Rennell",
        "state": "Central",
        "country": "Solomon Islands",
        "woeid": "2344838",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "94",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RSJ",
        "lat": "48.6339",
        "lon": "-122.81",
        "name": "Rosario Seaplane Base",
        "city": "Olga",
        "state": "Washington",
        "country": "United States",
        "woeid": "12524597",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2850",
        "elev": "2170",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RSU",
        "lat": "34.8369",
        "lon": "127.619",
        "name": "Yeosu",
        "city": "Yeosu-Si",
        "state": "Jeollanam-Do",
        "country": "South Korea",
        "woeid": "12514236",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "68",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "RTG",
        "lat": "-8.5878",
        "lon": "120.483",
        "name": "Satartacik",
        "city": "Ruteng",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12513503",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "3440",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RUA",
        "lat": "37.5534",
        "lon": "-76.3539",
        "name": "Arua",
        "city": "Arua",
        "state": "Arua",
        "country": "Uganda",
        "woeid": "1450394",
        "tz": "AfricaKampala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "3951",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RVE",
        "lat": "6.9581",
        "lon": "-71.855",
        "name": "Saravena El Eden",
        "city": "Saravena",
        "state": "Arauca",
        "country": "Colombia",
        "woeid": "12512416",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3677",
        "elev": "212",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RVV",
        "lat": "-23.8862",
        "lon": "-147.665",
        "name": "Raivavae",
        "city": "Rairua",
        "state": "Austral Islands",
        "country": "French Polynesia",
        "woeid": "28752897",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "RYK",
        "lat": "28.4",
        "lon": "70.2833",
        "name": "Rahim Yar Khan",
        "city": "Rahim Yar Khan",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "2211116",
        "tz": "AsiaKarachi",
        "phone": "+926 85562301",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "270",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SAB",
        "lat": "17.6394",
        "lon": "-63.2316",
        "name": "Yrausquin",
        "city": "",
        "state": "Saba",
        "country": "Netherlands Antilles",
        "woeid": "12515142",
        "tz": "AmericaCuracao",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1300",
        "elev": "60",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SBR",
        "lat": "-9.4",
        "lon": "142.667",
        "name": "Saibai Island",
        "city": "Saibai Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "1105453",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SCU",
        "lat": "19.9708",
        "lon": "-75.8364",
        "name": "Antonio Maceo",
        "city": "",
        "state": "Santiago de Cuba",
        "country": "Cuba",
        "woeid": "12512450",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8465",
        "elev": "207",
        "icao": "MUCU",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SCV",
        "lat": "47.6943",
        "lon": "26.363",
        "name": "Suceava Salcea",
        "city": "",
        "state": "Suceava",
        "country": "Romania",
        "woeid": "12515580",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "1371",
        "icao": "LRSV",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SDV",
        "lat": "32.1104",
        "lon": "34.7823",
        "name": "Sde Dov",
        "city": "Tel Aviv Yafo",
        "state": "Tel Aviv",
        "country": "Israel",
        "woeid": "23388293",
        "tz": "AsiaJerusalem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SFN",
        "lat": "-31.7108",
        "lon": "-60.8117",
        "name": "Sauce Viejo",
        "city": "Santa Fe",
        "state": "Santa Fe",
        "country": "Argentina",
        "woeid": "12510563",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7170",
        "elev": "56",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SGO",
        "lat": "-28.0833",
        "lon": "148.75",
        "name": "St George",
        "city": "St George",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12708293",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "656",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SGU",
        "lat": "37.0973",
        "lon": "-113.591",
        "name": "St George Municipal",
        "city": "St. George",
        "state": "Utah",
        "country": "United States",
        "woeid": "12521964",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6100",
        "elev": "2938",
        "icao": "KSGU",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SGY",
        "lat": "59.4594",
        "lon": "-135.314",
        "name": "Skagway",
        "city": "Skagway",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521869",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3301",
        "elev": "44",
        "icao": "PAGY",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SHB",
        "lat": "43.5767",
        "lon": "144.958",
        "name": "Nakashibetsu",
        "city": "Nakashibetsu-cho",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "12523220",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.nakashibetsu-airport.jp",
        "runway_length": "6561",
        "elev": "218",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SHC",
        "lat": "14.0833",
        "lon": "38.2833",
        "name": "Indaselassie",
        "city": "Indaselassie",
        "state": "Tigray",
        "country": "Ethiopia",
        "woeid": "1482295",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SHD",
        "lat": "38.2637",
        "lon": "-78.9013",
        "name": "Shenandoah Valley",
        "city": "Weyers Cave",
        "state": "Virginia",
        "country": "United States",
        "woeid": "12521830",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6002",
        "elev": "1201",
        "icao": "KSHD",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SHH",
        "lat": "66.2568",
        "lon": "-166.067",
        "name": "Shishmaref",
        "city": "Shishmaref",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2492966",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "8",
        "icao": "PASH",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SHP",
        "lat": "45.0167",
        "lon": "119.75",
        "name": "Qinhuangdao",
        "city": "Qinhuangdao",
        "state": "内蒙古自治区",
        "country": "China",
        "woeid": "12711986",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SHR",
        "lat": "44.7739",
        "lon": "-106.97",
        "name": "Sheridan County",
        "city": "Sheridan",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12521834",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6649",
        "elev": "4021",
        "icao": "KSHR",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SHY",
        "lat": "-3.66487",
        "lon": "33.4212",
        "name": "Shinyanga",
        "city": "Shinyanga",
        "state": "Shinyanga",
        "country": "Tanzania",
        "woeid": "1449377",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4560",
        "elev": "3800",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SJP",
        "lat": "-20.8156",
        "lon": "-49.4042",
        "name": "Sao Jose do Rio Preto",
        "city": "São José do Rio Prêto",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511317",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "1778",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SKC",
        "lat": "-6.65619",
        "lon": "145.859",
        "name": "Papua New Guinea",
        "city": "Suki",
        "state": "",
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2130",
        "elev": "63",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SKT",
        "lat": "32.5194",
        "lon": "74.5555",
        "name": "Sialkot",
        "city": "Sialkot",
        "state": "Punjab",
        "country": "Pakistan",
        "woeid": "2211467",
        "tz": "AsiaKarachi",
        "phone": "0092 52 3555333",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3",
        "elev": "45",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SKU",
        "lat": "38.9683",
        "lon": "24.4881",
        "name": "Skiros",
        "city": "Skiros",
        "state": "Sterea Ellada",
        "country": "Greece",
        "woeid": "12513323",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "48",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SKZ",
        "lat": "27.7236",
        "lon": "68.7903",
        "name": "Sukkur",
        "city": "Sukkur",
        "state": "Sindh",
        "country": "Pakistan",
        "woeid": "12515270",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "191",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SLI",
        "lat": "-12.1722",
        "lon": "26.3667",
        "name": "Solwezi",
        "city": "Solwezi",
        "state": "North-Western",
        "country": "Zambia",
        "woeid": "1575642",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "4399",
        "elev": "4547",
        "icao": "KSLI",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SLQ",
        "lat": "61.7",
        "lon": "-157.117",
        "name": "Sleetmute",
        "city": "Sleetmute",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2494667",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "225",
        "icao": "PASL",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SLX",
        "lat": "21.3223",
        "lon": "-71.1983",
        "name": "Salt Cay",
        "city": "Salt Cay",
        "state": "Salt Cay",
        "country": "Turks And Caicos Islands",
        "woeid": "12517844",
        "tz": "AmericaGrand_Turk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "5",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SMA",
        "lat": "36.9658",
        "lon": "-25.1714",
        "name": "Santa Maria",
        "city": "Vila do Porto",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515457",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "305",
        "icao": "LPAZ",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SMN",
        "lat": "45.1148",
        "lon": "-113.888",
        "name": "Lemhi County",
        "city": "Salmon",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12520625",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5150",
        "elev": "4045",
        "icao": "KSMN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SMR",
        "lat": "11.1221",
        "lon": "-74.2262",
        "name": "Simon Bolivar",
        "city": "",
        "state": "Magdalena",
        "country": "Colombia",
        "woeid": "12512418",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5576",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SMS",
        "lat": "-17.0939",
        "lon": "49.8158",
        "name": "Sainte Marie",
        "city": "Toamasina",
        "state": "Toamasina",
        "country": "Madagascar",
        "woeid": "12514707",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3363",
        "elev": "7",
        "icao": "KSMS",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SMX",
        "lat": "34.8888",
        "lon": "-120.437",
        "name": "Santa Maria Public",
        "city": "Santa Maria",
        "state": "California",
        "country": "United States",
        "woeid": "12521743",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6300",
        "elev": "259",
        "icao": "KSMX",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SNP",
        "lat": "57.1667",
        "lon": "-170.217",
        "name": "Saint Paul Island",
        "city": "Saint Paul Island",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799712",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5175",
        "elev": "44",
        "icao": "PASN",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SOW",
        "lat": "34.2595",
        "lon": "-110.005",
        "name": "Show Low Municipal",
        "city": "Show Low",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12521843",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "6010",
        "elev": "6411",
        "icao": "KSOW",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SPS",
        "lat": "33.9862",
        "lon": "-98.4984",
        "name": "Sheppard Air Force Base",
        "city": "Wichita Falls",
        "state": "Texas",
        "country": "United States",
        "woeid": "12521831",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "13100",
        "elev": "1015",
        "icao": "KSPS",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SRP",
        "lat": "59.7928",
        "lon": "5.33972",
        "name": "Sorstukken",
        "city": "Skjold",
        "state": "Rogaland Fylke",
        "country": "Norway",
        "woeid": "12523950",
        "tz": "EuropeOslo",
        "phone": "+ 47 53 40 37 10",
        "type": "Airports",
        "email": "admin@stordlufthavn.no",
        "url": "http:www.stordlufthavn.no",
        "runway_length": "3936",
        "elev": "160",
        "icao": "ENSA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SRX",
        "lat": "31.206",
        "lon": "16.5924",
        "name": "Surt",
        "city": "Sert",
        "state": "Surt",
        "country": "Libya",
        "woeid": "1353260",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9250",
        "elev": "40",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SRY",
        "lat": "36.635",
        "lon": "53.1933",
        "name": "Sary",
        "city": "Sary",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7550",
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SRZ",
        "lat": "-17.8072",
        "lon": "-63.1708",
        "name": "El Trompillo",
        "city": "Santa Cruz de la Sierra",
        "state": "Santa Cruz",
        "country": "Bolivia",
        "woeid": "12510905",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11483",
        "elev": "1224",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "STG",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "St George Island",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3050",
        "elev": "90",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "STW",
        "lat": "45.1104",
        "lon": "42.1089",
        "name": "Mikhaylovskoye",
        "city": "Stavropol'",
        "state": "Stavropolrskiy Kray",
        "country": "Russia",
        "woeid": "12516431",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SVA",
        "lat": "63.6951",
        "lon": "-170.465",
        "name": "Alaska",
        "city": "Savoonga",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "53",
        "icao": "PASA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "SVI",
        "lat": "2.1464",
        "lon": "-74.7622",
        "name": "San Vicente del Caguan",
        "city": "San Vicente del Caguán",
        "state": "Caqueta",
        "country": "Colombia",
        "woeid": "12512411",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "920",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SVL",
        "lat": "61.9464",
        "lon": "28.9355",
        "name": "Savonlinna",
        "city": "Savonlinna",
        "state": "Eastern Finland",
        "country": "Finland",
        "woeid": "12512788",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "312",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SWJ",
        "lat": "-16.4917",
        "lon": "167.439",
        "name": "South West Bay",
        "city": "South West Bay",
        "state": "Malampa",
        "country": "Vanuatu",
        "woeid": "12523327",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2887",
        "elev": "10",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SXL",
        "lat": "54.275",
        "lon": "-8.6",
        "name": "Sligo",
        "city": "Larass",
        "state": "",
        "country": "Ireland",
        "woeid": "12512727",
        "tz": "EuropeDublin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "20",
        "icao": "EISG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SYJ",
        "lat": "29.5504",
        "lon": "55.6708",
        "name": "Sirjan",
        "city": "Sirjan",
        "state": "Kerman",
        "country": "Iran",
        "woeid": "2255214",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "SYO",
        "lat": "38.7061",
        "lon": "140.018",
        "name": "Shonai",
        "city": "Shonai",
        "state": "Yamagata Prefecture",
        "country": "Japan",
        "woeid": "28379163",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "SYU",
        "lat": "-31.875",
        "lon": "136.081",
        "name": "Australia",
        "city": "Sue Island",
        "state": "",
        "country": "Australia",
        "woeid": "23424748",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TAP",
        "lat": "14.7931",
        "lon": "-92.37",
        "name": "Tapachula",
        "city": "Tapachula",
        "state": "Chiapas",
        "country": "Mexico",
        "woeid": "12514971",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMTP",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "TAT",
        "lat": "49.0663",
        "lon": "20.2445",
        "name": "Poprad Tatry",
        "city": "Poprad",
        "state": "Presovsky",
        "country": "Slovakia",
        "woeid": "12514622",
        "tz": "EuropeBratislava",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LZTT",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TBN",
        "lat": "37.7327",
        "lon": "-92.1361",
        "name": "Waynesville At Forney Field",
        "city": "Fort Leonard Wood",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12519767",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6038",
        "elev": "1159",
        "icao": "KTBN",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TBP",
        "lat": "-3.5494",
        "lon": "-80.3781",
        "name": "Pedro Canga",
        "city": "Tumbes",
        "state": "Tumbes",
        "country": "Peru",
        "woeid": "12515214",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TCQ",
        "lat": "-18.0508",
        "lon": "-70.2739",
        "name": "Cor Fap Carlos C Santa Rosa",
        "city": "Tacna",
        "state": "Tacna",
        "country": "Peru",
        "woeid": "12515202",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "TEB",
        "lat": "40.8541",
        "lon": "-74.0662",
        "name": "Teterboro",
        "city": "Teterboro",
        "state": "New Jersey",
        "country": "United States",
        "woeid": "12522105",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTEB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TEE",
        "lat": "35.4308",
        "lon": "8.1244",
        "name": "Tebessa",
        "city": "Tebessa",
        "state": "Tebessa",
        "country": "Algeria",
        "woeid": "12510351",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TEX",
        "lat": "37.9544",
        "lon": "-107.901",
        "name": "Telluride",
        "city": "Telluride",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12522097",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTEX",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "TFI",
        "lat": "-9.08333",
        "lon": "149.317",
        "name": "Tufi",
        "city": "Tufi",
        "state": "Northern",
        "country": "Papua New Guinea",
        "woeid": "1061985",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TGH",
        "lat": "-16.9027",
        "lon": "168.56",
        "name": "Tongoa",
        "city": "",
        "state": "Shefa",
        "country": "Vanuatu",
        "woeid": "12515055",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TGM",
        "lat": "46.4676",
        "lon": "24.4203",
        "name": "Vidrasau",
        "city": "",
        "state": "Mures",
        "country": "Romania",
        "woeid": "12515593",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LRTM",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "TGO",
        "lat": "43.6125",
        "lon": "122.265",
        "name": "Tongliao",
        "city": "Tongliao",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "2149767",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TGR",
        "lat": "33.0675",
        "lon": "6.0883",
        "name": "Touggourt",
        "city": "Ouargla",
        "state": "Ouargla",
        "country": "Algeria",
        "woeid": "12510356",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "THE",
        "lat": "-5.0603",
        "lon": "-42.8239",
        "name": "Senador Petronio Portella",
        "city": "Teresina",
        "state": "Maranhao",
        "country": "Brazil",
        "woeid": "12511344",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "TIH",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Tikehau Atoll",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TIN",
        "lat": "39.5354",
        "lon": "-93.4399",
        "name": "Tindouf",
        "city": "Tindouf",
        "state": "Tindouf",
        "country": "Algeria",
        "woeid": "1257822",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TJA",
        "lat": "-21.5503",
        "lon": "-64.7044",
        "name": "Capitan Oriel Lea Plaza",
        "city": "Tarija",
        "state": "Tarija",
        "country": "Bolivia",
        "woeid": "12510904",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "SLTJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TKJ",
        "lat": "48.1205",
        "lon": "21.4125",
        "name": "Tok",
        "city": "Tok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2506862",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TKK",
        "lat": "7.4569",
        "lon": "151.84",
        "name": "Truk",
        "city": "Weno",
        "state": "Chuuk",
        "country": "Federated States of Micronesia",
        "woeid": "12512817",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TKP",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Takapoto",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TKS",
        "lat": "34.1339",
        "lon": "134.603",
        "name": "Tokushima",
        "city": "Matsushige-cho",
        "state": "Tokushima Prefecture",
        "country": "Japan",
        "woeid": "12514027",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "26",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TKX",
        "lat": "-14.4667",
        "lon": "-1.96667",
        "name": "Takaroa",
        "city": "Takaroa",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "28743703",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTKX",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TLA",
        "lat": "65.2413",
        "lon": "-166.331",
        "name": "Teller",
        "city": "Teller",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2504609",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATE",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TMJ",
        "lat": "37.2808",
        "lon": "67.3064",
        "name": "Termez",
        "city": "Termiz",
        "state": "Surkhondaryo",
        "country": "Uzbekistan",
        "woeid": "12522727",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TMW",
        "lat": "-31.0839",
        "lon": "150.849",
        "name": "Tamworth",
        "city": "Barry",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510789",
        "tz": "AustraliaSydney",
        "phone": "(02) 6760 7611",
        "type": "Airports",
        "email": "",
        "url": "http:www.tamworth.nsw.gov.auaspindex.asp?pgid=9279&cid=24735",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TNN",
        "lat": "22.9634",
        "lon": "120.216",
        "name": "Tainan",
        "city": "Tainan City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517957",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "RCNN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TOD",
        "lat": "2.8",
        "lon": "104.167",
        "name": "Tioman",
        "city": "Kampung Genting",
        "state": "Pahang",
        "country": "Malaysia",
        "woeid": "12523243",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3255",
        "elev": "15",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TOU",
        "lat": "41.1258",
        "lon": "-96.832",
        "name": "Touho",
        "city": "Touho",
        "state": "Nord",
        "country": "New Caledonia",
        "woeid": "24551429",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TPP",
        "lat": "-6.5058",
        "lon": "-76.3714",
        "name": "Tarapoto",
        "city": "Tarapoto",
        "state": "San Martin",
        "country": "Peru",
        "woeid": "12515220",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TPQ",
        "lat": "21.4758",
        "lon": "-104.881",
        "name": "Tepic",
        "city": "Tepic",
        "state": "Nayarit",
        "country": "Mexico",
        "woeid": "12514975",
        "tz": "AmericaMazatlan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MMEP",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TRO",
        "lat": "-31.8887",
        "lon": "152.518",
        "name": "Taree",
        "city": "Taree",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510790",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TRU",
        "lat": "-8.09",
        "lon": "-79.115",
        "name": "Cap C Martinez de Pinillos",
        "city": "Huanchaco",
        "state": "La Libertad",
        "country": "Peru",
        "woeid": "12515191",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "SPRU",
        "direct_flights": "2",
        "carriers": "5"
    },
    {
        "code": "TSS",
        "lat": "40.7474",
        "lon": "-73.9912",
        "name": "East 34th Street Heliport",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "woeid": "12523248",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TTA",
        "lat": "28.4294",
        "lon": "-11.098",
        "name": "Tan-Tan",
        "city": "Tan Tan",
        "state": "Tan-Tan",
        "country": "Morocco",
        "woeid": "1540902",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTTA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TTE",
        "lat": "0.8308",
        "lon": "127.377",
        "name": "Babullah",
        "city": "Ternate",
        "state": "Maluku Utara",
        "country": "Indonesia",
        "woeid": "12513452",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TUA",
        "lat": "0.8111",
        "lon": "-77.7069",
        "name": "El Rosal Teniente Guerrero",
        "city": "Urbina",
        "state": "Carchi",
        "country": "Ecuador",
        "woeid": "12512628",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TUF",
        "lat": "47.428",
        "lon": "0.72414",
        "name": "St Symphorien",
        "city": "Tours",
        "state": "Centre",
        "country": "France",
        "woeid": "12512976",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "LFOT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TUP",
        "lat": "34.2662",
        "lon": "-88.766",
        "name": "Tupelo Municipal-C D Lemons",
        "city": "Tupelo",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12522222",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTUP",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "TUR",
        "lat": "-3.7767",
        "lon": "-49.7192",
        "name": "Tucurui",
        "city": "Tucuruí",
        "state": "Para",
        "country": "Brazil",
        "woeid": "12511353",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TVY",
        "lat": "14.1017",
        "lon": "98.2067",
        "name": "Dawe",
        "city": "Dawe",
        "state": "",
        "country": "Myanmar",
        "woeid": "23424763",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "84",
        "icao": "KTVY",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TWA",
        "lat": "36.8259",
        "lon": "-83.3212",
        "name": "Twin Hills",
        "city": "Twin Hills",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2509121",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TXK",
        "lat": "33.457",
        "lon": "-93.9943",
        "name": "Texarkana",
        "city": "Texarkana",
        "state": "Arkansas",
        "country": "United States",
        "woeid": "12522107",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTXK",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "TYR",
        "lat": "32.3506",
        "lon": "-95.4118",
        "name": "Tyler Pounds Field",
        "city": "Tyler",
        "state": "Texas",
        "country": "United States",
        "woeid": "12522238",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7200",
        "elev": "544",
        "icao": "KTYR",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "UAH",
        "lat": "-8.91445",
        "lon": "-139.548",
        "name": "French Polynesia",
        "city": "Ua Huka",
        "state": "Marquesas Islands",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2477",
        "elev": "151",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UAQ",
        "lat": "-31.6024",
        "lon": "-68.5472",
        "name": "San Juan",
        "city": "9 de Julio",
        "state": "San Juan",
        "country": "Argentina",
        "woeid": "12510552",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8070",
        "elev": "1959",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "UAS",
        "lat": "0.466667",
        "lon": "37.5667",
        "name": "Samburu",
        "city": "Samburu",
        "state": "Eastern",
        "country": "Kenya",
        "woeid": "12732896",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "3300",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UBP",
        "lat": "15.2489",
        "lon": "104.873",
        "name": "Ubon",
        "city": "Don Mot Daeng",
        "state": "Ubon Ratchathani",
        "country": "Thailand",
        "woeid": "12517791",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9848",
        "elev": "406",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "UIH",
        "lat": "13.7673",
        "lon": "109.256",
        "name": "Vietnam",
        "city": "Qui Nhon",
        "state": "Binh Dinh",
        "country": "Vietnam",
        "woeid": "23424984",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5081",
        "elev": "25",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UIN",
        "lat": "39.9348",
        "lon": "-91.196",
        "name": "Quincy Municipal-Baldwin Field",
        "city": "Quincy",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12521511",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7097",
        "elev": "769",
        "icao": "KUIN",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "UIP",
        "lat": "47.9751",
        "lon": "-4.17416",
        "name": "Pluguffan",
        "city": "Plomelin",
        "state": "Brittany",
        "country": "France",
        "woeid": "12512952",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6234",
        "elev": "302",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ULB",
        "lat": "-16.3333",
        "lon": "168.294",
        "name": "Ulei",
        "city": "Port-Vato",
        "state": "Malampa",
        "country": "Vanuatu",
        "woeid": "12523334",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2789",
        "elev": "126",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ULP",
        "lat": "-26.6087",
        "lon": "144.255",
        "name": "Quilpie Aerodrome",
        "city": "Quilpie",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510769",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4900",
        "elev": "655",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UPB",
        "lat": "23.0805",
        "lon": "-82.517",
        "name": "Playa Baracoa",
        "city": "Havana",
        "state": "Ciudad de la Habana",
        "country": "Cuba",
        "woeid": "66179",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "URE",
        "lat": "58.2432",
        "lon": "22.5041",
        "name": "Kuressarre",
        "city": "Kingissepa",
        "state": "Saaremaa",
        "country": "Estonia",
        "woeid": "12512737",
        "tz": "EuropeTallinn",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.eeke.ee",
        "runway_length": null,
        "elev": null,
        "icao": "EEKE",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "URJ",
        "lat": "60.1167",
        "lon": "64.8333",
        "name": "Uraj",
        "city": "Uraj",
        "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
        "country": "Russian Federation",
        "woeid": "2124077",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "URT",
        "lat": "9.1367",
        "lon": "99.1417",
        "name": "Surat Thani",
        "city": "Phun Phin",
        "state": "Surat Thani",
        "country": "Thailand",
        "woeid": "12517786",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "19",
        "icao": "VTSB",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "USN",
        "lat": "35.5906",
        "lon": "129.357",
        "name": "Ulsan",
        "city": "Ulsan",
        "state": "Ulsan",
        "country": "South Korea",
        "woeid": "12514234",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "30",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "UTH",
        "lat": "17.3831",
        "lon": "102.794",
        "name": "Udon",
        "city": "Changwat Udon Thani; Udon Thani",
        "state": "Udon Thani",
        "country": "Thailand",
        "woeid": "12517792",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "579",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "UTN",
        "lat": "-28.3986",
        "lon": "21.2528",
        "name": "Pierre Van Ryneveld",
        "city": "Upington",
        "state": "Northern Cape",
        "country": "South Africa",
        "woeid": "12517458",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "16076",
        "elev": "2791",
        "icao": "FAUP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UTP",
        "lat": "12.6778",
        "lon": "101.009",
        "name": "Rayong",
        "city": "Ban Chang",
        "state": "Rayong",
        "country": "Thailand",
        "woeid": "12517790",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11500",
        "elev": "59",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "UUD",
        "lat": "51.8063",
        "lon": "107.443",
        "name": "Ulan Ude-Mukhino",
        "city": "Kabansk",
        "state": "Buryatiya",
        "country": "Russia",
        "woeid": "12517073",
        "tz": "AsiaIrkutsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8943",
        "elev": "1690",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "UWE",
        "lat": "51.4997",
        "lon": "-2.54595",
        "name": "Wiesbaden",
        "city": "Wiesbaden",
        "state": "Hesse",
        "country": "Germany",
        "woeid": "706329",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VAA",
        "lat": "63.0396",
        "lon": "21.7691",
        "name": "Vaasa",
        "city": "Vaasa",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12512804",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "15",
        "icao": "EFVA",
        "direct_flights": "2",
        "carriers": "7"
    },
    {
        "code": "VAK",
        "lat": "61.5338",
        "lon": "-165.584",
        "name": "Chevak",
        "city": "Hooper Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388570",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2689",
        "elev": "75",
        "icao": "PAVA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "VDB",
        "lat": "61.0105",
        "lon": "9.29374",
        "name": "Fagernes Leirin",
        "city": "Fagernes",
        "state": "Oppland Fylke",
        "country": "Norway",
        "woeid": "12515108",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VDE",
        "lat": "27.8145",
        "lon": "-17.885",
        "name": "Valverde",
        "city": "Valverde",
        "state": "Canary Islands",
        "country": "Spain",
        "woeid": "12523969",
        "tz": "AtlanticCanary",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3609",
        "elev": "105",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VGA",
        "lat": "16.5167",
        "lon": "80.8",
        "name": "Vijaywada",
        "city": "Manthena",
        "state": "Andhra Pradesh",
        "country": "India",
        "woeid": "12513694",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "82",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VHC",
        "lat": "-9.6889",
        "lon": "20.43",
        "name": "Saurimo",
        "city": "Saurimo",
        "state": "Lunda Sul",
        "country": "Angola",
        "woeid": "12510452",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11154",
        "elev": "3576",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VIS",
        "lat": "36.3253",
        "lon": "-119.394",
        "name": "Visalia Municipal",
        "city": "Visalia",
        "state": "California",
        "country": "United States",
        "woeid": "12522291",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6556",
        "elev": "292",
        "icao": "KVIS",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VKG",
        "lat": "9.945",
        "lon": "105.135",
        "name": "Kien Giang",
        "city": "Kien Giang",
        "state": "Kien Giang",
        "country": "Vietnam",
        "woeid": "12522921",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3767",
        "elev": "13",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VLS",
        "lat": "-15.1468",
        "lon": "167.038",
        "name": "Vanuatu",
        "city": "Valesdir",
        "state": "Shefa",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1968",
        "elev": "36",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VRK",
        "lat": "62.1742",
        "lon": "27.8658",
        "name": "Varkaus",
        "city": "Varkaus",
        "state": "Eastern Finland",
        "country": "Finland",
        "woeid": "12512805",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.ilmailulaitos.fiairport_varkaus",
        "runway_length": "6562",
        "elev": "286",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "VSG",
        "lat": "48.5667",
        "lon": "39.3333",
        "name": "Lugansk",
        "city": "Luhans'k",
        "state": "Luhans´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12524015",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UKCW",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "VUP",
        "lat": "10.4433",
        "lon": "-73.245",
        "name": "Alfonso Lopez",
        "city": "Valledupar",
        "state": "Cesar",
        "country": "Colombia",
        "woeid": "12512361",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "453",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "VVZ",
        "lat": "26.5001",
        "lon": "8.47094",
        "name": "Illizi",
        "city": "Illizi",
        "state": "Illizi",
        "country": "Algeria",
        "woeid": "1255066",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5889",
        "elev": "1778",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WAG",
        "lat": "-39.9635",
        "lon": "175.029",
        "name": "Wanganui",
        "city": "Wanganui",
        "state": "Manawatu Wanganui",
        "country": "New Zealand",
        "woeid": "12515174",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "28",
        "icao": "NZWU",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WBQ",
        "lat": "66.359",
        "lon": "-147.396",
        "name": "Alaska",
        "city": "Beaver",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3800",
        "elev": "365",
        "icao": "PAWB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "WEI",
        "lat": "-12.6773",
        "lon": "141.923",
        "name": "Weipa Aerodrome",
        "city": "Weipa",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510809",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "59",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WGA",
        "lat": "-35.159",
        "lon": "147.467",
        "name": "Wagga Wagga",
        "city": "Forest Hill",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510802",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5800",
        "elev": "726",
        "icao": "YSWG",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "WGP",
        "lat": "-9.6722",
        "lon": "120.308",
        "name": "Mau Hau",
        "city": "Waingapu",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12513482",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "39",
        "icao": "WRRW",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "WHK",
        "lat": "-37.9213",
        "lon": "176.919",
        "name": "Whakatane",
        "city": "Whakatane",
        "state": "Bay Of Plenty",
        "country": "New Zealand",
        "woeid": "12515177",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "20",
        "icao": "NZWK",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WIC",
        "lat": "58.4538",
        "lon": "-3.08767",
        "name": "Wick",
        "city": "Wick",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22494239",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5279",
        "elev": "127",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "WIN",
        "lat": "-22.3635",
        "lon": "143.082",
        "name": "Winton Aerodrome",
        "city": "Corfield",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510815",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "638",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WJA",
        "lat": "7.46667",
        "lon": "168.567",
        "name": "Woja",
        "city": "Woja",
        "state": "Ailinglaplap",
        "country": "Marshall Islands",
        "woeid": "22503997",
        "tz": "PacificKwajalein",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WLK",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Selawik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "25",
        "icao": "PASK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "WMK",
        "lat": "55.7389",
        "lon": "-132.258",
        "name": "Meyers Chuck",
        "city": "Meyers Chuck",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2449996",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WMO",
        "lat": "64.6811",
        "lon": "-163.408",
        "name": "White Mountain",
        "city": "White Mountain",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2519364",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "262",
        "icao": "",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "WNA",
        "lat": "60.7049",
        "lon": "-161.766",
        "name": "Napaskiak",
        "city": "Napakiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2456992",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2142",
        "elev": "20",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "WNR",
        "lat": "-25.4104",
        "lon": "142.67",
        "name": "Windorah",
        "city": "Tanbar",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510814",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4508",
        "elev": "452",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WNS",
        "lat": "26.2194",
        "lon": "68.3922",
        "name": "Nawabshah",
        "city": "Nawabshah",
        "state": "Sindh",
        "country": "Pakistan",
        "woeid": "12515254",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "9022",
        "elev": "93",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WPM",
        "lat": "-8.79133",
        "lon": "142.869",
        "name": "Wipim",
        "city": "Wipim",
        "state": "Western",
        "country": "Papua New Guinea",
        "woeid": "1061923",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1640",
        "elev": "100",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WRE",
        "lat": "-35.7705",
        "lon": "174.36",
        "name": "Whangarei",
        "city": "Whangarei",
        "state": "Northland",
        "country": "New Zealand",
        "woeid": "12515178",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "133",
        "icao": "NZWR",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WRG",
        "lat": "56.4876",
        "lon": "-132.378",
        "name": "Wrangell",
        "city": "Wrangell",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522517",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "6003",
        "elev": "44",
        "icao": "PAWG",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "WSX",
        "lat": "48.8907",
        "lon": "-116.982",
        "name": "United States",
        "city": "Westsound",
        "state": "Washington",
        "country": "United States",
        "woeid": "23424977",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "WTK",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Noatak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "99",
        "icao": "PAWN",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "WTL",
        "lat": "60.3432",
        "lon": "-162.664",
        "name": "Tuntutuliak",
        "city": "Tuntutuliak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2508647",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "4"
    },
    {
        "code": "XBJ",
        "lat": "32.8983",
        "lon": "59.2631",
        "name": "Birjand",
        "city": "Birjand",
        "state": "Khorasan",
        "country": "Iran",
        "woeid": "12513708",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6890",
        "elev": "4823",
        "icao": "OIMB",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "XCH",
        "lat": "-10.4503",
        "lon": "105.689",
        "name": "Christmas Island",
        "city": "The Settlement",
        "state": "",
        "country": "Christmas Island",
        "woeid": "12514237",
        "tz": "IndianChristmas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XGR",
        "lat": "58.5344",
        "lon": "-65.8892",
        "name": "Kangiqsualujjuaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524059",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XIC",
        "lat": "27.9935",
        "lon": "102.179",
        "name": "Xichang North",
        "city": "Xichang",
        "state": "Sichuan",
        "country": "China",
        "woeid": "12512245",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZUXC",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "XIT",
        "lat": "51.5039",
        "lon": "0.04981",
        "name": "London City",
        "city": "Londra City",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22475376",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2",
        "bookingId": "-2601889"
    },
    {
        "code": "XKH",
        "lat": "19.5",
        "lon": "103",
        "name": "Xieng Khouang",
        "city": "Xieng Khouang",
        "state": "Xiangkhoang",
        "country": "Lao People's Democratic Republic",
        "woeid": "12848854",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "3500",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XLB",
        "lat": "58.65",
        "lon": "-97.3667",
        "name": "Lac Brochet",
        "city": "Lac Brochet",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524062",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3480",
        "elev": "1200",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XSP",
        "lat": "1.4167",
        "lon": "103.869",
        "name": "Seletar",
        "city": "Singapore",
        "state": "Central Singapore",
        "country": "Singapore",
        "woeid": "12517523",
        "tz": "AsiaSingapore",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5354",
        "elev": "35",
        "icao": "WSSL",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XTL",
        "lat": "58.5999",
        "lon": "-98.349",
        "name": "Tadoule Lake",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524070",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYBQ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XWQ",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Sweden",
        "city": "Enkoping",
        "state": null,
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XWZ",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Sweden",
        "city": "Nykoping",
        "state": null,
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "XZU",
        "lat": "37.2997",
        "lon": "-92.3047",
        "name": "Off line Point",
        "city": "Rail (Generic)",
        "state": "Missouri",
        "country": "United States",
        "woeid": "2478178",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Off-line Point",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YAA",
        "lat": "52.4539",
        "lon": "-125.304",
        "name": "Anahim Lake",
        "city": "Alexis Creek",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511568",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3346",
        "elev": "3700",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YAC",
        "lat": "51.6667",
        "lon": "-91.8333",
        "name": "Cat Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524072",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "1343",
        "icao": "CYAC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YAG",
        "lat": "48.6498",
        "lon": "-93.4279",
        "name": "Fort Frances Municipal",
        "city": "Fort Frances",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511663",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "1122",
        "icao": "CYAG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YAK",
        "lat": "59.5103",
        "lon": "-139.668",
        "name": "Yakutat",
        "city": "Yakutat",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522527",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7813",
        "elev": "33",
        "icao": "PAYA",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "YAP",
        "lat": "9.48333",
        "lon": "138.083",
        "name": "Yap",
        "city": "Colonia",
        "state": "Yap",
        "country": "Federated States of Micronesia",
        "woeid": "12523267",
        "tz": "PacificPonape",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4820",
        "elev": "51",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YAT",
        "lat": "52.9338",
        "lon": "-82.392",
        "name": "Attawapiskat",
        "city": "Winisk",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524078",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "30",
        "icao": "CYAT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YBG",
        "lat": "48.3368",
        "lon": "-70.9998",
        "name": "Bagotville",
        "city": "Saguenay",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511571",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "521",
        "icao": "CYBG",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YBI",
        "lat": "53.4552",
        "lon": "-55.7653",
        "name": "Black Tickle",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524082",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "67",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YBK",
        "lat": "64.3003",
        "lon": "-96.0846",
        "name": "Baker Lake",
        "city": "Chesterfield Inlet",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524243",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "59",
        "icao": "CYBK",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "YBT",
        "lat": "57.8833",
        "lon": "-101.683",
        "name": "Brochet",
        "city": "Brochet",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "169",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYBT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YCG",
        "lat": "49.3002",
        "lon": "-117.637",
        "name": "Castlegar",
        "city": "Castlegar",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511602",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5300",
        "elev": "1624",
        "icao": "CYCG",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YCK",
        "lat": "67.0454",
        "lon": "-125.853",
        "name": "Colville Lake",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524090",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "900",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YCY",
        "lat": "70.4833",
        "lon": "-68.5167",
        "name": "Clyde River",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524096",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "100",
        "icao": "CYCY",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YER",
        "lat": "56",
        "lon": "-87.6333",
        "name": "Fort Severn",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524113",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "40",
        "icao": "CYER",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YFA",
        "lat": "52.2417",
        "lon": "-81.5917",
        "name": "Fort Albany",
        "city": "Winisk",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524115",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "10",
        "icao": "CYFA",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YFS",
        "lat": "61.7556",
        "lon": "-121.238",
        "name": "Fort Simpson",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511667",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "554",
        "icao": "CYFS",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YFX",
        "lat": "52.3598",
        "lon": "-55.6684",
        "name": "Fox Harbour Aerodrome",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524121",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Harbours",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "77",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YGP",
        "lat": "48.7782",
        "lon": "-64.4738",
        "name": "Gaspe",
        "city": "Gaspé",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511674",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYGP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YGV",
        "lat": "50.2811",
        "lon": "-63.6111",
        "name": "Harve-St-Pierre",
        "city": "Havre St Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511691",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "108",
        "icao": "CYGV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YHA",
        "lat": "52.5333",
        "lon": "-56.3",
        "name": "Port Hope Simpson Aerodrome",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524134",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "353",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YHG",
        "lat": "52.7667",
        "lon": "-56.1",
        "name": "Charlottetown",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12523297",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "165",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YHI",
        "lat": "70.7766",
        "lon": "-117.831",
        "name": "Holman",
        "city": "Holman",
        "state": "",
        "country": "Canada",
        "woeid": "12524137",
        "tz": "AmericaResolute",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "117",
        "icao": "CYHI",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YHP",
        "lat": "52.0801",
        "lon": "-94.3094",
        "name": "Poplar Hill",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524140",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YHS",
        "lat": "49.4735",
        "lon": "-123.747",
        "name": "Sechelt Water Aerodrome",
        "city": "Gibsons",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524142",
        "tz": "AmericaVancouver",
        "phone": "604-885-9017",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "250",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YHY",
        "lat": "60.8392",
        "lon": "-115.781",
        "name": "Hay River",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511692",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "543",
        "icao": "CYHY",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YIK",
        "lat": "62.4137",
        "lon": "-77.9323",
        "name": "Ivujivik",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524146",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "139",
        "icao": "CYIK",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YIN",
        "lat": "43.9375",
        "lon": "81.3029",
        "name": "Yining",
        "city": "Yining",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaUrumqi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YIV",
        "lat": "53.859",
        "lon": "-94.6296",
        "name": "Island Lake-Garden Hill",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524148",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "776",
        "icao": "CYIV",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YJT",
        "lat": "48.5456",
        "lon": "-58.5626",
        "name": "Stephenville",
        "city": "Stephenville",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511856",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "84",
        "icao": "CYJT",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YKM",
        "lat": "46.5703",
        "lon": "-120.54",
        "name": "Yakima Air Terminal",
        "city": "Yakima",
        "state": "Washington",
        "country": "United States",
        "woeid": "12522526",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7603",
        "elev": "1095",
        "icao": "KYKM",
        "direct_flights": "2",
        "carriers": "7"
    },
    {
        "code": "YLH",
        "lat": "52.2069",
        "lon": "-87.9102",
        "name": "Lansdowne House",
        "city": "Marathon",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524165",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "822",
        "icao": "CYLH",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YMH",
        "lat": "52.3",
        "lon": "-55.8333",
        "name": "Mary's Harbour",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524173",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYMH",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YNB",
        "lat": "24.145",
        "lon": "38.0636",
        "name": "Yenbo",
        "city": "Yanbu al Bahr",
        "state": "Al Madinah",
        "country": "Saudi Arabia",
        "woeid": "12517379",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10531",
        "elev": "26",
        "icao": "OEYN",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YNC",
        "lat": "52.9972",
        "lon": "-78.8333",
        "name": "Wemindji",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524178",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2300",
        "elev": "30",
        "icao": "CYNC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YNE",
        "lat": "53.9833",
        "lon": "-97.8",
        "name": "Norway House",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524179",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "730",
        "icao": "CYNE",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YNO",
        "lat": "52.4953",
        "lon": "-92.8807",
        "name": "North Spirit Lake",
        "city": "Casummit Lake",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524184",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YNP",
        "lat": "62.3587",
        "lon": "-96.5821",
        "name": "Natuashish",
        "city": "Natuashish",
        "state": "",
        "country": "Canada",
        "woeid": "23424775",
        "tz": "AmericaResolute",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "26",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YNS",
        "lat": "51.6896",
        "lon": "-76.1421",
        "name": "Nemiscau",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524186",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YOH",
        "lat": "54.9357",
        "lon": "-95.2757",
        "name": "Oxford House",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524190",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "672",
        "icao": "CYOH",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YOJ",
        "lat": "58.6137",
        "lon": "-117.163",
        "name": "High Level",
        "city": "Brownvale",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511694",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1110",
        "icao": "CYOJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YOP",
        "lat": "58.4906",
        "lon": "-119.402",
        "name": "Rainbow Lake",
        "city": "Brownvale",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511807",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "1759",
        "icao": "CYOP",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YPC",
        "lat": "69.3499",
        "lon": "-124.085",
        "name": "Paulatuk",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524250",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3200",
        "elev": "50",
        "icao": "CYPC",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YPR",
        "lat": "54.292",
        "lon": "-130.446",
        "name": "Prince Rupert",
        "city": "Prince Rupert",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511802",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "111",
        "icao": "CYPR",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YQC",
        "lat": "61.0495",
        "lon": "-69.6207",
        "name": "Quaqtaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524204",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YQD",
        "lat": "53.8234",
        "lon": "-101.205",
        "name": "The Pas",
        "city": "The Pas",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511871",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYQD",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YQF",
        "lat": "52.1786",
        "lon": "-113.883",
        "name": "Red Deer",
        "city": "Sylvan Lake",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511809",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5528",
        "elev": "2968",
        "icao": "CYQF",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YQU",
        "lat": "55.1787",
        "lon": "-118.872",
        "name": "Grande Prairie",
        "city": "Brownvale",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511684",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "2195",
        "icao": "CYQU",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YQZ",
        "lat": "53.0275",
        "lon": "-122.507",
        "name": "Quesnel",
        "city": "Quesnel",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511806",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "1789",
        "icao": "CYQZ",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YRF",
        "lat": "53.7084",
        "lon": "-57.0014",
        "name": "Cartwright",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524210",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "153",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YRG",
        "lat": "54.2102",
        "lon": "-58.4141",
        "name": "Rigolet Aerodrome",
        "city": "Happy Valley-Goose Bay",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524211",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "180",
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YRJ",
        "lat": "48.52",
        "lon": "-72.2661",
        "name": "Roberval",
        "city": "Roberval",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511816",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "586",
        "icao": "CYRJ",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YSM",
        "lat": "60.0208",
        "lon": "-111.963",
        "name": "Fort Smith",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12511668",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "666",
        "icao": "CYSM",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YSR",
        "lat": "72.9833",
        "lon": "-84.6333",
        "name": "Nanisivik",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12523274",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6400",
        "elev": "2098",
        "icao": "CYSR",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YTF",
        "lat": "48.55",
        "lon": "-71.65",
        "name": "Alma",
        "city": "Alma",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511566",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4300",
        "elev": "445",
        "icao": "CYTF",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YTQ",
        "lat": "53.6833",
        "lon": "-69.9333",
        "name": "Tasiujuaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524231",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYTQ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YUD",
        "lat": "56.4835",
        "lon": "-76.4426",
        "name": "Umiujaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524234",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YUM",
        "lat": "32.6685",
        "lon": "-114.599",
        "name": "Yuma",
        "city": "Yuma",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12522544",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13299",
        "elev": "213",
        "icao": "KYUM",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YUX",
        "lat": "68.7804",
        "lon": "-81.2429",
        "name": "Hall Beach",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524245",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "27",
        "icao": "CYUX",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YWB",
        "lat": "61.6013",
        "lon": "-71.9503",
        "name": "Kangiqsujuaq",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524258",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YWJ",
        "lat": "65.1994",
        "lon": "-123.431",
        "name": "Fort Franklin",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524259",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "550",
        "icao": "CYWJ",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YWL",
        "lat": "52.1866",
        "lon": "-122.068",
        "name": "Williams Lake",
        "city": "Williams Lake",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511915",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "3085",
        "icao": "CYWL",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "YWM",
        "lat": "52.3333",
        "lon": "-56",
        "name": "Williams Harbour",
        "city": "St. Anthony",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12524260",
        "tz": "AmericaSt_Johns",
        "phone": "",
        "type": "Harbours",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "70",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YXK",
        "lat": "48.4727",
        "lon": "-68.5044",
        "name": "Rimouski",
        "city": "Rimouski",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12511814",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "82",
        "icao": "CYXK",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YXP",
        "lat": "66.1349",
        "lon": "-65.7142",
        "name": "Pangnirtung",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524269",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "82",
        "icao": "CYXP",
        "direct_flights": "2",
        "carriers": "3"
    },
    {
        "code": "YYE",
        "lat": "58.8429",
        "lon": "-122.579",
        "name": "Fort Nelson",
        "city": "Fort Nelson",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511666",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6400",
        "elev": "1253",
        "icao": "CYYE",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "YYF",
        "lat": "49.4614",
        "lon": "-119.606",
        "name": "Penticton",
        "city": "Penticton",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511788",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1129",
        "icao": "CYYF",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "YZT",
        "lat": "50.6843",
        "lon": "-127.377",
        "name": "Port Hardy",
        "city": "Gibsons",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511795",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "71",
        "icao": "CYZT",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZBL",
        "lat": "-24.3903",
        "lon": "150.519",
        "name": "Australia",
        "city": "Biloela",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "23424748",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZCB",
        "lat": "41.9619",
        "lon": "21.6181",
        "name": "Skopje",
        "city": "",
        "state": "Ilinden",
        "country": "Macedonia",
        "woeid": "12514772",
        "tz": "EuropeSkopje",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZDN",
        "lat": "-33.9344",
        "lon": "151.168",
        "name": "Kingsford Smith",
        "city": "Sydney",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "23388205",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ZEE",
        "lat": "30.754",
        "lon": "-91.3415",
        "name": "Kelsey",
        "city": "Kelsey",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524044",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CZEE",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZEL",
        "lat": "52.152",
        "lon": "-128.155",
        "name": "Bella Bella",
        "city": "Masset",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524283",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1500",
        "elev": "100",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZEM",
        "lat": "52.2399",
        "lon": "-78.5077",
        "name": "East Main",
        "city": "Chisasibi",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524284",
        "tz": "AmericaNipigon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "20",
        "icao": "CZEM",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZFN",
        "lat": "64.9",
        "lon": "-125.583",
        "name": "Fort Norman",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524290",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "320",
        "icao": "CZFN",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZFZ",
        "lat": "40.7145",
        "lon": "-74.0071",
        "name": "New York",
        "city": "Buffalo",
        "state": "New York",
        "country": "United States",
        "woeid": "2459115",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZGS",
        "lat": "50.3016",
        "lon": "-60.6776",
        "name": "Gethsemani",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524293",
        "tz": "AmericaBlanc-Sablon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZKE",
        "lat": "52.3117",
        "lon": "-81.6078",
        "name": "Kaschechewan",
        "city": "Winisk",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524116",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "35",
        "icao": "CZKE",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZKG",
        "lat": "50.1937",
        "lon": "-61.2817",
        "name": "Kegaska",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524295",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZLT",
        "lat": "50.833",
        "lon": "-58.9902",
        "name": "La Tabatiere",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524296",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZNV",
        "lat": "4.6",
        "lon": "-61.1",
        "name": "Santa Elena",
        "city": "Santa Elena de Uairén",
        "state": "Bolivar",
        "country": "Venezuela",
        "woeid": "12524549",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZOS",
        "lat": "-40.6072",
        "lon": "-73.0567",
        "name": "Canal Bajo Carlos H Siebert",
        "city": "Osorno",
        "state": "Los Lagos",
        "country": "Chile",
        "woeid": "12512307",
        "tz": "AmericaSantiago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "190",
        "icao": "SCJO",
        "direct_flights": "2",
        "carriers": "2"
    },
    {
        "code": "ZTB",
        "lat": "50.6876",
        "lon": "-59.3545",
        "name": "Tete-a-la-Baleine",
        "city": "Havre-St-Pierre",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "12524309",
        "tz": "AmericaHalifax",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZTV",
        "lat": "38.1865",
        "lon": "-85.7462",
        "name": "Louisville",
        "city": "Louisville",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "12521983",
        "tz": "AmericaKentuckyLouisville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZUM",
        "lat": "53.5619",
        "lon": "-64.1072",
        "name": "Churchill Falls",
        "city": "Wabush",
        "state": "Newfoundland and Labrador",
        "country": "Canada",
        "woeid": "12511616",
        "tz": "AmericaGoose_Bay",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "1442",
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "ZWV",
        "lat": "39.7393",
        "lon": "-89.5041",
        "name": "Illinois",
        "city": "Glenview",
        "state": "Illinois",
        "country": "United States",
        "woeid": "2347572",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "2",
        "carriers": "1"
    },
    {
        "code": "TQR",
        "lat": "43.2667",
        "lon": "10.9333",
        "name": "San Domino",
        "city": "San Domino",
        "state": "Trentino-Alto Adige",
        "country": "Italy",
        "woeid": "20142146",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AAM",
        "lat": "-24.8",
        "lon": "31.5333",
        "name": "Mala Mala",
        "city": "Mala Mala",
        "state": "",
        "country": "South Africa",
        "woeid": "55921381",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4420",
        "elev": "1200",
        "icao": "FAMD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AAT",
        "lat": "47.7406",
        "lon": "88.0845",
        "name": "Altay",
        "city": "Altay",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "12511977",
        "tz": "AsiaUrumqi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZWAT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ABK",
        "lat": "6.73719",
        "lon": "44.2797",
        "name": "Kabri Dar",
        "city": "Kabri Dar",
        "state": "Ogaden",
        "country": "Ethiopia",
        "woeid": "1317686",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10935",
        "elev": "1800",
        "icao": "HAKD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ABM",
        "lat": "-10.9438",
        "lon": "142.453",
        "name": "Bamaga",
        "city": "Seisia",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510598",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6700",
        "elev": "49",
        "icao": "YBAM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ABS",
        "lat": "22.3722",
        "lon": "31.6125",
        "name": "Abu Simbel",
        "city": "Abu Simbel",
        "state": "Aswan",
        "country": "Egypt",
        "woeid": "12512656",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "615",
        "icao": "HEBL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ABY",
        "lat": "31.5328",
        "lon": "-84.1867",
        "name": "Southwest Georgia",
        "city": "Albany",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12521922",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6601",
        "elev": "197",
        "icao": "KABY",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "ACH",
        "lat": "47.4887",
        "lon": "9.55376",
        "name": "Altenrhein",
        "city": "Altenrhein",
        "state": "Canton of St. Gallen",
        "country": "Switzerland",
        "woeid": "23344872",
        "tz": "EuropeZurich",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.airport-stgallen.com",
        "runway_length": "4350",
        "elev": "1306",
        "icao": "LSZR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ACP",
        "lat": "68.1609",
        "lon": "-151.696",
        "name": "Sahand",
        "city": "Sahand",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KACP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ACZ",
        "lat": "31.0385",
        "lon": "61.4962",
        "name": "Zabol AP",
        "city": "Zabol",
        "state": "Sistan va Baluchestan",
        "country": "Iran",
        "woeid": "2255305",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KACZ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ADK",
        "lat": "51.88",
        "lon": "-176.639",
        "name": "Adak",
        "city": "Adak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518524",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7794",
        "elev": "19",
        "icao": "PADK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ADU",
        "lat": "38.2465",
        "lon": "48.2951",
        "name": "Ardabil",
        "city": "Ardabil",
        "state": "Ardabil",
        "country": "Iran",
        "woeid": "2254335",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "OITL",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "AEH",
        "lat": "13.8486",
        "lon": "20.8472",
        "name": "Abeche",
        "city": "Abéché",
        "state": "Ouaddai",
        "country": "Chad",
        "woeid": "12511930",
        "tz": "AfricaNdjamena",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1788",
        "icao": "FTTC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AFS",
        "lat": "41.6136",
        "lon": "64.2331",
        "name": "Zarafshan",
        "city": "Zarafshan",
        "state": "Nawoiy",
        "country": "Uzbekistan",
        "woeid": "2272667",
        "tz": "AsiaSamarkand",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AFT",
        "lat": "-9.18333",
        "lon": "160.95",
        "name": "Afutara Aerodrome",
        "city": "Afutara",
        "state": "Malaita",
        "country": "Solomon Islands",
        "woeid": "1020505",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AFZ",
        "lat": "36.1903",
        "lon": "57.7094",
        "name": "Sabzevar",
        "city": "Sabzevar",
        "state": "Khorasan",
        "country": "Iran",
        "woeid": "12513753",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10428",
        "elev": "3010",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGF",
        "lat": "44.1736",
        "lon": "0.59223",
        "name": "La Garenne",
        "city": "Laplume",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512903",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7103",
        "elev": "203",
        "icao": "LFBA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGL",
        "lat": "-9.33333",
        "lon": "149.15",
        "name": "Wanigela",
        "city": "Wanigela",
        "state": "Northern",
        "country": "Papua New Guinea",
        "woeid": "1061994",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "50",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGM",
        "lat": "65.6667",
        "lon": "-37.6667",
        "name": "Angmagssalik",
        "city": "Angmassalik",
        "state": "Ostgronland",
        "country": "Greenland",
        "woeid": "12523853",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "BGAM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGN",
        "lat": "57.4996",
        "lon": "-134.577",
        "name": "Angoon",
        "city": "Angoon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524552",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "PAGN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGV",
        "lat": "9.55194",
        "lon": "-69.2367",
        "name": "Acarigua",
        "city": "Acarigua",
        "state": "Portuguesa",
        "country": "Venezuela",
        "woeid": "12522827",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "640",
        "icao": "SVAC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AGX",
        "lat": "10.8415",
        "lon": "72.175",
        "name": "Agatti Island",
        "city": "Agatti Island",
        "state": "Lakshadweep",
        "country": "India",
        "woeid": "12467998",
        "tz": "AsiaKolkata",
        "phone": "+91 4894-42217",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1204",
        "elev": null,
        "icao": "VOAT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AHC",
        "lat": "40.2664",
        "lon": "-120.151",
        "name": "Amedee Army Air Field",
        "city": "Herlong",
        "state": "California",
        "country": "United States",
        "woeid": "12518602",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KAHC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AHS",
        "lat": "15.4534",
        "lon": "-84.4041",
        "name": "Ahuas",
        "city": "Auas",
        "state": "Gracias a Dios",
        "country": "Honduras",
        "woeid": "12524351",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2460",
        "elev": "98",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AIT",
        "lat": "-18.8299",
        "lon": "-159.765",
        "name": "Aitutaki",
        "city": "Aitutaki",
        "state": "Aitutaki",
        "country": "Cook Islands",
        "woeid": "24549676",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5981",
        "elev": "14",
        "icao": "NCAI",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "AIU",
        "lat": "-20.0333",
        "lon": "-158.1",
        "name": "Atiu Island",
        "city": "Atiu Island",
        "state": "Atiu",
        "country": "Cook Islands",
        "woeid": "12523056",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2648",
        "elev": "200",
        "icao": "NCAT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AKA",
        "lat": "32.7026",
        "lon": "108.912",
        "name": "Ankang",
        "city": "Ankang",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "12511979",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZLAK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AKB",
        "lat": "52.2217",
        "lon": "-174.204",
        "name": "Atka",
        "city": "Atka",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518685",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3100",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "AKF",
        "lat": "24.1819",
        "lon": "23.3189",
        "name": "Kufra",
        "city": "Kufrah",
        "state": "Al Kufrah",
        "country": "Libya",
        "woeid": "12514656",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "12007",
        "elev": "1367",
        "icao": "HLKF",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "AKI",
        "lat": "60.8866",
        "lon": "-161.218",
        "name": "Akiak",
        "city": "Bethel",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524554",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "22",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "AKK",
        "lat": "56.95",
        "lon": "-154.167",
        "name": "Akhiok",
        "city": "Kodiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524555",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2170",
        "elev": "50",
        "icao": "PAKH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AKS",
        "lat": "-27.6667",
        "lon": "27.3167",
        "name": "Kroonstad",
        "city": "Kroonstad",
        "state": "Free State",
        "country": "South Africa",
        "woeid": "12517431",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "FAKS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AKU",
        "lat": "41.171",
        "lon": "80.1982",
        "name": "Aksu",
        "city": "Aksu",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "12511976",
        "tz": "AsiaKashgar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ZWAK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ALH",
        "lat": "-34.9447",
        "lon": "117.808",
        "name": "Albany",
        "city": "Albany",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510582",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5250",
        "elev": "232",
        "icao": "YABA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ALM",
        "lat": "32.8407",
        "lon": "-105.994",
        "name": "Alamogordo White Sands",
        "city": "Alamogordo",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12518553",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7005",
        "elev": "4197",
        "icao": "KALM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ALO",
        "lat": "42.5509",
        "lon": "-92.3951",
        "name": "Waterloo Municipal",
        "city": "Waterloo",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12522347",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8401",
        "elev": "873",
        "icao": "KALO",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "ALS",
        "lat": "37.4444",
        "lon": "-105.865",
        "name": "San Luis Valley",
        "city": "Alamosa",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12521726",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8499",
        "elev": "7535",
        "icao": "KALS",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "ALW",
        "lat": "46.0879",
        "lon": "-118.283",
        "name": "Walla Walla",
        "city": "Walla Walla",
        "state": "Washington",
        "country": "United States",
        "woeid": "12522317",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7186",
        "elev": "1205",
        "icao": "KALW",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "ALZ",
        "lat": "56.8988",
        "lon": "-154.246",
        "name": "Alitak Seaplane Base",
        "city": "Alitak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524557",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AMH",
        "lat": "6.01327",
        "lon": "37.5405",
        "name": "Ethiopia",
        "city": "Arba Mintch",
        "state": "Gamo Gofa",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3999",
        "elev": "4000",
        "icao": "HAAM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AMV",
        "lat": "69.7638",
        "lon": "61.5586",
        "name": "Amderma",
        "city": "Nar'yan-Mar",
        "state": "Nenetskiy Avtonomnyy Okrug",
        "country": "Russia",
        "woeid": "12515701",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "ULDD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ANE",
        "lat": "47.5603",
        "lon": "-0.312222",
        "name": "Aéroport d'Angers-Marcé",
        "city": "Seiches-sur-le-Loir",
        "state": "Pays de la Loire",
        "country": "France",
        "woeid": "22868196",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4052",
        "elev": "184",
        "icao": "LFRA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ANG",
        "lat": "45.7274",
        "lon": "0.21835",
        "name": "Brie Champniers",
        "city": "Champniers",
        "state": "Poitou-Charentes",
        "country": "France",
        "woeid": "12512864",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2723",
        "elev": "289",
        "icao": "LFBU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AOR",
        "lat": "6.1947",
        "lon": "100.404",
        "name": "Sultan Abdul Halim",
        "city": "Kepala Batas",
        "state": "Kedah",
        "country": "Malaysia",
        "woeid": "12515009",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6600",
        "elev": "15",
        "icao": "WMKA",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "APO",
        "lat": "7.88361",
        "lon": "-76.6321",
        "name": "Apartado",
        "city": "Apartadó",
        "state": "Antioquia",
        "country": "Colombia",
        "woeid": "12523474",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "ARD",
        "lat": "35.1437",
        "lon": "-93.1848",
        "name": "Alor Island",
        "city": "Alor Island",
        "state": "",
        "country": "Indonesia",
        "woeid": "23424846",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2952",
        "elev": "39",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ARK",
        "lat": "-3.36515",
        "lon": "36.6738",
        "name": "Arusha",
        "city": "Arusha",
        "state": "Arusha",
        "country": "Tanzania",
        "woeid": "24554855",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "HTAR",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "ARM",
        "lat": "-30.5308",
        "lon": "151.618",
        "name": "Armidale",
        "city": "Armidale",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510590",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5702",
        "elev": "3556",
        "icao": "YARM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ART",
        "lat": "43.9942",
        "lon": "-76.0232",
        "name": "Watertown",
        "city": "Dexter",
        "state": "New York",
        "country": "United States",
        "woeid": "12522348",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "325",
        "icao": "KART",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ASA",
        "lat": "31.4124",
        "lon": "-97.0528",
        "name": "Assab",
        "city": "Assab",
        "state": "",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "46",
        "icao": "HHSB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ASD",
        "lat": "24.6978",
        "lon": "-77.7961",
        "name": "Andros Town",
        "city": "Fresh Creek",
        "state": "Central Andros",
        "country": "Bahamas",
        "woeid": "12510859",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "5",
        "icao": "MYAF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ASO",
        "lat": "10.0611",
        "lon": "34.5344",
        "name": "Ethiopia",
        "city": "Asosa",
        "state": "Welega",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3346",
        "elev": "5260",
        "icao": "HASO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ASV",
        "lat": "-2.63333",
        "lon": "37.25",
        "name": "Amboseli",
        "city": "Amboseli",
        "state": "Rift Valley",
        "country": "Kenya",
        "woeid": "1528663",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "3755",
        "icao": "HKAM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ATB",
        "lat": "17.7",
        "lon": "33.9833",
        "name": "Atbara",
        "city": "Atbara",
        "state": "Nahr an Nil",
        "country": "Sudan",
        "woeid": "1431005",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1181",
        "icao": "HSAT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ATC",
        "lat": "24.6287",
        "lon": "-75.6734",
        "name": "Arthur's Town",
        "city": "Arthur Town",
        "state": "Cat Island",
        "country": "Bahamas",
        "woeid": "12524017",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "MYCA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ATD",
        "lat": "-8.86667",
        "lon": "161.033",
        "name": "Atoifi",
        "city": "Atoifi",
        "state": "Malaita",
        "country": "Solomon Islands",
        "woeid": "1020493",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1968",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AUR",
        "lat": "44.8984",
        "lon": "2.41982",
        "name": "Aurillac",
        "city": "Aurillac",
        "state": "Auvergne",
        "country": "France",
        "woeid": "12512848",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "2096",
        "icao": "LFLW",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AUY",
        "lat": "-20.1909",
        "lon": "169.825",
        "name": "Vanuatu",
        "city": "Aneityum",
        "state": "Tafea",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2001",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AVU",
        "lat": "-9.85",
        "lon": "160.417",
        "name": "Avu Avu",
        "city": "Avu Avu",
        "state": "Guadalcanal",
        "country": "Solomon Islands",
        "woeid": "1020506",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2210",
        "elev": "8",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AWD",
        "lat": "-19.2501",
        "lon": "169.598",
        "name": "Aniwa",
        "city": "Aniwa",
        "state": "Tafea",
        "country": "Vanuatu",
        "woeid": "12515050",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3085",
        "elev": "69",
        "icao": "NVVB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "AZR",
        "lat": "27.8378",
        "lon": "-0.1864",
        "name": "Touat",
        "city": "Adrar",
        "state": "Adrar",
        "country": "Algeria",
        "woeid": "12510355",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "915",
        "icao": "DAUA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BAG",
        "lat": "16.3764",
        "lon": "120.619",
        "name": "Baguio",
        "city": "Baguio City",
        "state": "Cordillera Administrative Region",
        "country": "Philippines",
        "woeid": "12515596",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5512",
        "elev": "4239",
        "icao": "RPUB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BAS",
        "lat": "-7.98333",
        "lon": "155.9",
        "name": "Balalae",
        "city": "Balalae",
        "state": "Western",
        "country": "Solomon Islands",
        "woeid": "1020484",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "5",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BBK",
        "lat": "-17.8333",
        "lon": "25.1667",
        "name": "Kasane",
        "city": "Kasane",
        "state": "Chobe",
        "country": "Botswana",
        "woeid": "12523069",
        "tz": "AfricaGaborone",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3150",
        "elev": "3060",
        "icao": "FBKE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BBS",
        "lat": "51.3252",
        "lon": "-0.84173",
        "name": "Blackbushe",
        "city": "Camberley",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518042",
        "tz": "EuropeLondon",
        "phone": "+44 (0) 1252 879449",
        "type": "Airports",
        "email": "",
        "url": "http:www.blackbusheairport.co.uk",
        "runway_length": "4436",
        "elev": "329",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BCA",
        "lat": "20.3644",
        "lon": "-74.5064",
        "name": "Baracoa",
        "city": "Baracoa",
        "state": "Guantanamo",
        "country": "Cuba",
        "woeid": "12512452",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "38",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BCL",
        "lat": "10.7856",
        "lon": "-83.5966",
        "name": "Barra Colorado",
        "city": "Colorado",
        "state": "Limon",
        "country": "Costa Rica",
        "woeid": "12524312",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2296",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BDB",
        "lat": "-24.9046",
        "lon": "152.322",
        "name": "Bundaberg",
        "city": "Bundaberg",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510621",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5030",
        "elev": "107",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BDH",
        "lat": "26.5333",
        "lon": "54.8239",
        "name": "Bandar Lengeh",
        "city": "Bandar Abbas",
        "state": "Hormozgan",
        "country": "Iran",
        "woeid": "12513704",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6650",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BDP",
        "lat": "26.5667",
        "lon": "88.0833",
        "name": "Bhadrapur",
        "city": "Bhadrapur",
        "state": "West Bengal",
        "country": "Nepal",
        "woeid": "2269155",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5133",
        "elev": "300",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BDU",
        "lat": "69.0589",
        "lon": "18.539",
        "name": "Bardufoss",
        "city": "Bardufoss",
        "state": "Troms Fylke",
        "country": "Norway",
        "woeid": "12515103",
        "tz": "EuropeOslo",
        "phone": "+47 77 83 02 00",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2443",
        "elev": "252",
        "icao": "ENDU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BFV",
        "lat": "15.2295",
        "lon": "103.253",
        "name": "Buri Ram",
        "city": "Buri Ram",
        "state": "Buriram",
        "country": "Thailand",
        "woeid": "28341176",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BHI",
        "lat": "-38.7261",
        "lon": "-62.1539",
        "name": "Bahia Blanca Cte Espora Naval Air Base",
        "city": "Punta Alta",
        "state": "Buenos Aires",
        "country": "Argentina",
        "woeid": "12510461",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8365",
        "elev": "246",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BHJ",
        "lat": "23.2875",
        "lon": "69.6706",
        "name": "Bhuj",
        "city": "Bhuj",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513554",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8317",
        "elev": "268",
        "icao": "VABJ",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BHR",
        "lat": "25.9173",
        "lon": "50.6071",
        "name": "Bharatpur",
        "city": "Bharatpur",
        "state": "Central",
        "country": "Nepal",
        "woeid": "2269055",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3800",
        "elev": "600",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BHS",
        "lat": "-33.4121",
        "lon": "149.657",
        "name": "Bathurst",
        "city": "Bathurst",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510601",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5595",
        "elev": "2434",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BHU",
        "lat": "21.7536",
        "lon": "72.1867",
        "name": "Bhavnagar",
        "city": "Bhavnagar",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513549",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6300",
        "elev": "34",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BID",
        "lat": "41.1696",
        "lon": "-71.58",
        "name": "Block Island State",
        "city": "Block Island",
        "state": "Rhode Island",
        "country": "United States",
        "woeid": "12524577",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "105",
        "icao": "KBID",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BII",
        "lat": "11.5833",
        "lon": "165.383",
        "name": "Enyu Airfield",
        "city": "Bikini Atoll",
        "state": "",
        "country": "Marshall Islands",
        "woeid": "23424932",
        "tz": "PacificMajuro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BIM",
        "lat": "25.6994",
        "lon": "-79.2636",
        "name": "South Bimini",
        "city": "Bailey's Town",
        "state": "Bimini",
        "country": "Bahamas",
        "woeid": "12510880",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "10",
        "icao": "MYBS",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "BIR",
        "lat": "26.4814",
        "lon": "87.2647",
        "name": "Biratnagar",
        "city": "Biratnagur",
        "state": "Central",
        "country": "Nepal",
        "woeid": "12515132",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "235",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BJB",
        "lat": "37.4933",
        "lon": "57.3007",
        "name": "Bojnord",
        "city": "Bojnord",
        "state": "Khorasan",
        "country": "Iran",
        "woeid": "2220377",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BJI",
        "lat": "47.5065",
        "lon": "-94.9338",
        "name": "Bemidji-Beltrami County",
        "city": "Bemidji",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12518794",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5699",
        "elev": "1390",
        "icao": "KBJI",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "BJW",
        "lat": "-8.78333",
        "lon": "120.983",
        "name": "Bajawa",
        "city": "Ende",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12523075",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2920",
        "elev": "4326",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BKA",
        "lat": "55.5516",
        "lon": "37.9763",
        "name": "Bykovo",
        "city": "Lyubertsy",
        "state": "Moskovskaya Oblast",
        "country": "Russia",
        "woeid": "12515878",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BKL",
        "lat": "41.5115",
        "lon": "-81.6869",
        "name": "Burke Lakefront",
        "city": "Cleveland",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12518976",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "6198",
        "elev": "584",
        "icao": "KBKL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BKS",
        "lat": "-3.8633",
        "lon": "102.339",
        "name": "Padangkemiling",
        "city": "Bengkulu",
        "state": "Bengkulu",
        "country": "Indonesia",
        "woeid": "12513489",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "50",
        "icao": "KBKS",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BKY",
        "lat": "-2.3014",
        "lon": "28.8139",
        "name": "Bukavu Kavumu",
        "city": "Bukavu",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511951",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "5633",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BLV",
        "lat": "38.5412",
        "lon": "-89.8499",
        "name": "Belleville",
        "city": "Belleville",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12521774",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KBLV",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BMK",
        "lat": "53.5797",
        "lon": "6.71294",
        "name": "Borkum",
        "city": "Borkum",
        "state": "Lower Saxony",
        "country": "Germany",
        "woeid": "22176048",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "3",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BMO",
        "lat": "24.2739",
        "lon": "97.2517",
        "name": "Bhamo",
        "city": "Bhamo",
        "state": "Kachin State",
        "country": "Myanmar",
        "woeid": "12510912",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5501",
        "elev": "360",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BMW",
        "lat": "28.0269",
        "lon": "1.65284",
        "name": "Algeria",
        "city": "Bordj Badji Mokhtar",
        "state": null,
        "country": "Algeria",
        "woeid": "23424740",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7372",
        "elev": "1303",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BMY",
        "lat": "-19.75",
        "lon": "163.667",
        "name": "Belep Island",
        "city": "Belep Island",
        "state": "Nord",
        "country": "New Caledonia",
        "woeid": "1049677",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1640",
        "elev": "338",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BNI",
        "lat": "6.3161",
        "lon": "5.6",
        "name": "Benin",
        "city": "Benin City",
        "state": "Edo",
        "country": "Nigeria",
        "woeid": "12515060",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "258",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BOC",
        "lat": "9.34",
        "lon": "-82.2514",
        "name": "Bocas del Toro",
        "city": "Bocas del Toro",
        "state": "Bocas del Toro",
        "country": "Panama",
        "woeid": "12515413",
        "tz": "AmericaPanama",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4932",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BPT",
        "lat": "29.9551",
        "lon": "-94.0185",
        "name": "Jefferson County",
        "city": "Beaumont",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520357",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6751",
        "elev": "16",
        "icao": "KBPT",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "BQK",
        "lat": "31.2525",
        "lon": "-81.4703",
        "name": "Glynco Jetport",
        "city": "Brunswick",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12519920",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8001",
        "elev": "26",
        "icao": "KBQK",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BRK",
        "lat": "-30.0403",
        "lon": "145.953",
        "name": "Bourke",
        "city": "Bourke",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510614",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "351",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BRV",
        "lat": "53.5537",
        "lon": "8.78359",
        "name": "Bremerhaven",
        "city": "Bad Bederkesa",
        "state": "Lower Saxony",
        "country": "Germany",
        "woeid": "12523855",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2259",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BSD",
        "lat": "25.0604",
        "lon": "99.1639",
        "name": "Baoshan",
        "city": "Baoshan",
        "state": "Yunnan",
        "country": "China",
        "woeid": "12511986",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BSO",
        "lat": "20.4531",
        "lon": "121.978",
        "name": "Basco",
        "city": "Basco",
        "state": "Cagayan Valley",
        "country": "Philippines",
        "woeid": "12515599",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4101",
        "elev": "184",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BTZ",
        "lat": "40.2344",
        "lon": "29.0053",
        "name": "Bursa",
        "city": "Bursa",
        "state": "Bursa",
        "country": "Turkey",
        "woeid": "12517876",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "332",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BUL",
        "lat": "33.9116",
        "lon": "-102.638",
        "name": "Bulolo",
        "city": "Bulolo",
        "state": "Manus",
        "country": "Papua New Guinea",
        "woeid": "12498381",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4375",
        "elev": "2240",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BUN",
        "lat": "3.8183",
        "lon": "-76.9931",
        "name": "Buenaventura",
        "city": "Buenaventura",
        "state": "Valle del Cauca",
        "country": "Colombia",
        "woeid": "12512367",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3510",
        "elev": "50",
        "icao": "SKBU",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BUX",
        "lat": "1.5717",
        "lon": "30.2128",
        "name": "Bunia",
        "city": "Bunia",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511952",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "4045",
        "icao": "FZKA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BVB",
        "lat": "2.8417",
        "lon": "-60.6917",
        "name": "Boa Vista",
        "city": "Boa Vista",
        "state": "Nordeste",
        "country": "Brazil",
        "woeid": "12511054",
        "tz": "AmericaRecife",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8071",
        "elev": "276",
        "icao": "SBBV",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "BVC",
        "lat": "16.0961",
        "lon": "-22.8172",
        "name": "Boa Vista",
        "city": "Boa Vista",
        "state": "Boa Vista",
        "country": "Cape Verde",
        "woeid": "12512494",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "69",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BVE",
        "lat": "45.1487",
        "lon": "1.47441",
        "name": "La Roche",
        "city": "Brive-la-Gaillarde",
        "state": "Limousin",
        "country": "France",
        "woeid": "12512905",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3609",
        "elev": "374",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BWA",
        "lat": "27.5042",
        "lon": "83.42",
        "name": "Bhairawa",
        "city": "Bhairawa",
        "state": "West",
        "country": "Nepal",
        "woeid": "12515131",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4970",
        "elev": "358",
        "icao": "VNBW",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BWF",
        "lat": "54.1284",
        "lon": "-3.26249",
        "name": "Walney Island",
        "city": "Barrow in Furness",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518033",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3953",
        "elev": "47",
        "icao": "EGNL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BWK",
        "lat": "43.2619",
        "lon": "16.655",
        "name": "Bol",
        "city": "Bol",
        "state": "Splitsko-Dalmatinska",
        "country": "Croatia",
        "woeid": "15022368",
        "tz": "EuropeBelgrade",
        "phone": "+38521559711",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BWT",
        "lat": "-40.9935",
        "lon": "145.725",
        "name": "Burnie Wynyard",
        "city": "Doctors Rocks",
        "state": "Tasmania",
        "country": "Australia",
        "woeid": "12510819",
        "tz": "AustraliaHobart",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4965",
        "elev": "61",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BWW",
        "lat": "22.4922",
        "lon": "-79.9408",
        "name": "Santa Clara",
        "city": "Santa Clara",
        "state": "",
        "country": "Cuba",
        "woeid": "12512488",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BXR",
        "lat": "29.4375",
        "lon": "-98.4618",
        "name": "Bam",
        "city": "Bam",
        "state": "Kerman",
        "country": "Iran",
        "woeid": "2254392",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BYM",
        "lat": "20.3972",
        "lon": "-76.6192",
        "name": "Bayamo",
        "city": "Bayamo",
        "state": "Granma",
        "country": "Cuba",
        "woeid": "12512453",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6625",
        "elev": "210",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "BZL",
        "lat": "22.6667",
        "lon": "90.3417",
        "name": "Barisal",
        "city": "Barisal",
        "state": "Barisal",
        "country": "Bangladesh",
        "woeid": "1914997",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1810",
        "elev": "20",
        "icao": "VGBR",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "BZZ",
        "lat": "51.7614",
        "lon": "-1.57482",
        "name": "Brize Norton",
        "city": "Carterton",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518048",
        "tz": "EuropeLondon",
        "phone": "00 44 1993 842551",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "10006",
        "elev": "287",
        "icao": "EGVN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CAH",
        "lat": "9.17667",
        "lon": "105.151",
        "name": "Camo",
        "city": "Ca Mau",
        "state": "Ca Mau",
        "country": "Vietnam",
        "woeid": "1252573",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1804",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CAL",
        "lat": "55.4388",
        "lon": "-5.69997",
        "name": "Campbeltown",
        "city": "Campbeltown",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22458271",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10003",
        "elev": "44",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CDC",
        "lat": "37.6992",
        "lon": "-113.092",
        "name": "Cedar City Municipal",
        "city": "Cedar City",
        "state": "Utah",
        "country": "United States",
        "woeid": "12519109",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7802",
        "elev": "5622",
        "icao": "KCDC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CED",
        "lat": "-32.1264",
        "lon": "133.701",
        "name": "Ceduna",
        "city": "Ceduna",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "28677708",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5735",
        "elev": "77",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CEM",
        "lat": "65.6121",
        "lon": "-144.674",
        "name": "Central",
        "city": "Circle",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524607",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "932",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CEZ",
        "lat": "37.3044",
        "lon": "-108.63",
        "name": "Cortez-Montezuma County",
        "city": "Cortez",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519328",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7205",
        "elev": "5914",
        "icao": "KCEZ",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CFK",
        "lat": "36.2128",
        "lon": "1.33167",
        "name": "Abou Bakr Belkaid",
        "city": "Chlef",
        "state": "Chlef",
        "country": "Algeria",
        "woeid": "12510322",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1200",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CGI",
        "lat": "37.2272",
        "lon": "-89.5637",
        "name": "Cape Girardeau Municipal",
        "city": "Scott City",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12519053",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6499",
        "elev": "342",
        "icao": "KCGI",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CGM",
        "lat": "9.173",
        "lon": "124.725",
        "name": "Philippines",
        "city": "Camiguin",
        "state": "Northern Mindanao",
        "country": "Philippines",
        "woeid": "23424934",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CHU",
        "lat": "61.5769",
        "lon": "-159.244",
        "name": "Chuathbaluk",
        "city": "Chuathbaluk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2380180",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCHU",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CHY",
        "lat": "-6.7",
        "lon": "156.45",
        "name": "Choiseul Bay",
        "city": "Choiseul Bay",
        "state": "Western",
        "country": "Solomon Islands",
        "woeid": "1020483",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2395",
        "elev": "5",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CIC",
        "lat": "39.7987",
        "lon": "-121.854",
        "name": "Chico Municipal",
        "city": "Chico",
        "state": "California",
        "country": "United States",
        "woeid": "12519180",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6724",
        "elev": "238",
        "icao": "KCIC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CIK",
        "lat": "66.65",
        "lon": "-143.75",
        "name": "Chalkyitsik",
        "city": "Chalkyitsik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524613",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "560",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CIP",
        "lat": "-11.1358",
        "lon": "-38.5205",
        "name": "Chipata",
        "city": "Chipata",
        "state": "Eastern",
        "country": "Zambia",
        "woeid": "1564855",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4822",
        "elev": "3359",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CJA",
        "lat": "-7.1322",
        "lon": "-78.4889",
        "name": "Maj Gen Fap A R Iglesias",
        "city": "Cajamarca",
        "state": "Cajamarca",
        "country": "Peru",
        "woeid": "12515209",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5971",
        "elev": "8590",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CKH",
        "lat": "70.6416",
        "lon": "147.933",
        "name": "Russia",
        "city": "Chokurdah",
        "state": "Sakha",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaMagadan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CKX",
        "lat": "64.0833",
        "lon": "-141.917",
        "name": "Chicken",
        "city": "Tok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524619",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "1640",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CKZ",
        "lat": "40.1553",
        "lon": "26.4142",
        "name": "Abydus",
        "city": "Canakkale",
        "state": "Çanakkale",
        "country": "Turkey",
        "woeid": "2343859",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3654",
        "elev": "28",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CLD",
        "lat": "33.1255",
        "lon": "-117.275",
        "name": "Mcclellan Palomar",
        "city": "Carlsbad",
        "state": "California",
        "country": "United States",
        "woeid": "12520851",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.co.san-diego.ca.usdpwairportsmcpal.htm",
        "runway_length": "4700",
        "elev": "328",
        "icao": "KCRQ",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CLM",
        "lat": "48.1155",
        "lon": "-123.491",
        "name": "William R Fairchild",
        "city": "Port Angeles",
        "state": "Washington",
        "country": "United States",
        "woeid": "12522452",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6349",
        "elev": "288",
        "icao": "KCLM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CLP",
        "lat": "58.8517",
        "lon": "-158.51",
        "name": "Clarks Point",
        "city": "Clarks Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524621",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2730",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CMG",
        "lat": "-19.0055",
        "lon": "-57.7055",
        "name": "Corumba",
        "city": "Corumba",
        "state": "Mato Grosso do Sul",
        "country": "Brazil",
        "woeid": "12511101",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5443",
        "elev": "461",
        "icao": "SBCR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CMU",
        "lat": "-6.0283",
        "lon": "44.9717",
        "name": "Chimbu",
        "city": "Kundiawa",
        "state": null,
        "country": "Papua New Guinea",
        "woeid": "12515463",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3410",
        "elev": "4900",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CMX",
        "lat": "47.1689",
        "lon": "-88.4942",
        "name": "Houghton County Memorial",
        "city": "Hancock",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520236",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "1095",
        "icao": "KCMX",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "CNC",
        "lat": "-10.0505",
        "lon": "143.069",
        "name": "Australia",
        "city": "Coconut Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "28644989",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCNC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "COQ",
        "lat": "48.1366",
        "lon": "114.65",
        "name": "Choybalsan Northeast",
        "city": "Choibalsan",
        "state": "Dornod",
        "country": "Mongolia",
        "woeid": "12514740",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCOQ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CPC",
        "lat": "-40.0758",
        "lon": "-71.1392",
        "name": "Chapelco",
        "city": "San Martin DeLos Andes",
        "state": "Buenos Aires",
        "country": "Argentina",
        "woeid": "12510474",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "2556",
        "icao": "SAMI",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CPD",
        "lat": "-29.0378",
        "lon": "134.724",
        "name": "Coober Pedy Aerodrome",
        "city": "Coober Pedy",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510638",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4630",
        "elev": "734",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CPV",
        "lat": "-7.2689",
        "lon": "-35.8947",
        "name": "Presidente Joao Suassuna",
        "city": "Campina Grande",
        "state": "Paraiba",
        "country": "Brazil",
        "woeid": "12511202",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "1641",
        "icao": "SBKG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CPX",
        "lat": "18.3204",
        "lon": "-65.2929",
        "name": "Culebra",
        "city": "Culebra",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12515657",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "65",
        "icao": "TJCP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CRM",
        "lat": "12.5044",
        "lon": "124.635",
        "name": "Catarman",
        "city": "Catarman",
        "state": "Eastern Visayas",
        "country": "Philippines",
        "woeid": "12515605",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3346",
        "elev": "6",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CSA",
        "lat": "56.0603",
        "lon": "-6.22418",
        "name": "Isle Of Colonsay",
        "city": "Isle Of Colonsay",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "26350303",
        "tz": "EuropeLondon",
        "phone": "01951 200411",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1642",
        "elev": "35",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CSG",
        "lat": "32.5184",
        "lon": "-84.9401",
        "name": "Columbus Metropolitan",
        "city": "Columbus",
        "state": "Georgia",
        "country": "United States",
        "woeid": "23418432",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KCSG",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CSH",
        "lat": "65.05",
        "lon": "35.6667",
        "name": "Solovky",
        "city": "Solovetsky",
        "state": "Arkhangelrskaya Oblast",
        "country": "Russian Federation",
        "woeid": "2030227",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CSK",
        "lat": "12.3953",
        "lon": "-16.7514",
        "name": "Cap Skiring",
        "city": "Cap Skirring",
        "state": "",
        "country": "Senegal",
        "woeid": "12517504",
        "tz": "AfricaDakar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4757",
        "elev": "49",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CTC",
        "lat": "-28.5939",
        "lon": "-65.7542",
        "name": "Catamarca",
        "city": "San Isidro",
        "state": "Catamarca",
        "country": "Argentina",
        "woeid": "12510470",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9186",
        "elev": "1522",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CTM",
        "lat": "18.5039",
        "lon": "-88.3247",
        "name": "Chetumal",
        "city": "Chetumal",
        "state": "Quintana Roo",
        "country": "Mexico",
        "woeid": "12514841",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7244",
        "elev": "39",
        "icao": "MMCM",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CUM",
        "lat": "10.4539",
        "lon": "-64.1297",
        "name": "Antonio Jose de Sucre",
        "city": "Cumaná",
        "state": "Sucre",
        "country": "Venezuela",
        "woeid": "12522754",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6857",
        "elev": "14",
        "icao": "SVCU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CUP",
        "lat": "10.6589",
        "lon": "-63.2625",
        "name": "Gen Jose Francisco Bermudez",
        "city": "El Pilar",
        "state": "Sucre",
        "country": "Venezuela",
        "woeid": "12522786",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6759",
        "elev": "33",
        "icao": "SVCP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CVN",
        "lat": "34.4271",
        "lon": "-103.087",
        "name": "Clovis Municipal",
        "city": "Texico",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12519252",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6200",
        "elev": "4214",
        "icao": "KCVN",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CWC",
        "lat": "48.2526",
        "lon": "25.976",
        "name": "Chernovtsy",
        "city": "Chernivtsi",
        "state": "Chernivets´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518229",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CXJ",
        "lat": "-29.1961",
        "lon": "-51.1906",
        "name": "Campo dos Bugres",
        "city": "Caxias do Sul",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511068",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CYP",
        "lat": "12.0722",
        "lon": "124.544",
        "name": "Calbayog",
        "city": "Calbayog",
        "state": "Eastern Visayas",
        "country": "Philippines",
        "woeid": "12515603",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5415",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CYS",
        "lat": "41.1527",
        "lon": "-104.819",
        "name": "Cheyenne",
        "city": "Cheyenne",
        "state": "Wyoming",
        "country": "United States",
        "woeid": "12519176",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9200",
        "elev": "6156",
        "icao": "KCYS",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "CYX",
        "lat": "68.75",
        "lon": "161.35",
        "name": "Cherskiy",
        "city": "Cherskiy",
        "state": "Sakha",
        "country": "Russian Federation",
        "woeid": "2120544",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CYZ",
        "lat": "16.93",
        "lon": "121.756",
        "name": "Cauayan",
        "city": "Cauayan",
        "state": "Cagayan Valley",
        "country": "Philippines",
        "woeid": "12515606",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "200",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "CZE",
        "lat": "11.4158",
        "lon": "-69.6819",
        "name": "Jose Leonardo Chirinos",
        "city": "La Vela de Coro",
        "state": "Falcon",
        "country": "Venezuela",
        "woeid": "12522796",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "52",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CZH",
        "lat": "18.3696",
        "lon": "-88.4307",
        "name": "Corozal",
        "city": "Corozal",
        "state": "Corozal",
        "country": "Belize",
        "woeid": "12524033",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1970",
        "elev": "39",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CZN",
        "lat": "62.0833",
        "lon": "-142.05",
        "name": "Chisana",
        "city": "Gakona",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524639",
        "tz": "AmericaYakutat",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "3318",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "CZS",
        "lat": "-7.5994",
        "lon": "-72.7697",
        "name": "Cruzeiro do Sul",
        "city": "Cruzeiro Do Sul",
        "state": "Acre",
        "country": "Brazil",
        "woeid": "12511105",
        "tz": "AmericaRio_Branco",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7877",
        "elev": "600",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DBA",
        "lat": "30.4419",
        "lon": "69.3597",
        "name": "Pakistan",
        "city": "Dalbandin",
        "state": "",
        "country": "Pakistan",
        "woeid": "23424922",
        "tz": "AsiaKarachi",
        "phone": "0885 -210200",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1524",
        "elev": "2800",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DEC",
        "lat": "39.8335",
        "lon": "-88.8773",
        "name": "Decatur",
        "city": "Decatur",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12519441",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6497",
        "elev": "679",
        "icao": "KDEC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DED",
        "lat": "30.3167",
        "lon": "78.0333",
        "name": "Dehra Dun",
        "city": "Dehra Dun",
        "state": "Uttaranchal",
        "country": "India",
        "woeid": "2294972",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "VIDN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DEF",
        "lat": "32.4317",
        "lon": "48.3989",
        "name": "Dezful",
        "city": "Dezful",
        "state": "Khuzestan",
        "country": "Iran",
        "woeid": "12513715",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DEZ",
        "lat": "35.2822",
        "lon": "40.1744",
        "name": "Deir Zzor",
        "city": "Dayr az Zawr",
        "state": "Dayr az Zawr",
        "country": "Syria",
        "woeid": "12517699",
        "tz": "AsiaDamascus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "700",
        "icao": "OSDZ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DGE",
        "lat": "-32.5613",
        "lon": "149.618",
        "name": "Mudgee Aerodrome",
        "city": "Apple Tree Flat",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510746",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5705",
        "elev": "1545",
        "icao": "YMDG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DGT",
        "lat": "9.3358",
        "lon": "123.301",
        "name": "Dumaguete",
        "city": "Sibulan",
        "state": "Central Visayas",
        "country": "Philippines",
        "woeid": "12515613",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5610",
        "elev": "16",
        "icao": "RPVD",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "DHM",
        "lat": "32.3",
        "lon": "76.2667",
        "name": "Gaggal",
        "city": "Dharamsala",
        "state": "Himachal Pradesh",
        "country": "India",
        "woeid": "2290603",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DHN",
        "lat": "31.3144",
        "lon": "-85.4487",
        "name": "Dothan",
        "city": "Dothan",
        "state": "Alabama",
        "country": "United States",
        "woeid": "12519514",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8499",
        "elev": "401",
        "icao": "KDHN",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "DIN",
        "lat": "38.5686",
        "lon": "-81.0596",
        "name": "Dien Bien",
        "city": "Dien Bien Phu",
        "state": "Dien Bien",
        "country": "Vietnam",
        "woeid": "1252386",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DIU",
        "lat": "20.7134",
        "lon": "70.9217",
        "name": "Diu",
        "city": "Diu",
        "state": "Daman and Diu",
        "country": "India",
        "woeid": "29230263",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "VA1P",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DLU",
        "lat": "25.5667",
        "lon": "100.233",
        "name": "Dali",
        "city": "Dali City",
        "state": "Yunnan",
        "country": "China",
        "woeid": "2160550",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DLZ",
        "lat": "43.9667",
        "lon": "104.683",
        "name": "Dalanzadgad",
        "city": "Dalanzadgad",
        "state": "Omnogov",
        "country": "Mongolia",
        "woeid": "2266117",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KDLZ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DOP",
        "lat": "29.217",
        "lon": "82.7517",
        "name": "Nepal",
        "city": "Dolpa",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "23424911",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1500",
        "elev": "8200",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DOU",
        "lat": "-22.1978",
        "lon": "-54.9225",
        "name": "Dourados",
        "city": "Dourados",
        "state": "Mato Grosso do Sul",
        "country": "Brazil",
        "woeid": "12511113",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4321",
        "elev": "1509",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DOY",
        "lat": "40.0294",
        "lon": "-120.107",
        "name": "Dongying",
        "city": "Dongying",
        "state": "Shandong",
        "country": "China",
        "woeid": "26198082",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DPO",
        "lat": "-41.1726",
        "lon": "146.425",
        "name": "Devonport",
        "city": "Devonport",
        "state": "Tasmania",
        "country": "Australia",
        "woeid": "12510655",
        "tz": "AustraliaHobart",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6030",
        "elev": "22",
        "icao": "YDPO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DRT",
        "lat": "29.3702",
        "lon": "-100.918",
        "name": "Del Rio",
        "city": "Del Rio",
        "state": "Texas",
        "country": "United States",
        "woeid": "12519452",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5105",
        "elev": "999",
        "icao": "KDRT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DUE",
        "lat": "-7.3958",
        "lon": "20.8236",
        "name": "Dundo",
        "city": "Dundo",
        "state": "Lunda Norte",
        "country": "Angola",
        "woeid": "12510434",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6233",
        "elev": "2451",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DWD",
        "lat": "23.9176",
        "lon": "45.0811",
        "name": "Saudi Arabia",
        "city": "Dawadmi",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "23424938",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "DZN",
        "lat": "47.7131",
        "lon": "67.7065",
        "name": "Dzhezkazgan South",
        "city": "Zhezqazghan",
        "state": "Qaraghandy",
        "country": "Kazakhstan",
        "woeid": "12514327",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "UAKD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EAA",
        "lat": "64.7667",
        "lon": "-141.15",
        "name": "Eagle",
        "city": "Tok",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524661",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "880",
        "icao": "PAEA",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "EAL",
        "lat": "9.08333",
        "lon": "167.333",
        "name": "Elenak",
        "city": "Kwajalein Atoll",
        "state": "Kwajalein",
        "country": "Marshall Islands",
        "woeid": "24549846",
        "tz": "PacificKwajalein",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EAR",
        "lat": "40.7236",
        "lon": "-99.0151",
        "name": "Kearney Municipal",
        "city": "Kearney",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12520429",
        "tz": "AmericaChicago",
        "phone": "308-234-2318",
        "type": "Airports",
        "email": "bjohnson@flykearney.com",
        "url": "",
        "runway_length": "7092",
        "elev": "2130",
        "icao": "KEAR",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "EAT",
        "lat": "47.4048",
        "lon": "-120.21",
        "name": "Pangborn Memorial",
        "city": "East Wenatchee",
        "state": "Washington",
        "country": "United States",
        "woeid": "12521295",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5499",
        "elev": "1245",
        "icao": "KEAT",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "EDA",
        "lat": "55.9503",
        "lon": "-133.656",
        "name": "Edna Bay Seaplane Base",
        "city": "Ketchikan",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524642",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EDL",
        "lat": "0.5375",
        "lon": "35.2783",
        "name": "Eldoret",
        "city": "Nakuru",
        "state": "Rift Valley",
        "country": "Kenya",
        "woeid": "12514065",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4396",
        "elev": "7050",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "EDO",
        "lat": "39.599",
        "lon": "27.0221",
        "name": "Edremit-Korfez",
        "city": "Edremit",
        "state": "Balıkesir",
        "country": "Turkey",
        "woeid": "12523443",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EGS",
        "lat": "65.2694",
        "lon": "-14.3549",
        "name": "Egilsstadir",
        "city": "Egilsstadir",
        "state": "Sudur-Mulasysla",
        "country": "Iceland",
        "woeid": "12523881",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5334",
        "elev": "81",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EIE",
        "lat": "58.4395",
        "lon": "92.1852",
        "name": "Russia",
        "city": "Eniseysk",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EIK",
        "lat": "59.298",
        "lon": "10.4319",
        "name": "Tonsberg",
        "city": "Tonsberg",
        "state": "Vestfold Fylke",
        "country": "Norway",
        "woeid": "22659603",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EJA",
        "lat": "7.025",
        "lon": "-73.8092",
        "name": "Yariguies",
        "city": "Barrancabermeja",
        "state": "Santander",
        "country": "Colombia",
        "woeid": "12512424",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5904",
        "elev": "412",
        "icao": "SKEJ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EKO",
        "lat": "40.829",
        "lon": "-115.78",
        "name": "Elko Municipal-J C Harris Field",
        "city": "Elko",
        "state": "Nevada",
        "country": "United States",
        "woeid": "12519626",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7211",
        "elev": "5135",
        "icao": "KEKO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EKS",
        "lat": "45.2681",
        "lon": "-111.65",
        "name": "Ennis Big Sky",
        "city": "",
        "state": "",
        "country": "",
        "woeid": "29387875",
        "tz": "AmericaBoise",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "5383",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ELC",
        "lat": "-11.9149",
        "lon": "135.803",
        "name": "Elcho Island",
        "city": "Darwin",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "12510663",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4850",
        "elev": "97",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ELG",
        "lat": "30.5686",
        "lon": "2.8653",
        "name": "El Golea",
        "city": "El Golea",
        "state": "Ghardaia",
        "country": "Algeria",
        "woeid": "12510324",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1312",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ELV",
        "lat": "58.1833",
        "lon": "-136.317",
        "name": "Elfin Cove",
        "city": "Elfin Cove",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524668",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ELY",
        "lat": "39.3043",
        "lon": "-114.853",
        "name": "Yelland Field",
        "city": "Ely",
        "state": "Nevada",
        "country": "United States",
        "woeid": "12519640",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5998",
        "elev": "6255",
        "icao": "KELY",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "EMD",
        "lat": "-23.5704",
        "lon": "148.178",
        "name": "Emerald Aerodrome",
        "city": "Emerald",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510665",
        "tz": "AustraliaMelbourne",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4800",
        "elev": "624",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EME",
        "lat": "53.3893",
        "lon": "7.2284",
        "name": "Emden",
        "city": "Emden",
        "state": "Lower Saxony",
        "country": "Germany",
        "woeid": "22192358",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ENF",
        "lat": "68.3606",
        "lon": "23.4205",
        "name": "Enontekio",
        "city": "Leppäjärvi",
        "state": "Lapland",
        "country": "Finland",
        "woeid": "12512763",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "1004",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ENU",
        "lat": "6.4756",
        "lon": "7.5661",
        "name": "Enugu",
        "city": "Enugu",
        "state": "Enugu",
        "country": "Nigeria",
        "woeid": "12515063",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "466",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ENW",
        "lat": "42.5934",
        "lon": "-87.9299",
        "name": "Kenosha",
        "city": "Kenosha",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12520443",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "729",
        "icao": "KENW",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EOZ",
        "lat": "7.0631",
        "lon": "-69.4942",
        "name": "Elorza",
        "city": "Elorza",
        "state": "Apure",
        "country": "Venezuela",
        "woeid": "12522783",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "295",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EPR",
        "lat": "-33.6819",
        "lon": "121.827",
        "name": "Esperance Aerodrome",
        "city": "Gibson",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510666",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "470",
        "icao": "YESP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EQS",
        "lat": "-42.9264",
        "lon": "-71.1425",
        "name": "Esquel",
        "city": "Esquel",
        "state": "Chubut",
        "country": "Argentina",
        "woeid": "12510495",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7857",
        "elev": "2582",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ERH",
        "lat": "31.9525",
        "lon": "-4.4067",
        "name": "Er Rachidia",
        "city": "Errachidia",
        "state": "Er Rachidia",
        "country": "Morocco",
        "woeid": "12514786",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "3392",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ESM",
        "lat": "0.9717",
        "lon": "-79.6219",
        "name": "General Rivadeneira",
        "city": "Tachina",
        "state": "Esmeraldas",
        "country": "Ecuador",
        "woeid": "12512631",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7872",
        "elev": "23",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "EUA",
        "lat": "45.9034",
        "lon": "-116.982",
        "name": "Eua Island",
        "city": "Eua",
        "state": "Tongatapu",
        "country": "Tonga",
        "woeid": "12502093",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2400",
        "elev": "325",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EUM",
        "lat": "54.1667",
        "lon": "9.98333",
        "name": "Wasbek",
        "city": "Bordesholm",
        "state": "Schleswig-Holstein",
        "country": "Germany",
        "woeid": "12523863",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1968",
        "elev": "72",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EVG",
        "lat": "62.0466",
        "lon": "14.4187",
        "name": "Sveg",
        "city": "Sveg",
        "state": "Jamtland",
        "country": "Sweden",
        "woeid": "12517684",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "1176",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "EWD",
        "lat": "61.1021",
        "lon": "-149.924",
        "name": "Wildman Lake",
        "city": "Wildman Lake",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799607",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FAB",
        "lat": "51.2783",
        "lon": "-0.7703",
        "name": "Farnborough",
        "city": "Farnborough",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518087",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2440",
        "elev": "238",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FCN",
        "lat": "53.7849",
        "lon": "8.59625",
        "name": "Nordholz",
        "city": "Cuxhaven",
        "state": "Lower-Saxony",
        "country": "Germany",
        "woeid": "680398",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FGU",
        "lat": "-15.9038",
        "lon": "-140.494",
        "name": "French Polynesia",
        "city": "Fangatau",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2953",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FHZ",
        "lat": "-8.64687",
        "lon": "-131.787",
        "name": "French Polynesia",
        "city": "Fakahina",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2952",
        "elev": "3",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FJR",
        "lat": "25.1106",
        "lon": "56.3267",
        "name": "Fujairah",
        "city": "Al Fujayrah",
        "state": "Fujairah",
        "country": "United Arab Emirates",
        "woeid": "12517735",
        "tz": "AsiaDubai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "10",
        "icao": "OMFJ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FMA",
        "lat": "-26.21",
        "lon": "-58.235",
        "name": "Formosa",
        "city": "Formosa",
        "state": "Formosa",
        "country": "Argentina",
        "woeid": "12510497",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "194",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FNL",
        "lat": "40.4505",
        "lon": "-105.008",
        "name": "Fort Collins Loveland Municipal",
        "city": "Loveland",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519773",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4700",
        "elev": "4935",
        "icao": "KFNL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FOD",
        "lat": "42.5523",
        "lon": "-94.1819",
        "name": "Fort Dodge",
        "city": "Fort Dodge",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12519775",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6547",
        "elev": "1157",
        "icao": "KFOD",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "FRC",
        "lat": "-20.5897",
        "lon": "-47.3819",
        "name": "Franca",
        "city": "Franca",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511149",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "3271",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FRE",
        "lat": "-8.1",
        "lon": "159.583",
        "name": "Fera Island",
        "city": "Fera Island",
        "state": "Isabel",
        "country": "Solomon Islands",
        "woeid": "1020491",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2231",
        "elev": "8",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FRW",
        "lat": "-21.16",
        "lon": "27.4825",
        "name": "Francistown",
        "city": "Francistown",
        "state": "North-East",
        "country": "Botswana",
        "woeid": "12510835",
        "tz": "AfricaGaborone",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7088",
        "elev": "3281",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FUG",
        "lat": "37.8882",
        "lon": "-82.9323",
        "name": "Fuyang",
        "city": "Fuyang",
        "state": "Zhejiang",
        "country": "China",
        "woeid": "2132597",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FUJ",
        "lat": "32.6726",
        "lon": "128.836",
        "name": "Fukue",
        "city": "Goto-shi",
        "state": "Nagasaki Prefecture",
        "country": "Japan",
        "woeid": "12513949",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5250",
        "elev": "273",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FUN",
        "lat": "-8.52982",
        "lon": "179.189",
        "name": "Funafuti",
        "city": "Funafuti",
        "state": "Funafuti",
        "country": "Tuvalu",
        "woeid": "12517924",
        "tz": "PacificFunafuti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "9",
        "icao": "NGFU",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "FUT",
        "lat": "-14.25",
        "lon": "-178.15",
        "name": "Futuna Island",
        "city": "Futuna Island",
        "state": "Hahake",
        "country": "Wallis and Futuna Islands",
        "woeid": "22503940",
        "tz": "PacificWallis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3051",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "FWM",
        "lat": "56.8179",
        "lon": "-5.1039",
        "name": "Fort William Heliport",
        "city": "Fort William",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "12523986",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GAO",
        "lat": "20.143",
        "lon": "-75.209",
        "name": "Cuba",
        "city": "Guantanamo",
        "state": "Guantanamo",
        "country": "Cuba",
        "woeid": "23424793",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8053",
        "elev": "20",
        "icao": "KGAO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GAX",
        "lat": "-2.7639",
        "lon": "9.98581",
        "name": "Gamba",
        "city": "Gamba",
        "state": "",
        "country": "Gabon",
        "woeid": "23424822",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2461",
        "elev": "13",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GBJ",
        "lat": "15.8714",
        "lon": "-61.2647",
        "name": "Marie Galante",
        "city": "Grand-Bourg",
        "state": "Pointe-À-Pitre",
        "country": "Guadeloupe",
        "woeid": "12513278",
        "tz": "AmericaGuadeloupe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4068",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GDO",
        "lat": "7.2111",
        "lon": "-70.7583",
        "name": "Vare Maria",
        "city": "Barinas",
        "state": "Apure",
        "country": "Venezuela",
        "woeid": "12522788",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3969",
        "elev": "426",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GER",
        "lat": "21.8375",
        "lon": "-82.78",
        "name": "Nueva Gerona",
        "city": "Nueva Gerona",
        "state": "Isla de la Juventud",
        "country": "Cuba",
        "woeid": "12512478",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "72",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GFN",
        "lat": "-29.7562",
        "lon": "153.031",
        "name": "Grafton",
        "city": "Grafton",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510680",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "110",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GGG",
        "lat": "32.3874",
        "lon": "-94.7194",
        "name": "Gregg County",
        "city": "Longview",
        "state": "Texas",
        "country": "United States",
        "woeid": "12520015",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10000",
        "elev": "365",
        "icao": "KGGG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GHT",
        "lat": "25.1325",
        "lon": "10.1489",
        "name": "Ghat",
        "city": "Ghat",
        "state": "Awbari",
        "country": "Libya",
        "woeid": "12514652",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "2175",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GIC",
        "lat": "-9.2622",
        "lon": "142.214",
        "name": "Boigu Island",
        "city": "Kubin Village",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510611",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GIL",
        "lat": "35.9192",
        "lon": "74.3358",
        "name": "Gilgit",
        "city": "Gilgit",
        "state": "Northern Areas",
        "country": "Pakistan",
        "woeid": "12515235",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "4770",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GJA",
        "lat": "16.4591",
        "lon": "-85.9034",
        "name": "Guanaja",
        "city": "Guanaja",
        "state": "Islas de la Bahía",
        "country": "Honduras",
        "woeid": "12513359",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3600",
        "elev": "36",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GJL",
        "lat": "36.7942",
        "lon": "5.8769",
        "name": "Taher",
        "city": "Jijel",
        "state": "Jijel",
        "country": "Algeria",
        "woeid": "12510349",
        "tz": "AfricaAlgiers",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GLF",
        "lat": "8.6525",
        "lon": "-83.1828",
        "name": "Golfito",
        "city": "Palmar Sur",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12512432",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "49",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GLH",
        "lat": "33.4819",
        "lon": "-90.9903",
        "name": "Mid Delta",
        "city": "Greenville",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12520935",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7019",
        "elev": "131",
        "icao": "KGLH",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "GMR",
        "lat": "-23.0831",
        "lon": "-134.887",
        "name": "Gambier Is",
        "city": "Gambier Is",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "22504021",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GNU",
        "lat": "59.1218",
        "lon": "-161.588",
        "name": "Alaska",
        "city": "Goodnews Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "15",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GOP",
        "lat": "22.5552",
        "lon": "88.3685",
        "name": "Gorakhpur",
        "city": "Gorakhpur",
        "state": "Bihar",
        "country": "India",
        "woeid": "29160405",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "259",
        "icao": "KGOP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GOQ",
        "lat": "36.8135",
        "lon": "95.3498",
        "name": "Golmud",
        "city": "Golmud",
        "state": "Qinghai",
        "country": "China",
        "woeid": "12512057",
        "tz": "AsiaUrumqi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GRQ",
        "lat": "53.1273",
        "lon": "6.58249",
        "name": "Groningen Eelde",
        "city": "Eelde",
        "state": "Drenthe",
        "country": "Netherlands",
        "woeid": "12515086",
        "tz": "EuropeAmsterdam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "17",
        "icao": "EHGG",
        "direct_flights": "1",
        "carriers": "1",
        "bookingId": "-2145032"
    },
    {
        "code": "GRV",
        "lat": "43.3964",
        "lon": "45.6868",
        "name": "Groznyy",
        "city": "Groznyy",
        "state": "Chechnya",
        "country": "Russia",
        "woeid": "12516015",
        "tz": "EuropeVolgograd",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GRW",
        "lat": "39.0936",
        "lon": "-28.0297",
        "name": "Graciosa",
        "city": "Graciosa Island",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12515444",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "86",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GRY",
        "lat": "66.5439",
        "lon": "-18.0117",
        "name": "Grimsey",
        "city": "Akureyri",
        "state": "Akureyri",
        "country": "Iceland",
        "woeid": "12513443",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2559",
        "elev": "66",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GST",
        "lat": "58.4246",
        "lon": "-135.707",
        "name": "Gustavus",
        "city": "Gustavus",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520042",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "36",
        "icao": "PAGS",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GSY",
        "lat": "53.4533",
        "lon": "-0.2",
        "name": "Binbrook",
        "city": "Market Rasen",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518039",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "374",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GTO",
        "lat": "0.6339",
        "lon": "122.846",
        "name": "Jalaluddin",
        "city": "Gorontalo",
        "state": "Sulawesi Utara",
        "country": "Indonesia",
        "woeid": "12513474",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "60",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GTR",
        "lat": "33.4516",
        "lon": "-88.5876",
        "name": "Golden Triangle",
        "city": "Columbus",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12519926",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6497",
        "elev": "264",
        "icao": "KGTR",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "GTS",
        "lat": "-31.875",
        "lon": "136.081",
        "name": "Australia",
        "city": "Granites",
        "state": "",
        "country": "Australia",
        "woeid": "23424748",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3615",
        "elev": "1106",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GYA",
        "lat": "-10.8276",
        "lon": "-65.3993",
        "name": "Guayaramerin",
        "city": "Guayaramerín",
        "state": "El Beni",
        "country": "Bolivia",
        "woeid": "12523574",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "427",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "GYM",
        "lat": "27.9689",
        "lon": "-110.924",
        "name": "General Jose Maria Yanez in",
        "city": "Guaymas",
        "state": "Sonora",
        "country": "Mexico",
        "woeid": "12514881",
        "tz": "AmericaHermosillo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7710",
        "elev": "88",
        "icao": "MMGM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HAE",
        "lat": "36.2306",
        "lon": "-112.669",
        "name": "Havasupai",
        "city": "Havasupai",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12794857",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KHAE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HAQ",
        "lat": "6.75785",
        "lon": "73.1472",
        "name": "Hanimadu",
        "city": "Hanimaadhoo",
        "state": "Haa Dhaalu",
        "country": "Maldives",
        "woeid": "12468497",
        "tz": "IndianMaldives",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "VRMH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HBT",
        "lat": "23.9176",
        "lon": "45.0811",
        "name": "Saudi Arabia",
        "city": "Hafr Albatin",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "23424938",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7054",
        "elev": "1175",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HCN",
        "lat": "22.0403",
        "lon": "120.735",
        "name": "Hengchun",
        "city": "Hengchun",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "28760738",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HDM",
        "lat": "34.8692",
        "lon": "48.5522",
        "name": "Hamadan",
        "city": "Hamadan",
        "state": "Hamadan",
        "country": "Iran",
        "woeid": "12513727",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7643",
        "elev": "5730",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HDS",
        "lat": "-24.3578",
        "lon": "31.0444",
        "name": "Hoedspruit Afs",
        "city": "Phalaborwa",
        "state": "Limpopo",
        "country": "South Africa",
        "woeid": "12517422",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HEI",
        "lat": "54.153",
        "lon": "8.89555",
        "name": "Heide-Busum",
        "city": "Heide-Buesum",
        "state": "Schleswig-Holstein",
        "country": "Germany",
        "woeid": "22656458",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2362",
        "elev": "7",
        "icao": "KHEI",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HEK",
        "lat": "50.25",
        "lon": "127.3",
        "name": "Heihe",
        "city": "Heihe",
        "state": "Heilongjiang",
        "country": "China",
        "woeid": "12523304",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HFA",
        "lat": "32.8111",
        "lon": "35.0417",
        "name": "U Michaeli",
        "city": "Hefa",
        "state": "H'efa",
        "country": "Israel",
        "woeid": "12513795",
        "tz": "AsiaJerusalem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4183",
        "elev": "28",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HGN",
        "lat": "19.2989",
        "lon": "97.9764",
        "name": "Mae Hongson",
        "city": "Mae Hong Son",
        "state": "Mae Hong Son",
        "country": "Thailand",
        "woeid": "12517764",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5433",
        "elev": "761",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "HHQ",
        "lat": "12.6281",
        "lon": "99.95",
        "name": "Hua Hin",
        "city": "Hua Hin",
        "state": "Kalasin",
        "country": "Thailand",
        "woeid": "12517753",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HHZ",
        "lat": "-18.0363",
        "lon": "-142.616",
        "name": "French Polynesia",
        "city": "Hikueru",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HIL",
        "lat": "19.7192",
        "lon": "-155.082",
        "name": "Shillavo",
        "city": "Shillavo",
        "state": "",
        "country": "Ethiopia",
        "woeid": "23424808",
        "tz": "AfricaAddis_Ababa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HIS",
        "lat": "-20.0543",
        "lon": "148.884",
        "name": "Hayman Island",
        "city": "Hayman Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510688",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "HJJ",
        "lat": "34.668",
        "lon": "104.166",
        "name": "Zhi Jiang",
        "city": "Zhi Jiang",
        "state": "Hunan",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HJR",
        "lat": "24.8496",
        "lon": "79.9367",
        "name": "Khajuraho",
        "city": "Khajuraho",
        "state": "Madhya Pradesh",
        "country": "India",
        "woeid": "12513622",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "713",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "HKB",
        "lat": "63.8667",
        "lon": "-148.967",
        "name": "Healy Lake",
        "city": "Healy Lake",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799776",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2640",
        "elev": "1294",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HKK",
        "lat": "-42.7141",
        "lon": "170.989",
        "name": "Hokitika",
        "city": "Hokitika",
        "state": "West Coast",
        "country": "New Zealand",
        "woeid": "12523121",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4311",
        "elev": "146",
        "icao": "NZHK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HNH",
        "lat": "58.0966",
        "lon": "-135.41",
        "name": "Hoonah",
        "city": "Hoonah",
        "state": "Alaska",
        "country": "United States",
        "woeid": "29388566",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3100",
        "elev": "30",
        "icao": "PAOH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HOB",
        "lat": "32.6929",
        "lon": "-103.215",
        "name": "Lea County",
        "city": "Hobbs",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12520599",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7398",
        "elev": "3659",
        "icao": "KHOB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HOF",
        "lat": "23.9176",
        "lon": "45.0811",
        "name": "Saudi Arabia",
        "city": "Alahsa",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "23424938",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6350",
        "elev": "517",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HON",
        "lat": "44.3833",
        "lon": "-98.2333",
        "name": "Howes",
        "city": "Huron",
        "state": "South Dakota",
        "country": "United States",
        "woeid": "12782876",
        "tz": "AmericaChicago",
        "phone": "605-353-8516",
        "type": "Airports",
        "email": "",
        "url": "http:Huron",
        "runway_length": "7200",
        "elev": "1288",
        "icao": "KHON",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "HOQ",
        "lat": "50.2893",
        "lon": "11.866",
        "name": "Hof-Plauen",
        "city": "Hof",
        "state": "Bavaria",
        "country": "Germany",
        "woeid": "22221018",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2690",
        "elev": "1926",
        "icao": "EDQM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HPH",
        "lat": "20.8192",
        "lon": "106.726",
        "name": "Hai Phong Cat Bi",
        "city": "Häi Phòng",
        "state": "Hai Phong",
        "country": "Vietnam",
        "woeid": "12522913",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "HRT",
        "lat": "54.0486",
        "lon": "-1.25333",
        "name": "Linton-On-Ouse",
        "city": "Harrogate",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "26353284",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6014",
        "elev": "53",
        "icao": "KHRT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HTG",
        "lat": "71.9751",
        "lon": "102.493",
        "name": "Hatanga",
        "city": "Dudinka",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "12516026",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HTN",
        "lat": "40.5",
        "lon": "78.5",
        "name": "Hotan",
        "city": "Hotan",
        "state": "新疆维吾尔自治区",
        "country": "China",
        "woeid": "12714209",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "HUQ",
        "lat": "29.1117",
        "lon": "15.9661",
        "name": "Hon",
        "city": "Houn",
        "state": "Al Jufrah",
        "country": "Libya",
        "woeid": "12514655",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5890",
        "elev": "837",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HVA",
        "lat": "-14.6289",
        "lon": "47.7667",
        "name": "Analalava",
        "city": "Analalava",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "12514687",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "312",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HVD",
        "lat": "48.0167",
        "lon": "91.6333",
        "name": "Mongolia",
        "city": "Khovd",
        "state": "Hovd",
        "country": "Mongolia",
        "woeid": "23424887",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HVN",
        "lat": "41.2709",
        "lon": "-72.89",
        "name": "Tweed New Haven",
        "city": "East Haven",
        "state": "Connecticut",
        "country": "United States",
        "woeid": "12522229",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5600",
        "elev": "13",
        "icao": "KHVN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HVR",
        "lat": "48.5461",
        "lon": "-109.773",
        "name": "Havre City-County",
        "city": "Havre",
        "state": "Montana",
        "country": "United States",
        "woeid": "12520127",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5205",
        "elev": "2588",
        "icao": "KHVR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HYG",
        "lat": "55.205",
        "lon": "-132.822",
        "name": "Alaska",
        "city": "Hydaburg",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HZG",
        "lat": "33.0677",
        "lon": "107.007",
        "name": "Hanzhong",
        "city": "Hanzhong",
        "state": "Shaanxi",
        "country": "China",
        "woeid": "12512068",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "HZH",
        "lat": "32.7833",
        "lon": "106.667",
        "name": "Liping",
        "city": "Liping City",
        "state": "陝西省",
        "country": "China",
        "woeid": "12713981",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IAA",
        "lat": "67.4538",
        "lon": "86.4912",
        "name": "Russia",
        "city": "Igarka",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IBA",
        "lat": "7.3608",
        "lon": "3.9781",
        "name": "Ibadan",
        "city": "Ibadan",
        "state": "Oyo",
        "country": "Nigeria",
        "woeid": "12515065",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4537",
        "elev": "769",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ICI",
        "lat": "-17.7518",
        "lon": "-179.307",
        "name": "Cicia",
        "city": "Cicia",
        "state": "Eastern",
        "country": "Fiji",
        "woeid": "12512810",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "13",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IEG",
        "lat": "52.1282",
        "lon": "15.7975",
        "name": "Babimost",
        "city": "Babimost",
        "state": "Lubusz",
        "country": "Poland",
        "woeid": "12515279",
        "tz": "EuropeWarsaw",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EPZG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IFJ",
        "lat": "66.0548",
        "lon": "-23.1301",
        "name": "Isafjordur",
        "city": "Isafjordur",
        "state": "Isafjordur",
        "country": "Iceland",
        "woeid": "12513444",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IGG",
        "lat": "59.3167",
        "lon": "-155.9",
        "name": "Igiugig",
        "city": "Igiugig",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12520567",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2700",
        "elev": "110",
        "icao": "PAIG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IGR",
        "lat": "-25.7422",
        "lon": "-54.4753",
        "name": "Cataratas del Iguazu",
        "city": "Puerto Esperanza",
        "state": "Misiones",
        "country": "Argentina",
        "woeid": "12510472",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10827",
        "elev": "915",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "IIL",
        "lat": "32.4207",
        "lon": "53.6824",
        "name": "Iran",
        "city": "Ilaam",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IKO",
        "lat": "52.9389",
        "lon": "-168.868",
        "name": "Nikolski",
        "city": "Nikolski",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2459970",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "73",
        "icao": "PAKO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IKS",
        "lat": "71.6969",
        "lon": "128.908",
        "name": "Tiksi",
        "city": "Tiksi",
        "state": "",
        "country": "Russia",
        "woeid": "12517006",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ILP",
        "lat": "-22.609",
        "lon": "167.478",
        "name": "Moue",
        "city": "Vao",
        "state": "Sud",
        "country": "New Caledonia",
        "woeid": "12515039",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3608",
        "elev": "315",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ILY",
        "lat": "55.6835",
        "lon": "-6.24856",
        "name": "Islay",
        "city": "Glenegedale",
        "state": "Scotland",
        "country": "United Kingdom",
        "woeid": "22483897",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4458",
        "elev": "58",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "ILZ",
        "lat": "49.2333",
        "lon": "18.6179",
        "name": "Kotesovo",
        "city": "Bytča",
        "state": "Zilinsky",
        "country": "Slovakia",
        "woeid": "12514614",
        "tz": "EuropePrague",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "IMK",
        "lat": "29.9667",
        "lon": "81.8333",
        "name": "Nepal",
        "city": "Simikot",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "23424911",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "INL",
        "lat": "48.5661",
        "lon": "-93.3978",
        "name": "Falls",
        "city": "International Falls",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12519705",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6508",
        "elev": "1185",
        "icao": "KINL",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "INU",
        "lat": "-0.54855",
        "lon": "166.921",
        "name": "Nauru",
        "city": "Yaren",
        "state": "Yaren",
        "country": "Nauru",
        "woeid": "12523130",
        "tz": "PacificNauru",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5620",
        "elev": "19",
        "icao": "ANYN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IOA",
        "lat": "39.6967",
        "lon": "20.8258",
        "name": "Ioannina",
        "city": "Ioannina",
        "state": "Ipiros",
        "country": "Greece",
        "woeid": "12513290",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "1600",
        "icao": "LGIO",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "ION",
        "lat": "1.5953",
        "lon": "18.0522",
        "name": "Impfondo",
        "city": "Impfondo",
        "state": "Likuoala",
        "country": "Congo",
        "woeid": "12511946",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1099",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IPA",
        "lat": "-18.8134",
        "lon": "169.166",
        "name": "Ipota",
        "city": "Ipota",
        "state": "Tafea",
        "country": "Vanuatu",
        "woeid": "20069887",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3050",
        "elev": "36",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IPI",
        "lat": "0.8611",
        "lon": "-77.6681",
        "name": "San Luis",
        "city": "Ipiales",
        "state": "Narino",
        "country": "Colombia",
        "woeid": "12512410",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "9762",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IPL",
        "lat": "32.8332",
        "lon": "-115.57",
        "name": "Imperial County",
        "city": "Imperial",
        "state": "California",
        "country": "United States",
        "woeid": "12520284",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5304",
        "elev": "-56",
        "icao": "KIPL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IPN",
        "lat": "-19.47",
        "lon": "-42.4875",
        "name": "Usiminas",
        "city": "Santana do Paraíso",
        "state": "Minas Gerais",
        "country": "Brazil",
        "woeid": "12511364",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6575",
        "elev": "784",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IPT",
        "lat": "41.2441",
        "lon": "-76.9276",
        "name": "Williamsport-Lycoming County",
        "city": "Montoursville",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12522464",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6449",
        "elev": "529",
        "icao": "KIPT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IQM",
        "lat": "38.1333",
        "lon": "85.5333",
        "name": "Qiemo",
        "city": "Qiemo",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "2143746",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IRC",
        "lat": "65.8245",
        "lon": "-144.063",
        "name": "Circle",
        "city": "Circle",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2380412",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2730",
        "elev": "598",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IRJ",
        "lat": "-29.3847",
        "lon": "-66.7833",
        "name": "Capitan Vicente A Almonacid",
        "city": "Chamical",
        "state": "La Rioja",
        "country": "Argentina",
        "woeid": "12510468",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9383",
        "elev": "1437",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ISC",
        "lat": "49.9167",
        "lon": "-6.3",
        "name": "Saint Mary's",
        "city": "Rams Valley",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12523133",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1870",
        "elev": "116",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ISO",
        "lat": "35.3243",
        "lon": "-77.6115",
        "name": "Kinston Jetport Stallings",
        "city": "Kinston",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12520475",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "94",
        "icao": "KISO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IUE",
        "lat": "-19.0787",
        "lon": "-169.926",
        "name": "Niue",
        "city": "Niue Island",
        "state": "",
        "country": "Niue",
        "woeid": "23424904",
        "tz": "PacificNiue",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5400",
        "elev": "192",
        "icao": "NIUE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IWD",
        "lat": "46.5208",
        "lon": "-90.1344",
        "name": "Gogebic-Iron County",
        "city": "Ironwood",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520317",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "1230",
        "icao": "KIWD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IXG",
        "lat": "15.8581",
        "lon": "74.6189",
        "name": "Belgaum",
        "city": "Kangrali",
        "state": "Karnataka",
        "country": "India",
        "woeid": "12513546",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5899",
        "elev": "2488",
        "icao": "VABM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IXW",
        "lat": "22.8167",
        "lon": "86.1667",
        "name": "Sonari",
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "country": "India",
        "woeid": "12513608",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3480",
        "elev": "459",
        "icao": "VEJS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IXY",
        "lat": "23.1119",
        "lon": "70.1011",
        "name": "Kandla",
        "city": "Gandhidham",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513616",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "96",
        "icao": "VAKE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IYK",
        "lat": "35.6606",
        "lon": "-117.816",
        "name": "Inyokern",
        "city": "Inyokern",
        "state": "California",
        "country": "United States",
        "woeid": "12520307",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7337",
        "elev": "2457",
        "icao": "KIYK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "IZO",
        "lat": "35.4131",
        "lon": "132.887",
        "name": "Izumo",
        "city": "Hikawa-cho",
        "state": "Shimane Prefecture",
        "country": "Japan",
        "woeid": "12513969",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JAL",
        "lat": "19.5833",
        "lon": "-96.8333",
        "name": "Jalapa",
        "city": "Jalapa",
        "state": "",
        "country": "Mexico",
        "woeid": "12524377",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "2871",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "JCU",
        "lat": "35.8879",
        "lon": "-5.31601",
        "name": "Ceuta Heliport",
        "city": "Ceuta",
        "state": "Ciudad Autónoma de Ceuta",
        "country": "Spain",
        "woeid": "20089013",
        "tz": "AfricaCeuta",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JEF",
        "lat": "38.593",
        "lon": "-92.1652",
        "name": "Jefferson City Memorial",
        "city": "Holts Summit",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12520356",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KJEF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JGA",
        "lat": "22.4633",
        "lon": "70.0119",
        "name": "Jamnagar",
        "city": "Jamnagar",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513607",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "69",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "JGC",
        "lat": "35.9621",
        "lon": "-112.133",
        "name": "Grand Canyon Heliport",
        "city": "Williams",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12523301",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Heliport2",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "6580",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JIK",
        "lat": "37.6176",
        "lon": "26.1614",
        "name": "Ikaria Island",
        "city": "Evdilos",
        "state": "Voreio Aigaio",
        "country": "Greece",
        "woeid": "12523353",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JKL",
        "lat": "37.5917",
        "lon": "-83.315",
        "name": "Julian Carroll",
        "city": "Jackson",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "12520407",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KJKL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JLN",
        "lat": "37.147",
        "lon": "-94.5019",
        "name": "Joplin",
        "city": "Webb City",
        "state": "Missouri",
        "country": "United States",
        "woeid": "12520400",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6503",
        "elev": "981",
        "icao": "KJLN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JNX",
        "lat": "37.1",
        "lon": "25.3833",
        "name": "Naxos",
        "city": "Naxos",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12520392",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KJNX",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JNZ",
        "lat": "39.1",
        "lon": "121.717",
        "name": "Jinzhou",
        "city": "Jinzhou",
        "state": "Liaoning",
        "country": "China",
        "woeid": "2147999",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JOI",
        "lat": "-26.223",
        "lon": "-48.8046",
        "name": "Lauro Carneiro de Loyola",
        "city": "Joinville",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511203",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5381",
        "elev": "12",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "JOL",
        "lat": "6.0553",
        "lon": "121.009",
        "name": "Jolo",
        "city": "Jolo",
        "state": "Autonomous Region in Muslim Mind",
        "country": "Philippines",
        "woeid": "12515623",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4429",
        "elev": "79",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "JSA",
        "lat": "26.8873",
        "lon": "70.8636",
        "name": "Jaisalmer",
        "city": "Jaisalmer",
        "state": "Rajasthan",
        "country": "India",
        "woeid": "12513604",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9055",
        "elev": "887",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JSR",
        "lat": "23.1833",
        "lon": "89.1644",
        "name": "Jessore",
        "city": "Jessore",
        "state": "Khulna",
        "country": "Bangladesh",
        "woeid": "12510891",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "JSY",
        "lat": "37.4236",
        "lon": "24.95",
        "name": "Syros Island",
        "city": "Ano Siros",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12523331",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JUJ",
        "lat": "-24.3933",
        "lon": "-65.0889",
        "name": "Jujuy",
        "city": "Santa Catalina",
        "state": "Jujuy",
        "country": "Argentina",
        "woeid": "12510508",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9678",
        "elev": "3022",
        "icao": "SASJ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JUM",
        "lat": "40.6152",
        "lon": "-83.7891",
        "name": "Jumla",
        "city": "Jumla",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "2269118",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1631",
        "elev": "8500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JVA",
        "lat": "-23.3548",
        "lon": "43.6631",
        "name": "Toliara",
        "city": "Ankavandra",
        "state": "Toliara",
        "country": "Madagascar",
        "woeid": "1364703",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "426",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JYR",
        "lat": "28.7244",
        "lon": "57.6683",
        "name": "Jiroft",
        "city": "Jiroft",
        "state": "Kerman",
        "country": "Iran",
        "woeid": "12513754",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KJYR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "JYV",
        "lat": "62.401",
        "lon": "25.6748",
        "name": "Jyvaskyla",
        "city": "Tikkakoski",
        "state": "Western Finland",
        "country": "Finland",
        "woeid": "12512773",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "459",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "KAC",
        "lat": "37.0333",
        "lon": "41.0833",
        "name": "Kamishly",
        "city": "Al Qamishli",
        "state": "Al H'asakah",
        "country": "Syria",
        "woeid": "12517703",
        "tz": "AsiaDamascus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9020",
        "elev": "1480",
        "icao": "OSKL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KAD",
        "lat": "10.695",
        "lon": "7.3194",
        "name": "Kaduna",
        "city": "Afaka",
        "state": "Kaduna",
        "country": "Nigeria",
        "woeid": "12515069",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9840",
        "elev": "2000",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KAT",
        "lat": "-35.0714",
        "lon": "173.277",
        "name": "Kaitaia Aerodrome",
        "city": "Kaitaia",
        "state": "Northland",
        "country": "New Zealand",
        "woeid": "12515157",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "270",
        "icao": "NZKT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KCA",
        "lat": "41.5667",
        "lon": "82.7333",
        "name": "Kuqa",
        "city": "Kuqa",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "2143659",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KCF",
        "lat": "30.4419",
        "lon": "69.3597",
        "name": "Pakistan",
        "city": "Kadanwari",
        "state": "",
        "country": "Pakistan",
        "woeid": "23424922",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "239",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KDI",
        "lat": "-4.0811",
        "lon": "122.417",
        "name": "Wolter Monginsidi",
        "city": "Kendari",
        "state": "Sulawesi Tenggara",
        "country": "Indonesia",
        "woeid": "12513524",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "164",
        "icao": "",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "KDL",
        "lat": "59.0013",
        "lon": "22.8181",
        "name": "Kardla East",
        "city": "Kerdlya",
        "state": "Hiiumaa",
        "country": "Estonia",
        "woeid": "12512735",
        "tz": "EuropeTallinn",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EEKA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KDU",
        "lat": "35.3",
        "lon": "75.6333",
        "name": "Skardu",
        "city": "Skardu",
        "state": "Jammu and Kashmir",
        "country": "Pakistan",
        "woeid": "12515269",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6600",
        "elev": "7600",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KEB",
        "lat": "41.0833",
        "lon": "-92.4583",
        "name": "Nanwalek",
        "city": "Nanwalek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "23417038",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "27",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KEM",
        "lat": "65.7786",
        "lon": "24.5814",
        "name": "Kemi",
        "city": "Kemi",
        "state": "Lapland",
        "country": "Finland",
        "woeid": "12512776",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "61",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KFA",
        "lat": "16.5889",
        "lon": "-11.4053",
        "name": "Kiffa",
        "city": "Kiffa",
        "state": "Assaba",
        "country": "Mauritania",
        "woeid": "12514802",
        "tz": "AfricaNouakchott",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5249",
        "elev": "423",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KFP",
        "lat": "54.85",
        "lon": "-163.417",
        "name": "False Pass",
        "city": "False Pass",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2402200",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KGC",
        "lat": "-35.7158",
        "lon": "137.521",
        "name": "Kingscote",
        "city": "Kingscote",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510708",
        "tz": "AustraliaAdelaide",
        "phone": "08 8553 2015",
        "type": "Airports",
        "email": "",
        "url": "http:www.kangarooisland.sa.gov.ausitepage.cfm?u=213sitepag",
        "runway_length": "4600",
        "elev": "24",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KGE",
        "lat": "5.88333",
        "lon": "116.75",
        "name": "Kagau",
        "city": "Kagau",
        "state": "Sabah",
        "country": "Solomon Islands",
        "woeid": "12488244",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KHM",
        "lat": "11.9848",
        "lon": "104.984",
        "name": "Khamti",
        "city": "Khamti",
        "state": "",
        "country": "Myanmar",
        "woeid": "23424763",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "600",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KHY",
        "lat": "38.5556",
        "lon": "44.9958",
        "name": "Iran",
        "city": "Khoy",
        "state": "Azarbayjan-e Gharbi",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KHZ",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Kauehi",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KIK",
        "lat": "35.4681",
        "lon": "44.3536",
        "name": "Kirkuk",
        "city": "Kirkuk",
        "state": "At Ta'mim",
        "country": "Iraq",
        "woeid": "12513909",
        "tz": "AsiaBaghdad",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9250",
        "elev": "1148",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KIT",
        "lat": "36.261",
        "lon": "22.9993",
        "name": "Kithira",
        "city": "Potamos Kythiron",
        "state": "Attiki",
        "country": "Greece",
        "woeid": "12513299",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "950",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKB",
        "lat": "58.1869",
        "lon": "-152.375",
        "name": "Kitoi Seaplane Base",
        "city": "Kitoi Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524650",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKC",
        "lat": "16.465",
        "lon": "102.791",
        "name": "Khon Kaen",
        "city": "Khon Kaen",
        "state": "Khon Kaen",
        "country": "Thailand",
        "woeid": "12517755",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "629",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKD",
        "lat": "-8.94828",
        "lon": "148.314",
        "name": "Northern",
        "city": "Kokoda",
        "state": "Northern",
        "country": "Papua New Guinea",
        "woeid": "2346584",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2800",
        "elev": "1269",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKE",
        "lat": "-35.2584",
        "lon": "173.908",
        "name": "Bay of Islands",
        "city": "Kerikeri",
        "state": "Northland",
        "country": "New Zealand",
        "woeid": "12515158",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3900",
        "elev": "492",
        "icao": "NZKK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKI",
        "lat": "60.9057",
        "lon": "-161.424",
        "name": "Akiachak",
        "city": "Akiachak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2352459",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "25",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KKR",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Kaukura Atoll",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2300",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KKU",
        "lat": "58.8167",
        "lon": "-158.55",
        "name": "Ekuk",
        "city": "Clarks Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524622",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1200",
        "elev": "30",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KLH",
        "lat": "16.6667",
        "lon": "74.3333",
        "name": "Kolhapur",
        "city": "Kolhapur",
        "state": "Maharashtra",
        "country": "India",
        "woeid": "29230260",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "2001",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KLX",
        "lat": "37.0678",
        "lon": "22.0267",
        "name": "Kalamata",
        "city": "Kalamae",
        "state": "Peloponnisos",
        "country": "Greece",
        "woeid": "12513292",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9894",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KMC",
        "lat": "57.1333",
        "lon": "-153.2",
        "name": "King Khalid Military",
        "city": "King Khalid Mil. City",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2433701",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KME",
        "lat": "-2.4614",
        "lon": "28.9092",
        "name": "Kamembe",
        "city": "Kamembe",
        "state": "Province de l'Ouest",
        "country": "Rwanda",
        "woeid": "12517315",
        "tz": "AfricaKigali",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4944",
        "elev": "5220",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KMS",
        "lat": "6.7172",
        "lon": "-1.5911",
        "name": "Kumasi",
        "city": "New Tafo",
        "state": "Ashanti",
        "country": "Ghana",
        "woeid": "12513023",
        "tz": "AfricaAccra",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "942",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KMV",
        "lat": "23.1869",
        "lon": "94.0556",
        "name": "Kalemyo",
        "city": "Kalemyo",
        "state": "Sagaing",
        "country": "Myanmar",
        "woeid": "12510915",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "499",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KMY",
        "lat": "57",
        "lon": "-154.167",
        "name": "Moser Bay",
        "city": "Moser Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799674",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KND",
        "lat": "-2.9225",
        "lon": "25.9139",
        "name": "Kindu",
        "city": "Kindu",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511962",
        "tz": "AfricaLubumbashi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "1631",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KNF",
        "lat": "51.3667",
        "lon": "-0.283333",
        "name": "Kings Lynn",
        "city": "Kings Lynn",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "25093",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KNG",
        "lat": "-2.29861",
        "lon": "134.059",
        "name": "West Irian Jaya",
        "city": "Kaimana",
        "state": "West Irian Jaya",
        "country": "Indonesia",
        "woeid": "28350157",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KNK",
        "lat": "59.4333",
        "lon": "-154.85",
        "name": "Kakhonak",
        "city": "Kakhonak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2430469",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "50",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KNS",
        "lat": "-39.8797",
        "lon": "143.881",
        "name": "King Island",
        "city": "King Island",
        "state": "Tasmania",
        "country": "Australia",
        "woeid": "12510706",
        "tz": "AustraliaHobart",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "-10",
        "elev": "5200",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KOP",
        "lat": "17.404",
        "lon": "104.771",
        "name": "Nakhon Phanom",
        "city": "Nakhon Phanom",
        "state": "Nakhon Phanom",
        "country": "Thailand",
        "woeid": "12517766",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "579",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KOU",
        "lat": "21.3602",
        "lon": "-157.919",
        "name": "Koulamoutou",
        "city": "Koulamoutou",
        "state": "Ogooue-Lolo",
        "country": "Gabon",
        "woeid": "1324072",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6010",
        "elev": "1070",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KOY",
        "lat": "47.902",
        "lon": "-112.265",
        "name": "Olga Bay",
        "city": "Olga Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KOZ",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Ouzinkie",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KPB",
        "lat": "56.3502",
        "lon": "-133.622",
        "name": "Point Baker Seaplane Base",
        "city": "Point Baker",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523156",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KPC",
        "lat": "65.2544",
        "lon": "-166.856",
        "name": "Port Clarence Coast Guard Station",
        "city": "Brevig Mission",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12521440",
        "tz": "AmericaNome",
        "phone": "907-642-3844",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "10",
        "icao": "PAPC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KPO",
        "lat": "35.9847",
        "lon": "129.419",
        "name": "Pohang",
        "city": "Pohang-Si",
        "state": "Gyeongsangbuk-Do",
        "country": "South Korea",
        "woeid": "12514216",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "70",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KPR",
        "lat": "58.4833",
        "lon": "-152.583",
        "name": "Port Williams",
        "city": "Port Williams",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12485470",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KPV",
        "lat": "55.9122",
        "lon": "-159.154",
        "name": "Alaska",
        "city": "Perryville",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "25",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KPY",
        "lat": "58.743",
        "lon": "-154.96",
        "name": "Alaska",
        "city": "Port Bailey",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KQA",
        "lat": "54.1271",
        "lon": "-165.889",
        "name": "Akutan",
        "city": "Akutan",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12518551",
        "tz": "AmericaAdak",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KRF",
        "lat": "63.0478",
        "lon": "17.7633",
        "name": "Kramfors",
        "city": "Nyland",
        "state": "Vasternorrland",
        "country": "Sweden",
        "woeid": "23322390",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KRP",
        "lat": "56.3028",
        "lon": "9.1141",
        "name": "Karup",
        "city": "Kårup",
        "state": "Midtjylland",
        "country": "Denmark",
        "woeid": "12512592",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.karup-airport.dk",
        "runway_length": "9623",
        "elev": "170",
        "icao": "EKKA",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KRY",
        "lat": "45.5726",
        "lon": "84.8891",
        "name": "Karamay",
        "city": "Karamay",
        "state": "Xinjiang",
        "country": "China",
        "woeid": "12512107",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KSL",
        "lat": "15.3833",
        "lon": "36.3236",
        "name": "Kassala",
        "city": "Kassala",
        "state": "Kassala",
        "country": "Sudan",
        "woeid": "12517600",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "1667",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KSO",
        "lat": "40.4508",
        "lon": "21.2733",
        "name": "Kastoria",
        "city": "Argos Orestiko",
        "state": "Dytiki Makedonia",
        "country": "Greece",
        "woeid": "12513296",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5938",
        "elev": "2183",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KSZ",
        "lat": "61.2165",
        "lon": "46.6936",
        "name": "Kotlas Southeast",
        "city": "Vel'sk",
        "state": "Arkhangelrskaya Oblast",
        "country": "Russia",
        "woeid": "12516238",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KTE",
        "lat": "4.5378",
        "lon": "103.428",
        "name": "Kerteh",
        "city": "Kerteh",
        "state": "Terengganu",
        "country": "Malaysia",
        "woeid": "12514994",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "18",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KUG",
        "lat": "-31.875",
        "lon": "136.081",
        "name": "Australia",
        "city": "Kubin Island",
        "state": "",
        "country": "Australia",
        "woeid": "23424748",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KUK",
        "lat": "60.8953",
        "lon": "-162.517",
        "name": "Kasigluk",
        "city": "Kasigluk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2430788",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2463",
        "elev": "40",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KUU",
        "lat": "31.8729",
        "lon": "77.1482",
        "name": "Bhuntar",
        "city": "Bhuntar",
        "state": "Himachal Pradesh",
        "country": "India",
        "woeid": "12513555",
        "tz": "AsiaKolkata",
        "phone": "91-1902-65037",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "3573",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KUV",
        "lat": "35.9017",
        "lon": "126.618",
        "name": "Gunsan",
        "city": "Gunsan-Si",
        "state": "Jeollabuk-Do",
        "country": "South Korea",
        "woeid": "12514209",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "29",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KVC",
        "lat": "55.0667",
        "lon": "-162.317",
        "name": "King Cove",
        "city": "King Cove",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2432507",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "148",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KWP",
        "lat": "57.7696",
        "lon": "-153.554",
        "name": "Village Seaplane Base-West Point",
        "city": "West Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523157",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KWT",
        "lat": "60.8",
        "lon": "-161.45",
        "name": "Kwethluk",
        "city": "Kwethluk",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2434059",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "28",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "KWZ",
        "lat": "-10.7667",
        "lon": "25.5069",
        "name": "Kolwezi",
        "city": "Kolwezi",
        "state": "Democratic Republic of",
        "country": "Congo",
        "woeid": "12511968",
        "tz": "AfricaLubumbashi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5741",
        "elev": "5007",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KXA",
        "lat": "55.5",
        "lon": "-132.5",
        "name": "Kasaan SPB",
        "city": "Kasaan",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2430774",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KXF",
        "lat": "-17.3167",
        "lon": "179.383",
        "name": "Koro Island",
        "city": "Koro Island",
        "state": "Northern",
        "country": "Fiji",
        "woeid": "1062932",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2515",
        "elev": "358",
        "icao": "NFNO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KYK",
        "lat": "57.5645",
        "lon": "-154.454",
        "name": "Karluk",
        "city": "Kodiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523158",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "137",
        "icao": "PAKY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KYS",
        "lat": "14.4319",
        "lon": "-11.4397",
        "name": "Kayes",
        "city": "Kayes",
        "state": "Kayes",
        "country": "Mali",
        "woeid": "12514775",
        "tz": "AfricaBamako",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3822",
        "elev": "154",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KYZ",
        "lat": "51.6636",
        "lon": "94.1494",
        "name": "Tyva",
        "city": "Kyzyl",
        "state": "Tyva",
        "country": "Russia",
        "woeid": "2346879",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "KZS",
        "lat": "36.1355",
        "lon": "29.5754",
        "name": "Kastelorizo",
        "city": "Kastelorizo",
        "state": "New York",
        "country": "Greece",
        "woeid": "12523354",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LAI",
        "lat": "48.756",
        "lon": "-3.47109",
        "name": "Servel",
        "city": "Lannion",
        "state": "Brittany",
        "country": "France",
        "woeid": "12523815",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4708",
        "elev": "292",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LAK",
        "lat": "68.2332",
        "lon": "-134.96",
        "name": "Aklavik",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524046",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "35",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LAQ",
        "lat": "32.76",
        "lon": "21.7616",
        "name": "Al Bayda'",
        "city": "Beida",
        "state": "Al Jabal al Akhdar",
        "country": "Libya",
        "woeid": "1352570",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11981",
        "elev": "2157",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LBE",
        "lat": "40.2728",
        "lon": "-79.4056",
        "name": "Westmoreland County",
        "city": "Latrobe",
        "state": "Pennsylvania",
        "country": "United States",
        "woeid": "12522400",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5501",
        "elev": "1161",
        "icao": "KLBE",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "LBF",
        "lat": "41.1333",
        "lon": "-100.705",
        "name": "Lee Bird Field",
        "city": "North Platte",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12520609",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8000",
        "elev": "2779",
        "icao": "KLBF",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "LBJ",
        "lat": "-8.51667",
        "lon": "119.883",
        "name": "Mutiara",
        "city": "Ende",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "12523308",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "295",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LCH",
        "lat": "30.1235",
        "lon": "-93.2198",
        "name": "Lake Charles",
        "city": "Lake Charles",
        "state": "Louisiana",
        "country": "United States",
        "woeid": "12520523",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "15",
        "icao": "KLCH",
        "direct_flights": "1",
        "carriers": "5"
    },
    {
        "code": "LCX",
        "lat": "19.3542",
        "lon": "-99.2925",
        "name": "Longyan",
        "city": "Longyan",
        "state": "Fujian",
        "country": "China",
        "woeid": "2139971",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LDE",
        "lat": "43.1857",
        "lon": "0.00346",
        "name": "Ossun",
        "city": "Juillan",
        "state": "Midi-Pyrenees",
        "country": "France",
        "woeid": "12512948",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1260",
        "icao": "LFBT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LDG",
        "lat": "64.9029",
        "lon": "45.708",
        "name": "Leshukonskoye",
        "city": "Leshukonskoye",
        "state": "Arkhangelrskaya Oblast",
        "country": "Russia",
        "woeid": "12516346",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LDN",
        "lat": "27.3",
        "lon": "86.75",
        "name": "Lamidanda",
        "city": "Lamidanda",
        "state": "Central",
        "country": "Nepal",
        "woeid": "22662771",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1770",
        "elev": "3500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LDU",
        "lat": "5.0319",
        "lon": "118.324",
        "name": "Lahad Datu",
        "city": "Lahad Datu",
        "state": "Sabah",
        "country": "Malaysia",
        "woeid": "12514999",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "45",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LEV",
        "lat": "37.2127",
        "lon": "-83.6199",
        "name": "Bureta",
        "city": "Bureta",
        "state": "",
        "country": "Fiji",
        "woeid": "23424813",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2543",
        "elev": "11",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LGI",
        "lat": "23.1794",
        "lon": "-75.0906",
        "name": "Deadmans Cay",
        "city": "Deadmans Cay",
        "state": "Long Island",
        "country": "Bahamas",
        "woeid": "12510865",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "9",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "LGP",
        "lat": "13.1578",
        "lon": "123.731",
        "name": "Legazpi",
        "city": "Daraga",
        "state": "Bicol Region",
        "country": "Philippines",
        "woeid": "12515627",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6476",
        "elev": "66",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LGQ",
        "lat": "0.1",
        "lon": "-76.8833",
        "name": "Lago Agrio",
        "city": "Lago Agrio",
        "state": "Sucumbios",
        "country": "Ecuador",
        "woeid": "12523610",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6085",
        "elev": "980",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LHG",
        "lat": "-29.4531",
        "lon": "147.981",
        "name": "New South Wales",
        "city": "Lightning Ridge",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "2344700",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "540",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LIW",
        "lat": "19.6886",
        "lon": "97.2169",
        "name": "Loikaw",
        "city": "Loi-kaw",
        "state": "Kayan State",
        "country": "Myanmar",
        "woeid": "12510921",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "2940",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LKA",
        "lat": "-8.34641",
        "lon": "122.979",
        "name": "Larantuka",
        "city": "Larantuka",
        "state": "Nusa Tengarra Timur",
        "country": "Indonesia",
        "woeid": "1047732",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LKB",
        "lat": "-18.2",
        "lon": "178.833",
        "name": "Lakemba Island",
        "city": "Lakeba",
        "state": "Eastern",
        "country": "Fiji",
        "woeid": "12490030",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2495",
        "elev": "280",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LKG",
        "lat": "4.20388",
        "lon": "34.3495",
        "name": "Lokichoggio",
        "city": "Lokichoggio",
        "state": "Rift Valley",
        "country": "Kenya",
        "woeid": "1528041",
        "tz": "AfricaNairobi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LLF",
        "lat": "26.2333",
        "lon": "111.617",
        "name": "Lingling",
        "city": "LingLing",
        "state": "Hunan",
        "country": "China",
        "woeid": "12512124",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LMA",
        "lat": "63.8825",
        "lon": "-152.313",
        "name": "Lake Minchumina",
        "city": "Lake Minchumina",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2435032",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "684",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LMC",
        "lat": "3.31667",
        "lon": "-73.9",
        "name": "Lamacarena",
        "city": "San Juan de Arama",
        "state": "Meta",
        "country": "Colombia",
        "woeid": "12524487",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3099",
        "elev": "1500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LMN",
        "lat": "2.91165",
        "lon": "112.609",
        "name": "Sarawak",
        "city": "Limbang",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "2346305",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1412",
        "elev": "70",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LNJ",
        "lat": "25.1902",
        "lon": "101.824",
        "name": "Yunnan",
        "city": "Lincang",
        "state": "Yunnan",
        "country": "China",
        "woeid": "12578018",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LNV",
        "lat": "-3.06667",
        "lon": "152.617",
        "name": "Gerrit Denys Island",
        "city": "Lihir Island",
        "state": "New Ireland",
        "country": "Papua New Guinea",
        "woeid": "12498872",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1900",
        "elev": "300",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LOH",
        "lat": "-3.96911",
        "lon": "-79.2116",
        "name": "Loja",
        "city": "Loja",
        "state": "Loja",
        "country": "Ecuador",
        "woeid": "12523618",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5576",
        "elev": "4061",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LOU",
        "lat": "38.2228",
        "lon": "-85.6669",
        "name": "Bowman Field",
        "city": "Louisville",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "12518909",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KLOU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LOV",
        "lat": "26.9561",
        "lon": "-101.467",
        "name": "Monclova",
        "city": "Frontera",
        "state": "Coahuila de Zaragoza",
        "country": "Mexico",
        "woeid": "12514922",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4784",
        "elev": "1850",
        "icao": "MMMV",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LPD",
        "lat": "-1.3",
        "lon": "-69.7167",
        "name": "La Pedrera",
        "city": "La Pedrera",
        "state": "Amazonas",
        "country": "Colombia",
        "woeid": "12524489",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4750",
        "elev": "250",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LPK",
        "lat": "52.6194",
        "lon": "39.6597",
        "name": "Lipeck",
        "city": "Lipetsk",
        "state": "Lipetskaya Oblast",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LPP",
        "lat": "61.0465",
        "lon": "28.1536",
        "name": "Lappeenranta",
        "city": "Lappeenranta",
        "state": "Southern Finland",
        "country": "Finland",
        "woeid": "12512782",
        "tz": "EuropeHelsinki",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "349",
        "icao": "EFLP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LPS",
        "lat": "48.4855",
        "lon": "-122.936",
        "name": "Lopez Island",
        "city": "Lopez Island",
        "state": "Washington",
        "country": "United States",
        "woeid": "29387798",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "200",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LPT",
        "lat": "18.2742",
        "lon": "99.5072",
        "name": "Lampang",
        "city": "Lampang",
        "state": "Lampang",
        "country": "Thailand",
        "woeid": "12517760",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4890",
        "elev": "794",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LPY",
        "lat": "45.0757",
        "lon": "3.76327",
        "name": "Loudes",
        "city": "Le Puy",
        "state": "Auvergne",
        "country": "France",
        "woeid": "12512927",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4757",
        "elev": "2730",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LSH",
        "lat": "22.9767",
        "lon": "97.7558",
        "name": "Lashio",
        "city": "Lashio",
        "state": "Shan State",
        "country": "Myanmar",
        "woeid": "12510920",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5252",
        "elev": "2450",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LSS",
        "lat": "15.8686",
        "lon": "-61.5778",
        "name": "Terre-De-Haut",
        "city": "Trois-Rivières",
        "state": "Basse-Terre",
        "country": "Guadeloupe",
        "woeid": "12513279",
        "tz": "AmericaGuadeloupe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LSY",
        "lat": "-28.8249",
        "lon": "153.259",
        "name": "Lismore",
        "city": "Lismore",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510718",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3930",
        "elev": "35",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LTD",
        "lat": "30.147",
        "lon": "9.50288",
        "name": "Ghadames",
        "city": "Ghadames",
        "state": "Ghadamis",
        "country": "Libya",
        "woeid": "1352905",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5740",
        "elev": "1070",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LTI",
        "lat": "44.9167",
        "lon": "95.4167",
        "name": "Altay",
        "city": "Altai",
        "state": "Govi-Altay",
        "country": "Mongolia",
        "woeid": "2265164",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LTT",
        "lat": "43.2037",
        "lon": "6.47477",
        "name": "La Mole",
        "city": "Grimaud",
        "state": "Provence-alpes-cote d'Azur",
        "country": "France",
        "woeid": "12512904",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.st-tropez-airport.com",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LTX",
        "lat": "0.933333",
        "lon": "-78.6167",
        "name": "Latacunga",
        "city": "Latacunga",
        "state": "Cotopaxi",
        "country": "Ecuador",
        "woeid": "375752",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LUA",
        "lat": "27.6833",
        "lon": "86.7333",
        "name": "Lukla",
        "city": "Solukhumbu",
        "state": "Central",
        "country": "Nepal",
        "woeid": "12523168",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1476",
        "elev": "9100",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LUM",
        "lat": "24.4833",
        "lon": "98.5167",
        "name": "Mangshi",
        "city": "Luxi",
        "state": "Yunnan",
        "country": "China",
        "woeid": "2160712",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KLUM",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LUO",
        "lat": "-11.791",
        "lon": "19.9046",
        "name": "Luena",
        "city": "Luena",
        "state": "Moxico",
        "country": "Angola",
        "woeid": "1261966",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "4360",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LUP",
        "lat": "21.2077",
        "lon": "-156.976",
        "name": "Kalaupapa",
        "city": "Kalaupapa",
        "state": "Hawaii",
        "country": "United States",
        "woeid": "29387738",
        "tz": "PacificHonolulu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2760",
        "elev": "26",
        "icao": "PHLU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LUR",
        "lat": "68.8482",
        "lon": "-166.118",
        "name": "Cape Lisburne Long-Range Radar Station",
        "city": "Point Hope",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12519054",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "5009",
        "elev": "12",
        "icao": "PALU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LUV",
        "lat": "0.10974",
        "lon": "113.917",
        "name": "Indonesia",
        "city": "Langgur",
        "state": "",
        "country": "Indonesia",
        "woeid": "23424846",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4363",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "LXG",
        "lat": "20.9667",
        "lon": "101.4",
        "name": "Luang Namtha",
        "city": "Luang Namtha",
        "state": "Louangnamtha",
        "country": "Lao People's Democratic Republic",
        "woeid": "1140558",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4330",
        "elev": "1968",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "LZC",
        "lat": "17.9889",
        "lon": "-102.219",
        "name": "Lazaro Cardenas",
        "city": "Arteaga",
        "state": "Michoacan de Ocampo",
        "country": "Mexico",
        "woeid": "12514908",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4922",
        "elev": "36",
        "icao": "MMLC",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MAK",
        "lat": "9.5556",
        "lon": "31.6444",
        "name": "Malakal",
        "city": "Malakal",
        "state": "A ali an Nil",
        "country": "Sudan",
        "woeid": "12517602",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6600",
        "elev": "1270",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MAM",
        "lat": "25.7694",
        "lon": "-97.5239",
        "name": "General Sevando Canales",
        "city": "Matamoros",
        "state": "Tamaulipas",
        "country": "Mexico",
        "woeid": "12514886",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7546",
        "elev": "27",
        "icao": "MMMA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MAZ",
        "lat": "18.2531",
        "lon": "-67.1489",
        "name": "Eugenio Maria de Hostos",
        "city": "Mayaguez",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12515660",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "29",
        "icao": "TJMZ",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MBD",
        "lat": "-25.8",
        "lon": "25.545",
        "name": "Mmabatho",
        "city": "Mmabatho",
        "state": "North-west",
        "country": "South Africa",
        "woeid": "12517447",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "14928",
        "elev": "4189",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MBE",
        "lat": "44.3046",
        "lon": "143.404",
        "name": "Okhotsk-Monbetsu",
        "city": "Monbetsu-shi",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "28360513",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MBL",
        "lat": "44.2757",
        "lon": "-86.2558",
        "name": "Manistee County-Blacker",
        "city": "Manistee",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12520778",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5502",
        "elev": "620",
        "icao": "KMBL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MBT",
        "lat": "12.3703",
        "lon": "123.628",
        "name": "Masbate",
        "city": "Masbate",
        "state": "Bicol Region",
        "country": "Philippines",
        "woeid": "12515634",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "33",
        "icao": "KMBT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MBU",
        "lat": "-9.73333",
        "lon": "160.75",
        "name": "Mbambanakira",
        "city": "Mbambanakira",
        "state": "Guadalcanal",
        "country": "Solomon Islands",
        "woeid": "1020499",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2217",
        "elev": "70",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MCE",
        "lat": "37.2892",
        "lon": "-120.515",
        "name": "Merced Municipal-Macready Field",
        "city": "Merced",
        "state": "California",
        "country": "United States",
        "woeid": "12520900",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5903",
        "elev": "153",
        "icao": "KMCE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MCM",
        "lat": "43.7278",
        "lon": "7.41875",
        "name": "Monte Carlo Heliport",
        "city": "Monaco-Ville",
        "state": "La Condamine",
        "country": "Monaco",
        "woeid": "12523920",
        "tz": "EuropeMonaco",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MCN",
        "lat": "32.7023",
        "lon": "-83.65",
        "name": "Middle Georgia",
        "city": "Macon",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12520939",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "354",
        "icao": "KMCN",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MCV",
        "lat": "47.452",
        "lon": "-122.288",
        "name": "Mcarthur River",
        "city": "Mcarthur River",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "55864519",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5180",
        "elev": "125",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MDQ",
        "lat": "-37.9347",
        "lon": "-57.5764",
        "name": "Mar del Plata",
        "city": "Mar del Plata",
        "state": "Buenos Aires",
        "country": "Argentina",
        "woeid": "12510518",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7218",
        "elev": "72",
        "icao": "KMDQ",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MDU",
        "lat": "-6.65619",
        "lon": "145.859",
        "name": "Papua New Guinea",
        "city": "Mendi",
        "state": null,
        "country": "Papua New Guinea",
        "woeid": "23424926",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4395",
        "elev": "5500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MEA",
        "lat": "-22.3422",
        "lon": "-41.7633",
        "name": "Macae",
        "city": "Macae",
        "state": "Rio de Janeiro",
        "country": "Brazil",
        "woeid": "12511217",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MEC",
        "lat": "-0.95",
        "lon": "-80.6833",
        "name": "Eloy Alfaro",
        "city": "Manta",
        "state": "Manabi",
        "country": "Ecuador",
        "woeid": "12512629",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "40",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "MEG",
        "lat": "-9.5236",
        "lon": "16.3158",
        "name": "Malanje",
        "city": "Malanje",
        "state": "Malanje",
        "country": "Angola",
        "woeid": "12510442",
        "tz": "AfricaLuanda",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7267",
        "elev": "3865",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MEI",
        "lat": "32.3334",
        "lon": "-88.7449",
        "name": "Key Field",
        "city": "Meridian",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12520455",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8004",
        "elev": "297",
        "icao": "KMEI",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "MEY",
        "lat": "40.1494",
        "lon": "-91.5043",
        "name": "Meghauli",
        "city": "Meghauli",
        "state": "",
        "country": "Nepal",
        "woeid": "23424911",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "600",
        "icao": "KMEY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MFJ",
        "lat": "-18.6045",
        "lon": "179.924",
        "name": "Moala",
        "city": "Moala",
        "state": "Eastern",
        "country": "Fiji",
        "woeid": "12512811",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2510",
        "elev": "13",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MFK",
        "lat": "23.5998",
        "lon": "121.024",
        "name": "Taiwan",
        "city": "Matsu",
        "state": null,
        "country": "Taiwan",
        "woeid": "23424971",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MFU",
        "lat": "-13.2597",
        "lon": "31.9347",
        "name": "Mfuwe",
        "city": "Mfuwe",
        "state": "Eastern",
        "country": "Zambia",
        "woeid": "12523013",
        "tz": "AfricaLusaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7200",
        "elev": "1880",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "MGH",
        "lat": "-30.8569",
        "lon": "30.3417",
        "name": "Margate",
        "city": "Port Shepstone",
        "state": "Kwazulu Natal",
        "country": "South Africa",
        "woeid": "12517442",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4498",
        "elev": "495",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MGS",
        "lat": "-21.9103",
        "lon": "-157.921",
        "name": "Mangaia",
        "city": "Mangaia Island",
        "state": "Mangaia",
        "country": "Cook Islands",
        "woeid": "12489418",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3275",
        "elev": "45",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MGT",
        "lat": "-18.5572",
        "lon": "133.501",
        "name": "Northern Territory",
        "city": "Milingimbi",
        "state": "Northern Territory",
        "country": "Australia",
        "woeid": "2344701",
        "tz": "AustraliaDarwin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4130",
        "elev": "47",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MHP",
        "lat": "53.8713",
        "lon": "27.5352",
        "name": "Minsk",
        "city": "Minsk",
        "state": "Minskaya Voblasts'",
        "country": "Belarus",
        "woeid": "12510966",
        "tz": "EuropeMinsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMHP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MJD",
        "lat": "27.3364",
        "lon": "68.1417",
        "name": "Moenjodaro",
        "city": "Mohenjodaro",
        "state": "Sindh",
        "country": "Pakistan",
        "woeid": "12515250",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6512",
        "elev": "167",
        "icao": "KMJD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MJL",
        "lat": "-1.56087",
        "lon": "11.1955",
        "name": "Ngounie",
        "city": "Mouila",
        "state": "Ngounie",
        "country": "Gabon",
        "woeid": "2345452",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "295",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MKQ",
        "lat": "-8.5183",
        "lon": "140.422",
        "name": "Mopah",
        "city": "Merauke",
        "state": "Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513484",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MKU",
        "lat": "0.5792",
        "lon": "12.8922",
        "name": "Makokou",
        "city": "Makokou",
        "state": "Ogooue-Ivindo",
        "country": "Gabon",
        "woeid": "12512995",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "1726",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MKW",
        "lat": "-0.8911",
        "lon": "134.051",
        "name": "Rendani",
        "city": "Manokwari",
        "state": "West Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513500",
        "tz": "AsiaJayapura",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "15",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MLB",
        "lat": "28.0957",
        "lon": "-80.6282",
        "name": "Melbourne",
        "city": "Melbourne",
        "state": "Florida",
        "country": "United States",
        "woeid": "12520889",
        "tz": "AmericaNew_York",
        "phone": "321-723-6227",
        "type": "Airports",
        "email": "info@mlbair.com",
        "url": "http:www.mlbair.com",
        "runway_length": "10181",
        "elev": "35",
        "icao": "KMLB",
        "direct_flights": "1",
        "carriers": "5"
    },
    {
        "code": "MLG",
        "lat": "-7.98189",
        "lon": "112.627",
        "name": "Malang",
        "city": "Malang",
        "state": "Jawa Timur",
        "country": "Indonesia",
        "woeid": "1047846",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6398",
        "elev": "1726",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MLO",
        "lat": "36.7137",
        "lon": "24.5118",
        "name": "Milos Island",
        "city": "Apollonia",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513312",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "12",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MLY",
        "lat": "64.9812",
        "lon": "-150.637",
        "name": "Manley Hot Springs",
        "city": "Manley Hot Springs",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2444846",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2760",
        "elev": "270",
        "icao": "PAML",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MMH",
        "lat": "37.6288",
        "lon": "-118.844",
        "name": "Mammoth June Lakes",
        "city": "Mammoth Lakes",
        "state": "California",
        "country": "United States",
        "woeid": "12520768",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7000",
        "elev": "7128",
        "icao": "KMMH",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MMO",
        "lat": "15.2209",
        "lon": "-23.1762",
        "name": "Maio",
        "city": "Vila do Maio",
        "state": "Maio",
        "country": "Cape Verde",
        "woeid": "12512492",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "170",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MNF",
        "lat": "-17.6611",
        "lon": "177.119",
        "name": "Mana Island Airstrip",
        "city": "Mana Island",
        "state": "Western",
        "country": "Fiji",
        "woeid": "2345338",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KMNF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MNT",
        "lat": "65.1987",
        "lon": "-149.53",
        "name": "Minto",
        "city": "Minto",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2452225",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "328",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MOA",
        "lat": "38.5702",
        "lon": "-109.553",
        "name": "Moa",
        "city": "Moa",
        "state": "Holguin",
        "country": "Cuba",
        "woeid": "65544",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5580",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MOD",
        "lat": "37.6319",
        "lon": "-120.959",
        "name": "Modesto City County-Harry Sham Field",
        "city": "Modesto",
        "state": "California",
        "country": "United States",
        "woeid": "12520978",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5911",
        "elev": "97",
        "icao": "KMOD",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MOG",
        "lat": "37.3729",
        "lon": "-87.1361",
        "name": "Mong Hsat",
        "city": "Mong Hsat",
        "state": "Rakhine State",
        "country": "Myanmar",
        "woeid": "1017865",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1875",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MOI",
        "lat": "-19.8167",
        "lon": "-157.717",
        "name": "Mitiaro Island",
        "city": "Mitiaro Island",
        "state": "Mitiaro",
        "country": "Cook Islands",
        "woeid": "24549667",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "25",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MOT",
        "lat": "48.2554",
        "lon": "-101.291",
        "name": "Minot",
        "city": "Minot",
        "state": "North Dakota",
        "country": "United States",
        "woeid": "12520968",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7493",
        "elev": "1715",
        "icao": "KMOT",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "MOV",
        "lat": "-22.0574",
        "lon": "148.077",
        "name": "Moranbah",
        "city": "Moranbah",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "28677683",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3200",
        "elev": "565",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MPA",
        "lat": "-17.6342",
        "lon": "24.1806",
        "name": "Mpacha",
        "city": "Mpacha",
        "state": "",
        "country": "Namibia",
        "woeid": "12522982",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7500",
        "elev": "3230",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MRA",
        "lat": "32.3325",
        "lon": "15.0617",
        "name": "Misurata",
        "city": "Misratah",
        "state": "Misratah",
        "country": "Libya",
        "woeid": "12514660",
        "tz": "AfricaTripoli",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10980",
        "elev": "60",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MRD",
        "lat": "8.5853",
        "lon": "-71.1597",
        "name": "Alberto Carnevalli",
        "city": "Ejido",
        "state": "Mérida",
        "country": "Venezuela",
        "woeid": "12522751",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5348",
        "elev": "5007",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MRZ",
        "lat": "-29.494",
        "lon": "149.851",
        "name": "Moree",
        "city": "Moree",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510741",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5300",
        "elev": "701",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MSE",
        "lat": "51.3448",
        "lon": "1.35733",
        "name": "Kent",
        "city": "Manston",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22484617",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9029",
        "elev": "178",
        "icao": "GMSH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MSS",
        "lat": "44.9326",
        "lon": "-74.8535",
        "name": "Massena",
        "city": "Massena",
        "state": "New York",
        "country": "United States",
        "woeid": "12521580",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5001",
        "elev": "214",
        "icao": "KMSS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MSU",
        "lat": "-29.4581",
        "lon": "27.5556",
        "name": "Maseru Moshoeshoe",
        "city": "Maseru",
        "state": "Maseru",
        "country": "Lesotho",
        "woeid": "12514633",
        "tz": "AfricaMaseru",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "5105",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MSW",
        "lat": "15.6",
        "lon": "39.4333",
        "name": "Massawa",
        "city": "Massawa",
        "state": "Semien-Keih-Bahri",
        "country": "Ethiopia",
        "woeid": "1312462",
        "tz": "AfricaAsmara",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6201",
        "elev": "37",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MTM",
        "lat": "55.1333",
        "lon": "-131.583",
        "name": "Metlakatla Sea Plane Base",
        "city": "Metlakatla",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2449920",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "MTS",
        "lat": "-26.5204",
        "lon": "31.3146",
        "name": "Matsapa",
        "city": "Manzini",
        "state": "Manzini",
        "country": "Swaziland",
        "woeid": "12522997",
        "tz": "AfricaMbabane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8530",
        "elev": "2075",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MTT",
        "lat": "18.0833",
        "lon": "-94.5961",
        "name": "Minatitlan",
        "city": "Minatitlan",
        "state": "",
        "country": "Mexico",
        "woeid": "12514920",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6217",
        "elev": "90",
        "icao": "MMMT",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MUK",
        "lat": "-20.1333",
        "lon": "-157.35",
        "name": "Mauke Island",
        "city": "Mauke Island",
        "state": "Atiu",
        "country": "Cook Islands",
        "woeid": "24549674",
        "tz": "PacificRarotonga",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3107",
        "elev": "90",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MVB",
        "lat": "-1.6569",
        "lon": "13.4397",
        "name": "Franceville Mvengue",
        "city": "Franceville",
        "state": "Haut-Ogooue",
        "country": "Gabon",
        "woeid": "12512992",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10105",
        "elev": "1447",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "MVP",
        "lat": "1.13333",
        "lon": "-70.05",
        "name": "Mitu",
        "city": "Mitu",
        "state": "Vaupes",
        "country": "Colombia",
        "woeid": "12523635",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "680",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MVS",
        "lat": "-18.0492",
        "lon": "-39.8653",
        "name": "Aeroporto Max Feffer",
        "city": "Mucuri",
        "state": "Nordeste",
        "country": "Brazil",
        "woeid": "456351",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MWA",
        "lat": "37.7467",
        "lon": "-89.0104",
        "name": "Williamson County",
        "city": "Marion",
        "state": "Illinois",
        "country": "United States",
        "woeid": "12522462",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6502",
        "elev": "471",
        "icao": "KMWA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MWQ",
        "lat": "20.1403",
        "lon": "94.928",
        "name": "Magwe",
        "city": "Magwe",
        "state": "Magway",
        "country": "Myanmar",
        "woeid": "1017567",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4600",
        "elev": "275",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MXN",
        "lat": "48.5987",
        "lon": "-3.81735",
        "name": "Ploujean",
        "city": "Morlaix",
        "state": "Brittany",
        "country": "France",
        "woeid": "12523822",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4216",
        "elev": "282",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MXV",
        "lat": "49.663",
        "lon": "100.094",
        "name": "Moron",
        "city": "Mörön",
        "state": "Hovsgol",
        "country": "Mongolia",
        "woeid": "12514750",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MYE",
        "lat": "34.0726",
        "lon": "139.56",
        "name": "Miyakejima",
        "city": "Miyake-mura",
        "state": "Tokyo Prefecture",
        "country": "Japan",
        "woeid": "12513989",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "65",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MYW",
        "lat": "-10.3342",
        "lon": "40.1808",
        "name": "Mtwara",
        "city": "Ziwani",
        "state": "Mtwara",
        "country": "Tanzania",
        "woeid": "12518018",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7400",
        "elev": "371",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "MZH",
        "lat": "40.8303",
        "lon": "35.5202",
        "name": "Merzifon",
        "city": "Merzifon",
        "state": "",
        "country": "Turkey",
        "woeid": "2343729",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "9000",
        "elev": "1783",
        "icao": "KMZH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MZO",
        "lat": "20.2861",
        "lon": "-77.0864",
        "name": "Manzanillo",
        "city": "Manzanillo",
        "state": "Granma",
        "country": "Cuba",
        "woeid": "12512474",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "112",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "MZV",
        "lat": "4.03333",
        "lon": "114.8",
        "name": "Mulu",
        "city": "Mulu",
        "state": "Sarawak",
        "country": "Malaysia",
        "woeid": "12523318",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NAA",
        "lat": "-30.3208",
        "lon": "149.826",
        "name": "Narrabri",
        "city": "Bohena Creek",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510748",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "786",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NAV",
        "lat": "32.5281",
        "lon": "-87.2731",
        "name": "Nevsehir",
        "city": "Nevsehir",
        "state": "Nevşehir",
        "country": "Turkey",
        "woeid": "2347303",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NAW",
        "lat": "6.5183",
        "lon": "101.748",
        "name": "Narathiwat",
        "city": "Narathiwat",
        "state": "Narathiwat",
        "country": "Thailand",
        "woeid": "12517772",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6578",
        "elev": "18",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NBX",
        "lat": "-3.3617",
        "lon": "135.494",
        "name": "Nabire",
        "city": "Nabire",
        "state": "Irian Jaya",
        "country": "Indonesia",
        "woeid": "12513488",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3773",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "NCN",
        "lat": "60.0784",
        "lon": "-148.017",
        "name": "Chenega",
        "city": "New Chenega",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2378864",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "NFO",
        "lat": "-15.6022",
        "lon": "-175.614",
        "name": "Niuafo'ou",
        "city": "",
        "state": "Vava'eu",
        "country": "Tonga",
        "woeid": "12517848",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NGE",
        "lat": "7.3564",
        "lon": "13.5614",
        "name": "Ngaoundere",
        "city": "Ngaoundéré",
        "state": "Adamaoua",
        "country": "Cameroon",
        "woeid": "12512352",
        "tz": "AfricaDouala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8858",
        "elev": "3656",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NGI",
        "lat": "-18.0333",
        "lon": "179.3",
        "name": "Ngau Island",
        "city": "Ngau Island",
        "state": "Eastern",
        "country": "Fiji",
        "woeid": "12489736",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2493",
        "elev": "50",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NIB",
        "lat": "45.9837",
        "lon": "-108.028",
        "name": "Nikolai",
        "city": "Nikolai",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2459969",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "450",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NKI",
        "lat": "55.8556",
        "lon": "-133.183",
        "name": "Naukiti",
        "city": "Naukiti",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NLD",
        "lat": "27.4422",
        "lon": "-99.5686",
        "name": "Quetzalcoatl",
        "city": "Nuevo Laredo",
        "state": "Tamaulipas",
        "country": "Mexico",
        "woeid": "12514945",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "486",
        "icao": "MMNL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NLG",
        "lat": "56.0167",
        "lon": "-161.15",
        "name": "Nelson Lagoon",
        "city": "Nelson Lagoon",
        "state": "Alaska",
        "country": "United States",
        "woeid": "23417017",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3300",
        "elev": "13",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NLV",
        "lat": "47.05",
        "lon": "31.9167",
        "name": "Nikolaev",
        "city": "Mykolayiv",
        "state": "Mykolayivs´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12524013",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NNB",
        "lat": "-10.8483",
        "lon": "162.455",
        "name": "Makira",
        "city": "Santa Ana",
        "state": "Makira",
        "country": "Solomon Islands",
        "woeid": "2344841",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NNL",
        "lat": "59.9717",
        "lon": "-154.847",
        "name": "Nondalton",
        "city": "Nondalton",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2460300",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2900",
        "elev": "300",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NNT",
        "lat": "18.8097",
        "lon": "100.787",
        "name": "Nan",
        "city": "Nan",
        "state": "Nan",
        "country": "Thailand",
        "woeid": "12517771",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6561",
        "elev": "699",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NOB",
        "lat": "10.0053",
        "lon": "-85.6625",
        "name": "Nosara Beach",
        "city": "Nosara",
        "state": "Guanacaste",
        "country": "Costa Rica",
        "woeid": "12524316",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "NST",
        "lat": "8.4561",
        "lon": "99.96",
        "name": "Nakhon Si Thammarat",
        "city": "Phra Phrom",
        "state": "Nakhon Si Thammarat",
        "country": "Thailand",
        "woeid": "12517768",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3625",
        "elev": "44",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "NTQ",
        "lat": "37.2917",
        "lon": "136.957",
        "name": "Noto",
        "city": "Anamizu-machi",
        "state": "Ishikawa Prefecture",
        "country": "Japan",
        "woeid": "28360528",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NTT",
        "lat": "-15.9481",
        "lon": "-173.736",
        "name": "Niuatoputapu",
        "city": "Niuatoputapu",
        "state": "Vava'eu",
        "country": "Tonga",
        "woeid": "12517849",
        "tz": "PacificTongatapu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NUK",
        "lat": "-19.0861",
        "lon": "-139",
        "name": "Nukutavake",
        "city": "Nukutavake",
        "state": "Windward Islands",
        "country": "French Polynesia",
        "woeid": "22504012",
        "tz": "PacificTahiti",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2788",
        "elev": "17",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "NVT",
        "lat": "-26.8797",
        "lon": "-48.6481",
        "name": "Ministro Victor Konder",
        "city": "Navegantes",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511240",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5581",
        "elev": "18",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "NZH",
        "lat": "49.6",
        "lon": "117.433",
        "name": "Manzhouli",
        "city": "Manzhouli",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "2149766",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OAG",
        "lat": "-32.3833",
        "lon": "149.132",
        "name": "Springhill",
        "city": "Arthurville",
        "state": "New South Wales",
        "country": "Australia",
        "woeid": "12510756",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "3112",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OAM",
        "lat": "-44.9679",
        "lon": "171.086",
        "name": "Oamaru",
        "city": "Oamaru",
        "state": "Canterbury",
        "country": "New Zealand",
        "woeid": "12515162",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "99",
        "icao": "NZOU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OCC",
        "lat": "-0.46532",
        "lon": "-76.979",
        "name": "Coca",
        "city": "Chontapunta",
        "state": "Orellano",
        "country": "Ecuador",
        "woeid": "12524524",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5107",
        "elev": "820",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "ODY",
        "lat": "20.5833",
        "lon": "104.167",
        "name": "Oudomxay",
        "city": "Oudomxay",
        "state": "Houaphan",
        "country": "Lao People's Democratic Republic",
        "woeid": "12848841",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OER",
        "lat": "63.4113",
        "lon": "18.9916",
        "name": "Ornskoldsvik",
        "city": "Husum",
        "state": "Vasternorrland",
        "country": "Sweden",
        "woeid": "12517666",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "351",
        "icao": "ESNO",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "OGN",
        "lat": "24.4674",
        "lon": "122.979",
        "name": "Yonaguni",
        "city": "Yonaguni-cho",
        "state": "Okinawa Prefecture",
        "country": "Japan",
        "woeid": "12514039",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2620",
        "elev": "54",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OGS",
        "lat": "44.6782",
        "lon": "-75.4765",
        "name": "Ogdensburg",
        "city": "Ogdensburg",
        "state": "New York",
        "country": "United States",
        "woeid": "12521199",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5201",
        "elev": "297",
        "icao": "KOGS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OGZ",
        "lat": "43.2065",
        "lon": "44.6105",
        "name": "Ordzhonikidze North",
        "city": "Mozdok",
        "state": "Severnaya Osetiya-Alaniya",
        "country": "Russia",
        "woeid": "12516613",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OHE",
        "lat": "53.6321",
        "lon": "10.0042",
        "name": "Hamburg",
        "city": "Amburgo",
        "state": "Hamburg",
        "country": "Germany",
        "woeid": "22211603",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OHO",
        "lat": "59.4103",
        "lon": "143.062",
        "name": "Okhotsk",
        "city": "Okhotsk",
        "state": "Khabarovskiy Kray",
        "country": "Russia",
        "woeid": "12516592",
        "tz": "AsiaVladivostok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OKR",
        "lat": "36.6159",
        "lon": "-85.1253",
        "name": "Yorke Island",
        "city": "Yorke Island",
        "state": "",
        "country": "Australia",
        "woeid": "23424748",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OLA",
        "lat": "63.696",
        "lon": "9.60463",
        "name": "Orland",
        "city": "Orland",
        "state": "Sor-Trondelag",
        "country": "Norway",
        "woeid": "12515117",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8904",
        "elev": "30",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OLF",
        "lat": "48.0964",
        "lon": "-105.575",
        "name": "L. M. Clayton",
        "city": "Wolf Point",
        "state": "Montana",
        "country": "United States",
        "woeid": "29387871",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5089",
        "elev": "1986",
        "icao": "KOLF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OLH",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Old Harbor",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "15",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "OLJ",
        "lat": "-16.2368",
        "lon": "167.492",
        "name": "Malampa",
        "city": "Olpoi",
        "state": "Malampa",
        "country": "Vanuatu",
        "woeid": "20069886",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OLP",
        "lat": "-30.484",
        "lon": "136.878",
        "name": "Olympic Dam Aerodrome",
        "city": "Roxby Downs",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510755",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OMB",
        "lat": "-1.5778",
        "lon": "9.25386",
        "name": "Omboué",
        "city": "Omboue",
        "state": "Ogooue-Martime",
        "country": "Gabon",
        "woeid": "1325458",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OMC",
        "lat": "11.0611",
        "lon": "124.563",
        "name": "Ormoc",
        "city": "Ormoc",
        "state": "Eastern Visayas",
        "country": "Philippines",
        "woeid": "12515639",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3609",
        "elev": "82",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "OND",
        "lat": "-17.8783",
        "lon": "15.95",
        "name": "Ondangwa",
        "city": "Ondangwa",
        "state": "Oshana",
        "country": "Namibia",
        "woeid": "12522985",
        "tz": "AfricaWindhoek",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9750",
        "elev": "3592",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OPF",
        "lat": "25.9023",
        "lon": "-80.2677",
        "name": "Opa Locka",
        "city": "Opa-Locka",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521232",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "8002",
        "elev": "9",
        "icao": "KOPF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ORI",
        "lat": "-7.6833",
        "lon": "-39.3667",
        "name": "Port Lions",
        "city": "Port Lions",
        "state": "Alaska",
        "country": "United States",
        "woeid": "23510508",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "52",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "OSI",
        "lat": "45.4639",
        "lon": "18.8083",
        "name": "Osijek",
        "city": "Osijek",
        "state": "Osječko-baranjska",
        "country": "Croatia",
        "woeid": "12513368",
        "tz": "EuropeZagreb",
        "phone": "00385 31 51 44 40",
        "type": "Airports",
        "email": "zlosijek-opc@os.t-com.hr",
        "url": "http:www.osijek-airport.hr",
        "runway_length": "3990",
        "elev": "298",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OSM",
        "lat": "36.3086",
        "lon": "43.1458",
        "name": "Mosul",
        "city": "Mosul",
        "state": "Ninawa",
        "country": "Iraq",
        "woeid": "12513911",
        "tz": "AsiaBaghdad",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8600",
        "elev": "910",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OSW",
        "lat": "51.2085",
        "lon": "58.6325",
        "name": "Orsk",
        "city": "Orsk",
        "state": "Orenburgskaya Oblast",
        "country": "Russia",
        "woeid": "2122669",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OTR",
        "lat": "8.6006",
        "lon": "-82.9694",
        "name": "Coto 47",
        "city": "Coto 47",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12512429",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3084",
        "elev": "26",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OUE",
        "lat": "1.6167",
        "lon": "16.0444",
        "name": "Ouesso",
        "city": "Ouesso",
        "state": "Sangha",
        "country": "Congo",
        "woeid": "12511947",
        "tz": "AfricaBrazzaville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "1155",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OUZ",
        "lat": "22.7337",
        "lon": "-12.4941",
        "name": "Mauritania",
        "city": "Zouerate",
        "state": "Tiris Zemmour",
        "country": "Mauritania",
        "woeid": "23424896",
        "tz": "AfricaNouakchott",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6500",
        "elev": "1050",
        "icao": "GQPZ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "OXR",
        "lat": "34.1984",
        "lon": "-119.206",
        "name": "Oxnard",
        "city": "Oxnard",
        "state": "California",
        "country": "United States",
        "woeid": "12521271",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5950",
        "elev": "43",
        "icao": "KOXR",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "OYE",
        "lat": "1.5353",
        "lon": "11.5803",
        "name": "Oyem",
        "city": "Oyem",
        "state": "Woleu-Ntem",
        "country": "Gabon",
        "woeid": "12512999",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6005",
        "elev": "2158",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "OYG",
        "lat": "3.64967",
        "lon": "31.7239",
        "name": "Moyo",
        "city": "Moyo",
        "state": "Moyo",
        "country": "Uganda",
        "woeid": "1453837",
        "tz": "AfricaKampala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4260",
        "elev": "3100",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PAH",
        "lat": "37.0612",
        "lon": "-88.7672",
        "name": "Barkley",
        "city": "West Paducah",
        "state": "Kentucky",
        "country": "United States",
        "woeid": "12518741",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6499",
        "elev": "410",
        "icao": "KPAH",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "PAI",
        "lat": "12.85",
        "lon": "102.583",
        "name": "Pailin",
        "city": "Pailin",
        "state": "Pailin",
        "country": "Cambodia",
        "woeid": "12523199",
        "tz": "AsiaPhnom_Penh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "623",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PAS",
        "lat": "37.0683",
        "lon": "25.1975",
        "name": "Paros Island",
        "city": "Levkai",
        "state": "Notio Aigaio",
        "country": "Greece",
        "woeid": "12513317",
        "tz": "EuropeAthens",
        "phone": "0030-2284091257",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2362",
        "elev": "121",
        "icao": "LGPA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PBD",
        "lat": "21.6489",
        "lon": "69.6567",
        "name": "Porbandar",
        "city": "Porbandar",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513653",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4500",
        "elev": "21",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PBP",
        "lat": "9.85917",
        "lon": "-85.3692",
        "name": "Punta Islita",
        "city": "Punta Islita",
        "state": "Guanacaste",
        "country": "Costa Rica",
        "woeid": "58155",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PBU",
        "lat": "27.33",
        "lon": "97.4306",
        "name": "Putao",
        "city": "Putao",
        "state": "Kachin State",
        "country": "Myanmar",
        "woeid": "12510933",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "1500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PCE",
        "lat": "57.093",
        "lon": "-157.258",
        "name": "Painter Creek",
        "city": "Painter Creek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799701",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PCL",
        "lat": "-8.3753",
        "lon": "-74.5731",
        "name": "Pucallpa",
        "city": "Callaria",
        "state": "Ucayali",
        "country": "Peru",
        "woeid": "12515216",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "515",
        "icao": "SPCL",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "PDB",
        "lat": "59.7868",
        "lon": "-154.105",
        "name": "Pedro Bay",
        "city": "Pedro Bay",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2469932",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "45",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PDS",
        "lat": "28.7",
        "lon": "-100.55",
        "name": "Piedras Negras",
        "city": "Piedras Negras",
        "state": "Coahuila de Zaragoza",
        "country": "Mexico",
        "woeid": "12514933",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "980",
        "icao": "MMPG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PEC",
        "lat": "57.95",
        "lon": "-136.233",
        "name": "Pelican Sea Plane Base",
        "city": "Pelican",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2470028",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PEM",
        "lat": "-12.6128",
        "lon": "-69.2297",
        "name": "Padre Aldamiz",
        "city": "Puerto Maldonado",
        "state": "Madre de Dios",
        "country": "Peru",
        "woeid": "12515213",
        "tz": "AmericaLima",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "11630",
        "elev": "690",
        "icao": "SPTU",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "PEU",
        "lat": "15.2404",
        "lon": "-83.7794",
        "name": "Puerto Lempira",
        "city": "Puerto Lempira",
        "state": "Gracias a Dios",
        "country": "Honduras",
        "woeid": "12524366",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5590",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PFQ",
        "lat": "39.6053",
        "lon": "47.8758",
        "name": "Parsabad",
        "city": "Parsabad",
        "state": "Ardabil",
        "country": "Iran",
        "woeid": "2255028",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PGV",
        "lat": "35.6336",
        "lon": "-77.3808",
        "name": "Pitt Greenville",
        "city": "Greenville",
        "state": "North Carolina",
        "country": "United States",
        "woeid": "12521400",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "25",
        "icao": "KPGV",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PGX",
        "lat": "45.1951",
        "lon": "0.81208",
        "name": "Bassillac",
        "city": "St-Pierre",
        "state": "Aquitaine",
        "country": "France",
        "woeid": "12512852",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5741",
        "elev": "328",
        "icao": "LFBX",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PHS",
        "lat": "16.7861",
        "lon": "100.278",
        "name": "Phitsanulok",
        "city": "Phitsanulok",
        "state": "Phitsanulok",
        "country": "Thailand",
        "woeid": "12517774",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "145",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PHW",
        "lat": "-23.9336",
        "lon": "31.1561",
        "name": "Hendrik Van Eck",
        "city": "Phalaborwa",
        "state": "Limpopo",
        "country": "South Africa",
        "woeid": "12517419",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4491",
        "elev": "1427",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PIB",
        "lat": "31.4671",
        "lon": "-89.3333",
        "name": "Pine Belt",
        "city": "Moselle",
        "state": "Mississippi",
        "country": "United States",
        "woeid": "12521381",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6501",
        "elev": "298",
        "icao": "KPIB",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PIF",
        "lat": "22.6781",
        "lon": "120.471",
        "name": "Pingdong",
        "city": "Pingtung",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517951",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PIH",
        "lat": "42.9056",
        "lon": "-112.588",
        "name": "Pocatello Municipal",
        "city": "Pocatello",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12521423",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9056",
        "elev": "4449",
        "icao": "KPIH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PJA",
        "lat": "62.1984",
        "lon": "17.5514",
        "name": "Pajala",
        "city": "Pajala",
        "state": "",
        "country": "Sweden",
        "woeid": "23424954",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKB",
        "lat": "39.3462",
        "lon": "-81.4345",
        "name": "Wood County-Gill Robb Wilson Field",
        "city": "Williamstown",
        "state": "West Virginia",
        "country": "United States",
        "woeid": "12522506",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6781",
        "elev": "858",
        "icao": "KPKB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKE",
        "lat": "-33.1358",
        "lon": "148.235",
        "name": "Parkes",
        "city": "Parkes",
        "state": "Australian Capital Territory",
        "country": "Australia",
        "woeid": "12510758",
        "tz": "AustraliaSydney",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5525",
        "elev": "1069",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKG",
        "lat": "4.21667",
        "lon": "100.55",
        "name": "Pangkor",
        "city": "Pangkor",
        "state": "Perak",
        "country": "Malaysia",
        "woeid": "12523322",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKK",
        "lat": "21.3333",
        "lon": "95.1",
        "name": "Pakokku",
        "city": "Pakokku",
        "state": "Magway",
        "country": "Myanmar",
        "woeid": "1017758",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4019",
        "elev": "150",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKP",
        "lat": "-8.64687",
        "lon": "-131.787",
        "name": "French Polynesia",
        "city": "Puka Puka",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2952",
        "elev": "6",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PKR",
        "lat": "28.2",
        "lon": "83.9833",
        "name": "Pokhara",
        "city": "Pokhara",
        "state": "West",
        "country": "Nepal",
        "woeid": "12523202",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4809",
        "elev": "2713",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PKY",
        "lat": "-2.2228",
        "lon": "113.938",
        "name": "Tjilik Riwut",
        "city": "Buntok",
        "state": "Kalimantan Tangah",
        "country": "Indonesia",
        "woeid": "12513520",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5052",
        "elev": "82",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PLD",
        "lat": "9.88107",
        "lon": "-85.5507",
        "name": "Playa Samara",
        "city": "Nicoya",
        "state": "Guanacaste",
        "country": "Costa Rica",
        "woeid": "12524318",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KPLD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PLO",
        "lat": "-34.6041",
        "lon": "135.879",
        "name": "Port Lincoln",
        "city": "Port Lincoln",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510765",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5020",
        "elev": "36",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PMA",
        "lat": "-5.2539",
        "lon": "39.8125",
        "name": "Pemba",
        "city": "Pemba",
        "state": "Pemba South",
        "country": "Tanzania",
        "woeid": "12518021",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "80",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PMD",
        "lat": "34.6294",
        "lon": "-118.084",
        "name": "Air Force Plant Nr 42 Palmdale",
        "city": "Palmdale",
        "state": "California",
        "country": "United States",
        "woeid": "12521290",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "12002",
        "elev": "2542",
        "icao": "KPMD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PME",
        "lat": "50.8303",
        "lon": "-1.05586",
        "name": "Portsmouth",
        "city": "Portsmouth",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12523999",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3481",
        "elev": "12",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PMW",
        "lat": "-10.2417",
        "lon": "-48.3528",
        "name": "Palmas",
        "city": "Palmas",
        "state": "Tocantins",
        "country": "Brazil",
        "woeid": "12523650",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "788",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "PMY",
        "lat": "-42.7597",
        "lon": "-65.11",
        "name": "El Tehuelche",
        "city": "Puerto Madryn",
        "state": "Chubut",
        "country": "Argentina",
        "woeid": "12510492",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "446",
        "icao": "SAVY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PND",
        "lat": "16.0977",
        "lon": "-88.8303",
        "name": "Punta Gorda",
        "city": "Punta Gorda",
        "state": "Toledo",
        "country": "Belize",
        "woeid": "12524035",
        "tz": "AmericaBelize",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2430",
        "elev": "60",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PNK",
        "lat": "-0.1481",
        "lon": "109.403",
        "name": "Supadio",
        "city": "Pontianak",
        "state": "Kalimantan Barat",
        "country": "Indonesia",
        "woeid": "12513512",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5430",
        "elev": "10",
        "icao": "WIOO",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "PNP",
        "lat": "-8.8056",
        "lon": "148.309",
        "name": "Girua",
        "city": "Popondetta",
        "state": "Northern",
        "country": "Papua New Guinea",
        "woeid": "12515465",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3400",
        "elev": "400",
        "icao": "PAPN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PPE",
        "lat": "31.35",
        "lon": "-113.526",
        "name": "Punta Penasco",
        "city": "Pto. Penasco",
        "state": "Sonora",
        "country": "Mexico",
        "woeid": "12514942",
        "tz": "AmericaHermosillo",
        "phone": "(638)383-6097",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "46",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PPK",
        "lat": "54.7833",
        "lon": "69.1833",
        "name": "Petropavlovsk",
        "city": "Petropavlovsk",
        "state": "Soltustik Qazaqstan",
        "country": "Kazakhstan",
        "woeid": "20070174",
        "tz": "AsiaAlmaty",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PPL",
        "lat": "28.3948",
        "lon": "84.1278",
        "name": "Nepal",
        "city": "Phaplu",
        "state": "",
        "country": "Nepal",
        "woeid": "23424911",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "2378",
        "elev": "9000",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PPV",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Port Protection",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PQI",
        "lat": "46.6921",
        "lon": "-68.0447",
        "name": "Northern Maine",
        "city": "Presque Isle",
        "state": "Maine",
        "country": "United States",
        "woeid": "12521164",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7440",
        "elev": "534",
        "icao": "KPQI",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PRI",
        "lat": "-4.3158",
        "lon": "55.6958",
        "name": "Praslin",
        "city": "Praslin Island",
        "state": "Grand Anse Praslin",
        "country": "Seychelles",
        "woeid": "12517388",
        "tz": "IndianMahe",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2493",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PSZ",
        "lat": "-18.9747",
        "lon": "-57.8194",
        "name": "Salvador Ogaya Gutierrez",
        "city": "Puerto Suárez",
        "state": "Santa Cruz",
        "country": "Bolivia",
        "woeid": "12510910",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "440",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PTA",
        "lat": "60.3956",
        "lon": "-154.494",
        "name": "Port Alsworth",
        "city": "Port Alsworth",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2475311",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "280",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PTF",
        "lat": "-17.7818",
        "lon": "177.2",
        "name": "Malololailai",
        "city": "Malololailai",
        "state": "Western",
        "country": "Fiji",
        "woeid": "12523205",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PTG",
        "lat": "-23.9236",
        "lon": "29.4833",
        "name": "Pietersburg Municipal",
        "city": "Pietersburg",
        "state": "Limpopo",
        "country": "South Africa",
        "woeid": "12517461",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10400",
        "elev": "4075",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PTU",
        "lat": "58.9216",
        "lon": "-161.727",
        "name": "Platinum",
        "city": "Platinum",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2473568",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3800",
        "elev": "9",
        "icao": "PAPM",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "PUK",
        "lat": "21.0754",
        "lon": "-156.793",
        "name": "Pukarua",
        "city": "Pukarua",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2952",
        "elev": "3",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PVA",
        "lat": "13.3577",
        "lon": "-81.3535",
        "name": "Providencia Island",
        "city": "San Andrés",
        "state": "San Andres y Providencia",
        "country": "Colombia",
        "woeid": "12512404",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3281",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PVC",
        "lat": "42.0771",
        "lon": "-70.2169",
        "name": "Provincetown Municipal",
        "city": "Provincetown",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12521490",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3498",
        "elev": "8",
        "icao": "KPVC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PWE",
        "lat": "69.7862",
        "lon": "170.642",
        "name": "Under Construction Pevek",
        "city": "Anadyr'",
        "state": "Chukotskiy Avtonomnyy Okrug",
        "country": "Russia",
        "woeid": "12517084",
        "tz": "AsiaKamchatka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PXM",
        "lat": "15.8725",
        "lon": "-97.0861",
        "name": "Puerto Escondido",
        "city": "San Pedro Juchatengo",
        "state": "Oaxaca",
        "country": "Mexico",
        "woeid": "12514938",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7544",
        "elev": "289",
        "icao": "MMPS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PYH",
        "lat": "5.5833",
        "lon": "-67.6167",
        "name": "Puerto Ayacucho",
        "city": "Maroa",
        "state": "Amazonas",
        "country": "Venezuela",
        "woeid": "12522832",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6726",
        "elev": "105",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "PZB",
        "lat": "-29.6514",
        "lon": "30.3994",
        "name": "Pietermaritzburg",
        "city": "Pietermaritzburg",
        "state": "Kwazulu Natal",
        "country": "South Africa",
        "woeid": "12517459",
        "tz": "AfricaJohannesburg",
        "phone": "033 386 5252",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "2425",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "QOW",
        "lat": "5.49341",
        "lon": "7.0261",
        "name": "Owerri",
        "city": "Owerri",
        "state": "Imo",
        "country": "Nigeria",
        "woeid": "1510193",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "DNIM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "QQD",
        "lat": "51.07",
        "lon": "1.19",
        "name": "Dover",
        "city": "Dover",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "26345882",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "QWB",
        "lat": "-25.5275",
        "lon": "-49.1731",
        "name": "Afonso Pena",
        "city": "Sao Jose dos Pinhais",
        "state": "Parana",
        "country": "Brazil",
        "woeid": "12511012",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RAJ",
        "lat": "22.3169",
        "lon": "70.769",
        "name": "Rajkot",
        "city": "Rajkot",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513661",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5395",
        "elev": "440",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RBH",
        "lat": "64.7381",
        "lon": "-155.489",
        "name": "Brooks Lodge",
        "city": "Brooks Lodge",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RBX",
        "lat": "46.4717",
        "lon": "-108.55",
        "name": "Roundup",
        "city": "Roundup",
        "state": "Montana",
        "country": "United States",
        "woeid": "12521664",
        "tz": "AmericaBoise",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RCB",
        "lat": "-28.7386",
        "lon": "32.0928",
        "name": "Richards Bay",
        "city": "Empangeni",
        "state": "Kwazulu Natal",
        "country": "South Africa",
        "woeid": "12517470",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "108",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RCH",
        "lat": "11.5292",
        "lon": "-72.9244",
        "name": "Almirante Padilla",
        "city": "Ríohacha",
        "state": "La Guajira",
        "country": "Colombia",
        "woeid": "12512362",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5413",
        "elev": "48",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RCL",
        "lat": "-15.4802",
        "lon": "167.834",
        "name": "Redcliffe",
        "city": "Redcliffe",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2230",
        "elev": "36",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RCP",
        "lat": "51.5257",
        "lon": "-0.14499",
        "name": "Cinder River",
        "city": "Cinder River",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RDB",
        "lat": "67.75",
        "lon": "-163.667",
        "name": "Red Dog",
        "city": "Red Dog",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799789",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PADG",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "RDV",
        "lat": "61.7645",
        "lon": "-157.312",
        "name": "Red Devil",
        "city": "Red Devil",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2479276",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "210",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "REA",
        "lat": "-19",
        "lon": "-135.1",
        "name": "Reao",
        "city": "Reao",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "28743702",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2953",
        "elev": "7",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RGA",
        "lat": "-53.7792",
        "lon": "-67.7667",
        "name": "Rio Grande",
        "city": "Río Grande",
        "state": "Tierra del Fuego",
        "country": "Argentina",
        "woeid": "12510546",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6561",
        "elev": "66",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RIB",
        "lat": "-11.0167",
        "lon": "-66.0833",
        "name": "Gen. Buech",
        "city": "Riberalta",
        "state": "El Beni",
        "country": "Bolivia",
        "woeid": "12523692",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "463",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RIS",
        "lat": "45.2419",
        "lon": "141.186",
        "name": "Rishiri",
        "city": "Rishirifuji-cho",
        "state": "Hokkaido Prefecture",
        "country": "Japan",
        "woeid": "28360519",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "98",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RJN",
        "lat": "30.2803",
        "lon": "57.067",
        "name": "Kerman",
        "city": "Rafsanjan",
        "state": "Kerman",
        "country": "Iran",
        "woeid": "2254796",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RNA",
        "lat": "-19.3",
        "lon": "44.8167",
        "name": "Ulawa",
        "city": "Arona",
        "state": "Toliara",
        "country": "Solomon Islands",
        "woeid": "1355953",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RNB",
        "lat": "56.2542",
        "lon": "15.2672",
        "name": "Ronneby",
        "city": "Kallinge",
        "state": "Blekinge",
        "country": "Sweden",
        "woeid": "12517671",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7743",
        "elev": "189",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RNN",
        "lat": "55.0681",
        "lon": "14.7472",
        "name": "Bornholm",
        "city": "Ronne",
        "state": "Hovedstaden",
        "country": "Denmark",
        "woeid": "12512596",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.bornholms-lufthavn.dken",
        "runway_length": "6562",
        "elev": "52",
        "icao": "EKRN",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "RNP",
        "lat": "11.15",
        "lon": "166.883",
        "name": "Rongelap Island",
        "city": "Rongelap Island",
        "state": "Rongelap",
        "country": "Marshall Islands",
        "woeid": "24549862",
        "tz": "PacificMajuro",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KRNP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ROI",
        "lat": "16.0142",
        "lon": "103.592",
        "name": "Mueang Poi Et",
        "city": "Roi Et",
        "state": "Roi Et",
        "country": "Thailand",
        "woeid": "28341194",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ROO",
        "lat": "-16.4458",
        "lon": "-54.6639",
        "name": "Rondonopolis",
        "city": "Rondonópolis",
        "state": "Mato Grosso",
        "country": "Brazil",
        "woeid": "12511294",
        "tz": "AmericaCampo_Grande",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "984",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ROW",
        "lat": "33.3071",
        "lon": "-104.519",
        "name": "Roswell Industrial Air Center",
        "city": "Roswell",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12521662",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13000",
        "elev": "3669",
        "icao": "KROW",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RRG",
        "lat": "-20.2554",
        "lon": "57.5517",
        "name": "Mauritius",
        "city": "Rodrigues Is",
        "state": "Rodrigues",
        "country": "Mauritius",
        "woeid": "23424894",
        "tz": "IndianMauritius",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3346",
        "elev": "131",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RRS",
        "lat": "62.5792",
        "lon": "11.3458",
        "name": "Roeros",
        "city": "Roros",
        "state": "Sor-Trondelag",
        "country": "Norway",
        "woeid": "12515120",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5610",
        "elev": "2054",
        "icao": "ENRO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RSA",
        "lat": "-36.5881",
        "lon": "-64.2756",
        "name": "Santa Rosa",
        "city": "Santa Rosa",
        "state": "La Pampa",
        "country": "Argentina",
        "woeid": "12510559",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7545",
        "elev": "623",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RSD",
        "lat": "24.8917",
        "lon": "-76.1781",
        "name": "Rock Sound",
        "city": "Rock Sound",
        "state": "South Eleuthera",
        "country": "Bahamas",
        "woeid": "12510877",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7200",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "RTA",
        "lat": "-12.5017",
        "lon": "177.066",
        "name": "Rotuma",
        "city": "Rotuma Island",
        "state": "Rotuma",
        "country": "Fiji",
        "woeid": "20069921",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4901",
        "elev": "25",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RUD",
        "lat": "59.9448",
        "lon": "10.6382",
        "name": "Shahrud",
        "city": "Shahrud",
        "state": "Oslo Fylke",
        "country": "Norway",
        "woeid": "863011",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RUK",
        "lat": "28.3948",
        "lon": "84.1278",
        "name": "Nepal",
        "city": "Rukumkot",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "23424911",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RUM",
        "lat": "27.3333",
        "lon": "86.55",
        "name": "Rumjatar",
        "city": "Rumjatar",
        "state": "Central",
        "country": "Nepal",
        "woeid": "2269044",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1525",
        "elev": "4500",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RUT",
        "lat": "43.5291",
        "lon": "-72.945",
        "name": "Rutland State",
        "city": "North Clarendon",
        "state": "Vermont",
        "country": "United States",
        "woeid": "12521687",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "787",
        "icao": "KRUT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RVA",
        "lat": "-22.8053",
        "lon": "47.8206",
        "name": "Farafangana",
        "city": "Farafangana",
        "state": "Fianarantsoa",
        "country": "Madagascar",
        "woeid": "1360922",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4347",
        "elev": "26",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RVT",
        "lat": "-24.4378",
        "lon": "121.079",
        "name": "Western Australia",
        "city": "Ravensthorpe",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "2344706",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RXS",
        "lat": "11.6",
        "lon": "122.751",
        "name": "Roxas",
        "city": "Ivisan",
        "state": "Western Visayas",
        "country": "Philippines",
        "woeid": "12515644",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5807",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "RZP",
        "lat": "15.5931",
        "lon": "120.739",
        "name": "Philippines",
        "city": "Taytay Sandoval",
        "state": "Calabarzon",
        "country": "Philippines",
        "woeid": "23424934",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RZR",
        "lat": "36.9108",
        "lon": "50.6806",
        "name": "Ramsar",
        "city": "Ramsar",
        "state": "Mazandaran",
        "country": "Iran",
        "woeid": "12513751",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4920",
        "elev": "-75",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "RZS",
        "lat": "33.6403",
        "lon": "73.8569",
        "name": "Sawan",
        "city": "Sawan",
        "state": "",
        "country": "Pakistan",
        "woeid": "23424922",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SAQ",
        "lat": "25.05",
        "lon": "-78.0333",
        "name": "San Andros Municipal",
        "city": "San Andros",
        "state": "North Andros",
        "country": "Bahamas",
        "woeid": "12524025",
        "tz": "AmericaNassau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4974",
        "elev": "5",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SBL",
        "lat": "-13.7667",
        "lon": "-65.5833",
        "name": "Santa Ana de Yacuma",
        "city": "Santa Ana",
        "state": "El Beni",
        "country": "Bolivia",
        "woeid": "12524440",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4823",
        "elev": "726",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SCK",
        "lat": "37.8973",
        "lon": "-121.253",
        "name": "Stockton Metropolitan",
        "city": "Stockton",
        "state": "California",
        "country": "United States",
        "woeid": "12522018",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8650",
        "elev": "30",
        "icao": "KSCK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SCT",
        "lat": "25.6472",
        "lon": "-100.172",
        "name": "Socotra",
        "city": "Socotra",
        "state": "",
        "country": "Yemen",
        "woeid": "23425002",
        "tz": "AsiaAden",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9510",
        "elev": "150",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SCX",
        "lat": "16.2104",
        "lon": "-95.1989",
        "name": "Salina Cruz",
        "city": "Salina Cruz",
        "state": "",
        "country": "Mexico",
        "woeid": "24552823",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5248",
        "elev": "75",
        "icao": "KSCX",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SCY",
        "lat": "-0.91",
        "lon": "-89.6067",
        "name": "San Cristobal",
        "city": "Puerto Baquerizo Moreno",
        "state": "Galapagos",
        "country": "Ecuador",
        "woeid": "12512650",
        "tz": "PacificGalapagos",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SCZ",
        "lat": "-11.6167",
        "lon": "166.85",
        "name": "Santa Cruz Is",
        "city": "Santa Cruz Is",
        "state": "",
        "country": "Solomon Islands",
        "woeid": "23424766",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "18",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SDG",
        "lat": "35.25",
        "lon": "47.0092",
        "name": "Sanandaj",
        "city": "Sanandaj",
        "state": "Kordestan",
        "country": "Iran",
        "woeid": "12513755",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6725",
        "elev": "4505",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SDT",
        "lat": "34.8125",
        "lon": "72.3528",
        "name": "Saidu Sharif",
        "city": "Saidu",
        "state": "North-West Frontier",
        "country": "Pakistan",
        "woeid": "12515266",
        "tz": "AsiaKarachi",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6053",
        "elev": "3000",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SEN",
        "lat": "51.5678",
        "lon": "0.69928",
        "name": "Southend",
        "city": "Southend on Sea",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12518151",
        "tz": "EuropeLondon",
        "phone": "+44 (0)1702 608100",
        "type": "Airports",
        "email": "",
        "url": "http:www.southendairport.net",
        "runway_length": "5265",
        "elev": "49",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SEW",
        "lat": "40.6438",
        "lon": "-93.2598",
        "name": "Siwa",
        "city": "Siwa",
        "state": "Matruh",
        "country": "Egypt",
        "woeid": "1526392",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5820",
        "elev": "330",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SFD",
        "lat": "7.8856",
        "lon": "-67.4406",
        "name": "San Fernando de Apure",
        "city": "San Fernando de Apure",
        "state": "Apure",
        "country": "Venezuela",
        "woeid": "12522842",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "239",
        "icao": "KSFD",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SFE",
        "lat": "16.5958",
        "lon": "120.302",
        "name": "San Fernando",
        "city": "San Fernando",
        "state": "Ilocos Region",
        "country": "Philippines",
        "woeid": "12515645",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "13",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SFL",
        "lat": "14.9",
        "lon": "-24.5167",
        "name": "Sao Filipe",
        "city": "Sao Filipe",
        "state": "Fogo",
        "country": "Cape Verde",
        "woeid": "1310891",
        "tz": "AtlanticCape_Verde",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1804",
        "elev": "617",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SFT",
        "lat": "64.6236",
        "lon": "21.0675",
        "name": "Skelleftea",
        "city": "Skelleftea",
        "state": "Vasterbotten",
        "country": "Sweden",
        "woeid": "12517678",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "153",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SGD",
        "lat": "54.963",
        "lon": "9.78978",
        "name": "Sonderborg",
        "city": "Sonderborg",
        "state": "Syddanmark",
        "country": "Denmark",
        "woeid": "12512601",
        "tz": "EuropeCopenhagen",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.eksb.dk",
        "runway_length": "4921",
        "elev": "24",
        "icao": "EKSB",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SGX",
        "lat": "-10.6668",
        "lon": "36.3287",
        "name": "Ruvuma",
        "city": "Songea",
        "state": "Ruvuma",
        "country": "Tanzania",
        "woeid": "2347365",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5743",
        "elev": "3445",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SHG",
        "lat": "66.8801",
        "lon": "-157.067",
        "name": "Shungnak",
        "city": "Shungnak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524646",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2800",
        "elev": "200",
        "icao": "PAHG",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "SHL",
        "lat": "25.5667",
        "lon": "91.8833",
        "name": "Shillong",
        "city": "Shillong",
        "state": "Assam",
        "country": "India",
        "woeid": "2277394",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6000",
        "elev": "2950",
        "icao": "KSHL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SHM",
        "lat": "33.664",
        "lon": "135.362",
        "name": "Nanki-Shirahama",
        "city": "Shirahama-cho",
        "state": "Wakayama Prefecture",
        "country": "Japan",
        "woeid": "12513996",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3936",
        "elev": "345",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SIC",
        "lat": "42.0189",
        "lon": "35.0792",
        "name": "Sinop",
        "city": "Sinop",
        "state": "Sinop",
        "country": "Turkey",
        "woeid": "2344394",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "21",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SIF",
        "lat": "27",
        "lon": "85.5333",
        "name": "Simara",
        "city": "Simara",
        "state": "Central",
        "country": "Nepal",
        "woeid": "12742210",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3740",
        "elev": "450",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SIG",
        "lat": "18.4558",
        "lon": "-66.0981",
        "name": "Isla Grande",
        "city": "San Juan",
        "state": "Puerto Rico",
        "country": "United States",
        "woeid": "12515661",
        "tz": "AmericaPuerto_Rico",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5317",
        "elev": "10",
        "icao": "TJIG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SJE",
        "lat": "2.59328",
        "lon": "-72.6081",
        "name": "San Jose del Guaviaro",
        "city": "San Jose del Guavuare",
        "state": "Guavaire",
        "country": "Colombia",
        "woeid": "12524511",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6479",
        "elev": "520",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SJI",
        "lat": "12.3611",
        "lon": "121.047",
        "name": "San Jose",
        "city": "San Jose",
        "state": "Mimaropa",
        "country": "Philippines",
        "woeid": "12515646",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "SKH",
        "lat": "28.4908",
        "lon": "81.7689",
        "name": "Surkhet",
        "city": "Surkhet",
        "state": "Midwest",
        "country": "Nepal",
        "woeid": "23706779",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3480",
        "elev": "2400",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SKO",
        "lat": "12.9164",
        "lon": "5.2075",
        "name": "Sadiq Abubakar Iii",
        "city": "Shunni",
        "state": "Sokoto",
        "country": "Nigeria",
        "woeid": "12515077",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1006",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SLE",
        "lat": "44.9104",
        "lon": "-123.008",
        "name": "Mcnary Field",
        "city": "Salem",
        "state": "Oregon",
        "country": "United States",
        "woeid": "12520877",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5811",
        "elev": "210",
        "icao": "KSLE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SLK",
        "lat": "44.3804",
        "lon": "-74.2038",
        "name": "Adirondack",
        "city": "Saranac Lake",
        "state": "New York",
        "country": "United States",
        "woeid": "12518529",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6573",
        "elev": "1663",
        "icao": "KSLK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SLV",
        "lat": "31.0778",
        "lon": "77.0728",
        "name": "Simla",
        "city": "Jutogh",
        "state": "Himachal Pradesh",
        "country": "India",
        "woeid": "12513671",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SNC",
        "lat": "-2.2",
        "lon": "-80.985",
        "name": "General Ulpiano Paez",
        "city": "Salinas",
        "state": "Guayas",
        "country": "Ecuador",
        "woeid": "12512632",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7996",
        "elev": "14",
        "icao": "KSNC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SNO",
        "lat": "17.2847",
        "lon": "104.107",
        "name": "Sakon Nakhon",
        "city": "Sakon Nakhon",
        "state": "Sakon Nakhon",
        "country": "Thailand",
        "woeid": "12517781",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4593",
        "elev": "554",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SNR",
        "lat": "47.3121",
        "lon": "-2.15411",
        "name": "Montoir",
        "city": "Montoir-de-Bretagne",
        "state": "Pays de la Loire",
        "country": "France",
        "woeid": "12512944",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7874",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SOM",
        "lat": "8.9489",
        "lon": "-64.1478",
        "name": "San Tome",
        "city": "Cantaura",
        "state": "Anzoategui",
        "country": "Venezuela",
        "woeid": "12522844",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "846",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SPD",
        "lat": "25.7625",
        "lon": "88.91",
        "name": "Saidpur",
        "city": "Dinajpur",
        "state": "Rajshahi",
        "country": "Bangladesh",
        "woeid": "12510894",
        "tz": "AsiaDhaka",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "125",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SPL",
        "lat": "36.9658",
        "lon": "-25.1714",
        "name": "Santa Maria",
        "city": "Vila do Porto",
        "state": "Azores",
        "country": "Portugal",
        "woeid": "12524514",
        "tz": "AtlanticAzores",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SRA",
        "lat": "-27.9083",
        "lon": "-54.5217",
        "name": "Santa Rosa",
        "city": "Santa Rosa",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511306",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "935",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SRJ",
        "lat": "-14.8573",
        "lon": "-66.7383",
        "name": "Capitan G Q Guardia",
        "city": "San Borja",
        "state": "Santa Cruz",
        "country": "Bolivia",
        "woeid": "12524445",
        "tz": "AmericaLa_Paz",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5905",
        "elev": "636",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SRV",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Stony River",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2950",
        "elev": "230",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "SSB",
        "lat": "17.75",
        "lon": "-64.7",
        "name": "Christiansted Harbor Seaplane Base",
        "city": "Christiansted",
        "state": "US Virgin Islands",
        "country": "United States",
        "woeid": "12523231",
        "tz": "AmericaSt_Thomas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "1",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "STC",
        "lat": "45.5463",
        "lon": "-94.066",
        "name": "St Cloud",
        "city": "St. Cloud",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12521961",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5200",
        "elev": "1024",
        "icao": "KSTC",
        "direct_flights": "1",
        "carriers": "4"
    },
    {
        "code": "STD",
        "lat": "7.57033",
        "lon": "-72.0443",
        "name": "Mayor Buenaventura Vivas",
        "city": "Santa Ana del Tachira",
        "state": "Tachira",
        "country": "Venezuela",
        "woeid": "12522820",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5052",
        "elev": "1118",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "STV",
        "lat": "21.1667",
        "lon": "72.8333",
        "name": "Surat",
        "city": "Un",
        "state": "Gujarat",
        "country": "India",
        "woeid": "12513677",
        "tz": "AsiaKolkata",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3280",
        "elev": "50",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SUG",
        "lat": "9.7589",
        "lon": "125.479",
        "name": "Surigao",
        "city": "Surigao City",
        "state": "Caraga",
        "country": "Philippines",
        "woeid": "12515648",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4265",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SUJ",
        "lat": "47.7134",
        "lon": "22.8887",
        "name": "Satu Mare",
        "city": "Satu Mare",
        "state": "Satu Mare",
        "country": "Romania",
        "woeid": "12515569",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8202",
        "elev": "400",
        "icao": "LRSM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SUX",
        "lat": "42.4012",
        "lon": "-96.3784",
        "name": "Sioux Gateway",
        "city": "Sioux City",
        "state": "Iowa",
        "country": "United States",
        "woeid": "12521862",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8999",
        "elev": "1098",
        "icao": "KSUX",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "SVC",
        "lat": "32.6301",
        "lon": "-108.164",
        "name": "Silver City-Grant County",
        "city": "Silver City",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12521856",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "5443",
        "icao": "KSVC",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "SVS",
        "lat": "66.0044",
        "lon": "-149.091",
        "name": "Stevens Village",
        "city": "Stevens Village",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2499756",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "310",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SVZ",
        "lat": "7.8431",
        "lon": "-72.4381",
        "name": "San Antonio del Tachira",
        "city": "Táriba",
        "state": "Tachira",
        "country": "Venezuela",
        "woeid": "12522839",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6070",
        "elev": "1312",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SWO",
        "lat": "36.1618",
        "lon": "-97.0751",
        "name": "Stillwater Municipal",
        "city": "Stillwater",
        "state": "Oklahoma",
        "country": "United States",
        "woeid": "12522014",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5002",
        "elev": "984",
        "icao": "KSWO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SWQ",
        "lat": "-8.4933",
        "lon": "117.421",
        "name": "Brang Bidji",
        "city": "Sumbawa Besar",
        "state": "Nusa Tenggara Barat",
        "country": "Indonesia",
        "woeid": "12513511",
        "tz": "AsiaMakassar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4754",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SWS",
        "lat": "51.6006",
        "lon": "-4.07",
        "name": "Swansea",
        "city": "Swansea",
        "state": "Wales",
        "country": "United Kingdom",
        "woeid": "12518159",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4829",
        "elev": "300",
        "icao": "EGFH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SYB",
        "lat": "58.1667",
        "lon": "-152.5",
        "name": "Seal Bay",
        "city": "Kodiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524652",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "SYM",
        "lat": "22.8333",
        "lon": "101",
        "name": "Simao",
        "city": "Simao",
        "state": "Yunnan",
        "country": "China",
        "woeid": "2160700",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TAG",
        "lat": "9.6644",
        "lon": "123.852",
        "name": "Tagbilaran",
        "city": "Tagbilaran City",
        "state": "Central Visayas",
        "country": "Philippines",
        "woeid": "12515649",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TBB",
        "lat": "13.0494",
        "lon": "109.334",
        "name": "Tuy Hoa",
        "city": "Tuy Hoa",
        "state": "Phu Yen",
        "country": "Vietnam",
        "woeid": "1252649",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3048",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TBH",
        "lat": "12.3133",
        "lon": "122.079",
        "name": "Romblon",
        "city": "Alcantara",
        "state": "Mimaropa",
        "country": "Philippines",
        "woeid": "12515643",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TBJ",
        "lat": "36.9777",
        "lon": "8.87678",
        "name": "Tabarka",
        "city": "Jundobah",
        "state": "Jundubah",
        "country": "Tunisia",
        "woeid": "24554858",
        "tz": "AfricaTunis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2870",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TBO",
        "lat": "5.08333",
        "lon": "32.8333",
        "name": "Tabora",
        "city": "Tabora",
        "state": "Tabora",
        "country": "Tanzania",
        "woeid": "1449526",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "13",
        "elev": "3920",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TBW",
        "lat": "52.7056",
        "lon": "41.3686",
        "name": "Tambov",
        "city": "Tambov",
        "state": "Tambovskaya Oblast",
        "country": "Russia",
        "woeid": "2123828",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TCE",
        "lat": "45.0653",
        "lon": "28.7232",
        "name": "Cataloi",
        "city": "Tulcea",
        "state": "Tulcea",
        "country": "Romania",
        "woeid": "12515516",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TCH",
        "lat": "47.4463",
        "lon": "-52.9283",
        "name": "Tchibanga",
        "city": "Tchibanga",
        "state": "Nyanga",
        "country": "Gabon",
        "woeid": "1325711",
        "tz": "AfricaLibreville",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TCO",
        "lat": "1.8208",
        "lon": "-78.7528",
        "name": "La Florida",
        "city": "Tumaco",
        "state": "Narino",
        "country": "Colombia",
        "woeid": "12512387",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TCP",
        "lat": "29.4945",
        "lon": "34.8918",
        "name": "Taba",
        "city": "Taba",
        "state": "Janub Sina'",
        "country": "Egypt",
        "woeid": "1526415",
        "tz": "AfricaCairo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TCR",
        "lat": "8.78333",
        "lon": "78.1333",
        "name": "Tuticorin",
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "country": "India",
        "woeid": "2295369",
        "tz": "AsiaKolkata",
        "phone": "00-91-461-71565",
        "type": "Airports",
        "email": "",
        "url": "http:www.airportsindia.org.in",
        "runway_length": "1350",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TCT",
        "lat": "62.9845",
        "lon": "-156.07",
        "name": "Takotna",
        "city": "Takotna",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2503638",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TDX",
        "lat": "12.2229",
        "lon": "102.593",
        "name": "Mueang Trat",
        "city": "Trat",
        "state": "Trat",
        "country": "Thailand",
        "woeid": "28341379",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TEK",
        "lat": "60.8644",
        "lon": "-146.693",
        "name": "Tatitlek Seaplane Base",
        "city": "Valdez",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12524670",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TGC",
        "lat": "35.9349",
        "lon": "-88.8506",
        "name": "Gibson County",
        "city": "Milan",
        "state": "Tennessee",
        "country": "United States",
        "woeid": "12519895",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTGC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "THO",
        "lat": "66.2",
        "lon": "-15.3333",
        "name": "Thorshofn",
        "city": "Thorshofn",
        "state": "Nordur-Tingeyjarsysla",
        "country": "Iceland",
        "woeid": "12523905",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "THS",
        "lat": "17.2167",
        "lon": "99.8167",
        "name": "Sukhothai",
        "city": "Sawankhalok",
        "state": "Sukhothai",
        "country": "Thailand",
        "woeid": "12523370",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "THU",
        "lat": "76.53",
        "lon": "-68.7058",
        "name": "Pituffik",
        "city": "Pituffik",
        "state": "Nordgronland",
        "country": "Greenland",
        "woeid": "12513033",
        "tz": "AmericaThule",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "BGTL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TIQ",
        "lat": "45.0039",
        "lon": "12.2686",
        "name": "Tinian",
        "city": "Tinian",
        "state": "Veneto",
        "country": "Northern Mariana Islands",
        "woeid": "12846096",
        "tz": "EuropeRome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TIS",
        "lat": "-10.586",
        "lon": "142.293",
        "name": "Thursday Island",
        "city": "Kubin Village",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510795",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TIU",
        "lat": "-44.3015",
        "lon": "171.223",
        "name": "Richard Pearse",
        "city": "Timaru",
        "state": "Canterbury",
        "country": "New Zealand",
        "woeid": "12515170",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "NZTU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TIZ",
        "lat": "-5.83333",
        "lon": "142.95",
        "name": "Tari",
        "city": "Mendi",
        "state": "Southern Highlands",
        "country": "Papua New Guinea",
        "woeid": "12523242",
        "tz": "PacificPort_Moresby",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TJN",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Takume",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TJQ",
        "lat": "-2.7494",
        "lon": "107.763",
        "name": "Bulutumbang",
        "city": "Tanjungpandan",
        "state": "Bangka-Belitung",
        "country": "Indonesia",
        "woeid": "12513459",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TKE",
        "lat": "57.7773",
        "lon": "-135.208",
        "name": "Tenakee Springs",
        "city": "Tenakee Springs",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2504726",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Sea Plane Base",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TKG",
        "lat": "-5.2403",
        "lon": "105.178",
        "name": "Branti",
        "city": "Tanjungkarang",
        "state": "Lampung",
        "country": "Indonesia",
        "woeid": "12513457",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4986",
        "elev": "282",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TKN",
        "lat": "27.834",
        "lon": "128.882",
        "name": "Tokunoshima",
        "city": "Amagi-cho",
        "state": "Kagoshima Prefecture",
        "country": "Japan",
        "woeid": "12514026",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TKQ",
        "lat": "-4.88005",
        "lon": "29.628",
        "name": "Tanzania",
        "city": "Kigoma",
        "state": "Kigoma",
        "country": "Tanzania",
        "woeid": "23424973",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5797",
        "elev": "2700",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TKV",
        "lat": "-17.3371",
        "lon": "-138.408",
        "name": "French Polynesia",
        "city": "Tatakoto",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTKV",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TLJ",
        "lat": "44.2044",
        "lon": "23.5396",
        "name": "Tatalina",
        "city": "Tatalina",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TLT",
        "lat": "61.0959",
        "lon": "-160.968",
        "name": "Tuluksak",
        "city": "Tuluksak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2508578",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TME",
        "lat": "6.4536",
        "lon": "-71.7614",
        "name": "Tame",
        "city": "Tame",
        "state": "Arauca",
        "country": "Colombia",
        "woeid": "12512419",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TML",
        "lat": "9.5636",
        "lon": "-0.8625",
        "name": "Tamale",
        "city": "Savelugu",
        "state": "Northern",
        "country": "Ghana",
        "woeid": "12513026",
        "tz": "AfricaAccra",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TMU",
        "lat": "9.73167",
        "lon": "-85.0251",
        "name": "Tambor",
        "city": "Lepanto",
        "state": "Puntarenas",
        "country": "Costa Rica",
        "woeid": "12524321",
        "tz": "AmericaCosta_Rica",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TNC",
        "lat": "65.5639",
        "lon": "-167.919",
        "name": "Tin City AFS",
        "city": "Tin City",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2506552",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "PATC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TNJ",
        "lat": "0.9192",
        "lon": "104.536",
        "name": "Kijang",
        "city": "Tanjungpinang",
        "state": "Riau",
        "country": "Indonesia",
        "woeid": "12513478",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TOH",
        "lat": "-15.1468",
        "lon": "167.038",
        "name": "Vanuatu",
        "city": "Torres",
        "state": "",
        "country": "Vanuatu",
        "woeid": "23424907",
        "tz": "PacificEfate",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TOM",
        "lat": "16.7314",
        "lon": "-3.0053",
        "name": "Tombouctou",
        "city": "",
        "state": "Tombouctou",
        "country": "Mali",
        "woeid": "12514779",
        "tz": "AfricaBamako",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TPJ",
        "lat": "27.2999",
        "lon": "87.7011",
        "name": "Taplejung Suketar",
        "city": "Taplejung",
        "state": "Central",
        "country": "Nepal",
        "woeid": "2268915",
        "tz": "AsiaKatmandu",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TRK",
        "lat": "3.3258",
        "lon": "117.567",
        "name": "Tarakan",
        "city": "Tarakan",
        "state": "Kalimantan Timur",
        "country": "Indonesia",
        "woeid": "12513517",
        "tz": "AsiaJakarta",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTRK",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "TRW",
        "lat": "1.38",
        "lon": "173.151",
        "name": "Bonriki",
        "city": "Tarawa",
        "state": "",
        "country": "Kiribati",
        "woeid": "12514189",
        "tz": "PacificTarawa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TSJ",
        "lat": "34.2856",
        "lon": "129.33",
        "name": "Tsushima",
        "city": "Tsushima-shi",
        "state": "Nagasaki Prefecture",
        "country": "Japan",
        "woeid": "12514032",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TSL",
        "lat": "22.0458",
        "lon": "-98.8042",
        "name": "Tamuin",
        "city": "Ciudad Valles",
        "state": "San Luis Potosi",
        "country": "Mexico",
        "woeid": "12514969",
        "tz": "AmericaMexico_City",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TSO",
        "lat": "49.9502",
        "lon": "-6.32874",
        "name": "Isles Of Scilly",
        "city": "Isles Of Scilly",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "12695899",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTSO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TST",
        "lat": "7.5081",
        "lon": "99.625",
        "name": "Trang",
        "city": "Muang Trang",
        "state": "Trang",
        "country": "Thailand",
        "woeid": "12517789",
        "tz": "AsiaBangkok",
        "phone": "075-218-224, 211-150",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TTJ",
        "lat": "35.5298",
        "lon": "134.166",
        "name": "Tottori",
        "city": "Tottori-shi",
        "state": "Tottori Prefecture",
        "country": "Japan",
        "woeid": "12514029",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TTT",
        "lat": "22.7561",
        "lon": "121.107",
        "name": "Taitung",
        "city": "Taitung City",
        "state": "Taiwan Province",
        "country": "Taiwan",
        "woeid": "12517928",
        "tz": "AsiaTaipei",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TUC",
        "lat": "-26.8397",
        "lon": "-65.1019",
        "name": "Teniente Benjamin Matienzo",
        "city": "Banda del Río Salí",
        "state": "Tucuman",
        "country": "Argentina",
        "woeid": "12510566",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TUD",
        "lat": "13.7347",
        "lon": "-13.6561",
        "name": "Tambacounda",
        "city": "Tambacounda",
        "state": "Tambacounda",
        "country": "Senegal",
        "woeid": "12517510",
        "tz": "AfricaDakar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TUG",
        "lat": "17.6417",
        "lon": "121.731",
        "name": "Tuguegarao",
        "city": "Tuguegarao",
        "state": "Cagayan Valley",
        "country": "Philippines",
        "woeid": "12515652",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "TVF",
        "lat": "48.0655",
        "lon": "-96.1791",
        "name": "Thief River Falls",
        "city": "Thief River Falls",
        "state": "Minnesota",
        "country": "United States",
        "woeid": "12522121",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTVF",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TWB",
        "lat": "-27.5444",
        "lon": "151.914",
        "name": "Toowoomba",
        "city": "Toowoomba",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510799",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:tcc.toowoomba.qld.gov.auindex.php?option=com_content&tas",
        "runway_length": "1121",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TWF",
        "lat": "42.4838",
        "lon": "-114.485",
        "name": "Twin Falls-Sun Valley",
        "city": "Twin Falls",
        "state": "Idaho",
        "country": "United States",
        "woeid": "12522233",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KTWF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "TWT",
        "lat": "7.1",
        "lon": "124.65",
        "name": "Tawitawi",
        "city": "Tawitawi",
        "state": "Soccsksargen",
        "country": "Philippines",
        "woeid": "12747940",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "TYF",
        "lat": "60.154",
        "lon": "12.9958",
        "name": "Fryklanda",
        "city": "Torsby",
        "state": "Varmland",
        "country": "Sweden",
        "woeid": "12517627",
        "tz": "EuropeStockholm",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UBJ",
        "lat": "33.931",
        "lon": "131.276",
        "name": "Yamaguchi-Ube",
        "city": "Ube-shi",
        "state": "Yamaguchi Prefecture",
        "country": "Japan",
        "woeid": "12514036",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6560",
        "elev": "23",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "UDJ",
        "lat": "48.636",
        "lon": "22.2738",
        "name": "Uzhgorod",
        "city": "Uzhhorod",
        "state": "Zakarpats´ka Oblast´",
        "country": "Ukraine",
        "woeid": "12518458",
        "tz": "EuropeKiev",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "UEO",
        "lat": "26.3677",
        "lon": "126.716",
        "name": "Kumejima",
        "city": "Kumejima-cho",
        "state": "Okinawa Prefecture",
        "country": "Japan",
        "woeid": "12513982",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3940",
        "elev": "22",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UGB",
        "lat": "57.4253",
        "lon": "-157.74",
        "name": "Ugashik Bay",
        "city": "Pilot Point",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12523252",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1245",
        "elev": "25",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UGI",
        "lat": "57.8838",
        "lon": "-153.369",
        "name": "Uganik",
        "city": "Kodiak",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522240",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UII",
        "lat": "16.0969",
        "lon": "-86.9337",
        "name": "Utila",
        "city": "Utila",
        "state": "Islas de la Bahía",
        "country": "Honduras",
        "woeid": "12513366",
        "tz": "AmericaTegucigalpa",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "16",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ULO",
        "lat": "49.9674",
        "lon": "92.0767",
        "name": "Ulaangom",
        "city": "Ulaangom",
        "state": "Uvs",
        "country": "Mongolia",
        "woeid": "12514762",
        "tz": "AsiaHovd",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ULU",
        "lat": "2.81",
        "lon": "32.2683",
        "name": "Gulu",
        "city": "Gulu",
        "state": "Gulu",
        "country": "Uganda",
        "woeid": "12518025",
        "tz": "AfricaKampala",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10203",
        "elev": "3510",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ULY",
        "lat": "54.4145",
        "lon": "48.8063",
        "name": "Ulyanovsk Northeast",
        "city": "Dimitrovgrad",
        "state": "Ulryanovskaya Oblast",
        "country": "Russia",
        "woeid": "12517077",
        "tz": "AsiaYekaterinburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UMD",
        "lat": "70.6801",
        "lon": "-52.1221",
        "name": "Uummannaq",
        "city": "Uummannaq",
        "state": "Vestgronland",
        "country": "Greenland",
        "woeid": "12523852",
        "tz": "AmericaGodthab",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": "2",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UNN",
        "lat": "9.85278",
        "lon": "98.6292",
        "name": "Ranong",
        "city": "Ranong",
        "state": "Ranong",
        "country": "Thailand",
        "woeid": "12523324",
        "tz": "AsiaBangkok",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3529",
        "elev": "115",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "URG",
        "lat": "-29.7817",
        "lon": "-57.0383",
        "name": "Rubem Berta",
        "city": "Uruguaiana",
        "state": "Rio Grande do Sul",
        "country": "Brazil",
        "woeid": "12511295",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "243",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "USQ",
        "lat": "38.681",
        "lon": "29.4716",
        "name": "Usak",
        "city": "Usak",
        "state": "Uşak",
        "country": "Turkey",
        "woeid": "2347315",
        "tz": "EuropeIstanbul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UTT",
        "lat": "-31.5472",
        "lon": "28.6694",
        "name": "K D Matanzima",
        "city": "Umtata",
        "state": "Eastern Cape",
        "country": "South Africa",
        "woeid": "12517426",
        "tz": "AfricaJohannesburg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6650",
        "elev": "2400",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "UUN",
        "lat": "46.663",
        "lon": "113.287",
        "name": "Baruun Urt",
        "city": "Baruun-Urt",
        "state": "Suhbaatar",
        "country": "Mongolia",
        "woeid": "12514734",
        "tz": "AsiaChoibalsan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VAO",
        "lat": "7.56667",
        "lon": "154.667",
        "name": "Suavanao Airstrip",
        "city": "Suavanao",
        "state": "",
        "country": "Solomon Islands",
        "woeid": "23424766",
        "tz": "PacificNoumea",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VBM",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Blue Mountain",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaNome",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VBV",
        "lat": "16.4843",
        "lon": "73.746",
        "name": "Vanuabalavu",
        "city": "Vanuabalavu",
        "state": "",
        "country": "Fiji",
        "woeid": "23424813",
        "tz": "PacificFiji",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3018",
        "elev": "156",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VCL",
        "lat": "15.9742",
        "lon": "107.868",
        "name": "Chulai",
        "city": "Tamky",
        "state": "",
        "country": "Vietnam",
        "woeid": "23424984",
        "tz": "AsiaVientiane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VCS",
        "lat": "15.9742",
        "lon": "107.868",
        "name": "Vietnam",
        "city": "Con Dao",
        "state": "Ca Mau",
        "country": "Vietnam",
        "woeid": "23424984",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VCT",
        "lat": "28.8449",
        "lon": "-96.9201",
        "name": "Victoria",
        "city": "Victoria",
        "state": "Texas",
        "country": "United States",
        "woeid": "12522283",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9102",
        "elev": "115",
        "icao": "KVCT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VCV",
        "lat": "34.588",
        "lon": "-117.373",
        "name": "Southern California Logistics",
        "city": "Victorville",
        "state": "California",
        "country": "United States",
        "woeid": "12519884",
        "tz": "AmericaLos_Angeles",
        "phone": "",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "15050",
        "elev": "2885",
        "icao": "KVCV",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VDA",
        "lat": "29.9528",
        "lon": "34.9583",
        "name": "Ovda",
        "city": "Ovda",
        "state": "HaDarom",
        "country": "Israel",
        "woeid": "12513788",
        "tz": "AsiaJerusalem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "1490",
        "icao": "LLOV",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VDC",
        "lat": "-14.8633",
        "lon": "-40.8628",
        "name": "Vitoria da Conquista",
        "city": "Vitória da Conquista",
        "state": "Bahia",
        "country": "Brazil",
        "woeid": "12511375",
        "tz": "AmericaBelem",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5827",
        "elev": "2998",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VDM",
        "lat": "-40.8697",
        "lon": "-62.9994",
        "name": "Gobernador Castello",
        "city": "Viedma",
        "state": "Rio Negro",
        "country": "Argentina",
        "woeid": "12510503",
        "tz": "AmericaBuenos_Aires",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8431",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VDZ",
        "lat": "61.1294",
        "lon": "-146.256",
        "name": "Valdez",
        "city": "Valdez",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12522264",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "120",
        "icao": "PAVD",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VEL",
        "lat": "40.4485",
        "lon": "-109.514",
        "name": "Vernal",
        "city": "Vernal",
        "state": "Utah",
        "country": "United States",
        "woeid": "12522278",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6601",
        "elev": "5274",
        "icao": "KVEL",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "VEY",
        "lat": "63.4273",
        "lon": "-20.2682",
        "name": "Vestmannaeyjar",
        "city": "Vestmannaeyjar",
        "state": "Vestmannaeyjar",
        "country": "Iceland",
        "woeid": "12513447",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3904",
        "elev": "328",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VGZ",
        "lat": "1.06667",
        "lon": "-76.7167",
        "name": "Villagarzon",
        "city": "Villagarzon",
        "state": "Putumayo",
        "country": "Colombia",
        "woeid": "12523760",
        "tz": "AmericaBogota",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3412",
        "elev": "2622",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VHZ",
        "lat": "-17.6902",
        "lon": "-149.376",
        "name": "French Polynesia",
        "city": "Vahitahi",
        "state": "Tuamotu-Gambier",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VIG",
        "lat": "39.2505",
        "lon": "-82.7916",
        "name": "El Vigia",
        "city": "El Vigia",
        "state": "Mérida",
        "country": "Venezuela",
        "woeid": "468413",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10630",
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VII",
        "lat": "18.6688",
        "lon": "105.672",
        "name": "Vinh",
        "city": "Vinh City",
        "state": "Nghe An",
        "country": "Vietnam",
        "woeid": "1252662",
        "tz": "AsiaHo_Chi_Minh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VKT",
        "lat": "67.491",
        "lon": "63.995",
        "name": "Vorkuta",
        "city": "Ukhta",
        "state": "Komi",
        "country": "Russia",
        "woeid": "12517192",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VLD",
        "lat": "30.7892",
        "lon": "-83.2787",
        "name": "Valdosta",
        "city": "Valdosta",
        "state": "Georgia",
        "country": "United States",
        "woeid": "12522265",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6302",
        "elev": "204",
        "icao": "KVLD",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "VLV",
        "lat": "9.3414",
        "lon": "-70.5844",
        "name": "Dr Antonio Nicolas Briceno",
        "city": "Siquisique",
        "state": "Trujillo",
        "country": "Venezuela",
        "woeid": "12522773",
        "tz": "AmericaCaracas",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6726",
        "elev": "2060",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VLY",
        "lat": "52.3685",
        "lon": "-3.98509",
        "name": "Anglesey",
        "city": "Anglesey",
        "state": "Wales",
        "country": "United Kingdom",
        "woeid": "12578049",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VOL",
        "lat": "39.2203",
        "lon": "22.7947",
        "name": "Nea Anchialos",
        "city": "Almiros",
        "state": "Thessalia",
        "country": "Greece",
        "woeid": "12513314",
        "tz": "EuropeAthens",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "90",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VPN",
        "lat": "65.7177",
        "lon": "-14.8348",
        "name": "Vopnafjordur",
        "city": "Egilsstadir",
        "state": "Nordur-Mulasysla",
        "country": "Iceland",
        "woeid": "12523906",
        "tz": "AtlanticReykjavik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3268",
        "elev": "31",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VPY",
        "lat": "-19.1506",
        "lon": "33.4311",
        "name": "Chimoio",
        "city": "Chimoio",
        "state": "Manica",
        "country": "Mozambique",
        "woeid": "12515015",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VRC",
        "lat": "13.5792",
        "lon": "124.204",
        "name": "Virac",
        "city": "Virac",
        "state": "Bicol Region",
        "country": "Philippines",
        "woeid": "12515654",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5118",
        "elev": "121",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "VRY",
        "lat": "67.6667",
        "lon": "12.6833",
        "name": "Vaeroy",
        "city": "Varoy",
        "state": "Nordland Fylke",
        "country": "Norway",
        "woeid": "12523955",
        "tz": "EuropeOslo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2625",
        "elev": "36",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VTU",
        "lat": "20.9894",
        "lon": "-76.9361",
        "name": "Las Tunas",
        "city": "Becerra",
        "state": "Las Tunas",
        "country": "Cuba",
        "woeid": "12512470",
        "tz": "AmericaHavana",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5906",
        "elev": "312",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "VXC",
        "lat": "-13.2761",
        "lon": "35.2533",
        "name": "Lichinga",
        "city": "Lichinga",
        "state": "Niassa",
        "country": "Mozambique",
        "woeid": "12515019",
        "tz": "AfricaMaputo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8200",
        "elev": "4505",
        "icao": "FQLC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WAE",
        "lat": "23.9175",
        "lon": "45.1202",
        "name": "Saudi Arabia",
        "city": "Wadi Ad Dawasir",
        "state": "",
        "country": "Saudi Arabia",
        "woeid": "23424938",
        "tz": "AsiaRiyadh",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WAQ",
        "lat": "-15.7137",
        "lon": "46.3155",
        "name": "Mahajanga",
        "city": "Antsalova",
        "state": "Mahajanga",
        "country": "Madagascar",
        "woeid": "1361960",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3018",
        "elev": "551",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WHF",
        "lat": "21.7929",
        "lon": "31.3713",
        "name": "Wadi Halfa'",
        "city": "Wadi Halfa",
        "state": "Ash Shamaliyah",
        "country": "Sudan",
        "woeid": "1437351",
        "tz": "AfricaKhartoum",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WJU",
        "lat": "37.4904",
        "lon": "127.989",
        "name": "Wonju",
        "city": "WonJu",
        "state": "Kangwon-Do",
        "country": "South Korea",
        "woeid": "28808960",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WKA",
        "lat": "-44.725",
        "lon": "169.243",
        "name": "Wanaka",
        "city": "Wanaka",
        "state": "Otago",
        "country": "New Zealand",
        "woeid": "12515173",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2545",
        "elev": "1143",
        "icao": "NZWF",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WKK",
        "lat": "59.2667",
        "lon": "-158.633",
        "name": "Aleknagik",
        "city": "Aleknagik",
        "state": "Alaska",
        "country": "United States",
        "woeid": "12799627",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1800",
        "elev": "75",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WMR",
        "lat": "-16.167",
        "lon": "49.7556",
        "name": "Mananara",
        "city": "Mananara",
        "state": "Toamasina",
        "country": "Madagascar",
        "woeid": "1362331",
        "tz": "IndianAntananarivo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2543",
        "elev": "10",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WNP",
        "lat": "13.5872",
        "lon": "123.27",
        "name": "Naga",
        "city": "Pili",
        "state": "Bicol Region",
        "country": "Philippines",
        "woeid": "12515636",
        "tz": "AsiaManila",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4205",
        "elev": "141",
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "WSN",
        "lat": "58.7285",
        "lon": "-157.014",
        "name": "Naknek",
        "city": "South Naknek",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2456884",
        "tz": "AmericaAnchorage",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "130",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WST",
        "lat": "41.3492",
        "lon": "-71.8115",
        "name": "Westerly State",
        "city": "Westerly",
        "state": "Rhode Island",
        "country": "United States",
        "woeid": "12522397",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "81",
        "icao": "KWST",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WSZ",
        "lat": "-41.7383",
        "lon": "171.58",
        "name": "Westport",
        "city": "Westport",
        "state": "West Coast",
        "country": "New Zealand",
        "woeid": "12515176",
        "tz": "PacificAuckland",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4200",
        "elev": "13",
        "icao": "NZWS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WUA",
        "lat": "34.668",
        "lon": "104.166",
        "name": "Wu Hai",
        "city": "Wu Hai",
        "state": "",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WWP",
        "lat": "62.8903",
        "lon": "-149.054",
        "name": "Alaska",
        "city": "Whale Pass",
        "state": "Alaska",
        "country": "United States",
        "woeid": "2347560",
        "tz": "AmericaJuneau",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WYA",
        "lat": "-33.0608",
        "lon": "137.516",
        "name": "Whyalla",
        "city": "Whyalla",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510812",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5530",
        "elev": "41",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "WYS",
        "lat": "44.6876",
        "lon": "-111.114",
        "name": "Yellowstone",
        "city": "West Yellowstone",
        "state": "Montana",
        "country": "United States",
        "woeid": "12522531",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8399",
        "elev": "6644",
        "icao": "KWYS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XAP",
        "lat": "-27.1325",
        "lon": "-52.6611",
        "name": "Chapeco",
        "city": "Chapeco",
        "state": "Santa Catarina",
        "country": "Brazil",
        "woeid": "12511090",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4921",
        "elev": "2133",
        "icao": "SBCH",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "XCR",
        "lat": "48.7761",
        "lon": "4.1917",
        "name": "Europort Vatry",
        "city": "Coole",
        "state": "Champagne-Ardenne",
        "country": "France",
        "woeid": "12512984",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XET",
        "lat": "51.5039",
        "lon": "0.04981",
        "name": "London City",
        "city": "Londra City",
        "state": "England",
        "country": "United Kingdom",
        "woeid": "22475376",
        "tz": "EuropeLondon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1",
        "bookingId": "-2601889"
    },
    {
        "code": "XFW",
        "lat": "53.5356",
        "lon": "9.8342",
        "name": "Finkenwerder",
        "city": "Hamburg",
        "state": "Hamburg",
        "country": "Germany",
        "woeid": "12513120",
        "tz": "EuropeBerlin",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "EDHI",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XIL",
        "lat": "43.9667",
        "lon": "116.067",
        "name": "Xilinhot",
        "city": "Xilin Hot",
        "state": "Nei Mongol",
        "country": "China",
        "woeid": "12512248",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XLS",
        "lat": "16.0503",
        "lon": "-16.4603",
        "name": "Saint Louis",
        "city": "",
        "state": "St-Louis",
        "country": "Senegal",
        "woeid": "12517509",
        "tz": "AfricaDakar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6372",
        "elev": "13",
        "icao": "GOSS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XMS",
        "lat": "-2.335",
        "lon": "-78.1669",
        "name": "Macas",
        "city": "Zúñac",
        "state": "Morona Santiago",
        "country": "Ecuador",
        "woeid": "12512640",
        "tz": "AmericaGuayaquil",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "8366",
        "elev": "3281",
        "icao": "SEMC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XMY",
        "lat": "-10.4833",
        "lon": "142.45",
        "name": "Yam Island",
        "city": "Yam Island",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "28645136",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XNB",
        "lat": "-3.08333",
        "lon": "-67.9333",
        "name": "Sinop",
        "city": "Santo Antônio do Içá",
        "state": "Amazonas",
        "country": "Brazil",
        "woeid": "12523221",
        "tz": "AmericaPorto_Velho",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XNY",
        "lat": "42.8767",
        "lon": "-77.8826",
        "name": "York",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "woeid": "2524848",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XPQ",
        "lat": "3",
        "lon": "101.4",
        "name": "Port Klang",
        "city": "Port Klang",
        "state": "Selangor",
        "country": "Malaysia",
        "woeid": "1152513",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XSI",
        "lat": "56.7819",
        "lon": "-98.9558",
        "name": "South Indian Lake",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524069",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2600",
        "elev": "948",
        "icao": "",
        "direct_flights": "1",
        "carriers": "2"
    },
    {
        "code": "XTG",
        "lat": "-27.9829",
        "lon": "143.812",
        "name": "Thargomindah Aerodrome",
        "city": "Thargomindah",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510794",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "YTGM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XYA",
        "lat": "-9.06667",
        "lon": "159.233",
        "name": "Yandina",
        "city": "Yandina",
        "state": "Guadalcanal",
        "country": "Solomon Islands",
        "woeid": "1020497",
        "tz": "PacificGuadalcanal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5921",
        "elev": "20",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XZJ",
        "lat": "37.7167",
        "lon": "28.9667",
        "name": "Rail",
        "city": "Rail (Generic)",
        "state": "",
        "country": "Japan",
        "woeid": "23424856",
        "tz": "AsiaTokyo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XZM",
        "lat": "-7.45",
        "lon": "-70.55",
        "name": "Macau Ferry",
        "city": "Macau",
        "state": "Amazonas",
        "country": "Macao",
        "woeid": "12824753",
        "tz": "AmericaRio_Branco",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "XZV",
        "lat": "46.4833",
        "lon": "-0.633333",
        "name": "TGV Station",
        "city": "Toulon",
        "state": "Pays de la Loire",
        "country": "France",
        "woeid": "624561",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "YAO",
        "lat": "3.8336",
        "lon": "11.5236",
        "name": "Yaounde",
        "city": "Yaounde",
        "state": "Centre",
        "country": "Cameroon",
        "woeid": "12512354",
        "tz": "AfricaDouala",
        "phone": "+2372.2233602",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "6562",
        "elev": "2464",
        "icao": "FKKY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YBR",
        "lat": "49.9036",
        "lon": "-99.943",
        "name": "Brandon",
        "city": "Killarney",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12511586",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "http:www.brandon.cawelcome.nsf0be477a354605b4d86256b6500798d",
        "runway_length": "6500",
        "elev": "1343",
        "icao": "CYBR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YBV",
        "lat": "52.4333",
        "lon": "-97.0333",
        "name": "Berens River",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524086",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "728",
        "icao": "CYBV",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YBW",
        "lat": "48.7512",
        "lon": "-123.225",
        "name": "Bedwell Harbor",
        "city": "Bedwell Harbor",
        "state": "",
        "country": "Canada",
        "woeid": "12524103",
        "tz": "AmericaResolute",
        "phone": "",
        "type": "Harbours",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YCR",
        "lat": "54.6042",
        "lon": "-97.7607",
        "name": "Cross Lake",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524092",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "709",
        "icao": "CYCR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YES",
        "lat": "34.4382",
        "lon": "-104.611",
        "name": "Yasouj",
        "city": "Yasouj",
        "state": "",
        "country": "Iran",
        "woeid": "23424851",
        "tz": "AsiaTehran",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YFJ",
        "lat": "64.1904",
        "lon": "-114.183",
        "name": "Snare Lake",
        "city": "Snare Lake",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "29374024",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YGB",
        "lat": "49.6942",
        "lon": "-124.516",
        "name": "Gillies Bay",
        "city": "Gibsons",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511870",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2500",
        "elev": "300",
        "icao": "CYGB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YGG",
        "lat": "48.85",
        "lon": "-123.5",
        "name": "Ganges Harbor",
        "city": "Saltspring Island",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524124",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Harbours",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YGK",
        "lat": "44.2333",
        "lon": "-76.5833",
        "name": "Kingston",
        "city": "Kingston",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511717",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.cityofkingston.caresidentstransportationairportin",
        "runway_length": "5000",
        "elev": "305",
        "icao": "CYGK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YGZ",
        "lat": "76.425",
        "lon": "-82.9458",
        "name": "Grise Fiord",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524133",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "146",
        "icao": "CYGZ",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YIP",
        "lat": "42.2385",
        "lon": "-83.5467",
        "name": "Willow Run",
        "city": "Ypsilanti",
        "state": "Michigan",
        "country": "United States",
        "woeid": "12522472",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "7522",
        "elev": "716",
        "icao": "KYIP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YKT",
        "lat": "52.5995",
        "lon": "-128.529",
        "name": "Klemtu Water Aerodrome",
        "city": "Masset",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524159",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YLC",
        "lat": "62.85",
        "lon": "-69.8833",
        "name": "Lake Harbour",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524162",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "1700",
        "elev": "200",
        "icao": "CYLC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YLE",
        "lat": "63.15",
        "lon": "-117.267",
        "name": "Lac la Martre Aerodrome",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524163",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2200",
        "elev": "890",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YLL",
        "lat": "53.3122",
        "lon": "-110.073",
        "name": "Lloydminster",
        "city": "Vegreville",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511734",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5577",
        "elev": "2194",
        "icao": "CYLL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YND",
        "lat": "45.5179",
        "lon": "-75.5618",
        "name": "Ottawa Gatineau",
        "city": "Gatineau",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511778",
        "tz": "AmericaToronto",
        "phone": "(819)-663-0737",
        "type": "Other",
        "email": "infos@ego-airport.ca",
        "url": "http:www.ego-airport.ca",
        "runway_length": "6000",
        "elev": "192",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YNG",
        "lat": "41.2576",
        "lon": "-80.6648",
        "name": "Youngstown-Warren",
        "city": "Vienna",
        "state": "Ohio",
        "country": "United States",
        "woeid": "12522541",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7492",
        "elev": "1196",
        "icao": "KYNG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YNY",
        "lat": "38.0481",
        "lon": "128.656",
        "name": "Yang Yang",
        "city": "Sonyang-Myeon",
        "state": "Kangwon-Do",
        "country": "South Korea",
        "woeid": "28808959",
        "tz": "AsiaSeoul",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YOG",
        "lat": "51.6291",
        "lon": "-85.954",
        "name": "Ogoki Aerodrome",
        "city": "Marathon",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524189",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2000",
        "elev": "602",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YPB",
        "lat": "49.2618",
        "lon": "-124.836",
        "name": "Port Alberni",
        "city": "Lake Cowichan",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524192",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2100",
        "elev": "8",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YPE",
        "lat": "56.2314",
        "lon": "-117.448",
        "name": "Peace River",
        "city": "Brownvale",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511783",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "1873",
        "icao": "CYPE",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YPO",
        "lat": "54.9906",
        "lon": "-85.4393",
        "name": "Peawanuck",
        "city": "Winisk",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12524200",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "174",
        "icao": "CYPO",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YPW",
        "lat": "49.8369",
        "lon": "-124.496",
        "name": "Powell River",
        "city": "Powell River",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511799",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3760",
        "elev": "425",
        "icao": "CYPW",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YQG",
        "lat": "42.2657",
        "lon": "-82.9602",
        "name": "Windsor",
        "city": "Windsor",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511916",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "7900",
        "elev": "622",
        "icao": "CYQG",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YQL",
        "lat": "49.6344",
        "lon": "-112.787",
        "name": "Lethbridge",
        "city": "Pincher",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511730",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.lethbridgecountyairport.com",
        "runway_length": "6500",
        "elev": "3047",
        "icao": "CYQL",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YRA",
        "lat": "64.1111",
        "lon": "-117.353",
        "name": "Rae Lakes Aerodrome",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524207",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2800",
        "elev": "700",
        "icao": "CYRA",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YRS",
        "lat": "54.1623",
        "lon": "-93.5887",
        "name": "Red Sucker Lake",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524214",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "729",
        "icao": "CYRS",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YSG",
        "lat": "62.4009",
        "lon": "-110.693",
        "name": "Snowdrift Aerodrome",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524218",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2240",
        "elev": "575",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YSK",
        "lat": "56.2385",
        "lon": "-78.8122",
        "name": "Sanikiluaq",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524220",
        "tz": "AmericaIqaluit",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3800",
        "elev": "107",
        "icao": "CYSK",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YST",
        "lat": "20.9417",
        "lon": "95.9181",
        "name": "Shante",
        "city": "Meiktila",
        "state": "Mandalay",
        "country": "Myanmar",
        "woeid": "12510935",
        "tz": "AsiaRangoon",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "773",
        "icao": "CYST",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YSY",
        "lat": "72",
        "lon": "-125.267",
        "name": "Sachs Harbour",
        "city": "Hay River",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12523262",
        "tz": "AmericaInuvik",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4000",
        "elev": "281",
        "icao": "CYSY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YTM",
        "lat": "46.25",
        "lon": "-74.5833",
        "name": "La Macaza",
        "city": "Mont Tremblant",
        "state": "Quebec",
        "country": "Canada",
        "woeid": "23707674",
        "tz": "AmericaMontreal",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YUB",
        "lat": "69.4335",
        "lon": "-133.02",
        "name": "Tuktoyaktuk",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524233",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYUB",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YVA",
        "lat": "-11.7075",
        "lon": "43.2342",
        "name": "Moroni Iconi",
        "city": "Moroni",
        "state": "Grande Comore",
        "country": "Comoros",
        "woeid": "12512358",
        "tz": "IndianComoro",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "4445",
        "elev": "33",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YVM",
        "lat": "67.5412",
        "lon": "-63.9855",
        "name": "Broughton Island",
        "city": "Iqaluit",
        "state": "Nunavut",
        "country": "Canada",
        "woeid": "12524254",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3475",
        "elev": "15",
        "icao": "CYVM",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "YWS",
        "lat": "50.1333",
        "lon": "-122.95",
        "name": "Green Lake Water Aerodrome",
        "city": "Gibsons",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12523277",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YXH",
        "lat": "50.0219",
        "lon": "-110.722",
        "name": "Medicine Hat",
        "city": "Medicine Hat",
        "state": "Alberta",
        "country": "Canada",
        "woeid": "12511748",
        "tz": "AmericaEdmonton",
        "phone": "403-526-4664",
        "type": "Airports",
        "email": "",
        "url": "http:www.medicinehat.cacityservicesairport",
        "runway_length": "5000",
        "elev": "2352",
        "icao": "CYXH",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YYU",
        "lat": "49.4098",
        "lon": "-82.4618",
        "name": "Kapuskasing",
        "city": "Kapuskasing",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511711",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5500",
        "elev": "743",
        "icao": "CYYU",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YZP",
        "lat": "53.2528",
        "lon": "-131.814",
        "name": "Sandspit",
        "city": "Masset",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511824",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5120",
        "elev": "21",
        "icao": "CYZP",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YZR",
        "lat": "42.9965",
        "lon": "-82.3078",
        "name": "Sarnia",
        "city": "Sarnia",
        "state": "Ontario",
        "country": "Canada",
        "woeid": "12511825",
        "tz": "AmericaToronto",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5100",
        "elev": "594",
        "icao": "CYZR",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "YZZ",
        "lat": "37.0625",
        "lon": "-95.6771",
        "name": "Trail",
        "city": "Trail",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "9923",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZAC",
        "lat": "56.0763",
        "lon": "-96.09",
        "name": "York Landing",
        "city": "Nelson House",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524282",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3000",
        "elev": "609",
        "icao": "CZAC",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZAT",
        "lat": "27.3167",
        "lon": "103.8",
        "name": "Zhaotong",
        "city": "Zhaotong",
        "state": "Yunnan",
        "country": "China",
        "woeid": "2160703",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZEI",
        "lat": "12.9794",
        "lon": "11.8559",
        "name": "Zei",
        "city": "Zei",
        "state": "Yobe",
        "country": "Nigeria",
        "woeid": "1511177",
        "tz": "AfricaLagos",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZFM",
        "lat": "67.4024",
        "lon": "-134.849",
        "name": "Fort Mcpherson",
        "city": "Hay River",
        "state": "Northwest Territories",
        "country": "Canada",
        "woeid": "12524289",
        "tz": "AmericaEdmonton",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3500",
        "elev": "142",
        "icao": "CZFM",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZGN",
        "lat": "22.5167",
        "lon": "113.367",
        "name": "Zhongshan Ferry Port",
        "city": "Zhongshan",
        "state": "Guangdong",
        "country": "China",
        "woeid": "2161664",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZIG",
        "lat": "12.5561",
        "lon": "-16.2758",
        "name": "Ziguinchor",
        "city": "Ziguinchor",
        "state": "Ziguinchor",
        "country": "Senegal",
        "woeid": "12517511",
        "tz": "AfricaDakar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4413",
        "elev": "82",
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZJT",
        "lat": "1.58333",
        "lon": "110.483",
        "name": "Tanjung Pelepas",
        "city": "Tanjung Pelepas",
        "state": "",
        "country": "Malaysia",
        "woeid": "23424901",
        "tz": "AsiaKuala_Lumpur",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZML",
        "lat": "42.9471",
        "lon": "-87.9051",
        "name": "General Mitchell",
        "city": "Milwaukee",
        "state": "Wisconsin",
        "country": "United States",
        "woeid": "12519880",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZMT",
        "lat": "54.012",
        "lon": "-132.134",
        "name": "Masset",
        "city": "Masset",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12524297",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": null,
        "icao": "CZMT",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZNE",
        "lat": "-23.4169",
        "lon": "119.803",
        "name": "Newman",
        "city": "Newman",
        "state": "Western Australia",
        "country": "Australia",
        "woeid": "12510751",
        "tz": "AustraliaPerth",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6800",
        "elev": "1724",
        "icao": "YNWN",
        "direct_flights": "1",
        "carriers": "3"
    },
    {
        "code": "ZTA",
        "lat": "41.3",
        "lon": "-6.46667",
        "name": "Tureira",
        "city": "Tureira",
        "state": "",
        "country": "French Polynesia",
        "woeid": "23424817",
        "tz": "PacificMidway",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "NTGY",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZTI",
        "lat": "22.8267",
        "lon": "113.672",
        "name": "Humen Port",
        "city": "Humen",
        "state": "Guangdong",
        "country": "China",
        "woeid": "2161939",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZUN",
        "lat": "35.0843",
        "lon": "-108.789",
        "name": "Black Rock",
        "city": "Zuni",
        "state": "New Mexico",
        "country": "United States",
        "woeid": "12518848",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "KZUN",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZYK",
        "lat": "30.7",
        "lon": "114.35",
        "name": "Shekou Port",
        "city": "Shekou",
        "state": "湖北省",
        "country": "China",
        "woeid": "12713102",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "1",
        "carriers": "1"
    },
    {
        "code": "ZYL",
        "lat": "24.9589",
        "lon": "91.8744",
        "name": "Osmany Sylhet",
        "city": "Sylhet",
        "state": "Sylhet",
        "country": "Bangladesh",
        "woeid": "12510892",
        "tz": "AsiaDhaka",
        "phone": "0821-714243, 718459",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "10500",
        "elev": "50",
        "icao": "VGSY",
        "direct_flights": "1",
        "carriers": "8"
    },
    {
        "code": "ACS",
        "lat": "47.7006",
        "lon": "18.0064",
        "name": "Achinsk",
        "city": "Achinsk",
        "state": "Krasnoyarskiy Kray",
        "country": "Russia",
        "woeid": "1979926",
        "tz": "AsiaKrasnoyarsk",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "APA",
        "lat": "39.5752",
        "lon": "-104.845",
        "name": "Centennial",
        "city": "Englewood",
        "state": "Colorado",
        "country": "United States",
        "woeid": "12519112",
        "tz": "AmericaDenver",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "10002",
        "elev": "5883",
        "icao": "KAPA",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "BDR",
        "lat": "41.1636",
        "lon": "-73.1283",
        "name": "Igor I Sikorsky Memorial",
        "city": "Stratford",
        "state": "Connecticut",
        "country": "United States",
        "woeid": "12520279",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4761",
        "elev": "10",
        "icao": "KBDR",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "BOW",
        "lat": "27.9493",
        "lon": "-81.7818",
        "name": "Bartow Municipal",
        "city": "Bartow",
        "state": "Florida",
        "country": "United States",
        "woeid": "12518749",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "5000",
        "elev": "128",
        "icao": "KBOW",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "BSR",
        "lat": "30.5481",
        "lon": "47.6658",
        "name": "Basrah",
        "city": "Gawad",
        "state": "Al Basrah",
        "country": "Iraq",
        "woeid": "12513895",
        "tz": "AsiaBaghdad",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": "5971",
        "elev": "9",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "BZK",
        "lat": "53.2667",
        "lon": "34.3333",
        "name": "Briansk",
        "city": "Briansk",
        "state": "Tsentral'Niy",
        "country": "Russian Federation",
        "woeid": "2346892",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "DGM",
        "lat": "27.0667",
        "lon": "105.2",
        "name": "Dongguan",
        "city": "Dongguan",
        "state": "贵州省",
        "country": "China",
        "woeid": "12713509",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Other",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ENS",
        "lat": "52.2678",
        "lon": "6.89152",
        "name": "Enschede Twente",
        "city": "Enschede",
        "state": "Overijssel",
        "country": "Netherlands",
        "woeid": "12515094",
        "tz": "EuropeAmsterdam",
        "phone": "+31(0)53-4862222",
        "type": "Other",
        "email": "",
        "url": "http:www.enschede-airport.nl",
        "runway_length": "9801",
        "elev": "114",
        "icao": "EHTW",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "FMY",
        "lat": "26.5815",
        "lon": "-81.862",
        "name": "Page Field",
        "city": "Fort Myers",
        "state": "Florida",
        "country": "United States",
        "woeid": "12521276",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6401",
        "elev": "18",
        "icao": "KFMY",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "HUZ",
        "lat": "23.0957",
        "lon": "114.398",
        "name": "Huizhou",
        "city": "Huizhou",
        "state": "Guangdong",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaChongqing",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "IGM",
        "lat": "35.2692",
        "lon": "-113.957",
        "name": "Kingman",
        "city": "Kingman",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12520468",
        "tz": "AmericaPhoenix",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6831",
        "elev": "3446",
        "icao": "KIGM",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "IJK",
        "lat": "56.8346",
        "lon": "53.2026",
        "name": "Izhevsk",
        "city": "Izhevsk",
        "state": "Udmurtiya",
        "country": "Russia",
        "woeid": "23424936",
        "tz": "EuropeSamara",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.izhavia.udm.ru",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "LDV",
        "lat": "48.5303",
        "lon": "-4.1508",
        "name": "Landivisiau",
        "city": "Landivisiau",
        "state": "Brittany",
        "country": "France",
        "woeid": "12512911",
        "tz": "EuropeParis",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "LUF",
        "lat": "33.5355",
        "lon": "-112.372",
        "name": "Luke Air Force Base",
        "city": "Webb",
        "state": "Arizona",
        "country": "United States",
        "woeid": "12520723",
        "tz": "AmericaPhoenix",
        "phone": "623 856 6376",
        "type": "Military",
        "email": "",
        "url": "",
        "runway_length": "9910",
        "elev": "1085",
        "icao": "KLUF",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "MWN",
        "lat": "-3.59224",
        "lon": "33.5603",
        "name": "Mwadui",
        "city": "Mwadui",
        "state": "Shinyanga",
        "country": "Tanzania",
        "woeid": "1448060",
        "tz": "AfricaDar_es_Salaam",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "http:www.mwadui.com",
        "runway_length": "5249",
        "elev": "3970",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "NAC",
        "lat": "-36.9803",
        "lon": "140.728",
        "name": "Naracoorte",
        "city": "Naracoorte",
        "state": "South Australia",
        "country": "Australia",
        "woeid": "12510747",
        "tz": "AustraliaAdelaide",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3440",
        "elev": "169",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "NLF",
        "lat": "-9.5925",
        "lon": "143.773",
        "name": "Darnley Island",
        "city": "Kubin Village",
        "state": "Queensland",
        "country": "Australia",
        "woeid": "12510650",
        "tz": "AustraliaBrisbane",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "NVR",
        "lat": "58.5167",
        "lon": "31.3",
        "name": "Novgorod",
        "city": "Novgorod",
        "state": "Novgorodskaya Oblast",
        "country": "Russia",
        "woeid": "2122471",
        "tz": "EuropeMoscow",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "OFI",
        "lat": "9.6",
        "lon": "-4.03333",
        "name": "Ouango Fitini",
        "city": "Ouango Fitini",
        "state": "Bouna",
        "country": "Cote D'Ivoire",
        "woeid": "1345337",
        "tz": "AfricaAbidjan",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "3937",
        "elev": "974",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ONL",
        "lat": "42.4655",
        "lon": "-98.6866",
        "name": "The Oneill Municipal",
        "city": "O'neill",
        "state": "Nebraska",
        "country": "United States",
        "woeid": "12522114",
        "tz": "AmericaChicago",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "4400",
        "elev": "2030",
        "icao": "KONL",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ORH",
        "lat": "42.2699",
        "lon": "-71.8706",
        "name": "Worcester Municipal",
        "city": "Worcester",
        "state": "Massachusetts",
        "country": "United States",
        "woeid": "12522514",
        "tz": "AmericaNew_York",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "6999",
        "elev": "1008",
        "icao": "KORH",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ORJ",
        "lat": "4.71667",
        "lon": "-60.0333",
        "name": "Orinduik",
        "city": "Rera",
        "state": "Roraima",
        "country": "Brazil",
        "woeid": "12524536",
        "tz": "AmericaManaus",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "2826",
        "elev": "1800",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "SJK",
        "lat": "-23.2283",
        "lon": "-45.8706",
        "name": "Sao Jose dos Campos",
        "city": "Sao Jose dos Campos",
        "state": "Sao Paulo",
        "country": "Brazil",
        "woeid": "12511318",
        "tz": "AmericaSao_Paulo",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": "9843",
        "elev": "2129",
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "SZV",
        "lat": "31.3092",
        "lon": "120.613",
        "name": "China",
        "city": "Suzhou",
        "state": "Jiangsu",
        "country": "China",
        "woeid": "23424781",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "TNZ",
        "lat": "50.2394",
        "lon": "99.8604",
        "name": "Hovsgol",
        "city": "Tosontsengel",
        "state": "Hovsgol",
        "country": "Mongolia",
        "woeid": "2346167",
        "tz": "AsiaUlaanbaatar",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "TTU",
        "lat": "35.5953",
        "lon": "-5.3261",
        "name": "Sania Ramel",
        "city": "Tetouan",
        "state": "Tetouan",
        "country": "Morocco",
        "woeid": "12514792",
        "tz": "AfricaCasablanca",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "YAZ",
        "lat": "49.0797",
        "lon": "-125.769",
        "name": "Tofino",
        "city": "Lake Cowichan",
        "state": "British Columbia",
        "country": "Canada",
        "woeid": "12511879",
        "tz": "AmericaVancouver",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CYAZ",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ZEJ",
        "lat": "47.7134",
        "lon": "22.8887",
        "name": "Satu Mare",
        "city": "",
        "state": "Satu Mare",
        "country": "Romania",
        "woeid": "12515569",
        "tz": "EuropeBucharest",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ZMY",
        "lat": "43.8321",
        "lon": "4.36677",
        "name": "Huangpu Harbour",
        "city": "Huangpu",
        "state": "Shanghai",
        "country": "China",
        "woeid": "22726048",
        "tz": "AsiaShanghai",
        "phone": "",
        "type": "Harbours",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "ZSN",
        "lat": "56.7819",
        "lon": "-98.9558",
        "name": "South Indian Lake",
        "city": "South Indian Lake",
        "state": "Manitoba",
        "country": "Canada",
        "woeid": "12524069",
        "tz": "AmericaWinnipeg",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "CZSN",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "TNM",
        "lat": "-81.5",
        "lon": "0",
        "name": "Teniente R. Marsh",
        "city": "Teniente R. Marsh",
        "state": "",
        "country": "Antarctica",
        "woeid": "28289409",
        "tz": "AntarcticaSouth_Pole",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    },
    {
        "code": "",
        "lat": "-81.5",
        "lon": "0",
        "name": "Everywhere",
        "city": "Ovunque",
        "state": "",
        "country": "Antarctica",
        "woeid": "28289409",
        "tz": "AntarcticaSouth_Pole",
        "phone": "",
        "type": "Airports",
        "email": "",
        "url": "",
        "runway_length": null,
        "elev": null,
        "icao": "",
        "direct_flights": "0",
        "carriers": "0"
    }
]

export default airports;