import './searchCity.css';
import Sheet from 'react-modal-sheet';
import {useState, useEffect} from 'react';
import closeImg from '../../assets/close.png';
import dragbottom from '../../assets/dragbottom.png';
import landscapecolor from '../../assets/landscapecolor.png';
import airports from '../../consts/airports';

function SearchCity({open, isDep, close}) {

    const [city, setCity] = useState('');
    const [possibleCities, setPossibleCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const destinationPlaceholder = ['Londra','Spagna', 'Ovunque','Berlino','Germania', 'Ovunque', 'Madrid','Francia', 'Ovunque', 'Parigi','Grecia', 'Ovunque', 'Roma','Italia', 'Ovunque'];
    const [randomPH, setRandomPH] = useState(destinationPlaceholder[0]);

    useEffect(()=>{
        randomDestination();
        setPossibleCities(airports);
    }, []);

    useEffect(()=>{
        setTimeout(()=> {
            randomDestination();
        }, 2000)
    }, [randomPH]);

    useEffect(()=>{
        if (city.length > 1) {
            setPossibleCities([]);
        } else {
            setPossibleCities(airports);
        }
    }, [city]);

    useEffect(()=>{
        if (!possibleCities.length) {
            let pCity = [];
            airports.forEach(element => {
                if (element.name.toLocaleLowerCase().includes(city.toLocaleLowerCase()) || element.city.toLocaleLowerCase().includes(city.toLocaleLowerCase())) {
                    pCity.push(element);
                }
            });
            setPossibleCities(pCity);
        }
    }, [possibleCities]);

    useEffect(()=>{
        closeSheet();
    }, [selectedCity]);

    const closeSheet = ()=> {
        setPossibleCities(airports);
        close(selectedCity);
    }

    const searchAirport = (e)=> {
        setCity(e.target.value);
    }

    const selecteCity = (airport)=> {
        setSelectedCity(airport);
    }

    const randomDestination = ()=> {
        let randomIndex = Math.random() * destinationPlaceholder.length;
        randomIndex = Math.floor(randomIndex);
        initPHDest(destinationPlaceholder[randomIndex]);
    }

    const initPHDest = (dest)=> {
        if (dest !== randomPH) {
            setRandomPH(dest); 
        } else {
            randomDestination();
        }
    }

    return (
        <Sheet
              isOpen={open} onClose={closeSheet}
              snapPoints={[600]}
              initialSnap={0}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={closeSheet}/>
                    <img src={closeImg} onClick={closeSheet} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                    <div className='input_container'>
                        <input className='search mb-3' type="text" placeholder={isDep ? 'Città di partenza' : `Prova con ${randomPH}`} onChange={(e)=>searchAirport(e)}/>
                    </div>
                    <div>
                        {
                            possibleCities.map((airport, i)=>
                            <div key={i}>
                                <div className="center" onClick={()=>selecteCity(airport)}>
                                    <div className="one_city">
                                        <div className="name">{airport.city}</div>
                                        <div className={airport.isCountry ? 'codeState' : 'codeCity'}>({airport.code})</div>
                                    </div>
                                </div>
                                <div className="divider"></div>
                            </div>
                            )
                        }
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
    )
}

export default SearchCity;