const CitiesPhoto = [
    {name: 'AHO',
    img: 'https://olivercamponovo.com/wp-content/uploads/2020/08/Oliver-Camponovo-Sardegna.jpg'},
    {name: 'CAG',
    img: 'https://cdn.ideeperviaggiare.it/media/2021/05/italia-cagliari-mini-trekking-sella-diavolo-1920x1043-367908.jpg'},
    {name: 'OLB',
    img:'https://www.visitalymaps.app/borghi/images/o/olbia/11a25d86c6b85db9d2b385c7e80f30e4a11ab35b.jpg'},
    {name: 'ALC',
    img: 'https://images.ctfassets.net/ihlmn42cjuv0/6g1Ua1tBZQkGI31TUR2ZPv/f78d39d971128ba4232789c3e34e831b/nature-4998424.jpg?fit=fill&w=1346&h=838&fm=jpg&q=93'},
    {name: 'AMS',
    img: 'https://wallpaperaccess.com/full/4171800.jpg'},
    {name: 'AYT',
    img: 'https://cdn.travelatelier.com/wp-content/uploads/2019/05/10-unforgettable-things-to-do-antalya-turkey.jpg'},
    {name: 'ATH',
    img: 'https://cdn.britannica.com/53/150953-050-2E0836EB/Parthenon-Acropolis-Athens.jpg'},
    {name: 'BCN',
    img: 'https://www.grimaldi-lines.com/wp-content/uploads/2022/07/sagrada-familia-1030x687.jpg'},
    {name: 'BRI',
    img: 'https://images.lonelyplanetitalia.it/uploads/shutterstock-1451445944?q=80&p=slider&s=380ec9b48d95e3f821293c7ea2f1f768'},
    {name: 'belfast',
    img: 'https://d5qsyj6vaeh11.cloudfront.net/images/destinations/belfast/belfast-quarters/belfast-quarters-bg-desktop.jpg'},
    {name: 'BER',
    img: 'https://www.berlin-welcomecard.de/sites/default/files/styles/stage_desktop_20x/public/berlin-welcomecard_hero_2880__1.jpg?itok=fgbQwjEO&timestamp=1528123533'},
    {name: 'TXL',
    img: 'https://www.berlin-welcomecard.de/sites/default/files/styles/stage_desktop_20x/public/berlin-welcomecard_hero_2880__1.jpg?itok=fgbQwjEO&timestamp=1528123533'},
    {name: 'SXF',
    img: 'https://www.berlin-welcomecard.de/sites/default/files/styles/stage_desktop_20x/public/berlin-welcomecard_hero_2880__1.jpg?itok=fgbQwjEO&timestamp=1528123533'},
    {name: 'BIO',
    img: 'https://www.fulltravel.it/wp-content/uploads/2018/11/Veduta-del-lungofiume-di-Bilbao.jpg'},
    {name: 'BLQ',
    img: 'https://www.prologis.it/sites/italy/files/images/2018/05/3000x2400-it_bologna-italy.jpg'},
    {name: 'brindisi',
    img: ''},
    {name: 'BRU',
    img: 'https://loveincorporated.blob.core.windows.net/contentimages/fullsize/caf99867-9d84-433f-b124-49eca86e11c1-brussels-guide-lead1.jpg'},
    {name: 'CRL',
    img: 'https://loveincorporated.blob.core.windows.net/contentimages/fullsize/caf99867-9d84-433f-b124-49eca86e11c1-brussels-guide-lead1.jpg'},
    {name: 'OTP',
    img: 'https://www.easynite.it/wp-content/uploads/2021/11/bucarest.jpg'},
    {name: 'BUD',
    img: 'https://www.viaggimust.it/wp-content/uploads/2020/03/10-cose-da-vedere-a-budapest.jpg'},
    {name: 'cagliari',
    img: ''},
    {name: 'CTA',
    img: 'https://acchiappamappa.com/wp-content/uploads/2020/05/cosa-vedere-a-catania-1140x760.jpg'},
    {name: 'CGN',
    img: 'https://images.lonelyplanetitalia.it/static/places/colonia-2561.jpg?q=90&p=2400%7C1350%7Cmax&s=d4114eebaf8e389a7e06cbfdeb9ff2b6'}, //'https://www.prologisgermany.de/sites/germany/files/images/2019/01/presence_markets_cologne_web.jpg'
    {name: 'CPH',
    img: 'https://www.aeroporto.catania.it/wp-content/uploads/2020/06/Copenaghen1.jpg'},
    {name: 'DUB',
    img: 'https://cdn2.hometogo.net/assets/media/pics/1200_628/611877fa10ec8.jpg'},
    {name: 'EDI',
    img: 'https://www.travel365.it/foto/view-edinburgh-castle-from-princes-street-gardens-with-ross-fountain-foreground-1.jpg'},
    {name: 'FRA',
    img: 'https://content.r9cdn.net/rimg/dimg/39/86/ae1975d6-city-26939-15516fe0259.jpg?width=1750&height=1000&xhint=2069&yhint=1666&crop=true'},
    {name: 'HHN',
    img: 'https://content.r9cdn.net/rimg/dimg/39/86/ae1975d6-city-26939-15516fe0259.jpg?width=1750&height=1000&xhint=2069&yhint=1666&crop=true'},
    {name: 'IBZ',
    img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/10/2e/a4/ibiza.jpg?w=700&h=500&s=1'},
    {name: 'IST',
    img: 'https://www.raiplay.it/cropgd/1440x810/dl/img/2019/11/18/1574074860777_2048x1152.jpg'},
    {name: 'SAW',
    img: 'https://www.raiplay.it/cropgd/1440x810/dl/img/2019/11/18/1574074860777_2048x1152.jpg'},
    {name: 'KRK',
    img: 'https://content.r9cdn.net/rimg/dimg/41/43/9531cd74-city-9856-162c1121669.jpg?width=1200&height=630&crop=true'},
    {name: 'kyiv',
    img: 'https://lp-cms-production.imgix.net/2019-06/a6469f3993128cbcdde6e2b0a90aa1d3-kyiv.jpg'},
    {name: 'lanzarote',
    img: ''},
    {name: 'larnaca',
    img: ''},
    {name: 'las palmas',
    img: ''},
    {name: 'LIS',
    img: 'https://images.lonelyplanetitalia.it/static/places/lisbona-1373.jpg?q=90&p=2400%7C1350%7Cmax&s=bbf38b90a96e810dfff84f2285b60a06'},
    {name: 'LGW',
    img: 'https://cdn.contexttravel.com/image/upload/c_fill,q_60,w_2600/v1555943130/production/city/hero_image_11_1555943130.jpg'},
    {name: 'LHR',
    img: 'https://cdn.contexttravel.com/image/upload/c_fill,q_60,w_2600/v1555943130/production/city/hero_image_11_1555943130.jpg'},
    {name: 'STN',
    img: 'https://cdn.contexttravel.com/image/upload/c_fill,q_60,w_2600/v1555943130/production/city/hero_image_11_1555943130.jpg'},
    {name: 'LON',
    img: 'https://cdn.contexttravel.com/image/upload/c_fill,q_60,w_2600/v1555943130/production/city/hero_image_11_1555943130.jpg'},
    {name: 'LYS',
    img: 'https://cdn.flixbus.de/city_description_images/lione-vita-notturna.jpg'},
    {name: 'MAD',
    img: 'https://www.travellens.co/content/images/2019/07/Gran-via-1.jpg'},
    {name: 'AGP',
    img: 'https://awayandfar.com/wp-content/uploads/2019/01/Malaga-Football-Club.jpg'},
    {name: 'malta',
    img: ''},
    {name: 'MAN',
    img: 'https://www.prolificnorth.co.uk/sites/default/files/styles/lightbox_large/public/images/news/manchester-cityscape01.jpg?itok=AfGQqc-K'},
    {name: 'MXP',
    img: 'https://www.worldtravelguide.net/wp-content/uploads/2018/02/shu-Europe-Italy-Milan-Duomo-di-Milano-sunrise-700247896-Boris-Stroujko-1440x823.jpg'},
    {name: 'NAP',
    img: 'https://cdn.escapismmagazine.com/featured_image/5d95d318873e6.jpeg'},
    {name: 'NCE',
    img: 'https://images.ctfassets.net/bth3mlrehms2/5AkWUzt0zqRL47DqOa03ld/1fd00735584100f40a5c3f4c767ea31e/Nice__French_Riviera.jpg?w=2276&h=1317&fl=progressive&q=50&fm=jpg'},
    {name: 'olbia',
    img: ''},
    {name: 'PMO',
    img: 'https://www.filoteapasta.com/wp-content/uploads/2019/07/Cattedrale-Palermo.jpg'},
    {name: 'PMI',
    img: 'https://www.funair.com/wp-content/uploads/2022/04/Best-Beaches-and-Coves-for-Superyacht-Crew-to-Explore-in-Palma.jpg'},
    {name: 'CDG',
    img: 'https://cdn.thecrazytourist.com/wp-content/uploads/2019/03/ccimage-shutterstock_112137761.jpg'},
    {name: 'ORY',
    img: 'https://cdn.thecrazytourist.com/wp-content/uploads/2019/03/ccimage-shutterstock_112137761.jpg'},
    {name: 'pescara',
    img: ''},
    {name: 'pisa',
    img: ''},
    {name: 'OPO',
    img: 'https://www.10cose.it/wp-content/uploads/2015/09/porto.jpg'},
    {name: 'PRG',
    img: 'https://www.tomorrowland.com/src/Frontend/Files/userfiles/images/Global%20Journey/Discover%20Europe/praag(1).jpg'},
    {name: 'SVQ',
    img: 'https://handluggageonly.co.uk/wp-content/uploads/2019/01/Hand-Luggage-Only-3-1.jpg'},
    {name: 'ARN',
    img: 'https://tourscanner.com/blog/wp-content/uploads/2021/09/Cosa-vedere-a-Stoccolma.jpg'},
    {name: 'tirana',
    img: ''},
    {name: 'TRN',
    img: 'https://assets.vogue.com/photos/633eefaf4f85bd18e8ffbc47/master/w_2560%2Cc_limit/GettyImages-690073036.jpg'},
    {name: 'VLC',
    img: 'https://www.50epiuenasco.it/wp-content/uploads/2019/09/shutterstock_548065771-1.jpg'},
    {name: 'VCE',
    img: 'https://rccl-h.assetsadobe.com/is/image/content/dam/royal/data/ports/venice-italy/overview/venice-italy-grand-canal.jpg?$1024x700$'},
    {name: 'verona',
    img: 'https://www.eurospin-viaggi.it/media/immagini/36099_n_Verona_1.jpg'},
    {name: 'VIE',
    img: 'https://www.roadaffair.com/wp-content/uploads/2017/09/horse-hofburg-vienna-austria-shutterstock_334989290-1024x680.jpg'},
    {name: 'WAW',
    img: 'https://www.telegraph.co.uk/content/dam/Travel/2018/October/warsaw-iStock-613048620.jpg'},
    {name: 'WMI',
    img: 'https://www.telegraph.co.uk/content/dam/Travel/2018/October/warsaw-iStock-613048620.jpg'},
    {name: 'KBP',
    img: 'https://bari.ilquotidianoitaliano.com/wp-content/uploads/2019/11/kiev.jpg'},
    {name: 'HAM',
    img: 'https://www.pocruises.com/content/dam/po/inventory-assets/ports/HAM/HAM.jpg'},
    {name: 'SOF',
    img: 'https://aws-tiqets-cdn.imgix.net/images/content/e4cb266f01f3412a86fb25251e42810a.jpg?auto=format&fit=crop&ixlib=python-3.2.1&q=70&s=2a2c8591eed4a3569d9a9e9ad4d1de44'},
    {name: 'SVO',
    img: 'https://www.wallpaperflare.com/static/294/27/762/moscow-red-square-city-multicolored-wallpaper.jpg'},
    {name: 'GLA',
    img: 'https://static.ohga.it/wp-content/uploads/sites/24/2020/10/glasgow.jpg'},
    {name: 'DUS',
    img: 'https://cdn.flixbus.de/city_description_images/duesseldorf-giro-turistico.jpg'},
    {name: 'FCO',
    img: 'https://cdn.getyourguide.com/img/location/5ffeb3e11c2dd.jpeg/88.jpg'},
    {name: 'BGY',
    img: 'https://www.hotelalgamilano.it/sites/alga2torri.gisnet.it/files/Hotel_Santa_Barbara_Milano_01t.jpg'},
    {name: 'MUC',
    img: 'https://a.cdn-hotels.com/gdcs/production113/d1211/9310bba6-a855-403a-900c-7ede3d838adb.jpg'},
    {name: 'JTR',
    img: 'https://www.bgviaggi.com/images/santo_sito.jpg'},
    {name: 'JMK',
    img: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/mykonos-cosa-fare-1519946879.jpg'},
    {name: 'RHO',
    img: 'https://e3.365dm.com/19/08/2048x1152/skynews-rhodes-greece_4733450.jpg'},
    {name: 'CFU',
    img: 'https://www.domanidoveandiamo.it/wp-content/uploads/2018/08/corfu_grecia.jpg'},
    {name: 'EIN',
    img: 'https://www.holland.com/upload_mm/5/5/6/68751_fullimage_eindhoven_skyline.jpg'},
    {name: 'FUE',
    img: 'https://www.vdgmagazine.it/wp-content/uploads/2021/11/fue-fuerteventura-port-1b-scaled.jpeg'},
    {name: 'ACE',
    img: 'https://wallpaperaccess.com/full/6710941.jpg'},
    {name: 'LPA',
    img: 'https://wallpapercave.com/wp/wp4196749.jpg'},
    {name: 'other',
    img: ''},
    {name: 'ZAG',
    img:'https://images.lonelyplanetitalia.it/static/places/zagabria-370.jpg?q=90&p=2400%7C1350%7Cmax&s=e3e25aefc41d4a72454de5a60d20b318'},
    {name:'SKG',
    img:'https://www.gay.it/wp-content/uploads/2019/07/salonicco-960x640.jpg'},
    {name: 'BTS',
    img: 'https://image-service.web.oebb.at/www.nightjet.com/.imaging/default/dam/reiseportal/teaser-1422x800/regionen/europa/bratislava.jpg/jcr:content.jpg?t=1535622532450&scale=1.0',
    },
    {name: 'RTM',
    img: 'https://i1.wp.com/nonsoloturisti.it/wp-content/uploads/2018/11/city-2655108_1280.jpg?fit=1280%2C852&ssl=1'},
    {name: 'ZRH',
    img: 'https://images.squarespace-cdn.com/content/v1/57b9b98a29687f1ef5c622df/1534620783415-O9TBV2SAG24PMC7FRKSE/zurich+best+view'},
    {name:'OSL',
    img:'https://www.webuildvalue.com/wp-content/uploads/2021/08/Oslo-the-trailblazing-capital-of-%E2%80%9Cgreen%E2%80%9D-construction-sites_v2.jpg'},
    {name: 'HEL',
    img: 'https://travel.thewom.it/pictures/2021/09/17/helsinki-cosa-vedere.jpeg'},
    {name: 'ZTH',
    img: 'https://www.cosafarei.it/wp-content/uploads/2018/04/zante-principale-1366x691.jpg'},
    {name: 'MAH',
    img: 'https://cimebordeaux.it/wp-content/uploads/2018/03/Minorca.jpg'},
    {name: 'EFL',
    img: 'https://www.isole-greche.com/wp-content/uploads/immagini/cefalonia/assos-cefalonia-grecia.jpg'},
    {name: 'HER',
    img: 'https://www.youknowboat.com/wp-content/uploads/2021/05/Agios-Nikolaos-Crete-Greece.jpeg'},
    {name: 'KGS',
    img: 'https://www.consigliamidove.it/wp-content/uploads/2019/10/Kos.jpg'},
    {name: 'MLA',
    img: 'https://www.ilblogdimalta.com/wp-content/uploads/2019/05/mare-a-malta.jpg'},
    {name: 'TIA',
    img: 'https://i0.wp.com/nonsoloturisti.it/wp-content/uploads/2018/11/Tirana-Albania.jpg?fit=2048%2C1365&ssl=1'},
    {name: 'TLL',
    img: 'https://static2-viaggi.corriereobjects.it/wp-content/uploads/2015/06/iStock-1172193795.jpg'},
    {name: 'RIX',
    img: 'https://www.10cose.it/wp-content/uploads/2018/09/riga.jpg'},
    {name: 'VNO',
    img: 'https://images.musement.com/cover/0001/37/vilnius-jpg_header-36634.jpeg'},
    {name: 'KEF',
    img: 'https://img.itinari.com/page/content/original/483482f8-cd36-4d9c-b5fc-b321d9dd28b4-basiczto-istock-996160826.jpg?ch=DPR&dpr=2.625&w=994&s=cc7e513c888bed00db2112a33530e8a9'},
    {
        name: 'INN',
        img: 'https://www.teahub.io/photos/full/309-3090265_the-sun-and-snow-wallpaper-free-wallpapers-hd.jpg'
    },
    {
        name: 'STR',
        img: 'https://www.westend61.de/images/0001463072pw/germany-baden-wurttemberg-stuttgart-aerial-view-of-stuttgart-ost-quarter-at-sunset-WDF06289.jpg'
    },
    {name: 'SZG',
    img: 'https://images3.alphacoders.com/708/thumb-1920-708177.jpg'},
    {
        name: 'TCI',
        img: 'https://tourscanner.com/blog/wp-content/uploads/2020/12/Things-to-do-in-Tenerife.jpg'
    },
    {
        name: 'TFS',
        img: 'https://tourscanner.com/blog/wp-content/uploads/2020/12/Things-to-do-in-Tenerife.jpg'
    },
    {
        name: 'TFN',
        img: 'https://tourscanner.com/blog/wp-content/uploads/2020/12/Things-to-do-in-Tenerife.jpg'
    },
    {
        name: 'LUX',
        img: 'https://www.latitudeslife.com/wp-content/uploads/22_Lux-622.jpg'
    },
    {
        name: 'MRS',
        img: 'https://www.artribune.com/wp-content/uploads/2022/11/Marsiglia.-Il-MUCEM-e-il-Fort-St.-Jean-%C2%A9-shutterstock.jpg'
    },
    {
        name: 'LMP',
        img: 'https://cdn.traghetti.com/porti/lampedusa58-84227.jpg'
    },
    {name: 'BHX',
    img: 'https://a.cdn-hotels.com/gdcs/production131/d1170/e7199a50-080e-4dab-87d5-6d15ddada00d.jpg'},
    {name: 'CLJ',
    img: 'https://xn--urlaub-in-rumnien-2qb.de/wp-content/uploads/2017/06/cluj-1-1.jpg'},
    {name: 'POZ',
    img: 'https://www.dove-e-quando.it/site/images/illustration/oualler/pologne-poznan_123.jpg'},
    {name: 'SUF',
    img: 'https://www.lameziaairport.it/wp-content/uploads/2021/04/Lamezia-Terme-2.jpg'},
    {name: 'LPL',
    img: 'https://omghcontent.affino.com/AcuCustom/Sitename/DAM/119/LIVERPOOL_COUNCIL_1200px_GETTY_MIN.jpg'},
    {name: 'BRS',
    img: 'https://deih43ym53wif.cloudfront.net/bristol-shutterstock_686562400_55bff2c31b.jpeg'},
    {name: 'BOH',
    img: 'https://cdn.getyourguide.com/img/location/5e171426a936f.jpeg/75.jpg'},
    {name: 'SPU',
    img: 'https://images.lonelyplanetitalia.it/static/places/spalato-e-dalmazia-centrale-451.jpg?q=90&p=96%7C96%7Ccrop&s=5d1bb36a1fa2e3c9e208aa45196063fe'},
    {name: 'DBV',
    img: 'https://www.transfeero.com/wp-content/uploads/2020/08/vista-panoramica-dubrovnik.jpg'},
    {name: 'BRQ',
    img: 'https://loveincorporated.blob.core.windows.net/contentimages/main/ff88abe8-a2b1-4ec3-a923-aa536dc56c92-brno-city-skyline.jpg'},
    {name: 'CLJ',
    img: 'https://www.kayak.it/rimg/dimg/81/ee/e112fbd1-city-27633-1656c96a4c7.jpg?width=1366&height=768&xhint=1498&yhint=1011&crop=true'},
    {name: 'TSR',
    img: 'https://www.romaniaforall.it/wp-content/uploads/2021/10/Timisioara-Piata-Unirii-.jpeg'},
    {name: 'AAL',
    img: 'https://bestdistricts.com/wp-content/uploads/2019/07/The-Best-Areas-to-Stay-in-Aalborg-Denmark.jpg'},
    {name: 'WRO',
    img: 'https://www.aviontourism.com/images/1400-1200-fix/2d3850f7-e152-42dc-83c1-74be1ba42f35'},
    {name: 'PVK',
    img: 'https://images.musement.com/cover/0003/14/parga-xxl-jpg_header-213794.jpeg?w=1200&h=630&q=95&fit=crop'},
    {name: 'JSI',
    img: 'https://mediaim.expedia.com/destination/1/85d7d1293efef6531702738b4a50d609.jpg'},
    {
        name: '',
        img: ''
    },

    //// COUNTRY
    {name: 'ES',
    img: 'https://images.unsplash.com/photo-1566232392379-afd9298e6a46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3BhaW4lMjBmbGFnfGVufDB8fDB8fA%3D%3D&w=1000&q=80'},
    {name: 'IT',
    img: 'https://www.wantedinrome.com/i/preview/storage/uploads/2022/01/italy-celebrates-225-years-of-italian-tricolour-flag.jpg'},
    {name: 'DE',
    img: 'https://media.istockphoto.com/id/512900384/photo/pariser-platz-and-brandeburg-tor-berlin.jpg?b=1&s=170667a&w=0&k=20&c=XNUCsiXxf4SHm3HaYXc1zlCJ-ZqJPh02J5AnMsQcziQ='},
    {name: 'FR',
    img: 'https://cdn.travelpulse.com/images/99999999-9999-9999-9999-999999999999/fdd16e7f-0167-6524-b1f9-933d7482e71e/630x355.jpg'},
    {name: 'GB',
    img: 'https://crossroads.ideasoneurope.eu/files/2018/06/BigBen-UKflag-Melinda-Nagy-AdobeStock-small.jpeg'},
    {name: 'PT',
    img: 'https://t4.ftcdn.net/jpg/02/90/17/01/360_F_290170124_V0VuNDT4WM0JaZCcdvOgmncZU0b9KQ7g.jpg'},
    {name: 'NL',
    img: 'https://images.unsplash.com/photo-1601492385399-20e7b49c4e81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV0aGVybGFuZHMlMjBmbGFnfGVufDB8fDB8fA%3D%3D&w=1000&q=80'},
    {name: 'BE',
    img: 'https://biovox.eu/app/uploads/2021/12/Belgium-health-and-biotech.jpg'},
    {name: 'GR',
    img: 'https://media.istockphoto.com/id/1206509311/photo/oia-sunset-and-greek-flag-santorini-greece.jpg?s=612x612&w=0&k=20&c=R3H8zqSHBeqa2t7we4FHzd3VYiFk3_ZK4vSGrYdEUk8='},
    {name: 'MA',
    img: 'https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Fsundaytimes%2Fprod%2Fweb%2Fbin%2Fbdb29984-224f-11ea-81b4-b78674dd3224.jpg?crop=2667%2C1500%2C0%2C0'},
    {name: 'EG',
    img: 'https://www.targatocn.it/typo3temp/pics/e_6d6bb60af1.jpg'},
    {name: 'TR',
    img: 'https://www.med-or.org/volumes/news/tra-energia-economia-e-geopolitica-la-turchia-e-il-suo-ruolo-nel-mediterraneo/_1200xAUTO_crop_center-center_85_none/Turchia.jpg'},
    {name: 'US',
    img: 'https://img.freepik.com/premium-photo/manhattan-new-york-city-statue-liberty-american-flag-usa_73110-11026.jpg?w=2000'},
    {name: 'CA',
    img: 'https://media.istockphoto.com/id/1367728341/photo/canadian-national-flag-in-ottawa.jpg?b=1&s=170667a&w=0&k=20&c=a7uIwX7IaX3KICnudiJuUolVfRVhfqGmVAAtkNBzIz0='},
    {name: 'JP',
    img: 'https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg'},
    {name: 'FI',
    img: 'https://cdn.sanity.io/images/24oxpx4s/prod/2ad9208fee6151b36450d28f65cdd3f321eb2469-5464x3640.jpg?w=1600&h=1066&fit=crop'},
    {name: 'NO',
    img: 'https://www.travelworld.it/wp-content/uploads/2020/09/Norvegia-Trolltunga-LP.jpg'},
    {name: 'DK',
    img: 'https://studyindenmark.dk/why-denmark/quality-of-life-1/about-denmark/image_large'},
    {name: 'SE',
    img: 'https://static.euronews.com/articles/stories/06/58/92/78/1440x810_cmsv2_26c9e590-9491-522c-8def-11ce8b71d3f8-6589278.jpg'},
    {name: 'IS',
    img: 'https://www.state.gov/wp-content/uploads/2018/11/Iceland-2109x1406.jpg'},
    {name: 'CH',
    img: 'https://www.nationsonline.org/gallery/Switzerland/Sunrise-on-the-Matterhorn.jpg'},
    {name: '',
    img: ''},

    //// CITTà FUORI EUROPA
    {name: 'JFK',
    img: 'https://www.viaggi-usa.it/wp-content/uploads/2013/11/cosa-fare-a-new-york.jpg'},
    

    //// OFFERTE DEL MESE
    {
        name: 'off',
        img: 'https://www.agenziapressplay.it/wp-content/uploads/fly-images/482/jetcost-scaled-1680x9999.jpg'
    },

    //// AREA
    {
        name: '01',
        img: 'https://travel.thewom.it/content/uploads/sites/4/2020/02/Isole-Canarie.jpg'
    },
    {
        name: '02',
        img: 'https://a.cdn-hotels.com/gdcs/production173/d729/9d1c9a44-7800-4a7b-9710-bd0c19c6bc84.jpg'
    },
    {
        name: '03',
        img: 'https://www.pepitaviaggi.com/img_prod/andalusia_tour_2020_%20(3).jpg'
    },
    {
        name: '04',
        img: 'https://magazine.fortevillageresort.com/wp-content/uploads/2019/05/mare-sardegna-def.jpg'
    },
    {
        name: '05',
        img: 'https://www.sixt.it/magazine/wp-content/uploads//sites/8/2018/07/shutterstock_1995993740-2.jpg'
    },
    {
        name: '06',
        img: 'https://www.telegraph.co.uk/content/dam/Travel/Cruise/Ocean/CROP-Aerial-view-of-Danube-from-Budapest-with-Liberty-Statue-Getty.jpg'
    },
    {
        name: '07',
        img: 'https://static.wixstatic.com/media/df9fb8_e5e20e1fdb2a455680a0a728cb32de3c~mv2.jpg/v1/fill/w_1000,h_659,al_c,q_85,enc_auto/df9fb8_e5e20e1fdb2a455680a0a728cb32de3c~mv2.jpg'
    },
    {
        name: '08',
        img: 'https://www.triptherapy.net/images/easyblog_articles/540/iStock-514795912.jpeg'
    },
    {
        name: '09',
        img: 'https://www.greenme.it/wp-content/uploads/2019/06/baunei.jpg'
    },
    {
        name: '10',
        img: 'https://media.cntraveller.com/photos/611be7334e09f53b43732217/16:9/w_2992,h_1683,c_limit/pechon-playa-cantabria-gettyimages-481045677.jpg'
    },

    //// SUMMER DESTINATION 
    {
        name: 'summ',
        img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/10/2e/a4/ibiza.jpg?w=700&h=500&s=1'
    },
]

export default CitiesPhoto;