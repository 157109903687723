import telegram from '../../assets/telegram-icon.png';
import facebook from '../../assets/facebook-icon.png';
import instagram from '../../assets/instagram-icon.png';
import '../../App.css';

function ShareSocialPage() {

    const instagramLink = `https://www.instagram.com/flights.hunter/`
    const telegramLink = 'https://t.me/FlightsHunterIt';
    const facebookLink = 'https://www.facebook.com/people/FlightsHunter/100066867267065/';

    const share = (social, socialName) => {
        window.open(social, '_blank');
    }

    return(
        <div className='shareContainer'>
            <img className='socialIconMin' src={telegram} alt="telegram" onClick={()=>share(telegramLink, 'telegram')}/>
            <img className='socialIconMin' src={instagram} alt="instagram" onClick={()=>share(instagramLink, 'instagram')}/>
            <img className='socialIconMin' src={facebook} alt="facebook" onClick={()=>share(facebookLink, 'facebook')}/>
        </div>
    )
}

export default ShareSocialPage;