import React, {useState, useEffect} from 'react';
import {db} from '../../firebase-config';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from "react-router-dom";
import OfferCard from '../offerCard/offerCard';
import Spinner from 'react-bootstrap/Spinner';
import PBottom from '../../assets/photo_bottom.png';
import shareImg from '../../assets/white_share.png';
import Share from '../share/share';
import logo from '../../assets/whiteLogoFH.png';
import AreaOffers from '../areaOffers/areaOffers';
import defaultPhoto from '../../assets/defaultphoto.png';
import guidaIcon from '../../assets/guide.png';
import Col from 'react-bootstrap/Col';
import bookingLogo from '../../assets/booking.png';
import newwindows from '../../assets/newwindows.png';
import brokenLink from '../../assets/brokenLink.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../App.css';

function Offers() {

    const navigate = useNavigate();
    const offerId = useLocation();

    const [cityTo, setCityTo] = useState({});
    const [offers, setOffers] = useState([]);
    const [offersType, setOffersType] = useState([]);
    const [photo, setPhoto] = useState();
    const [GYG, setGYG] = useState();
    const [guideId, setGuideId] = useState();
    const [bookingId, setBookingId] = useState(undefined);
    const [bookingUrl, setBookingUrl] = useState();

    const [loader, setLoader] = useState(false);
    const [openShare, setOpenShare] = useState(false);

    const [imageError, setImageError] = useState(false);

    const [isBrokenLink, setIsBrokenLink] = useState(false);

    useEffect(()=>{
        updateViewers();
        if (window.innerWidth > 900) {
            setTimeout(()=>{
                window.scrollTo({
                    top: 1000,
                    behavior: 'smooth',
                });
            }, 1000)
        }
    }, [])

    useEffect(()=>{
        if (offerId.state.id){
            loadOffers(offerId.state.id);
            setLoader(true);
        }
    }, [offerId.state.id])

    useEffect(()=>{
        if (bookingId) {
            setBookingUrl(`https://www.booking.com/searchresults.it.html?city=${bookingId}&aid=7924785&no_rooms=1&group_adults=2&room1=A%2CA&label=FlightsHunter`)
          }
    }, [bookingId])


    const loadOffers = async (id) => {
        const docRef = doc(db, "offers", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.data()) {
            setCityTo({name: docSnap.data().cityTo.name, cityToCode: docSnap.data().cityTo.code});
            setOffersType(docSnap.data().offersType);
            setOffers(docSnap.data().offers);
            setPhoto(docSnap.data().img);
            setGuideId(docSnap.data().cityTo.guide);
            let GygTemplate = docSnap.data().GYG
            setGYG(GygTemplate);
            setTimeout(()=>{setLoader(false)}, 5000);
            setBookingId(docSnap.data().bookingId);
        } else {
            setIsBrokenLink(true);
            setLoader(false);
            setTimeout(()=>{
                navigate('/')
            }, 7000)
        }        
    }

    const updateViewers = async() => {
        // TODO FUNZIONA DA METTERE SOLO IN PROD
        // TOLTO PER TROPPE CHIAMATE A FIREBASE
        // let viewer = 0;
        // let id = new Date().toDateString();
        // const docRef = doc(db, "view", id);
        // const docSnap = await getDoc(docRef);
        // console.log(docSnap.data());
        // if (docSnap.data()) {
        //     viewer = Number(docSnap.data().views) +1;
        // } else {
        //     viewer = 1;
        // }
        // setDoc(docRef, {views: viewer});
    }

    const closeShare = (e) => {
        setOpenShare(e);
    }

    const openBooking = ()=> {
        window.open(bookingUrl, '_blank');
    }

    const openBookingLandingPage = ()=> {
        window.open('https://www.booking.com/index.html?aid=7924785', '_blank');
    }

    return (
        <>
            {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            <div className='mb-5 relative'>
                <div className='iconContainer'>
                <img className='imgw18' style={{width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                <img className='imgw18' style={{width: '30px'}} src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/>

                </div>
                {
                    imageError ? 
                    <img src={defaultPhoto} alt="city to photo" style={{width: '100vw', top: '0'}} onLoad={()=>setLoader(false)}/>
                    :
                    <img src={photo} alt="city to photo" style={{width: '100vw', top: '0'}} onLoad={()=>setLoader(false)} onError={()=>{setImageError(true)}}/>
                }
                <span className='center_f'>
                    <img src={PBottom} alt="city to photo" style={{width: '100vw', position: 'absolute', left: '0', top: '80%'}}/>
                    <span className='toCityTxt'>{cityTo.name}</span>
                </span>
            </div>
            <div className='w85 mb-3 mt-3' style={{'display':'flex', 'justifyContent':'space-between', 'alignItems': 'center'}}>
                <div>
                    {
                        bookingId ? 
                        <div onClick={openBooking} className='booking-btn-offers'>
                            <div style={{'whiteSpace': 'nowrap', 'color': 'darkslategrey', 'fontSize': '12px'}}>
                                Cerca Hotel su <img src={bookingLogo} alt="open" style={{'width': '90px'}}/> <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                            </div>
                        </div>
                        : 
                        <div onClick={openBookingLandingPage} className='booking-btn-offers'>
                            <div style={{'whiteSpace': 'nowrap', 'color': 'darkslategrey', 'fontSize': '12px'}}>
                                Cerca Hotel su <img src={bookingLogo} alt="open" style={{'width': '90px'}}/> <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                            </div>
                        </div>
                    }
                </div>
                {
                    guideId ? 
                    <img className='imgw18' style={{width: '34px'}} src={guidaIcon} alt="Guida" onClick={()=>{window.open(`https://www.flightshunter.it/guide/${guideId}`, "_blank")}}/>
                    :
                    <div></div>
                }
            </div>
            <div>
                {
                    offersType !== 'area' ? 
                    <div>
                        {
                            offers.map((offer, i)=>
                            <div key={i}>
                                <OfferCard offer={offer} offersType={offersType} offerId={offerId.state.id} bookingId={bookingId}/>
                            </div>
                            )
                        }
                    </div>
                    :
                    <div>
                        <AreaOffers offer={offers} offerId={offerId.state.id}/>
                    </div>
                }
                
            </div>

            {
                GYG !== '<div></div>' && offersType === 'arrival' ?
                <>
                    <div className='divider mb-3 mt2'></div>
                    <div className='messageGYG'>
                        Trova le attrazioni da visitare
                        <br />
                        e le migliori esperienze da vivere
                    </div>
                    <div dangerouslySetInnerHTML={{__html: GYG}}></div>
                </>
                :
                <></>
            }
            <div className='mb-2'></div>
            <Modal show={isBrokenLink} style={{'width' : '100vw', 'textAlign':'center'}}>
                <Modal.Body>
                  <div className='mb-2 mt-5'>
                    <div className='messageGYG'>
                        Ci dispiace Hunter
                        <br />
                        qualcosa è andato storto! 
                    </div>
                    <img src={brokenLink} alt="broken link" style={{'width' : '85vw'}}/>
                    <div className='messageGYG fs-12'>
                        verrai reindirizzato automaticamente
                    </div> 
                  </div>
                </Modal.Body>
                <Modal.Footer style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
                    <Button className='offerBtn white footer_btn' variant="light" onClick={()=>{navigate('/')}}>
                      Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
            <Share open={openShare} closeShare={closeShare} da={''} a={cityTo.name} price={''} id={offerId.state.id} isForFullOffer={true}/>        </>
    )
}

export default Offers;