import {useEffect, useState} from 'react';
import guide from '../../consts/guide';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GuideCards from '../guideCards/guideCards';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../assets/whiteLogoFH.png';
import bck from '../../assets/landscapecolor.png';
import {useNavigate} from 'react-router-dom';
import NoResult from '../../assets/noResult.png';
import SEO from '../seo/seo';

function SearchGuide() {

    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [selectedGuide, setSelectedGuide] = useState();
    const [guideList, setGuideList] = useState(guide);

    useEffect(()=>{
        setGuideList([]);
    }, [selectedGuide])

    useEffect(()=>{
        if (!guideList.length) {
            filterGuide(selectedGuide)
        }
    }, [guideList])

    const filterGuide = (e)=> {
        if (e && e.length) {
            let filtered = [];
            guide.forEach(element => {
                if (element.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())) {
                    filtered.push(element);
                }
            });
            setGuideList(filtered);
        } else {
            setGuideList(guide);
        }
        
    }

    return(
        <div>
            <SEO title={'FlightsHunter - Guide di viaggio'} description={'Guide dettagliate per le migliori destinazioni nel mondo, così da non perderti nessuna esperienza durante il tuo viaggio'}></SEO>
            {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            <div className='mb-4 relative'>
                <div className='iconContainer'>
                    <img className='imgw18' style={{width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                    {/* <img className='imgw18' style={{width: '30px'}} src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/> */}
                </div>
                <img src={bck} alt="bck" className='bck_img'/>
                <div className='guideTitle mt-5'>
                    <div className='city_name'>Guide</div>
                </div>
            </div>
            <div className='w-100'>
                <input className='w-90 search mb-3' type="text" placeholder='Cerca guida' onChange={(e)=>{setSelectedGuide(e.target.value)}}/>
            </div>
            {
                guideList.length ? 
                <div>
                    {
                        guideList.map((g, i)=>
                        <div key={i} className="center d-col">
                            <Container>
                                {
                                    i % 2 && i !== 0 ?
                                    <Row>
                                      <Col>
                                        <GuideCards guideDetail={guide[i-1]}/>
                                      </Col>
                                      <Col>
                                        <GuideCards guideDetail={guide[i]}/>
                                      </Col>
                                    </Row>
                                    : 
                                    i == 0 && guideList.length == 1 ? 
                                    <Row>
                                      <Col>
                                        <GuideCards guideDetail={g}/>
                                      </Col>
                                    </Row>
                                    :
                                    <></>
                                }
                            </Container>
                        </div>
                        )
                    }
                </div>
                :
                <div>
                    <div>Nessun risultato</div>
                    <img src={NoResult} alt="noresult" className='nrw mt2'/>
                </div>
            }            
        </div>
    )
}

export default SearchGuide;