import Sheet from 'react-modal-sheet';
import {useState} from 'react';
import closeImg from '../../assets/close.png';
import dragbottom from '../../assets/dragbottom.png';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as locales from 'react-date-range/dist/locale';
import Button from 'react-bootstrap/Button';
import './selectDates.css';

function SelectDates({open, close}) {

    const [dates, setDates] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    const saveDates = (item)=> {
        setDates([item.selection])
    }

    const closeSheet = ()=> {
        close(dates);
    }

    return (
        <Sheet
              isOpen={open} onClose={closeSheet}
              snapPoints={[500]}
              initialSnap={0}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={closeSheet}/>
                    <img src={closeImg} onClick={closeSheet} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                  <div className='center w-100'>
                    <DateRange
                      editableDateInputs={true}
                      onChange={item => saveDates(item)}
                      moveRangeOnFirstSelection={false}
                      minDate={new Date()}
                      locale={locales['it']}
                      ranges={dates}
                    />
                  </div>
                  <div className='w-100 choosen_btn_container'>
                    <Button className='choose_button' onClick={closeSheet}>FATTO!</Button>
                  </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
    )
}

export default SelectDates;