import './playAndWin.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import welcome from '../../assets/welcome.png';
import { signInWithRedirect, getRedirectResult } from "firebase/auth";
import { auth, googleProvider, db } from '../../firebase-config';
import newwindows from '../../assets/newwindows.png';
import travelquiz from '../../assets/travelquiz.png';
import closeW from '../../assets/closeW.png';
import {useNavigate} from 'react-router-dom';
import first from '../../assets/first.png';
import second from '../../assets/second.png';
import third from '../../assets/third.png';
import graph from '../../assets/graph.png';
import Sheet from 'react-modal-sheet';
import closeImg from '../../assets/close.png';
import dragbottom from '../../assets/dragbottom.png';
import rules from '../../assets/rules.png';
import award from '../../assets/award.png';
import Countdown from "react-countdown";
import homequiz from '../../assets/homequiz.png';
import returnQ from '../../consts/quiz';
import background from '../../assets/background.png';
import Form from 'react-bootstrap/Form';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import SEO from '../seo/seo';

function PlayAndWin () {

    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        name: undefined,
        shortName: undefined,
        email: undefined,
        id: undefined,
        pt: 0,
        played: 0,
        lastPlayed: undefined,
        lastSaved: undefined
    });
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openMessCloseGame, setOpenMessCloseGame] = useState(false);
    const [showRegolamento, setShowRegolamento] = useState(false);
    const [showGame, setShowGame] = useState(false);
    const [isSaturday, setIsSaturday] = useState(new Date().getDay() === 6);
    const [today, setToday] = useState(new Date());
    const [nextSaturday, setNextSaturday] = useState();
    const [quiz, setQuiz] = useState([]);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [newPt, setNewPt] = useState(0);
    const [clickConfirm, setClickConfirm] = useState(false);
    const [timer, setTimer] = useState();
    const [errorLogin, setErrorLogin] = useState(false);
    const [showVisibilityChangeModal, setShowVisibilityChangeModal] = useState(false);

    useEffect(()=>{
        setQuiz(returnQ());
        const data = localStorage.getItem('fh-u-d');
        if (data) setUserData(JSON.parse(data));
        checkLastSaved(JSON.parse(data));
        // getLoginResult(); LOGIN GOOGLE NON FUNZIONA
        findNextSaturday();
    }, []);

    useEffect(()=>{
        if (document.visibilityState !== 'visible' && showGame === true) {
            setShowGame(false);
            setShowVisibilityChangeModal(true);
        }
    }, [document.visibilityState])

    /////////// LOGIN CON GOOGLE NON FUNZIONA ////////////
    const signInWithGoogle = async()=> {
        signInWithRedirect(auth, googleProvider)
        .then((res)=>{
          console.log(res);
        })
        .catch((err)=>{
          console.log(err)
        })
    }

    const saveUserDataFromGoogle = (data)=> {
        let firstName = data.user.displayName.split(' ')[0];
        firstName = firstName ? firstName : data.user.displayName;
        return {
            name: data.user.displayName,
            shortName: firstName,
            email: data.user.email,
            phoneNumber: data.user.phoneNumber,
            emailVerified: data.user.emailVerified,
            id: new Date().getTime(),
            pt: 0,
            played: 0,
            lastPlayed: undefined
        }
    }

    const getLoginResult = async()=> {
        try {
            const result = await getRedirectResult(auth)
            if (result) {
                setUserData(saveUserDataFromGoogle(result));
                setOpenLoginModal(false);
                // TODO save in firebase i dati la prima volta
            } else {
              // Everything is fine
            }
          } catch (error) { }
    }
    //////////////////////////////////////////////

    const checkLastSaved = (data)=> {
        let today = new Date();
        if (data && data.lastSaved) {
            let lS = new Date(data.lastSaved);
            if (today.getMonth() < lS.getMonth() && today.getFullYear() <= lS.getFullYear()) {
                updateUserData(data);
            }
        }
    }

    const updateUserData = (data)=> {
        const docRef = doc(db, "users", data.id.toString());
        setDoc(docRef, data)
        .then(()=>{}) 
    }

    const findNextSaturday = ()=> {
        let nS = new Date();
        setNextSaturday(nS.setDate(today.getDate() + (6-today.getDay())));
        // setNextSaturday(nS.setDate(today.getDate() + (((1 + 5 - today.getDay()) % 5) || 5)));
    }

    const startPlay = ()=> {
        if (userData.name) {
            setShowGame(true);
            setTimer(new Date().getTime() + 16000);
            setUserData({...userData, lastPlayed: today.getTime()})
        } else {
            setOpenLoginModal(true);
        }
    }

    const signIn = ()=> {
        if(userData.name && userData.email) {
            let newId = new Date().getTime();
            setErrorLogin(false);
            setOpenLoginModal(false);
            setUserData({
                ...userData,
                id: newId,
                pt: 0,
                played: 0,
                lastPlayed: undefined,
                shortName: userData.name,
                lastSaved: new Date()
            })
            saveUserDataIntoFirebase({
                ...userData,
                id: newId,
                pt: 0,
                played: 0,
                lastPlayed: 0,
                shortName: userData.name,
                lastSaved: new Date()
            })
        } else {
            setErrorLogin(true);
        } 
    }

    const saveUserDataIntoFirebase = async(datiUtente)=> {
        const docRef = doc(db, "users", datiUtente.id.toString());
        setDoc(docRef, datiUtente)
        .then(()=>{}) 
    }

    const updatePt = (pt)=> {
        setUserData({...userData, pt: pt*1055})
    }

    useEffect(()=>{
        if (userData) localStorage.setItem('fh-u-d', JSON.stringify(userData));
    }, [userData])

    const retireAwards = ()=> {
        if (userData.pt === undefined) {
            userData.pt = 0;
        } else if (userData.pt >= 2500){
            // TODO ritirare premi
            window.open(`mailto:flightshunter.app@gmail.com?subject=Vorrei ritirare un premio&body=Ho ${userData.pt} punti. ${userData.id}`)
        }
    }

    const [dataFromCountdown, setDataFromCountdown] = useState({});
    const [passSecond, setPassSecond] = useState();
    const getDataFromCountDown = (e)=> {
        if (e.seconds % 1 == 0 && e.seconds!==passSecond) {
            setDataFromCountdown(e);
            setPassSecond(e.seconds);
        }      
    }

    const [dataFromTimer, setDataFromTimer] = useState({});
    const [passSecondTimer, setPassSecondTimer] = useState();
    const setCountForQuestion = (e)=> {
        
        if (e.seconds % 1 == 0 && e.seconds!==passSecondTimer) {
            setDataFromTimer(e);
            setPassSecondTimer(e.seconds);
            if (e.seconds === 1) {
                let index = questionIndex;
                if(questionIndex < 9) {
                    setQuestionIndex(index+1);
                    setTimer(new Date().getTime() + 16000);
                } else {
                    quiz.length = 0;
                } 
            }
        }
    }

    const closeGame = ()=> {
        setShowGame(false);
        setOpenMessCloseGame(false);
    }

    const confirmaRisposta = ()=> {
        setClickConfirm(true);
        if (quiz[questionIndex].res === quiz[questionIndex].correctIndex) {
            let punti = newPt;
            punti = punti+10;
            setNewPt(punti);
        }
        if(questionIndex < 9) {

        } else {
            quiz.length = 0;
        } 
    }

    useEffect(()=>{
        if(questionIndex && quiz.length) {
            document.getElementById('res-0').checked = false;
            document.getElementById('res-1').checked = false;
            document.getElementById('res-2').checked = false;
            setClickConfirm(false);
        }
    },[questionIndex])

    const finishAndClose = ()=> {
        updatePt(userData.pt/1055 + newPt);
        setShowGame(false);
    }

    return(
        <div>
            <SEO title={'FlightsHunter - Vinci un viaggio'} description={'Gioca, rispondi alle domande, accumula punti e vinci un viaggio! Nuove partite ogni weekend'}></SEO>
            <div className='relative'>
                <div className='iconContainer'>
                    <div>
                        <div className='user_info_txt'>{userData.pt ? userData.pt/1055 : 0} pt</div>
                        <div className='user_info_txt'>{userData.shortName}</div>
                    </div>
                    <img className='imgw18' src={closeW} alt="logo" onClick={()=>navigate('/')}/>
                </div>
                <img src={travelquiz} alt="bck" className='bck_img'/>
            </div>
            <div>
                <div className='landing_title mt-3'>Let's play!</div>
                <div className='landing_txt'>gioca e vinci un viaggio</div>
                <div className='relative mt-3'>
                    <img src={homequiz} alt="graph" style={{'width': '30vh'}}/>
                </div>
            </div>
            {
               userData.pt/1055 >= 2500 ?
                <div className='landing_txt fs-10 mt-3 d-flex justify-content-center'>
                    <div className='retireAward' onClick={retireAwards}>
                    Ritira premi
                    </div>
                </div>
                :
                <div></div>
            }
            
            <div className='footer_container'>
                <div className='pb-1  mt-3'>
                {
                        new Date(userData.lastPlayed).toDateString() === today.toDateString() ? 
                        <div className='info text-center'>Hai già giocato oggi</div>
                        :
                        <div></div>
                    }
                    <div className='mb-2'>
                        <Button disabled={!isSaturday || new Date(userData.lastPlayed).toDateString() === today.toDateString()} className='play_btn w-90' variant="light" onClick={startPlay}>Gioca</Button>
                    </div>
                    {
                        !isSaturday || (!isSaturday && new Date(userData.lastPlayed).toDateString() === today.toDateString()) ?
                        <div>
                            <div className='landing_txt fs-10'>
                                Prossima partita tra
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                <Countdown
                                    date={new Date(new Date(nextSaturday).toDateString())}
                                    renderer={(e)=>getDataFromCountDown(e)}
                                />
                                <div className='d-flex align-items-center'>
                                    <div className='countDown pe-1'>{dataFromCountdown.days}<span className='fs-10'>g</span></div>
                                    <div className='countDown pe-1'>{dataFromCountdown.hours}<span className='fs-10'>h</span></div>
                                    <div className='countDown pe-1'>{dataFromCountdown.minutes}<span className='fs-10'>m</span></div>
                                    <div className='countDown'>{dataFromCountdown.seconds}<span className='fs-10'>s</span></div>
                                </div>
                            </div>
                        </div>
                        :
                        <div></div>
                    }
                </div>
                
                <div className='landing_txt fs-10 mb-3'>
                    <div style={{'whiteSpace': 'nowrap'}} onClick={()=>{setShowRegolamento(true)}}>
                        regolamento <img src={newwindows} alt="open" style={{'width': '18px'}}/>
                    </div>
                </div>
            </div>


            <Sheet
              isOpen={showRegolamento} onClose={()=>{setShowRegolamento(false)}}
              snapPoints={[700]}
              initialSnap={0}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={()=>{setShowRegolamento(false)}}/>
                    <img src={closeImg} onClick={()=>{setShowRegolamento(false)}} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                    <div className='rules_container'>
                        <div className='flx'>
                            <img src={rules} alt="dateAndHour" className='iconw'/>
                            <div className="ps-1 pe-2" style={{'fontWeight':'600'}}>Regole</div>
                            <div className='divider'></div>
                        </div>
                        <div className='mt-2 fs-12'>
                            <div>· Sarà possibile giocare una volta ogni sabato</div>
                            <div>· Ogni partita è composta da 10 domande</div>
                            <div>· Ogni risposta corretta assegna 10 punti</div>
                            <div>· Per ogni domanda si hanno 15 secondi di tempo per rispondere</div>
                            <div>· Il punteggio complessivo sarà salvato e una volta raggiunta quota sufficiente potrai scambiare i punti per un premio</div>
                            <div>· L'account e il punteggio non possono essere condivisi tra più persone</div>
                            <div>· VIETATO CHIUDERE O CAMBIARE SCHEDA DEL BROWSER DURANTE UNA PARTITA, PENA L'ANNULLAMENTO DELLA PARTITA STESSA</div>
                        </div>
                        
                    </div>
                    <div className='mt-3'>
                        <div className='flx'>
                            <img src={award} alt="dateAndHour" className='iconw'/>
                            <div className="ps-1 pe-2" style={{'fontWeight':'600'}}>Premi</div>
                            <div className='divider'></div>
                        </div>
                        <div className='premi_container mt-4'>
                            <Col className='column'>
                                <img className='w25' src={first} alt="first" />
                                <div className='txt_premi text-center mt-2'>
                                    voucher Ryanair o booking del valore di 100€
                                </div>
                            </Col>
                            <Col className='column'>
                                <img className='w25' src={second} alt="second" />
                                <div className='txt_premi text-center mt-2'>
                                    voucher Ryanair o booking del valore di 60€
                                </div>
                            </Col>
                            <Col className='column'>
                                <img className='w25' src={third} alt="third" />
                                <div className='txt_premi text-center mt-2'>
                                    voucher Ryanair del valore di 30€
                                </div>
                            </Col>
                        </div>
                        <div className='footer_container_rules'>
                            <div className='mb-2'>
                                <Button disabled={userData.pt/1055 < 2500} className='play_btn w-90' variant="light" onClick={retireAwards}>Ritira premi</Button>
                            </div>
                            {
                                userData.pt/1055 < 2500 ? 
                                <div className='info text-center'>arriva ad un minimo di 2500 punti</div>
                                :
                                <div></div>
                            }
                        </div>
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>

            <Sheet
              isOpen={showGame} onClose={()=>{setOpenMessCloseGame(true)}}
              snapPoints={[window.innerHeight]}
              initialSnap={0}
              disableDrag={true}>
              <Sheet.Container>
                <Sheet.Header />
                <div className='close'>
                    <img src={dragbottom} alt="drag bottom" style={{'width': '18px'}} onClick={()=>{setOpenMessCloseGame(true)}}/>
                    <img src={closeImg} onClick={()=>{setOpenMessCloseGame(true)}} alt="x" style={{'width': '18px'}}/>
                </div>
                <Sheet.Content>
                    <div className='relative'>
                        <img src={background} alt="bcakground" className='sheetBck'/>
                        <div className='text-center w-95 question'>
                            {
                                quiz.length ? 
                                <div>
                                    <div className='w-95 gameCountdown d-flex align-items-center justify-content-center'>
                                        <Countdown
                                            date={timer}
                                            renderer={(e)=>setCountForQuestion(e)}
                                        />
                                        <div>
                                            <div>{dataFromTimer.seconds-1}<span className='fs-10'>s</span></div>
                                        </div>
                                    </div>
                                    <div className='question'>
                                        <div>{quiz[questionIndex].quiz}</div>
                                        <div className='fs-12'>{quiz[questionIndex].subQuiz}</div>
                                        <div className='w-95 d-flex align-items-center justify-content-center'>
                                            <div className='mt-4 w-75'>
                                                <Form>
                                                    {
                                                        quiz[questionIndex].options.map((option, i)=>
                                                        <Form.Check
                                                            defaultChecked={false}
                                                            key={i}
                                                            label={option.label}
                                                            name="group"
                                                            type="radio"
                                                            id={`res-${i}`}
                                                            disabled={clickConfirm}
                                                            onClick={()=> {quiz[questionIndex].res = i}}
                                                        />        
                                                        )
                                                    }
                                                </Form>   
                                            </div>
                                        </div>
                                    </div>
                                    <div className='confirm_btn'>
                                        <Button variant="light" className='confirm' onClick={confirmaRisposta}>Conferma</Button>
                                        {
                                            clickConfirm === true ?
                                            <div className='fs-12'>
                                                {
                                                    quiz[questionIndex].res === quiz[questionIndex].correctIndex ?
                                                    <div>
                                                        <div>
                                                            Risposta esatta!
                                                        </div>
                                                        <div>
                                                            +10 punti
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div>
                                                            Risposta sbagliata!
                                                        </div>
                                                        <div>
                                                            la risposta corretta era:
                                                        </div>
                                                        <div>
                                                            {quiz[questionIndex].options[quiz[questionIndex].correctIndex].label}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        newPt > 10 ?
                                        <div>
                                            <div>complimenti</div>
                                            <div>Hai guadagnato</div>
                                            <div>{newPt} punti!</div>
                                            <div className='mt-4'>
                                                ora in totale hai
                                                <div>{(userData.pt/1055) + newPt} punti</div>
                                            </div>
                                            <Button  className='mt-5' variant="light" onClick={finishAndClose}>Salva e Chiudi</Button>
                                        </div>
                                        :
                                        <div>
                                            <div>Oggi è andata male</div>
                                            <div>non hai guadagnato punti</div>
                                            <div>non abbatterti!</div>
                                            <div>riprova la prossima settimana</div>
                                            <Button  className='mt-5' variant="light" onClick={finishAndClose}>Chiudi</Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div> 
                    </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>

            <Modal centered show={openMessCloseGame} onHide={()=>{setOpenMessCloseGame(false)}} style={{'width' : '100vw', 'textAlign':'center', 'zIndex':'999999999'}}>
            <Modal.Header closeButton>
                <Modal.Title className='landing_txt'>Chiudi</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  <div className='mb-2'>
                    <div className='close_mess'>
                            Sei sicuro di voler uscire dal gioco?
                        <br />
                        <div>
                            la tua partita verrà conteggiata ugualmente
                        </div>
                    </div> 
                    <div className='btn_container mt-3'>
                        <div>
                            <button className="offerBtn btn btn-primary" onClick={closeGame}>Esci dal gioco</button>
                        </div>
                    </div>
                  </div>
                </Modal.Body>
            </Modal>

            <Modal centered show={showVisibilityChangeModal} onHide={()=>{setShowVisibilityChangeModal(false)}} style={{'width' : '100vw', 'textAlign':'center', 'zIndex':'999999999'}}>
            <Modal.Header closeButton>
                <Modal.Title className='landing_txt'>Partita annullata</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  <div className='mb-2'>
                    <div className='close_mess'>
                            L'applicazione è stata chiusa durante una partita
                        <br />
                        <div>
                            come da regolamento la partita è stata annullata
                        </div>
                    </div> 
                    <div className='btn_container mt-3'>
                        <div>
                            <button className="offerBtn btn btn-primary" onClick={()=>{setShowVisibilityChangeModal(false)}}>Chiudi</button>
                        </div>
                    </div>
                  </div>
                </Modal.Body>
            </Modal>


            <Modal show={openLoginModal}  onHide={()=>{setOpenLoginModal(false)}} style={{'width' : '100vw', 'textAlign':'center'}}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  <div className='mb-2'>
                    <div className='landing_txt'>
                        Per poter giocare devi effetuare il login
                        <br />
                        <div>
                            così da salvare i tuoi progressi in modo sicuro
                        </div>
                    </div>
                    <img src={welcome} alt="welcome" style={{'width' : '65vw'}}/> 
                    <div className='btn_container mt-3'>
                    <Form>
                      <Form.Group className="mb-2">
                        <Form.Label className='landing_txt mb-0'>Nome</Form.Label>
                        <Form.Control type="text" placeholder="Ex. Fede" onChange={(e)=>{setUserData({...userData, name: e.target.value})}}/>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className='landing_txt mb-0'>Email</Form.Label>
                        <Form.Control type="email" placeholder="Ex. name@example.com" onChange={(e)=>{setUserData({...userData, email: e.target.value})}}/>
                        <Form.Label className='fs-12'>inserisci una mail a cui hai accesso così in caso di necessità puoi recuperare i tuoi punti in tutta sicurezza</Form.Label>
                      </Form.Group>
                    </Form>
                        <div>
                            {/* <button className="login-with-google-btn" onClick={signInWithGoogle}>Sign in with Google</button> */}
                            <Button className='play_btn w-90' variant="light" onClick={signIn}>Fatto</Button>
                            {
                                errorLogin ?
                                <div className='fs-12'>Compilare i campi per proseguire</div>
                                :
                                <div></div>
                            }
                        </div>
                    </div>
                  </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PlayAndWin;