import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './offerCard.css';
import depPhoto from '../../assets/partenza.png';
import calendarPhoto from '../../assets/calendario.png';
import paymentPhoto from '../../assets/pagare.png';
import Details from '../details/details';

function OfferCard({offer, offersType, offerId, searchType, bookingId}) {

  const [openDetail, setOpenDetail] = useState(false);

  const openLink = ()=>{
    setOpenDetail(true);
  }

  const closeSheetCard = (e) => {
    setOpenDetail(e);
  }

    return(
        <div className='cardContainer'>
            <Card className={offersType === 'area' ? 'noBck' : 'singleCard'}>
              <Card.Body className='bodyCard'>
                <div className='inRow'>
                  <div className='me-2'><img className='imgw' src={depPhoto} alt="partenza" /></div>
                  <div className='footerC w-100'>
                    {
                      offersType === 'area' ?
                      <></>
                      :
                      <div>
                        <span className='smallLabel'>Da:</span>
                        <Card.Title>
                          {offer.cityFrom} 
                          {
                            offersType === 'noarrival' ?
                            <></>
                            :
                            <span className='fromCityCodeLabel'>({offer.flyFrom})</span>
                          }
                        </Card.Title>
                      </div>
                    }
                    <div>
                      {
                        offersType !== 'arrival' ? 
                          <div>
                            <span className='smallLabel'>A:</span>
                            <Card.Title>
                              {offer.cityTo}
                            </Card.Title>
                          </div>
                          :
                          <></>
                      }
                    </div>
                  </div>
                </div>

                <div className='inRow'>
                  <div className='me-2'><img className='imgw' src={calendarPhoto} alt="partenza" /></div>
                  <div className='dateContainer'>
                    <div>
                      <div className='smallLabel'>dal:</div>
                      {offer.date.departure.from}
                    </div>
                    <div>
                      <div className='smallLabel'>al:</div>
                      {offer.date.return.from}
                    </div>
                  </div>
                </div>

                {
                  searchType === 'round' ?
                  <div className='inRow'>
                    <div className='me-2 imgw'></div>
                    <div className='dateContainer'>
                      <div className='fs-12'>
                        {offer.date.depH.from} - {offer.date.depH.to}
                      </div>
                      <div className='fs-12'>
                        {offer.date.retH.from} - {offer.date.retH.to}
                      </div>
                    </div>
                  </div>
                  :
                  <></>
                }

                <div className='footerC mt-3'>
                  <div className='inRow'>
                    <div className='me-2'><img className='imgw' src={paymentPhoto} alt="partenza" /></div>
                    <Card.Title className='mb-0'>
                      {offer.price} €
                    </ Card.Title>
                  </div>
                  <Button className='offerBtn' onClick={openLink}>Vedi offerta</Button>
                </div>
              </Card.Body>
            </Card>

            <Details open={openDetail} detail={offer} offerId={offerId} closeSheetCard={closeSheetCard} bookingId={bookingId}/>

        </div>
    )
}

export default OfferCard;