import './searchForm.css';
import {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import invert from '../../assets/round-trip.png';
import SearchCity from '../searchCity/searchCity';
import axios from 'axios';
import {Md5} from 'ts-md5/dist/md5';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import SelectDates from '../selectDates/selectDates';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Select from 'react-select';
import airlines from '../../consts/airlines';
import CitiesPhoto from '../../consts/citiesPhoto';
import {useNavigate, useLocation} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import settings from '../../assets/settings.png';
import guide from '../../consts/guide';
import guidePhoto from '../../assets/guidewhite.png';

function SearchForm({isLoading, openToast, noFlights, inResult}) {

    const navigate = useNavigate();
    const formInformation = useLocation();

    const monthOptions = [
        { value: '01', label: 'Gennaio', fromDate: '01/01/', toDate: '01/31/' },
        { value: '02', label: 'Febbraio', fromDate: '02/021/', toDate: '02/28/' },
        { value: '03', label: 'Marzo', fromDate: '03/01/', toDate: '03/31/' },
        { value: '04', label: 'Aprile', fromDate: '04/01/', toDate: '04/30/' },
        { value: '05', label: 'Maggio', fromDate: '05/01/', toDate: '05/31/' },
        { value: '06', label: 'Giugno', fromDate: '06/01/', toDate: '06/30/' },
        { value: '07', label: 'Luglio', fromDate: '07/01/', toDate: '07/31/' },
        { value: '08', label: 'Agosto', fromDate: '08/01/', toDate: '08/31/' },
        { value: '09', label: 'Settembre', fromDate: '09/01/', toDate: '09/30/' },
        { value: '10', label: 'Ottobre', fromDate: '10/01/', toDate: '10/31/' },
        { value: '11', label: 'Novembre', fromDate: '11/01/', toDate: '11/30/' },
        { value: '12', label: 'Dicembre', fromDate: '12/01/', toDate: '12/31/' },
    ];

    const [typeSearch, setTypeSearch] = useState('month');
    const [from, setFrom] = useState({name: 'malpensa', code: 'MXP'});
    const [to, setTo] = useState({name: 'budapest', code: 'BUD'});
    const [nightInDstFrom, setNightInDstFrom] = useState('2');
    const [nightInDstTo, setnightInDstTo] = useState('7');
    const [price, setPrice] = useState(25);
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [lang, setLang] = useState('it');
    const [stopOver, setStopOver] = useState('0');
    const [voliDiretti, setVoliDiretti] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(monthOptions[new Date().getMonth() === 11 ? 0 : new Date().getMonth()+1]);

    const [isDep, setIsDep] = useState(); // controlla se è partenza o arrivo
    const [searchCitySheet, setSearchCitySheet] = useState(false);
    const [kiwiOffers, setkiwiOffers] = useState([]);
    const [aviaSalesOffer, setAviaSalesOffer] = useState([]);
    const [ryanairOffers, setRyanairOffers] = useState([]);
    const [loadingOffers, setLoadingOffers] = useState([]);
    const [allOffers, setAllOffers] = useState([]);
    const [ryanairAlreadyLoaded, setRyanairAlreadyLoaded] = useState(false);
    const [selectDatesSheet, setSelectDatesSheet] = useState(false);
    const [aSSearchId, setASSearchId] = useState();
    const [searchClick, setSearchClick] = useState(false);
    const [userIp, setUserIp] = useState('127.0.0.1');

    const flightsKiwi = 'https://tequila-api.kiwi.com/v2/search?fly_from=';
    const tequilaKey = 'NPwrSMNPfBXmx1eGuVGrAK6UthlGNU3M';
    const aviaSalesUrl = 'https://api.travelpayouts.com/v1/flight_search';
    const ryanairUrl = 'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&';

    useEffect(()=> {
        if (inResult) {
            setFormInformation();
        }
        getIp();
    }, [])

    const getIp = ()=> {
        axios.get('https://geolocation-db.com/json/')
        .then((response) => {
            setUserIp(response.data.IPv4)
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    const setFormInformation = ()=> {
        if (formInformation.state.formInfo) {
            setTypeSearch(formInformation.state.formInfo.typeSearch);
            setFrom(formInformation.state.formInfo.from);
            setTo(formInformation.state.formInfo.to);
            setPrice(formInformation.state.formInfo.price);
            setDepartureDate(formInformation.state.formInfo.departureDate);
            setReturnDate(formInformation.state.formInfo.returnDate);
            setSelectedMonth(formInformation.state.formInfo.selectedMonth);
            setnightInDstTo(formInformation.state.formInfo.nightInDstTo);
            setNightInDstFrom(formInformation.state.formInfo.nightInDstFrom);
            setVoliDiretti(formInformation.state.formInfo.voliDiretti);
        } 
        else if (formInformation.state.data && formInformation.state.isSearch) {

            setTypeSearch('round');
            setFrom(formInformation.state.data.dep);
            setTo(formInformation.state.data.arr);
            setPrice(formInformation.state.data.price);
            setDepartureDate(formInformation.state.data.dateDep);
            setReturnDate(formInformation.state.data.dateRet);

            searchOffers();
        }
    }

    const changeDepWithRetu = ()=> {
        let departure = from;
        let destination = to;
        setFrom(destination);
        setTo(departure);
    }

    const openSearchCity = (isDep) => {
        isLoading(true);
        setIsDep(isDep);
        setSearchCitySheet(true);
    }

    useEffect(()=>{
        isLoading(false);
    }, [searchCitySheet])

    const closeSearchCity = (city) => {
        if (city) {
            if (isDep) {
                setFrom(city);
            } else {
                setTo(city);
            }
        }
        setSearchCitySheet(false);
    }

    const closeSelectDates = (dates) => {
        setSelectDatesSheet(false);
        setDepartureDate(new Date(dates[0].startDate));
        setReturnDate(new Date(dates[0].endDate));
    }

    const searchOffers = ()=> {
        isLoading(true);
        setSearchClick(true);
        setRyanairAlreadyLoaded(false);
        let noArrival = to.code === "";
        let finalPriceTo;
        finalPriceTo = price === 500 ? 1500 : price;
        setStopOver(voliDiretti ? '0' : '2');
        switch(typeSearch) {
            case 'round':
                roundOffers(noArrival, finalPriceTo);
            break;
            case 'month':
                monthOffers(noArrival, finalPriceTo);
            break;
            case 'year':
                yearOffers(noArrival, finalPriceTo); 
            break;
            default: roundOffers(noArrival); //TODO creare messaggio di errore
        }
    }

    const roundOffers = (noArrival, finalPriceTo)=> {
        let stop = voliDiretti ? '0' : '1';
        let link = noArrival ?
            from.code + '&date_from=' + formatDate(departureDate) +'&date_to=' + formatDate(departureDate) + '&return_from='+ formatDate(returnDate) +'&return_to='+ formatDate(returnDate) +'&nights_in_dst_from='+nightInDstFrom+'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' +'&max_fly_duration=20&flight_type=round&adults='+ 1 + '&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from=' + '10' + '&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft'
        :
            from.code + '&fly_to=' + to.code + '&date_from=' + formatDate(departureDate) +'&date_to=' + formatDate(departureDate) + '&return_from='+ formatDate(returnDate) +'&return_to='+ formatDate(returnDate) +'&nights_in_dst_from='+nightInDstFrom+'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' +'&max_fly_duration=20&flight_type=round&adults='+ 1 + '&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from=' + '10' + '&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft'

        axios.get(flightsKiwi + link,
        {headers: {apikey: tequilaKey, accept: 'application/json'}})
        .then((response) => {
          setkiwiOffers(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    const monthOffers = (noArrival, finalPriceTo)=> {
        let today = new Date();
        let firstDay = new Date(selectedMonth.fromDate+today.getFullYear().toString());
        let year = today.getFullYear();
        if (today.getMonth() >= firstDay.getMonth()) {
            year+=1;
        }
        let stop = voliDiretti ? '0' : '1';
        let link = noArrival ? 
            from.code + '&date_from=' + formatDate(new Date(selectedMonth.fromDate+year.toString())) +'&date_to=' + formatDate(new Date(selectedMonth.toDate+year.toString())) + '&return_from='+ formatDate(new Date(selectedMonth.fromDate+year.toString())) +'&return_to='+ formatDate(new Date(selectedMonth.toDate+year.toString())) +'&nights_in_dst_from='+ nightInDstFrom +'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' + '&max_fly_duration=20&flight_type=round&one_for_city=1&adults=1&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from='+ '10' +'&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft'
        :
            from.code + '&fly_to=' + to.code + '&date_from=' + formatDate(new Date(selectedMonth.fromDate+year.toString())) +'&date_to=' + formatDate(new Date(selectedMonth.toDate+year.toString())) + '&return_from='+ formatDate(new Date(selectedMonth.fromDate+year.toString())) +'&return_to='+ formatDate(new Date(selectedMonth.toDate+year.toString())) +'&nights_in_dst_from='+ nightInDstFrom +'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' + '&max_fly_duration=20&flight_type=round&adults=1&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from='+ '10' +'&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft';

        axios.get(flightsKiwi + link,
        {headers: {apikey: tequilaKey, accept: 'application/json'}})
        .then((response) => {
          setkiwiOffers(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    const yearOffers = (noArrival, finalPriceTo) => {
        let today = new Date();
        let year = today.getFullYear();
        if (today.getMonth() >= 10) {
            year+=1;
        }
        let stop = voliDiretti ? '0' : '1';
        let link = noArrival ? 
            from.code + '&date_from=' + formatDate(today) +'&date_to=' + '31/12/' + year + '&return_from='+ formatDate(today) +'&return_to='+ '31/12/' + year +'&nights_in_dst_from='+ nightInDstFrom +'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' + '&max_fly_duration=20&flight_type=round&one_for_city=1&adults=1&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from='+ '10' +'&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft'
        :
            from.code + '&fly_to=' + to.code + '&date_from=' + formatDate(today) +'&date_to=' + '31/12/' + year + '&return_from='+ formatDate(today) +'&return_to='+ '31/12/' + year +'&nights_in_dst_from='+ nightInDstFrom +'&nights_in_dst_to='+ nightInDstTo + '&ret_from_diff_city=false&ret_to_diff_city=false' + '&max_fly_duration=20&flight_type=round&adults=1&selected_cabins=M&partner_market=' + lang + '&curr=EUR&locale=' + lang + '&price_from='+ '10' +'&price_to=' + finalPriceTo +'&max_stopovers=' + stop + '&vehicle_type=aircraft';

        axios.get(flightsKiwi + link,
        {headers: {apikey: tequilaKey, accept: 'application/json'}})
        .then((response) => {
          setkiwiOffers(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    useEffect(()=> {
        if (kiwiOffers.length) {
            searchAviaSales();
        } else if(!kiwiOffers.length && searchClick) {
            isLoading(false);
            noFlights();
        }
    }, [kiwiOffers])

    const searchAviaSales = () => {
        let bestkiwiOffers = kiwiOffers[0];
 
        let depDate = new Date(bestkiwiOffers.route[0].local_departure);
        let retDate = new Date(bestkiwiOffers.route[1].local_departure);
        let toCode = to.isCountry || to.code === '' ? kiwiOffers[0].flyTo : to.code;
        let fromCode = from.isCountry || from.code === '' ? kiwiOffers[0].flyFrom : from.code;
        axios.post('https://thriving-panda-73b004.netlify.app/.netlify/functions/api/asd',
        {
            dep: formatASDate(depDate),
            depdestination: toCode,
            deporigin: fromCode,
            ret: formatASDate(retDate),
            retdestination: fromCode,
            retorigin: toCode,
            ip: userIp
        },
        {headers: {
            "content-type": "application/json",
          }}
        )
        .then((response) => {
            let cheapest = [];
            let searchASId = response[0] && response[0].search_id ? response[0].search_id : undefined;
            setASSearchId(response.data[0].search_id);
                if (response.data.length > 0) {
                  for(let i = 0; i < response.data.length; i++) {
                    if (response.data[i].proposals.length && searchASId) {
                        if (response.data[i].proposals[0].terms[Object.keys(response.data[i].proposals[0].terms)].price <= price) {
                            response.data[i].proposals[0].isAviaSales = true;
                            cheapest.push(response.data[i].proposals[0])
                        }
                    }
                  }
                }
            setAviaSalesOffer(cheapest);
            isLoading(false);
        })
        .catch((error) => {
            isLoading(false);
            setAviaSalesOffer([]);
            console.log(error);
        })     
    }

    useEffect(()=>{
        let allOffers = kiwiOffers;
        if (aviaSalesOffer) allOffers = allOffers.concat(aviaSalesOffer);
        setLoadingOffers(allOffers);
    }, [aviaSalesOffer]);

    useEffect(()=>{
        if(kiwiOffers.length) searchRyanair();
    }, [loadingOffers])

    const searchRyanair = ()=> {
        let noArrival = to.code === "";
        let isCountry = to.code.lenght == 2 ? true : false;
        let finalPriceTo = price === 500 ? 1500 : price;
        switch(typeSearch) {
            case 'round':
                ryanairRoundOffers(noArrival, finalPriceTo, isCountry);
            break;
            case 'month':
                ryanairMonthOffers(noArrival, finalPriceTo, isCountry);
            break;
            case 'year':
                ryanairYearOffers(noArrival, finalPriceTo, isCountry); 
            break;
            default: roundOffers(noArrival); //TODO creare messaggio di errore
        }        
    }

    const ryanairRoundOffers = (noArrival, finalPriceTo, isCountry)=> {
        let link  = noArrival ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(returnDate) +'&inboundDepartureDateTo='+ formatASDate(returnDate) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(departureDate) +'&outboundDepartureDateTo='+ formatASDate(departureDate) +'&priceValueTo=' + finalPriceTo
         :
         isCountry ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalCountryCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(returnDate) +'&inboundDepartureDateTo='+ formatASDate(returnDate) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(departureDate) +'&outboundDepartureDateTo='+ formatASDate(departureDate) +'&priceValueTo=' + finalPriceTo
         :
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalAirportIataCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(returnDate) +'&inboundDepartureDateTo='+ formatASDate(returnDate) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(departureDate) +'&outboundDepartureDateTo='+ formatASDate(departureDate) +'&priceValueTo=' + finalPriceTo
        
        axios.get(link)
        .then((response) => {
            if (response.data.fares.length && !ryanairAlreadyLoaded) {
                response.data.fares.forEach(fare => {
                    fare.isRyanair = true;
                });
                setRyanairAlreadyLoaded(true);
                setRyanairOffers(response.data.fares);
            } else {
                editOffers(loadingOffers);
            }
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    const ryanairMonthOffers = (noArrival, finalPriceTo, isCountry)=> {
        let today = new Date();
        let firstDay = new Date(selectedMonth.fromDate+today.getFullYear().toString());
        let year = today.getFullYear();
        if (today.getMonth() >= firstDay.getMonth()) {
            year+=1;
        }
        let link  = noArrival ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&inboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&outboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&priceValueTo=' + finalPriceTo
         :
         isCountry ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalCountryCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&inboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&outboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&priceValueTo=' + finalPriceTo
         :
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalAirportIataCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&inboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(new Date(selectedMonth.fromDate+year.toString())) +'&outboundDepartureDateTo='+ formatASDate(new Date(selectedMonth.toDate+year.toString())) +'&priceValueTo=' + finalPriceTo
        
        axios.get(link)
        .then((response) => {
            if (response.data.fares.length && !ryanairAlreadyLoaded) {
                response.data.fares.forEach(fare => {
                    fare.isRyanair = true;
                });
                setRyanairAlreadyLoaded(true);
                setRyanairOffers(response.data.fares);
            } else {
                editOffers(loadingOffers);
            }
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    const ryanairYearOffers = (noArrival, finalPriceTo, isCountry)=> {
        let today = new Date();
        let year = today.getFullYear();
        if (today.getMonth() >= 10) {
            year+=1;
        }
        let endYearDay = new Date(year+'-12-31');
        let link  = noArrival ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(today) +'&inboundDepartureDateTo='+ formatASDate(endYearDay) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(today) +'&outboundDepartureDateTo='+ formatASDate(endYearDay) +'&priceValueTo=' + finalPriceTo
         :
         isCountry ? 
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalCountryCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(today) +'&inboundDepartureDateTo='+ formatASDate(endYearDay) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(today) +'&outboundDepartureDateTo='+ formatASDate(endYearDay) +'&priceValueTo=' + finalPriceTo
         :
            'https://www.ryanair.com/api/farfnd/3/roundTripFares?&ToUs=AGREED&arrivalAirportIataCode='+ to.code +'&departureAirportIataCode='+ from.code +'&durationFrom='+ nightInDstFrom +'&durationTo='+ nightInDstTo +'&inboundDepartureDateFrom='+ formatASDate(today) +'&inboundDepartureDateTo='+ formatASDate(endYearDay) +'&language=it&limit=16&market=it-it&offset=0&outboundDepartureDateFrom='+ formatASDate(today) +'&outboundDepartureDateTo='+ formatASDate(endYearDay) +'&priceValueTo=' + finalPriceTo
        
        axios.get(link)
        .then((response) => {
            if (response.data.fares.length && !ryanairAlreadyLoaded) {
                response.data.fares.forEach(fare => {
                    fare.isRyanair = true;
                });
                setRyanairAlreadyLoaded(true);
                setRyanairOffers(response.data.fares);
            } else {
                editOffers(loadingOffers);
            }
        })
        .catch((error) => {
          console.log(error);
          isLoading(false);
        })
    }

    useEffect(()=>{
        let allOffers = loadingOffers;
        if (ryanairOffers) allOffers = allOffers.concat(ryanairOffers);
        editOffers(allOffers);
    }, [ryanairOffers])

    const editOffers = (offers)=> {
        offers.forEach(off => {
            if (off.isAviaSales) {
                editASObj(off);  
            } else if(off.isRyanair) {
                editRyanairObj(off);
            } else {
                off.date = setCustomDate(off);
            }
            setAirlinesName(off);
        });
        offers.sort(function(a, b) {
            return a.price - b.price;
        });
        setAllOffers(offers);
    }

    const formatDate = (date) => {
        return '' + (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString()) + '/' + ((date.getMonth()+1) >= 10 ? (date.getMonth()+1).toString() : '0' + (date.getMonth()+1).toString()) + '/' + date.getFullYear();
    }

    const formatASDate = (date)=> {
        return '' + date.getFullYear() + '-' + ((date.getMonth()+1) >= 10 ? (date.getMonth()+1).toString() : '0' + (date.getMonth()+1).toString()) + '-' + (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString());
    }

    const setCustomDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.route[0].local_departure.substring(8, 10) + '/' + offert.route[0].local_departure.substring(5, 7) + '/' + offert.route[0].local_departure.substring(0, 4),
          to: offert.route[0].local_arrival.substring(8, 10) + '/' + offert.route[0].local_arrival.substring(5, 7) + '/' + offert.route[0].local_arrival.substring(0, 4),
        };
        offert.date.return = {
          from: offert.route[1].local_departure.substring(8, 10) + '/' + offert.route[1].local_departure.substring(5, 7) + '/' + offert.route[1].local_departure.substring(0, 4),
          to: offert.route[1].local_arrival.substring(8, 10) + '/' + offert.route[1].local_arrival.substring(5, 7) + '/' + offert.route[1].local_arrival.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.route[0].local_departure.substring(11, 16),
          to: offert.route[0].local_arrival.substring(11, 16),
        };
        offert.date.retH = {
          from: offert.route[1].local_departure.substring(11, 16),
          to: offert.route[1].local_arrival.substring(11, 16),
        };
        return offert.date;
    }

    const setCustomASDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.segment[0].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[0].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[0].flight[0].departure_date.substring(0, 4),
          to: offert.segment[0].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[0].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[0].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.return = {
          from: offert.segment[1].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[1].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[1].flight[0].departure_date.substring(0, 4),
          to: offert.segment[1].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[1].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[1].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.segment[0].flight[0].departure_time,
          to: offert.segment[0].flight[0].arrival_time,
        };
        offert.date.retH = {
          from: offert.segment[1].flight[0].departure_time,
          to: offert.segment[1].flight[0].arrival_time,
        };
        return offert.date;
    }

    const setCustomRyanairDate = (offert)=> {
        offert.date = {};
        offert.date.departure = {
          from: offert.outbound.departureDate.substring(8, 10) + '/' + offert.outbound.departureDate.substring(5, 7) + '/' + offert.outbound.departureDate.substring(0, 4),
          to: offert.outbound.arrivalDate.substring(8, 10) + '/' + offert.outbound.arrivalDate.substring(5, 7) + '/' + offert.outbound.arrivalDate.substring(0, 4),
        };
        offert.date.return = {
          from: offert.inbound.departureDate.substring(8, 10) + '/' + offert.inbound.departureDate.substring(5, 7) + '/' + offert.inbound.departureDate.substring(0, 4),
          to: offert.inbound.arrivalDate.substring(8, 10) + '/' + offert.inbound.arrivalDate.substring(5, 7) + '/' + offert.inbound.arrivalDate.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.outbound.departureDate.substring(11, 16),
          to: offert.outbound.arrivalDate.substring(11, 16),
        };
        offert.date.retH = {
          from: offert.inbound.departureDate.substring(11, 16),
          to: offert.inbound.arrivalDate.substring(11, 16),
        };
        return offert.date;
    }

    const setAirlinesName = (offert) => {
        let fDeparture = airlines.find(x => x.iata === offert.route[0].airline);
        if (fDeparture) {
          offert.route[0].airline = {detail: fDeparture}
        } else {
            offert.route[0].airline = {detail: {iata: '-', name: '-'}}
        }
    
        let fReturn = airlines.find(x => x.iata === offert.route[1].airline);
        if (fReturn) {
          offert.route[1].airline = {detail: fReturn}
        } else {
            offert.route[1].airline = {detail: {iata: '-', name: '-'}}
        }
    }

    const editASObj = (offer) => {
        offer.searchId = aSSearchId;
        offer.termsUrl = offer.terms[Object.keys(offer.terms)].url;
        offer.cityFrom = kiwiOffers[0].cityFrom;
        offer.flyFrom = offer.segment[0].flight[0].departure;
        offer.cityTo = kiwiOffers[0].cityTo;
        offer.flyTo = offer.segment[0].flight[0].arrival;
        offer.price = offer.terms[Object.keys(offer.terms)].price;
        // ERA PER LA LUNGHEZZA DEL VOLO MA NON VA
        // offer.outbound = {};
        // offer.outbound.arrivalDate = offer.segment[0].flight[0].arrival_timestamp;
        // offer.outbound.departureDate = offer.segment[0].flight[0].departure_timestamp;
        // offer.inbound = {};
        // offer.inbound.arrivalDate = offer.segment[1].flight[0].arrival_timestamp;
        // offer.inbound.departureDate = offer.segment[1].flight[0].departure_timestamp;
        offer.route = [];
        offer.route[0] = {};
        offer.route[1] = {};
        offer.route[0].airline = offer.segment[0].flight[0].operated_by;
        offer.route[1].airline = offer.segment[1].flight[0].operated_by;
        offer.route[0].flight_no = offer.segment[0].flight[0].number;
        offer.route[1].flight_no = offer.segment[1].flight[0].number;
        offer.nightsInDest = '-';
        setCustomASDate(offer);
    }

    const editRyanairObj = (offer)=> {
        // offer.searchId = aSSearchId;
        // offer.termsUrl = offer.terms[Object.keys(offer.terms)].url;
        offer.cityFrom = kiwiOffers[0].cityFrom;
        offer.flyFrom = offer.outbound.departureAirport.iataCode;
        offer.cityTo = kiwiOffers[0].cityTo;
        offer.flyTo = offer.outbound.arrivalAirport.iataCode;
        offer.price = Math. floor(offer.summary.price.value);
        // ERA PER LA LUNGHEZZA DEL VOLO MA NON VA
        // offer.outbound = {};
        // offer.outbound.arrivalDate = offer.segment[0].flight[0].arrival_timestamp;
        // offer.outbound.departureDate = offer.segment[0].flight[0].departure_timestamp;
        // offer.inbound = {};
        // offer.inbound.arrivalDate = offer.segment[1].flight[0].arrival_timestamp;
        // offer.inbound.departureDate = offer.segment[1].flight[0].departure_timestamp;
        offer.route = [];
        offer.route[0] = {};
        offer.route[1] = {};
        offer.route[0].airline = 'Ryanair';
        offer.route[1].airline = 'Ryanair';
        offer.route[0].flight_no = offer.outbound.flightNumber;
        offer.route[1].flight_no = offer.inbound.flightNumber;
        offer.nightsInDest = '-';
        offer.deep_link = `www.ryanair.com/it/it/booking/home/${from.code}/${to.code}/${offer.outbound.departureDate.substring(0, 10)}/${offer.inbound.departureDate.substring(0, 10)}/1/0/0/0`;
        setCustomRyanairDate(offer);
    }

    const setPhoto = (offert) => {
        let img = CitiesPhoto.find(e => e.name.toLowerCase() === offert.flyTo.toLowerCase());
        if (to.code === "" || !img) {
          img = CitiesPhoto.find(e => e.name.toLowerCase() === 'off');
        }
        return img;
    }

    useEffect(()=> {
        if (allOffers.length) {
            navigate('/searchResults', {state:{offers:createNewOffer(), formInfo: saveFormInfo()}});
            if (inResult) {
                window.location.reload(false);
            }
            setSearchClick(false);
        }
    }, [allOffers])

    const saveFormInfo = ()=> {
        let formInformation = {
            to: to,
            from: from,
            price: price,
            typeSearch: typeSearch,
            departureDate: departureDate,
            returnDate: returnDate,
            selectedMonth: selectedMonth,
            nightInDstTo: nightInDstTo,
            nightInDstFrom: nightInDstFrom,
            voliDiretti: voliDiretti
        }
        return formInformation;
    }

    const createNewOffer = () => {
        let defaultObj = {
          name: 'Offerte',
          code: '',
          cost: 60
        };
        let cityToObj = {
            code: to.code,
            name: to.name,
            cost: price,
            guide: findGuide(to.name)
        }
        
        let offers = {
          id: undefined,
          cityTo: cityToObj,
          GYG: '<div></div>',
          searchType: typeSearch,
          offers: allOffers,
          offersType: to.code === "" || to.isCountry ? 'noarrival' : 'arrival',
          img: setPhoto(allOffers[0]),
          voliDiretti: voliDiretti,
          bookingId: to.bookingId ? to.bookingId : undefined,
          selectedMonth: selectedMonth ? selectedMonth : undefined
        }
        return offers;
    }

    const findGuide = (to)=> {
        let guida = guide.find(x=>x.name === to.toLowerCase());
        if (guida) return guida.guide;
        return null;
    }

    const selectMonth = (e)=> {
        setSelectedMonth(e)
    }

    const goToGuideSearch = ()=> {
        navigate('/searchGuide');
    }

    return (
        <>
            <Form className='container'>
                <Form.Group className="w-100 center">
                    <div className="center pointer round_one w-65">
                      <Form.Label className={typeSearch === 'round' ? 'w-50 mb-0 r-o-check' : 'w-50 mb-0 r-o-unCheck'} onClick={()=>{setTypeSearch('round')}}>Date</Form.Label>
                      <Form.Label className={typeSearch === 'month' ? 'w-50 mb-0 r-o-check' : 'w-50 mb-0 r-o-unCheck'} onClick={()=>{setTypeSearch('month')}}>Mese</Form.Label>
                      <Form.Label className={typeSearch === 'year'  ? 'w-50 mb-0 r-o-check' : 'w-50 mb-0 r-o-unCheck'} onClick={()=>{setTypeSearch('year')}}>Anno</Form.Label>
                    </div>
                </Form.Group>
                <Form.Group className="w-100 center">
                    <div className="f_sb pointer w-100">
                        <div className='w-50' onClick={()=>{openSearchCity(true)}}>
                            <Form.Label className="code mb-0">{from.code}</Form.Label>
                            <Form.Label className="city mb-0">{from.name}</Form.Label>
                        </div>
                        <div className="pointer">
                          <img className="round_trip" src={invert} onClick={changeDepWithRetu} />
                        </div>
                        <div className='w-50' onClick={()=>{openSearchCity(false)}}>
                            <Form.Label className="code mb-0">{to.code}</Form.Label>
                            <Form.Label className="city mb-0">{to.name}</Form.Label>
                        </div>
                    </div>
                </Form.Group>
                <Form.Group>
                    {
                        typeSearch === 'round' ? 
                        <div className="rdrDateDisplayWrapper" style={{'borderRadius': '10px', 'backgroundColor': 'rgb(239 242 247 / 27%)'}} onClick={()=>{setSelectDatesSheet(true)}}>
                            <div className="rdrDateDisplay" style={{'color': 'rgb(61, 145, 255)', 'padding': '0.5rem', 'margin': '0'}} onClick={()=>{setSelectDatesSheet(true)}}>
                                <span className="rdrDateInput rdrDateDisplayItem">
                                    <span className='spanAsInput' onClick={(e)=>{e.preventDefault(); setSelectDatesSheet(true)}}>
                                        {
                                          departureDate.toLocaleDateString('it') ?
                                          departureDate.toLocaleDateString('it')
                                          :
                                          "Partenza"
                                        }
                                    </span>
                                </span>
                                <span className="rdrDateInput rdrDateDisplayItem">
                                    <span className='spanAsInput' onClick={(e)=>{e.preventDefault(); setSelectDatesSheet(true)}}>
                                        {
                                          returnDate.toLocaleDateString('it') ?
                                          returnDate.toLocaleDateString('it')
                                          :
                                          "Ritorno"
                                        }
                                    </span>
                                </span>
                            </div>
                        </div>
                        : 
                        typeSearch === 'month' ?
                        <div>
                            <div className="rdrDateDisplayWrapper lh2" style={{'borderRadius': '10px', 'backgroundColor': 'rgb(239 242 247 / 27%)'}}>
                                <div className="rdrDateDisplay" style={{'color': 'rgb(61, 145, 255)', 'padding': '0.5rem', 'margin': '0'}}>
                                <Select
                                  value={selectedMonth}
                                  onChange={(e)=>{selectMonth(e)}}
                                  isSearchable={false}
                                  options={monthOptions}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                      ...theme.colors,
                                      primary25: 'white',
                                      primary: '#fe534c',
                                      neutral60: 'black'
                                    },
                                  })}
                                />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="rdrDateDisplayWrapper" style={{'borderRadius': '10px', 'backgroundColor': 'rgb(239 242 247 / 27%)'}} onClick={openToast}>
                            <div className="rdrDateDisplay" style={{'color': 'rgb(61, 145, 255)', 'padding': '0.5rem', 'margin': '0'}}>
                                <span className="rdrDateInput rdrDateDisplayItem">
                                    <input placeholder="Partenza" value={new Date().toLocaleDateString('it')} disabled/>
                                </span>
                                <span className="rdrDateInput rdrDateDisplayItem">
                                    <input placeholder="Ritorno" value={'31/12/' + (new Date().getMonth() >= 10 ? (new Date().getFullYear()+1) : new Date().getFullYear())} disabled/>
                                </span>
                            </div>
                        </div>
                    }
                    
                </Form.Group>
                <Form.Group className='price_container mt-1'>
                <Form.Label className="mb-0 price_txt">Prezzo max: {price}{price === 500 ? '+' : ''}€</Form.Label>
                    <Slider
                        value={price}
                        max={500}
                        tooltip={false}
                        onChange={(e)=>{setPrice(e)}}
                    />
                </Form.Group>
                <Form.Group className='f_sb me-3 ms-3'>
                    <div className='relative center w-33' style={{'display': 'flex', 'justifyContent':'start'}}>
                        <img className='imgw18 absolute' src={settings} alt="settings" />
                        <Dropdown className='absolute' autoClose={'outside'}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            i
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                                Voli diretti
                                <Form.Check 
                                  type="switch"
                                  id="custom-switch"
                                  checked={voliDiretti}
                                  onChange={()=>{setVoliDiretti(!voliDiretti)}}
                                />
                            </Dropdown.Item>
                            {
                                typeSearch === 'round' ? 
                                <div></div>
                                :
                                <Dropdown.Item href="#/action-2">
                                    Notti min <Form.Control type="number" value={nightInDstFrom} onChange={(e)=>{setNightInDstFrom(e.target.value)}} disabled={typeSearch === 'round'}/>
                                </Dropdown.Item>
                            }
                            {
                                typeSearch === 'round' ? 
                                <div></div>
                                :
                                <Dropdown.Item href="#/action-2">
                                    Notti max <Form.Control type="number" value={nightInDstTo} onChange={(e)=>{setnightInDstTo(e.target.value)}} disabled={typeSearch === 'round'}/>
                                </Dropdown.Item>
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Button className='w-33 find_btn' variant="light" onClick={searchOffers}>CERCA</Button>
                    {/* <div></div> */}
                    <div className='w-33' onClick={goToGuideSearch} style={{'display': 'flex', 'justifyContent':'end'}}>
                        <img src={guidePhoto} alt="filter" className='imgw22'/>
                    </div>
                </Form.Group>
            </Form>
            <SearchCity open={searchCitySheet} isDep={isDep} close={closeSearchCity}/>
            <SelectDates open={selectDatesSheet} close={closeSelectDates}/>
        </>
    )
}

export default SearchForm;