import {useParams, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import axios from 'axios';
import airlines from '../../consts/airlines';
import CitiesPhoto from '../../consts/citiesPhoto';
import guide from '../../consts/guide';
import airports from '../../consts/airports';
import landing from '../../assets/landingLink.png';
import Spinner from 'react-bootstrap/Spinner';
import './searchLink.css';
import Button from 'react-bootstrap/Button';
import NoResult from '../../assets/noResult.png';
import ShareSocialPage from '../shareSocialPage/shareSocialPage';

function SearchLink() {

    let {data} = useParams();
    const navigate = useNavigate();
    const flightsKiwi = 'https://tequila-api.kiwi.com/v2/search?fly_from=';
    const tequilaKey = 'NPwrSMNPfBXmx1eGuVGrAK6UthlGNU3M';

    const [searchData, setSearchData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [kiwiOffers, setkiwiOffers] = useState([]);
    const [aviaSalesOffer, setAviaSalesOffer] = useState([]);
    const [userIp, setUserIp] = useState('127.0.0.1');
    const [aSSearchId, setASSearchId] = useState();
    const [allOffers, setAllOffers] = useState([]);
    const [noFlights, setNoFlights] = useState(false);

    useEffect(()=>{
        if (data) {
            let searchInfo = {
                dep: findCity(data.substring(0, 3)),
                arr: findCity(data.substring(4, 7)),
                dateDep: data.substring(8, 18).split('-').join('/'),
                dateRet: data.substring(19, 29).split('-').join('/'),
                searchType: data.substring(30, 31),
                price: Number(data.substring(32))+100
            }
            getIp();
            setSearchData(searchInfo);
        }
    }, [data]);

    const findCity = (code) => {
        return airports.find(city => city.code === code);
    }

    const getIp = ()=> {
        axios.get('https://geolocation-db.com/json/')
        .then((response) => {
            setUserIp(response.data.IPv4)
        })
        .catch((error) => {
          console.log(error);
        })
    }

    useEffect(()=>{
        if(searchData) searchOffers();
    },[searchData])

    const searchOffers = ()=> {
        setIsLoading(true);
        let link = searchData.searchType == 0 ?
        searchData.dep.code + '&fly_to=' + searchData.arr.code + '&date_from=' + searchData.dateDep +'&date_to=' + searchData.dateDep + '&return_from='+ searchData.dateRet +'&return_to='+ searchData.dateRet +'&nights_in_dst_from='+1+'&nights_in_dst_to='+ 10 + '&ret_from_diff_city=false&ret_to_diff_city=false' +'&max_fly_duration=20&flight_type=round&adults='+ 1 + '&selected_cabins=M&partner_market=' + 'it' + '&curr=EUR&locale=' + 'it' + '&price_from=' + '10' + '&price_to=' + searchData.price +'&max_stopovers=' + 0 + '&vehicle_type=aircraft'
        :
        searchData.dep.code + '&fly_to=' + searchData.arr.code + '&date_from=' + searchData.dateDep +'&date_to=' + searchData.dateRet + '&return_from='+ searchData.dateDep +'&return_to='+ searchData.dateRet +'&nights_in_dst_from='+1+'&nights_in_dst_to='+ 10 + '&ret_from_diff_city=false&ret_to_diff_city=false' +'&max_fly_duration=20&flight_type=round&adults='+ 1 + '&selected_cabins=M&partner_market=' + 'it' + '&curr=EUR&locale=' + 'it' + '&price_from=' + '10' + '&price_to=' + searchData.price +'&max_stopovers=' + 0 + '&vehicle_type=aircraft';

        axios.get(flightsKiwi + link,
        {headers: {apikey: tequilaKey, accept: 'application/json'}})
        .then((response) => {
            setkiwiOffers(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        })
    };

    useEffect(()=> {
        if (kiwiOffers.length) {
            searchAviaSales();
        } else if(!kiwiOffers.length) {
            setIsLoading(false);
            setNoFlights(true);
        }
    }, [kiwiOffers]);

    const searchAviaSales = () => {
        let bestkiwiOffers = kiwiOffers[0];
 
        let depDate = new Date(bestkiwiOffers.route[0].local_departure);
        let retDate = new Date(bestkiwiOffers.route[1].local_departure);
        let toCode = kiwiOffers[0].flyTo;
        let fromCode = kiwiOffers[0].flyFrom;
        axios.post('https://thriving-panda-73b004.netlify.app/.netlify/functions/api/asd',
        {
            dep: formatASDate(depDate),
            depdestination: toCode,
            deporigin: fromCode,
            ret: formatASDate(retDate),
            retdestination: fromCode,
            retorigin: toCode,
            ip: userIp
        },
        {headers: {
            "content-type": "application/json",
          }}
        )
        .then((response) => {
            let cheapest = [];
            let searchASId = response[0] && response[0].search_id ? response[0].search_id : undefined;
            setASSearchId(response.data[0].search_id);
                if (response.data.length > 0) {
                  for(let i = 0; i < response.data.length; i++) {
                    if (response.data[i].proposals.length && searchASId) {
                        if (response.data[i].proposals[0].terms[Object.keys(response.data[i].proposals[0].terms)].price <= searchData.price) {
                            response.data[i].proposals[0].isAviaSales = true;
                            cheapest.push(response.data[i].proposals[0])
                        }  
                    }
                  }
                }
            setAviaSalesOffer(cheapest);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            setAviaSalesOffer([]);
            console.log(error);
        })     
    }

    const formatASDate = (date)=> {
        return '' + date.getFullYear() + '-' + ((date.getMonth()+1) >= 10 ? (date.getMonth()+1).toString() : '0' + (date.getMonth()+1).toString()) + '-' + (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString());
    }

    useEffect(()=>{
        let allOffers = kiwiOffers;
        if (aviaSalesOffer) allOffers = allOffers.concat(aviaSalesOffer);
        editOffers(allOffers);
    }, [aviaSalesOffer]);

    const editOffers = (offers)=> {
        offers.forEach(off => {
            if (off.isAviaSales) {
                editASObj(off);  
            } else {
                off.date = setCustomDate(off);
            }
            setAirlinesName(off);
        });
        offers.sort(function(a, b) {
            return a.price - b.price;
        });
        setAllOffers(offers);
    }

    const editASObj = (offer) => {
        offer.searchId = aSSearchId;
        offer.termsUrl = offer.terms[Object.keys(offer.terms)].url;
        offer.cityFrom = kiwiOffers[0].cityFrom;
        offer.flyFrom = offer.segment[0].flight[0].departure;
        offer.cityTo = kiwiOffers[0].cityTo;
        offer.flyTo = offer.segment[0].flight[0].arrival;
        offer.price = offer.terms[Object.keys(offer.terms)].price;
        // ERA PER LA LUNGHEZZA DEL VOLO MA NON VA
        // offer.outbound = {};
        // offer.outbound.arrivalDate = offer.segment[0].flight[0].arrival_timestamp;
        // offer.outbound.departureDate = offer.segment[0].flight[0].departure_timestamp;
        // offer.inbound = {};
        // offer.inbound.arrivalDate = offer.segment[1].flight[0].arrival_timestamp;
        // offer.inbound.departureDate = offer.segment[1].flight[0].departure_timestamp;
        offer.route = [];
        offer.route[0] = {};
        offer.route[1] = {};
        offer.route[0].airline = offer.segment[0].flight[0].operated_by;
        offer.route[1].airline = offer.segment[1].flight[0].operated_by;
        offer.route[0].flight_no = offer.segment[0].flight[0].number;
        offer.route[1].flight_no = offer.segment[1].flight[0].number;
        offer.nightsInDest = '-';
        setCustomASDate(offer);
    }

    const setCustomDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.route[0].local_departure.substring(8, 10) + '/' + offert.route[0].local_departure.substring(5, 7) + '/' + offert.route[0].local_departure.substring(0, 4),
          to: offert.route[0].local_arrival.substring(8, 10) + '/' + offert.route[0].local_arrival.substring(5, 7) + '/' + offert.route[0].local_arrival.substring(0, 4),
        };
        offert.date.return = {
          from: offert.route[1].local_departure.substring(8, 10) + '/' + offert.route[1].local_departure.substring(5, 7) + '/' + offert.route[1].local_departure.substring(0, 4),
          to: offert.route[1].local_arrival.substring(8, 10) + '/' + offert.route[1].local_arrival.substring(5, 7) + '/' + offert.route[1].local_arrival.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.route[0].local_departure.substring(11, 16),
          to: offert.route[0].local_arrival.substring(11, 16),
        };
        offert.date.retH = {
          from: offert.route[1].local_departure.substring(11, 16),
          to: offert.route[1].local_arrival.substring(11, 16),
        };
        return offert.date;
    }

    const setCustomASDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.segment[0].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[0].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[0].flight[0].departure_date.substring(0, 4),
          to: offert.segment[0].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[0].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[0].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.return = {
          from: offert.segment[1].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[1].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[1].flight[0].departure_date.substring(0, 4),
          to: offert.segment[1].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[1].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[1].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.segment[0].flight[0].departure_time,
          to: offert.segment[0].flight[0].arrival_time,
        };
        offert.date.retH = {
          from: offert.segment[1].flight[0].departure_time,
          to: offert.segment[1].flight[0].arrival_time,
        };
        return offert.date;
    }

    const setAirlinesName = (offert) => {
        let fDeparture = airlines.find(x => x.iata === offert.route[0].airline);
        if (fDeparture) {
          offert.route[0].airline = {detail: fDeparture}
        } else {
            offert.route[0].airline = {detail: {iata: '-', name: '-'}}
        }
    
        let fReturn = airlines.find(x => x.iata === offert.route[1].airline);
        if (fReturn) {
          offert.route[1].airline = {detail: fReturn}
        } else {
            offert.route[1].airline = {detail: {iata: '-', name: '-'}}
        }
    }

    useEffect(()=> {
        if (allOffers.length) {
            navigate('/searchResults', {state:{offers:createNewOffer(), formInfo: saveFormInfo()}});
        } else {
            setNoFlights(true);
        }
    }, [allOffers]);

    const createNewOffer = () => {
        let defaultObj = {
          name: 'Offerte',
          code: '',
          cost: 60
        };
        let cityToObj = {
            code: searchData.arr.code,
            name: searchData.arr.name,
            cost: searchData.price,
            guide: findGuide(searchData.arr.name)
        }
        
        let offers = {
          id: undefined,
          cityTo: cityToObj,
          GYG: '<div></div>',
          searchType: 'round',
          offers: allOffers,
          offersType: 'arrival',
          img: setPhoto(allOffers[0]),
          voliDiretti: true,
          bookingId: searchData.arr.bookingId ? searchData.arr.bookingId : undefined
        }
        return offers;
    };

    const setPhoto = (offert) => {
        let img = CitiesPhoto.find(e => e.name.toLowerCase() === offert.flyTo.toLowerCase());
        if (searchData.arr.code === "" || !img) {
          img = CitiesPhoto.find(e => e.name.toLowerCase() === 'off');
        }
        return img;
    }

    const saveFormInfo = ()=> {
        let formInformation = {
            to: searchData.arr,
            from: searchData.dep,
            price: searchData.price,
            typeSearch: 'round',
            departureDate: new Date(searchData.dateDep.substring(3, 5) + '-' + searchData.dateDep.substring(0, 2) + '-' + searchData.dateDep.substring(6)),
            returnDate: new Date(searchData.dateRet.substring(3, 5) + '-' + searchData.dateRet.substring(0, 2) + '-' + searchData.dateRet.substring(6)),
            selectedMonth: undefined,
            nightInDstTo: '7',
            nightInDstFrom: 2,
            voliDiretti: true
        }
        return formInformation;
    }

    const findGuide = (to)=> {
        let guida = guide.find(x=>x.name === to.toLowerCase());
        if (guida) return guida.guide;
        return null;
    }


    return(
        <>
            {
                isLoading == true ?
                <div className='container pt-5'>
                    <div className='landing_title'>Caricamento Offerte</div>
                    {
                        isLoading ?
                        <Spinner className='spinnerLink' animation="border" variant="danger" />
                        :
                        <></>
                    }
                    <div>
                        <img style={{'width': '95vw'}} src={landing} alt="caricamento" />
                    </div>
                    <div className='landing_txt pt-3'>
                        <div>Stiamo caricando le offerte migliori</div>
                        <div>Ti chiediamo di aspettare qualche secondo</div>
                        <div>Verrai reindirizzato automaticamente</div>
                    </div>
                </div>
                :
                <div>
                    <div className='landing_txt fs16 pt-5'>
                        Ci dispiace Hunter
                        <br />
                        l'offerta che cercavi non è più disponibile!
                    </div>
                    <img src={NoResult} alt="noresult" className='nrw mt2'/>
                    <Button className='w-33 landing_btn mt-4' variant="light" onClick={()=>{navigate('/')}}>Tona alla home</Button>
                    <div className='container landing_txt mt2'>
                        Seguici sui nostri canali social per non perderne più una!
                        <div className='mt2'>
                            <ShareSocialPage />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SearchLink;