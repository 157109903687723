import Accordion from 'react-bootstrap/Accordion';
import OfferCard from '../offerCard/offerCard';
import './areaOffersSection.css';

function AreaOffersSection({name, code, flights, offerId}) {

    return (
        <div className='w85 mt-3'>
            <Accordion >
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <div className='flx-sb w-100'>
                        <div>
                            <span className='smallLabel'>Da </span>
                            <span className='messageGYG'>{name}</span> 
                            <span className='smallLabel'> ({code})</span>
                        </div>
                        <div className='me-2'>
                            <span className='smallLabel'>a {flights[0].price}€</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='divider'></div>
                  {
                    flights.map((fl, i)=>
                    <div key={i} className="center d-col">
                        <OfferCard offer={fl} offersType="area" offerId={offerId}/>
                        {
                            i === flights.length-1 ? 
                            <></>
                            :
                            <div className='divider'></div>
                        }
                    </div>
                    )
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default AreaOffersSection;