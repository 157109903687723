import './recommended.css';
import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../assets/whiteLogoFH.png';
import bck from '../../assets/landscapecolor.png';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import DynamicToast from '../dynamicToast/dynamicToast';
import airlines from '../../consts/airlines';
import CitiesPhoto from '../../consts/citiesPhoto';
import guide from '../../consts/guide';
import SearchCity from '../searchCity/searchCity';
import Form from 'react-bootstrap/Form';
import RecommendedRedirect from '../recommendedRedirect/recommendedRedirect';

function Recommended() {

    const destinations = [
        {
            id: '1',
            title: 'mete estive',
            dest: [
                {name: 'Barcellona', code: 'BCN', img:'https://www.grimaldi-lines.com/wp-content/uploads/2022/07/sagrada-familia-1030x687.jpg', desc:"Barcellona d'estate è una delle città più vive d'Europa. Bagnata dal mar Mediterraneo la zona balneare si estende per una lunghezza di circa 5km. Spiagge libere e ampie con attività di ogni tipo, beach volley in primis. Sicuramente la spiaggia più conosciuta è Barceloneta in cui è possibile arrivare con la metro L4."},
                {name: 'Minorca', code: 'MAH', img:'https://cimebordeaux.it/wp-content/uploads/2018/03/Minorca.jpg', desc:"Minorca è una delle Isole Baleari spagnole del Mar Mediterraneo. Tradizionalmente più sobria rispetto alle vicine Maiorca e Ibiza, è nota per le spiagge infinite, da crescenti sabbiosi lunghi chilometri a baie rocciose e turchesi chiamate calas. Ottima meta per godersi il mare incontaminato."},
                {name: 'Santorini', code: 'JTR', img:'https://www.bgviaggi.com/images/santo_sito.jpg', desc:"I tramonti e le viste mozzafiato sono le meraviglie che garantiscono ogni anno a Santorini più di un milione di visitatori. Non bisogna aspettarsi spiagge caraibiche di sabbia bianca e mare turchese; l'origine vulcanica di Santorini ha lasciato il segno anche nelle spiagge dell'isola, caratterizzate da sabbia scura, acqua blu e imponenti scogliere a picco sul mare. Ma è sicuramente un ottima destinazione per una vacanza romantica e di pieno relax."},
                {name: 'Kos', code: 'KGS', img:'https://www.consigliamidove.it/wp-content/uploads/2019/10/Kos.jpg', desc:"Conosciuta per le numerose spiagge di sabbia e mare cristallino. Sull'isola si trovano anche molti monumenti greci e romani, soprattutto all'interno della città di Kos e nei suoi dintorni."},
                {name: 'Corfu', code: 'CFU', img:'https://www.domanidoveandiamo.it/wp-content/uploads/2018/08/corfu_grecia.jpg', desc:"Una settimana non vi basterà per vedere tutte le spiagge più belle di Corfù. Sono tantissime, il che è una buona notizia per gli amanti del mare perché significa che più o meno ovunque è possibile trovare un angolo da sogno dove stendere l'asciugamano."},
                {name: 'Palermo', code: 'PMO', img:'https://www.filoteapasta.com/wp-content/uploads/2019/07/Cattedrale-Palermo.jpg', desc:"Palermo presenta alcune delle spiagge più belle d'Italia, tra cui Cefalù, Mondello e Capaci. Si trova mare cristallino, ottimo cibo e tanto relax."},
                {name: 'Zante', code: 'ZTH', img:'https://www.cosafarei.it/wp-content/uploads/2018/04/zante-principale-1366x691.jpg', desc:"Popolare destinazione estiva. Conosciuta per la celebre spiaggia del relitto, ha intorno a se mare limpido in cui è possibile ammirare il fascino delle tartarughe marine in libertà. Alcune parti dell'isola sono più vivaci, altre dedicate maggiormente alla tranquillità."},
                {name: 'Malta', code: 'MLA', img:'https://www.ilblogdimalta.com/wp-content/uploads/2019/05/mare-a-malta.jpg', desc:"Situata a sud della Sicilia, si può trovare di tutto, dal mare limpido ai siti archeologici dei vari dominatori dell'isola, come romani, mori, inglesi e francesi. Molto attiva anche la vita notturna con un quartiere dedicato al divertimento e alla musica."},
                {name: 'Cagliari', code: 'CAG', img:'https://cdn.ideeperviaggiare.it/media/2021/05/italia-cagliari-mini-trekking-sella-diavolo-1920x1043-367908.jpg', desc:"Cagliari è il capoluogo della Sardegna. È nota per il Castello, un quartiere fortificato medievale in collina situato al di sopra del resto della città. Il Poetto è la principale spiaggia di Cagliari che si estende per circa dodici chilometri. Questa spiaggia è un piccolo paradiso a pochi passi dalla città dove potrete trascorrere una giornata in pieno relax e divertirvi con la sua intensa vita notturna tra locali, bar e discoteche."},
                {name: 'Marsiglia', code: 'MRS', img:'https://www.artribune.com/wp-content/uploads/2022/11/Marsiglia.-Il-MUCEM-e-il-Fort-St.-Jean-%C2%A9-shutterstock.jpg', desc:"Marsiglia è tra le città più grandi della Francia, ma invece di un'atmosfera da metropoli, qua si avverte piuttosto il fascino marinaresco. Fatta di spiagge e sole presenta coste semplicemente irresistibili dove godersi una vacanza in pieno stile Mediterraneo. Qui l'acqua è blu e il clima conviviale ti farà venire voglia di prenderti una lunga vacanza di divertimento e relax."},
            ]
        },
        {
            id: '2',
            title: 'mare e arte',
            dest: [
                {name: 'Atene', code: 'ATH', img:'https://cdn.britannica.com/53/150953-050-2E0836EB/Parthenon-Acropolis-Athens.jpg', desc: "La città è ancora oggi dominata dai monumenti del V secolo a.C., tra cui l'Acropoli, una cittadella in cima a una collina dove sorge, tra gli altri, il tempio di Partenone con le sue colonne. Il Museo dell'Acropoli e il Museo archeologico nazionale conservano sculture, vasi, gioielli e altri reperti dell'antica Grecia."},
                {name: 'Barcellona', code: 'BCN', img:'https://www.grimaldi-lines.com/wp-content/uploads/2022/07/sagrada-familia-1030x687.jpg', desc:"Barcellona, la capitale della regione spagnola della Catalogna, è celebre soprattutto per l'arte e l'architettura. La basilica della Sagrada Família e gli altri edifici progettati da Antoni Gaudí sono il simbolo della città. Il Museo Picasso e la Fondazione Joan Miró espongono opere d'arte dei due famosi artisti moderni. Il museo di storia cittadina MUHBA raccoglie numerosi reperti archeologici di epoca romana."},
                {name: 'Catania', code: 'CTA', img:'https://acchiappamappa.com/wp-content/uploads/2020/05/cosa-vedere-a-catania-1140x760.jpg', desc:"Catania è una città ricca d'arte, l'ampia piazza centrale della città, la famosa piazza del Duomo, è caratterizzata dalla pittoresca statua della Fontana dell'Elefante e dalla Cattedrale, simbolo della città."},
                {name: 'Malaga', code: 'AGP', img:'https://awayandfar.com/wp-content/uploads/2019/01/Malaga-Football-Club.jpg', desc:"Detta anche la capitale della Costa del Sol, Malaga è un'importante città storica e ambita meta turistica grazie alla sua vita culturale molto intensa e alla presenza del Museo Pablo Picasso. Il centro storico di Malaga è ricco di monumenti, tra cui l’Alcazaba, il Teatro Romano e il Castillo de Gibralfaro, stradine antiche e grandi viali alberati chiusi al traffico."},
                {name: 'Rodi', code: 'RHO', img:'https://e3.365dm.com/19/08/2048x1152/skynews-rhodes-greece_4733450.jpg', desc:"Rodi, l'isola più grande del Dodecaneso, è famosa per le località balneari, le antiche rovine e per essere stata occupata dai Cavalieri di San Giovanni durante le Crociate. Nel centro storico della città di Rodi si trovano due siti medievali: Via dei Cavalieri e il Palazzo dei Gran Maestri, con una struttura simile a un castello. Occupato dagli Ottomani e ricostruito dagli Italiani, il palazzo ospita oggi un museo di storia."},
                {name: 'Lisbona', code: 'LIS', img:'https://images.lonelyplanetitalia.it/static/places/lisbona-1373.jpg?q=90&p=2400%7C1350%7Cmax&s=bbf38b90a96e810dfff84f2285b60a06', desc:"La capitale del Portogallo sorge sulla costa ed è caratterizzata da un territorio collinare. Dall'imponente castello di São Jorge, il panorama si allarga sugli edifici in tinte pastello della città vecchia, famoso il Museo Nazionale Azulejo espone cinque secoli di piastrelle in ceramica."},
                {name: 'Spalato', code: 'SPU', img:'https://www.youknowboat.com/wp-content/uploads/2021/05/Paklinski-Islands-in-Hvar-Split-Croatia.jpeg', desc:"Spalato, una città croata sulla costa della Dalmazia, è famosa per il palazzo di Diocleziano, un complesso fortificato che si trova al centro della città e che fu eretto dall'imperatore romano nel IV secolo. Oggi le sue tentacolari rovine comprendono più di 200 edifici."},
                {name: 'Valencia', code: 'VLC', img:'https://www.50epiuenasco.it/wp-content/uploads/2019/09/shutterstock_548065771-1.jpg', desc:"La città portuale di Valencia sorge sulla costa sud-orientale della Spagna, dove il fiume Turia incontra il mar Mediterraneo. È conosciuta per la Città delle arti e delle scienze, che comprende strutture futuristiche fra cui un planetario, un oceanario e un museo interattivo."},
            ]
        },
        {
            id: '3',
            title: 'summer party',
            dest: [
                {name: 'Mykonos', code: 'JMK', img:'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/mykonos-cosa-fare-1519946879.jpg', desc:"Famosa per le feste che si tengono durante l'estate, quando nelle spiagge come Paradise e Super Paradise si diffonde musica a tutto volume. Le sue enormi discoteche attraggono famosi DJ internazionali e rimangono aperte fino al mattino."},
                {name: 'Ibiza', code: 'IBZ', img:'https://www.voglioviverecosi.com/wp-content/uploads/2022/03/ibiza-top.jpg', desc:"Benvenuti sull'isola più festaiola del mondo! Mare cristallino, spiagge di sabbia dorata, ma soprattutto notti pazze! Regina incontrastata della vita notturna e patria delle discoteche. Qui le feste si susseguono giorno e notte senza sosta, tra i suoi numerosi beach bars e clubs, tra mega eventi e feste trasgressive, il tutto condito dalla sua unica vivacità."},
                {name: 'Tenerife', code: 'TCI', img:'https://tourscanner.com/blog/wp-content/uploads/2020/12/Things-to-do-in-Tenerife.jpg', desc:"Amerai vivere la notte a Tenerife, perché la vita non costa molto rispetto agli standard italiani, e ogni sera i locali sono pieni di eventi e serate a tema. La vacanza a Tenerife è ottima per gruppi di amici o amiche giovani dai venti ai trent'anni. I locali si riempiono dopo l'una, dunque fino a quell'ora è tempo di bere un drink e fare conoscenze. L'ingresso nei locali più famosi è a pagamento nei fine settimana, mentre negli altri giorni può essere gratuito."},
                {name: 'Zante', code: 'ZTH', img:'https://www.cosafarei.it/wp-content/uploads/2018/04/zante-principale-1366x691.jpg', desc:"Meta per gli amanti delle notti folli è Laganas. Famosa tra i giovani e i giovanissimi per le sue discoteche, tra cui le imperdibili Rescue, Zero e Medousa, i locali sul lungomare sono invece l'ideale per chi è alla ricerca di un po' di atmosfera. Piccoli bar, ottimi ristoranti di pesce, taverne tradizionali e eleganti caffetterie restano aperti fino a tardi."},
                {name: 'Palma', code: 'PMI', img:'https://www.funair.com/wp-content/uploads/2022/04/Best-Beaches-and-Coves-for-Superyacht-Crew-to-Explore-in-Palma.jpg', desc:"Il fulcro della vita notturna di Palma di Maiorca è il Paseo Maritimo, ossia l'ultima parte del lungomare di Palma, nei pressi del porto. Qui troverai i principali club notturni. Nei dintorni, come all'Arenal puoi trovare locali lungo mare con musica fino a tarda notte. Malafug invece è da preferire per le grosse discoteche e club, come il Tito's."},
                {name: 'Barcellona', code: 'BCN', img:'https://www.grimaldi-lines.com/wp-content/uploads/2022/07/sagrada-familia-1030x687.jpg', desc:"La vita notturna di Barcellona è in gran parte concentrata in quartieri come il Quartiere Gotico, Barceloneta, Sant Antoni e El Born, che offrono una moltitudine di club e pub. Questi quartieri sono i migliori in cui alloggiare se si vuole stare a breve distanza dagli eventi più importanti della città. Sul lungomare vicino alla spiaggia de la Barceloneta si possono trovare le discoteche più grandi, tra cui il famosissimo Pacha."},
                {name: 'Olbia', code: 'OLB', img:'https://www.visitalymaps.app/borghi/images/o/olbia/11a25d86c6b85db9d2b385c7e80f30e4a11ab35b.jpg', desc:"I locali notturni Olbia si trovano in pieno centro e nella zona del lungomare dove ci sono numerosi beach bar, lounge bar, beach club e pub che organizzano happy hour, serate ed eventi con musica dal vivo. Durante il periodo estivo però la movida tende a spostarsi nelle località limitrofe come Porto Rotondo, San Teodoro o Baia Sardinia che ospitano i locali notturni e discoteche più belli della Costa Smeralda."},
                {name: 'Alicante', code: 'ALC', img:'https://static.nexilia.it/wearegaylyplanet/2022/04/Cosa-vedere-a-Alicante-itinerari-e-luoghi-da-vedere.jpg', desc:"Le principali zone dei locali ad Alicante sono El Puerto e El Barrio. Discoteca principale è il Marmarela, situata vicino al porto con affaccio sul mare, d'estate aperto ogni sera. Mentre nel Barrio si possono tranquillamente trovare bar e pub con prezzi folli, drink da 1 litro a 6€."},
                {name: 'Malta', code: 'MLA', img:'https://www.ilblogdimalta.com/wp-content/uploads/2019/05/mare-a-malta.jpg', desc:"La movida di Malta è famosa in tutta Europa, e molti la comparano ad Ibiza o a Mykonos. Le zone più vivaci sono Saint Julian's, Paceville e Sliema, ed è molto comodo spostarsi da un bar a l'altro a piedi. Ideale per giovani e giovanissimi, sopratutto come vacanza post maturità."},
                {name: 'Sunny beach', code: 'BOJ', img:'https://storage.googleapis.com/bulgaria-travel-guide/2022/07/Sunny-Beach-Travel-Guide-Travel-S-Helper-33.jpg', desc:"Se siete giovani e alla ricerca di una meta per le vostre vacanze estive dove divertirvi, fare nuove amicizie e spendere poco, non potete non conoscere la mitica Sunny Beach in Bulgaria. Detta anche l'Ibiza dell'Est. Offre gli stessi divertimenti di località come Mykonos, Palma, Corfù e molte altre a prezzi veramente low cost,un ingresso in discoteca può costare anche 3 euro. Senza parlare degli Open bar e innumerevoli offerte per fare serata."}
            ]
        }
    ];

    const monthOptions = [
        { value: '01', label: 'Gennaio', fromDate: '01/01/', toDate: '01/31/' },
        { value: '02', label: 'Febbraio', fromDate: '02/021/', toDate: '02/28/' },
        { value: '03', label: 'Marzo', fromDate: '03/01/', toDate: '03/31/' },
        { value: '04', label: 'Aprile', fromDate: '04/01/', toDate: '04/30/' },
        { value: '05', label: 'Maggio', fromDate: '05/01/', toDate: '05/31/' },
        { value: '06', label: 'Giugno', fromDate: '06/01/', toDate: '06/30/' },
        { value: '07', label: 'Luglio', fromDate: '07/01/', toDate: '07/31/' },
        { value: '08', label: 'Agosto', fromDate: '08/01/', toDate: '08/31/' },
        { value: '09', label: 'Settembre', fromDate: '09/01/', toDate: '09/30/' },
        { value: '10', label: 'Ottobre', fromDate: '10/01/', toDate: '10/31/' },
        { value: '11', label: 'Novembre', fromDate: '11/01/', toDate: '11/30/' },
        { value: '12', label: 'Dicembre', fromDate: '12/01/', toDate: '12/31/' },
    ];

    const flightsKiwi = 'https://tequila-api.kiwi.com/v2/search?fly_from=';
    const tequilaKey = 'NPwrSMNPfBXmx1eGuVGrAK6UthlGNU3M';

    let {id} = useParams();
    const navigate = useNavigate();

    const [trip, setTrip] = useState();
    const [loader, setLoader] = useState(false);
    const [kiwiOffers, setkiwiOffers] = useState([]);
    const [aviaSalesOffer, setAviaSalesOffer] = useState([]);
    const [allOffers, setAllOffers] = useState([]);
    const [openToast, setOpenToast] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toCode, setToCode] = useState();
    const [toName, setToName] = useState();
    const [fromCode, setFromCode] = useState();
    const [fromName, setFromName] = useState();
    const [userIp, setUserIp] = useState('127.0.0.1');
    const [aSSearchId, setASSearchId] = useState();
    const [price, setPrice] = useState(100);
    const [selectedMonth, setSelectedMonth] = useState(monthOptions[new Date().getMonth() === 11 ? 0 : new Date().getMonth()+1]);
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [searchClick, setSearchClick] = useState(false);
    const [searchCitySheet, setSearchCitySheet] = useState(false);
    const [voliDiretti, setVoliDiretti] = useState(true);

    useEffect(()=>{
        getIp();
    }, [])

    const getIp = ()=> {
        axios.get('https://geolocation-db.com/json/')
        .then((response) => {
            setUserIp(response.data.IPv4)
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        })
    }

    useEffect(()=>{
        if (id && (id === '1' || id === '2' || id === '3')) {
            let updatedId = Number(id)-1;
            setTrip(updatedId);
        } else if (!id || (id !== '1' || id !== '2' || id !== '3')) {
            navigate('/notfound');
        }
    }, [id]);

    const formatDate = (date) => {
        return '' + (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString()) + '/' + ((date.getMonth()+1) >= 10 ? (date.getMonth()+1).toString() : '0' + (date.getMonth()+1).toString()) + '/' + date.getFullYear();
    }

    const formatASDate = (date)=> {
        return '' + date.getFullYear() + '-' + ((date.getMonth()+1) >= 10 ? (date.getMonth()+1).toString() : '0' + (date.getMonth()+1).toString()) + '-' + (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString());
    }

    const searchFlights = (code, name) => {
        if (fromCode) {
            setSearchClick(true);
            setToCode(code);
            setToName(name)
            setLoader(true);
            let today = new Date();
            let firstDate;
            let lastDate = new Date('08/31/' + today.getFullYear());
            let todayTime = new Date().getTime();
            let firstDayTime = new Date('06/01/' + today.getFullYear()).getTime();
            firstDate = todayTime > firstDayTime ? today : new Date('06/01/' + today.getFullYear());
            let stop = voliDiretti ? '0' : '2';
            let link = fromCode + '&fly_to=' + code + '&date_from=' + formatDate(firstDate) +'&date_to=' + formatDate(lastDate) + '&return_from='+ formatDate(firstDate) +'&return_to='+ formatDate(lastDate) +'&nights_in_dst_from='+ '3' +'&nights_in_dst_to='+ '7' + '&ret_from_diff_city=false&ret_to_diff_city=false' + '&max_fly_duration=20&flight_type=round&adults=1&selected_cabins=M&partner_market=' + 'it' + '&curr=EUR&locale=' + 'it' + '&price_from='+ '10' +'&price_to=' + price +'&max_stopovers=' + stop + '&vehicle_type=aircraft';
    
            axios.get(flightsKiwi + link,
            {headers: {apikey: tequilaKey, accept: 'application/json'}})
            .then((response) => {
              setkiwiOffers(response.data.data);
            })
            .catch((error) => {
              console.log(error);
              setLoader(false);
            })
        } else {
            window.scrollTo(0, 0);
            setLoader(false);
            setToastText("Inserisci l'aeroporto di partenza!");
            setOpenToast(true);
        }
        
    }

    useEffect(()=> {
        if (kiwiOffers.length) {
            searchAviaSales();
        } else if(!kiwiOffers.length && searchClick) {
            window.scrollTo(0, 0);
            setLoader(false);
            if(voliDiretti) {
                setToastText('Non abbiamo trovato offerte in questo momento, riprova anche con voli NON diretti!');
            } else {
                setToastText('Riprova con altre destinazioni o altri aeroporti di partenza!');
            }
            setOpenToast(true);
        }
    }, [kiwiOffers]);

    const searchAviaSales = () => {
        let bestkiwiOffers = kiwiOffers[0];
 
        let depDate = new Date(bestkiwiOffers.route[0].local_departure);
        let retDate = new Date(bestkiwiOffers.route[1].local_departure);
        axios.post('https://thriving-panda-73b004.netlify.app/.netlify/functions/api/asd',
        {
            dep: formatASDate(depDate),
            depdestination: toCode,
            deporigin: fromCode,
            ret: formatASDate(retDate),
            retdestination: fromCode,
            retorigin: toCode,
            ip: userIp
        },
        {headers: {
            "content-type": "application/json",
          }}
        )
        .then((response) => {
            let cheapest = [];
            setASSearchId(response.data[0].search_id);
                if (response.data.length > 0) {
                  for(let i = 0; i < response.data.length; i++) {
                      if (response.data[i].proposals[0].terms[Object.keys(response.data[i].proposals[0].terms)].price <= price) {
                          response.data[i].proposals[0].isAviaSales = true;
                          cheapest.push(response.data[i].proposals[0])
                      }
                  }
                }
            setAviaSalesOffer(cheapest);
            setLoader(false);
        })
        .catch((error) => {
            setLoader(false);
            setAviaSalesOffer([]);
            console.log(error);
        })    
    }

    useEffect(()=>{
        let allOffers = kiwiOffers;
        if (aviaSalesOffer) allOffers = allOffers.concat(aviaSalesOffer);
        editOffers(allOffers);
    }, [aviaSalesOffer]);

    const editOffers = (offers)=> {
        offers.forEach(off => {
            if (off.isAviaSales) {
                editASObj(off);  
            } else {
                off.date = setCustomDate(off);
            }
            setAirlinesName(off);
        });
        offers.sort(function(a, b) {
            return a.price - b.price;
        });
        setAllOffers(offers);
    }

    const setCustomDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.route[0].local_departure.substring(8, 10) + '/' + offert.route[0].local_departure.substring(5, 7) + '/' + offert.route[0].local_departure.substring(0, 4),
          to: offert.route[0].local_arrival.substring(8, 10) + '/' + offert.route[0].local_arrival.substring(5, 7) + '/' + offert.route[0].local_arrival.substring(0, 4),
        };
        offert.date.return = {
          from: offert.route[1].local_departure.substring(8, 10) + '/' + offert.route[1].local_departure.substring(5, 7) + '/' + offert.route[1].local_departure.substring(0, 4),
          to: offert.route[1].local_arrival.substring(8, 10) + '/' + offert.route[1].local_arrival.substring(5, 7) + '/' + offert.route[1].local_arrival.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.route[0].local_departure.substring(11, 16),
          to: offert.route[0].local_arrival.substring(11, 16),
        };
        offert.date.retH = {
          from: offert.route[1].local_departure.substring(11, 16),
          to: offert.route[1].local_arrival.substring(11, 16),
        };
        return offert.date;
    }

    const setCustomASDate = (offert) => {
        offert.date = {};
        offert.date.departure = {
          from: offert.segment[0].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[0].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[0].flight[0].departure_date.substring(0, 4),
          to: offert.segment[0].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[0].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[0].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.return = {
          from: offert.segment[1].flight[0].departure_date.substring(8, 10) + '/' + offert.segment[1].flight[0].departure_date.substring(5, 7) + '/' + offert.segment[1].flight[0].departure_date.substring(0, 4),
          to: offert.segment[1].flight[0].arrival_date.substring(8, 10) + '/' + offert.segment[1].flight[0].arrival_date.substring(5, 7) + '/' + offert.segment[1].flight[0].arrival_date.substring(0, 4),
        };
        offert.date.depH = {
          from: offert.segment[0].flight[0].departure_time,
          to: offert.segment[0].flight[0].arrival_time,
        };
        offert.date.retH = {
          from: offert.segment[1].flight[0].departure_time,
          to: offert.segment[1].flight[0].arrival_time,
        };
        return offert.date;
    }

    const setAirlinesName = (offert) => {
        let fDeparture = airlines.find(x => x.iata === offert.route[0].airline);
        if (fDeparture) {
          offert.route[0].airline = {detail: fDeparture}
        } else {
            offert.route[0].airline = {detail: {iata: '-', name: '-'}}
        }
    
        let fReturn = airlines.find(x => x.iata === offert.route[1].airline);
        if (fReturn) {
          offert.route[1].airline = {detail: fReturn}
        } else {
            offert.route[1].airline = {detail: {iata: '-', name: '-'}}
        }
    }

    const editASObj = (offer) => {
        offer.searchId = aSSearchId;
        offer.termsUrl = offer.terms[Object.keys(offer.terms)].url;
        offer.cityFrom = kiwiOffers[0].cityFrom;
        offer.flyFrom = offer.segment[0].flight[0].departure;
        offer.cityTo = kiwiOffers[0].cityTo;
        offer.flyTo = offer.segment[0].flight[0].arrival;
        offer.price = offer.terms[Object.keys(offer.terms)].price;
        // ERA PER LA LUNGHEZZA DEL VOLO MA NON VA
        // offer.outbound = {};
        // offer.outbound.arrivalDate = offer.segment[0].flight[0].arrival_timestamp;
        // offer.outbound.departureDate = offer.segment[0].flight[0].departure_timestamp;
        // offer.inbound = {};
        // offer.inbound.arrivalDate = offer.segment[1].flight[0].arrival_timestamp;
        // offer.inbound.departureDate = offer.segment[1].flight[0].departure_timestamp;
        offer.route = [];
        offer.route[0] = {};
        offer.route[1] = {};
        offer.route[0].airline = offer.segment[0].flight[0].operated_by;
        offer.route[1].airline = offer.segment[1].flight[0].operated_by;
        offer.route[0].flight_no = offer.segment[0].flight[0].number;
        offer.route[1].flight_no = offer.segment[1].flight[0].number;
        offer.nightsInDest = '-';
        setCustomASDate(offer);
    }

    const setPhoto = (offert) => {
        let img = CitiesPhoto.find(e => e.name.toLowerCase() === offert.flyTo.toLowerCase());
        if (toCode === "" || !img) {
          img = CitiesPhoto.find(e => e.name.toLowerCase() === 'off');
        }
        return img;
    }

    useEffect(()=> {
        if (allOffers.length) {
            navigate('/searchResults', {state:{offers:createNewOffer(), formInfo: saveFormInfo()}});
        }
        setSearchClick(false);
    }, [allOffers])

    const saveFormInfo = ()=> {
        let formInformation = {
            to: {name: toName, code: toCode},
            from: {name: fromName, code: fromCode},
            price: price,
            typeSearch: 'month',
            departureDate: departureDate,
            returnDate: returnDate,
            selectedMonth: selectedMonth,
            nightInDstTo: '7',
            nightInDstFrom: '2',
            voliDiretti: voliDiretti
        }
        return formInformation;
    }

    const createNewOffer = () => {
        let defaultObj = {
          name: 'Offerte',
          code: '',
          cost: 60
        };
        let cityToObj = {
            code: toCode,
            name: toName,
            cost: price,
            guide: findGuide(toName)
        }
        
        let offers = {
          id: undefined,
          cityTo: cityToObj,
          GYG: '<div></div>',
          searchType: 'month',
          offers: allOffers,
          offersType: 'arrival',
          img: setPhoto(allOffers[0]),
          voliDiretti: voliDiretti
        }
        return offers;
    }

    const findGuide = (to)=> {
        let guida = guide.find(x=>x.name === to.toLowerCase());
        if (guida) return guida.guide;
        return null;
    }

    const openSearchCity = ()=> {
        setLoader(true);
        setSearchCitySheet(true);
    }

    const closeSearchCity = (city) => {
        if (city) {
            setFromCode(city.code);
            setFromName(city.city);
            setLoader(false);
        }
        setSearchCitySheet(false);
    }

    return(
        <div>
            {
                trip == 0 ||  trip == 1 ||  trip == 2? 
                <div>
                    {
                        loader ?
                        <Spinner className='spinner' animation="border" variant="danger" />
                        :
                        <></>
                    }
                    <div className='mb-4 relative'>
                        <div className='iconContainer'>
                            <img className='imgw18' style={{width: '36px'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                            {/* <img className='imgw18' style={{width: '30px'}} src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/> */}
                        </div>
                        <img src={bck} alt="bck" className='bck_img'/>
                        <div className='guideTitle mt-5'>
                            <div className='city_name'>{destinations[trip].title}</div>
                        </div>
                    </div>
                    <div className='centralize'>
                    <div className='center w-85 mb-3'>
                        <div className='w-100' onClick={openSearchCity}>
                            <input className='search' type="text" placeholder={fromName ? `${fromName}` : "Da dove parti?"}/>
                        </div>
                        <div className='ms-2'>
                            <div className='direct'>Voli diretti</div>
                            <Form.Check 
                              type="switch"
                              id="custom-switch"
                              checked={voliDiretti}
                              onChange={()=>{setVoliDiretti(!voliDiretti)}}
                            />
                        </div>
                    </div>
                        {
                            destinations[trip].dest.map((d, i)=>
                            <div key={i}>
                                <Card className='mb-3 shadow' style={{ width: '85vw', 'borderRadius': '10px' }}>
                                  <Card.Img variant="top" src={d.img} style={{'borderTopRightRadius': '10px', 'borderTopLeftRadius': '10px', 'height': '150px', 'objectFit': 'cover'}}/>
                                  <Card.Body style={{'padding': '0px'}}>
                                    <div className='center'>
                                        <Accordion defaultActiveKey="0" className='w-100'>
                                          <Accordion.Item eventKey="1" style={{'border': 'none'}}>
                                            <Accordion.Header>
                                            <div className='w-100 flx-sb'>
                                                <Card.Title className='offerTxt mb-0'>{d.name}</Card.Title>
                                                <Card.Title className='offerTxt mb-0 findF' onClick={()=>{searchFlights(d.code, d.name)}}>vedi voli</Card.Title>
                                            </div>
                                            </Accordion.Header>
                                            <Accordion.Body className='p-0'>
                                                <div className='divider'></div>
                                                <div className='r_desc att_description' style={{'fontSize': '11px'}}>
                                                    {d.desc}
                                                </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                    </div>
                                  </Card.Body>
                                </Card>
                            </div>
                            )
                        }
                    </div>
                    <div className='redirect' style={{'background': 'white'}}>
                        <RecommendedRedirect />
                    </div>
                </div>
                :
                <div></div>
            }
            <SearchCity open={searchCitySheet} isDep={false} close={closeSearchCity}/>
            <DynamicToast open={openToast} text={toastText} close={()=>{setOpenToast(false)}}/>
        </div>
    )
}

export default Recommended;