import './lastOffersCard.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

function LastOfferCard ({offer}) {

    const navigate = useNavigate();

    return(
        <div className='cardContainer'>
            <Card className='singleC'>
              <Card.Body className='bodyC'>
                <Col className='cnt' style={{'justifyContent' : 'flex-start'}}>
                  <Card.Title className='mb-0'>{offer.cityTo.name}</Card.Title>
                </Col>
                <Col className='cnt' style={{'justifyContent' : 'center'}}>
                  <span>da {offer.price}€</span>
                </Col>
                <Col className='cnt' style={{'justifyContent' : 'flex-end'}}>
                  <Button className='offerBtn' onClick={()=>navigate(`/offers/${offer.id}`)}>Vedi offerta</Button>
                </Col>
              </Card.Body>
            </Card>
        </div>
    )
}

export default LastOfferCard;