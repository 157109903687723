import './guide.css';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {db} from '../../firebase-config';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import Spinner from 'react-bootstrap/Spinner';
import GuideSection from '../guideSection/guideSection';
import bck from '../../assets/landscapecolor.png';
import Accordion from 'react-bootstrap/Accordion';
import SearchForm from '../searchForm/searchForm';
import DynamicToast from '../dynamicToast/dynamicToast';
import logo from '../../assets/whiteLogoFH.png';
import Share from '../share/share';
import shareImg from '../../assets/white_share.png';
import guide from '../../consts/guide';
import SEO from '../seo/seo';

const guideArr = guide;

function Guide({id, fromRes}) {

    const guideId = useLocation();
    const navigate = useNavigate();

    const [guide, setGuide] = useState({});
    const [attractions, setAttractions] = useState([]);
    const [openSection, setOpenSection] = useState(0);

    const [loader, setLoader] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [showYearMess, setShowYearMess] = useState(false);
    const [toastText, setToastText] = useState('');
    const [openShare, setOpenShare] = useState(false);

    useEffect(()=>{
        if (guideId.state.id){
            setLoader(true);
            loadGuide(guideId.state.id);
        } else if(id) {
            setLoader(true);
            loadGuide(id);
        }
    }, [guideId.state.id, id])

    const loadGuide = async(id)=> {
        let selectedGuide = guideArr.find(g => g.guide === id);
        if (selectedGuide) {
            setGuide(selectedGuide);
            setAttractions(selectedGuide.attractions);
            setTimeout(()=>{setLoader(false)}, 3000);
        } else {
            navigate('/notfound')
        }
    }

    const noFlights = ()=> {
        setToastText('Non abbiamo trovato offerte con i criteri di ricerca inseriti, prova a cambiare qualcosa!');
        setShowYearMess(true);
    }

    const closeDynamicToast = ()=> {
        setToastText('');
        setShowYearMess(false);
    }

    const isLoading = (e)=> {
        setLoader(e)
    }

    const closeShare = (e) => {
        setOpenShare(e);
    }

    return (
        <div>
            <SEO title={`FlightsHunter - Guida per ${guide.name}`} description={`Scopri tutto quello che c'è da vedere a ${guide.name}! Non perderti i musei, monumenti, attrazioni ed esperienze da vivere. Crea ricordi, non rimpianti!`}></SEO>
            {
                loader ?
                <Spinner className='spinner' animation="border" variant="danger" />
                :
                <></>
            }
            <div className='mb-2 relative'>
                <div className='iconContainer'>
                    <img className='imgw18' style={{width: '36px', zIndex : '9'}} src={logo} alt="logo" onClick={()=>navigate('/')}/>
                    <img className='imgw18' style={{width: '30px', zIndex : '9'}} src={shareImg} alt="share" onClick={()=>setOpenShare(true)}/>
                </div>
                <img src={bck} alt="bck" className='bck_img'/>
                <div className='guideTitle'>
                    Guida per
                    <div className='city_name'>{guide.name}</div>
                </div>
            </div>
            <div className='w-100 center'>
                <div className='smallLabel w85' style={{'textAlign': 'left'}}>Cosa vedere :</div>
            </div>
            {
            attractions.map((att, i)=>
                    <div key={i} className="center">
                        <GuideSection att={att} openSection={openSection} changeSection={(e)=> {setOpenSection(e)}}/>
                    </div>
                    )
            }
            {
                fromRes ? 
                <div></div>
                :
                <div className='mt-3 center'>
                    <Accordion defaultActiveKey="0" className='acc'>
                      <Accordion.Item eventKey="1" className='acc_bck'>
                        <Accordion.Header>
                            <div className='offerTxt att_title'>
                                Cerca voli
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='acc_body'>
                          <SearchForm isLoading={isLoading} noFlights={noFlights} inResult={true}/>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                </div>
            }
            
            <DynamicToast open={showYearMess} text={toastText} close={closeDynamicToast}/>
            <Share open={openShare} closeShare={closeShare} da={''} a={guide.name} price={''} id={guideId.state.id || id} isGuide={true}/>
            <div className='mb-3'></div>
        </div>
    )
}

export default Guide;