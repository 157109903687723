export const actions = {
    LAST_OFFERS_DAY: "LAST_OFFERS_DAY",
    LAST_OFFERS: "LAST_OFFERS",
}

export const setLastOffersDay = (day) => ({
    day: day,
    type: actions.LAST_OFFERS_DAY,
});

export const saveLastOffers = (offers) => ({
    offers: offers,
    type: actions.LAST_OFFERS,
});
