import Button from 'react-bootstrap/Button';

function RecommendedRedirect () {

    const openTelegramChannel = ()=> {
        window.open('https://t.me/FlightsHunterIt', '_blank');
    }

    return(
        <div className="container center w-100">
            <div className='fs-14' style={{'text-align' : 'left'}}>Seguici su Telegram per non perderti altre straordinarie offerte 🎉</div>
            <div className='ms-2'>
                <Button className='offerBtn' onClick={openTelegramChannel}>Apri</Button>
            </div>
        </div>
    )
}
export default RecommendedRedirect; 