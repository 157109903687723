import cloneDeep from 'lodash/cloneDeep';

const EasyQuiz = [
    {
        quiz: "Quanto è alta la Tour Eiffel?",
        options: [{index: 0 ,label: "324 m"}, {index: 1 ,label: "254 m"}, {index: 2 ,label: "454 m"}],
        correctIndex: 0
    },
    {
        quiz: "Quale compagnia aerea ha una foglia rossa come logo?",
        options: [{index: 0 ,label: "Hawaiian Airlines"}, {index: 1 ,label: "Air Canada"}, {index: 2 ,label: "Emirates"}],
        correctIndex: 1 
    },
    {
        quiz: "Quanti piani ci sono nell'Empire State Building?",
        options: [{index: 0 ,label: "99"}, {index: 1 ,label: "102"}, {index: 2 ,label: "125"}],
        correctIndex: 1 
    },
    {
        quiz: "Quale tra le seguenti non è una fermata della Circumvesuviana?",
        options: [{index: 0 ,label: "Venezia"}, {index: 1 ,label: "Pompeii"}, {index: 2 ,label: "Sorrento"}],
        correctIndex: 0 
    },
    {
        quiz: "Qual è la capitale più a sud del mondo?",
        options: [{index: 0 ,label: "Wellington"}, {index: 1 ,label: "Buenos Aires"}, {index: 2 ,label: "Città del Capo"}],
        correctIndex: 0 
    },
    {
        quiz: "Qual'era la capitale della Spagna prima di Madrid?",
        options: [{index: 0 ,label: "Siviglia"}, {index: 1 ,label: "Girona"}, {index: 2 ,label: "Toledo"}],
        correctIndex: 2 
    },
    {
        quiz: "Qual è la capitale del Brasile?",
        options: [{index: 0 ,label: "Rio de Janeiro"}, {index: 1 ,label: "Brasilia"}, {index: 2 ,label: "San Paolo"}],
        correctIndex: 1 
    },
    {
        quiz: "Qual è la capitale dell'Australia?",
        options: [{index: 0 ,label: "Canberra"}, {index: 1 ,label: "Sydney"}, {index: 2 ,label: "Melbourne"}],
        correctIndex: 0 
    },
    {
        quiz: "Quale aeroporto è riconosciuto con il codice FCO?",
        options: [{index: 0 ,label: "Firenze"}, {index: 1 ,label: "Roma"}, {index: 2 ,label: "Venezia"}],
        correctIndex: 1 
    },
    {
        quiz: "Quale aeroporto in Turchia ha lo stesso nome di un Supereroe?",
        options: [{index: 0 ,label: "Spiderman airport"}, {index: 1 ,label: "Batman airport"}, {index: 2 ,label: "Superman airport"}],
        correctIndex: 1 
    },
    {
        quiz: "Qual è la montagna più alta in Africa?",
        options: [{index: 0 ,label: "Karisimbi"}, {index: 1 ,label: "Kilimanjaro"}, {index: 2 ,label: "Soira"}],
        correctIndex: 1
    },
    {
        quiz: "Con il codice MRS quale aeroporto è riconosciuto",
        options: [{index: 0 ,label: "Marsiglia"}, {index: 1 ,label: "Malpensa"}, {index: 2 ,label: "Malta"}],
        correctIndex: 0
    },
    {
        quiz: "Di che colore è il logo di Easyjet",
        options: [{index: 0 ,label: "Verde"}, {index: 1 ,label: "Viola"}, {index: 2 ,label: "Arancione"}],
        correctIndex: 2
    },
    {
        quiz: "In quale oceano si trovano le Maldive?",
        options: [{index: 0 ,label: "Oceano Pacifico"}, {index: 1 ,label: "Oceano Indiano"}, {index: 2 ,label: "Oceano Atlantico"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è l'oceano più profondo?",
        options: [{index: 0 ,label: "Oceano Pacifico"}, {index: 1 ,label: "Oceano Indiano"}, {index: 2 ,label: "Oceano Atlantico"}],
        correctIndex: 0
    },
    {
        quiz: "In quale oceano affondò il Titanic?",
        options: [{index: 0 ,label: "Oceano Pacifico"}, {index: 1 ,label: "Oceano Indiano"}, {index: 2 ,label: "Oceano Atlantico"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è la più grande specie animale nell'oceano?",
        options: [{index: 0 ,label: "Orca"}, {index: 1 ,label: "Capodoglio"}, {index: 2 ,label: "Balena"}],
        correctIndex: 2
    },
    {
        quiz: "La bandiera di quale paese europeo è blu con una croce gialla?",
        options: [{index: 0 ,label: "Danimarca"}, {index: 1 ,label: "Svezia"}, {index: 2 ,label: "Islanda"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è la lingua ufficiale dei Paesi Bassi?",
        options: [{index: 0 ,label: "Olandese"}, {index: 1 ,label: "Francese"}, {index: 2 ,label: "Tedesco"}],
        correctIndex: 2
    },
    {
        quiz: "In quale città irlandese si trova la fabbrica della Guinness?",
        options: [{index: 0 ,label: "Dublino"}, {index: 1 ,label: "Cork"}, {index: 2 ,label: "Tallaght"}],
        correctIndex: 0
    },
    {
        quiz: "Quale monumento indiano è stato costruito a partire dal 1632 e terminando nel 1653?",
        options: [{index: 0 ,label: "Tempio di Konarak"}, {index: 1 ,label: "Taj Mahal"}, {index: 2 ,label: "Tempio d'Oro"}],
        correctIndex: 1
    },
    {
        quiz: "Quanti paesi compongono il Regno Unito?",
        options: [{index: 0 ,label: "5"}, {index: 1 ,label: "3"}, {index: 2 ,label: "4"}],
        correctIndex: 2
    },
    {
        quiz: "Quanti confini condivide la Germania con altri paesi?",
        options: [{index: 0 ,label: "1"}, {index: 1 ,label: "6"}, {index: 2 ,label: "9"}],
        correctIndex: 2
    },
    {
        quiz: "Una buona parte di Jurassic Park di Steven Spielberg è stata girata in quale stato degli Stati Uniti?",
        options: [{index: 0 ,label: "Hawaii"}, {index: 1 ,label: "Texas"}, {index: 2 ,label: "California"}],
        correctIndex: 0
    },
    {
        quiz: "Quale capitale europea è conosciuta come la città dell'amore?",
        options: [{index: 0 ,label: "Roma"}, {index: 1 ,label: "Parigi"}, {index: 2 ,label: "Budapest"}],
        correctIndex: 1
    },
    {
        quiz: "Quale continente è l'unico a non avere formiche?",
        options: [{index: 0 ,label: "Antarctico"}, {index: 1 ,label: "Australia"}, {index: 2 ,label: "Africa"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è la capitale di Cuba?",
        options: [{index: 0 ,label: "Santiago di Cuba"}, {index: 1 ,label: "Havana"}, {index: 2 ,label: "Santa Clara"}],
        correctIndex: 1
    },
    {
        quiz: "La bandiera di quale paese è bianca con un grande cerchio rosso?",
        options: [{index: 0 ,label: "Corea"}, {index: 1 ,label: "Giappone"}, {index: 2 ,label: "Cina"}],
        correctIndex: 1
    },
    {
        quiz: "La bandiera della Germania è composta da tre colori, quali?",
        options: [{index: 0 ,label: "Rosso-Nero-Giallo"}, {index: 1 ,label: "Rosso-Nero-Verde"}, {index: 2 ,label: "Rosso-Giallo-Grigio"}],
        correctIndex: 0
    },
    {
        quiz: "Quale gruppo di isole comprende Ibiza, Minorca e Maiorca?",
        options: [{index: 0 ,label: "Egadi"}, {index: 1 ,label: "Canarie"}, {index: 2 ,label: "Baleari"}],
        correctIndex: 2
    },
    {
        quiz: "In quale città americana puoi visitare una replica della Torre Eiffel, la Piramide di Giza e un canale di Venezia?",
        options: [{index: 0 ,label: "Los Angeles"}, {index: 1 ,label: "Las Vegas"}, {index: 2 ,label: "New York"}],
        correctIndex: 1
    },
    {
        quiz: "Quale fiore rosa che cresce sugli alberi porta migliaia di visitatori ogni anno in Giappone?",
        options: [{index: 0 ,label: "Ciliegio"}, {index: 1 ,label: "Hibiscus"}, {index: 2 ,label: "Magnolia"}],
        correctIndex: 0
    },
    {
        quiz: "In quale paese è stata girata la trilogia de Il Signore degli Anelli?",
        options: [{index: 0 ,label: "Scozia"}, {index: 1 ,label: "Nuova Zelanda"}, {index: 2 ,label: "Iran"}],
        correctIndex: 1
    },
    {
        quiz: "Chi ha dipinto il soffitto della Cappella Sistina?",
        options: [{index: 0 ,label: "Borromini"}, {index: 1 ,label: "Giotto"}, {index: 2 ,label: "Michelangelo"}],
        correctIndex: 2
    },
    {
        quiz: "Quale famosa statua di Michelangelo si trova nella città italiana di Firenze?",
        options: [{index: 0 ,label: "David"}, {index: 1 ,label: "La pietà"}, {index: 2 ,label: "Bacco"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il paese più grande del Sud America?",
        options: [{index: 0 ,label: "Perù"}, {index: 1 ,label: "Brasile"}, {index: 2 ,label: "Argentina"}],
        correctIndex: 1
    },
    {
        quiz: "Quale città australiana ha il porto naturale più grande del mondo?",
        options: [{index: 0 ,label: "Sydney"}, {index: 1 ,label: "Melbourne"}, {index: 2 ,label: "Brisbane"}],
        correctIndex: 0
    },
    {
        quiz: "In quale stato degli Stati Uniti troveresti il ​​Grand Canyon?",
        options: [{index: 0 ,label: "Texas"}, {index: 1 ,label: "Arizona"}, {index: 2 ,label: "Carolina del Nord"}],
        correctIndex: 1
    },
    {
        quiz: "In quale capitale europea troverai il museo Van Gogh?",
        options: [{index: 0 ,label: "Barcellona"}, {index: 1 ,label: "Berlino"}, {index: 2 ,label: "Amsterdam"}],
        correctIndex: 2
    },
    {
        quiz: "Hiragana, katakana e kanji sono i nomi dei sistemi di scrittura di quale paese?",
        options: [{index: 0 ,label: "Giappone"}, {index: 1 ,label: "Corea"}, {index: 2 ,label: "Cina"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il punto più profondo degli oceani terrestri?",
        options: [{index: 0 ,label: "Bacino Eurasiatico"}, {index: 1 ,label: "fossa di Tonga"}, {index: 2 ,label: "fossa delle Marianne"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese si trova l'antica città di Petra?",
        options: [{index: 0 ,label: "Egitto"}, {index: 1 ,label: "Iraq"}, {index: 2 ,label: "Giordania"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese si trova la capitale più a nord?",
        options: [{index: 0 ,label: "Canada"}, {index: 1 ,label: "Russia"}, {index: 2 ,label: "Islanda"}],
        correctIndex: 2
    },
    {
        quiz: "Quante isole ha l'Australia?",
        options: [{index: 0 ,label: "8222"}, {index: 1 ,label: "822"}, {index: 2 ,label: "82"}],
        correctIndex: 0
    },
    {
        quiz: "Quante stelle ha la bandiera degli Stati Uniti d'America?",
        options: [{index: 0 ,label: "45"}, {index: 1 ,label: "50"}, {index: 2 ,label: "55"}],
        correctIndex: 1
    },
    {
        quiz: "In quale Paese le mucche sono considerate sacre?",
        options: [{index: 0 ,label: "Malesia"}, {index: 1 ,label: "Corea"}, {index: 2 ,label: "India"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è la capitale della Turchia?",
        options: [{index: 0 ,label: "Ankara"}, {index: 1 ,label: "Adalia"}, {index: 2 ,label: "Istanbul"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il paese più soggetto a terremoti?",
        options: [{index: 0 ,label: "Itaiia"}, {index: 1 ,label: "Giappone"}, {index: 2 ,label: "Hawaii"}],
        correctIndex: 1
    },
    {
        quiz: "Su quale mare si trova la città di Beirut?",
        options: [{index: 0 ,label: "Mar morto"}, {index: 1 ,label: "Mar rosso"}, {index: 2 ,label: "Mar mediterraneo"}],
        correctIndex: 2
    },
]

const MediumQuiz = [
    {
        quiz: "Quanto è lunga la muraglia cinese?",
        options: [{index: 0 ,label: "25196 km"}, {index: 1 ,label: "11196 km"}, {index: 2 ,label: "21196 km"}],
        correctIndex: 2 
    },
    {
        quiz: "Qual è la capitale dell'Afghanistan?",
        options: [{index: 0 ,label: "Herat"}, {index: 1 ,label: "Kabul"}, {index: 2 ,label: "Taloqan"}],
        correctIndex: 1 
    },
    {
        quiz: "Quale città degli Stati Uniti ha un aeroporto che porta il nome di John Fitzgerald Kennedy?",
        options: [{index: 0 ,label: "Chicago"}, {index: 1 ,label: "Detroit"}, {index: 2 ,label: "New York"}],
        correctIndex: 2 
    },
    {
        quiz: "Quale aeroporto è riconosciuto con il codice RAK?",
        options: [{index: 0 ,label: "Kabul"}, {index: 1 ,label: "Detroit"}, {index: 2 ,label: "Marrakech"}],
        correctIndex: 2 
    },
    {
        quiz: "In quale paese si trova la spiaggia dei maiali?",
        options: [{index: 0 ,label: "Hawaii"}, {index: 1 ,label: "Bahamas"}, {index: 2 ,label: "Thailandia"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese si trova la piramide più grande del mondo per volume?",
        options: [{index: 0 ,label: "Messico"}, {index: 1 ,label: "Egitto"}, {index: 2 ,label: "Malesia"}],
        correctIndex: 0
    },
    {
        quiz: "In quale città puoi trovare La notte stellata di Vincent Van Gogh?",
        options: [{index: 0 ,label: "Amsterdam"}, {index: 1 ,label: "New York"}, {index: 2 ,label: "Parigi"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese si trova l'aeroporto situato a maggior altitudine al mondo?",
        options: [{index: 0 ,label: "Islanda"}, {index: 1 ,label: "Bolivia"}, {index: 2 ,label: "Cina"}],
        correctIndex: 2
    },
    {
        quiz: "Addis Abeda è la capitale di quale paese africano?",
        options: [{index: 0 ,label: "Uganda"}, {index: 1 ,label: "Nigeria"}, {index: 2 ,label: "Etiopia"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è la città più grande d'Europa?",
        options: [{index: 0 ,label: "Istanbul"}, {index: 1 ,label: "Londra"}, {index: 2 ,label: "Parigi"}],
        correctIndex: 0
    },
    {
        quiz: "Cosa significa 'domingo' in spagnolo?",
        options: [{index: 0 ,label: "Domani"}, {index: 1 ,label: "Domenica"}, {index: 2 ,label: "Dormire"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è la lingua tradizionale parlata in Quebec, Canada?",
        options: [{index: 0 ,label: "Francese"}, {index: 1 ,label: "Inglese"}, {index: 2 ,label: "Spagnolo"}],
        correctIndex: 0
    },
    {
        quiz: "Se ordini una pinta di 'pivo', in quale paese europeo ti trovi?",
        options: [{index: 0 ,label: "Portogallo"}, {index: 1 ,label: "Polonia"}, {index: 2 ,label: "Croazia"}],
        correctIndex: 2
    },
    {
        quiz: "Cos'è il BenNevis in Scozia?",
        options: [{index: 0 ,label: "Un lago"}, {index: 1 ,label: "Un pub"}, {index: 2 ,label: "Una montagna"}],
        correctIndex: 2
    },
    {
        quiz: "Quanti aeroporti ci sono a Londra?",
        options: [{index: 0 ,label: "4"}, {index: 1 ,label: "6"}, {index: 2 ,label: "7"}],
        correctIndex: 0
    },
    {
        quiz: "Quanto dura il volo più lungo al mondo?",
        options: [{index: 0 ,label: "16h"}, {index: 1 ,label: "18h"}, {index: 2 ,label: "24h"}],
        correctIndex: 1
    },
    {
        quiz: "Quale paese confina con Brasile e Argentina?",
        options: [{index: 0 ,label: "Bolivia"}, {index: 1 ,label: "Perù"}, {index: 2 ,label: "Uruguay"}],
        correctIndex: 2
    },
    {
        quiz: "La Finlandia confina con Svezia, Norvegia e quale altro paese?",
        options: [{index: 0 ,label: "Russia"}, {index: 1 ,label: "Estonia"}, {index: 2 ,label: "Danimarca"}],
        correctIndex: 0
    },
    {
        quiz: "Quale stato degli Stati Uniti ha la superficie più grande?",
        options: [{index: 0 ,label: "California"}, {index: 1 ,label: "Alaska"}, {index: 2 ,label: "Texas"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il paese più montuoso d'Europa?",
        options: [{index: 0 ,label: "Svizzera"}, {index: 1 ,label: "Germania"}, {index: 2 ,label: "Austria"}],
        correctIndex: 0
    },
    {
        quiz: "Quale paese ospita il 60% dei laghi del mondo?",
        options: [{index: 0 ,label: "Islanda"}, {index: 1 ,label: "Russia"}, {index: 2 ,label: "Canada"}],
        correctIndex: 2
    },
    {
        quiz: "Quale paese è composto da 180.000 isole?",
        options: [{index: 0 ,label: "Finlandia"}, {index: 1 ,label: "Islanda"}, {index: 2 ,label: "Svezia"}],
        correctIndex: 0
    },
    {
        quiz: "La bandiera di quale stato degli Stati Uniti è rappresentata da un orso grizzly bruno?",
        options: [{index: 0 ,label: "California"}, {index: 1 ,label: "Arkansas"}, {index: 2 ,label: "Kentucky"}],
        correctIndex: 0
    },
    {
        quiz: "Quale sito archeologico italiano risale al 70 d.C.?",
        options: [{index: 0 ,label: "Pompei"}, {index: 1 ,label: "Colosseo"}, {index: 2 ,label: "Paestum"}],
        correctIndex: 1
    },
    {
        quiz: "Dov'è la Grotta Azzurra?",
        options: [{index: 0 ,label: "Ischia"}, {index: 1 ,label: "Elba"}, {index: 2 ,label: "Capri"}],
        correctIndex: 2
    },
    {
        quiz: "Quale percentuale della terra è acqua?",
        options: [{index: 0 ,label: "60%"}, {index: 1 ,label: "70%"}, {index: 2 ,label: "80%"}],
        correctIndex: 1
    },
    {
        quiz: "Il Louvre è stato aperto a Parigi nel 1793. In che anno è stato aperto il Louvre Abu Dhabi?",
        options: [{index: 0 ,label: "1925"}, {index: 1 ,label: "1982"}, {index: 2 ,label: "2017"}],
        correctIndex: 2
    },
    {
        quiz: "In quale città si trova la sede delle Nazioni Unite?",
        options: [{index: 0 ,label: "New York"}, {index: 1 ,label: "Londra"}, {index: 2 ,label: "Parigi"}],
        correctIndex: 0
    },
    {
        quiz: "Si pensa che l'albero di avocado sia originario di quale paese?",
        options: [{index: 0 ,label: "Grecia"}, {index: 1 ,label: "Messico"}, {index: 2 ,label: "Venezuela"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il continente più grande del mondo?",
        options: [{index: 0 ,label: "Africa"}, {index: 1 ,label: "America"}, {index: 2 ,label: "Asia"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è l'unica grande città situata su due continenti?",
        options: [{index: 0 ,label: "Istanbul"}, {index: 1 ,label: "Nuova Delhi"}, {index: 2 ,label: "Londra"}],
        correctIndex: 0
    },
    {
        quiz: "Le Isole Fær Øer sono un territorio di quale paese?",
        options: [{index: 0 ,label: "Danimarca"}, {index: 1 ,label: "Groenlandia"}, {index: 2 ,label: "Islanda"}],
        correctIndex: 0
    },
    {
        quiz: "Quale paese europeo ha la costa più lunga?",
        options: [{index: 0 ,label: "Italia"}, {index: 1 ,label: "Norvegia"}, {index: 2 ,label: "Svezia"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il continente più piatto?",
        options: [{index: 0 ,label: "Africa"}, {index: 1 ,label: "Antartide"}, {index: 2 ,label: "Australia"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è l'isola europea più grande?",
        options: [{index: 0 ,label: "Islanda"}, {index: 1 ,label: "Groenlandia"}, {index: 2 ,label: "Regno unito"}],
        correctIndex: 2
    },
    {
        quiz: "Quale stato degli Stati Uniti ha il maggior numero di college e università?",
        options: [{index: 0 ,label: "New York"}, {index: 1 ,label: "California"}, {index: 2 ,label: "Michigan"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il paese più piccolo del mondo?",
        options: [{index: 0 ,label: "Città del vaticano"}, {index: 1 ,label: "san Marino"}, {index: 2 ,label: "Lussemburgo"}],
        correctIndex: 0
    },
    {
        quiz: "Quanti fusi orari ci sono in Russia?",
        options: [{index: 0 ,label: "9"}, {index: 1 ,label: "10"}, {index: 2 ,label: "11"}],
        correctIndex: 2
    },
    {
        quiz: "Quale paese è l'unico in Medio Oriente senza deserto?",
        options: [{index: 0 ,label: "Siria"}, {index: 1 ,label: "Libano"}, {index: 2 ,label: "Qatar"}],
        correctIndex: 1
    },
    {
        quiz: "Quale paese ha più persone in carcere?",
        options: [{index: 0 ,label: "USA"}, {index: 1 ,label: "Brasile"}, {index: 2 ,label: "Messico"}],
        correctIndex: 0
    },
    {
        quiz: "In quale parte del mondo è stata registrata la temperatura più alta?",
        options: [{index: 0 ,label: "Congo"}, {index: 1 ,label: "California"}, {index: 2 ,label: "Egitto"}],
        correctIndex: 1
    },
    {
        quiz: "Dove troverai Timbuktu?",
        options: [{index: 0 ,label: "Mali"}, {index: 1 ,label: "Thailandia"}, {index: 2 ,label: "Malesia"}],
        correctIndex: 0
    },
    {
        quiz: "Sofia è la capitale di quale paese?",
        options: [{index: 0 ,label: "Bulgaria"}, {index: 1 ,label: "Ungheria"}, {index: 2 ,label: "Montenegro"}],
        correctIndex: 0
    },
    {
        quiz: "Quale capitale europea è stata costruita su 14 isole?",
        options: [{index: 0 ,label: "Stoccolma"}, {index: 1 ,label: "Amsterdam"}, {index: 2 ,label: "Helsinki"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il paese più grande dell'Africa?",
        options: [{index: 0 ,label: "Zambia"}, {index: 1 ,label: "Egitto"}, {index: 2 ,label: "Algeria"}],
        correctIndex: 2
    },
    {
        quiz: "Come si chiamava l'Iran prima del 1935?",
        options: [{index: 0 ,label: "Babilonia"}, {index: 1 ,label: "Persia"}, {index: 2 ,label: "Iran"}],
        correctIndex: 0
    },
    {
        quiz: "Quale paese sudamericano ospita i due terzi della foresta pluviale amazzonica? ",
        options: [{index: 0 ,label: "Perù"}, {index: 1 ,label: "Brasile"}, {index: 2 ,label: "Ecuador"}],
        correctIndex: 1
    },
    {
        quiz: "Circa 400.000 gladiatori morirono combattendo in quale punto di riferimento italiano?",
        options: [{index: 0 ,label: "Arena di verona"}, {index: 1 ,label: "Circo massimo"}, {index: 2 ,label: "Colosseo"}],
        correctIndex: 2
    },
    {
        quiz: "Quanti fusi orari esistono nel mondo?",
        options: [{index: 0 ,label: "24"}, {index: 1 ,label: "12"}, {index: 2 ,label: "6"}],
        correctIndex: 0
    },
]

const DifficultQuiz = [
    {
        quiz: "Il volo più breve del mondo è tra due Isole in Scozia. Quanto tempo dura?",
        options: [{index: 0 ,label: "2 minuti e 45 secondi"}, {index: 1 ,label: "5 minuti e 30 secondi"}, {index: 2 ,label: "1 minuto e 30 secondi"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese si trova l'isola di Natale?",
        options: [{index: 0 ,label: "Messico"}, {index: 1 ,label: "Australia"}, {index: 2 ,label: "Filippine"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese si trova la città di nome Condom?",
        options: [{index: 0 ,label: "Francia"}, {index: 1 ,label: "Colombia"}, {index: 2 ,label: "India"}],
        correctIndex: 0
    },
    {
        quiz: "In quale paese si trova la città di nome",
        subQuiz: "Llanfairpwllgwyn gyllgogerychwyrndrobwll llantysiliogogogoch?",
        options: [{index: 0 ,label: "Polonia"}, {index: 1 ,label: "Estonia"}, {index: 2 ,label: "Galles"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese si trova l'isola Phi Phi?",
        options: [{index: 0 ,label: "Thailandia"}, {index: 1 ,label: "Filippine"}, {index: 2 ,label: "Malesia"}],
        correctIndex: 0
    },
    {
        quiz: "Quale città ha il maggior numero di ponti al mondo?",
        options: [{index: 0 ,label: "Pittsburgh"}, {index: 1 ,label: "Londra"}, {index: 2 ,label: "Amburgo"}],
        correctIndex: 2
    },
    {
        quiz: "Quanti parchi Disneyland ci sono attualmente al mondo?",
        options: [{index: 0 ,label: "12"}, {index: 1 ,label: "15"}, {index: 2 ,label: "8"}],
        correctIndex: 0
    },
    {
        quiz: "In quale paese mi trovo se il menu dello street food include Som Tam e Khao Pad?",
        options: [{index: 0 ,label: "Malesia"}, {index: 1 ,label: "Thailandia"}, {index: 2 ,label: "Corea"}],
        correctIndex: 1
    },
    {
        quiz: "Come si chiama il piatto nazionale del Perù che consiste in pesce crudo sotto aceto condito con agrumi e spezie?",
        options: [{index: 0 ,label: "Ceviche"}, {index: 1 ,label: "Anticuchos"}, {index: 2 ,label: "Rocoto relleno"}],
        correctIndex: 0
    },
    {
        quiz: "Quante lingue si parlano al mondo?",
        options: [{index: 0 ,label: "7200"}, {index: 1 ,label: "6500"}, {index: 2 ,label: "5800"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il più grande parco nazionale del Galles?",
        options: [{index: 0 ,label: "Glyderau"}, {index: 1 ,label: "Brecon Beacons"}, {index: 2 ,label: "Snowdonia"}],
        correctIndex: 2
    },
    {
        quiz: "Quante camere d'albergo ci sono al Bellagio, Las Vegas?",
        options: [{index: 0 ,label: "3450"}, {index: 1 ,label: "3950"}, {index: 2 ,label: "3675"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese l'umile gesto del 'pollice alzato' è considerato un grande insulto?",
        options: [{index: 0 ,label: "Iran"}, {index: 1 ,label: "Nigeria"}, {index: 2 ,label: "Corea"}],
        correctIndex: 0
    },
    {
        quiz: "Quale parco nazionale in USA offre punti panoramici tra cui Mather Point e Yavapai Observation Station?",
        options: [{index: 0 ,label: "Yosemite"}, {index: 1 ,label: "Grand Canyon"}, {index: 2 ,label: "yellowstone"}],
        correctIndex: 1
    },
    {
        quiz: "Quanti stati degli Stati Uniti iniziano con la lettera M?",
        options: [{index: 0 ,label: "9"}, {index: 1 ,label: "7"}, {index: 2 ,label: "8"}],
        correctIndex: 2
    },
    {
        quiz: "In quale capitale asiatica si trova il Tempio del Buddha di Smeraldo?",
        options: [{index: 0 ,label: "Bangkok"}, {index: 1 ,label: "Pechino"}, {index: 2 ,label: "Taipei"}],
        correctIndex: 0
    },
    {
        quiz: "Quanto è alto il Burj Khalifa?",
        options: [{index: 0 ,label: "725"}, {index: 1 ,label: "830"}, {index: 2 ,label: "900"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è la percentuale media di sale nel Mar Morto?",
        options: [{index: 0 ,label: "22%"}, {index: 1 ,label: "25%"}, {index: 2 ,label: "33%"}],
        correctIndex: 2
    },
    {
        quiz: "In quale anno è nata British Airways?",
        options: [{index: 0 ,label: "1919"}, {index: 1 ,label: "1898"}, {index: 2 ,label: "1945"}],
        correctIndex: 0
    },
    {
        quiz: "Il più grande museo d'arte del mondo?",
        options: [{index: 0 ,label: "British Museum"}, {index: 1 ,label: "Louvre"}, {index: 2 ,label: "Metropolitan NYC"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese puoi trovare un biscotto al cioccolato Tim Tam?",
        options: [{index: 0 ,label: "Australia"}, {index: 1 ,label: "Cina"}, {index: 2 ,label: "Giappone"}],
        correctIndex: 0
    },
    {
        quiz: "In quale paese è nata la cheesecake?",
        options: [{index: 0 ,label: "Inghilterra"}, {index: 1 ,label: "Grecia"}, {index: 2 ,label: "Francia"}],
        correctIndex: 1
    },
    {
        quiz: "Quante statue di leoni ci sono a Trafalgar Square?",
        options: [{index: 0 ,label: "6"}, {index: 1 ,label: "2"}, {index: 2 ,label: "4"}],
        correctIndex: 2
    },
    {
        quiz: "Quale stato degli Stati Uniti ha il soprannome di 'Terra dei 10.000 laghi'?",
        options: [{index: 0 ,label: "Texas"}, {index: 1 ,label: "Minnesota"}, {index: 2 ,label: "Oregon"}],
        correctIndex: 1
    },
    {
        quiz: "Quale nazione africana ha il maggior numero di piramidi?",
        options: [{index: 0 ,label: "Egitto"}, {index: 1 ,label: "Algeria"}, {index: 2 ,label: "Sudan"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è lo stato americano meno popolato?",
        options: [{index: 0 ,label: "Wyoming"}, {index: 1 ,label: "Montana"}, {index: 2 ,label: "Delaware"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è l'unico continente con terra in tutti e quattro gli emisferi?",
        options: [{index: 0 ,label: "Africa"}, {index: 1 ,label: "Europa"}, {index: 2 ,label: "Asia"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è la città abitata più antica del mondo?",
        options: [{index: 0 ,label: "Gerusalemme"}, {index: 1 ,label: "Atene"}, {index: 2 ,label: "Damasco"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese troverai gli antichi glifi noti come linee di Nasca?",
        options: [{index: 0 ,label: "Perù"}, {index: 1 ,label: "Giordania"}, {index: 2 ,label: "Cina"}],
        correctIndex: 0
    },
    {
        quiz: "Quante strisce rosse ci sono su una bandiera degli Stati Uniti?",
        options: [{index: 0 ,label: "5"}, {index: 1 ,label: "7"}, {index: 2 ,label: "9"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il paese più grande del mondo senza fiumi, laghi o cascate?",
        options: [{index: 0 ,label: "Cambogia"}, {index: 1 ,label: "Colombia"}, {index: 2 ,label: "Arabia Saudita"}],
        correctIndex: 2
    },
    {
        quiz: "Quale paese ha la cascata più alta del mondo?",
        options: [{index: 0 ,label: "Kenya"}, {index: 1 ,label: "Venezuela"}, {index: 2 ,label: "Malesia"}],
        correctIndex: 1
    },
    {
        quiz: "La bandiera di quale nazione è l'unica che non è un rettangolo o un quadrato?",
        options: [{index: 0 ,label: "Ghana"}, {index: 1 ,label: "iran"}, {index: 2 ,label: "Nepal"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è la lingua ufficiale della Groenlandia?",
        options: [{index: 0 ,label: "francese"}, {index: 1 ,label: "inglese"}, {index: 2 ,label: "groenlandese"}],
        correctIndex: 2
    },
    {
        quiz: "In quale paese c'è un pozzo di gas naturale soprannominato la 'Porta dell'Inferno' che brucia dal 1971?",
        options: [{index: 0 ,label: "Pakistan"}, {index: 1 ,label: "Turkmenistan"}, {index: 2 ,label: "Iraq"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese troveresti la valuta 'baht'?",
        options: [{index: 0 ,label: "Cambogia"}, {index: 1 ,label: "Myanmar"}, {index: 2 ,label: "Thailandia"}],
        correctIndex: 1
    },
    {
        quiz: "In quale paese si trova la cascata più alta del mondo?",
        options: [{index: 0 ,label: "Venezuela"}, {index: 1 ,label: "Kenya"}, {index: 2 ,label: "Zimbabwe"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il lago più profondo del mondo?",
        options: [{index: 0 ,label: "Crater Lake"}, {index: 1 ,label: "Lake Baikal"}, {index: 2 ,label: "Ysyk"}],
        correctIndex: 1
    },
    {
        quiz: "Zanzibar è un'isola al largo di quale Paese africano?",
        options: [{index: 0 ,label: "Ruanda"}, {index: 1 ,label: "Uganda"}, {index: 2 ,label: "Tanzania"}],
        correctIndex: 2
    },
    {
        quiz: "Al Taj Mahal c'è una panchina che prende il nome da qualcuno, chi è?",
        options: [{index: 0 ,label: "Nelson Mandela"}, {index: 1 ,label: "Mahatma Gandhi"}, {index: 2 ,label: "Lady Diana"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è il paese o il territorio meno densamente popolato al mondo?",
        options: [{index: 0 ,label: "Groenlandia"}, {index: 1 ,label: "Islanda"}, {index: 2 ,label: "Palau"}],
        correctIndex: 0
    },
    {
        quiz: "Quante lingue ufficiali hanno le Nazioni Unite?",
        options: [{index: 0 ,label: "4"}, {index: 1 ,label: "5"}, {index: 2 ,label: "6"}],
        correctIndex: 2
    },
    {
        quiz: "Quale paese ha la maggior attività vulcanica al mondo?",
        options: [{index: 0 ,label: "Hawaii"}, {index: 1 ,label: "Indonesia"}, {index: 2 ,label: "Islanda"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è la capitale del Costa Rica?",
        options: [{index: 0 ,label: "Santa Marta"}, {index: 1 ,label: "San Jose"}, {index: 2 ,label: "San Miguel"}],
        correctIndex: 1
    },
    {
        quiz: "Qual è il fiume più lungo dell'Asia?",
        options: [{index: 0 ,label: "Enisej"}, {index: 1 ,label: "Mekong"}, {index: 2 ,label: "Yangtze"}],
        correctIndex: 2
    },
    {
        quiz: "Quanti paesi attraversa il fiume Nilo?",
        options: [{index: 0 ,label: "1"}, {index: 1 ,label: "11"}, {index: 2 ,label: "15"}],
        correctIndex: 1
    },
    {
        quiz: "Quanti paesi attraversa l'Equatore?",
        options: [{index: 0 ,label: "1"}, {index: 1 ,label: "7"}, {index: 2 ,label: "13"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è l'attrazione turistica più visitata negli Stati Uniti?",
        options: [{index: 0 ,label: "Times Square"}, {index: 1 ,label: "Grand canyon"}, {index: 2 ,label: "Casa bianca"}],
        correctIndex: 0
    },
    {
        quiz: "Qual è il più grande mammifero terrestre trovato in Nord America?",
        options: [{index: 0 ,label: "Bisonte"}, {index: 1 ,label: "Orso bruno"}, {index: 2 ,label: "Alce"}],
        correctIndex: 0
    },
    {
        quiz: "Quale paese ha la più alta percentuale di terra coperta da foreste?",
        options: [{index: 0 ,label: "Paraguay"}, {index: 1 ,label: "Guyana"}, {index: 2 ,label: "Suriname"}],
        correctIndex: 2
    },
    {
        quiz: "Qual è l'unica nazione cattolica in Asia?",
        options: [{index: 0 ,label: "Filippine"}, {index: 1 ,label: "Mongolia"}, {index: 2 ,label: "Taiwan"}],
        correctIndex: 0
    },
    {
        quiz: "L'inno nazionale di quale paese non ha parole?",
        options: [{index: 0 ,label: "Portoghese"}, {index: 1 ,label: "Spagnolo"}, {index: 2 ,label: "Tedesco"}],
        correctIndex: 1
    },
]

let EasyQuizCopy = cloneDeep(EasyQuiz);
let MediumQuizCopy = cloneDeep(MediumQuiz);
let DifficultQuizCopy = cloneDeep(DifficultQuiz);

const returnQ = ()=> {
    let quizQuestions = [];
    let index = 0;
    for(let i = 0; i < 10; i++) {
        if (i<3) {
            index = getIndex(1);
            quizQuestions.push(EasyQuizCopy[index]);
            EasyQuizCopy.splice(index, 1);
        } else if(i >= 3 && i < 6) {
            index = getIndex(2);
            quizQuestions.push(MediumQuizCopy[index]);
            MediumQuizCopy.splice(index, 1);
        } else if (i >= 6) {
            index = getIndex(3);
            quizQuestions.push(DifficultQuizCopy[index]);
            DifficultQuizCopy.splice(index, 1);
        }
    }
    return quizQuestions;
}

const getIndex = (lvl)=> {
    let index;
    switch (lvl) {
        case 1:
            index = Math.random() * EasyQuizCopy.length;
            index = Math.floor(index);
        return index
        case 2:
            index = Math.random() * MediumQuizCopy.length;
            index = Math.floor(index);
        return index
        case 3:
            index = Math.random() * DifficultQuizCopy.length;
            index = Math.floor(index);
        return index
    }
}

export default returnQ;